import { ReturnProfile_featuredDeal$key } from "__generated__/ReturnProfile_featuredDeal.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import ComplexRowText from "../../../../../../components/01_Core/Tables/ComplexRowText";
import RowHeader from "../../../../../../components/01_Core/Tables/RowHeader";
import { formatDollars } from "../../../../../../utils/money";
import { useActiveResponsiveValue } from "../../../../../../utils/responsiveUtils";
import { formatNumber } from "../../../../../../utils/stringFormatting";
import { DealAnalysisSize } from "./DealAnalysis";
import getReturnProfile, {
  ReturnProfileColumnName,
  ReturnProfileRowName
} from "./getReturnProfile";

type ReturnProfileRow = [
  ReturnProfileRowName,
  string, // a row title
  (rawValue: number) => string // a formatter function for display
];

interface IReturnProfileProps {
  size: ResponsiveValue<DealAnalysisSize>;
  featuredDeal: ReturnProfile_featuredDeal$key;
}

/**
 * figma: N/A
 */
function ReturnProfile(props: IReturnProfileProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const subtitleVariant = activeSize === "large" ? "h400" : "h300";
  const featuredDeal = useFragment(
    graphql`
      fragment ReturnProfile_featuredDeal on FeaturedDealNode {
        termLengthMonths
        streamsPerDay
        totalAmountRaising
        partnerSplitPreRecoupment
        partnerSplitPostRecoupment
      }
    `,
    props.featuredDeal
  );
  const termLengthFormatted =
    featuredDeal.termLengthMonths % 12 === 0
      ? `${featuredDeal.termLengthMonths / 12} year`
      : `${featuredDeal.termLengthMonths} month`;
  const rows: ReturnProfileRow[] = [
    ["streamsPerDay", "Release streams per day", formatNumber],
    [
      "termLengthReturns",
      `Returns over ${termLengthFormatted}s ($)`,
      v => `$${formatNumber(v)}`
    ],
    [
      "termLengthReturnsCoefficient",
      `Returns over ${termLengthFormatted}s (x)`,
      v => `${v}x`
    ],
    [
      "monthsToRecoup",
      "Expected months to recoup",
      v => (v ? formatNumber(v) : "0")
    ]
  ];
  const columnNames: ReturnProfileColumnName[] = [
    "decline",
    "staticGrowth",
    "strongGrowth",
    "massiveGrowth"
  ];
  const returnProfile = getReturnProfile(
    featuredDeal.streamsPerDay,
    featuredDeal.termLengthMonths,
    featuredDeal.totalAmountRaising,
    featuredDeal.partnerSplitPreRecoupment,
    featuredDeal.partnerSplitPostRecoupment
  );

  return (
    <AutoLayout
      spacing={24}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      <Text variant={subtitleVariant}>
        Return profile for a ${formatDollars(featuredDeal.totalAmountRaising)}{" "}
        investment with {termLengthFormatted} term
      </Text>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          width: "100%"
        }}
      >
        {rows.map(([rowName, rowTitle, formatter]) => {
          return (
            <Box key={rowName} sx={{ display: "contents" }}>
              <RowHeader
                align={"left"}
                emphasis={rowName === "streamsPerDay" ? "high" : "low"}
                size={props.size}
                sx={{
                  gridColumn: "1 / -1"
                }}
              >
                {rowTitle}
              </RowHeader>
              {columnNames.map(columnName => {
                return (
                  <Box
                    key={`${rowName}-${columnName}`}
                    sx={{ display: "contents" }}
                  >
                    <ComplexRowText
                      align={"right"}
                      size={props.size}
                      type={
                        rowName === "streamsPerDay" ? "important" : "default"
                      }
                      emphasis={rowName === "streamsPerDay" ? "high" : "low"}
                    >
                      {formatter(returnProfile[columnName][rowName])}
                    </ComplexRowText>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
      <Text variant={"bodyMedium"}>
        Based on ${GLOBALS.DOLLARS_PER_STREAM}/stream and{" "}
        {featuredDeal.partnerSplitPreRecoupment}% pre and{" "}
        {featuredDeal.partnerSplitPostRecoupment}% post-recoupment splits
      </Text>
    </AutoLayout>
  );
}

export default ReturnProfile;
