import { ReactElement } from "react";
import { Box, ThemeUIStyleObject } from "theme-ui";

interface IDividerProps {
  saturation?: "light" | "dark";
  styles?: ThemeUIStyleObject;
  id?: string;
}

export default function Divider(props: IDividerProps): ReactElement {
  const saturation: IDividerProps["saturation"] = props.saturation ?? "light";

  return (
    <Box
      id={props.id}
      backgroundColor={saturation === "light" ? "midGray70" : "deepGray100"}
      sx={{
        height: "1px",
        width: "100%",
        ...props.styles
      }}
    />
  );
}

export function insertDividers(elements: ReactElement[]): ReactElement[] {
  return elements
    .filter(Boolean)
    .flatMap((e, i) => [e, <Divider key={`divider-${i}`} />])
    .slice(0, -1);
}
