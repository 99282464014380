import { Properties } from "csstype";
import { ReactElement } from "react";
import { Box, BoxProps } from "theme-ui";

type GridStyle = {
  gridTemplateColumns: Properties["gridTemplateColumns"];
  gridColumnGap: Properties["gridColumnGap"];
  paddingX: Properties["padding"];
  width: Properties["width"];
};

const GridStyles: {
  [gridType: string]: {
    [gridSize: string]: GridStyle;
  };
} = {
  FluidMaterial: {
    xSmall: {
      gridTemplateColumns: "repeat(4, 1fr)",
      gridColumnGap: "16px",
      paddingX: "16px",
      width: "100%"
    },
    small600: {
      gridTemplateColumns: "repeat(8, 1fr)",
      gridColumnGap: "16px",
      paddingX: "16px",
      width: "100%"
    },
    small768: {
      gridTemplateColumns: "repeat(8, 1fr)",
      gridColumnGap: "24px",
      paddingX: "24px",
      width: "100%"
    },
    medium: {
      gridTemplateColumns: "repeat(12, 1fr)",
      gridColumnGap: "24px",
      paddingX: "24px",
      width: "100%"
    }
  },
  Fixed: {
    small: {
      gridTemplateColumns: "repeat(8, 64px)",
      gridColumnGap: "24px",
      paddingX: "0px",
      width: "unset"
    },
    medium: {
      gridTemplateColumns: "repeat(12, 64px)",
      gridColumnGap: "24px",
      paddingX: "0px",
      width: "unset"
    }
  }
};

const FixedGridStyles = [
  GridStyles.FluidMaterial.xSmall,
  GridStyles.FluidMaterial.small600,
  GridStyles.Fixed.small,
  GridStyles.Fixed.medium
];

const FluidGridStyles = [
  GridStyles.FluidMaterial.xSmall,
  GridStyles.FluidMaterial.small600,
  GridStyles.FluidMaterial.small768,
  GridStyles.FluidMaterial.medium
];

interface IGridProps extends BoxProps {
  noOuterPadding?: boolean;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2159%3A979
 */
export function FixedGrid(props: IGridProps): ReactElement {
  const { sx, noOuterPadding, ...rest } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: FixedGridStyles.map(
          ({ gridTemplateColumns }) => gridTemplateColumns
        ),
        gridColumnGap: FixedGridStyles.map(
          ({ gridColumnGap }) => gridColumnGap
        ),
        px: noOuterPadding
          ? "unset"
          : FixedGridStyles.map(({ paddingX }) => paddingX),
        width: FixedGridStyles.map(({ width }) => width),
        ...sx
      }}
      {...rest}
    />
  );
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2159%3A107
 */
export function FluidGrid(props: IGridProps): ReactElement {
  const { sx, noOuterPadding, ...rest } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: FluidGridStyles.map(
          ({ gridTemplateColumns }) => gridTemplateColumns
        ),
        gridColumnGap: FluidGridStyles.map(
          ({ gridColumnGap }) => gridColumnGap
        ),
        px: noOuterPadding
          ? "unset"
          : FluidGridStyles.map(({ paddingX }) => paddingX),
        width: FluidGridStyles.map(({ width }) => width),
        ...sx
      }}
      {...rest}
    />
  );
}
