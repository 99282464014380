import { ReactElement } from "react";

import { Loader } from "../999_Miscellaneous/Loader";
import Button, { IButtonProps } from "./Button";

export interface ILoadingButtonProps extends IButtonProps {
  loading: boolean;
}

export default function LoadingButton(
  props: ILoadingButtonProps
): ReactElement {
  const { loading, disabled, children, iconProps, ...rest } = props;

  return (
    <Button
      {...rest}
      disabled={disabled || loading}
      iconProps={{
        icon: loading ? <Loader /> : iconProps?.icon ?? null,
        placement: loading
          ? iconProps?.placement ?? "left"
          : iconProps?.placement ?? null
      }}
    >
      {children}
    </Button>
  );
}
