import {
  DealManageable_featuredDeal$data,
  DealManageable_featuredDeal$key
} from "__generated__/DealManageable_featuredDeal.graphql";
import { mutationsToggleDraftFeaturedDealMutation } from "__generated__/mutationsToggleDraftFeaturedDealMutation.graphql";
import { ReactElement, useContext } from "react";
import { commitMutation, graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import ReleaseIconsNames from "../../../../components/01_Core/Releases/ReleaseIconsNames";
import environment from "../../../../environment";
import { toggleDraftFeaturedDealMutation } from "../../../../mutations/mutations";
import color from "../../../../theme/color";
import { formatDollars } from "../../../../utils/money";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import DealManageMenu from "./DealManageMenu";

type DealManageableSize = "small" | "large";

interface IDealManageableProps {
  featuredDeal: DealManageable_featuredDeal$key;
  onClickEditDeal: () => void;
  size: ResponsiveValue<DealManageableSize>;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=1018%3A20147
 */
function DealManageable(props: IDealManageableProps): ReactElement {
  const notifyBanner = useContext(BannerContext);

  const activeSize = useActiveResponsiveValue(props.size);

  const featuredDeal = useFragment(
    graphql`
      fragment DealManageable_featuredDeal on FeaturedDealNode {
        id
        status
        totalAmountRaising
        ...ReleaseIconsNames_query
        ...DealManageMenu_featuredDeal
      }
    `,
    props.featuredDeal
  );
  const toggleDraftFeaturedDeal = () =>
    commitMutation<mutationsToggleDraftFeaturedDealMutation>(environment, {
      mutation: toggleDraftFeaturedDealMutation,
      variables: {
        id: featuredDeal.id
      }
    });

  const onClickCopyDealLink = () => {
    navigator.clipboard
      .writeText(
        window.location.origin +
          generatePath(InternalRoutes.featuredDeal, {
            dealId: featuredDeal.id
          })
      )
      .then(() =>
        notifyBanner({
          type: "neutral",
          children: "Deal link copied to clipboard."
        })
      );
  };

  type palette = {
    [area in "bg" | "statusText" | "raisingText"]: keyof typeof color;
  };
  const disabledPalette: palette = {
    bg: "lightGray100",
    statusText: "deepGray100",
    raisingText: "deepGray70"
  };
  const enabledPalette: palette = {
    bg: "white100",
    statusText: "black100",
    raisingText: "deepGray100"
  };
  const palettes: {
    [status in DealManageable_featuredDeal$data["status"]]: palette;
  } = {
    PENDING: disabledPalette,
    DRAFT: disabledPalette,
    PUBLISHED: enabledPalette,
    CLOSED: enabledPalette
  };
  const statusLabels: {
    [status in DealManageable_featuredDeal$data["status"]]: string;
  } = {
    PENDING: "Pending approval",
    DRAFT: "Hidden from partners",
    PUBLISHED: "Visible to partners",
    CLOSED: "Closed"
  };

  return (
    <AutoLayout
      spacing={16}
      resizingX={FillContainer()}
      dependentProps={{
        direction: activeSize === "small" ? "vertical" : "horizontal",
        alignment: "left"
      }}
      bg={palettes[featuredDeal.status].bg}
      sx={{
        padding: "16px",
        border: "1px solid",
        borderColor: "midGray100",
        borderRadius: "4px"
      }}
    >
      <ReleaseIconsNames size={"large"} query={featuredDeal} />
      {activeSize === "small" && <Divider />}
      <AutoLayout
        spacing={16}
        dependentProps={{
          direction: "horizontal"
        }}
        sx={{
          flexShrink: 0,
          flexDirection: activeSize === "small" ? "row-reverse" : "row"
        }}
      >
        <AutoLayout
          spacing={2}
          dependentProps={{
            direction: "vertical",
            alignment: activeSize === "small" ? "left" : "right"
          }}
          sx={{
            flexShrink: 0
          }}
        >
          <Text
            variant={"bodyMedium"}
            color={palettes[featuredDeal.status].statusText}
          >
            {statusLabels[featuredDeal.status]}
          </Text>
          {featuredDeal.totalAmountRaising != null && (
            <Text
              variant={"bodySmall"}
              color={palettes[featuredDeal.status].raisingText}
            >
              Raising ${formatDollars(featuredDeal.totalAmountRaising)}
            </Text>
          )}
        </AutoLayout>
        <DealManageMenu
          openDirection={activeSize === "small" ? "right" : "left"}
          featuredDeal={featuredDeal}
          onClickEditDeal={props.onClickEditDeal}
          onClickToggleDraftDeal={toggleDraftFeaturedDeal}
          onClickCopyDealLink={onClickCopyDealLink}
        />
      </AutoLayout>
    </AutoLayout>
  );
}

export default DealManageable;
