/**
 * @generated SignedSource<<ca9cc938131efefebe38ae764ab4629c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundTestimonials_company$data = {
  readonly testimonials: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"FundTestimonial_companytestimonial">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "FundTestimonials_company";
};
export type FundTestimonials_company$key = {
  readonly " $data"?: FundTestimonials_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundTestimonials_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundTestimonials_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyTestimonialNodeConnection",
      "kind": "LinkedField",
      "name": "testimonials",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyTestimonialNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyTestimonialNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FundTestimonial_companytestimonial"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CompanyNode",
  "abstractKey": null
};

(node as any).hash = "48f28d279c79f1e36442c0e1bb35d7ee";

export default node;
