/**
 * Icon utilities needed to implement 01 Core/Foundations/Icons
 *
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=0%3A8812
 */

import { ReactElement } from "react";
import { Flex, FlexProps, Spinner, SpinnerProps } from "theme-ui";

interface IIconBoxProps extends FlexProps {
  icon: SvgrComponent;
  viewBoxSize?: number;
}

export function IconBox(props: IIconBoxProps): ReactElement {
  const { icon: Icon, viewBoxSize, ...rest } = props;
  const viewBoxProps =
    viewBoxSize != null
      ? {
          viewBox: `0 0 ${viewBoxSize} ${viewBoxSize}`
        }
      : {};

  return (
    <Flex {...rest}>
      <Icon width={"100%"} height={"auto"} {...viewBoxProps} />
    </Flex>
  );
}

export function ClickableIconBox(
  props: IIconBoxProps &
    SpinnerProps & {
      onClick: () => void;
      loading?: boolean;
    }
): ReactElement {
  const { loading, ...rest } = props;
  if (loading) return <Spinner strokeWidth={2} size={props.size} />;
  return (
    <IconBox
      {...rest}
      sx={{ cursor: "pointer", size: props.size, ...props.sx }}
    />
  );
}
