import { ClaimYourAccountFlow_IndifyToArtist_Accept_Mutation } from "__generated__/ClaimYourAccountFlow_IndifyToArtist_Accept_Mutation.graphql";
import { ClaimYourAccountFlow_IndifyToPartner_Accept_Mutation } from "__generated__/ClaimYourAccountFlow_IndifyToPartner_Accept_Mutation.graphql";
import { ClaimYourAccountFlow_PartnerToArtist_Accept_Mutation } from "__generated__/ClaimYourAccountFlow_PartnerToArtist_Accept_Mutation.graphql";
import { ClaimYourAccountFlow_PartnerToCompanyPartner_Accept_Mutation } from "__generated__/ClaimYourAccountFlow_PartnerToCompanyPartner_Accept_Mutation.graphql";
import { ClaimYourAccountFlow_PartnerToPartner_Accept_Mutation } from "__generated__/ClaimYourAccountFlow_PartnerToPartner_Accept_Mutation.graphql";
import { ClaimYourAccountFlowQuery as ClaimYourAccountFlowQueryType } from "__generated__/ClaimYourAccountFlowQuery.graphql";
import { FORM_ERROR } from "final-form";
import { ReactElement } from "react";
import { Form } from "react-final-form";
import {
  graphql,
  PreloadedQuery,
  useMutation,
  usePreloadedQuery
} from "react-relay";
import { Redirect } from "react-router-dom";
import { PayloadError } from "relay-runtime";
import { BehaviorSubject, Subject } from "rxjs";
import { Text } from "theme-ui";

import AutoLayout, { Fixed } from "../../../../components/01_Core/AutoLayout";
import { HiddenFieldValue } from "../../../../components/01_Core/Forms/utils/reactFinalFormUtils";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { useAuth } from "../../hooks/useAuth";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import BannerSwitch from "../03_UI_Kit/Forms/Banners/BannerSwitch";
import ProgressBar from "../03_UI_Kit/Forms/ProgressBar";
import ClaimYourAccountFlowFooter from "./ClaimYourAccountFlowFooter";
import HelperText from "./HelperText";
import { IIndifyToArtistReferralFlowFormValues } from "./IndifyToArtistReferralFlow";
import { IIndifyToPartnerReferralFlowFormValues } from "./IndifyToPartnerReferralFlow";
import { ConfirmAccountInfoPage, Pages } from "./Pages/Page";
import { IPartnerToCompanyPartnerReferralFlowFormValues } from "./PartnerToCompanyPartnerReferralFlow";
import ReferralFlowSwitch from "./ReferralFlowSwitch";

const Visible = "visible";

interface IClaimYourAccountFlowProps {
  referralSlug: string;
  queryRef: PreloadedQuery<ClaimYourAccountFlowQueryType>;
}

export type NavigationRequest = "continue" | "back";

export interface INavState {
  current: number;
  total: number;
  page: Pages;
}

export interface IReferralFormValues {
  referral: string;
}

export type FormValues =
  | IPartnerToCompanyPartnerReferralFlowFormValues
  | IIndifyToArtistReferralFlowFormValues;

// Each page has validation which needs to be complete before moving forward
function ClaimYourAccountFlow(props: IClaimYourAccountFlowProps): ReactElement {
  const { setMeFragmentRef } = useAuth();
  const navStateSubject = new BehaviorSubject<INavState>({
    current: 0,
    total: 1,
    page: ConfirmAccountInfoPage
  });
  const navRequestSubject = new Subject<NavigationRequest>();
  const data = usePreloadedQuery(ClaimYourAccountFlowQuery, props.queryRef);

  if (
    data.referralBySlug === null ||
    data.referralBySlug.state === "ACCEPTED"
  ) {
    return <Redirect to={"/"} />;
  }

  const [commitPartnerToCompanyPartnerReferral] =
    useMutation<ClaimYourAccountFlow_PartnerToCompanyPartner_Accept_Mutation>(
      graphql`
        mutation ClaimYourAccountFlow_PartnerToCompanyPartner_Accept_Mutation(
          $input: PartnerToCompanyPartnerReferralAcceptInput!
        ) {
          partnerToCompanyPartnerReferralAccept(data: $input) {
            me {
              ...useAuth_MeFragment
            }
          }
        }
      `
    );
  const [commitIndifyToArtistReferral] =
    useMutation<ClaimYourAccountFlow_IndifyToArtist_Accept_Mutation>(graphql`
      mutation ClaimYourAccountFlow_IndifyToArtist_Accept_Mutation(
        $input: IndifyToArtistReferralAcceptInput!
      ) {
        indifyToArtistReferralAccept(data: $input) {
          me {
            ...useAuth_MeFragment
          }
        }
      }
    `);
  const [commitIndifyToPartnerReferral] =
    useMutation<ClaimYourAccountFlow_IndifyToPartner_Accept_Mutation>(graphql`
      mutation ClaimYourAccountFlow_IndifyToPartner_Accept_Mutation(
        $input: IndifyToPartnerReferralAcceptInput!
      ) {
        indifyToPartnerReferralAccept(data: $input) {
          me {
            ...useAuth_MeFragment
          }
        }
      }
    `);
  const [commitPartnerToPartnerReferral] =
    useMutation<ClaimYourAccountFlow_PartnerToPartner_Accept_Mutation>(graphql`
      mutation ClaimYourAccountFlow_PartnerToPartner_Accept_Mutation(
        $input: PartnerToPartnerReferralAcceptInput!
      ) {
        partnerToPartnerReferralAccept(data: $input) {
          me {
            ...useAuth_MeFragment
          }
        }
      }
    `);
  const [commitPartnerToArtistReferral] =
    useMutation<ClaimYourAccountFlow_PartnerToArtist_Accept_Mutation>(graphql`
      mutation ClaimYourAccountFlow_PartnerToArtist_Accept_Mutation(
        $input: PartnerToArtistReferralAcceptInput!
      ) {
        partnerToArtistReferralAccept(data: $input) {
          me {
            ...useAuth_MeFragment
          }
        }
      }
    `);

  const onSubmit = (values: FormValues) => {
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });
      const resolveErrors = (errors: PayloadError[]) => {
        resolve({ [FORM_ERROR]: errors.map(e => e.message).join("\n") });
      };

      switch (data.referralBySlug.__typename) {
        case "PartnerToCompanyPartnerReferralNode": {
          const {
            distroWorkflow, // extract distroWorkflow form value out since we're only using it for frontend validation
            ...rest
          } = values as IPartnerToCompanyPartnerReferralFlowFormValues;
          commitPartnerToCompanyPartnerReferral({
            variables: {
              input: {
                ...rest
              }
            },
            onCompleted: (response, errors) => {
              if (errors) {
                resolveErrors(errors);
              }

              setMeFragmentRef(
                response.partnerToCompanyPartnerReferralAccept.me
              );
              resolve(undefined);
            },
            onError: () => {
              resolveUnknownError();
            }
          });
          break;
        }
        case "IndifyToArtistReferralNode": {
          const {
            genre,
            spotifyConfirm, // extract spotifyConfirm form value out since we're only using it for frontend validation
            distroWorkflow, // extract distroWorkflow form value out since we're only using it for frontend validation
            ...rest
          } = values as IIndifyToArtistReferralFlowFormValues;
          commitIndifyToArtistReferral({
            variables: {
              input: {
                genre: genre?.value,
                ...rest
              }
            },
            onCompleted: (response, errors) => {
              if (errors) {
                resolveErrors(errors);
              }

              setMeFragmentRef(response.indifyToArtistReferralAccept.me);
              resolve(undefined);
            },
            onError: () => {
              resolveUnknownError();
            }
          });
          break;
        }
        case "IndifyToPartnerReferralNode": {
          const {
            websiteUrl,
            company: { label: companyName, value: companyId },
            distroWorkflow, // extract distroWorkflow form value out since we're only using it for frontend validation
            ...rest
          } = values as IIndifyToPartnerReferralFlowFormValues;
          commitIndifyToPartnerReferral({
            variables: {
              input: {
                company: {
                  id: companyId,
                  name: companyName,
                  websiteUrl
                },
                ...rest
              }
            },
            onCompleted: (response, errors) => {
              if (errors) {
                resolveErrors(errors);
              }

              setMeFragmentRef(response.indifyToPartnerReferralAccept.me);
              resolve(undefined);
            },
            onError: () => {
              resolveUnknownError();
            }
          });
          break;
        }
        case "PartnerToPartnerReferralNode": {
          const {
            websiteUrl,
            company: { label: companyName, value: companyId },
            distroWorkflow, // extract distroWorkflow form value out since we're only using it for frontend validation
            ...rest
          } = values as IIndifyToPartnerReferralFlowFormValues;
          commitPartnerToPartnerReferral({
            variables: {
              input: {
                company: {
                  id: companyId,
                  name: companyName,
                  websiteUrl
                },
                ...rest
              }
            },
            onCompleted: (response, errors) => {
              if (errors) {
                resolveErrors(errors);
              }

              setMeFragmentRef(response.partnerToPartnerReferralAccept.me);
              resolve(undefined);
            },
            onError: () => {
              resolveUnknownError();
            }
          });
          break;
        }
        case "PartnerToArtistReferralNode": {
          const {
            genre,
            spotifyConfirm, // extract spotifyConfirm form value out since we're only using it for frontend validation
            distroWorkflow, // extract distroWorkflow form value out since we're only using it for frontend validation
            ...rest
          } = values as IIndifyToArtistReferralFlowFormValues;
          commitPartnerToArtistReferral({
            variables: {
              input: {
                genre: genre?.value,
                ...rest
              }
            },
            onCompleted: (response, errors) => {
              if (errors) {
                resolveErrors(errors);
              }

              setMeFragmentRef(response.partnerToArtistReferralAccept.me);
              resolve(undefined);
            },
            onError: () => {
              resolveUnknownError();
            }
          });
          break;
        }
        default:
          throw new Error("Unsupported referral type");
      }
    });
  };

  const initialValues = (() => {
    switch (data.referralBySlug.__typename) {
      case "IndifyToArtistReferralNode":
      case "PartnerToArtistReferralNode":
        return {
          genre: { value: null as string },
          [Visible]: true
        };
      case "IndifyToPartnerReferralNode":
        return data.referralBySlug.company?.id
          ? { company: { value: data.referralBySlug.company.id, label: "" } }
          : undefined;
      default:
        return undefined;
    }
  })();

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ width: "100%", justifyContent: "center" }}
    >
      <FixedGrid>
        <Form<FormValues> onSubmit={onSubmit} initialValues={initialValues}>
          {formProps => {
            if (formProps.submitSucceeded) {
              const recipientIsArtist =
                data.referralBySlug.__typename ==
                  "IndifyToArtistReferralNode" ||
                data.referralBySlug.__typename == "PartnerToArtistReferralNode";
              return (
                <Redirect
                  to={recipientIsArtist ? InternalRoutes.artistTutorial : "/"}
                />
              );
            }

            const { current, total } = navStateSubject.value;
            const percent = ((current + 1) / total) * 100;

            return (
              <GridItem
                sx={{ flexDirection: "column" }}
                gridColumn={[
                  "1 / span 4",
                  "1 / span 8",
                  "1 / span 8",
                  "3 / span 8"
                ]}
                mt={[32, 32, 64, 64]}
              >
                <HiddenFieldValue<FormValues, "referral">
                  field={"referral"}
                  value={data.referralBySlug.id}
                />
                <BannerSwitch referral={data.referralBySlug} />
                <ProgressBar percent={percent} />
                <AutoLayout
                  spacing={48}
                  resizingX={Fixed("auto")}
                  dependentProps={{
                    direction: "vertical"
                  }}
                  bg={"white100"}
                  p={["16px", "32px", "88px", "88px"]}
                  sx={{
                    border: "1px solid",
                    borderColor: "midGray70"
                  }}
                >
                  <ReferralFlowSwitch
                    referral={data.referralBySlug}
                    navState={navStateSubject}
                    navRequests={navRequestSubject}
                  />
                  {formProps.submitError && (
                    <Text variant={"bodyMedium"} color={"alert100"}>
                      There was an error submitting the form.
                    </Text>
                  )}
                  <ClaimYourAccountFlowFooter
                    formRenderProps={formProps}
                    navState={navStateSubject}
                    navRequests={navRequestSubject}
                  />
                </AutoLayout>
                <HelperText />
              </GridItem>
            );
          }}
        </Form>
      </FixedGrid>
    </GridItem>
  );
}

export const ClaimYourAccountFlowQuery = graphql`
  query ClaimYourAccountFlowQuery($slug: String!) {
    referralBySlug(slug: $slug) {
      __typename
      ... on ReferralInterface {
        id
        state
      }
      ... on IndifyToPartnerReferralNode {
        company {
          id
        }
      }
      ...ReferralFlowSwitch_referral
      ...BannerSwitch_banner
    }
  }
`;

export default ClaimYourAccountFlow;
