import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import { Box, Flex, Text } from "theme-ui";

import termLengthSchema from "../../../../../../../schemas/v1-0-0/dealTerms/termLength.json";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import { InputCaption } from "../../../../../components/01_Core/Forms/Input";
import NumberInput from "../../../../../components/01_Core/Forms/Inputs/NumberInput";
import getHasError from "../../../../../components/01_Core/Forms/utils/getHasError";
import useSchemaValidation from "../../../../../components/01_Core/Forms/utils/useSchemaValidation";
import { useFindActiveRV } from "../../../../../utils/responsiveUtils";
import { formatNumeral } from "../../../../../utils/utils";
import { useAuth } from "../../../hooks/useAuth";
import {
  IFundingOfferFormFieldProps,
  IFundingOfferFormValues
} from "../../07_Deal_Flow/OfferFormFunding";
import FormSection, { FormSectionSize } from "./FormSection";
import OfferFormGuidance from "./OfferFormGuidance";
import { getStreamsToRecoup } from "./OfferFormSplits/OfferFormSplits";
import { getTermLengthWarning } from "./OfferTermsWarningBlock";

export const termLengthFieldName = "termLength";
type TermLengthType = number;

export interface IOfferFormTermLengthProps extends IFundingOfferFormFieldProps {
  isRequired: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=704%3A802
 */
function OfferFormTermLength(props: IOfferFormTermLengthProps): ReactElement {
  const validator = useSchemaValidation<TermLengthType>(
    termLengthSchema,
    props.isRequired
  );
  const hasError = getHasError<IFundingOfferFormValues>(
    props.showAllErrors,
    "termLength"
  );
  const labelVariant = useFindActiveRV(props.size, getLabelVariant);

  const formState = useFormState<IFundingOfferFormValues>();
  const termLengthWarning = getTermLengthWarning(
    formState.values.termLength,
    useAuth().authUser.userType
  );

  const streamsToRecoup = getStreamsToRecoup();
  const termLengthDays = Math.round(
    (formState.values.termLength ?? 0) * 30.4167
  );
  const streamsPerDayToRecoupDuringTermLength =
    streamsToRecoup / termLengthDays;

  return (
    <FormSection
      heading={"Term Length"}
      headingTooltip={
        "The number of months after all content has been released that this deal will remain in effect, whether or not the advance has been recouped."
      }
      error={hasError ? "Please specify a term length." : null}
      warning={{ type: "warning", children: termLengthWarning }}
      size={props.size}
    >
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        <Box sx={{ width: "72px" }}>
          <FieldBuilder
            fieldName={termLengthFieldName}
            inputField={NumberInput}
            inputFieldProps={{
              placeholder: "0",
              size: props.size,
              pl: "26px"
            }}
            validator={validator}
            suppressValidationText={true}
          />
        </Box>
        <Text variant={labelVariant} color={"black100"} mx={"12px"}>
          months
        </Text>
      </Flex>
      <InputCaption
        size={props.size}
        state={"normal"}
        caption={
          "The amount of time this agreement will be in effect for all releases included."
        }
      />
      {!!streamsToRecoup && !!termLengthDays && (
        <Box mt={"16px"} sx={{ width: "100%" }}>
          <OfferFormGuidance>
            To recoup during the term length, this deal would need to generate{" "}
            <strong>
              {formatNumeral(streamsPerDayToRecoupDuringTermLength)} streams per
              day
            </strong>{" "}
            on average.
          </OfferFormGuidance>
        </Box>
      )}
    </FormSection>
  );
}

function getLabelVariant(size: FormSectionSize) {
  switch (size) {
    case "small":
      return "bodySmall";
    case "medium":
      return "bodyMedium";
  }
}

export default OfferFormTermLength;
