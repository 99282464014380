// TODO: Remove explicit usages of black100/primary100 most places since html color is black by default
export default {
  indifyRed: "#ef220d",
  black100: "#000000",
  gray60: "#A1A1A1",
  gray10: "#EAEAEA",
  deepGray100: "#747474",
  deepGray70: "#909090",
  midGray100: "#C7C7C7",
  midGray70: "#E1E1E1",
  lightGray100: "#F9F9F9",
  lightGray95: "#F4F4F5",
  white100: "#FFFFFF",
  white70: "rgba(255, 255, 255, .7)",
  white40: "rgba(255, 255, 255, .4)",
  primary100: "#000000",
  primary85: "#262626",
  primary20: "#91ADFA",
  secondary100: "#0645CC",
  secondary85: "#5082EB",
  secondary20: "#D7E1FE",
  secondary10: "#EAF0FD",
  secondary00: "#F5F7FC",
  success100: "#086343",
  success50: "#008556",
  success05: "#D6F3E2",
  warning100: "#A64F21",
  warning50: "#E86825",
  warning05: "#FFE1BE",
  alert100: "#9F1B1F",
  alert50: "#DE1C22",
  alert05: "#F9D3D4",
  upwards100: "#008F2C",
  downwards100: "#C70000"
};
