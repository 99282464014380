import { ReactElement } from "react";

import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { requiredStringValidator } from "../../../../../components/01_Core/Forms/utils/validators";

export const displayNameFieldName = "displayName";

function DisplayName(): ReactElement {
  return (
    <FieldBuilder
      inputField={TextInput}
      inputFieldProps={{
        // TODO: Placeholder?
        size: ["medium", "large", "large", "large"],
        label: "Display name"
      }}
      fieldName={displayNameFieldName}
      validator={requiredStringValidator}
    />
  );
}

export default DisplayName;
