/**
 * @generated SignedSource<<2d0acee12e0187ef7b214f67cf8cfca4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistInfo_artist$data = {
  readonly about: string | null;
  readonly displayName: string;
  readonly featuredDeals: {
    readonly edges: ReadonlyArray<{
      readonly __typename: "FeaturedDealNodeEdge";
    } | null>;
  };
  readonly imageUrl: string;
  readonly profiles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"SocialLinks_profiles">;
      } | null;
    } | null>;
  };
  readonly spotifyApiId: string | null;
  readonly spotifyProfile: {
    readonly imageUrl: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistAttributes_artist" | "FeaturedDeals_artist">;
  readonly " $fragmentType": "ArtistInfo_artist";
};
export type ArtistInfo_artist$key = {
  readonly " $data"?: ArtistInfo_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistInfo_artist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistInfo_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spotifyApiId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "spotifyProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "about",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeaturedDealNodeConnection",
      "kind": "LinkedField",
      "name": "featuredDeals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeaturedDealNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNodeConnection",
      "kind": "LinkedField",
      "name": "profiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SocialLinks_profiles"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArtistAttributes_artist"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeaturedDeals_artist"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};
})();

(node as any).hash = "11e83a00a2e0e0e296cab4c08fe29219";

export default node;
