import { ActionMakeOffer_chat$key } from "__generated__/ActionMakeOffer_chat.graphql";
import { mutationsOfferButtonSendMutation } from "__generated__/mutationsOfferButtonSendMutation.graphql";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import { Fragment, ReactElement } from "react";
import {
  commitMutation,
  ConnectionHandler,
  graphql,
  useFragment
} from "react-relay";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import Ic20FileAdd from "../../../../../../imgs/icons/ic20-file-add.svg";
import Button from "../../../../../components/01_Core/Buttons/Button";
import environment from "../../../../../environment";
import { sendOfferMutation } from "../../../../../mutations/mutations";
import MarketplaceAccessGate from "../../../../../utils/MarketplaceAccessGate";
import { IFundingOfferFormValues } from "../../07_Deal_Flow/OfferFormFunding";
import FullScreenModal from "../Modals/FullScreenModal";
import OfferForm from "../Offers/OfferForm";

export interface IActionMakeOfferProps {
  chat: ActionMakeOffer_chat$key;
  disabled?: boolean;
}

export interface IActionMakeOfferLocationState {
  isMakingOffer: boolean;
  initialValues?: Partial<IFundingOfferFormValues>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=741%3A22122
 */
function ActionMakeOffer(props: IActionMakeOfferProps): ReactElement {
  const chat = useFragment(
    graphql`
      fragment ActionMakeOffer_chat on ChatNode {
        id
        partnerUser {
          partner {
            ...OfferForm_partner
          }
        }
        artistUser {
          artist {
            displayName
            ...OfferForm_artist
          }
        }
      }
    `,
    props.chat
  );
  const location = useLocation<IActionMakeOfferLocationState>();
  const history = useHistory();
  const isMakingOffer = location.state?.isMakingOffer ?? false;
  const setIsMakingOffer = (isOpen: boolean) => {
    history.replace(generatePath(location.pathname), { isMakingOffer: isOpen });
  };
  const openModal = () => setIsMakingOffer(true);
  const closeModal = () => {
    setIsMakingOffer(false);
  };
  const sendOffer = ({
    artistId,
    partnerId,
    companyId,
    legalName,
    signature,
    willTransferCreativeAdvance,
    willTransferMarketingAdvance,
    allReleases,
    soundRecordingIds,
    releaseHasIncludedTracks: _skip,
    ...terms
  }: IFundingOfferFormValues) => {
    // Occasionally advance terms get set as null which trips the json schema validation.
    // If they are null, set them to 0 to prevent the issue.
    // Ideally we're able to prevent null field values further upstream, but this works for now.
    if (terms.advanceAmountCents === null) {
      terms.advanceAmountCents = 0;
    }
    if (terms.marketingAmountCents === null) {
      terms.marketingAmountCents = 0;
    }
    if (terms.creativeAmountCents === null) {
      terms.creativeAmountCents = 0;
    }

    return new Promise(resolve => {
      const connectionId = ConnectionHandler.getConnectionID(
        chat.id,
        "ChatThread_messages"
      );
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });
      commitMutation<mutationsOfferButtonSendMutation>(environment, {
        mutation: sendOfferMutation,
        variables: {
          data: {
            chatNodeId: chat.id,
            artistNodeId: artistId,
            partnerNodeId: partnerId,
            companyNodeId: companyId,
            allReleases: allReleases.map(
              ({
                tempId: _skip,
                hasExistingPartnership: _skip1,
                hasExistingInvestmentPartnership: _skip2,
                ...rest
              }) => rest
            ),
            soundRecordingIds: Object.keys(
              _.pickBy(soundRecordingIds, Boolean)
            ),
            terms: JSON.stringify(terms),
            partnerLegalName: legalName,
            partnerSignature: signature,
            willTransferCreativeAdvance,
            willTransferMarketingAdvance
          },
          connections: [connectionId]
        },
        onCompleted: (response, errors) => {
          if (errors) return resolveUnknownError();
          closeModal();
          resolve(undefined);
        },
        onError: () => {
          resolveUnknownError();
        }
      });
    });
  };

  return (
    <Fragment>
      <FullScreenModal isOpen={isMakingOffer} onClose={closeModal}>
        <OfferForm
          sendOffer={sendOffer}
          closeModal={closeModal}
          artist={chat.artistUser.artist}
          partner={chat.partnerUser.partner}
        />
      </FullScreenModal>
      <MarketplaceAccessGate>
        <Button
          size={"small"}
          variant={"secondary"}
          disabled={props.disabled || undefined}
          iconProps={{ icon: <Ic20FileAdd />, placement: "left" }}
          onClick={openModal}
        >
          {`Make ${chat.artistUser.artist.displayName} an offer`}
        </Button>
      </MarketplaceAccessGate>
    </Fragment>
  );
}

export default ActionMakeOffer;
