import {
  FeaturedDealDirectoryPaginationQuery$variables,
  FeaturedDealSpotifyMonthlyListenersRange,
  FeaturedDealStreamsThisWeekRange,
  FeaturedDealTiktokFollowersRange,
  FeaturedDealTiktokPostsRange
} from "__generated__/FeaturedDealDirectoryPaginationQuery.graphql";
import _ from "lodash";
import { ReactElement, useState } from "react";
import { ResponsiveValue } from "styled-system";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import ColumnHeader, {
  IColumnHeaderProps
} from "../../../../components/01_Core/Tables/ColumnHeader";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { arrayOfAll } from "../../../../utils/utils";
import { useStaticData } from "../../hooks/useStaticData";
import { SocialIconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";
import FilterAccordion, { FilterAccordionSize } from "./FilterAccordion";
import FilterRangesWithHeader from "./FilterRangesWithHeader";

type FilterRangeOptions = Pick<
  FeaturedDealDirectoryPaginationQuery$variables,
  | "releasesCount"
  | "streamsThisWeek"
  | "spotifyMonthlyListeners"
  | "tiktokFollowers"
  | "tiktokPosts"
>;

type GenreOption = {
  label: string;
  value: string;
};
export type IFeaturedDealsFilterForm = { genres: GenreOption[] } & {
  [T in keyof FilterRangeOptions]: {
    [key in FilterRangeOptions[T][number]]: boolean;
  };
};

// These need to be declared because you can't iterate over an enum type directly
const FILTER_RANGE_OPTIONS: FilterRangeOptions = {
  releasesCount: [
    // Omit option for 0 releases
    // "_0_0",
    "_1_1",
    "_2_2",
    "_3_3",
    "_4_inf"
  ],
  streamsThisWeek: arrayOfAll<FeaturedDealStreamsThisWeekRange>()([
    "_0_99999",
    "_100000_749999",
    "_750000_1999999",
    "_2000000_inf"
  ]),
  spotifyMonthlyListeners:
    arrayOfAll<FeaturedDealSpotifyMonthlyListenersRange>()([
      "_0_99999",
      "_100000_749999",
      "_750000_1999999",
      "_2000000_inf"
    ]),
  tiktokPosts: arrayOfAll<FeaturedDealTiktokPostsRange>()([
    "_0_2499",
    "_2500_9999",
    "_10000_49999",
    "_50000_inf"
  ]),
  tiktokFollowers: arrayOfAll<FeaturedDealTiktokFollowersRange>()([
    "_0_9999",
    "_10000_99999",
    "_100000_499999",
    "_500000_inf"
  ])
};

export default function FeaturedDealDirectoryFilters(props: {
  size: ResponsiveValue<FilterAccordionSize>;
}): ReactElement {
  const genreOptions: GenreOption[] = _.sortBy(
    useStaticData().genres,
    g => g.genre
  ).map(genre => ({
    label: genre.genre,
    value: genre.id
  }));

  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

  const activeSize = useActiveResponsiveValue(props.size);
  const gridTemplateAreas = (() => {
    switch (activeSize) {
      case "small":
        return `
          "gn gn rl rl"
          "tp tp tf tf"
          "ml ml st st"
        `;
      case "medium":
      case "large":
        return `
          "gn gn gn gn tp tp tf tf"
          "rl rl rl rl ml ml st st"
        `;
      case "extraLarge":
        return `
          "gn gn gn gn tp tp tp tp tf tf tf tf"
          "rl rl rl rl ml ml ml ml st st st st"
        `;
      default:
        activeSize satisfies never;
    }
  })();

  const labels: Record<keyof IFeaturedDealsFilterForm, ReactElement | string> =
    {
      genres: "Genre",
      releasesCount: "Release Count",
      tiktokPosts: <SocialIconLabel platform="TIKTOK">UGCs</SocialIconLabel>,
      tiktokFollowers: (
        <SocialIconLabel platform="TIKTOK">Followers</SocialIconLabel>
      ),
      spotifyMonthlyListeners: (
        <SocialIconLabel platform="SPOTIFY">Monthly Listeners</SocialIconLabel>
      ),
      streamsThisWeek: "Streams This Week"
    };

  const columnHeaderProps: Pick<
    IColumnHeaderProps,
    "align" | "size" | "state" | "styles"
  > = {
    align: "left",
    size: "small",
    state: "default",
    styles: { ":hover": {} }
  };

  const filterRangesWithHeaderProps = {
    columnHeaderProps,
    settings: FILTER_RANGE_OPTIONS
  };

  return (
    <FilterAccordion<IFeaturedDealsFilterForm>
      size={props.size}
      labels={labels}
      accordionOpen={accordionOpen}
      setAccordionOpen={setAccordionOpen}
      styles={{ borderBottom: "unset" }}
    >
      <FixedGrid
        sx={{
          gridTemplateAreas,
          width: "100%",
          borderTop: "1px solid",
          borderColor: "midGray70",
          paddingBottom: "16px"
        }}
      >
        <GridItem
          gridArea={"gn"}
          sx={{
            paddingLeft: ["large", "extraLarge"].includes(activeSize)
              ? "16px"
              : "0px"
          }}
        >
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={4}
            resizingX={FillContainer()}
          >
            <ColumnHeader {...columnHeaderProps} divider={false}>
              {labels.genres}
            </ColumnHeader>
            <Field name="genres">
              {({ input, ...fieldProps }) => {
                return (
                  <SelectInput<(typeof genreOptions)[0]>
                    {...fieldProps}
                    size={"small"}
                    options={genreOptions}
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    input={input}
                    menuIsOpen={accordionOpen ? undefined : false}
                  />
                );
              }}
            </Field>
          </AutoLayout>
        </GridItem>
        <GridItem
          gridArea={"rl"}
          sx={{
            paddingLeft: ["large", "extraLarge"].includes(activeSize)
              ? "16px"
              : "0px"
          }}
        >
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={labels.releasesCount}
            settingName="releasesCount"
          />
        </GridItem>
        <GridItem gridArea={"tp"}>
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={labels.tiktokPosts}
            settingName="tiktokPosts"
          />
        </GridItem>
        <GridItem gridArea={"tf"}>
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={labels.tiktokFollowers}
            settingName="tiktokFollowers"
          />
        </GridItem>
        <GridItem gridArea={"ml"}>
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={labels.spotifyMonthlyListeners}
            settingName="spotifyMonthlyListeners"
          />
        </GridItem>
        <GridItem gridArea={"st"}>
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={labels.streamsThisWeek}
            settingName="streamsThisWeek"
          />
        </GridItem>
      </FixedGrid>
    </FilterAccordion>
  );
}
