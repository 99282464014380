import { ReactElement, ReactNode } from "react";
import { Box, Link as ExternalLink, Text } from "theme-ui";

import Ic16Info from "../../../../../../../imgs/icons/ic16-info.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import { ExternalRoutes } from "../../../../Routing/ExternalRoutes";
import { OfferTermsWarningBlock } from "../../Offers/OfferTermsWarningBlock";

type OfferMessageDefinitionsSize = "small" | "medium";
interface IOfferMessageDefinitionsProps {
  size: OfferMessageDefinitionsSize;
  definition: ReactNode;
  showLinkToStandardDealTerms?: boolean;
  warning?: {
    type: Parameters<typeof OfferTermsWarningBlock>[0]["type"];
    children: ReactNode;
  };
}

function OfferMessageDefinitions(
  props: IOfferMessageDefinitionsProps
): ReactElement {
  const getPx = (size: OfferMessageDefinitionsSize) => {
    switch (size) {
      case "medium":
        return 24;
      case "small":
        return 16;
    }
  };

  return (
    <AutoLayout
      spacing={8}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
      py={"8px"}
      bg={"lightGray100"}
    >
      <AutoLayout
        spacing={8}
        dependentProps={{ direction: "horizontal" }}
        px={`${getPx(props.size)}px`}
      >
        <Box sx={{ flexShrink: 0, width: "16px" }} color={"deepGray70"}>
          <Ic16Info color={"inherit"} />
        </Box>
        <AutoLayout spacing={8} dependentProps={{ direction: "vertical" }}>
          <Text variant={"secondary.bodySmall"}>{props.definition}</Text>
          {props.showLinkToStandardDealTerms && (
            <Text variant={"secondary.bodySmall"}>
              <ExternalLink
                href={ExternalRoutes.standardDealTerms}
                target={"_blank"}
                sx={{
                  fontWeight: "bold",
                  textDecoration: "underline"
                }}
              >
                Check out our guide to standard deal terms.
              </ExternalLink>
            </Text>
          )}
        </AutoLayout>
      </AutoLayout>
      {props.warning?.children && (
        <Box paddingX={`${getPx(props.size) - 8}px`} sx={{ width: "100%" }}>
          <OfferTermsWarningBlock type={props.warning.type} size={"small"}>
            {props.warning.children}
          </OfferTermsWarningBlock>
        </Box>
      )}
    </AutoLayout>
  );
}

export default OfferMessageDefinitions;
