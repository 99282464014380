/**
 * @generated SignedSource<<f9bdf58d0ccffbb1c60156cc823b10ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useUserCanViewChats_authUser$data = {
  readonly chats: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "ChatNode";
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "useUserCanViewChats_authUser";
};
export type useUserCanViewChats_authUser$key = {
  readonly " $data"?: useUserCanViewChats_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUserCanViewChats_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useUserCanViewChats_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatNodeConnection",
      "kind": "LinkedField",
      "name": "chats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "ea039591667ac39abfa9c71d15d0e00f";

export default node;
