import { ReactElement } from "react";
import { Text } from "theme-ui";

import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { useField } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import {
  composeFieldValidators,
  FieldValidator,
  requiredStringValidator,
  slugValidator
} from "../../../../../components/01_Core/Forms/utils/validators";

export const slugFieldName = "slug";

function Slug(props: { kind: "artist" | "partner" | "company" }): ReactElement {
  const { input, meta } = useField(slugFieldName);
  const slugFieldValueDisplay = input.value == null ? "" : input.value;
  const label =
    props.kind === "company" ? "Company profile URL" : "Profile URL";
  const breadcrumb =
    props.kind === "artist"
      ? "artists"
      : props.kind === "partner"
      ? "partners"
      : "companies";

  const validator: FieldValidator<string | null> = composeFieldValidators(
    requiredStringValidator,
    slugValidator
  );

  return (
    <FieldBuilder
      fieldName={slugFieldName}
      inputField={TextInput}
      validator={validator}
      inputFieldProps={{
        size: ["medium", "large", "large", "large"],
        label,
        ...(meta.touched && meta.error != null
          ? {}
          : {
              caption: (
                <Text variant={"bodyMedium"} color={"deepGray100"}>
                  Your indify URL: https://app.indify.io/{breadcrumb}/
                  <Text
                    variant={"bodyMedium"}
                    color={"inherit"}
                    sx={{ display: "inline" }}
                  >
                    <strong>{slugFieldValueDisplay}</strong>
                  </Text>
                </Text>
              )
            })
      }}
    />
  );
}

export default Slug;
