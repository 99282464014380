/**
 * @generated SignedSource<<241670580e430ab08f181378ad08e113>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActivateFormUserFromActivationKeyQuery$variables = {
  activationKey?: string | null;
};
export type ActivateFormUserFromActivationKeyQuery$data = {
  readonly userByActivationKey: {
    readonly id: string;
  } | null;
};
export type ActivateFormUserFromActivationKeyQuery = {
  response: ActivateFormUserFromActivationKeyQuery$data;
  variables: ActivateFormUserFromActivationKeyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "activationKey"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "activationKey",
        "variableName": "activationKey"
      }
    ],
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "userByActivationKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivateFormUserFromActivationKeyQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivateFormUserFromActivationKeyQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "946ab5ea7a62c3dac04ccb91031b9cae",
    "id": null,
    "metadata": {},
    "name": "ActivateFormUserFromActivationKeyQuery",
    "operationKind": "query",
    "text": "query ActivateFormUserFromActivationKeyQuery(\n  $activationKey: String\n) {\n  userByActivationKey(activationKey: $activationKey) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f76ad0f5446c6e8f61f1d55ac286ed75";

export default node;
