import { ChangeEvent, forwardRef, ReactElement } from "react";
import {
  Textarea as ThemeUITextarea,
  TextareaProps as ThemeUITextAreaProps
} from "theme-ui";

import Input, { IInputElementProps, InputProps } from "../Input";

function TextareaInput(
  props: InputProps<HTMLTextAreaElement, string | null>
): ReactElement {
  return (
    <Input
      InputComponent={BaseTextareaInput}
      {...props}
      isTextareaComponent={true}
    />
  );
}

const BaseTextareaInput = forwardRef<
  HTMLTextAreaElement,
  IInputElementProps<HTMLTextAreaElement, string | null> & ThemeUITextAreaProps
>((props, ref) => {
  const {
    input: { value, onChange, ...inputRest },
    meta: _skip,
    ...rest
  } = props;

  const serializingOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const v: string = e.target.value;
    onChange(v === "" ? null : v);
  };

  const serializedValue = value?.toString();

  return (
    <ThemeUITextarea
      {...rest}
      {...inputRest}
      ref={ref}
      value={serializedValue}
      onChange={serializingOnChange}
      sx={{
        resize: "none",
        ...rest.sx
      }}
    />
  );
});

export default TextareaInput;
