import { PrivateRoute_authUser$key } from "__generated__/PrivateRoute_authUser.graphql";
import { UserType } from "__generated__/useAuth_MeFragment.graphql";
import {
  configureScope as configureSentryScope,
  setUser as setSentryUser
} from "@sentry/react";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Redirect, RouteProps } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import useSubscribedUser from "../hooks/useSubscribedUser";
import { InternalRoutes } from "./InternalRoutes";
import { SentryRoute } from "./Routing";

interface IPrivateRouteProps extends RouteProps {
  forUserType?: UserType;
  requiresSubscriptionForUserTypes?: UserType[];
  requiresMarketplaceAccessForUserTypes?: UserType[];
}

function PrivateRoute(props: IPrivateRouteProps): ReactElement {
  const authUser = useFragment<PrivateRoute_authUser$key>(
    graphql`
      fragment PrivateRoute_authUser on UserNode {
        id
        email
        userType
        name
        canAccessMarketplace
      }
    `,
    useAuth().authUser
  );
  const subscribedUser = useSubscribedUser();

  if (!authUser) {
    configureSentryScope(scope => scope.setUser(null));

    return (
      <Redirect
        to={{
          pathname: InternalRoutes.login,
          state: { from: location.pathname }
        }}
      />
    );
  }

  setSentryUser({
    id: authUser.id,
    email: authUser.email,
    username: authUser.name
  });

  const failsUserTypeCheck =
    props.forUserType && authUser.userType !== props.forUserType;
  const failsSubscriptionCheck =
    props.requiresSubscriptionForUserTypes &&
    props.requiresSubscriptionForUserTypes.includes(authUser.userType) &&
    !subscribedUser;
  const failsMarketplaceAccessCheck =
    props.requiresMarketplaceAccessForUserTypes &&
    props.requiresMarketplaceAccessForUserTypes.includes(authUser.userType) &&
    !authUser.canAccessMarketplace;

  if (
    failsUserTypeCheck ||
    failsSubscriptionCheck ||
    failsMarketplaceAccessCheck
  ) {
    return <Redirect to={"/"} />;
  }

  return <SentryRoute {...props} />;
}

export default PrivateRoute;
