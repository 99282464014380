import { UserInfo_authUser$key } from "__generated__/UserInfo_authUser.graphql";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, useThemeUI } from "theme-ui";

import Ic12ArrowDown from "../../../../../../../../imgs/icons/ic12-arrow-down.svg";
import AutoLayout from "../../../../../../../components/01_Core/AutoLayout";
import AuthUserAvatarName from "../../../../../../../components/01_Core/Users/AuthUserAvatarName";
import { ActorAvatarIcon } from "../../../../../../../components/01_Core/Users/AvatarIcon";
import { rvMap } from "../../../../../../../utils/responsiveUtils";
import { useBreakpoint } from "../../../../../../../utils/useBreakpoints";
import { useAuth } from "../../../../../hooks/useAuth";
import UserNav from "../../UserNav";
import { InboxSize } from "./Inbox";

export type UserInfoSize = "small" | "large";

interface IUserInfoProps {
  size: ResponsiveValue<UserInfoSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=212%3A2078
 */
function UserInfo(props: IUserInfoProps): ReactElement {
  const authUser = useFragment<UserInfo_authUser$key>(
    graphql`
      fragment UserInfo_authUser on UserNode {
        actor {
          ...AvatarIcon_actor
        }
      }
    `,
    useAuth().authUser
  );
  const { theme } = useThemeUI();

  const breakpointMatchMap = useBreakpoint();
  const useSmallNav =
    breakpointMatchMap["mobile"] || breakpointMatchMap["tablet"];

  const [isUserNavOpen, setIsUserNavOpen] = useState(false);
  const toggleIsUserNavOpen = () => setIsUserNavOpen(!isUserNavOpen);
  const openUserNav = () => {
    setIsUserNavOpen(true);
  };
  const closeUserNav = () => setIsUserNavOpen(false);
  const onMouseEnter = () => {
    if (useSmallNav) {
      return;
    }

    openUserNav();
  };
  const onMouseLeave = () => {
    if (useSmallNav) {
      return;
    }

    closeUserNav();
  };

  return (
    <Box
      onClick={toggleIsUserNavOpen}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{ position: "relative" }}
    >
      <AutoLayout
        spacing={16}
        dependentProps={{
          direction: "horizontal",
          alignment: "center"
        }}
        padding={"4px 8px"}
        sx={{
          borderRadius: "4px",
          cursor: "pointer",
          border: "1px solid",
          borderColor: isUserNavOpen ? "deepGray100" : "midGray70",
          height: rvMap(props.size, getHeight),
          ":hover": {
            borderColor: "deepGray100"
          },
          transition: "border-color 0.2s"
        }}
      >
        {useSmallNav ? (
          <ActorAvatarIcon
            actor={authUser.actor}
            size={"small"}
            disableActiveDot
          />
        ) : (
          <AuthUserAvatarName size={"small"} />
        )}
        <Ic12ArrowDown color={theme.colors.black100 as string} />
      </AutoLayout>
      {isUserNavOpen && (
        <UserNav close={closeUserNav} useSmallNav={useSmallNav} />
      )}
    </Box>
  );
}

function getHeight(size: InboxSize) {
  switch (size) {
    case "small":
      return "40px";
    case "large":
      return "44px";
  }
}

export default UserInfo;
