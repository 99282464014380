import {
  ProfilePlatform,
  SocialLinks_profiles$key
} from "__generated__/SocialLinks_profiles.graphql";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { Link as ExternalLink } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { getPlatformIcons } from "../../../../utils/getPlatformIcons";

const PLATFORMS = GLOBALS.PLATFORMS;

interface ISocialLinksProps {
  profiles: SocialLinks_profiles$key;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=1047%3A18201
 */
function SocialLinks(props: ISocialLinksProps): ReactElement {
  const profiles = useFragment(
    graphql`
      fragment SocialLinks_profiles on ProfileNode @relay(plural: true) {
        id
        url
        platform
      }
    `,
    props.profiles
  );

  return (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "vertical", alignment: "center" }}
      resizingX={FillContainer()}
    >
      <Divider />
      {!!profiles.length && (
        <AutoLayout
          spacing={[16, 16, 24, 24]}
          dependentProps={{ direction: "horizontal", alignment: "center" }}
          sx={{ flexWrap: "wrap" }}
        >
          {profiles
            .filter(p => PLATFORMS[p.platform].regexes.profile)
            .map(p => (
              <PlatformIcon
                key={p.id}
                url={p.url}
                platform={p.platform}
                name={PLATFORMS[p.platform].displayName}
              />
            ))}
        </AutoLayout>
      )}
      {!!profiles.length && <Divider />}
    </AutoLayout>
  );
}

function PlatformIcon(props: {
  platform: ProfilePlatform;
  name: string;
  url: string;
}): ReactElement {
  const [, [IconMedium]] = getPlatformIcons(props.platform);

  const httpFormattedUrl = `${/^http/i.test(props.url) ? "" : "https://"}${
    props.url
  }`;

  return (
    <ExternalLink
      href={httpFormattedUrl}
      target={"_blank"}
      sx={{ marginY: "8px" }}
    >
      <TextButton
        size={"medium"}
        type={"secondary"}
        disabled={false}
        iconProps={{
          icon: <IconMedium />,
          placement: "left"
        }}
      >
        {props.name}
      </TextButton>
    </ExternalLink>
  );
}

export default SocialLinks;
