import { RowDeal_dropdown$key } from "__generated__/RowDeal_dropdown.graphql";
import { RowDeal_featuredDeal$key } from "__generated__/RowDeal_featuredDeal.graphql";
import {
  RowDeal_useTikTokSoundsTotal$data,
  RowDeal_useTikTokSoundsTotal$key
} from "__generated__/RowDeal_useTikTokSoundsTotal.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { ReactElement, ReactNode, useContext } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Box, Link as ExternalLink, Text, ThemeUIStyleObject } from "theme-ui";

import Ic20Copy from "../../../../../../imgs/icons/ic20-copy.svg";
import Ic20Email from "../../../../../../imgs/icons/ic20-email.svg";
import Ic20MoreVer from "../../../../../../imgs/icons/ic20-more-ver.svg";
import Ic24PlayCircle from "../../../../../../imgs/icons/ic24-play-circle.svg";
import { BannerContext } from "../../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../../components/01_Core/Buttons/TextButton";
import Dropdown from "../../../../../components/01_Core/Dropdown_Menus/Dropdown";
import { IconBox } from "../../../../../components/01_Core/Foundations/Icons";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import getToggleWatchFeaturedDeal from "../../../../../mutations/ToggleWatchFeaturedDealMutation";
import MarketplaceAccessGate from "../../../../../utils/MarketplaceAccessGate";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { formatNumeral, lineClampStyles } from "../../../../../utils/utils";
import useFeaturedDealTitle from "../../../hooks/useFeaturedDealTitle";
import { useMessageActor } from "../../../hooks/useMessage";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import { IndifysTakeHeader } from "../Deals/SpecialDeal";
import StatDelta from "../User_Directory/StatDelta";
import { getBookmarkElement } from "./BookmarkIcon";
import { OfferDealArtistReleases } from "./OfferDealArtistReleases";
import RowCell from "./RowCell";
import { SocialIconLabel } from "./RowHeaderDeal";

dayjs.extend(relativeTime);

type RowDealSize = "small" | "medium" | "large";

interface IRowDealProps {
  featuredDeal: RowDeal_featuredDeal$key;
  size: ResponsiveValue<RowDealSize>;
}

export const formatStat = (stat?: number): string => {
  return stat == null ? "—" : stat.toLocaleString();
};

export function actionIconSx(disabled = false): ThemeUIStyleObject {
  if (disabled) {
    return {
      cursor: "not-allowed",
      color: "midGray70"
    };
  } else {
    return {
      cursor: "pointer",
      color: "deepGray100",
      ":hover": { color: "black100" },
      transition: "color 0.2s"
    };
  }
}

export function actionBorderSx(disabled = false): ThemeUIStyleObject {
  if (disabled) {
    return {
      cursor: "not-allowed",
      borderColor: "midGray70"
    };
  } else {
    return {
      cursor: "pointer",
      borderColor: "deepGray100",
      ":hover": { borderColor: "black100" },
      transition: "border-color 0.2s"
    };
  }
}

export function DealDropdown(props: {
  featuredDeal: RowDeal_dropdown$key;
  hideMessageArtist?: boolean;
}): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment RowDeal_dropdown on FeaturedDealNode {
        id
        watching
        editorialNote
        tiktokSounds {
          ...RowDeal_useTikTokSoundsTotal
        }
        artist {
          ...useMessage_actor
          displayName
          latestSpotifyMonthlyListenerCount {
            current
            percentChange
          }
        }
        ...useFeaturedDealTitle
      }
    `,
    props.featuredDeal
  );

  const notifyBanner = useContext(BannerContext);
  const [messageArtist, _isInFlight] = useMessageActor(featuredDeal.artist);
  const BookmarkElement = getBookmarkElement(featuredDeal.watching);

  const featuredDealTitle = useFeaturedDealTitle(featuredDeal, false);
  const { currentTotal: tiktokSoundsTotal } = useTikTokSoundsTotal(
    featuredDeal.tiktokSounds
  );
  const monthlyListenersStat =
    featuredDeal.artist.latestSpotifyMonthlyListenerCount?.current;

  const statPhrases = [
    tiktokSoundsTotal && `${formatStat(tiktokSoundsTotal)} TikTok UGCs`,
    monthlyListenersStat &&
      `${formatStat(monthlyListenersStat)} Spotify monthly listeners`
  ].filter(x => x);

  const clipboardTextLines = [
    `${
      featuredDeal.artist.displayName
    } is seeking partners for ${featuredDealTitle.join("")}`,
    statPhrases.length ? `Key stats: ${statPhrases.join(" & ")}` : null,
    featuredDeal.editorialNote ?? null,
    `${window.location.origin}${generatePath(InternalRoutes.featuredDeal, {
      dealId: featuredDeal.id
    })}`
  ];

  const clipboardText = clipboardTextLines.filter(x => x).join("\r\n");

  return (
    <Dropdown
      triggerElement={
        <IconBox sx={{ ...actionIconSx(), size: 20 }} icon={Ic20MoreVer} />
      }
      openDirection={"left"}
      styles={{ top: 38, width: 270 }}
    >
      {!props.hideMessageArtist && (
        <MarketplaceAccessGate>
          <TextButton
            size={"medium"}
            type={"secondary"}
            iconProps={{ placement: "left", icon: <Ic20Email /> }}
            onClick={messageArtist}
          >
            Message artist
          </TextButton>
        </MarketplaceAccessGate>
      )}
      <TextButton
        size={"medium"}
        type={"secondary"}
        iconProps={{
          placement: "left",
          icon: <BookmarkElement />
        }}
        onClick={getToggleWatchFeaturedDeal(featuredDeal.id)}
      >
        {`${featuredDeal.watching ? "Unwatch" : "Watch"} deal`}
      </TextButton>
      <TextButton
        size={"medium"}
        type={"secondary"}
        iconProps={{
          placement: "left",
          icon: <Ic20Copy />
        }}
        onClick={() =>
          navigator.clipboard.writeText(clipboardText).then(() => {
            notifyBanner({
              type: "neutral",
              children: "Deal proposal link copied to clipboard."
            });
          })
        }
      >
        Copy proposal link
      </TextButton>
    </Dropdown>
  );
}

export function useTikTokSoundsTotal(
  tiktokSoundsHaver: RowDeal_useTikTokSoundsTotal$key
): {
  withHighestValue: RowDeal_useTikTokSoundsTotal$data["edges"][number]["node"];
  currentTotal: number;
  totalPercentChange: number;
} {
  const obj = useFragment(
    graphql`
      fragment RowDeal_useTikTokSoundsTotal on TikTokSoundNodeConnection {
        edges {
          node {
            tiktokUrl
            name
            mostRecentStat {
              current
              previous
              percentChange
            }
          }
        }
      }
    `,
    tiktokSoundsHaver
  );

  const tikTokSounds = obj.edges.map(({ node }) => node);
  const tikTokSoundsWithPrevious = tikTokSounds.filter(
    ({ mostRecentStat }) => mostRecentStat?.previous != null
  );

  const tikTokSoundsWithMostRecentStat = tikTokSounds.filter(
    ({ mostRecentStat }) => mostRecentStat?.current
  );

  const currentTotal = tikTokSoundsWithMostRecentStat.length
    ? _.sum(
        tikTokSoundsWithMostRecentStat.map(
          ({ mostRecentStat }) => mostRecentStat?.current
        )
      )
    : null;
  const previousTotal = tikTokSoundsWithPrevious.length
    ? _.sum(
        tikTokSoundsWithPrevious.map(
          ({ mostRecentStat }) => mostRecentStat?.previous
        )
      )
    : null;
  const totalPercentChange =
    currentTotal && previousTotal
      ? 100 * ((currentTotal - previousTotal) / previousTotal)
      : null;

  return {
    withHighestValue: _.maxBy(
      tikTokSounds,
      ({ mostRecentStat }) => mostRecentStat?.current ?? 0
    ),
    currentTotal,
    totalPercentChange
  };
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2619%3A19627
 */
function RowDeal(props: IRowDealProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment RowDeal_featuredDeal on FeaturedDealNode {
        ...RowDeal_dropdown
        ...OfferDealArtistReleases_query
        id
        artist {
          id
          displayName
          latestSpotifyMonthlyListenerCount {
            current
            percentChange
          }
          isWatchingCurrentUserCompany
          spotifyProfile {
            url
          }
          latestTiktokFollowerCount {
            current
            percentChange
          }
          tiktokProfile {
            url
          }
        }
        lastApprovedAt
        watching
        soundLink
        editorialNote
        tiktokSounds {
          ...RowDeal_useTikTokSoundsTotal
        }
      }
    `,
    props.featuredDeal
  );

  const activeSize = useActiveResponsiveValue(props.size);

  const gridTemplateAreas = (() => {
    switch (activeSize) {
      case "small":
        return `
          "ar ar ar fa"
          "tp tf ml ml"
        `;
      case "medium":
        return `"ar ar ar tp tf ml ml fa"`;
      case "large":
        return `"ar ar ar ar tp tp tf tf ml ml da fa"`;
    }
  })();

  const tikTokSounds = useTikTokSoundsTotal(featuredDeal.tiktokSounds);
  const monthlyListenersStat =
    featuredDeal.artist.latestSpotifyMonthlyListenerCount?.current;

  const soundLink =
    featuredDeal.soundLink ?? tikTokSounds.withHighestValue?.tiktokUrl;

  const formatStatWithUnit = (
    stat: number,
    percentChange: number,
    url: string,
    platform: Parameters<typeof SocialIconLabel>[0]["platform"],
    label: string
  ): ReactNode => {
    const statFormatted =
      activeSize === "small" ? formatNumeral(stat, true) : formatStat(stat);

    const statDisplay =
      activeSize === "small" ? (
        stat == null ? null : (
          <SocialIconLabel platform={platform}>
            <Text sx={lineClampStyles(2)}>
              {statFormatted} {label}
            </Text>
          </SocialIconLabel>
        )
      ) : stat == null ? (
        "—"
      ) : (
        statFormatted
      );

    return (
      <RowCell align={activeSize !== "medium" ? "left" : "right"}>
        <ExternalLink href={url} target={"_blank"}>
          <AutoLayout
            spacing={4}
            dependentProps={{ direction: "vertical" }}
            sx={{ paddingBottom: activeSize === "small" ? "12px" : 0 }}
          >
            <Text
              variant={activeSize === "small" ? "bodySmall" : "bodyMedium"}
              color={"deepGray100"}
              sx={{ textAlign: activeSize !== "medium" ? "left" : "right" }}
            >
              {statDisplay}
            </Text>
            <Box sx={{ alignSelf: "center" }}>
              {percentChange != null && (
                <StatDelta
                  size={["small", "small", "large", "large"]}
                  delta={percentChange}
                />
              )}
            </Box>
          </AutoLayout>
        </ExternalLink>
      </RowCell>
    );
  };

  return (
    <Box sx={{ backgroundColor: "white", width: "100%" }}>
      <FixedGrid sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"ar"}>
          <RowCell
            styles={{ paddingLeft: activeSize === "small" ? 0 : "16px" }}
          >
            <OfferDealArtistReleases
              query={featuredDeal}
              link={generatePath(InternalRoutes.featuredDeal, {
                dealId: featuredDeal.id
              })}
            />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"tp"}>
          {formatStatWithUnit(
            tikTokSounds.currentTotal,
            tikTokSounds.totalPercentChange,
            tikTokSounds.withHighestValue?.tiktokUrl,
            "TIKTOK",
            "UGCs"
          )}
        </GridItem>
        <GridItem gridArea={"tf"}>
          {formatStatWithUnit(
            featuredDeal.artist.latestTiktokFollowerCount?.current,
            featuredDeal.artist.latestTiktokFollowerCount?.percentChange,
            featuredDeal.artist.tiktokProfile?.url,
            "TIKTOK",
            "followers"
          )}
        </GridItem>
        <GridItem gridArea={"ml"}>
          {formatStatWithUnit(
            monthlyListenersStat,
            featuredDeal.artist.latestSpotifyMonthlyListenerCount
              ?.percentChange,
            featuredDeal.artist.spotifyProfile?.url,
            "SPOTIFY",
            "monthly listeners"
          )}
        </GridItem>
        {activeSize === "large" && (
          <GridItem gridArea={"da"}>
            <RowCell align={"right"}>
              <Text
                variant={"bodyMedium"}
                color={"deepGray100"}
                sx={{ textAlign: "right" }}
              >
                {dayjs()
                  .to(dayjs(featuredDeal.lastApprovedAt))
                  .replace(" ago", "")}
              </Text>
            </RowCell>
          </GridItem>
        )}
        <GridItem gridArea={"fa"}>
          <RowCell align={"right"} styles={{ width: "100%" }}>
            <AutoLayout
              spacing={16}
              dependentProps={{ direction: "horizontal" }}
              sx={{
                justifyContent: "flex-end",
                paddingRight: activeSize === "small" ? 0 : "4px",
                alignItems: "center"
              }}
            >
              <ExternalLink href={soundLink} target={"_blank"}>
                <IconBox
                  sx={{ ...actionIconSx(!soundLink), size: 32 }}
                  icon={Ic24PlayCircle}
                />
              </ExternalLink>
              <DealDropdown featuredDeal={featuredDeal} />
            </AutoLayout>
          </RowCell>
        </GridItem>
      </FixedGrid>
      {featuredDeal.editorialNote && (
        <Box
          sx={{
            paddingX: activeSize === "small" ? "16px" : "32px",
            paddingBottom: "16px"
          }}
        >
          <AutoLayout
            spacing={2}
            dependentProps={{ direction: "vertical" }}
            sx={{ width: "100%" }}
          >
            <IndifysTakeHeader />
            <Text
              variant={"bodyMedium"}
              color={"black100"}
              sx={lineClampStyles(4)}
            >
              {featuredDeal.editorialNote}
            </Text>
          </AutoLayout>
        </Box>
      )}
      <Divider />
    </Box>
  );
}

export default RowDeal;
