import dayjs from "dayjs";
import numeral from "numeral";
import { ReactElement, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from "recharts";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import Ic16ArrowLeft from "../../../../../imgs/icons/ic16-arrow-left.svg";
import Ic16ArrowRight from "../../../../../imgs/icons/ic16-arrow-right.svg";
import AutoLayout, {
  AutoLayoutDependentProps,
  Direction,
  Distribution,
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import { formatDollars } from "../../../../utils/money";
import { rvMap, useFindActiveRV } from "../../../../utils/responsiveUtils";
import { RecoupmentProgressBar } from "./RecoupmentProgressBar";

type EarningsSectionSize = "small" | "medium" | "large";
type IMonthlyEarnings = { month: string; earnings: number }[];
// type IPartnerPayouts = { id: string; date: Date; amount: number }[];

interface IEarningsSectionProps {
  totalInvested: number;
  monthlyEarnings: IMonthlyEarnings;
  totalMonthlyEarnings: number;
  size: ResponsiveValue<EarningsSectionSize>;
}

function chunk(arr: IMonthlyEarnings, chunkSize: number) {
  if (arr.length === 0 || arr.length === chunkSize) {
    return [arr];
  }

  const chunks = [];

  // start from the end of the array so the first element of the chunked array
  // contains the last {chunkSize} elements, which are the most recent earnings
  for (let i = arr.length; i >= 0; i -= chunkSize)
    chunks.push(arr.slice(Math.max(i - chunkSize, 0), i));
  return chunks;
}

function EarningsSection(props: IEarningsSectionProps): ReactElement {
  const monthlyEarningsChunks = chunk(props.monthlyEarnings, 12);
  const numChunks = monthlyEarningsChunks.length;
  const [selectedChunk, setSelectedChunk] = useState<number>(0);

  const CustomTooltip = ({
    active,
    payload,
    label
  }: TooltipProps<any, any>) => {
    if (!active || !payload || !payload.length) {
      return null;
    }

    return (
      <AutoLayout
        spacing={8}
        dependentProps={{ direction: "vertical" }}
        sx={{ backgroundColor: "white100" }}
        p={3}
      >
        <Text variant={"bodyMedium"} color={"black100"}>
          {dayjs(label).format("MMMM YYYY")}
        </Text>
        <Text variant={"h300"} color={"black100"}>
          ${formatDollars(payload[0].value)}
        </Text>
      </AutoLayout>
    );
  };

  const PrevButton = () => (
    <TextButton
      onClick={() => {
        if (selectedChunk < numChunks - 1) {
          setSelectedChunk(selectedChunk + 1);
        }
      }}
      size={"small"}
      type={"primary"}
      iconProps={{
        placement: "left",
        icon: <Ic16ArrowLeft />
      }}
      disabled={selectedChunk === numChunks - 1}
    >
      {"Prev"}
    </TextButton>
  );

  const NextButton = () => (
    <TextButton
      onClick={() => {
        if (selectedChunk > 0) {
          setSelectedChunk(selectedChunk - 1);
        }
      }}
      size={"small"}
      type={"primary"}
      iconProps={{
        placement: "right",
        icon: <Ic16ArrowRight />
      }}
      disabled={selectedChunk === 0}
    >
      {"Next"}
    </TextButton>
  );

  return (
    <AutoLayout
      spacing={12}
      dependentProps={rvMap(props.size, getDependentProps)}
      resizingX={FillContainer()}
    >
      <AutoLayout
        spacing={8}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical", alignment: "left" }}
      >
        <Text variant={"h400"} color={"black100"} mb={48}>
          Earnings
        </Text>
        {props.monthlyEarnings.length === 0 ? (
          <Flex
            sx={{
              width: "100%",
              height: "300px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              backgroundColor: "midGray70"
            }}
          >
            <Text variant={"h300"} color={"deepGray100"}>
              No earnings to show
            </Text>
          </Flex>
        ) : (
          <ResponsiveContainer width={"100%"} height={300}>
            <BarChart
              data={monthlyEarningsChunks[selectedChunk]}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3" />
              <XAxis
                dataKey="month"
                tickFormatter={v => dayjs(v).format("MMM")}
              />
              <YAxis
                type="number"
                tickFormatter={v => (v > 0 ? numeral(v).format("0a") : "")}
                domain={[0, "dataMax"]}
                width={40}
                interval={0}
              />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<CustomTooltip />}
              />
              <Bar
                dataKey="earnings"
                barSize={useFindActiveRV(props.size, getBarSize)}
                fill="#000000"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        <AutoLayout
          spacing={0}
          resizingX={FillContainer()}
          dependentProps={{
            direction: "horizontal",
            distribution: "spaceBetween"
          }}
          mb={rvMap(props.size, getMarginBottom)}
          px={"16px"}
        >
          <PrevButton />
          <NextButton />
        </AutoLayout>
      </AutoLayout>
      <AutoLayout
        spacing={32}
        dependentProps={{ direction: "vertical", alignment: "left" }}
        p={16}
        sx={{
          backgroundColor: "white100",
          border: "1px solid",
          borderColor: "midGray100",
          borderRadius: "4px",
          height: "min-content",
          width: rvMap(props.size, getWidth),
          flexShrink: 0
        }}
      >
        <AutoLayout
          spacing={4}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <Text variant={"h400"} color={"upwards100"}>
            ${formatDollars(props.totalMonthlyEarnings)}
          </Text>
          <Text variant={"bodySmall"} color={"black100"}>
            {" "}
            recouped of ${formatDollars(props.totalInvested)} total invested
          </Text>
          <RecoupmentProgressBar
            totalMonthlyEarnings={props.totalMonthlyEarnings}
            totalInvested={props.totalInvested}
            mt={18}
          />
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
}

function getDependentProps(
  size: EarningsSectionSize
): AutoLayoutDependentProps<Direction, Distribution> {
  switch (size) {
    case "small":
      return {
        direction: "vertical",
        alignment: "center"
      };
    case "medium":
    case "large":
      return { direction: "horizontal", alignment: "top" };
  }
}

function getWidth(size: EarningsSectionSize) {
  switch (size) {
    case "small":
      return "100%";
    case "medium":
    case "large":
      return "300px";
  }
}

function getMarginBottom(size: EarningsSectionSize) {
  switch (size) {
    case "small":
      return "24px";
    case "medium":
    case "large":
      return "0";
  }
}

function getBarSize(size: EarningsSectionSize) {
  switch (size) {
    case "small":
    case "medium":
      return 12;
    case "large":
      return 16;
  }
}

export default EarningsSection;
