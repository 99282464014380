/**
 * @generated SignedSource<<c4887eeeff7380be104fa0286354ccfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActionItemPlatformPlatform = "INSTAGRAM" | "STREAMING" | "TIKTOK";
import { FragmentRefs } from "relay-runtime";
export type HomebaseGuidance_homebase$data = {
  readonly actionItems: ReadonlyArray<{
    readonly checked: boolean | null;
    readonly id: string;
    readonly platforms: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly platform: ActionItemPlatformPlatform;
        } | null;
      } | null>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"HomebaseGuidance_actionItem">;
  } | null> | null;
  readonly " $fragmentType": "HomebaseGuidance_homebase";
};
export type HomebaseGuidance_homebase$key = {
  readonly " $data"?: HomebaseGuidance_homebase$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebaseGuidance_homebase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebaseGuidance_homebase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActionItemNode",
      "kind": "LinkedField",
      "name": "actionItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActionItemPlatformNodeConnection",
          "kind": "LinkedField",
          "name": "platforms",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ActionItemPlatformNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ActionItemPlatformNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "platform",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HomebaseGuidance_actionItem"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HomebaseNode",
  "abstractKey": null
};

(node as any).hash = "3ce1825e22779ac284119d496581e371";

export default node;
