import { ToggleWatchArtistMutation } from "__generated__/ToggleWatchArtistMutation.graphql";
import { commitMutation, graphql } from "react-relay";

import environment from "../environment";
import { NodeId } from "../types/relay_types";

const toggleWatchArtistMutation = graphql`
  mutation ToggleWatchArtistMutation($artistNodeId: String!) {
    toggleWatchArtist(artistNodeId: $artistNodeId) {
      artist {
        watching
      }
    }
  }
`;

const getToggleWatchArtist = (artistNodeId: NodeId) => (): void => {
  const variables = {
    artistNodeId
  };
  commitMutation<ToggleWatchArtistMutation>(environment, {
    mutation: toggleWatchArtistMutation,
    variables
  });
};

export default getToggleWatchArtist;
