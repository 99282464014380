import { UserListCompanyTeam_company$key } from "__generated__/UserListCompanyTeam_company.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box } from "theme-ui";

import ReferralRecipientActive from "../03_UI_Kit/User_Directory/ReferralRecipient/ReferralRecipientActive";
import ReferralRecipientInvited from "../03_UI_Kit/User_Directory/ReferralRecipient/ReferralRecipientInvited";

interface IUserListCompanyTeamProps {
  company: UserListCompanyTeam_company$key;
}

/**
 * TODO: filter to only display referrals of status: sent
 *
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1670%3A14380
 */
function UserListCompanyTeam(props: IUserListCompanyTeamProps): ReactElement {
  const company = useFragment(
    graphql`
      fragment UserListCompanyTeam_company on CompanyNode {
        partners {
          edges {
            node {
              id
              ...ReferralRecipientActive_recipient
            }
          }
        }
        partnerCompanyReferrals {
          edges {
            node {
              id
              ...ReferralRecipientInvited_referral
            }
          }
        }
      }
    `,
    props.company
  );

  return (
    <Box sx={{ width: "100%" }}>
      {company.partners.edges
        .map(e => e.node)
        .map(p => (
          <ReferralRecipientActive key={p.id} recipient={p} />
        ))}
      {company.partnerCompanyReferrals.edges
        .map(e => e.node)
        .map(r => (
          <ReferralRecipientInvited key={r.id} referral={r} />
        ))}
    </Box>
  );
}

export default UserListCompanyTeam;
