import { ReferralFlowSwitch_referral$key } from "__generated__/ReferralFlowSwitch_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { BehaviorSubject, Subject } from "rxjs";

import { INavState, NavigationRequest } from "./ClaimYourAccountFlow";
import IndifyToArtistReferralFlow from "./IndifyToArtistReferralFlow";
import IndifyToPartnerReferralFlow from "./IndifyToPartnerReferralFlow";
import PartnerToCompanyPartnerReferralFlow from "./PartnerToCompanyPartnerReferralFlow";

interface IReferralFlowSwitchProps {
  referral: ReferralFlowSwitch_referral$key;
  navState: BehaviorSubject<INavState>;
  navRequests: Subject<NavigationRequest>;
}

function ReferralFlowSwitch(props: IReferralFlowSwitchProps): ReactElement {
  const referral = useFragment(
    graphql`
      fragment ReferralFlowSwitch_referral on ReferralInterface {
        __typename
        ...IndifyToArtistReferralFlow_referral
        ...IndifyToPartnerReferralFlow_referral
        ...PartnerToCompanyPartnerReferralFlowFragment_referral
      }
    `,
    props.referral
  );

  const flowProps = {
    referral,
    navState: props.navState,
    navRequests: props.navRequests
  };

  switch (referral.__typename) {
    case "IndifyToArtistReferralNode":
    case "PartnerToArtistReferralNode":
      return <IndifyToArtistReferralFlow {...flowProps} />;
    case "IndifyToPartnerReferralNode":
    case "PartnerToPartnerReferralNode":
      return <IndifyToPartnerReferralFlow {...flowProps} />;
    case "PartnerToCompanyPartnerReferralNode":
      return <PartnerToCompanyPartnerReferralFlow {...flowProps} />;
    default:
      return <div>TODO: Implement flow</div>;
  }
}

export default ReferralFlowSwitch;
