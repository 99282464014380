import { ArtistPortfolioRowDeal_offer$key } from "__generated__/ArtistPortfolioRowDeal_offer.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import numeral from "numeral";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Text, useThemeUI } from "theme-ui";

import Ic20Check from "../../../../../imgs/icons/ic20-check.svg";
import Ic24UnfoldMore from "../../../../../imgs/icons/ic24-unfold-more.svg";
import AutoLayout from "../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import ReleaseIconsNames from "../../../../components/01_Core/Releases/ReleaseIconsNames";
import { ActorAvatarName } from "../../../../components/01_Core/Users/AvatarName";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { lineClampStyles } from "../../../../utils/utils";
import RowCell from "../03_UI_Kit/Discover/RowCell";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";
import PortfolioRowDeal, { expandCssId } from "./PortfolioRowDeal";
import { RecoupmentProgressBar } from "./RecoupmentProgressBar";

dayjs.extend(relativeTime);

interface IArtistPortfolioRowDealProps {
  offer: ArtistPortfolioRowDeal_offer$key;
  //   size: ResponsiveValue<ArtistPortfolioRowDealSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2619%3A19627
 */
export default function ArtistPortfolioRowDeal(
  props: IArtistPortfolioRowDealProps
): ReactElement {
  const { theme } = useThemeUI();

  //   const mediumGridTemplateAreas = `"pa pa pa rl rl rc cl dl"`; // 8
  const mediumGridTemplateAreas = `
    "pa pa pa pa pa cl cl ex"
    "rl rl rl st st rc rc ex"
  `;
  const gridTemplateAreas = useActiveResponsiveValue([
    `
      "pa pa cl ex"
      "rl rl rl ex"
      "st rc rc ex"
    `,
    mediumGridTemplateAreas,
    mediumGridTemplateAreas,
    `"pa pa pa rl rl rl st rc rc cl cl ex"` // 12
  ]);

  const offer = useFragment(
    graphql`
      fragment ArtistPortfolioRowDeal_offer on OfferNode {
        totalStreams
        monthlyEarnings {
          edges {
            node {
              partnerEarnings
              partnerExpenses
            }
          }
        }
        dateOfferAccepted
        partner {
          ...AvatarName_actor
        }
        ...ReleaseIconsNames_query
        ...PortfolioRowDeal_offer
      }
    `,
    props.offer
  );

  const totalStreams = offer.totalStreams;

  const monthlyEarnings = offer.monthlyEarnings.edges.map(({ node }) => node);
  const totalMonthlyEarnings = _.sumBy(monthlyEarnings, ({ partnerEarnings }) =>
    parseFloat(partnerEarnings as string)
  );
  const totalInvested =
    0 -
    _.sumBy(monthlyEarnings, ({ partnerExpenses }) =>
      parseFloat(partnerExpenses as string)
    );

  const totalProfit = totalMonthlyEarnings - totalInvested;
  const hasRecouped = totalProfit >= 0;

  return (
    <PortfolioRowDeal offer={offer}>
      <FixedGrid sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"rl"} gridTemplateAreas={gridTemplateAreas}>
          <RowCell styles={{ paddingLeft: [0, 0, "16px", 0] }}>
            <ReleaseIconsNames
              query={offer}
              size={"small"}
              textStyles={lineClampStyles(2)}
            />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"pa"} gridTemplateAreas={gridTemplateAreas}>
          <RowCell styles={{ paddingLeft: [0, 0, "16px", "16px"] }}>
            <ActorAvatarName actor={offer.partner} size={"small"} disableLink />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"st"} gridTemplateAreas={gridTemplateAreas}>
          <RowCell align={"left"}>
            <Text variant={"bodyMedium"} color={"black100"}>
              {numeral(totalStreams).format("0,0")} streams
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"cl"} gridTemplateAreas={gridTemplateAreas}>
          <RowCell align={"right"}>
            <Text
              variant={"bodySmall"}
              color={"deepGray100"}
              title={dayjs(offer.dateOfferAccepted).format("MMMM D, YYYY")}
            >
              Closed {dayjs().to(dayjs(offer.dateOfferAccepted))}
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"ex"}>
          <RowCell
            align="right"
            styles={{
              height: "100%",
              verticalAlign: "middle",
              paddingRight: [0, 0, "16px", "16px"]
            }}
          >
            <Text
              color={"midGray100"}
              id={expandCssId}
              sx={{ transition: "color 0.2s" }}
            >
              <IconLabel Icon={Ic24UnfoldMore} size="1.4em" />
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"rc"} gridTemplateAreas={gridTemplateAreas}>
          <RowCell styles={{ paddingRight: [0, 0, "16px", 0] }} align="center">
            {hasRecouped ? (
              <AutoLayout
                spacing={12}
                dependentProps={{ direction: "horizontal" }}
              >
                <Ic20Check color={theme.colors.upwards100 as string} />
                <Text variant={"bodyMedium"} color={"upwards100"}>
                  Recouped!
                </Text>
              </AutoLayout>
            ) : (
              <RecoupmentProgressBar
                totalMonthlyEarnings={totalMonthlyEarnings}
                totalInvested={totalInvested}
              />
            )}
          </RowCell>
        </GridItem>
      </FixedGrid>
      <Divider />
    </PortfolioRowDeal>
  );
}
