import { FeaturedDealPageQuery as FeaturedDealPageQueryType } from "__generated__/FeaturedDealPageQuery.graphql";
import { ReactElement } from "react";
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay";

import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import DealAnalysis from "./Sections/DealAnalysis/DealAnalysis";
import Details from "./Sections/Details";
import FeaturedDealTheSound from "./Sections/FeaturedDealTheSound";
import Hero from "./Sections/Hero";

interface IFeaturedDealPageProps {
  queryRef: PreloadedQuery<FeaturedDealPageQueryType>;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=549%3A22251
 */
function FeaturedDealPage(props: IFeaturedDealPageProps): ReactElement {
  const featuredDeal = usePreloadedQuery<FeaturedDealPageQueryType>(
    FeaturedDealPageQuery,
    props.queryRef
  ).featuredDeal;

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ flexDirection: "column", alignItems: "center" }}
    >
      <Hero featuredDeal={featuredDeal} />
      <Details featuredDeal={featuredDeal} />
      {!!featuredDeal.embedInfo && (
        <FeaturedDealTheSound
          featuredDeal={featuredDeal}
          size={["small", "small", "small", "large"]}
        />
      )}
      <DealAnalysis
        featuredDeal={featuredDeal}
        size={["small", "small", "small", "large"]}
      />
    </GridItem>
  );
}

export const FeaturedDealPageQuery = graphql`
  query FeaturedDealPageQuery($dealId: ID!) {
    featuredDeal(id: $dealId) {
      embedInfo
      ...Hero_FeaturedDeal
      ...Details_featuredDeal
      ...DealAnalysis_featuredDeal
      ...FeaturedDealTheSound_featuredDeal
    }
  }
`;

export default FeaturedDealPage;
