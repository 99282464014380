import { ReactElement, ReactNode } from "react";
import { useFormState } from "react-final-form";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import {
  getInputSpacing,
  getLabelVariant,
  InputSize
} from "../../../../../components/01_Core/Forms/Input";
import TextareaInput from "../../../../../components/01_Core/Forms/Inputs/TextareaInput";
import { FieldValidator } from "../../../../../components/01_Core/Forms/utils/validators";
import { rvMap, useFindActiveRV } from "../../../../../utils/responsiveUtils";
import { IEntityInfoValues } from "../AccountManagementPage";

export const aboutFieldName = "about";

function About(props: {
  label: ReactNode;
  validator?: FieldValidator<string | null>;
}): ReactElement {
  const formState = useFormState<IEntityInfoValues>();
  const characterCount = (formState.values.about ?? "").length;
  const size: ResponsiveValue<InputSize> = [
    "medium",
    "large",
    "large",
    "large"
  ];
  const labelVariant = useFindActiveRV(size, getLabelVariant);

  return (
    <AutoLayout
      spacing={rvMap(size, getInputSpacing)}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
    >
      <Flex sx={{ justifyContent: "space-between", width: "100%" }}>
        <Text variant={labelVariant} color={"black100"}>
          {props.label}
        </Text>
        <Text variant={"bodySmall"} mt={"8px"} color={"deepGray100"}>
          <Text
            variant={"bodySmall"}
            color={"inherit"}
            sx={{ display: "inline" }}
          >
            <strong>{characterCount}</strong>{" "}
          </Text>
          / 3000 character limit
        </Text>
      </Flex>
      <FieldBuilder
        inputField={TextareaInput}
        inputFieldProps={{
          placeholder: "Tell us about yourself...",
          size
        }}
        fieldName={aboutFieldName}
        validator={props.validator}
        showErrorsBeforeTouched={true}
      />
    </AutoLayout>
  );
}

export default About;
