import Big from "big.js";

import { Cent, Dollar } from "../types/money";

export function dollarsToCents(n: Dollar): Cent {
  return new Big(n).mul(100).round(0).toNumber();
}

export function centsToDollars(n: Cent): Dollar {
  return new Big(n).div(100).round(2).toNumber();
}

export function formatDollars(x: number | null, decimals = 2): string {
  if (x == null) {
    return "0";
  }

  const formattedWithDecimals = x.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });

  return x % 1 === 0
    ? formattedWithDecimals.slice(0, formattedWithDecimals.length - 3) // "10,000.00" becomes "10,000"
    : formattedWithDecimals;
}
