import { ImageUploadButtonQuery } from "__generated__/ImageUploadButtonQuery.graphql";
import { ImageUploadButtonSetImageUrlMutation } from "__generated__/ImageUploadButtonSetImageUrlMutation.graphql";
import {
  ChangeEvent,
  Fragment,
  MutableRefObject,
  ReactElement,
  SyntheticEvent,
  useRef
} from "react";
import { commitMutation, fetchQuery, graphql } from "react-relay";

import Button, {
  IButtonProps
} from "../../../../../components/01_Core/Buttons/Button";
import environment from "../../../../../environment";
import { NodeId } from "../../../../../types/relay_types";

interface IImageUploadButtonProps {
  nodeToUpdate: NodeId;
  size: IButtonProps["size"];
  children: string;
}

const getImageUploadQuery = graphql`
  query ImageUploadButtonQuery($contentType: String) {
    imageUploadUrl(contentType: $contentType) {
      publicUrl
      uploadUrl
    }
  }
`;

function getImageUploadUrl(
  contentType: string
): Promise<ImageUploadButtonQuery["response"]> {
  return fetchQuery<ImageUploadButtonQuery>(environment, getImageUploadQuery, {
    contentType
  }).toPromise();
}

const setImageUrlMutation = graphql`
  mutation ImageUploadButtonSetImageUrlMutation(
    $nodeId: String
    $publicUrl: String
  ) {
    setImageUrl(nodeId: $nodeId, publicUrl: $publicUrl) {
      imageInfo {
        id
        imageUrl
        thumbnailUrl
        blurhash
      }
    }
  }
`;

function setImageUrl(publicUrl: string, nodeId: NodeId): void {
  const variables = {
    publicUrl,
    nodeId
  };
  commitMutation<ImageUploadButtonSetImageUrlMutation>(environment, {
    variables,
    mutation: setImageUrlMutation
  });
}

function useImageUpload(nodeId: NodeId) {
  return (fileUploaded: File) => {
    getImageUploadUrl(fileUploaded.type).then(result => {
      return fetch(result.imageUploadUrl.uploadUrl, {
        method: "PUT",
        body: fileUploaded,
        headers: {
          "Content-Type": fileUploaded.type,
          "Content-Length": fileUploaded.size.toString()
        }
      }).then(() => setImageUrl(result.imageUploadUrl.publicUrl, nodeId));
    });
  };
}

function ImageUploadButton(props: IImageUploadButtonProps): ReactElement {
  const handleFile = useImageUpload(props.nodeToUpdate);

  // Create a reference to the hidden file input element
  const hiddenFileInput: MutableRefObject<HTMLInputElement> = useRef(null);

  // Programmatically click the hidden file input element when the Button component is clicked
  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded: File = event.target.files[0];
    if (fileUploaded === undefined) {
      return;
    }
    handleFile(fileUploaded);
  };

  return (
    <Fragment>
      <input
        type="file"
        accept=".png, .jpg, .jpeg"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }} /* Make the file input element invisible */
      />
      <Button
        variant={"tertiary"}
        onClick={handleClick}
        size={props.size}
        disabled={false}
      >
        {props.children}
      </Button>
    </Fragment>
  );
}

export default ImageUploadButton;
