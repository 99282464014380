import { useArtistCanMessageCompany_artist$key } from "__generated__/useArtistCanMessageCompany_artist.graphql";
import { useArtistCanMessageCompany_chat$key } from "__generated__/useArtistCanMessageCompany_chat.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { graphql, useFragment } from "react-relay";

dayjs.extend(relativeTime);

export function useArtistCanMessageCompany(
  artist: useArtistCanMessageCompany_artist$key,
  chat: useArtistCanMessageCompany_chat$key,
  explainCannotReply: boolean
): [boolean, string] {
  const { numIntrosSendable, nextIntroSendableAt } =
    useFragment<useArtistCanMessageCompany_artist$key>(
      graphql`
        fragment useArtistCanMessageCompany_artist on ArtistNode {
          numIntrosSendable
          nextIntroSendableAt
        }
      `,
      artist
    );

  const { partnerHasMessaged, artistHasMessaged } =
    useFragment<useArtistCanMessageCompany_chat$key>(
      graphql`
        fragment useArtistCanMessageCompany_chat on ChatNode {
          partnerHasMessaged
          artistHasMessaged
        }
      `,
      chat
    ) ?? { artistHasMessaged: false, partnerHasMessaged: false };

  if (partnerHasMessaged) {
    return [true, null];
  }

  const waitForReplyMessage =
    "You'll be able to continue the conversation with this company after they respond to your initial message.";
  if (artistHasMessaged) {
    return [false, waitForReplyMessage];
  }
  const canMessage = numIntrosSendable > 0;
  const explanation = canMessage
    ? [
        `You have ${numIntrosSendable} ${
          numIntrosSendable === 1 ? "initial message" : "initial messages"
        } left to send this month.`,
        explainCannotReply ? waitForReplyMessage : ""
      ].join(" ")
    : `You can send more initial messages in ${dayjs(
        nextIntroSendableAt
      ).fromNow(true)}.`;

  return [canMessage, explanation];
}
