/**
 * @generated SignedSource<<9ffc3578a3d508788496e246dffbdeac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ManageSubscription_updateStripePriceForUserMutation$variables = {
  billingPeriod: string;
};
export type ManageSubscription_updateStripePriceForUserMutation$data = {
  readonly updateStripePriceForUser: {
    readonly user: {
      readonly stripePriceId: string | null;
    } | null;
  } | null;
};
export type ManageSubscription_updateStripePriceForUserMutation = {
  response: ManageSubscription_updateStripePriceForUserMutation$data;
  variables: ManageSubscription_updateStripePriceForUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "billingPeriod"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "billingPeriod",
    "variableName": "billingPeriod"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripePriceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageSubscription_updateStripePriceForUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStripePriceForUserMutation",
        "kind": "LinkedField",
        "name": "updateStripePriceForUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageSubscription_updateStripePriceForUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStripePriceForUserMutation",
        "kind": "LinkedField",
        "name": "updateStripePriceForUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "adf2623cb1be90942846cb3cafd48455",
    "id": null,
    "metadata": {},
    "name": "ManageSubscription_updateStripePriceForUserMutation",
    "operationKind": "mutation",
    "text": "mutation ManageSubscription_updateStripePriceForUserMutation(\n  $billingPeriod: String!\n) {\n  updateStripePriceForUser(billingPeriod: $billingPeriod) {\n    user {\n      stripePriceId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ec673a830a300e75c3a325b6ec457ae";

export default node;
