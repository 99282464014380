/**
 * @generated SignedSource<<acf695069c1c587975a24b3b3231aee7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArtistPartnershipStatus = "ACTIVELY_LOOKING" | "NOT_LOOKING" | "OPEN";
export type ArtistSeekingServiceService = "CONTENT_PRODUCTION" | "DISTRIBUTION" | "FUNDING" | "INDUSTRY" | "INFLUENCER" | "MARKETING";
import { FragmentRefs } from "relay-runtime";
export type ArtistDirectoryCard_artist$data = {
  readonly displayName: string;
  readonly genre: {
    readonly genre: string;
  } | null;
  readonly imageUrl: string;
  readonly instagramProfile: {
    readonly url: string | null;
  } | null;
  readonly latestInstagramFollowerCount: {
    readonly current: number;
    readonly percentChange: number | null;
  } | null;
  readonly latestSpotifyFollowerCount: {
    readonly current: number;
    readonly percentChange: number | null;
  } | null;
  readonly latestSpotifyMonthlyListenerCount: {
    readonly current: number;
    readonly percentChange: number | null;
  } | null;
  readonly latestTiktokFollowerCount: {
    readonly current: number;
    readonly percentChange: number | null;
  } | null;
  readonly partnershipStatus: ArtistPartnershipStatus;
  readonly seekingServices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly service: ArtistSeekingServiceService;
      } | null;
    } | null>;
  };
  readonly slug: string;
  readonly spotifyProfile: {
    readonly imageUrl: string | null;
    readonly url: string | null;
  } | null;
  readonly stats: {
    readonly streamsThisWeek: number | null;
    readonly weeklyChange: number | null;
  } | null;
  readonly tiktokProfile: {
    readonly tiktokSounds: {
      readonly " $fragmentSpreads": FragmentRefs<"RowDeal_useTikTokSoundsTotal">;
    };
    readonly url: string | null;
  } | null;
  readonly user: {
    readonly lastActiveAt: any | null;
  } | null;
  readonly " $fragmentType": "ArtistDirectoryCard_artist";
};
export type ArtistDirectoryCard_artist$key = {
  readonly " $data"?: ArtistDirectoryCard_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistDirectoryCard_artist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "current",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentChange",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistDirectoryCard_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastActiveAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnershipStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistSeekingServiceNodeConnection",
      "kind": "LinkedField",
      "name": "seekingServices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistSeekingServiceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArtistSeekingServiceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "service",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GenreNode",
      "kind": "LinkedField",
      "name": "genre",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "genre",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistStatsObject",
      "kind": "LinkedField",
      "name": "stats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamsThisWeek",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weeklyChange",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StatWithDelta",
      "kind": "LinkedField",
      "name": "latestSpotifyMonthlyListenerCount",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StatWithDelta",
      "kind": "LinkedField",
      "name": "latestSpotifyFollowerCount",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "spotifyProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StatWithDelta",
      "kind": "LinkedField",
      "name": "latestTiktokFollowerCount",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "tiktokProfile",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TikTokSoundNodeConnection",
          "kind": "LinkedField",
          "name": "tiktokSounds",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RowDeal_useTikTokSoundsTotal"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StatWithDelta",
      "kind": "LinkedField",
      "name": "latestInstagramFollowerCount",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "instagramProfile",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};
})();

(node as any).hash = "702b2857c1f618e5173600453d5badea";

export default node;
