import { OfferMessageStatusUpdate_offer$key } from "__generated__/OfferMessageStatusUpdate_offer.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Text } from "theme-ui";

import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import { useAuth } from "../../../../hooks/useAuth";
import OfferNextStep from "../OfferNextStep/OfferNextStep";
import OfferNotification from "../OfferNotification";

type OfferMessageStatusUpdateEvent = "accepted" | "declined" | "canceled";
interface IOfferMessageStatusUpdateProps {
  event: OfferMessageStatusUpdateEvent;
  offer: OfferMessageStatusUpdate_offer$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?type=design&node-id=741%3A21181&t=epSM9Av8qyRaXuk1-1
 */
function OfferMessageStatusUpdate(
  props: IOfferMessageStatusUpdateProps
): ReactElement {
  const offer = useFragment(
    graphql`
      fragment OfferMessageStatusUpdate_offer on OfferNode {
        artist {
          displayName
        }
        partner {
          displayName
        }
        ...OfferNextStep_offer
      }
    `,
    props.offer
  );

  const userType = useAuth().authUser.userType;

  const formattedArtistName = <strong>{offer.artist.displayName}</strong>;
  const formattedPartnerName = <strong>{offer.partner.displayName}</strong>;

  function getMessage() {
    switch (props.event) {
      case "accepted":
        return userType === "artist" ? (
          <Text>
            You have accepted {formattedPartnerName}
            {"'"}s offer!
          </Text>
        ) : (
          <Text>{formattedArtistName} has accepted your offer!</Text>
        );
      case "declined":
        return userType === "artist" ? (
          <Text>
            You have declined {formattedPartnerName}
            {"'"}s offer.
          </Text>
        ) : (
          <Text>{formattedArtistName} has declined your offer.</Text>
        );
      case "canceled":
        return userType === "artist" ? (
          <Text>{formattedPartnerName} has canceled an offer to you.</Text>
        ) : (
          <Text>You have canceled your offer to {formattedArtistName}.</Text>
        );
    }
  }

  return (
    <OfferNotification>
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        sx={{ width: "100%" }}
      >
        <Text variant={"bodySmall"}>{getMessage()}</Text>
        {props.event === "accepted" && <OfferNextStep offer={offer} />}
      </AutoLayout>
    </OfferNotification>
  );
}

export default OfferMessageStatusUpdate;
