/**
 * @generated SignedSource<<07bd73e2570c49e0ff7b7c274f6259d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebaseTable_homebase$data = {
  readonly instagramFollowers: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly spotifyFollowers: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly tiktokFollowers: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly weeklyStreams: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "HomebaseTable_homebase";
};
export type HomebaseTable_homebase$key = {
  readonly " $data"?: HomebaseTable_homebase$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebaseTable_homebase">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "HomebaseTimeseriesNode",
    "kind": "LinkedField",
    "name": "timeseries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "x",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "y",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebaseTable_homebase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "instagramFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "tiktokFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "weeklyStreams",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "spotifyFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HomebaseNode",
  "abstractKey": null
};
})();

(node as any).hash = "34d5a4b8a5b57f4c3dbf9b74cb1631eb";

export default node;
