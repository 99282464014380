/**
 * @generated SignedSource<<0b2470b23952f0f8665990f09fd51645>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReferralTypename = "PartnerToArtistReferralNode" | "PartnerToCompanyPartnerReferralNode" | "PartnerToPartnerReferralNode";
export type ReferralCreateInput = {
  recipientEmail: string;
  typename: ReferralTypename;
};
export type ReferralInputMutation$variables = {
  input: ReferralCreateInput;
};
export type ReferralInputMutation$data = {
  readonly referralCreate: {
    readonly referral: {
      readonly company?: {
        readonly displayName: string;
        readonly id: string;
        readonly partnerCompanyReferrals: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null>;
        };
      } | null;
      readonly id: string;
      readonly recipientEmail: string;
      readonly sender: {
        readonly artistReferrals?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null>;
        };
        readonly partnerReferrals?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null>;
        };
        readonly partnerReferralsMaxAllowed?: number;
      } | null;
    } | null;
  } | null;
};
export type ReferralInputMutation = {
  response: ReferralInputMutation$data;
  variables: ReferralInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipientEmail",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnerReferralsMaxAllowed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerToPartnerReferralNodeConnection",
      "kind": "LinkedField",
      "name": "partnerReferrals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerToPartnerReferralNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PartnerToPartnerReferralNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerToArtistReferralNodeConnection",
      "kind": "LinkedField",
      "name": "artistReferrals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerToArtistReferralNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PartnerToArtistReferralNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PartnerNode",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyNode",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerToCompanyPartnerReferralNodeConnection",
          "kind": "LinkedField",
          "name": "partnerCompanyReferrals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PartnerToCompanyPartnerReferralNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PartnerToCompanyPartnerReferralNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PartnerToCompanyPartnerReferralNode",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferralInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferralCreate",
        "kind": "LinkedField",
        "name": "referralCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "referral",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sender",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReferralInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferralCreate",
        "kind": "LinkedField",
        "name": "referralCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "referral",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sender",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9df260eeb829162d4c7d903005f4c9c0",
    "id": null,
    "metadata": {},
    "name": "ReferralInputMutation",
    "operationKind": "mutation",
    "text": "mutation ReferralInputMutation(\n  $input: ReferralCreateInput!\n) {\n  referralCreate(data: $input) {\n    referral {\n      __typename\n      id\n      recipientEmail\n      sender {\n        __typename\n        ... on PartnerNode {\n          partnerReferralsMaxAllowed\n          partnerReferrals {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n          artistReferrals {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n        }\n        id\n      }\n      ... on PartnerToCompanyPartnerReferralNode {\n        company {\n          id\n          displayName\n          partnerCompanyReferrals {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05cd2ab30db27c6136e717df5d80c559";

export default node;
