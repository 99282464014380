/**
 * @generated SignedSource<<358c0789dfc16872281c843cc228f5a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductName = "artist" | "partner" | "partner_enterprise";
import { FragmentRefs } from "relay-runtime";
export type useSubscribedUser$data = {
  readonly availableProductName: ProductName;
  readonly partner: {
    readonly company: {
      readonly partners: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly user: {
              readonly availableProductName: ProductName;
              readonly " $fragmentSpreads": FragmentRefs<"ManageSubscription_subscribedUser">;
            } | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
  readonly stripePriceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ManageSubscription_subscribedUser">;
  readonly " $fragmentType": "useSubscribedUser";
};
export type useSubscribedUser$key = {
  readonly " $data"?: useSubscribedUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSubscribedUser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ManageSubscription_subscribedUser"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableProductName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSubscribedUser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripePriceId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyNode",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PartnerNodeConnection",
              "kind": "LinkedField",
              "name": "partners",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PartnerNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PartnerNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserNode",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "1f02e8aacca96a0d3f0c31c9b17f3048";

export default node;
