import color from "./color";

const {
  black100,
  deepGray70,
  midGray100,
  midGray70,
  white100,
  white40,
  primary100,
  primary85,
  alert100,
  alert50,
  alert05,
  success100,
  success50,
  success05
} = color;
export const buttonVariants: any = {
  primary: {
    color: white100,
    backgroundColor: primary100,
    border: `1px solid ${primary100}`,
    "&:hover": {
      color: white100,
      backgroundColor: primary85,
      border: `1px solid ${primary85}`
    },
    "&:disabled": {
      color: deepGray70,
      backgroundColor: midGray70,
      border: `1px solid ${midGray70}`
    }
  },
  secondary: {
    color: primary85,
    backgroundColor: white100,
    border: `1px solid ${primary85}`,
    "&:hover": {
      color: primary85,
      backgroundColor: white100,
      border: `1px solid ${primary85}`
    },
    "&:disabled": {
      color: deepGray70,
      backgroundColor: white100,
      border: `1px solid ${midGray100}`
    }
  },
  tertiary: {
    color: black100,
    backgroundColor: white100,
    border: `1px solid ${midGray100}`,
    "&:hover": {
      color: black100,
      backgroundColor: white100,
      border: `1px solid ${black100}`
    },
    "&:disabled": {
      color: deepGray70,
      backgroundColor: white100,
      border: `1px solid ${midGray100}`
    }
  },
  contrastSecondary: {
    color: white100,
    backgroundColor: black100,
    border: `1px solid ${white100}`,
    "&:hover": {
      color: white100,
      backgroundColor: white40,
      border: `1px solid ${white100}`
    },
    "&:disabled": {
      color: white40,
      backgroundColor: black100,
      border: `1px solid ${white40}`
    }
  },
  destructive: {
    primary: {
      color: white100,
      backgroundColor: alert50,
      border: `1px solid ${alert50}`,
      "&:hover": {
        color: white100,
        backgroundColor: alert100,
        border: `1px solid ${alert100}`
      },
      "&:disabled": {
        color: deepGray70,
        backgroundColor: midGray70,
        border: `1px solid ${midGray70}`
      }
    },
    secondary: {
      color: alert50,
      backgroundColor: white100,
      border: `1px solid ${alert50}`,
      "&:hover": {
        color: alert50,
        backgroundColor: alert05,
        border: `1px solid ${alert50}`
      },
      "&:disabled": {
        color: deepGray70,
        backgroundColor: white100,
        border: `1px solid ${midGray100}`
      }
    }
  },
  success: {
    primary: {
      color: white100,
      backgroundColor: success50,
      border: `1px solid ${success50}`,
      "&:hover": {
        color: white100,
        backgroundColor: success100,
        border: `1px solid ${success100}`
      },
      "&:disabled": {
        color: deepGray70,
        backgroundColor: midGray70,
        border: `1px solid ${midGray70}`
      }
    },
    secondary: {
      color: success50,
      backgroundColor: white100,
      border: `1px solid ${success50}`,
      "&:hover": {
        color: success50,
        backgroundColor: success05,
        border: `1px solid ${success50}`
      },
      "&:disabled": {
        color: deepGray70,
        backgroundColor: white100,
        border: `1px solid ${midGray100}`
      }
    }
  }
};
