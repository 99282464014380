import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import { getLabelVariant } from "../../../../../components/01_Core/Forms/Input";
import DisplayAvatar from "../../../../../components/01_Core/Users/DisplayAvatar";
import { NodeId } from "../../../../../types/relay_types";
import { useFindActiveRV } from "../../../../../utils/responsiveUtils";
import ImageUploadButton from "./ImageUploadButton";

type SectionProfilePhotoSize = "small" | "medium" | "large";
interface ISectionProfilePhotoProps {
  label?: string;
  caption?: string;
  ctaText: string;
  size: ResponsiveValue<SectionProfilePhotoSize>;
  thumbnailUrl: string;
  nodeId: NodeId;
  isCompanyImage?: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1396%3A24506
 */
function SectionProfilePhoto(props: ISectionProfilePhotoProps): ReactElement {
  const labelVariant = useFindActiveRV(props.size, getLabelVariant);

  return (
    <AutoLayout
      spacing={32}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical"
      }}
    >
      {props.label != null && (
        <Text variant={labelVariant} color={"black100"}>
          {props.label}
        </Text>
      )}
      <AutoLayout
        spacing={24}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical" }}
        sx={{ alignItems: "center" }}
      >
        <Box sx={{ flexShrink: 0 }}>
          <DisplayAvatar
            imageUrl={props.thumbnailUrl}
            size={"small"}
            type="roundedSquare"
          />
        </Box>
        <ImageUploadButton nodeToUpdate={props.nodeId} size={props.size}>
          {props.ctaText}
        </ImageUploadButton>
      </AutoLayout>
      {props.caption != null && (
        <Text variant={"bodyMedium"} color={"deepGray100"}>
          {props.caption}
        </Text>
      )}
    </AutoLayout>
  );
}

export default SectionProfilePhoto;
