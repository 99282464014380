import { Properties } from "csstype";
import { Children, DOMAttributes, ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, FlexProps } from "theme-ui";

import {
  rvMap,
  rvZip,
  useActiveResponsiveValue
} from "../../utils/responsiveUtils";

export type Direction = "horizontal" | "vertical";
export type Distribution = "packed" | "spaceBetween";
export type VerticalSpaceBetweenAlignment = "left" | "center" | "right";
export type HorizontalSpaceBetweenAlignment = "top" | "center" | "bottom";
export type PackedAlignment =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "left"
  | "center"
  | "right"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight";

export type AutoLayoutDependentProps<
  Dir extends Direction,
  Dist extends Distribution
> = {
  direction: Dir;
  distribution?: Dist;
} & (Dist extends "packed"
  ? { alignment?: PackedAlignment }
  : {
      alignment?: Dir extends "horizontal"
        ? HorizontalSpaceBetweenAlignment
        : VerticalSpaceBetweenAlignment;
    });

type LengthProperty = Properties["width"] | Properties["height"];

interface IHugContents {
  kind: "hugContents";
  value: LengthProperty;
}

interface IFixed {
  kind: "fixed";
  value: LengthProperty;
}

interface IFillContainer {
  kind: "fillContainer";
  value: LengthProperty;
}

export type ResizingX = IFixed | IFillContainer | IHugContents;
export type ResizingY = IFixed | IFillContainer | IHugContents;

export function Fixed(value: LengthProperty): IFixed {
  return { kind: "fixed", value };
}

export function FillContainer(): IFillContainer {
  return { kind: "fillContainer", value: "100%" };
}

export function HugContents(): IHugContents {
  return {
    kind: "hugContents",
    value: "fit-content"
  };
}

type Spacing = number | string;

export interface IAutoLayoutProps extends FlexProps {
  children: ReactNode;
  spacing: ResponsiveValue<Spacing>;
  dependentProps: ResponsiveValue<
    AutoLayoutDependentProps<Direction, Distribution>
  >;
  resizingX?: ResponsiveValue<ResizingX>;
  resizingY?: ResponsiveValue<ResizingY>;
  onClick?: DOMAttributes<never>["onClick"];
}

/**
 * figma guide: https://help.figma.com/hc/en-us/articles/360040451373-Create-dynamic-designs-with-Auto-layout
 */
function AutoLayout(props: IAutoLayoutProps): ReactElement {
  const {
    children,
    spacing,
    resizingX: rawResizingX,
    resizingY: rawResizingY,
    dependentProps: rawDependentProps,
    sx,
    ...rest
  } = props;
  const resizingX = rawResizingX ?? HugContents();
  const resizingY = rawResizingY ?? HugContents();
  const dependentProps: ResponsiveValue<
    AutoLayoutDependentProps<Direction, Distribution>
  > = rvMap(rawDependentProps, ({ direction, distribution, alignment }) => ({
    direction,
    distribution: distribution ?? "packed",
    alignment: alignment ?? "topLeft"
  }));

  const childrenArray = Children.toArray(children);
  const childrenArrayWithSpacers = childrenArray.flatMap((c, i) =>
    i === childrenArray.length - 1
      ? [c]
      : [
          c,
          <Spacer
            key={`spacer-${i}`}
            spacing={spacing}
            direction={rvMap(dependentProps, p => p.direction)}
          />
        ]
  );

  return (
    <Flex
      sx={{
        flexDirection: rvMap(dependentProps, getFlexDirection),
        justifyContent: rvMap(dependentProps, getJustifyContent),
        alignItems: rvMap(dependentProps, getAlignItems),
        width: rvMap(resizingX, (rx: ResizingX) => rx.value),
        height: rvMap(resizingY, (ry: ResizingY) => ry.value),
        ...sx
      }}
      {...rest}
    >
      {childrenArrayWithSpacers}
    </Flex>
  );
}

interface ISpacerProps {
  spacing: ResponsiveValue<Spacing>;
  direction: ResponsiveValue<Direction>;
}

function Spacer(props: ISpacerProps): ReactElement {
  const makeSpacingFn = (direction: Direction) => () => {
    if (direction !== useActiveResponsiveValue(props.direction)) return 0;
    const spacing = useActiveResponsiveValue(props.spacing);
    return typeof spacing == "number" ? `${spacing}px` : spacing;
  };

  const responsiveDimensions = rvMap(
    rvZip(props.spacing, props.direction),
    ({ a: spacing, b: direction }) => ({ spacing, direction })
  );

  return (
    <Box
      sx={{
        width: rvMap(responsiveDimensions, makeSpacingFn("horizontal")),
        height: rvMap(responsiveDimensions, makeSpacingFn("vertical")),
        flexShrink: 0
      }}
    />
  );
}

function getFlexDirection<Dir extends Direction, Dist extends Distribution>(
  dependentProps: AutoLayoutDependentProps<Dir, Dist>
): Properties["flexDirection"] {
  return dependentProps.direction === "horizontal" ? "row" : "column";
}

function getJustifyContent<Dir extends Direction, Dist extends Distribution>(
  dependentProps: AutoLayoutDependentProps<Dir, Dist>
): Properties["justifyContent"] {
  const { distribution, direction, alignment } = dependentProps;
  switch (distribution) {
    case "spaceBetween":
      return "space-between";
    case "packed":
      switch (direction) {
        case "horizontal":
          switch (alignment) {
            case "bottomCenter":
              return "center";
            case "bottomLeft":
              return "flex-start";
            case "bottomRight":
              return "flex-end";
            case "center":
              return "center";
            case "left":
              return "flex-start";
            case "right":
              return "flex-end";
            case "topCenter":
              return "center";
            case "topLeft":
              return "flex-start";
            case "topRight":
              return "flex-end";
            case "bottom":
            case "top":
              return "center";
          }
          break;
        case "vertical":
          switch (alignment) {
            case "bottomCenter":
              return "flex-end";
            case "bottomLeft":
              return "flex-end";
            case "bottomRight":
              return "flex-end";
            case "center":
              return "center";
            case "left":
              return "center";
            case "right":
              return "center";
            case "topCenter":
              return "flex-start";
            case "topLeft":
              return "flex-start";
            case "topRight":
              return "flex-start";
            case "bottom":
            case "top":
              return "center";
          }
      }
  }
}

function getAlignItems<Dir extends Direction, Dist extends Distribution>(
  dependentProps: AutoLayoutDependentProps<Dir, Dist>
): Properties["alignItems"] {
  const { distribution, direction, alignment } = dependentProps;
  switch (distribution) {
    case "spaceBetween":
      switch (direction) {
        case "horizontal":
          switch (alignment as HorizontalSpaceBetweenAlignment) {
            case "top":
              return "flex-start";
            case "center":
              return "center";
            case "bottom":
              return "flex-end";
          }
          break;
        case "vertical":
          switch (alignment as VerticalSpaceBetweenAlignment) {
            case "left":
              return "flex-start";
            case "center":
              return "center";
            case "right":
              return "flex-end";
          }
      }
      break;
    case "packed":
      switch (direction) {
        case "horizontal":
          switch (alignment) {
            case "bottomCenter":
              return "flex-end";
            case "bottomLeft":
              return "flex-end";
            case "bottomRight":
              return "flex-end";
            case "center":
              return "center";
            case "left":
              return "center";
            case "right":
              return "center";
            case "topCenter":
              return "flex-start";
            case "topLeft":
              return "flex-start";
            case "topRight":
              return "flex-start";
            case "bottom":
            case "top":
              return "center";
          }
          break;
        case "vertical":
          switch (alignment) {
            case "bottomCenter":
              return "center";
            case "bottomLeft":
              return "flex-start";
            case "bottomRight":
              return "flex-end";
            case "center":
              return "center";
            case "left":
              return "flex-start";
            case "right":
              return "flex-end";
            case "topCenter":
              return "center";
            case "topLeft":
              return "flex-start";
            case "topRight":
              return "flex-end";
            case "bottom":
            case "top":
              return "center";
          }
      }
  }
}

export default AutoLayout;
