/**
 * @generated SignedSource<<b37085d341686ce5b4bf0358248e8f16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePartnerCanViewDeals_authUser$data = {
  readonly partner: {
    readonly canViewPortfolio: boolean;
    readonly company: {
      readonly offers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "OfferNode";
          } | null;
        } | null>;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "usePartnerCanViewDeals_authUser";
};
export type usePartnerCanViewDeals_authUser$key = {
  readonly " $data"?: usePartnerCanViewDeals_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePartnerCanViewDeals_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePartnerCanViewDeals_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canViewPortfolio",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyNode",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "isAccepted",
                  "value": true
                }
              ],
              "concreteType": "OfferNodeConnection",
              "kind": "LinkedField",
              "name": "offers",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OfferNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OfferNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "offers(isAccepted:true)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "4b28cd26eaa562875c90ad301cf186c7";

export default node;
