import { ChatPageChatsQuery as ChatPageChatsQueryType } from "__generated__/ChatPageChatsQuery.graphql";
import { ChatPagePaginationQuery } from "__generated__/ChatPagePaginationQuery.graphql";
import { Inbox_chats$key } from "__generated__/Inbox_chats.graphql";
import { ReactElement, useEffect } from "react";
import {
  graphql,
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery
} from "react-relay";
import { generatePath, useHistory } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Flex } from "theme-ui";

import { NodeId } from "../../../../types/relay_types";
import { useBreakpoint } from "../../../../utils/useBreakpoints";
import { useAuth } from "../../hooks/useAuth";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import CardHeadingPlain from "../03_UI_Kit/Content/CardHeadingPlain";
import { ChatPageChatsQuery } from "./ChatPage";
import InboxChats from "./InboxChats";

type InboxSize = "small" | "medium";
interface IInbox {
  myChatsQueryRef: PreloadedQuery<ChatPageChatsQueryType>;
  selectedChatId: NodeId;
  size: ResponsiveValue<InboxSize>;
}

function Inbox(props: IInbox): ReactElement {
  const chatsQuery = usePreloadedQuery<ChatPageChatsQueryType>(
    ChatPageChatsQuery,
    props.myChatsQueryRef
  );

  const {
    data: paginatedChats,
    loadNext,
    hasNext
  } = usePaginationFragment<ChatPagePaginationQuery, Inbox_chats$key>(
    graphql`
      fragment Inbox_chats on Query
      @relay
      @refetchable(queryName: "ChatPagePaginationQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 15 }
        cursor: { type: "String" }
      ) {
        myChats(
          first: $count
          after: $cursor
          hasMessages: true
          orderBy: "-last_message_sent_at"
        ) @connection(key: "Inbox_myChats") {
          edges {
            node {
              id
              ...InboxChats_chats
            }
          }
        }
      }
    `,
    chatsQuery
  );

  const { authUser } = useAuth();

  const history = useHistory();
  const breakpointMatchMap = useBreakpoint();

  const chats = paginatedChats.myChats.edges.map(e => e.node);

  const { selectedChatId } = props;
  useEffect(() => {
    if (
      !breakpointMatchMap.mobile &&
      selectedChatId == null &&
      chats.length > 0
    ) {
      history.replace(
        generatePath(InternalRoutes.inbox, {
          chatId: chats[0].id
        })
      );
    }
  }, [selectedChatId, breakpointMatchMap]);

  const infiniteScrollProps = {
    next: () => loadNext(15),
    hasMore: hasNext
  };

  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: "column",
        overflowY: "auto"
      }}
    >
      <CardHeadingPlain size={props.size} level={"h3"}>
        Your Inbox
      </CardHeadingPlain>
      <InboxChats
        size={props.size}
        selectedChatId={props.selectedChatId}
        infiniteScrollProps={infiniteScrollProps}
        chats={chats}
        emptyText={`You haven't started any conversations yet. Once you've started a conversation with ${
          authUser?.userType === "artist" ? "a partner" : "an artist"
        }, they'll appear here.`}
      />
    </Flex>
  );
}

export default Inbox;
