import { ReactElement } from "react";
import { generatePath, Link } from "react-router-dom";
import { Box } from "theme-ui";

import AutoLayout from "../../../../../../../components/01_Core/AutoLayout";
import Button from "../../../../../../../components/01_Core/Buttons/Button";
import { Tooltip } from "../../../../../../../components/01_Core/Tooltips/Tooltip";
import { useBreakpoint } from "../../../../../../../utils/useBreakpoints";
import useArtistCanViewDeals from "../../../../../hooks/useArtistCanViewDeals";
import useCanAccessMarketplace from "../../../../../hooks/useCanAccessMarketplace";
import { InternalRoutes } from "../../../../../Routing/InternalRoutes";
import {
  dealsSubpage,
  manageReleasesSubpage
} from "../../../../08_Account_Management/AccountManagementPage";
import Base from "../Items/Base";

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=3011%3A19090
 */
function Artist(): ReactElement {
  const breakpointMap = useBreakpoint();

  const [canAccessMarketplace, _] = useCanAccessMarketplace();
  const [artistCanViewDeals, artistCannotViewDealsText] =
    useArtistCanViewDeals();

  return (
    !breakpointMap.mobile && (
      <AutoLayout
        spacing={32}
        dependentProps={{ direction: "horizontal", alignment: "center" }}
        sx={{ height: "100%" }}
      >
        {canAccessMarketplace && (
          <Base route={InternalRoutes.fundDirectory}>Browse Partners</Base>
        )}
        <Base route={InternalRoutes.insights} badge={"BETA"}>
          Insights
        </Base>
        <Tooltip content={artistCannotViewDealsText} size="medium">
          <Base route={InternalRoutes.portfolio} disabled={!artistCanViewDeals}>
            Portfolio
          </Base>
        </Tooltip>
        {breakpointMap.desktop && canAccessMarketplace && (
          <Link
            to={generatePath(InternalRoutes.settings, {
              subpage: manageReleasesSubpage
            })}
          >
            <Box py={"12px"}>
              <Button
                key={"button"}
                variant={"tertiary"}
                size={"medium"}
                disabled={false}
              >
                Add Upcoming Release
              </Button>
            </Box>
          </Link>
        )}
        {breakpointMap.desktop && canAccessMarketplace && (
          <Link
            to={generatePath(InternalRoutes.settings, {
              subpage: dealsSubpage
            })}
          >
            <Box py={"12px"}>
              <Button
                key={"button"}
                variant={"tertiary"}
                size={"medium"}
                disabled={false}
              >
                Propose a Deal
              </Button>
            </Box>
          </Link>
        )}
      </AutoLayout>
    )
  );
}

export default Artist;
