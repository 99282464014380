import { ReactElement } from "react";

import AutoLayout, {
  FillContainer,
  HugContents
} from "../../../../../components/01_Core/AutoLayout";
import { InputState } from "../../../../../components/01_Core/Forms/Input";
import CheckboxInput from "../../../../../components/01_Core/Forms/Inputs/CheckboxInput";
import { Field } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { FieldValidator } from "../../../../../components/01_Core/Forms/utils/validators";
import { ExternalRoutes } from "../../../Routing/ExternalRoutes";
import Header from "../../03_UI_Kit/Forms/Header";

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=468%3A23679
 */
function AcceptBasicTerms(): ReactElement {
  const validateTOS: FieldValidator<boolean | null> = value => {
    return value !== true
      ? "In order to join indify you must confirm you accept these conditions."
      : undefined;
  };

  return (
    <AutoLayout
      spacing={[32, 48, 48, 48]}
      dependentProps={{
        direction: "vertical",
        distribution: "packed",
        alignment: "topLeft"
      }}
      resizingY={HugContents()}
      resizingX={FillContainer()}
    >
      <Header size={["small", "small", "large", "large"]}>
        Finally, review the terms
      </Header>
      <AutoLayout
        spacing={12}
        resizingX={HugContents()}
        resizingY={HugContents()}
        dependentProps={{
          direction: "vertical",
          distribution: "packed",
          alignment: "topLeft"
        }}
      >
        <Field name={"tos"} validate={validateTOS}>
          {({ input, meta }) => {
            const hasError = (meta.error || meta.submitError) && meta.touched;
            const state: InputState = hasError ? "alert" : "normal";
            return (
              <CheckboxInput
                input={input}
                meta={meta}
                size={["medium", "medium", "large", "large"]}
                label={[
                  {
                    kind: "normal",
                    text: "I agree with the "
                  },
                  {
                    kind: "link",
                    text: "Terms of Service",
                    href: ExternalRoutes.terms
                  },
                  {
                    kind: "normal",
                    text: " and "
                  },
                  {
                    kind: "link",
                    text: "Privacy Policy",
                    href: ExternalRoutes.privacy
                  }
                ]}
                state={state}
              />
            );
          }}
        </Field>
      </AutoLayout>
    </AutoLayout>
  );
}

export default AcceptBasicTerms;
