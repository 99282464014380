import { ReactElement } from "react";
import { Text, useThemeUI } from "theme-ui";

import Ic16Visibility from "../../../../../../../imgs/icons/ic16-visibility.svg";
import Ic16VisibilityOff from "../../../../../../../imgs/icons/ic16-visibility-off.svg";
import Ic20Visibility from "../../../../../../../imgs/icons/ic20-visibility.svg";
import Ic20VisibilityOff from "../../../../../../../imgs/icons/ic20-visibility-off.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";

type OfferMessageShowDefinitionsSize = "small" | "medium";
interface IOfferMessageShowDefinitionsProps {
  size: OfferMessageShowDefinitionsSize;
  areDefinitionsShown: boolean;
  onClick: () => void;
}

function OfferMessageShowDefinitions(
  props: IOfferMessageShowDefinitionsProps
): ReactElement {
  const { theme } = useThemeUI();

  const renderIcon = (
    size: OfferMessageShowDefinitionsSize,
    areTermDefinitionsShown: boolean
  ) => {
    const iconColor = theme.colors.primary85 as string;

    switch (size) {
      case "medium":
        return areTermDefinitionsShown ? (
          <Ic20VisibilityOff color={iconColor} />
        ) : (
          <Ic20Visibility color={iconColor} />
        );
      case "small":
        return areTermDefinitionsShown ? (
          <Ic16VisibilityOff color={iconColor} />
        ) : (
          <Ic16Visibility color={iconColor} />
        );
    }
  };

  const getPadding = (size: OfferMessageShowDefinitionsSize) => {
    switch (size) {
      case "medium":
        return "24px";
      case "small":
        return "16px";
    }
  };

  const getTextVariant = (size: OfferMessageShowDefinitionsSize) => {
    switch (size) {
      case "medium":
        return "link.bodyMedium";
      case "small":
        return "link.bodySmall";
    }
  };

  return (
    <AutoLayout
      spacing={8}
      dependentProps={{ direction: "horizontal" }}
      resizingX={FillContainer()}
      p={getPadding(props.size)}
      bg={"lightGray100"}
      sx={{ cursor: "pointer", border: "1px transparent", borderRadius: "4px" }}
      onClick={props.onClick}
    >
      {renderIcon(props.size, props.areDefinitionsShown)}
      <Text variant={getTextVariant(props.size)}>
        {props.areDefinitionsShown ? "Hide" : "Show"} definitions for each term
      </Text>
    </AutoLayout>
  );
}

export default OfferMessageShowDefinitions;
