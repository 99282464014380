/**
 * @generated SignedSource<<ecddc8b79670cc09dcbb6826263c9c39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundPortfolioPage_authUser$data = {
  readonly partner: {
    readonly canViewPortfolio: boolean;
  } | null;
  readonly " $fragmentType": "FundPortfolioPage_authUser";
};
export type FundPortfolioPage_authUser$key = {
  readonly " $data"?: FundPortfolioPage_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundPortfolioPage_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundPortfolioPage_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canViewPortfolio",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "bb0e16fe9780f764a147f58986909c80";

export default node;
