/**
 * @generated SignedSource<<c03eb9d83b3d96480df32f4bc4031ace>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useAuthLogoutMutation$variables = {};
export type useAuthLogoutMutation$data = {
  readonly logout: {
    readonly ok: boolean | null;
  } | null;
};
export type useAuthLogoutMutation = {
  response: useAuthLogoutMutation$data;
  variables: useAuthLogoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LogoutMutation",
    "kind": "LinkedField",
    "name": "logout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useAuthLogoutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useAuthLogoutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a75fafdb31872feeb7b2903e70c8a0df",
    "id": null,
    "metadata": {},
    "name": "useAuthLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation useAuthLogoutMutation {\n  logout {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2322955d677f4608a967b5dbb7501d1";

export default node;
