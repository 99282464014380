/**
 * @generated SignedSource<<0d5d0dc87028177bc2abe0c37eff9c16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type PrivateRoute_authUser$data = {
  readonly canAccessMarketplace: boolean;
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly userType: UserType | null;
  readonly " $fragmentType": "PrivateRoute_authUser";
};
export type PrivateRoute_authUser$key = {
  readonly " $data"?: PrivateRoute_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivateRoute_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateRoute_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessMarketplace",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "0ec897c7dbf133b38f35fd1b4e291069";

export default node;
