/**
 * @generated SignedSource<<d43a0d85bf55617ac8908cd87105131f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BasicInfoFormPartner_partner$data = {
  readonly about: string | null;
  readonly displayName: string;
  readonly id: string;
  readonly location: string | null;
  readonly slug: string;
  readonly thumbnailUrl: string;
  readonly " $fragmentType": "BasicInfoFormPartner_partner";
};
export type BasicInfoFormPartner_partner$key = {
  readonly " $data"?: BasicInfoFormPartner_partner$data;
  readonly " $fragmentSpreads": FragmentRefs<"BasicInfoFormPartner_partner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BasicInfoFormPartner_partner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "about",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "PartnerNode",
  "abstractKey": null
};

(node as any).hash = "b2d71fe662673bf700466096cd67849a";

export default node;
