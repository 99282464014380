import { IndifyToPartnerReferralFlow_referral$key } from "__generated__/IndifyToPartnerReferralFlow_referral.graphql";
import assert from "assert";
import {
  Context,
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { graphql, useFragment } from "react-relay";
import { BehaviorSubject, Observable } from "rxjs";

import { noop } from "../../../../utils/utils";
import useBehaviorSubjectState from "../../hooks/useBehaviorSubjectState";
import { websiteFieldName } from "../08_Account_Management/FormFields/Website";
import {
  INavState,
  IReferralFormValues,
  NavigationRequest
} from "./ClaimYourAccountFlow";
import Page, {
  ConfirmAccountInfoPage,
  Pages,
  PartnerAcceptTermsPage,
  PartnerAddCompanyInfoPage
} from "./Pages/Page";

interface IIndifyToPartnerReferralFlowProps {
  navRequests: Observable<NavigationRequest>;
  navState: BehaviorSubject<INavState>;
  referral: IndifyToPartnerReferralFlow_referral$key;
}

export interface IIndifyToPartnerReferralFlowFormValues
  extends IReferralFormValues {
  email: string;
  password: string;
  name: string;
  company: {
    label: string;
    value: string;
    thumbnailUrl?: string;
    __isNew__?: boolean;
  };
  tos: boolean;
  distroWorkflow: boolean;
  [websiteFieldName]?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const CompanyKnownContext: Context<
  [boolean, Dispatch<SetStateAction<boolean>>]
> = createContext([false, noop]);

/**
 * Manages stepping through the indify => Partner referral flow
 */
function IndifyToPartnerReferralFlow(
  props: IIndifyToPartnerReferralFlowProps
): ReactElement {
  const referral = useFragment(
    graphql`
      fragment IndifyToPartnerReferralFlow_referral on IndifyToPartnerReferralNode {
        company {
          id
        }
        ...Page_referral
      }
    `,
    props.referral
  );

  const [companyKnown, setCompanyKnown] = useState(!!referral.company?.id);

  // TODO: abstract the following into a hook
  const flow: Pages[] = companyKnown
    ? [ConfirmAccountInfoPage, PartnerAcceptTermsPage]
    : [
        ConfirmAccountInfoPage,
        PartnerAddCompanyInfoPage,
        PartnerAcceptTermsPage
      ];

  const { current } = useBehaviorSubjectState(props.navState);
  const section = flow[current];
  useEffect(() => {
    const { current, page } = props.navState.getValue();
    props.navState.next({
      current,
      page: page,
      total: flow.length
    });
  }, [flow.toString()]); // TODO: Does flow need to be a dep here?
  useEffect(() => {
    const sub = props.navRequests.subscribe({
      next: navRequest => {
        const { current, total } = props.navState.getValue();
        switch (navRequest) {
          case "continue": {
            const next = current + 1;
            assert(next < total);
            props.navState.next({
              current: next,
              page: flow[next],
              total
            });
            return;
          }
          case "back": {
            const previous = current - 1;
            assert(current > 0);
            props.navState.next({
              current: previous,
              page: flow[previous],
              total
            });
            return;
          }
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <CompanyKnownContext.Provider value={[companyKnown, setCompanyKnown]}>
      <Page page={section} referral={referral} />
    </CompanyKnownContext.Provider>
  );
}

export default IndifyToPartnerReferralFlow;
