import { ReactElement, ReactNode } from "react";
import { Flex, Text } from "theme-ui";

/**
 * figma: N/A
 */
function OfferNotification(props: { children: ReactNode }): ReactElement {
  return (
    <Flex
      bg={"secondary10"}
      py={"24px"}
      px={"8px"}
      sx={{ flexDirection: "column", width: "100%", borderRadius: "4px" }}
    >
      <Text
        variant={"primary.bodySmall"}
        sx={{ textAlign: "center", paddingX: "16px" }}
      >
        {props.children}
      </Text>
    </Flex>
  );
}

export default OfferNotification;
