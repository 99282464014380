/**
 * @generated SignedSource<<553879d38773289122ccb8dadb31caa3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FundCaseStudyOfferTrackDataQuery$variables = {
  companySlug: string;
};
export type FundCaseStudyOfferTrackDataQuery$data = {
  readonly caseStudyOfferByCompanySlug: {
    readonly dateOfferAccepted: any | null;
    readonly tracksUniqByLuminateSongId: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly data: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly value: number;
                readonly weekId: {
                  readonly endDay: any;
                };
              } | null;
            } | null>;
          };
          readonly name: string;
        } | null;
      } | null>;
    };
  } | null;
};
export type FundCaseStudyOfferTrackDataQuery = {
  response: FundCaseStudyOfferTrackDataQuery$data;
  variables: FundCaseStudyOfferTrackDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companySlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companySlug",
    "variableName": "companySlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateOfferAccepted",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "country",
    "value": "G1"
  },
  {
    "kind": "Literal",
    "name": "metric",
    "value": "Streaming On-Demand Total"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDay",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FundCaseStudyOfferTrackDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferNode",
        "kind": "LinkedField",
        "name": "caseStudyOfferByCompanySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferTrackNodeConnection",
            "kind": "LinkedField",
            "name": "tracksUniqByLuminateSongId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferTrackNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OfferTrackNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": (v4/*: any*/),
                        "concreteType": "OfferTrackDataNodeConnection",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OfferTrackDataNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OfferTrackDataNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WeekIdNode",
                                    "kind": "LinkedField",
                                    "name": "weekId",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "data(country:\"G1\",metric:\"Streaming On-Demand Total\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FundCaseStudyOfferTrackDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferNode",
        "kind": "LinkedField",
        "name": "caseStudyOfferByCompanySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferTrackNodeConnection",
            "kind": "LinkedField",
            "name": "tracksUniqByLuminateSongId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferTrackNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OfferTrackNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": (v4/*: any*/),
                        "concreteType": "OfferTrackDataNodeConnection",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OfferTrackDataNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OfferTrackDataNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WeekIdNode",
                                    "kind": "LinkedField",
                                    "name": "weekId",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "data(country:\"G1\",metric:\"Streaming On-Demand Total\")"
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ecac66dfdf847adb96e085ef7880359d",
    "id": null,
    "metadata": {},
    "name": "FundCaseStudyOfferTrackDataQuery",
    "operationKind": "query",
    "text": "query FundCaseStudyOfferTrackDataQuery(\n  $companySlug: String!\n) {\n  caseStudyOfferByCompanySlug(companySlug: $companySlug) {\n    dateOfferAccepted\n    tracksUniqByLuminateSongId {\n      edges {\n        node {\n          name\n          data(country: \"G1\", metric: \"Streaming On-Demand Total\") {\n            edges {\n              node {\n                value\n                weekId {\n                  endDay\n                  id\n                }\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1671810bca5777415a4c3574ad72d982";

export default node;
