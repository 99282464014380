import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import {
  Box,
  Checkbox as ThemeUICheckbox,
  Flex,
  Label,
  Link,
  Text
} from "theme-ui";

import { useFindActiveRV } from "../../../../utils/responsiveUtils";
import { ITextFragment } from "../../../../utils/textFragments";
import AutoLayout from "../../AutoLayout";
import { InputProps } from "../Input";

type State = "normal" | "alert" | "disabled" | "indeterminate";
type Size = "small" | "medium" | "large";

// `caption` can be present only if `label` is also present
type LabelCaptionProps<T> = {
  label?: T;
  caption?: T extends null ? never : ReactNode;
};

type ICheckboxProps = Omit<
  InputProps<HTMLInputElement, boolean | null>,
  "label"
> & {
  state?: State;
  size: ResponsiveValue<Size>;
} & LabelCaptionProps<ReactNode | ITextFragment[]>;

/**
 * figma:
 *   - https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1280%3A90
 *   - https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1280%3A91
 *   - https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1280%3A92
 */
function CheckboxInput(props: ICheckboxProps): ReactElement {
  const {
    input: { value, onChange }
  } = props;
  const state = props.state ?? "normal";

  const labelVariant = useFindActiveRV(props.size, getLabelVariant);
  const captionVariant = useFindActiveRV(props.size, getCaptionVariant);

  function getSpacing(size: Size) {
    switch (size) {
      case "small":
        return 4;
      case "medium":
        return 8;
      case "large":
        return 12;
      default:
        size satisfies never;
    }
  }

  return (
    <Label>
      <AutoLayout
        spacing={useFindActiveRV(props.size, getSpacing)}
        dependentProps={{
          direction: "horizontal"
        }}
        sx={{ alignItems: "center" }}
      >
        <Box mt={"2px"} sx={{ flexShrink: 0 }}>
          <ThemeUICheckbox onChange={() => onChange(!value)} checked={value} />
        </Box>
        <Flex sx={{ flexDirection: "column" }}>
          {props.label != null ? (
            Array.isArray(props.label) ? (
              <Box>
                {props.label.map(({ kind, text, href }: ITextFragment, i) => {
                  return kind === "link" ? (
                    <Link
                      key={i}
                      href={href}
                      target={"_blank"}
                      sx={{ display: "inline", textDecoration: "none" }}
                    >
                      <Text
                        variant={labelVariant}
                        color={"secondary100"}
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                          "&:hover": { textDecoration: "underline" }
                        }}
                      >
                        {text}
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      key={i}
                      variant={labelVariant}
                      color={getLabelTextColor(state)}
                      sx={{
                        display: "inline"
                      }}
                    >
                      {text}
                    </Text>
                  );
                })}
              </Box>
            ) : (
              <Text variant={labelVariant} color={getLabelTextColor(state)}>
                {props.label}
              </Text>
            )
          ) : null}
          {props.caption != null ? (
            <Text variant={captionVariant} color={getCaptionTextColor(state)}>
              {props.caption}
            </Text>
          ) : null}
        </Flex>
      </AutoLayout>
    </Label>
  );
}

export function getLabelTextColor(state: State): string {
  switch (state) {
    case "normal":
    case "alert":
    case "indeterminate":
      return "black100";
    case "disabled":
      return "deepGray70";
  }
}

export function getCaptionTextColor(state: State): string {
  switch (state) {
    case "normal":
    case "alert":
    case "indeterminate":
      return "deepGray100";
    case "disabled":
      return "gray40";
  }
}

export function getLabelVariant(size: Size): string {
  switch (size) {
    case "small":
      return "bodySmall";
    case "medium":
      return "bodyMedium";
    case "large":
      return "bodyLarge";
  }
}

export function getCaptionVariant(size: Size): string {
  switch (size) {
    case "small":
    case "medium":
      return "bodySmall";
    case "large":
      return "bodyMedium";
  }
}

export default CheckboxInput;
