/**
 * @generated SignedSource<<f951356e1524e6b1ecbeea6a34a5215d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseSelector_releases$data = ReadonlyArray<{
  readonly __typename: "ReleaseNode";
  readonly hasExistingInvestmentPartnership: boolean | null;
  readonly hasExistingPartnership: boolean | null;
  readonly id: string;
  readonly name: string;
  readonly releaseDate: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"Release_release">;
  readonly " $fragmentType": "ReleaseSelector_releases";
}>;
export type ReleaseSelector_releases$key = ReadonlyArray<{
  readonly " $data"?: ReleaseSelector_releases$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseSelector_releases">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ReleaseSelector_releases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExistingPartnership",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExistingInvestmentPartnership",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Release_release"
    }
  ],
  "type": "ReleaseNode",
  "abstractKey": null
};

(node as any).hash = "80827210c2cabb2ade73310e446882b0";

export default node;
