import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Linkify from "../../../../utils/Linkify";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import ShowMore, { IShowMoreProps } from "../03_UI_Kit/Actions/ShowMore";

type AboutSize = "small" | "large";
interface IAboutProps {
  size: ResponsiveValue<AboutSize>;
  about?: string;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=873%3A18720
 */
function About(props: IAboutProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);

  if (!props.about) {
    return null;
  }

  return (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
    >
      <Text variant={"h400"}>What We Do</Text>
      <ExpandableText size={activeSize}>
        <Text
          variant={activeSize === "small" ? "bodyMedium" : "bodyLarge"}
          sx={{ display: "block" }}
        >
          <Linkify>{props.about}</Linkify>
        </Text>
      </ExpandableText>
    </AutoLayout>
  );
}

const MAX_HEIGHT = 120;
export function ExpandableText(props: {
  children: ReactNode;
  size: IShowMoreProps["size"];
}): ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const accordionRef = useRef<HTMLDivElement>(null);

  const [accordionMaxHeight, setAccordionMaxHeight] = useState<number | string>(
    "unset"
  );
  useEffect(
    () =>
      setAccordionMaxHeight(
        expanded ? accordionRef.current?.scrollHeight ?? "unset" : MAX_HEIGHT
      ),
    [expanded]
  );

  const canExpand = accordionRef.current?.scrollHeight > MAX_HEIGHT;

  return (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "vertical" }}
      onClick={toggleExpanded}
      resizingX={FillContainer()}
      sx={{ cursor: canExpand ? "pointer" : undefined }}
    >
      <Box
        ref={accordionRef}
        sx={{
          width: "100%",
          overflow: "hidden",
          maxHeight: accordionMaxHeight,
          transition: "max-height 0.5s ease-out",
          ...(canExpand &&
            !expanded && {
              WebkitMaskImage:
                "linear-gradient(to bottom, black 50%, transparent 100%)"
            })
        }}
      >
        <Text sx={{ whiteSpace: "pre-line" }}>{props.children}</Text>
      </Box>
      {canExpand && <ShowMore expanded={expanded} size={props.size} />}
    </AutoLayout>
  );
}

export default About;
