import { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";

function useBehaviorSubjectState<T>(subject: BehaviorSubject<T>): T {
  const [state, setState] = useState(subject.getValue());
  useEffect(() => {
    const sub = subject.subscribe({
      next: setState
    });
    return () => sub.unsubscribe();
  }, []);
  return state;
}

export default useBehaviorSubjectState;
