/**
 * @generated SignedSource<<a98b352f675019f3ec1cd5dd306a0ba4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleWatchArtistMutation$variables = {
  artistNodeId: string;
};
export type ToggleWatchArtistMutation$data = {
  readonly toggleWatchArtist: {
    readonly artist: {
      readonly watching: boolean | null;
    } | null;
  } | null;
};
export type ToggleWatchArtistMutation = {
  response: ToggleWatchArtistMutation$data;
  variables: ToggleWatchArtistMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "artistNodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "artistNodeId",
    "variableName": "artistNodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "watching",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToggleWatchArtistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleWatchArtistMutation",
        "kind": "LinkedField",
        "name": "toggleWatchArtist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToggleWatchArtistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleWatchArtistMutation",
        "kind": "LinkedField",
        "name": "toggleWatchArtist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31de72a2b64c737d8b0e779737f5731a",
    "id": null,
    "metadata": {},
    "name": "ToggleWatchArtistMutation",
    "operationKind": "mutation",
    "text": "mutation ToggleWatchArtistMutation(\n  $artistNodeId: String!\n) {\n  toggleWatchArtist(artistNodeId: $artistNodeId) {\n    artist {\n      watching\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "49d31abaeaef0c74a50eafb84e79a275";

export default node;
