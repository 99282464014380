// Adapted from https://usehooks.com/useOnClickOutside/

import { MutableRefObject, useEffect } from "react";

function useOnClickOutside(
  ref: MutableRefObject<any>,
  handler: () => void
): void {
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }

      handler();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
