/**
 * @generated SignedSource<<a0347cc6a003e6cf5a5684a3549c1011>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageWithOffer_message$data = {
  readonly id: string;
  readonly message: string;
  readonly offer: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"OfferMessageStatusUpdate_offer">;
  } | null;
  readonly sentAt: any;
  readonly user: {
    readonly actor: {
      readonly " $fragmentSpreads": FragmentRefs<"AvatarIcon_actor">;
    } | null;
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageShell_message">;
  readonly " $fragmentType": "MessageWithOffer_message";
};
export type MessageWithOffer_message$key = {
  readonly " $data"?: MessageWithOffer_message$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageWithOffer_message">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageWithOffer_message",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferNode",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferMessageStatusUpdate_offer"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "actor",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AvatarIcon_actor"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferMessageShell_message"
    }
  ],
  "type": "ChatMessageNode",
  "abstractKey": null
};
})();

(node as any).hash = "fcb692c232b617835a32784d3761cc44";

export default node;
