import { ReactElement } from "react";

import { IFundingOfferFormFieldProps } from "../../../07_Deal_Flow/OfferFormFunding";
import ReleasesIncluded, { IReleasesIncludedProps } from "./ReleasesIncluded";

interface IFeaturedDealFormReleasesIncludedProps
  extends IFundingOfferFormFieldProps {
  artist: IReleasesIncludedProps["artist"];
}

/**
 * figma: N/A
 */
function FeaturedDealFormReleasesIncluded(
  props: IFeaturedDealFormReleasesIncludedProps
): ReactElement {
  return (
    <ReleasesIncluded
      {...props}
      artist={props.artist}
      canCreateUnreleasedContent={true}
    />
  );
}

export default FeaturedDealFormReleasesIncluded;
