import { ReactElement } from "react";
import { Box, Text } from "theme-ui";

function BadgeNew(): ReactElement {
  return (
    <Box bg={"primary85"} padding={"4px 8px"}>
      <Text variant={"h100"} color={"white100"}>
        New
      </Text>
    </Box>
  );
}

export default BadgeNew;
