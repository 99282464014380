import { Children, ReactElement, ReactNode, useRef, useState } from "react";
import { Box } from "theme-ui";

import useOnClickOutside from "../../../pages/indify/hooks/useOnClickOutside";
import { zIndices } from "../../../theme/theme";
import AutoLayout, { IAutoLayoutProps } from "../AutoLayout";
import { insertDividers } from "../Miscelleneous/Divider";

export interface IDropdownProps {
  triggerElement: ReactElement;
  openDirection: "left" | "right";
  styles: IAutoLayoutProps["sx"];
  children: ReactNode;
}

export default function Dropdown(props: IDropdownProps): ReactElement {
  const dropdownRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleIsDropdownOpen = () => setIsDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setIsDropdownOpen(false);
  useOnClickOutside(dropdownRef, closeDropdown);

  const menuItems = Children.toArray(props.children)
    .filter(Boolean)
    .map((child, index) => {
      return (
        <Box
          key={index}
          onClick={closeDropdown}
          sx={{
            cursor: "pointer"
          }}
        >
          {child}
        </Box>
      );
    });

  return (
    <Box
      ref={dropdownRef}
      sx={{
        position: "relative",
        minWidth: "unset"
      }}
    >
      <Box onClick={toggleIsDropdownOpen}>{props.triggerElement}</Box>
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical" }}
        padding={"16px"}
        sx={{
          display: isDropdownOpen ? "flex" : "none",
          position: "absolute",
          right: props.openDirection === "right" ? "auto" : 0,
          left: props.openDirection === "left" ? "auto" : 0,
          backgroundColor: "white100",
          boxShadow: "0px 4px 16px rgba(27, 27, 27, 0.16)",
          cursor: "default",
          borderRadius: "4px",
          border: "1px solid",
          borderColor: "midGray100",
          zIndex: zIndices.modal,
          ...props.styles
        }}
      >
        {insertDividers(menuItems)}
      </AutoLayout>
    </Box>
  );
}
