import { ReleaseIconsNames_query$key } from "__generated__/ReleaseIconsNames_query.graphql";
import { ReleaseIconsNames_releaseImages$key } from "__generated__/ReleaseIconsNames_releaseImages.graphql";
import { Fragment, ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text, ThemeUIStyleObject } from "theme-ui";

import useFeaturedDealTitle from "../../../pages/indify/hooks/useFeaturedDealTitle";
import { rvMap, useFindActiveRV } from "../../../utils/responsiveUtils";
import AutoLayout from "../AutoLayout";
import ReleaseImageIcon from "./ReleaseImageIcon";

type ReleaseIconsNamesSize =
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";
interface IReleaseIconsNamesProps {
  size: ResponsiveValue<ReleaseIconsNamesSize>;
  query: ReleaseIconsNames_query$key;
  textStyles?: ThemeUIStyleObject;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2388%3A11175
 */
function ReleaseIconsNames(props: IReleaseIconsNamesProps): ReactElement {
  const textVariant = useFindActiveRV(props.size, getSize);

  const featuredDeal = useFragment(
    graphql`
      fragment ReleaseIconsNames_query on OfferFeaturedDealInterface {
        ...ReleaseIconsNames_releaseImages
        ...useFeaturedDealTitle
      }
    `,
    props.query
  );

  const titleFormatted = useFeaturedDealTitle(featuredDeal, true);
  const titleUnformatted = useFeaturedDealTitle(featuredDeal, false);

  return (
    <AutoLayout
      spacing={rvMap(props.size, getSpacing)}
      dependentProps={{ direction: "horizontal", alignment: "left" }}
      sx={{ flexGrow: 1 }}
    >
      <ReleaseImages featuredDeal={featuredDeal} size={props.size} />
      <Text
        title={titleUnformatted.join("")}
        variant={textVariant}
        color={"black100"}
        sx={props.textStyles}
      >
        {titleFormatted.map((node, i) => (
          <Fragment key={i}>{node}</Fragment>
        ))}
      </Text>
    </AutoLayout>
  );
}

interface IReleaseImagesProps {
  featuredDeal: ReleaseIconsNames_releaseImages$key;
  size: ResponsiveValue<ReleaseIconsNamesSize>;
}

export function ReleaseImages(props: IReleaseImagesProps): ReactElement {
  const { releases, unreleasedReleases } = useFragment(
    graphql`
      fragment ReleaseIconsNames_releaseImages on OfferFeaturedDealInterface {
        releases {
          edges {
            node {
              thumbnailUrl
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              thumbnailUrl
            }
          }
        }
      }
    `,
    props.featuredDeal
  );
  const allReleases = [releases, unreleasedReleases].flatMap(a =>
    a.edges.map(e => e.node)
  );

  const numImages = Math.min(allReleases.length, 3);

  if (numImages === 1) {
    return (
      <ReleaseImageIcon size={props.size} src={allReleases[0].thumbnailUrl} />
    );
  }

  return (
    <AutoLayout
      spacing={4}
      dependentProps={{ direction: "horizontal" }}
      sx={{ flexShrink: 0 }}
    >
      {allReleases.slice(0, numImages).map((release, i) => (
        <ReleaseImageIcon
          key={i}
          size={props.size}
          src={release.thumbnailUrl}
        />
      ))}
    </AutoLayout>
  );
}

function getSpacing(size: ReleaseIconsNamesSize) {
  switch (size) {
    case "extraSmall":
      return 12;
    case "small":
      return 12;
    case "medium":
      return 16;
    case "large":
      return 16;
    case "extraLarge":
      return 16;
  }
}

function getSize(size: ReleaseIconsNamesSize) {
  switch (size) {
    case "extraSmall":
      return "bodyMedium";
    case "small":
      return "bodyMedium";
    case "medium":
      return "bodyLarge";
    case "large":
      return "bodyLarge";
    case "extraLarge":
      return "subtitle";
  }
}

export default ReleaseIconsNames;
