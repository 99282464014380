/**
 * @generated SignedSource<<a3dc339b0d0135f20f25732ddacb3662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReleaseTypeEnum = "album" | "compilation" | "single";
export type ReleaseTypename = "ReleaseNode" | "UnreleasedReleaseNode";
export type UpdateOrCreateReleaseMutationInput = {
  id?: string | null;
  isVisible?: boolean | null;
  name?: string | null;
  releaseType?: ReleaseTypeEnum | null;
  soundLink?: string | null;
  tracksCount?: number | null;
  typename: ReleaseTypename;
};
export type UpdateOrCreateReleaseModal_Mutation$variables = {
  input: UpdateOrCreateReleaseMutationInput;
};
export type UpdateOrCreateReleaseModal_Mutation$data = {
  readonly updateOrCreateRelease: {
    readonly release: {
      readonly id: string;
    } | null;
  } | null;
};
export type UpdateOrCreateReleaseModal_Mutation = {
  response: UpdateOrCreateReleaseModal_Mutation$data;
  variables: UpdateOrCreateReleaseModal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOrCreateReleaseModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrCreateReleaseMutation",
        "kind": "LinkedField",
        "name": "updateOrCreateRelease",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "release",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOrCreateReleaseModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrCreateReleaseMutation",
        "kind": "LinkedField",
        "name": "updateOrCreateRelease",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "release",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4571d2be2eeb9073175fb16cb9e22511",
    "id": null,
    "metadata": {},
    "name": "UpdateOrCreateReleaseModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOrCreateReleaseModal_Mutation(\n  $input: UpdateOrCreateReleaseMutationInput!\n) {\n  updateOrCreateRelease(data: $input) {\n    release {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b7ca3bd165cc06ac8b655d828a7e181";

export default node;
