import { TeamCard_artist$key } from "__generated__/TeamCard_artist.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { ActorAvatarName } from "../../../../../components/01_Core/Users/AvatarName";
import { rvMap } from "../../../../../utils/responsiveUtils";
import { getBodyPadding, ReleaseCardSize } from "../Releases/ReleaseCard";

interface ITeamCardProps {
  artist: TeamCard_artist$key;
  size: ResponsiveValue<ReleaseCardSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1876%3A25748
 */
function TeamCard(props: ITeamCardProps): ReactElement {
  const artist = useFragment(
    graphql`
      fragment TeamCard_artist on ArtistNode {
        offers(isAccepted: true) {
          edges {
            node {
              partner {
                id
                ...AvatarName_actor
              }
            }
          }
        }
      }
    `,
    props.artist
  );

  const partners = _.uniqBy(
    artist.offers.edges.map(({ node }) => node.partner),
    p => p.id
  );

  return (
    <AutoLayout
      spacing={24}
      resizingY={FillContainer()}
      dependentProps={{ direction: "vertical" }}
      bg={"white100"}
      sx={{
        borderRadius: "4px",
        padding: rvMap(props.size, getBodyPadding),
        width: "100%"
      }}
    >
      <Text variant={"h300"} color={"black100"} sx={{ whiteSpace: "nowrap" }}>
        Team on indify
      </Text>
      <AutoLayout
        spacing={12}
        resizingX={FillContainer()}
        dependentProps={{
          direction: "vertical"
        }}
      >
        {partners.map((partner, i) => (
          <AutoLayout
            key={partner.id}
            spacing={12}
            resizingX={FillContainer()}
            dependentProps={{
              direction: "vertical"
            }}
          >
            <ActorAvatarName actor={partner} size={"small"} />
            {i < partners.length - 1 && <Divider />}
          </AutoLayout>
        ))}
      </AutoLayout>
    </AutoLayout>
  );
}

export default TeamCard;
