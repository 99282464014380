import { IndifyToArtistReferralFlow_referral$key } from "__generated__/IndifyToArtistReferralFlow_referral.graphql";
import assert from "assert";
import { ReactElement, useEffect } from "react";
import { graphql, useFragment } from "react-relay";
import { BehaviorSubject, Observable } from "rxjs";

import useBehaviorSubjectState from "../../hooks/useBehaviorSubjectState";
import {
  INavState,
  IReferralFormValues,
  NavigationRequest
} from "./ClaimYourAccountFlow";
import Page, {
  ArtistAcceptTermsPage,
  ArtistDetailsPage,
  ArtistSocialLinksPage,
  ConfirmAccountInfoPage,
  Pages
} from "./Pages/Page";

interface IIndifyToArtistReferralFlowProps {
  navRequests: Observable<NavigationRequest>;
  navState: BehaviorSubject<INavState>;
  referral: IndifyToArtistReferralFlow_referral$key;
}

export interface IIndifyToArtistReferralFlowFormValues
  extends IReferralFormValues {
  email: string;
  password: string;
  name: string;
  spotify: string;
  tos: boolean;
  spotifyConfirm: boolean;
  distroWorkflow: boolean;
  visible?: boolean;
  genre?: { value: string };
}

const flow: Pages[] = [
  ConfirmAccountInfoPage,
  ArtistSocialLinksPage,
  ArtistDetailsPage,
  ArtistAcceptTermsPage
];

/**
 * Manages stepping through the indify => Artist referral flow
 */
function IndifyToArtistReferralFlow(
  props: IIndifyToArtistReferralFlowProps
): ReactElement {
  const { current } = useBehaviorSubjectState(props.navState);
  const section = flow[current];
  useEffect(() => {
    const { current, page } = props.navState.getValue();
    props.navState.next({
      current,
      page: page,
      total: flow.length
    });
  }, [flow]);
  useEffect(() => {
    const sub = props.navRequests.subscribe({
      next: navRequest => {
        const { current, total } = props.navState.getValue();
        switch (navRequest) {
          case "continue": {
            const next = current + 1;
            assert(next < total);
            props.navState.next({
              current: next,
              page: flow[next],
              total
            });
            return;
          }
          case "back": {
            const previous = current - 1;
            assert(current > 0);
            props.navState.next({
              current: previous,
              page: flow[previous],
              total
            });
            return;
          }
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const referral = useFragment(
    graphql`
      fragment IndifyToArtistReferralFlow_referral on IndifyToArtistReferralNode {
        ...Page_referral
      }
    `,
    props.referral
  );

  return <Page page={section} referral={referral} />;
}

export default IndifyToArtistReferralFlow;
