/**
 * @generated SignedSource<<c4d4b99fc8cba7a707cd383936501e05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReleaseType = "ALBUM" | "COMPILATION" | "SINGLE";
export type UnreleasedReleaseType = "ALBUM" | "COMPILATION" | "SINGLE";
import { FragmentRefs } from "relay-runtime";
export type DealCard_offer$data = {
  readonly artist: {
    readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor" | "ReleaseImagesGrid_artist">;
  };
  readonly dateOfferAccepted: any | null;
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly type: ReleaseType;
        readonly " $fragmentSpreads": FragmentRefs<"ReleaseImagesGrid_releases">;
      } | null;
    } | null>;
  };
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly type: UnreleasedReleaseType | null;
        readonly " $fragmentSpreads": FragmentRefs<"ReleaseImagesGrid_unreleasedReleases">;
      } | null;
    } | null>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useFeaturedDealTitle">;
  readonly " $fragmentType": "DealCard_offer";
};
export type DealCard_offer$key = {
  readonly " $data"?: DealCard_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealCard_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealCard_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfferAccepted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarName_actor"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReleaseImagesGrid_artist"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReleaseImagesGrid_releases"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReleaseImagesGrid_unreleasedReleases"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeaturedDealTitle"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};
})();

(node as any).hash = "9e9ec050aa0f79d1100771b5f18498ad";

export default node;
