import { ReactElement } from "react";
import { generatePath } from "react-router-dom";
import { Box, Flex, Text } from "theme-ui";

import InternalLink from "../../../../utils/InternalLink";
import usePathState from "../../hooks/usePathState";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import BadgeNew from "../03_UI_Kit/Marketing/BadgeNew";
import { Subpage } from "./AccountManagementPage";

interface INavItemProps {
  label: string;
  subpage: Subpage;
  isNew?: boolean;
  disabled?: boolean;
}

function NavItem({
  label,
  subpage,
  isNew,
  disabled
}: INavItemProps): ReactElement {
  const { value: selectedSubpage } = usePathState("settings", "subpage");
  const isActive = selectedSubpage === subpage;

  return (
    <Text color={isActive ? "black100" : "deepGray100"}>
      <InternalLink
        to={generatePath(InternalRoutes.settings, { subpage })}
        disabled={disabled}
        disabledColor="midGray100"
      >
        <Flex sx={{ alignItems: "center", height: "100%" }}>
          <Text
            variant={"bodyMedium"}
            sx={{
              whiteSpace: "nowrap",
              ":hover": !disabled && { color: "black100" }
            }}
          >
            {isActive ? <strong>{label}</strong> : label}
          </Text>
          {isNew && (
            <Box sx={{ marginLeft: "8px" }}>
              <BadgeNew />
            </Box>
          )}
        </Flex>
      </InternalLink>
    </Text>
  );
}

export default NavItem;
