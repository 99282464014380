import { ReferralRecipientInvited_referral$key } from "__generated__/ReferralRecipientInvited_referral.graphql";
import {
  ReferralRecipientInvitedMutation,
  ReferralTypename
} from "__generated__/ReferralRecipientInvitedMutation.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ReactElement } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer,
  HugContents
} from "../../../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../../../components/01_Core/Buttons/LoadingButton";
import Divider from "../../../../../../components/01_Core/Miscelleneous/Divider";
import AvatarName from "../../../../../../components/01_Core/Users/AvatarName";

dayjs.extend(relativeTime);

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1549%3A28430
 */
function ReferralRecipientInvited(props: {
  referral: ReferralRecipientInvited_referral$key;
}): ReactElement {
  const referral = useFragment(
    graphql`
      fragment ReferralRecipientInvited_referral on ReferralInterface {
        __typename
        id
        recipientEmail
        createdAt
      }
    `,
    props.referral
  );

  const [commitMutation, isInFlight] =
    useMutation<ReferralRecipientInvitedMutation>(graphql`
      mutation ReferralRecipientInvitedMutation(
        $typename: ReferralTypename!
        $referralNodeId: ID!
      ) {
        referralDelete(typename: $typename, referralNodeId: $referralNodeId) {
          user {
            partner {
              partnerReferralsMaxAllowed
              partnerReferrals {
                edges {
                  node {
                    id
                  }
                }
              }
              company {
                partnerCompanyReferrals {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);
  const cancelReferral = () =>
    commitMutation({
      variables: {
        typename: referral.__typename as ReferralTypename,
        referralNodeId: referral.id
      }
    });

  return (
    <Box>
      <Divider />
      <AutoLayout
        spacing={16}
        resizingX={FillContainer()}
        resizingY={HugContents()}
        dependentProps={{
          direction: "horizontal",
          distribution: "spaceBetween",
          alignment: "center"
        }}
        py={"16px"}
      >
        <Box sx={{ maxWidth: "240px" }}>
          <AvatarName
            imageUrl={
              "https://storage.googleapis.com/indify-images/invited_user.png"
            }
            size={"large"}
            displayName={referral.recipientEmail}
            status={"invited"}
          />
        </Box>
        <AutoLayout
          spacing={16}
          resizingX={HugContents()}
          resizingY={HugContents()}
          dependentProps={{
            direction: "horizontal",
            distribution: "packed",
            alignment: "right"
          }}
        >
          <Text
            variant={"bodySmall"}
            color={"deepGray100"}
            sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
          >
            Invited{" "}
            <Text
              variant={"bodySmall"}
              color={"deepGray100"}
              sx={{ display: "inline" }}
            >
              <strong>{dayjs().to(dayjs(referral.createdAt))}</strong>
            </Text>
          </Text>
          <LoadingButton
            onClick={cancelReferral}
            size={"small"}
            variant={"secondary"}
            loading={isInFlight}
            disabled={false}
          >
            Cancel
          </LoadingButton>
        </AutoLayout>
      </AutoLayout>
    </Box>
  );
}

export default ReferralRecipientInvited;
