import { useOfferNextStep_authUser$key } from "__generated__/useOfferNextStep_authUser.graphql";
import { useOfferNextStep_offer$key } from "__generated__/useOfferNextStep_offer.graphql";
import { graphql, useFragment } from "react-relay";

import { OfferNextStepEnum } from "../components/03_UI_Kit/Messaging/OfferNextStep/OfferNextStep";
import { useAuth } from "./useAuth";

export default function useOfferNextStep(
  query: useOfferNextStep_offer$key
): OfferNextStepEnum {
  const offer = useFragment(
    graphql`
      fragment useOfferNextStep_offer on OfferNode {
        status
        moneyTransferAmountCents
        transferRecipient {
          user {
            id
            stripeConnectedAccountApiId
          }
        }
        artist {
          user {
            id
          }
        }
      }
    `,
    query
  );
  const authUser = useFragment<useOfferNextStep_authUser$key>(
    graphql`
      fragment useOfferNextStep_authUser on UserNode {
        id
        stripeConnectedAccountApiId
      }
    `,
    useAuth().authUser
  );

  if (!offer) return null;

  if (offer.status === "voided") return "voided";
  if (offer.status === "active") return "active";
  if (offer.status === "canceled" || offer.status === "declined") return null; // No status badge for these statuses

  const recipientHasStripe =
    offer.transferRecipient?.user.stripeConnectedAccountApiId;

  if (offer.artist.user.id === authUser.id) {
    switch (offer.status) {
      case "needs_artist_response":
        return "actionArtistReviewOffer";
      case "needs_earnings_directed":
        return "actionArtistDirectEarnings";
      case "needs_invoice_creation":
        return recipientHasStripe
          ? "waitingIndifyCreateInvoice"
          : "actionArtistConnectStripe";
      case "needs_invoice_payment":
        return "waitingPartnerSendTransfer";
      case "legacy_incomplete_transfer":
        return "contactIndify";
    }
  } else {
    // Partner
    switch (offer.status) {
      case "needs_artist_response":
        return "waitingArtistReviewOffer";
      case "needs_earnings_directed":
        return "waitingArtistDirectEarnings";
      case "needs_invoice_creation":
        return recipientHasStripe
          ? "waitingIndifyCreateInvoice"
          : "waitingArtistConnectStripe";
      case "needs_invoice_payment":
        return "actionPartnerSendTransfer";
      case "legacy_incomplete_transfer":
        return "contactIndify";
    }
  }
}
