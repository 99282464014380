import { ConfirmAccountInfo_referral$key } from "__generated__/ConfirmAccountInfo_referral.graphql";
import { ConfirmAccountInfo_referralCheckQuery } from "__generated__/ConfirmAccountInfo_referralCheckQuery.graphql";
import { ReactElement } from "react";
import { fetchQuery, graphql, useFragment } from "react-relay";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import PasswordInput from "../../../../../components/01_Core/Forms/Inputs/PasswordInput";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import {
  composeFieldValidators,
  emailValidator,
  FieldValidator,
  getMinimumLengthStringValidator,
  requiredStringValidator
} from "../../../../../components/01_Core/Forms/utils/validators";
import environment from "../../../../../environment";
import Header from "../../03_UI_Kit/Forms/Header";
import SectionCompany from "../../03_UI_Kit/Forms/PartnerClaimYourProfile/SectionCompany";

interface IConfirmAccountInfoProps {
  referral: ConfirmAccountInfo_referral$key;
}

const EmailQuery = graphql`
  query ConfirmAccountInfo_referralCheckQuery($email: String!) {
    availableEmail(email: $email)
  }
`;

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=466%3A23482
 */
function ConfirmAccountInfo(props: IConfirmAccountInfoProps): ReactElement {
  const referral = useFragment(
    graphql`
      fragment ConfirmAccountInfo_referral on ReferralInterface {
        __typename
        recipientEmail
        ...SectionCompany_referral
        ... on IndifyToArtistReferralNode {
          recipientDisplayName
        }
        ... on IndifyToPartnerReferralNode {
          recipientDisplayName
        }
      }
    `,
    props.referral
  );

  const emailAvailableValidator: FieldValidator<string> = email =>
    fetchQuery<ConfirmAccountInfo_referralCheckQuery>(environment, EmailQuery, {
      email
    })
      .toPromise()
      .then(result =>
        result.availableEmail ? undefined : "Email is already used"
      );
  const emailFieldValidator = composeFieldValidators(
    requiredStringValidator,
    emailValidator
    // emailAvailableValidator TODO: restore this
  );

  const userType = (() => {
    switch (referral.__typename) {
      case "IndifyToArtistReferralNode":
      case "PartnerToArtistReferralNode":
        return "artist";
      case "IndifyToPartnerReferralNode":
      case "PartnerToPartnerReferralNode":
      case "PartnerToCompanyPartnerReferralNode":
        return "partner";
    }
  })();

  return (
    <AutoLayout
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
      spacing={48}
    >
      <Header size={["small", "small", "large", "large"]}>
        Confirm your account info
      </Header>

      <FieldBuilder
        fieldName={"name"}
        inputField={TextInput}
        initialValue={referral.recipientDisplayName}
        validator={requiredStringValidator}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: userType === "artist" ? "Display name" : "Your full name",
          caption: [
            "This is how you will appear to the indify Partnerships community.",
            userType === "artist" && "You can change this at any time."
          ]
            .filter(Boolean)
            .join(" ")
        }}
      />
      {userType === "partner" && <SectionCompany referral={referral} />}
      <FieldBuilder
        fieldName={"email"}
        inputField={TextInput}
        validator={emailFieldValidator}
        initialValue={referral.recipientEmail}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Email address",
          caption: "This is what you will use to sign in to your account."
        }}
      />
      <FieldBuilder
        fieldName={"password"}
        inputField={PasswordInput}
        validator={composeFieldValidators(
          requiredStringValidator,
          getMinimumLengthStringValidator(
            8,
            "Your password must be at least 8 characters."
          )
        )}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Choose password",
          caption: "Your password must be at least 8 characters in length."
        }}
      />
    </AutoLayout>
  );
}

export default ConfirmAccountInfo;
