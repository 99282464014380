import { WatchArtistAction_artist$key } from "__generated__/WatchArtistAction_artist.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";

import getToggleWatchArtist from "../../../../../../../mutations/ToggleWatchArtistMutation";
import { useActiveResponsiveValue } from "../../../../../../../utils/responsiveUtils";
import WatchAction, { WatchActionSize } from "./WatchAction";

/**
 * Data wrapper for <WatchAction>
 */
function WatchArtistAction(props: {
  artist: WatchArtistAction_artist$key;
  size: ResponsiveValue<WatchActionSize>;
}): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const artist = useFragment(
    graphql`
      fragment WatchArtistAction_artist on ArtistNode {
        id
        watching
      }
    `,
    props.artist
  );

  return (
    <WatchAction
      toggleWatch={getToggleWatchArtist(artist.id)}
      size={activeSize}
      isWatching={artist.watching}
    />
  );
}

export default WatchArtistAction;
