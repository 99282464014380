import { PartnerViewRoster_artistRoster$key } from "__generated__/PartnerViewRoster_artistRoster.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Text } from "theme-ui";

import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import TeamList from "./TeamList";

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1368%3A14174
 */
function PartnerViewRoster(props: {
  partner: PartnerViewRoster_artistRoster$key;
}): ReactElement {
  const partner = useFragment(
    graphql`
      fragment PartnerViewRoster_artistRoster on PartnerNode {
        offers(isAccepted: true) {
          edges {
            node {
              ...TeamList_offers
            }
          }
        }
      }
    `,
    props.partner
  );

  const offers = partner.offers.edges.map(({ node }) => node);

  return (
    <AccountManagementFormContent>
      <AccountManagementHeading
        key={"heading"}
        size={["small", "large", "large", "large"]}
        title={"Artist Roster"}
        description={"See which artists you’ve partnered with on indify."}
      />
      {offers.length > 0 ? (
        <TeamList
          size={["small", "medium", "large", "large"]}
          offers={offers}
        />
      ) : (
        <Text variant={"bodyMedium"} color={"deepGray100"}>
          You don’t have any artists on indify just yet.
        </Text>
      )}
    </AccountManagementFormContent>
  );
}

export default PartnerViewRoster;
