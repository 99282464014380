/**
 * @generated SignedSource<<e000a4277761786785cb7c5872ad7f4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReleaseTypeEnum = "album" | "compilation" | "single";
import { FragmentRefs } from "relay-runtime";
export type UpdateOrCreateReleaseModal_release$data = {
  readonly __typename: string;
  readonly id: string;
  readonly isVisible: boolean;
  readonly name: string;
  readonly releaseType: ReleaseTypeEnum | null;
  readonly soundLink: string | null;
  readonly tracksCount: number | null;
  readonly " $fragmentType": "UpdateOrCreateReleaseModal_release";
};
export type UpdateOrCreateReleaseModal_release$key = {
  readonly " $data"?: UpdateOrCreateReleaseModal_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateOrCreateReleaseModal_release">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateOrCreateReleaseModal_release",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "soundLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVisible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tracksCount",
      "storageKey": null
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "bb4d745831a66055506d3dad200e251b";

export default node;
