import { KeyPerformanceStats_artist$key } from "__generated__/KeyPerformanceStats_artist.graphql";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import ComplexRowText from "../../../../../components/01_Core/Tables/ComplexRowText";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { formatNumber } from "../../../../../utils/stringFormatting";
import { avgTimeseries, sumTimeseries } from "../../../../../utils/timeseries";

type KeyPerformanceStatsSize = "small" | "large";

interface IKeyPerformanceStatsProps {
  size: ResponsiveValue<KeyPerformanceStatsSize>;
  artist: KeyPerformanceStats_artist$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
function KeyPerformanceStats(props: IKeyPerformanceStatsProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const artist = useFragment(
    graphql`
      fragment KeyPerformanceStats_artist on ArtistNode {
        displayName
        luminateWeekDatas {
          value
          weekStartDay
        }
      }
    `,
    props.artist
  );

  if (!artist.luminateWeekDatas) return null;

  const fullTimeseries = artist.luminateWeekDatas.map(
    ({ value, weekStartDay }) => ({
      x: new Date(weekStartDay as string),
      y: value
    })
  );

  const filterTimeseriesWeeks = (weeks: number) => {
    const last = fullTimeseries[fullTimeseries.length - 1];
    if (!last) return [];
    const cutoff = dayjs(last.x).subtract(weeks, "week").valueOf();
    return fullTimeseries.filter(({ x }) => x.getTime() > cutoff);
  };

  const avgDailyStreams28Days = avgTimeseries(filterTimeseriesWeeks(4)) / 7;
  const totalStreams6Months = sumTimeseries(filterTimeseriesWeeks(26));
  const revenue6Months = totalStreams6Months * GLOBALS.DOLLARS_PER_STREAM;

  const titleVariant = activeSize === "large" ? "h500" : "h300";
  const captionVariant = activeSize === "large" ? "bodyMedium" : "bodySmall";

  return (
    <AutoLayout
      spacing={24}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      <AutoLayout spacing={0} dependentProps={{ direction: "vertical" }}>
        <Text variant={titleVariant} color={"deepGray100"}>
          {artist.displayName}
        </Text>
        <Text variant={titleVariant}>Key Performance Stats</Text>
      </AutoLayout>
      {/* Table */}
      <Box sx={{ width: "100%" }}>
        <Flex sx={{ width: "100%" }}>
          <ComplexRowText
            align={"left"}
            size={props.size}
            type={"default"}
            emphasis={"low"}
            sx={{ width: "160px" }}
          >
            {`+${formatNumber(avgDailyStreams28Days / 1000)}K`}
          </ComplexRowText>
          <ComplexRowText
            align={"right"}
            size={props.size}
            type={"default"}
            emphasis={"low"}
            sx={{ flexGrow: 1 }}
          >
            Daily streams average in the past 28 days
          </ComplexRowText>
        </Flex>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Flex sx={{ width: "100%" }}>
          <ComplexRowText
            align={"left"}
            size={props.size}
            type={"default"}
            emphasis={"low"}
            sx={{ width: "160px" }}
          >
            {`+${formatNumber(totalStreams6Months / 1000000)}M`}
          </ComplexRowText>
          <ComplexRowText
            align={"right"}
            size={props.size}
            type={"default"}
            emphasis={"low"}
            sx={{ flexGrow: 1 }}
          >
            Streams in the past 6 months
          </ComplexRowText>
        </Flex>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Flex sx={{ width: "100%" }}>
          <ComplexRowText
            align={"left"}
            size={props.size}
            type={"default"}
            emphasis={"low"}
            sx={{ width: "160px" }}
          >
            {`+$${formatNumber(revenue6Months / 1000)}K`}
          </ComplexRowText>
          <ComplexRowText
            align={"right"}
            size={props.size}
            type={"default"}
            emphasis={"low"}
            sx={{ flexGrow: 1 }}
          >
            Revenue in the past 6 months
          </ComplexRowText>
        </Flex>
      </Box>
      <Text variant={captionVariant} color={"deepGray100"}>
        Represents streaming volume on all DSPs across catalog; revenue
        estimates based on ${GLOBALS.DOLLARS_PER_STREAM}/stream and 100% pre-
        and 25% post-recoupment splits
      </Text>
    </AutoLayout>
  );
}

export default KeyPerformanceStats;
