import { numberBreakpoints } from "./theme";

export default {
  mobile: `screen and (min-width: 0) and (max-width: ${
    numberBreakpoints[0] - 1
  }px)`,
  tablet: `screen and (min-width: ${numberBreakpoints[0]}px) and (max-width: ${
    numberBreakpoints[1] - 1
  }px)`,
  tabletLarge: `screen and (min-width: ${
    numberBreakpoints[1]
  }px) and (max-width: ${numberBreakpoints[2] - 1}px)`,
  desktop: `screen and (min-width: ${numberBreakpoints[2]}px)`
};
