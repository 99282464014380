import { TikTokData_featuredDeal$key } from "__generated__/TikTokData_featuredDeal.graphql";
import _ from "lodash";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import {
  avgDeltaTimeseries,
  graphablePoints
} from "../../../../../utils/timeseries";
import { formatNumeral } from "../../../../../utils/utils";
import { SocialIconLabel } from "../../03_UI_Kit/Discover/RowHeaderDeal";
import Timeseries, { TimeseriesSize } from "../../04_User_Profile/Timeseries";

interface ITikTokData {
  featuredDeal: TikTokData_featuredDeal$key;
  size: ResponsiveValue<TimeseriesSize>;
}

type TikTokGraph = "soundPosts" | "followers";

export default function TikTokData(props: ITikTokData): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment TikTokData_featuredDeal on FeaturedDealNode {
        tiktokSounds {
          edges {
            node {
              data {
                date
                value
              }
            }
          }
        }
        artist {
          tiktokProfile {
            tiktokProfileData {
              edges {
                node {
                  date
                  followerCount
                }
              }
            }
          }
        }
      }
    `,
    props.featuredDeal
  );

  const graphs = {
    soundPosts: {
      label: <SocialIconLabel platform={"TIKTOK"}>UGCs</SocialIconLabel>,
      timeseriesProps: {
        statName: "Total TikTok UGCs",
        windowSummaryStatName: "Average new TikTok UGCs per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data: graphablePoints(
        _.map(
          _.groupBy(
            featuredDeal.tiktokSounds.edges
              .map(({ node }) => node)
              .flatMap(node =>
                node.data.map(({ date, value }) => ({
                  y: value,
                  x: new Date(date as string)
                }))
              ),
            ({ x }) => x.getTime()
          ),
          points => ({
            x: points[0].x,
            y: _.sumBy(points, ({ y }) => y)
          })
        )
      )
    },
    followers: {
      label: <SocialIconLabel platform={"TIKTOK"}>Followers</SocialIconLabel>,
      timeseriesProps: {
        statName: "Total TikTok followers",
        windowSummaryStatName: "Average new TikTok followers per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data: graphablePoints(
        featuredDeal?.artist?.tiktokProfile?.tiktokProfileData?.edges.map(
          ({ node }) => ({
            y: node.followerCount,
            x: new Date(new Date(node.date as string))
          })
        ) ?? []
      )
    }
  };

  // Hide the graph section if there is no data
  if (Object.values(graphs).every(({ data }) => data.length === 0)) {
    return null;
  }

  const [selectedGraph, setSelectedGraph] = useState<TikTokGraph>(
    Object.keys(graphs).find(
      key => graphs[key as TikTokGraph].data.length
    ) as TikTokGraph
  );

  return (
    <AutoLayout
      spacing={32}
      dependentProps={{ direction: "vertical" }}
      sx={{ width: "100%" }}
    >
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "horizontal" }}
        sx={{ width: "100%" }}
      >
        {Object.entries(graphs).map(([key, graph]) => {
          const canSelect = graph.data.length > 0;
          const graphLastDataPoint = graph.data[graph.data.length - 1];
          return (
            <Box
              key={key}
              sx={{
                width: "100%",
                paddingBottom: "16px",
                borderBottom: "2px solid",
                borderColor: selectedGraph === key ? "black100" : "midGray100",
                ":hover": canSelect && { borderColor: "black100" },
                cursor: canSelect ? "pointer" : "not-allowed"
              }}
              onClick={() => canSelect && setSelectedGraph(key as TikTokGraph)}
            >
              <Text color={canSelect ? "black100" : "midGray100"}>
                <AutoLayout
                  spacing={8}
                  dependentProps={{ direction: "vertical" }}
                >
                  <Text variant={"h400"}>
                    {graphLastDataPoint
                      ? formatNumeral(graphLastDataPoint.y)
                      : "—"}
                  </Text>
                  <Text>{graph.label}</Text>
                </AutoLayout>
              </Text>
            </Box>
          );
        })}
      </AutoLayout>
      <Timeseries
        timeseries={graphs[selectedGraph].data}
        windowGranularity={"day"}
        emptyText={"No data to show"}
        {...graphs[selectedGraph].timeseriesProps}
      />
    </AutoLayout>
  );
}
