import { useArtistCanViewDeals_authUser$key } from "__generated__/useArtistCanViewDeals_authUser.graphql";
import { graphql, useFragment } from "react-relay";

import { useAuth } from "./useAuth";
import useCanAccessMarketplace from "./useCanAccessMarketplace";

export default function useArtistCanViewDeals(): [boolean, string] {
  const authUser = useFragment<useArtistCanViewDeals_authUser$key>(
    graphql`
      fragment useArtistCanViewDeals_authUser on UserNode {
        artist {
          offers(isAccepted: true) {
            edges {
              node {
                __typename
              }
            }
          }
        }
      }
    `,
    useAuth().authUser
  );

  const [canAccessMarketplace, cannotAccessMarketplaceText] =
    useCanAccessMarketplace();

  const couldHaveDeals =
    canAccessMarketplace || authUser.artist?.offers.edges.length > 0;

  if (couldHaveDeals) {
    return [true, null];
  } else {
    return [false, cannotAccessMarketplaceText];
  }
}
