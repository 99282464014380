import { UpdatePasswordFormMutation } from "__generated__/UpdatePasswordFormMutation.graphql";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import { FormEvent, ReactElement, useContext } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { commitMutation, graphql } from "react-relay";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../components/01_Core/Forms/FieldBuilder";
import PasswordInput from "../../../../components/01_Core/Forms/Inputs/PasswordInput";
import {
  composeFieldValidators,
  getMinimumLengthStringValidator,
  requiredStringValidator
} from "../../../../components/01_Core/Forms/utils/validators";
import environment from "../../../../environment";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import Footer from "./Footer";

interface IUpdatePasswordFormValues {
  currentPassword: string;
  newPassword: string;
}

const updatePasswordMutation = graphql`
  mutation UpdatePasswordFormMutation(
    $currentPassword: String!
    $newPassword: String!
  ) {
    updatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      ok
    }
  }
`;

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1368%3A13331
 */
function UpdatePasswordForm(): ReactElement {
  const notifyBanner = useContext(BannerContext);
  const onSubmit = (values: IUpdatePasswordFormValues) => {
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });

      commitMutation<UpdatePasswordFormMutation>(environment, {
        mutation: updatePasswordMutation,
        variables: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword
        },
        onCompleted: (r, errors) => {
          // Success
          if (r.updatePassword?.ok) {
            notifyBanner({
              type: "neutral",
              children: "Your password has been updated"
            });
            return resolve("success");
            // Known errors
          } else if (
            errors != null &&
            _.some(errors, e => e.message === "currentPassword")
          ) {
            return resolve({
              currentPassword: "This does not match your current password."
            });
          } else {
            return resolveUnknownError();
          }
        },
        onError: () => {
          return resolveUnknownError();
        }
      });
    });
  };
  const validateUpdatePassword = (values: IUpdatePasswordFormValues) => {
    if (values[currentPasswordFieldName] === values[newPasswordFieldName]) {
      return {
        [newPasswordFieldName]:
          "Your new password must be different from your current one."
      };
    }

    return undefined;
  };

  const currentPasswordFieldName = "currentPassword";
  const newPasswordFieldName = "newPassword";

  return (
    <Form onSubmit={onSubmit} key={"form"} validate={validateUpdatePassword}>
      {(formProps: FormRenderProps<IUpdatePasswordFormValues>) => {
        const submitAndRestartFormOnSuccess = (
          e?: FormEvent<HTMLFormElement>
        ) => {
          formProps.handleSubmit(e)?.then(result => {
            if (typeof result === "string" && result === "success") {
              formProps.form.restart({
                [newPasswordFieldName]: "",
                [currentPasswordFieldName]: ""
              });
            }
          });
        };

        return (
          <form
            onSubmit={submitAndRestartFormOnSuccess}
            style={{ width: "100%" }}
          >
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Password"}
                description={"Update your password to keep your account safe."}
              />
              <AutoLayout
                spacing={32}
                resizingX={FillContainer()}
                dependentProps={{
                  direction: "vertical"
                }}
              >
                <FieldBuilder
                  key={currentPasswordFieldName}
                  fieldName={currentPasswordFieldName}
                  inputField={PasswordInput}
                  validator={requiredStringValidator}
                  inputFieldProps={{
                    size: ["medium", "large", "large", "large"],
                    label: "Current password"
                  }}
                />
                <FieldBuilder
                  key={newPasswordFieldName}
                  fieldName={newPasswordFieldName}
                  inputField={PasswordInput}
                  validator={composeFieldValidators(
                    requiredStringValidator,
                    getMinimumLengthStringValidator(
                      8,
                      "Your password must be at least 8 characters."
                    )
                  )}
                  inputFieldProps={{
                    size: ["medium", "large", "large", "large"],
                    label: "New password",
                    caption: formProps.touched[newPasswordFieldName]
                      ? formProps.errors[newPasswordFieldName]
                        ? undefined
                        : "Your password must be at least 8 characters."
                      : "Your password must be at least 8 characters."
                  }}
                />
              </AutoLayout>
              <Footer
                size={["small", "large", "large", "large"]}
                onClick={submitAndRestartFormOnSuccess}
                buttonDisabled={
                  formProps.pristine || formProps.hasValidationErrors
                }
                loading={formProps.submitting}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}

export default UpdatePasswordForm;
