import { DrillInQuery as DrillInQueryType } from "__generated__/DrillInQuery.graphql";
import { ReactElement, Suspense } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Link, Redirect } from "react-router-dom";

import Ic12ArrowDrillIn from "../../../../../../../imgs/icons/ic12-arrow-drill-in.svg";
import { Loader } from "../../../../../../components/01_Core/999_Miscellaneous/Loader";
import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import { ActorAvatarName } from "../../../../../../components/01_Core/Users/AvatarName";
import { Logo } from "../../../../../../components/02_Brand_Assets/Logo";
import { useBreakpoint } from "../../../../../../utils/useBreakpoints";
import usePathState from "../../../../hooks/usePathState";
import { InternalRoutes } from "../../../../Routing/InternalRoutes";
import MakeOfferAction from "../../Actions/Actions/MakeOfferAction";
import { MessageArtistAction } from "../../Actions/Actions/MessageAction";
import WatchArtistAction from "../../Actions/Actions/WatchAction/WatchArtistAction";

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1671%3A25789
 */
function DrillIn(): ReactElement {
  const breakpointMap = useBreakpoint();
  const { value: artistSlug } = usePathState("artistProfile", "artistSlug");

  function ArtistDisplayAndControls() {
    const DrillInQuery = graphql`
      query DrillInQuery($slug: String!) {
        artistBySlug(slug: $slug) {
          # eslint-disable-next-line relay/unused-fields
          id
          ...AvatarName_actor
          ...WatchArtistAction_artist
          ...MessageAction_artist
          ...MakeOfferAction_artist
        }
      }
    `;
    const artist = useLazyLoadQuery<DrillInQueryType>(DrillInQuery, {
      slug: artistSlug
    }).artistBySlug;

    if (artist == null) {
      return <Redirect to={InternalRoutes.featuredDealDirectory} />;
    }

    return (
      <>
        {!breakpointMap["mobile"] && (
          <ActorAvatarName actor={artist} size={"extraSmall"} disableLink />
        )}
        {
          <AutoLayout
            spacing={12}
            dependentProps={{ direction: "horizontal", alignment: "right" }}
            sx={{ flex: 1 }}
          >
            <WatchArtistAction
              artist={artist}
              size={["small", "small", "large", "large"]}
            />
            <MessageArtistAction
              artist={artist}
              size={["small", "small", "large", "large"]}
            />
            <MakeOfferAction
              artist={artist}
              size={["small", "small", "large", "large"]}
            />
          </AutoLayout>
        }
      </>
    );
  }

  return (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "horizontal", alignment: "left" }}
      sx={{
        flex: 1
      }}
    >
      <Link to={InternalRoutes.featuredDealDirectory}>
        <Logo />
      </Link>
      {!breakpointMap["mobile"] && <Ic12ArrowDrillIn />}
      <Suspense fallback={<Loader sx={{ width: "24px", height: "24px" }} />}>
        <ArtistDisplayAndControls />
      </Suspense>
    </AutoLayout>
  );
}

export default DrillIn;
