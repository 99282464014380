import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../components/01_Core/Buttons/LoadingButton";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { rvMap } from "../../../../utils/responsiveUtils";

type FooterSize = "small" | "large";
interface IFooterProps {
  size: ResponsiveValue<FooterSize>;
  onClick: () => void;
  loading: boolean;
  buttonDisabled?: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?type=design&node-id=1363%3A6&t=AMJPMULnNpz74CXi-1
 */
function Footer(props: IFooterProps): ReactElement {
  return (
    <AutoLayout
      spacing={rvMap(props.size, getSpacing)}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical"
      }}
    >
      <Divider />
      <LoadingButton
        onClick={props.onClick}
        variant={"primary"}
        size={rvMap(props.size, getButtonSize)}
        disabled={props.buttonDisabled}
        loading={props.loading}
        sx={{ width: "100%" }}
      >
        Save updates
      </LoadingButton>
    </AutoLayout>
  );
}

function getSpacing(size: FooterSize) {
  switch (size) {
    case "small":
      return 32;
    case "large":
      return 48;
  }
}

function getButtonSize(size: FooterSize) {
  switch (size) {
    case "small":
      return "small";
    case "large":
      return "medium";
  }
}

export default Footer;
