import { useToggleCheckActionItem_actionItem$key } from "__generated__/useToggleCheckActionItem_actionItem.graphql";
import { useToggleCheckActionItem_Mutation } from "__generated__/useToggleCheckActionItem_Mutation.graphql";
import { graphql, useFragment } from "react-relay";

import useMutationPromise from "../pages/indify/hooks/useMutationPromise";

export default function useToggleCheckActionItem(
  actionItem: useToggleCheckActionItem_actionItem$key
): [() => void, boolean] {
  const { id: actionItemId, checked: actionItemChecked } = useFragment(
    graphql`
      fragment useToggleCheckActionItem_actionItem on ActionItemNode {
        id
        checked
      }
    `,
    actionItem
  );

  const [commit, isInFlight] =
    useMutationPromise<useToggleCheckActionItem_Mutation>(graphql`
      mutation useToggleCheckActionItem_Mutation($actionItemId: ID!) {
        toggleCheckActionItem(actionItemId: $actionItemId) {
          actionItem {
            checked
          }
        }
      }
    `);

  return [
    () =>
      commit({
        variables: { actionItemId: actionItemId },
        optimisticResponse: {
          toggleCheckActionItem: {
            actionItem: {
              id: actionItemId,
              checked: !actionItemChecked
            }
          }
        }
      }),
    isInFlight
  ];
}
