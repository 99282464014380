/**
 * @generated SignedSource<<f48bee620a207164eb8e14656da1ff8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPartnerBasicInfoMutationInput = {
  about?: string | null;
  displayName: string;
  location?: string | null;
  partnerNodeId: string;
  slug: string;
};
export type BasicInfoFormPartnerMutation$variables = {
  input: EditPartnerBasicInfoMutationInput;
};
export type BasicInfoFormPartnerMutation$data = {
  readonly editPartnerBasicInfo: {
    readonly partner: {
      readonly displayName: string;
      readonly user: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"BasicInfoFormPartner_partner">;
    } | null;
  } | null;
};
export type BasicInfoFormPartnerMutation = {
  response: BasicInfoFormPartnerMutation$data;
  variables: BasicInfoFormPartnerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BasicInfoFormPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPartnerBasicInfoMutation",
        "kind": "LinkedField",
        "name": "editPartnerBasicInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PartnerNode",
            "kind": "LinkedField",
            "name": "partner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BasicInfoFormPartner_partner"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BasicInfoFormPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPartnerBasicInfoMutation",
        "kind": "LinkedField",
        "name": "editPartnerBasicInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PartnerNode",
            "kind": "LinkedField",
            "name": "partner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "about",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d511b710a9ef3366bb5ea4630d2a407f",
    "id": null,
    "metadata": {},
    "name": "BasicInfoFormPartnerMutation",
    "operationKind": "mutation",
    "text": "mutation BasicInfoFormPartnerMutation(\n  $input: EditPartnerBasicInfoMutationInput!\n) {\n  editPartnerBasicInfo(data: $input) {\n    partner {\n      displayName\n      user {\n        id\n        email\n      }\n      ...BasicInfoFormPartner_partner\n      id\n    }\n  }\n}\n\nfragment BasicInfoFormPartner_partner on PartnerNode {\n  id\n  thumbnailUrl\n  displayName\n  location\n  about\n  slug\n}\n"
  }
};
})();

(node as any).hash = "bf02809fd971473948880057f93552cb";

export default node;
