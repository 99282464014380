import * as _ from "lodash";

export type ReturnProfileColumnName =
  | "decline"
  | "staticGrowth"
  | "strongGrowth"
  | "massiveGrowth";
export type ReturnProfileRowName =
  | "streamsPerDay"
  | "termLengthReturns"
  | "termLengthReturnsCoefficient"
  | "monthsToRecoup";
export type ReturnProfileColumnData = {
  [rowName in ReturnProfileRowName]: number;
};
export type ReturnProfile = {
  [columnName in ReturnProfileColumnName]: ReturnProfileColumnData;
};

function getReturnProfile(
  currentStreamsPerDay: number,
  termLengthMonths: number,
  advanceDollars: number,
  partnerSplitPreRecoupment: number,
  partnerSplitPostRecoupment: number
): ReturnProfile {
  const streamDecayPerYear = 0.5;
  const indifyPercentage = 0.15;
  const columnNameCoefficients: [ReturnProfileColumnName, number][] = [
    ["decline", 0.5],
    ["staticGrowth", 1],
    ["strongGrowth", 3],
    ["massiveGrowth", 10]
  ];
  const initialAcc: ReturnProfile = {
    decline: {
      streamsPerDay: 0,
      termLengthReturns: 0,
      termLengthReturnsCoefficient: 0,
      monthsToRecoup: 0
    },
    staticGrowth: {
      streamsPerDay: 0,
      termLengthReturns: 0,
      termLengthReturnsCoefficient: 0,
      monthsToRecoup: 0
    },
    strongGrowth: {
      streamsPerDay: 0,
      termLengthReturns: 0,
      termLengthReturnsCoefficient: 0,
      monthsToRecoup: 0
    },
    massiveGrowth: {
      streamsPerDay: 0,
      termLengthReturns: 0,
      termLengthReturnsCoefficient: 0,
      monthsToRecoup: 0
    }
  };

  return columnNameCoefficients.reduce((acc, [columnName, coefficient]) => {
    const streamsPerDay = currentStreamsPerDay * coefficient;
    const streamsPerYear = _.mean([
      streamsPerDay * 365,
      streamsPerDay * streamDecayPerYear * 365
    ]);
    const streamsPerMonth = streamsPerYear / 12;
    const cumulativeRevenueMonths = _.range(termLengthMonths).map(
      month => streamsPerMonth * GLOBALS.DOLLARS_PER_STREAM * (month + 1)
    );
    const partnerRevenueMonths = cumulativeRevenueMonths.map((crm, i) => {
      if (crm < advanceDollars) {
        return crm * (partnerSplitPreRecoupment / 100);
      } else {
        return (
          advanceDollars +
          (crm - advanceDollars) *
            (partnerSplitPostRecoupment / 100) *
            (1 - indifyPercentage)
        );
      }
    });
    const termLengthReturns = _.round(
      partnerRevenueMonths[partnerRevenueMonths.length - 1]
    );

    acc[columnName] = {
      streamsPerDay,
      termLengthReturns,
      termLengthReturnsCoefficient: _.round(
        termLengthReturns / advanceDollars,
        1
      ),
      monthsToRecoup: _.floor(
        ((advanceDollars / (partnerSplitPreRecoupment / 100)) * 12) /
          (GLOBALS.DOLLARS_PER_STREAM * streamsPerYear)
      )
    };

    return acc;
  }, initialAcc);
}

export default getReturnProfile;
