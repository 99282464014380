import { ReactElement } from "react";
import { Flex, Link } from "theme-ui";

import { Logo } from "../../../../../components/02_Brand_Assets/Logo";

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1365%3A24771
 */
function SimpleNav(): ReactElement {
  return (
    <Flex
      bg={"white100"}
      sx={{
        height: 56,
        flexGrow: 0,
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        width: "100%"
      }}
    >
      <Link href={window.location.origin}>
        <Logo />
      </Link>
    </Flex>
  );
}

export default SimpleNav;
