/**
 * @generated SignedSource<<c1756d42e0203945ba21f381a3edc4b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistPartnershipStatus = "ACTIVELY_LOOKING" | "NOT_LOOKING" | "OPEN";
export type ArtistSeekingServiceService = "CONTENT_PRODUCTION" | "DISTRIBUTION" | "FUNDING" | "INDUSTRY" | "INFLUENCER" | "MARKETING";
export type EditArtistBasicInfoMutationInput = {
  about?: string | null;
  artistNodeId: string;
  displayName: string;
  genreNodeId?: string | null;
  location?: string | null;
  partnershipStatus: ArtistPartnershipStatus;
  seekingServices?: ReadonlyArray<ArtistSeekingServiceService | null> | null;
  slug: string;
  visible?: boolean | null;
};
export type BasicInfoFormArtistMutation$variables = {
  input: EditArtistBasicInfoMutationInput;
};
export type BasicInfoFormArtistMutation$data = {
  readonly editArtistBasicInfo: {
    readonly artist: {
      readonly displayName: string;
      readonly user: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"BasicInfoFormArtist_artist">;
    } | null;
  } | null;
};
export type BasicInfoFormArtistMutation = {
  response: BasicInfoFormArtistMutation$data;
  variables: BasicInfoFormArtistMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BasicInfoFormArtistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditArtistBasicInfoMutation",
        "kind": "LinkedField",
        "name": "editArtistBasicInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BasicInfoFormArtist_artist"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BasicInfoFormArtistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditArtistBasicInfoMutation",
        "kind": "LinkedField",
        "name": "editArtistBasicInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "about",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GenreNode",
                "kind": "LinkedField",
                "name": "genre",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visible",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "partnershipStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ArtistSeekingServiceNodeConnection",
                "kind": "LinkedField",
                "name": "seekingServices",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArtistSeekingServiceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ArtistSeekingServiceNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "service",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "167a3a20f7c74a8c000fc6cf619a528b",
    "id": null,
    "metadata": {},
    "name": "BasicInfoFormArtistMutation",
    "operationKind": "mutation",
    "text": "mutation BasicInfoFormArtistMutation(\n  $input: EditArtistBasicInfoMutationInput!\n) {\n  editArtistBasicInfo(data: $input) {\n    artist {\n      displayName\n      user {\n        id\n        email\n      }\n      ...BasicInfoFormArtist_artist\n      id\n    }\n  }\n}\n\nfragment BasicInfoFormArtist_artist on ArtistNode {\n  id\n  thumbnailUrl\n  displayName\n  location\n  about\n  genre {\n    id\n  }\n  visible\n  slug\n  partnershipStatus\n  seekingServices {\n    edges {\n      node {\n        service\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95805321470a1b55a3b3210fd35587db";

export default node;
