/**
 * @generated SignedSource<<5f8bec125477697ef04a17f992592c48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Release_release$data = {
  readonly thumbnailUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseDetails_release">;
  readonly " $fragmentType": "Release_release";
};
export type Release_release$key = {
  readonly " $data"?: Release_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"Release_release">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Release_release",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseDetails_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "f1c31b01c7438a61bc6fb8f5d3141276";

export default node;
