/**
 * @generated SignedSource<<109a626e910ab3d886a3a45042f7d4d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferFormHeader_artist$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OfferPartnership_artist">;
  readonly " $fragmentType": "OfferFormHeader_artist";
};
export type OfferFormHeader_artist$key = {
  readonly " $data"?: OfferFormHeader_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferFormHeader_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferFormHeader_artist",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferPartnership_artist"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "1c21c537a13cbf777b67909d1f1d568c";

export default node;
