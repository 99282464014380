import { ArtistReferrals_partner$key } from "__generated__/ArtistReferrals_partner.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box } from "theme-ui";

import ReferralRecipientActive from "../03_UI_Kit/User_Directory/ReferralRecipient/ReferralRecipientActive";
import ReferralRecipientInvited from "../03_UI_Kit/User_Directory/ReferralRecipient/ReferralRecipientInvited";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import ReferralInput from "./ReferralInput";

export default function ArtistReferrals(props: {
  partner: ArtistReferrals_partner$key;
}): ReactElement {
  const partner = useFragment(
    graphql`
      fragment ArtistReferrals_partner on PartnerNode {
        id
        artistReferrals {
          edges {
            node {
              id
              ...ReferralRecipientInvited_referral
              recipientArtist {
                ...ReferralRecipientActive_recipient
              }
            }
          }
        }
      }
    `,
    props.partner
  );

  const referrals = partner.artistReferrals.edges.map(e => e.node);
  const [joinedReferrals, invitedReferrals] = _.partition(
    referrals,
    r => r.recipientArtist
  );

  return (
    <AccountManagementFormContent>
      <AccountManagementHeading
        key={"heading"}
        size={["small", "small", "small", "large"]}
        title={`Refer artists`}
        description={"Invite new artists to join indify."}
      />
      <ReferralInput
        size={["small", "small", "small", "large"]}
        typename="PartnerToArtistReferralNode"
      />
      <Box sx={{ width: "100%" }}>
        {joinedReferrals.map(p => (
          <ReferralRecipientActive key={p.id} recipient={p.recipientArtist} />
        ))}
        {invitedReferrals.map(r => (
          <ReferralRecipientInvited key={r.id} referral={r} />
        ))}
      </Box>
    </AccountManagementFormContent>
  );
}
