import { ReactElement } from "react";

import { IFundingOfferFormFieldProps } from "../../../07_Deal_Flow/OfferFormFunding";
import ReleasesIncluded, { IReleasesIncludedProps } from "./ReleasesIncluded";

interface IOfferFormReleasesIncludedProps extends IFundingOfferFormFieldProps {
  artist: IReleasesIncludedProps["artist"];
  showCopartnerWarnings?: boolean;
}

/**
 * figma: N/A
 */
function OfferFormReleasesIncluded(
  props: IOfferFormReleasesIncludedProps
): ReactElement {
  return (
    <ReleasesIncluded
      {...props}
      artist={props.artist}
      canCreateUnreleasedContent={false}
      showCopartnerWarnings={props.showCopartnerWarnings}
    />
  );
}

export default OfferFormReleasesIncluded;
