import { FundTestimonial_companytestimonial$key } from "__generated__/FundTestimonial_companytestimonial.graphql";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { ActorAvatarName } from "../../../../components/01_Core/Users/AvatarName";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import BlockLowEmphasis from "../03_UI_Kit/Marketing/BlockLowEmphasis";
import { ExpandableText } from "./About";
import { FundTestimonialsSize } from "./FundTestimonials";

interface IFundTestimonialProps {
  testimonial: FundTestimonial_companytestimonial$key;
  size: ResponsiveValue<FundTestimonialsSize>;
}

function FundTestimonial(props: IFundTestimonialProps): ReactElement {
  const testimonial = useFragment(
    graphql`
      fragment FundTestimonial_companytestimonial on CompanyTestimonialNode {
        testimonial
        createdAt
        artist {
          ...AvatarName_actor
        }
      }
    `,
    props.testimonial
  );

  const activeSize = useActiveResponsiveValue(props.size);

  return (
    <BlockLowEmphasis
      size={props.size === "small" ? "small" : "large"}
      sx={{ width: "100%" }}
    >
      <AutoLayout
        spacing={16}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical" }}
      >
        <AutoLayout
          spacing={0}
          resizingX={FillContainer()}
          dependentProps={{
            direction: "horizontal",
            alignment: "center",
            distribution: "spaceBetween"
          }}
        >
          <ActorAvatarName
            actor={testimonial.artist}
            size={activeSize === "small" ? "small" : "medium"}
          />
          <Text
            variant={activeSize === "small" ? "bodySmall" : "bodyMedium"}
            color={"deepGray100"}
          >
            {dayjs(testimonial.createdAt).format("MMMM D, YYYY")}
          </Text>
        </AutoLayout>
        <ExpandableText size={activeSize}>
          <Text variant={activeSize === "small" ? "bodyMedium" : "bodyLarge"}>
            {testimonial.testimonial}
          </Text>
        </ExpandableText>
      </AutoLayout>
    </BlockLowEmphasis>
  );
}

export default FundTestimonial;
