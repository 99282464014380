/**
 * @generated SignedSource<<9cf99fc97c082a96222d51552e97d42b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompanyServiceService = "CONTENT_PRODUCTION" | "DISTRIBUTION" | "FUNDING" | "INDUSTRY" | "INFLUENCER" | "MARKETING";
import { FragmentRefs } from "relay-runtime";
export type RowFund_fund$data = {
  readonly artistsBacked: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"MultipleArtists_artists">;
  } | null> | null;
  readonly displayName: string;
  readonly partners: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user: {
          readonly lastActiveAt: any | null;
        } | null;
      } | null;
    } | null>;
  };
  readonly ranked: number | null;
  readonly recipientPartner: {
    readonly user: {
      readonly chatWithCurrentUser: {
        readonly " $fragmentSpreads": FragmentRefs<"useArtistCanMessageCompany_chat">;
      } | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"useMessage_actor">;
  } | null;
  readonly services: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly service: CompanyServiceService;
      } | null;
    } | null>;
  };
  readonly slug: string;
  readonly thumbnailUrl: string;
  readonly totalStreams: number;
  readonly watching: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"useToggleWatchCompany_company">;
  readonly " $fragmentType": "RowFund_fund";
};
export type RowFund_fund$key = {
  readonly " $data"?: RowFund_fund$data;
  readonly " $fragmentSpreads": FragmentRefs<"RowFund_fund">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RowFund_fund",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useToggleWatchCompany_company"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalStreams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ranked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watching",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "recipientPartner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatNode",
              "kind": "LinkedField",
              "name": "chatWithCurrentUser",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "useArtistCanMessageCompany_chat"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useMessage_actor"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNodeConnection",
      "kind": "LinkedField",
      "name": "partners",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PartnerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastActiveAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artistsBacked",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MultipleArtists_artists"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyServiceNodeConnection",
      "kind": "LinkedField",
      "name": "services",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyServiceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyServiceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "service",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CompanyNode",
  "abstractKey": null
};

(node as any).hash = "9c462111beb43d283dc963515fd93bc1";

export default node;
