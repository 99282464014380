/**
 * @generated SignedSource<<f811dbe9c0ff4c4ce36a58a0cd449a2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserEmailSubscriptionEmailType = "E01_PARTNER_GENERAL_INVITE" | "E02_ARTIST_GENERAL_INVITE" | "E03_PARTNER_INVITE_TO_COMPANY_TEAM" | "E04_MESSAGE_RECEIVED" | "E05_ARTIST_OFFER_RECEIVED" | "E07_PARTNER_OFFER_DECLINED" | "E09_ARTIST_OFFER_CANCELED" | "E10_TRANSFER_SENDER_MONEY_ON_THE_WAY" | "E11_TRANSFER_RECIPIENT_MONEY_ON_THE_WAY" | "E12_TRANSFER_SENDER_TRANSFER_COMPLETE" | "E13_ARTIST_DEAL_APPROVED" | "E13_TRANSFER_RECIPIENT_TRANSFER_COMPLETE" | "E15_PARTNER_NEW_ACTIONED_ARTIST_PROPOSAL" | "E16_PARTNER_NEW_HIGHLIGHTED_DEALS" | "E17_PARTNER_NEW_DEALS" | "E18_PARTNER_INVITE_FROM_PARTNER" | "E19_ARTIST_REMINDER_TO_CREATE_FEATURED_DEAL_FOR_NEW_RELEASES" | "E20_ARTIST_INVITE_FROM_PARTNER" | "E21_ARTIST_OFFER_ACCEPTED" | "E22_PARTNER_OFFER_ACCEPTED" | "E23_ARTIST_EARNINGS_DIRECTED" | "E24_PARTNER_EARNINGS_DIRECTED" | "E25_ARTIST_OFFER_REMINDER";
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type EmailSubscriptions_user$data = {
  readonly emailSubscriptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly emailType: UserEmailSubscriptionEmailType;
        readonly isSubscribed: boolean;
      } | null;
    } | null>;
  };
  readonly userType: UserType | null;
  readonly " $fragmentType": "EmailSubscriptions_user";
};
export type EmailSubscriptions_user$key = {
  readonly " $data"?: EmailSubscriptions_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailSubscriptions_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailSubscriptions_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEmailSubscriptionNodeConnection",
      "kind": "LinkedField",
      "name": "emailSubscriptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEmailSubscriptionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserEmailSubscriptionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emailType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSubscribed",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "bafbea0ab106207c9936e0c515b53e42";

export default node;
