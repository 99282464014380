import { PartnerSignupFlow_Mutation } from "__generated__/PartnerSignupFlow_Mutation.graphql";
import { FORM_ERROR } from "final-form";
import { ReactElement } from "react";
import { graphql, useMutation } from "react-relay";
import { PayloadError } from "relay-runtime";

import { useAuth } from "../../hooks/useAuth";
import {
  AcceptBasicTermsPage,
  LoginInfoPage,
  Pages,
  PartnerNameCompanyNamePage
} from "./Pages/Page";
import SignupFlow, { BaseSignupFormValues } from "./SignupFlow";

interface SignupFormValues extends BaseSignupFormValues {
  companyName: string;
}

function PartnerSignupFlow(): ReactElement {
  const { setMeFragmentRef } = useAuth();

  const [commitSignup] = useMutation<PartnerSignupFlow_Mutation>(graphql`
    mutation PartnerSignupFlow_Mutation($input: PartnerSignupMutationInput!) {
      partnerSignup(data: $input) {
        me {
          ...useAuth_MeFragment
        }
      }
    }
  `);

  const onSubmit = (values: SignupFormValues) => {
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });
      const resolveErrors = (errors: PayloadError[]) => {
        resolve({ [FORM_ERROR]: errors.map(e => e.message).join("\n") });
      };

      const { passwordConfirm: _, ...rest } = values; // Don't send password confirm over the wire

      commitSignup({
        variables: {
          input: {
            ...rest
          }
        },
        onCompleted: (response, errors) => {
          if (errors) {
            resolveErrors(errors);
          }

          setMeFragmentRef(response.partnerSignup.me);
          resolve(undefined);
        },
        onError: () => {
          resolveUnknownError();
        }
      });
    });
  };

  const pages: Pages[] = [
    LoginInfoPage,
    PartnerNameCompanyNamePage,
    AcceptBasicTermsPage
  ];

  return <SignupFlow<SignupFormValues> onSubmit={onSubmit} pages={pages} />;
}

export default PartnerSignupFlow;
