import { OfferMessageReleasesIncluded_offer$key } from "__generated__/OfferMessageReleasesIncluded_offer.graphql";
import { OfferMessageReleasesIncluded_releases$key } from "__generated__/OfferMessageReleasesIncluded_releases.graphql";
import { OfferMessageReleasesIncluded_unreleasedReleases$key } from "__generated__/OfferMessageReleasesIncluded_unreleasedReleases.graphql";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import ReleasesInfoRemovable from "../../../../../../components/01_Core/Releases/ReleasesInfoRemovable";
import OfferMessageSection, {
  OfferMessageSectionSize
} from "./OfferMessageSection";

interface IOfferMessageReleasesIncludedProps {
  offer: OfferMessageReleasesIncluded_offer$key;
  releases: OfferMessageReleasesIncluded_releases$key;
  unreleasedReleases: OfferMessageReleasesIncluded_unreleasedReleases$key;
  size: OfferMessageSectionSize;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=724%3A18739
 */
function OfferMessageReleasesIncluded(
  props: IOfferMessageReleasesIncludedProps
): ReactElement {
  const offer = useFragment(
    graphql`
      fragment OfferMessageReleasesIncluded_offer on OfferNode {
        ...ReleasesInfoRemovable_offerFeaturedDeal
      }
    `,
    props.offer
  );
  const releases = useFragment(
    graphql`
      fragment OfferMessageReleasesIncluded_releases on ReleaseNode
      @relay(plural: true) {
        id
        ...ReleasesInfoRemovable_release
      }
    `,
    props.releases
  );
  const unreleasedReleases = useFragment(
    graphql`
      fragment OfferMessageReleasesIncluded_unreleasedReleases on UnreleasedReleaseNode
      @relay(plural: true) {
        id
        ...ReleasesInfoRemovable_release
      }
    `,
    props.unreleasedReleases
  );

  const description = (
    <AutoLayout
      spacing={16}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      {[...releases, ...unreleasedReleases].map(r => (
        <ReleasesInfoRemovable
          key={r.id}
          offerFeaturedDeal={offer}
          release={r}
          size={getReleasesInfoRemovableSize(props.size)}
        />
      ))}
    </AutoLayout>
  );

  return (
    <OfferMessageSection
      size={props.size}
      heading={"Releases Included"}
      description={description}
    />
  );
}

function getReleasesInfoRemovableSize(size: OfferMessageSectionSize) {
  switch (size) {
    case "small":
      return "small";
    case "medium":
      return "large";
  }
}

export default OfferMessageReleasesIncluded;
