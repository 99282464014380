import Ajv, { ErrorObject, ValidateFunction } from "ajv";
import addErrors from "ajv-errors";
import addFormats from "ajv-formats";
import { JsonPointer } from "json-ptr";
import * as _ from "lodash";

// TODO: pass in required thing here.
// TODO: this function may want to take in an error message map
function useSchemaValidation<FieldType>(
  schemaJson: any, // TODO: come up with a better type for this
  required = true
): (value?: FieldType) => any {
  const ajv = new Ajv({ allErrors: true });
  addFormats(ajv, ["date"]);
  addErrors(ajv);

  const compiledSchemaValidator: ValidateFunction<FieldType> =
    ajv.compile<FieldType>(schemaJson);

  const validator = compiledSchemaValidator;

  return (value?: FieldType) => {
    if (value == null && !required) {
      return;
    }
    const isValid = validator(value);
    if (isValid) {
      return;
    }

    const errors: ErrorObject[] = validator.errors;
    // ErrorObject.dataPath is a json pointer to where in the passed in data
    // the error occurred. We use this to construct an object with errors at the
    // correct paths.
    const nestedErrors = _.filter(errors, e => e.dataPath !== "");
    if (!_.isEmpty(nestedErrors)) {
      return _.reduce(
        nestedErrors,
        (returnError, error) => {
          const ptr = JsonPointer.create(error.dataPath);
          // .set mutates. BOO THIS MAN
          ptr.set(returnError, error.message, true);
          return returnError;
        },
        {}
      );
    }

    // TODO: return errors in a better way.
    return _.join(
      validator.errors.map(e => e.message),
      "\n"
    );
  };
}

export default useSchemaValidation;
