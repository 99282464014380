import dayjs from "dayjs";

import { percentChange } from "./math";
export interface ITimeseriesPoint {
  x: Date;
  y?: number;
}

export function graphablePoints(
  timeseries: Array<ITimeseriesPoint>
): Array<ITimeseriesPoint> {
  return timeseries.filter(({ y }) => y != null);
}

export function sumTimeseries(timeseries: Array<ITimeseriesPoint>): number {
  return timeseries.reduce((acc, { y }) => acc + (y !== null ? y : 0), 0);
}

function withoutNullStats(timeseries: Array<ITimeseriesPoint>) {
  return timeseries.filter(({ y }) => y !== null);
}

export function avgTimeseries(timeseries: Array<ITimeseriesPoint>): number {
  timeseries = withoutNullStats(timeseries);
  if (timeseries.length === 0) {
    return 0;
  }

  return sumTimeseries(timeseries) / timeseries.length;
}

export function avgDeltaTimeseries(
  timeseries: Array<ITimeseriesPoint>
): number {
  timeseries = withoutNullStats(timeseries);
  if (timeseries.length <= 1) {
    return 0;
  }

  const y_delta = deltaTimeseries(timeseries);
  const x_delta = dayjs(timeseries[timeseries.length - 1].x).diff(
    dayjs(timeseries[0].x),
    "days"
  );
  return y_delta / x_delta;
}

export function deltaTimeseries(timeseries: Array<ITimeseriesPoint>): number {
  timeseries = withoutNullStats(timeseries);
  if (timeseries.length === 0) {
    return 0;
  }

  return timeseries[timeseries.length - 1].y! - timeseries[0].y!;
}

export function getTimeseriesPercentChange(
  previousTimeseries: Array<ITimeseriesPoint>,
  currentTimeseries: Array<ITimeseriesPoint>
): number {
  return percentChange(
    avgTimeseries(previousTimeseries),
    avgTimeseries(currentTimeseries)
  );
}
