/**
 * @generated SignedSource<<0e21229eea966155a8703faf99e4fdc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type UserNav_authUser$data = {
  readonly userType: UserType | null;
  readonly " $fragmentType": "UserNav_authUser";
};
export type UserNav_authUser$key = {
  readonly " $data"?: UserNav_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserNav_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNav_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "be43647f2a3022f9797bc2a5bb1f2880";

export default node;
