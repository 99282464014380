import _ from "lodash";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { useFormState } from "react-final-form";
import { ResponsiveValue } from "styled-system";
import { Box, Text, ThemeUIStyleObject, useThemeUI } from "theme-ui";

import Ic16ChevronDown from "../../../../../imgs/icons/ic16-chevron-down.svg";
import Ic24Filter from "../../../../../imgs/icons/ic24-filter.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import SubtleBadge, {
  ISubtleBadgeProps
} from "../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { IconBox } from "../../../../components/01_Core/Foundations/Icons";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { summarizeList } from "../../../../utils/utils";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";

export type FilterAccordionSize = "small" | "medium" | "large" | "extraLarge";

export default function FilterAccordion<
  FormValues extends Record<string, any>
>(props: {
  labels: { [K in keyof FormValues]: ReactNode };
  accordionOpen: boolean;
  setAccordionOpen: (open: boolean) => void;
  size: ResponsiveValue<FilterAccordionSize>;
  styles?: ThemeUIStyleObject;
  children?: ReactNode;
}): ReactElement {
  const { labels, accordionOpen, setAccordionOpen } = props;
  const { theme } = useThemeUI();

  const activeSize = useActiveResponsiveValue(props.size);

  const filterValues = useFormState<FormValues>().values;
  const activeFilterKeys = _.keys(labels).filter(label => {
    const value = filterValues[label];
    return (
      value &&
      (() => {
        switch (typeof value) {
          case "object":
            return !_.isEmpty(_.filter(value, Boolean));
          case "string":
            return value.length > 0;
          default:
            return !!value;
        }
      })()
    );
  }) as (string & keyof typeof labels)[];

  const maxFilterBadgesToShow = (() => {
    switch (activeSize) {
      case "small":
        return 1;
      case "medium":
        return 3;
      case "large":
        return 4;
      case "extraLarge":
        return 6;
    }
  })();

  const badgeProps: Pick<ISubtleBadgeProps, "size" | "color"> = {
    size: "small",
    color: "darkBlue"
  };

  const accordionRef = useRef<HTMLDivElement>(null);

  const [accordionMaxHeight, setAccordionMaxHeight] = useState<number | string>(
    "unset"
  );
  useEffect(
    () =>
      setAccordionMaxHeight(
        accordionOpen ? accordionRef.current?.scrollHeight ?? "unset" : "0"
      ),
    [accordionOpen, filterValues]
  );

  const activeLabels = summarizeList(
    activeFilterKeys.map(key => labels[key]),
    maxFilterBadgesToShow,
    "active"
  );

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.colors.white100 as string,
        border: "1px solid",
        borderColor: "midGray70",
        ...props.styles
      }}
    >
      <AutoLayout
        dependentProps={{ direction: "vertical" }}
        spacing={0}
        resizingX={FillContainer()}
      >
        <AutoLayout
          dependentProps={{ direction: "horizontal" }}
          spacing={16}
          resizingX={FillContainer()}
          onClick={() => setAccordionOpen(!accordionOpen)}
          sx={{
            width: "100%",
            cursor: "pointer",
            paddingY: "12px",
            paddingX: "24px",
            alignItems: "center",
            ":hover": {
              "#filters-icon-box": { color: theme.colors.black100 as string }
            }
          }}
        >
          <Text
            variant={activeSize === "small" ? "bodySmall" : "bodyMedium"}
            color={theme.colors.black100 as string}
          >
            <IconLabel Icon={Ic24Filter}>
              <strong>Filters</strong>
            </IconLabel>
          </Text>
          {activeLabels.map((label, i) => (
            <SubtleBadge key={i} {...badgeProps}>
              {label}
            </SubtleBadge>
          ))}
          <IconBox
            id={"filters-icon-box"}
            icon={Ic16ChevronDown}
            sx={{
              color: theme.colors.deepGray100 as string,
              width: "16px",
              height: "16px",
              transform: accordionOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.5s, color 0.2s",
              marginLeft: "auto"
            }}
          />
        </AutoLayout>
        <Box
          ref={accordionRef}
          sx={{
            width: "100%",
            overflow: "hidden",
            maxHeight: accordionMaxHeight,
            transition: "max-height 0.5s ease-out"
          }}
        >
          {props.children}
        </Box>
      </AutoLayout>
    </Box>
  );
}
