import { ReactElement } from "react";
import { Link as ExternalLink, Text } from "theme-ui";

import Ic24OpenInNew from "../../../../../imgs/icons/ic24-open-in-new.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Button from "../../../../components/01_Core/Buttons/Button";
import Linkify from "../../../../utils/Linkify";
import { useAuth } from "../../hooks/useAuth";
import { ExternalRoutes } from "../../Routing/ExternalRoutes";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";

export default function PayoutsSection(): ReactElement {
  const { authUser } = useAuth();

  return (
    <AutoLayout
      spacing={24}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      <Text variant={"h400"} color={"black100"}>
        Payouts
      </Text>
      <AutoLayout
        spacing={24}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        resizingX={FillContainer()}
      >
        <ExternalLink href={ExternalRoutes.curveLogin} target={"_blank"}>
          <Button variant={"secondary"} size={"medium"}>
            <IconLabel Icon={Ic24OpenInNew} iconSide="right">
              View payouts on Curve
            </IconLabel>
          </Button>
        </ExternalLink>
        <Text
          variant={"bodyMedium"}
          color={"deepGray100"}
          sx={{ maxWidth: "600px", whiteSpace: "pre-line" }}
        >
          <Linkify>
            {authUser.userType === "artist" ? "You" : "Your company"} must be
            set up on Curve to receive payouts from indify. Our Payments team
            has sent you an email with setup instructions. If you did not
            receive this email, please contact us at support@indify.io.
          </Linkify>
        </Text>
      </AutoLayout>
    </AutoLayout>
  );
}
