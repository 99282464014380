/**
 * @generated SignedSource<<4b3c98ef8a12e4f8dce6acee41c3d742>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebasePartners_partner$data = {
  readonly about: string | null;
  readonly deliverables: string | null;
  readonly fee: string | null;
  readonly id: string;
  readonly location: string | null;
  readonly name: string;
  readonly url: string | null;
  readonly " $fragmentType": "HomebasePartners_partner";
};
export type HomebasePartners_partner$key = {
  readonly " $data"?: HomebasePartners_partner$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebasePartners_partner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebasePartners_partner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliverables",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "about",
      "storageKey": null
    }
  ],
  "type": "HomebasePartnerNode",
  "abstractKey": null
};

(node as any).hash = "af425f201d8ca1e7afd8c30d20551ac5";

export default node;
