/**
 * @generated SignedSource<<ad828b12f6fa6bbc208cdf10237a562f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewAppFeaturesRefetchQuery$variables = {};
export type NewAppFeaturesRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewAppFeatures_features">;
};
export type NewAppFeaturesRefetchQuery = {
  response: NewAppFeaturesRefetchQuery$data;
  variables: NewAppFeaturesRefetchQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewAppFeaturesRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NewAppFeatures_features"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewAppFeaturesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AppFeatureNode",
        "kind": "LinkedField",
        "name": "newAppFeatures",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e98cd1c10caac609a43690e674c0eee4",
    "id": null,
    "metadata": {},
    "name": "NewAppFeaturesRefetchQuery",
    "operationKind": "query",
    "text": "query NewAppFeaturesRefetchQuery {\n  ...NewAppFeatures_features\n}\n\nfragment NewAppFeatures_features on Query {\n  newAppFeatures {\n    id\n    title\n    description\n    imageUrl\n  }\n}\n"
  }
};

(node as any).hash = "5dd3ef8c4d955fee05437f5d967695f6";

export default node;
