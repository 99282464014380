import { ReactElement } from "react";

import Ic20Star from "../../../../../../imgs/icons/ic20-star.svg";
import Ic20StarEmpty from "../../../../../../imgs/icons/ic20-star-empty.svg";
import { ClickableIconBox } from "../../../../../components/01_Core/Foundations/Icons";
import { actionIconSx } from "./RowDeal";

interface IBookmarkIconProps {
  watching: boolean;
  onClick: () => void;
  loading?: boolean;
}

export function getBookmarkElement(isWatching: boolean): SvgrComponent {
  return isWatching ? Ic20Star : Ic20StarEmpty;
}

export default function BookmarkIcon(props: IBookmarkIconProps): ReactElement {
  return (
    <ClickableIconBox
      icon={getBookmarkElement(props.watching)}
      loading={props.loading}
      size={20}
      onClick={props.onClick}
      sx={{
        cursor: "pointer",
        ...(props.watching ? { color: "black100" } : actionIconSx())
      }}
    />
  );
}
