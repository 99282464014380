import { MultipleArtists_artists$key } from "__generated__/MultipleArtists_artists.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Flex, Text } from "theme-ui";

import MultipleAvatarsNames from "../../../../../components/01_Core/Users/MultipleAvatarsNames";
import useArtistThumbnailUrl from "../../../hooks/useArtistThumbnailUrl";

interface IMultipleArtistsProps {
  size: Parameters<typeof MultipleAvatarsNames>[0]["size"];
  artists: MultipleArtists_artists$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=3212%3A19935
 */
function MultipleArtists(props: IMultipleArtistsProps): ReactElement {
  const artists = useFragment(
    graphql`
      fragment MultipleArtists_artists on ArtistNode @relay(plural: true) {
        id
        displayName
        ...useArtistThumbnailUrl_artist
      }
    `,
    props.artists
  );

  if (!artists.length) {
    return <Text color={"deepGray100"}>No artists</Text>;
  }

  return (
    <Flex
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        flexGrow: 1
      }}
    >
      <MultipleAvatarsNames
        users={artists.map(artist => {
          return {
            id: artist.id,
            name: artist.displayName,
            imageUrl: useArtistThumbnailUrl(artist)
          };
        })}
        numNames={3}
        size={props.size}
      />
    </Flex>
  );
}

export default MultipleArtists;
