import { AvatarName_actor$key } from "__generated__/AvatarName_actor.graphql";
import { CSSProperties, ReactElement, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

import Ic12ArrowDrillIn from "../../../../imgs/icons/ic12-arrow-drill-in.svg";
import { IconLabel } from "../../../pages/indify/components/03_UI_Kit/Discover/RowHeaderDeal";
import { useAuth } from "../../../pages/indify/hooks/useAuth";
import { InternalRoutes } from "../../../pages/indify/Routing/InternalRoutes";
import { rvMap, useFindActiveRV } from "../../../utils/responsiveUtils";
import { lineClampStyles } from "../../../utils/utils";
import AutoLayout from "../AutoLayout";
import AvatarIcon, {
  ActorAvatarIcon,
  AvatarIconSize,
  IAvatarIconProps
} from "./AvatarIcon";

type AvatarSize = IAvatarIconProps["size"];

export function ActorAvatarName(
  props: Partial<Omit<IActorDisplayNameProps, "hasLink">> & {
    actor: AvatarName_actor$key;
    size: AvatarSize;
    disableLink?: boolean;
    disableActiveDot?: boolean;
  }
): ReactElement {
  const actor = useFragment(
    graphql`
      fragment AvatarName_actor on ActorInterface {
        __typename
        slug
        displayName
        ...AvatarIcon_actor
        ... on PartnerNode {
          company {
            slug
            displayName
          }
        }
      }
    `,
    props.actor
  );

  const { authUser } = useAuth();

  const link = !props.disableLink
    ? actor.__typename === "PartnerNode"
      ? generatePath(InternalRoutes.fundProfile, {
          companySlug: actor.company.slug
        })
      : authUser.userType === "partner" &&
        generatePath(InternalRoutes.artistProfile, {
          artistSlug: actor.slug
        })
    : undefined;

  return (
    <AvatarNameContainer size={props.size} link={link}>
      <ActorAvatarIcon
        size={props.size}
        actor={actor}
        disableActiveDot={props.disableActiveDot}
      />
      <ActorDisplayName
        size={props.size}
        displayName={props.displayName ?? actor.displayName}
        caption={props.caption ?? actor.company?.displayName}
        hasLink={!!link}
        textStyles={props.textStyles}
      />
    </AvatarNameContainer>
  );
}

export type IAvatarNameProps = Pick<
  IAvatarIconProps,
  "imageUrl" | "status" | "badge"
> &
  Omit<IActorDisplayNameProps, "hasLink"> & {
    link?: string;
  };

/**
 * 03 Avatar + Name + Caption
 * 02 Avatar + Name
 *
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A1846
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A1845
 */

function AvatarName(props: IAvatarNameProps): ReactElement {
  const { size, displayName, caption, link, textStyles, ...iconProps } = props;

  return (
    <AvatarNameContainer size={size} link={link}>
      <AvatarIcon size={size} {...iconProps} />
      <ActorDisplayName
        size={size}
        displayName={displayName}
        caption={caption}
        hasLink={!!link}
        textStyles={textStyles}
      />
    </AvatarNameContainer>
  );
}

export function AvatarNameContainer(props: {
  size: IAvatarNameProps["size"];
  link: string;
  children: ReactNode | ReactNode[];
  styles?: CSSProperties;
}): ReactElement {
  const { link, children } = props;

  const responsivePadding = rvMap(props.size, getPadding);

  const withoutLink = (
    <AutoLayout
      spacing={responsivePadding}
      dependentProps={{ direction: "horizontal", alignment: "left" }}
      sx={{
        ":hover": {
          [`#${actorDisplayNameCssId}`]: { borderColor: "black100" }
        },
        ...props.styles
      }}
    >
      {children}
    </AutoLayout>
  );

  return link ? (
    <Link to={link} style={props.styles}>
      {withoutLink}
    </Link>
  ) : (
    withoutLink
  );
}

export const actorDisplayNameCssId = "actor-display-name";
interface IActorDisplayNameProps {
  size: AvatarSize;
  displayName: ReactNode;
  caption?: ReactNode;
  hasLink?: boolean;
  textStyles?: ThemeUIStyleObject;
}
export function ActorDisplayName(props: IActorDisplayNameProps): ReactElement {
  const responsiveNameTextType = useFindActiveRV(props.size, getNameTextType);
  const responsiveCaptionTextType = useFindActiveRV(
    props.size,
    getCaptionTextType
  );

  const withoutLink = (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-around"
      }}
    >
      <Text
        id={actorDisplayNameCssId}
        variant={responsiveNameTextType}
        color={"black100"}
        sx={{
          ...(props.hasLink && {
            borderBottom: "1px solid",
            borderColor: "transparent",
            transition: "border-color 0.2s"
          }),
          ...lineClampStyles(1),
          ...props.textStyles
        }}
      >
        {props.displayName}
      </Text>
      {props.caption && (
        <Text
          color={"deepGray100"}
          variant={responsiveCaptionTextType}
          sx={lineClampStyles(1)}
        >
          {props.caption}
        </Text>
      )}
    </Flex>
  );

  return props.hasLink ? (
    <Text variant={responsiveNameTextType}>
      <IconLabel
        Icon={Ic12ArrowDrillIn}
        iconSide="right"
        size="0.75em"
        spacing="0.8ch"
      >
        {withoutLink}
      </IconLabel>
    </Text>
  ) : (
    withoutLink
  );
}

export default AvatarName;

function getNameTextType(size: AvatarIconSize) {
  switch (size) {
    case "extraSmall":
    case "small":
      return "bodyMedium";
    case "medium":
    case "large":
      return "bodyLarge";
    case "extraLarge":
      return "subtitle";
  }
}

function getCaptionTextType(size: AvatarIconSize) {
  switch (size) {
    case "extraSmall":
    case "small":
    case "medium":
      return "bodySmall";
    case "large":
    case "extraLarge":
      return "bodyMedium";
  }
}

function getPadding(size: AvatarIconSize) {
  switch (size) {
    case "extraSmall":
    case "small":
      return 8;
    case "medium":
    case "large":
    case "extraLarge":
      return 16;
  }
}
