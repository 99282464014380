import {
  useStaticDataQuery as useStaticDataQueryType,
  useStaticDataQuery$data
} from "__generated__/useStaticDataQuery.graphql";
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import { fetchQuery } from "react-relay";
import { graphql } from "relay-runtime";

import environment from "../../../environment";

export interface IStaticData {
  genres: Genre[];
}
export type Genre = useStaticDataQueryType["response"]["genres"][number];
const StaticDataContext = createContext<IStaticData | null>(null);

const staticDataQuery = graphql`
  query useStaticDataQuery {
    genres {
      id
      genre
    }
  }
`;

export const useStaticData = (): IStaticData | null => {
  return useContext(StaticDataContext);
};

interface IProvideStaticDataProps {
  children: ReactNode;
}

export function ProvideStaticData(
  props: IProvideStaticDataProps
): ReactElement {
  const [staticData, setStaticData] = useState<useStaticDataQuery$data>({
    genres: []
  });

  useEffect(() => {
    fetchQuery<useStaticDataQueryType>(environment, staticDataQuery, {})
      .toPromise()
      .then(response => {
        setStaticData({ genres: response.genres });
      });
  }, []);

  const populatedStaticData: IStaticData = {
    genres: [...staticData.genres]
  };

  return (
    <StaticDataContext.Provider value={populatedStaticData}>
      {props.children}
    </StaticDataContext.Provider>
  );
}
