import { ReactElement } from "react";
import { Flex, Text } from "theme-ui";

import { zIndices } from "../../../../theme/theme";
import { rvMap } from "../../../../utils/responsiveUtils";
import { getFontSize, InputProps } from "../Input";
import NumberInput from "./NumberInput";

export const percentInputMaxWidth = "80px";

function PercentInput(
  props: InputProps<HTMLInputElement, number | null>
): ReactElement {
  const { sx, ...rest } = props;

  return (
    <Flex sx={{ alignItems: "center", position: "relative", width: "100%" }}>
      <NumberInput
        {...rest}
        sx={{ ...sx, maxWidth: percentInputMaxWidth }}
        pr={["25px", "25px", "25px", "30px"]}
      />
      <Text
        sx={{
          position: "absolute",
          right: "7px",
          fontSize: rvMap(props.size, getFontSize),
          color: "deepGray100",
          zIndex: zIndices.switch
        }}
      >
        %
      </Text>
    </Flex>
  );
}

export default PercentInput;
