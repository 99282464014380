/**
 * @generated SignedSource<<ef1467fd05f7368155ae26246d3b4824>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReleaseTypename = "ReleaseNode" | "UnreleasedReleaseNode";
export type SetReleasesVisibleMutationInput = {
  artistNodeId: string;
  isVisible: boolean;
  typename: ReleaseTypename;
};
export type ArtistManageReleasesMutation$variables = {
  input: SetReleasesVisibleMutationInput;
};
export type ArtistManageReleasesMutation$data = {
  readonly setReleasesVisible: {
    readonly artist: {
      readonly releases: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly isVisible: boolean;
          } | null;
        } | null>;
      };
      readonly unreleasedReleases: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly isVisible: boolean;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type ArtistManageReleasesMutation = {
  response: ArtistManageReleasesMutation$data;
  variables: ArtistManageReleasesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isVisible",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ReleaseNodeConnection",
  "kind": "LinkedField",
  "name": "releases",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UnreleasedReleaseNodeConnection",
  "kind": "LinkedField",
  "name": "unreleasedReleases",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArtistManageReleasesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetReleasesVisibleMutation",
        "kind": "LinkedField",
        "name": "setReleasesVisible",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArtistManageReleasesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetReleasesVisibleMutation",
        "kind": "LinkedField",
        "name": "setReleasesVisible",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "51aeb6b0eea30f79302dd30214bbdc72",
    "id": null,
    "metadata": {},
    "name": "ArtistManageReleasesMutation",
    "operationKind": "mutation",
    "text": "mutation ArtistManageReleasesMutation(\n  $input: SetReleasesVisibleMutationInput!\n) {\n  setReleasesVisible(data: $input) {\n    artist {\n      releases {\n        edges {\n          node {\n            id\n            isVisible\n          }\n        }\n      }\n      unreleasedReleases {\n        edges {\n          node {\n            id\n            isVisible\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86f38b6dc1257a30c3fe1d61d5a922a5";

export default node;
