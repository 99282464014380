/**
 * @generated SignedSource<<b46615115bb584effac31d010a92e731>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistPortfolioRowDeal_offer$data = {
  readonly dateOfferAccepted: any | null;
  readonly monthlyEarnings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly partnerEarnings: any | null;
        readonly partnerExpenses: any | null;
      } | null;
    } | null>;
  };
  readonly partner: {
    readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  };
  readonly totalStreams: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioRowDeal_offer" | "ReleaseIconsNames_query">;
  readonly " $fragmentType": "ArtistPortfolioRowDeal_offer";
};
export type ArtistPortfolioRowDeal_offer$key = {
  readonly " $data"?: ArtistPortfolioRowDeal_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistPortfolioRowDeal_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistPortfolioRowDeal_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalStreams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MonthlyEarningsNodeConnection",
      "kind": "LinkedField",
      "name": "monthlyEarnings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MonthlyEarningsNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyEarningsNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partnerEarnings",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partnerExpenses",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfferAccepted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarName_actor"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseIconsNames_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioRowDeal_offer"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "02d0a72e755562ed42f53227bfbf23a5";

export default node;
