import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import { ResponsiveValue } from "styled-system";
import { Flex } from "theme-ui";

import Ic16ArrowLeft from "../../../../../../imgs/icons/ic16-arrow-left.svg";
import Ic16ArrowRight from "../../../../../../imgs/icons/ic16-arrow-right.svg";
import Ic20ArrowLeft from "../../../../../../imgs/icons/ic20-arrow-left-1.svg";
import Ic20ArrowRight from "../../../../../../imgs/icons/ic20-arrow-right-1.svg";
import Ic24ArrowLeft from "../../../../../../imgs/icons/ic24-arrow-left.svg";
import Ic24ArrowRight from "../../../../../../imgs/icons/ic24-arrow-right.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { ButtonSize } from "../../../../../components/01_Core/Buttons/Button";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import TextButton from "../../../../../components/01_Core/Buttons/TextButton";
import { IWizardPageLayoutProps } from "../../../../../components/01_Core/Forms/Wizard";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { rvMap, useFindActiveRV } from "../../../../../utils/responsiveUtils";
import { IFundingOfferFormValues } from "../../07_Deal_Flow/OfferFormFunding";

interface IOfferFormFooterProps extends IWizardPageLayoutProps {
  submitButtonLabel: string;
  description?: ReactElement;
}

export default function OfferFormFooter(
  props: IOfferFormFooterProps
): ReactElement {
  const {
    prevPage,
    nextPage,
    prevOnClick,
    nextOnClick,
    hasAttemptedSubmit,
    submitButtonLabel
  } = props;

  const formProps = useFormState<IFundingOfferFormValues>();

  function getFooterPadding(size: "small" | "medium" | "large") {
    switch (size) {
      case "small":
        return "16px";
      case "medium":
        return "24px";
      case "large":
        return "32px";
    }
  }
  const controlSizes: ResponsiveValue<ButtonSize> = "medium";
  const getNextIcon = (size: ButtonSize) => {
    switch (size) {
      case "small":
        return <Ic16ArrowRight />;
      case "medium":
        return <Ic20ArrowRight />;
      case "large":
        return <Ic24ArrowRight />;
    }
  };
  const getPrevIcon = (size: ButtonSize) => {
    switch (size) {
      case "small":
        return <Ic16ArrowLeft />;
      case "medium":
        return <Ic20ArrowLeft />;
      case "large":
        return <Ic24ArrowLeft />;
    }
  };
  const prevIcon = useFindActiveRV(controlSizes, getPrevIcon);
  const nextIcon = useFindActiveRV(controlSizes, getNextIcon);

  const nextSubmitButtonProps = {
    size: controlSizes,
    onClick: nextOnClick,
    disabled:
      formProps.validating ||
      (formProps.hasValidationErrors && hasAttemptedSubmit)
  };
  return (
    <Flex
      sx={{
        justifyContent: "center",
        backgroundColor: "lightGray100",
        width: "100%",
        marginTop: "auto"
      }}
    >
      <FixedGrid>
        <GridItem
          gridColumn={["1 / span 4", "2 / span 6", "2 / span 6", "4 / span 6"]}
        >
          <AutoLayout
            spacing={24}
            dependentProps={{
              direction: "vertical",
              alignment: "topCenter"
            }}
            sx={{
              padding: rvMap(controlSizes, getFooterPadding),
              width: "100%"
            }}
          >
            <Flex
              sx={{
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <TextButton
                onClick={prevOnClick}
                type="secondary"
                size={controlSizes}
                disabled={!prevPage}
                iconProps={{
                  icon: prevIcon,
                  placement: "left"
                }}
              >
                Prev
              </TextButton>
              {nextPage ? (
                <TextButton
                  {...nextSubmitButtonProps}
                  iconProps={{
                    icon: nextIcon,
                    placement: "right"
                  }}
                  type="secondary"
                >
                  Next
                </TextButton>
              ) : (
                <LoadingButton
                  {...nextSubmitButtonProps}
                  variant={"primary"}
                  loading={formProps.submitting}
                >
                  {submitButtonLabel}
                </LoadingButton>
              )}
            </Flex>
            {props.description}
          </AutoLayout>
        </GridItem>
      </FixedGrid>
    </Flex>
  );
}
