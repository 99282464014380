import { ReactElement } from "react";
import { Image, ImageProps } from "theme-ui";

import IndifyLogo from "../../../imgs/logo-red-black.png";

export function Logo(props: ImageProps): ReactElement {
  return (
    <Image
      src={IndifyLogo}
      width={84}
      height={24}
      sx={{ verticalAlign: "middle", ...(props.sx ?? {}) }}
      {...props}
    />
  );
}
