/**
 * @generated SignedSource<<67f8135a73571fb184410cbc5f97468d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NewAppFeaturesMutation$variables = {
  input: ReadonlyArray<string>;
};
export type NewAppFeaturesMutation$data = {
  readonly userSetAppFeaturesSeen: {
    readonly user: {
      readonly id: string;
    } | null;
  } | null;
};
export type NewAppFeaturesMutation = {
  response: NewAppFeaturesMutation$data;
  variables: NewAppFeaturesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "input"
      }
    ],
    "concreteType": "UserSetAppFeaturesSeenMutation",
    "kind": "LinkedField",
    "name": "userSetAppFeaturesSeen",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewAppFeaturesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewAppFeaturesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "29932004d28c9b041e790977ea8b2bef",
    "id": null,
    "metadata": {},
    "name": "NewAppFeaturesMutation",
    "operationKind": "mutation",
    "text": "mutation NewAppFeaturesMutation(\n  $input: [ID!]!\n) {\n  userSetAppFeaturesSeen(data: $input) {\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd56607208c7306b4e246bd19a4d6a57";

export default node;
