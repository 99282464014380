import { ArtistCharts_artist$key } from "__generated__/ArtistCharts_artist.graphql";
import { Routing_ArtistChartsQuery as ArtistChartsQueryType } from "__generated__/Routing_ArtistChartsQuery.graphql";
import dayjs from "dayjs";
import _ from "lodash";
import { ReactElement, ReactNode, useState } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { graphql, useFragment } from "react-relay";
import { Box, Text } from "theme-ui";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import {
  avgDeltaTimeseries,
  avgTimeseries,
  graphablePoints
} from "../../../../utils/timeseries";
import { formatNumeral, lineClampStyles } from "../../../../utils/utils";
import { ArtistChartsQuery } from "../../Routing/Routing";
import { ARTIST_STAT_LABELS } from "../03_Discover/ArtistDirectoryFilters";
import Timeseries from "./Timeseries";

interface IArtistCharts {
  queryRef: PreloadedQuery<ArtistChartsQueryType>;
}

export default function ArtistCharts(props: IArtistCharts): ReactElement {
  const { artistBySlug } = usePreloadedQuery<ArtistChartsQueryType>(
    ArtistChartsQuery,
    props.queryRef
  );
  const artist = useFragment<ArtistCharts_artist$key>(
    graphql`
      fragment ArtistCharts_artist on ArtistNode {
        luminateWeekDatas {
          value
          weekStartDay
        }
        instagramProfile {
          instagramProfileData {
            edges {
              node {
                date
                followers
              }
            }
          }
        }
        tiktokProfile {
          tiktokProfileData {
            edges {
              node {
                date
                followerCount
              }
            }
          }
          tiktokSounds {
            edges {
              node {
                data {
                  date
                  value
                }
              }
            }
          }
        }
      }
    `,
    artistBySlug
  );

  const tikTokSoundDatas =
    artist.tiktokProfile?.tiktokSounds?.edges.map(({ node }) =>
      node.data.map(({ date, value }) => ({
        x: new Date(date as string),
        y: value
      }))
    ) ?? [];

  const graphs: Record<
    string,
    {
      label: ReactNode;
      timeseriesProps: Pick<
        Parameters<typeof Timeseries>[0],
        "statName" | "windowSummaryStatName" | "windowSummaryFn"
      > & {
        windowGranularity?: Parameters<
          typeof Timeseries
        >[0]["windowGranularity"];
      };
      data: Parameters<typeof Timeseries>[0]["timeseries"];
    }
  > = {
    streamsLastWeek: {
      label: ARTIST_STAT_LABELS.streamsThisWeek,
      timeseriesProps: {
        statName: "Weekly streams",
        windowGranularity: "week",
        windowSummaryStatName: "Average weekly streams",
        windowSummaryFn: avgTimeseries
      },
      data: artist.luminateWeekDatas.map(({ value, weekStartDay }) => ({
        x: dayjs(weekStartDay).add(6, "day").toDate(),
        y: value
      }))
    },
    tiktokUGCs: {
      label: ARTIST_STAT_LABELS.tiktokPosts,
      timeseriesProps: {
        statName: "Total TikTok UGCs",
        windowSummaryStatName: "Average new TikTok UGCs per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data: _.uniq(
        tikTokSoundDatas.flatMap(data => data.map(({ x }) => x.getTime()))
      )
        .sort()
        .map(targetTime => ({
          x: new Date(targetTime),
          y: _.sum(
            tikTokSoundDatas.map(data => {
              const existing = data.find(d => d.x.getTime() === targetTime);
              if (existing) return existing.y;
              // return existing?.y ?? 0;

              const [before, after] = _.partition(
                data,
                d => d.x.getTime() < targetTime
              );

              // Interpolate if possible
              if (before.length && after.length) {
                const [p1, p2] = [before[before.length - 1], after[0]];
                const slope = (p2.y - p1.y) / (p2.x.getTime() - p1.x.getTime());
                return p1.y + slope * (targetTime - p1.x.getTime());
              }

              // Extrapolate the future as constant, not linear
              if (before.length) return before[before.length - 1].y;

              // Don't extrapolate the past
              return 0;
            })
          )
        }))
    },
    tiktokFollowers: {
      label: ARTIST_STAT_LABELS.tiktokFollowers,
      timeseriesProps: {
        statName: "Total TikTok followers",
        windowSummaryStatName: "Average new TikTok followers per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data:
        artist?.tiktokProfile?.tiktokProfileData?.edges.map(({ node }) => ({
          y: node.followerCount,
          x: new Date(new Date(node.date as string))
        })) ?? []
    },
    instagramFollowers: {
      label: ARTIST_STAT_LABELS.instagramFollowers,
      timeseriesProps: {
        statName: "Total Instagram followers",
        windowSummaryStatName: "Average new Instagram followers per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data:
        artist?.instagramProfile?.instagramProfileData?.edges.map(
          ({ node }) => ({
            y: node.followers,
            x: new Date(new Date(node.date as string))
          })
        ) ?? []
    }
  };

  // Hide the graph section if there is no data
  if (Object.values(graphs).every(({ data }) => data.length === 0)) {
    return null;
  }

  const [selectedGraph, setSelectedGraph] = useState<keyof typeof graphs>(
    (Object.keys(graphs) as (keyof typeof graphs)[]).find(
      key => graphs[key].data.length
    )
  );

  const subheaderVariant = useActiveResponsiveValue([
    "bodySmall",
    "bodySmall",
    "bodyMedium",
    "bodyMedium"
  ]);

  return (
    <AutoLayout
      spacing={useActiveResponsiveValue([24, 24, 32, 32])}
      dependentProps={{ direction: "vertical" }}
      sx={{ width: "100%" }}
    >
      {/* <Box> */}
      <AutoLayout
        spacing={useActiveResponsiveValue([8, 8, 16, 16])}
        dependentProps={{ direction: "horizontal" }}
        sx={{ width: "100%" }}
      >
        {Object.entries(graphs).map(([key, graph]) => {
          const data = graphablePoints(graph.data);
          const canSelect = data.length > 0;
          const currentPoint = data[data.length - 1];
          return (
            <Box
              key={key}
              sx={{
                width: "100%",
                flexBasis: 0,
                flexGrow: 1,
                overflowY: "auto",
                paddingBottom: "16px",
                borderBottom: "2px solid",
                borderColor: selectedGraph === key ? "black100" : "midGray100",
                transition: "border-color 0.2s",
                ":hover": canSelect && { borderColor: "black100" },
                cursor: canSelect ? "pointer" : "not-allowed"
              }}
              onClick={() =>
                canSelect && setSelectedGraph(key as keyof typeof graphs)
              }
            >
              <Text color={canSelect ? "black100" : "midGray100"}>
                <AutoLayout
                  spacing={8}
                  dependentProps={{ direction: "vertical" }}
                  sx={{ width: "100%" }}
                >
                  <Text
                    variant={useActiveResponsiveValue([
                      "h300",
                      "h300",
                      "h400",
                      "h400"
                    ])}
                    sx={{ width: "100%" }}
                  >
                    {currentPoint ? formatNumeral(currentPoint.y) : "—"}{" "}
                  </Text>
                  <Text
                    variant={subheaderVariant}
                    color={"deepGray100"}
                    sx={lineClampStyles(1)}
                  >
                    {graph.label}
                  </Text>
                </AutoLayout>
              </Text>
            </Box>
          );
        })}
      </AutoLayout>
      {/* </Box> */}
      <Timeseries
        timeseries={graphablePoints(graphs[selectedGraph].data)}
        windowGranularity={"day"}
        emptyText={"No data to show"}
        {...graphs[selectedGraph].timeseriesProps}
      />
    </AutoLayout>
  );
}
