import { MessageAction_artist$key } from "__generated__/MessageAction_artist.graphql";
import { ReactElement, SyntheticEvent } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";

import Ic20Email from "../../../../../../../imgs/icons/ic20-email.svg";
import IconButton from "../../../../../../components/01_Core/Buttons/IconButton";
import LoadingButton, {
  ILoadingButtonProps
} from "../../../../../../components/01_Core/Buttons/LoadingButton";
import MarketplaceAccessGate from "../../../../../../utils/MarketplaceAccessGate";
import { useActiveResponsiveValue } from "../../../../../../utils/responsiveUtils";
import { useMessageActor } from "../../../../hooks/useMessage";

type MessageActionSize = "small" | "large";
interface IMessageActionProps {
  size: MessageActionSize;
  onClick: () => void;
  loading: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=226%3A1676 (with variant type: message)
 */
export function MessageAction(props: IMessageActionProps): ReactElement {
  function onClick(e: SyntheticEvent) {
    e.stopPropagation();
    props.onClick();
  }

  const buttonProps = {
    onClick,
    variant: "tertiary",
    disabled: false
  } as ILoadingButtonProps;

  return (
    <MarketplaceAccessGate>
      {props.size === "small" ? (
        <IconButton {...buttonProps} size={"small"}>
          <Ic20Email viewBox={"0 0 20 20"} height={"16px"} width={"16px"} />
        </IconButton>
      ) : (
        <LoadingButton
          {...buttonProps}
          size={"medium"}
          loading={props.loading}
          iconProps={{ placement: "left", icon: <Ic20Email /> }}
        >
          Message
        </LoadingButton>
      )}
    </MarketplaceAccessGate>
  );
}

interface IMessageArtistActionProps {
  artist: MessageAction_artist$key;
  size: ResponsiveValue<MessageActionSize>;
}

export function MessageArtistAction(
  props: IMessageArtistActionProps
): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const artist = useFragment(
    graphql`
      fragment MessageAction_artist on ArtistNode {
        ...useMessage_actor
      }
    `,
    props.artist
  );

  const [messageArtist, isInFlight] = useMessageActor(artist);

  return (
    <MessageAction
      size={activeSize}
      onClick={messageArtist}
      loading={isInFlight}
    />
  );
}
