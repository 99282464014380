import { ChatThread_authUser$key } from "__generated__/ChatThread_authUser.graphql";
import { ChatThread_messages$key } from "__generated__/ChatThread_messages.graphql";
import { ChatThreadMarkReadMutation } from "__generated__/ChatThreadMarkReadMutation.graphql";
import { ChatThreadPaginationQuery } from "__generated__/ChatThreadPaginationQuery.graphql";
import { ChatThreadQuery as ChatThreadQueryType } from "__generated__/ChatThreadQuery.graphql";
import { ChatThreadSendMessageMutation } from "__generated__/ChatThreadSendMessageMutation.graphql";
import { Fragment, ReactElement, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ConnectionHandler,
  graphql,
  PreloadedQuery,
  useFragment,
  usePaginationFragment,
  usePreloadedQuery
} from "react-relay";
import { Box, Flex, Grid, Text } from "theme-ui";

import { Loader } from "../../../../../components/01_Core/999_Miscellaneous/Loader";
import { FluidGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { ActorAvatarIcon } from "../../../../../components/01_Core/Users/AvatarIcon";
import { useAuth } from "../../../hooks/useAuth";
import useMutationPromise from "../../../hooks/useMutationPromise";
import ActionMakeOffer from "../Actions/ActionMakeOffer";
import ChatThreadHeading from "./ChatThreadHeading";
import MessageInput from "./MessageInput";
import MessageWithOffer from "./MessageWithOffer";

interface IChatThreadProps {
  queryRef: PreloadedQuery<ChatThreadQueryType>;
}

export const gridTemplateColumns = [
  "[avatar-start] 40px [avatar-end message-block-start] repeat(3, 1fr) [message-block-end]",
  "[avatar-start] 1fr [avatar-end message-block-start] repeat(4, 1fr) [message-block-end]",
  "[avatar-start] 1fr [avatar-end message-block-start] repeat(4, 1fr) [message-block-end]",
  "1fr [avatar-start] 1fr [avatar-end message-block-start] repeat(4, 1fr) [message-block-end] repeat(3, 1fr)"
];

function ChatThread(props: IChatThreadProps): ReactElement {
  const chat = usePreloadedQuery<ChatThreadQueryType>(
    ChatThreadQuery,
    props.queryRef
  ).chat;

  const {
    data: paginatedMessages,
    loadNext,
    hasNext
  } = usePaginationFragment<ChatThreadPaginationQuery, ChatThread_messages$key>(
    graphql`
      fragment ChatThread_messages on ChatNode
      @refetchable(queryName: "ChatThreadPaginationQuery")
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 10 }
      ) {
        messages(first: $count, after: $cursor)
          @connection(key: "ChatThread_messages") {
          edges {
            node {
              ...MessageWithOffer_message
            }
          }
        }
      }
    `,
    chat
  );
  const chatMessages = paginatedMessages.messages.edges.map(e => e.node);

  const authUser = useFragment<ChatThread_authUser$key>(
    graphql`
      fragment ChatThread_authUser on UserNode {
        id
        actor {
          __typename
          id
          ...AvatarIcon_actor
        }
        userType
        artist {
          visible
        }
      }
    `,
    useAuth().authUser
  );
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollTo(
      0,
      messagesContainerRef.current.scrollHeight
    );
  };

  const [commitMarkReadMutation] =
    useMutationPromise<ChatThreadMarkReadMutation>(graphql`
      mutation ChatThreadMarkReadMutation($chatNodeId: ID!) {
        markRead(chatNodeId: $chatNodeId) {
          chat {
            id
            hasUnreadMessages
          }
          user {
            id
            numUnreadMessages
          }
        }
      }
    `);

  const [commitSendMessageMutation, isSendMessageInFlight] =
    useMutationPromise<ChatThreadSendMessageMutation>(graphql`
      mutation ChatThreadSendMessageMutation(
        $connections: [ID!]!
        $input: SendMessageMutationInput!
      ) {
        sendMessage(data: $input) {
          chatMessage
            @prependNode(
              connections: $connections
              edgeTypeName: "MessageEdge"
            ) {
            ...MessageWithOffer_message
            chat {
              ...InboxChats_chats
              other {
                chatWithCurrentUser {
                  ...useArtistCanMessageCompany_chat
                }
              }
              artistUser {
                artist {
                  ...useArtistCanMessageCompany_artist
                }
              }
            }
          }
        }
      }
    `);

  const sendMessage = (message: string) => {
    const connectionId = ConnectionHandler.getConnectionID(
      chat.id,
      "ChatThread_messages"
    );
    return commitSendMessageMutation({
      variables: {
        input: { message, chatNodeId: chat.id },
        connections: [connectionId]
      },
      updater: store => {
        const myChats = ConnectionHandler.getConnection(
          store.getRoot(),
          "Inbox_myChats",
          { hasMessages: true, orderBy: "-last_message_sent_at" }
        );
        const myChatsEdges = myChats.getLinkedRecords("edges");
        const chatIndex = myChatsEdges.findIndex(
          e => e.getLinkedRecord("node").getValue("id") === chat.id
        );
        if (chatIndex === -1) {
          const chatNode = store.get(chat.id);
          const chatEdge = store.create(
            "client:newEdge:" + chat.id,
            "ChatEdge"
          );
          chatEdge.setLinkedRecord(chatNode, "node");
          myChats.setLinkedRecords([chatEdge, ...myChatsEdges], "edges");
        } else if (chatIndex > 0) {
          const chatEdge = myChatsEdges[chatIndex];
          myChats.setLinkedRecords(
            [
              chatEdge,
              ...myChatsEdges.slice(0, chatIndex),
              ...myChatsEdges.slice(chatIndex + 1)
            ],
            "edges"
          );
        }
      },
      optimisticResponse: {
        sendMessage: {
          chatMessage: {
            id: `client:newNode:${Date.now()}`,
            message,
            sentAt: new Date().toISOString(),
            offer: null,
            user: { id: authUser.id },
            chat: null
          }
        }
      }
    }).then(() => scrollToBottom());
  };

  useEffect(() => {
    if (messagesContainerRef.current?.scrollTop < 0) {
      scrollToBottom();
    }

    if (chat.hasUnreadMessages) {
      commitMarkReadMutation({ variables: { chatNodeId: chat.id } });
    }
  }, [chat]);

  return (
    <Grid
      gap={0}
      sx={{
        gridTemplateRows: "min-content",
        width: "100%",
        borderLeft: "1px solid",
        borderColor: theme => theme.colors.midGray70
      }}
    >
      <GridItem
        gridColumn={"1 / -1"}
        sx={{ flexDirection: "column", alignSelf: "start" }}
      >
        <ChatThreadHeading
          size={["small", "medium", "large", "large"]}
          chat={chat}
        />
      </GridItem>
      <GridItem
        gridColumn={"1 / -1"}
        sx={{
          overflowY: "auto",
          flexDirection: "column"
        }}
      >
        <Flex
          sx={{
            flexDirection: "column-reverse",
            width: "100%",
            overflowY: "auto"
          }}
          id={"infinite-scroll-container-message-thread"}
          ref={messagesContainerRef}
        >
          <InfiniteScroll
            next={() => loadNext(5)}
            hasMore={hasNext}
            loader={
              <FluidGrid
                my={"24px"}
                sx={{
                  gridTemplateColumns
                }}
              >
                <GridItem
                  gridColumn={"message-block-start / message-block-end"}
                  sx={{ justifyContent: "center" }}
                >
                  <Loader />
                </GridItem>
              </FluidGrid>
            }
            endMessage={
              <FluidGrid
                my={"24px"}
                sx={{
                  gridTemplateColumns
                }}
              >
                <GridItem
                  gridColumn={[
                    "1 / -1",
                    "1 / -1",
                    "1 / -1",
                    "message-block-start / message-block-end"
                  ]}
                  sx={{ justifyContent: "center" }}
                >
                  <Text variant={"bodyMedium"} color={"deepGray100"}>
                    {chatMessages.length
                      ? "You've reached the beginning of this chat."
                      : "No messages yet. Start the conversation!"}
                  </Text>
                </GridItem>
              </FluidGrid>
            }
            dataLength={chatMessages.length}
            inverse={true}
            scrollableTarget={"infinite-scroll-container-message-thread"}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "hidden"
            }}
          >
            {chatMessages.map((node, i) => (
              <MessageWithOffer key={`message_${i}`} message={node} />
            ))}
          </InfiniteScroll>
        </Flex>
      </GridItem>
      <GridItem
        gridColumn={"1 / -1"}
        mt={"8px"}
        sx={{ flexDirection: "column", alignSelf: "end" }}
      >
        <Divider />
        <FluidGrid
          sx={{
            gridTemplateColumns
          }}
        >
          <GridItem
            gridColumn={"avatar-start / avatar-end"}
            sx={{ justifyContent: "flex-end", alignItems: "center" }}
          >
            <ActorAvatarIcon
              actor={authUser.actor}
              size={"small"}
              disableActiveDot
            />
          </GridItem>
          <GridItem gridColumn={"message-block-start / message-block-end"}>
            <MessageInput
              size={["small", "medium", "medium", "medium"]}
              chat={chat}
              sendMessage={sendMessage}
              isSending={isSendMessageInFlight}
            />
          </GridItem>
          {authUser.userType === "partner" && (
            <Fragment>
              <GridItem gridColumn={"avatar-start / message-block-end"}>
                <Divider />
              </GridItem>
              <GridItem gridColumn={"message-block-start / span 2"}>
                <Box
                  sx={{ width: "100%" }}
                  py={["24px", "24px", "24px", "16px"]}
                >
                  <ActionMakeOffer
                    chat={chat}
                    disabled={!chat.other.artist?.visible}
                  />
                </Box>
              </GridItem>
            </Fragment>
          )}
        </FluidGrid>
      </GridItem>
    </Grid>
  );
}

export const ChatThreadQuery = graphql`
  query ChatThreadQuery($chatId: ID!) {
    chat(id: $chatId) {
      id
      hasUnreadMessages
      other {
        userType
        artist {
          visible
        }
      }
      ...MessageInput_chat
      ...ChatThread_messages
      ...ChatThreadHeading_chat
      ...ActionMakeOffer_chat
    }
  }
`;

export default ChatThread;
