import {
  ReleaseTypeEnum,
  useReleaseMetadata_release$data,
  useReleaseMetadata_release$key
} from "__generated__/useReleaseMetadata_release.graphql";
import * as dayjs from "dayjs";
import * as _ from "lodash";
import { graphql, useFragment } from "react-relay";

export function useReleaseMetadataParts(
  release: useReleaseMetadata_release$key
): string[] {
  const releaseData = useFragment(
    graphql`
      fragment useReleaseMetadata_release on ReleaseInterface {
        releaseType
        tracksCount
        releaseDate
      }
    `,
    release
  );

  return formatReleaseMetadataParts(releaseData);
}

export function useReleaseMetadata(
  release: useReleaseMetadata_release$key
): string {
  return useReleaseMetadataParts(release).join(" - ");
}

export function formatReleaseMetadataParts(
  release: Partial<useReleaseMetadata_release$data>
): string[] {
  const formattedReleaseType = formatReleaseType(release.releaseType);

  const formattedTracksCount =
    release?.tracksCount && release.tracksCount > 1
      ? `${release.tracksCount} tracks`
      : null;
  const formattedReleaseDate = formatReleaseDate(
    release?.releaseDate as string | null
  );

  return _.compact([
    formattedReleaseType,
    formattedTracksCount,
    formattedReleaseDate
  ]);
}

export function formatReleaseMetadata(
  release: Partial<useReleaseMetadata_release$data>
): string {
  return formatReleaseMetadataParts(release).join(" - ");
}

export function formatReleaseType(releaseType: ReleaseTypeEnum): string {
  switch (releaseType) {
    case "album":
      return "Album";
    case "compilation":
      return "Compilation";
    case "single":
      return "Single";
  }
}

export function formatReleaseDate(releaseDate: string | null): string {
  if (!releaseDate) {
    return "Upcoming";
  }
  const formattedReleaseDate = dayjs(releaseDate as string).format(
    "MMM D, YYYY"
  );

  return dayjs().isBefore(dayjs(releaseDate as string))
    ? `${formattedReleaseDate} (expected)`
    : formattedReleaseDate;
}
