import { FundDirectoryQuery as FundDirectoryQueryType } from "__generated__/FundDirectoryQuery.graphql";
import { ReactElement, Suspense, useState } from "react";
import { Form } from "react-final-form";
import { graphql, PreloadedQuery } from "react-relay";
import { Box } from "theme-ui";

import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { useAuth } from "../../hooks/useAuth";
import useDebouncedState from "../../hooks/useDebouncedState";
import DirectoryPageTitle from "../03_UI_Kit/Discover/DirectoryPageTitle";
import FundDirectorySearchSort, {
  companyOrderingOptions
} from "../03_UI_Kit/Discover/FundDirectorySearchSort";
import RowHeaderFund from "../03_UI_Kit/Discover/RowHeaderFund";
import DirectorySkeleton from "./DirectorySkeleton";
import FundDirectoryFilters, { IFundsFilterForm } from "./FundDirectoryFilters";
import FundDirectoryRows from "./FundDirectoryRows";

interface IFundDirectoryProps {
  queryRef: PreloadedQuery<FundDirectoryQueryType>;
}

/**
 * figma: https://www.figma.com/file/xyf6tm0ZBXnA2XM1yAl78l/03-Discover?node-id=1804%3A86011
 */
function FundDirectory(props: IFundDirectoryProps): ReactElement {
  const [showWatchedOnly, setShowWatchedOnly] = useState<boolean>(false);
  const toggleShowWatchedOnly = () => setShowWatchedOnly(!showWatchedOnly);

  const [search, setSearch] = useDebouncedState<string>("");

  const { authUser } = useAuth();
  const canMessage = authUser.userType === "artist";

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}
    >
      <Form<IFundsFilterForm>
        onSubmit={() => {}}
        initialValues={{
          orderBy: companyOrderingOptions.find(
            ({ value }) => value === "total_streams_desc"
          )
        }}
      >
        {_ => (
          <FixedGrid>
            <GridItem gridColumn={"1 / -1"}>
              <DirectoryPageTitle
                size={["small", "large", "large", "large"]}
                title={"Companies"}
                description={`Discover the top companies looking to partner with independent artists.  Partners help artists grow their career while staying independent by providing services like marketing, creative, and pitching. ${
                  canMessage
                    ? "If you see a partnership opportunity, you can start the conversation by messaging a company directly."
                    : ""
                }`}
              />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <FundDirectorySearchSort filterInput={setSearch} />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <FundDirectoryFilters
                size={["small", "medium", "large", "extraLarge"]}
              />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <RowHeaderFund
                size={["small", "medium", "large", "extraLarge"]}
                showWatchedOnly={showWatchedOnly}
                toggleShowWatchedOnly={toggleShowWatchedOnly}
              />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <Box
                sx={{
                  backgroundColor: "white100",
                  width: "100%",
                  border: "1px solid",
                  borderColor: "midGray70",
                  borderTop: "unset",
                  borderBottom: "unset"
                }}
              >
                <Suspense fallback={<DirectorySkeleton />}>
                  <FundDirectoryRows
                    onlyWatching={showWatchedOnly}
                    displayName_Icontains={search}
                    queryRef={props.queryRef}
                  />
                </Suspense>
              </Box>
            </GridItem>
          </FixedGrid>
        )}
      </Form>
    </GridItem>
  );
}

export const FundDirectoryQuery = graphql`
  query FundDirectoryQuery {
    ...FundDirectoryRows_allCompanies
  }
`;

export default FundDirectory;
