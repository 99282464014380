import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import TextButton from "../../../../../components/01_Core/Buttons/TextButton";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { rvMap } from "../../../../../utils/responsiveUtils";

type FooterSize = "small" | "large";
interface ICta {
  label: string;
  loading?: boolean;
  onClick: () => void;
}
interface IFooterProps {
  size: ResponsiveValue<FooterSize>;
  primaryCta: ICta & { disabled?: boolean };
  secondaryCta?: ICta & { icon?: ReactNode };
  tertiaryCta?: ICta & { icon?: ReactNode };
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1363%3A6
 */
function Footer(props: IFooterProps): ReactElement {
  return (
    <AutoLayout
      spacing={48}
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
    >
      <Divider />
      <LoadingButton
        sx={{ width: "100%" }}
        onClick={props.primaryCta.onClick}
        disabled={props.primaryCta.disabled}
        loading={props.primaryCta.loading}
        size={rvMap(props.size, getPrimaryButtonSize)}
        variant={"primary"}
      >
        {props.primaryCta.label}
      </LoadingButton>
      {(props.secondaryCta || props.tertiaryCta) && (
        <Flex sx={{ justifyContent: "space-between", width: "100%" }}>
          {props.secondaryCta != null ? (
            <TextButton
              type={"secondary"}
              onClick={props.secondaryCta.onClick}
              size={"large"}
              iconProps={{
                placement: "left",
                icon: props.secondaryCta.icon ?? undefined
              }}
            >
              {props.secondaryCta.label}
            </TextButton>
          ) : null}
          {props.tertiaryCta != null ? (
            <TextButton
              type={"secondary"}
              onClick={props.tertiaryCta.onClick}
              size={"large"}
              iconProps={{
                placement: "right",
                icon: props.tertiaryCta.icon ?? undefined
              }}
            >
              {props.tertiaryCta.label}
            </TextButton>
          ) : null}
        </Flex>
      )}
    </AutoLayout>
  );
}

function getPrimaryButtonSize(size: FooterSize) {
  switch (size) {
    case "small":
      return "medium";
    case "large":
      return "large";
  }
}

export default Footer;
