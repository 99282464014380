import { useCanAccessMarketplace_authUser$key } from "__generated__/useCanAccessMarketplace_authUser.graphql";
import { graphql, useFragment } from "react-relay";

import { useAuth } from "./useAuth";
import useSubscribedUser from "./useSubscribedUser";

export default function useCanAccessMarketplace(): [boolean, string] {
  const authUser = useFragment<useCanAccessMarketplace_authUser$key>(
    graphql`
      fragment useCanAccessMarketplace_authUser on UserNode {
        userType
        canAccessMarketplace
      }
    `,
    useAuth().authUser
  );
  const subscribedUser = useSubscribedUser();

  const canAccessMarketplace = authUser?.canAccessMarketplace ?? false;

  if (
    canAccessMarketplace &&
    (subscribedUser ||
      // Artists can access the marketplace even if they are not subscribed
      authUser.userType === "artist")
  ) {
    return [true, null];
  }

  return [
    false,
    subscribedUser
      ? "Join the marketplace to access this feature!"
      : "Update your subscription to access this feature!"
  ];
}
