import { ReactElement } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import PasswordInput from "../../../../../components/01_Core/Forms/Inputs/PasswordInput";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import {
  composeFieldValidators,
  emailValidator,
  getMinimumLengthStringValidator,
  requiredStringValidator
} from "../../../../../components/01_Core/Forms/utils/validators";
import Header from "../../03_UI_Kit/Forms/Header";

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=466%3A23482
 */
function LoginInfo(): ReactElement {
  const emailFieldValidator = composeFieldValidators(
    requiredStringValidator,
    emailValidator
  );

  return (
    <AutoLayout
      spacing={[32, 48, 48, 48]}
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
    >
      <Header size={["small", "small", "large", "large"]}>
        Enter your info
      </Header>
      <FieldBuilder
        fieldName={"email"}
        inputField={TextInput}
        validator={emailFieldValidator}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Email address",
          caption: "This is what you will use to sign in to your account."
        }}
      />
      <FieldBuilder
        fieldName={"password"}
        inputField={PasswordInput}
        validator={composeFieldValidators(
          requiredStringValidator,
          getMinimumLengthStringValidator(
            8,
            "Your password must be at least 8 characters."
          )
        )}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Choose password",
          caption: "Your password must be at least 8 characters in length."
        }}
      />
      <FieldBuilder
        fieldName={"passwordConfirm"}
        inputField={PasswordInput}
        validator={requiredStringValidator}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Repeat password"
        }}
      />
    </AutoLayout>
  );
}

export default LoginInfo;
