import {
  ErrorBoundary as SentryErrorBoundary,
  withProfiler as withSentryProfiler
} from "@sentry/react";
import { ReactElement } from "react";
import { createRoot } from "react-dom/client";
import { RelayEnvironmentProvider } from "react-relay";
import { ThemeUIProvider } from "theme-ui";

import GlobalErrorPage from "./components/00_Globals/GlobalErrorPage";
import environment from "./environment";
import Routing from "./pages/indify/Routing/Routing";
import GlobalStyles from "./theme/GlobalStyles";
import theme from "./theme/theme";
import { initSentry } from "./utils/sentryUtils";
import { BreakpointProvider } from "./utils/useBreakpoints";

initSentry();

function App(): ReactElement {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <ThemeUIProvider theme={theme}>
        <GlobalStyles />
        <BreakpointProvider>
          <SentryErrorBoundary fallback={<GlobalErrorPage />}>
            <Routing />
          </SentryErrorBoundary>
        </BreakpointProvider>
      </ThemeUIProvider>
    </RelayEnvironmentProvider>
  );
}

const AppWithSentryProfiler = withSentryProfiler(App);

const root = createRoot(document.getElementById("react_entry"));
root.render(<AppWithSentryProfiler />);
