import { ReactElement, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { ResponsiveValue } from "styled-system";
import { useThemeUI } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Button from "../../../../../components/01_Core/Buttons/Button";
import { Field } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";

export const signatureFieldName = "signature";
type SignatureType = string;

export type FormSignatureFieldSize = "small" | "medium";
export interface IFormSignatureFieldProps {
  size: ResponsiveValue<FormSignatureFieldSize>;
}

function FormSignatureField(props: IFormSignatureFieldProps): ReactElement {
  const { theme } = useThemeUI();
  const signatureRef = useRef(null);
  const activeSize = useActiveResponsiveValue(props.size);
  const validator = (value: SignatureType) => (value ? undefined : "Required");

  return (
    <Field<SignatureType> name={signatureFieldName} validate={validator}>
      {({ input }) => {
        return (
          <AutoLayout
            spacing={4}
            dependentProps={{ direction: "vertical" }}
            resizingX={FillContainer()}
            sx={{ width: "inherit" }}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                style: {
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: theme.colors.midGray100 as string,
                  borderRadius: "4px",
                  width: "100%",
                  height: getCanvasHeight(activeSize)
                }
              }}
              ref={signatureRef}
              onEnd={() =>
                input.onChange(
                  signatureRef.current.getTrimmedCanvas().toDataURL("image/png")
                )
              }
            />
            <Button
              size={"small"}
              variant={"tertiary"}
              disabled={false}
              onClick={() => {
                signatureRef.current.clear();
                input.onChange(undefined);
              }}
              sx={{ width: "100%" }}
            >
              Clear Signature
            </Button>
          </AutoLayout>
        );
      }}
    </Field>
  );
}

function getCanvasHeight(size: FormSignatureFieldSize): string {
  switch (size) {
    case "medium":
      return "150px";
    case "small":
      return "125px";
  }
}

export default FormSignatureField;
