import { ArtistDetails_referral$key } from "__generated__/ArtistDetails_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Header from "../../03_UI_Kit/Forms/Header";
import SectionProfilePhoto from "../../03_UI_Kit/Forms/SectionProfilePhoto";
import Genre from "../../08_Account_Management/FormFields/Genre";
import Location from "../../08_Account_Management/FormFields/Location";

interface IArtistDetailsProps {
  referral: ArtistDetails_referral$key;
}

function ArtistDetails(props: IArtistDetailsProps): ReactElement {
  const referral = useFragment(
    graphql`
      fragment ArtistDetails_referral on IndifyToArtistReferralNode {
        id
        thumbnailUrl
      }
    `,
    props.referral
  );

  return (
    <AutoLayout
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
      spacing={48}
    >
      <Header size={["small", "small", "large", "large"]}>
        Tell us about yourself
      </Header>
      {/* TODO: responsive size */}
      <SectionProfilePhoto
        caption={
          "Add a profile photo to help partners feel confident in sending you partnership offers."
        }
        ctaText={"Upload photo"}
        size={"large"}
        thumbnailUrl={referral.thumbnailUrl}
        nodeId={referral.id}
      />
      <Genre fieldName="genre" label="Genre" isMulti={false} />
      <Location />
    </AutoLayout>
  );
}

export default ArtistDetails;
