import { Properties } from "csstype";
import {
  ComponentType,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  Ref,
  SyntheticEvent
} from "react";
import { FieldMetaState } from "react-final-form";
import { ResponsiveValue } from "styled-system";
import {
  Flex,
  InputProps as ThemeUIInputProps,
  Text,
  TextareaProps as ThemeUITextAreaProps,
  Theme,
  useThemeUI
} from "theme-ui";

import { fontVariants } from "../../../theme/font";
import { rvMap, useFindActiveRV } from "../../../utils/responsiveUtils";
import AutoLayout from "../AutoLayout";
import { IconBox } from "../Foundations/Icons";
import { TooltipIconRight } from "../Tooltips/Tooltip";

export type InputSize = "small" | "medium" | "large";
export type InputState = "normal" | "alert" | "disabled";

/**
 * Type used for all of our input component subtypes
 * e.g. NumberInput, DollarInput, PercentInput, etc
 */
export type InputProps<
  ElementType extends HTMLInputElement | HTMLTextAreaElement,
  FieldType
> = IInputElementProps<ElementType, FieldType> & IInputRenderProps;

/**
 * Props passed to the underlying input element
 * e.g. styles, value, onChange, onSubmit, etc
 */
export type IInputElementProps<
  ElementType extends HTMLInputElement | HTMLTextAreaElement,
  FieldType
> = Omit<
  ElementType extends HTMLInputElement
    ? ThemeUIInputProps
    : ThemeUITextAreaProps,
  "size" | "ref"
> &
  Partial<{
    input: {
      name: string;
      onChange: (value: FieldType) => void;
      onBlur?: (event?: SyntheticEvent) => void;
      onFocus?: (event?: SyntheticEvent) => void;
      type?: string;
      onSubmit?: () => void;
      onKeyDown?: (event: KeyboardEvent) => void;
      value?: FieldType;
      defaultValue?: FieldType;
      checked?: boolean;
      multiple?: boolean;
    };
    meta: FieldMetaState<FieldType>;
    inputRef: Ref<ElementType>;
    ref: Ref<ElementType>;
  }>;

/**
 * Shared render props that non-input components use
 * e.g. size, label, caption, etc
 */
export interface IBaseRenderProps {
  size: ResponsiveValue<InputSize>;
  label?: ReactNode;
  labelTooltip?: ReactNode;
  caption?: ReactNode;
  placeholder?: string;
  state?: InputState;
}

/**
 * Props used during the render of the input wrapper
 * e.g. size, positioning, icon, label, etc
 */
interface IInputRenderProps extends IBaseRenderProps {
  action?: string;
  iconProps?: {
    Icon: SvgrComponent;
    placement: "left" | "right";
    color?: Properties["color"];
  };
  labelBadge?: ReactElement;
}

type IInputWrapperProps<
  ElementType extends HTMLInputElement | HTMLTextAreaElement,
  FieldType
> = InputProps<ElementType, FieldType> & {
  InputComponent: ComponentType<
    ElementType extends HTMLInputElement
      ? ThemeUIInputProps
      : ThemeUITextAreaProps
  >;
  isTextareaComponent?: boolean;
};

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1279%3A22960
 */
function Input<
  ElementType extends HTMLInputElement | HTMLTextAreaElement,
  FieldType
>(props: IInputWrapperProps<ElementType, FieldType>): ReactElement {
  const {
    InputComponent,
    size,
    label,
    labelTooltip,
    caption,
    state,
    action,
    iconProps,
    labelBadge,
    isTextareaComponent,
    inputRef,
    sx,
    ...rest
  } = props;
  const { theme } = useThemeUI();

  if (action != null && iconProps?.placement === "right") {
    throw new Error("Can't have an action and a right icon");
  }

  const responsiveInputHeight = rvMap(size, getInputHeight);
  const responsiveTextareaHeight = rvMap(size, getTextareaHeight);
  const responsiveFontSize = rvMap(size, getFontSize);
  // TODO: calculate this from the action text length?
  const actionPadding = 18;

  const leftPadding = props.pl
    ? props.pl
    : iconProps != null
    ? rvMap(size, getPlWithIcon)
    : rvMap(size, getPxWithoutIcon);
  const rightPadding =
    action != null ? actionPadding : rvMap(size, getPxWithoutIcon);

  const labelElement = label && <InputLabel size={size} label={label} />;

  const inputComponentProps = {
    ref: inputRef,
    py: "8px",
    pl: leftPadding,
    pr: rightPadding,
    backgroundColor: props.disabled
      ? theme.colors.midGray70
      : theme.colors.white100,
    sx: {
      width: "100%",
      height: isTextareaComponent
        ? responsiveTextareaHeight
        : responsiveInputHeight,
      minWidth: "55px",
      color: props.disabled ? theme.colors.deepGray70 : theme.colors.black100,
      fontSize: responsiveFontSize,
      cursor: "text",
      borderRadius: "4px",
      border: "1px solid",
      borderColor: getInputBorderColor(state),
      ":focus": {
        borderColor: getInputHoverBorderColor(state)
      },
      "::placeholder": { color: theme.colors.deepGray100 },
      ...sx
    },
    ...rest
  };

  return (
    <AutoLayout
      spacing={rvMap(size, getInputSpacing)}
      dependentProps={{ direction: "vertical" }}
      sx={{ width: "inherit" }}
    >
      {(label || labelBadge) && (
        <AutoLayout
          spacing={8}
          dependentProps={{
            direction: "horizontal"
          }}
        >
          {labelTooltip ? (
            <TooltipIconRight size={size} content={labelTooltip}>
              {labelElement}
            </TooltipIconRight>
          ) : (
            labelElement
          )}
          {labelBadge}
        </AutoLayout>
      )}
      <Flex
        sx={{ alignItems: "center", position: "relative", width: "inherit" }}
      >
        {iconProps && (
          <Flex
            sx={{
              position: "absolute",
              height: responsiveInputHeight,
              alignItems: "center",
              [iconProps.placement]: rvMap(size, getIconOffset)
            }}
          >
            <IconBox
              icon={iconProps.Icon}
              sx={{
                color: iconProps.color,
                size: rvMap(size, getIconBoxSize),
                mt: "-1px"
              }}
            />
          </Flex>
        )}
        {action && (
          <Text
            sx={{
              position: "absolute",
              right: actionPadding
            }}
          >
            {action}
          </Text>
        )}
        <InputComponent
          disabled={props.disabled}
          {...(inputComponentProps as any)}
        />
      </Flex>
      {caption && state != "alert" && (
        <InputCaption size={size} caption={caption} state={state} />
      )}
    </AutoLayout>
  );
}

export function InputLabel(props: {
  size: ResponsiveValue<InputSize>;
  label: ReactNode;
}): ReactElement {
  const labelVariant = useFindActiveRV(props.size, getLabelVariant);

  return (
    <Text variant={labelVariant} color={"black100"}>
      {props.label}
    </Text>
  );
}

export function InputCaption(props: {
  size: ResponsiveValue<InputSize>;
  caption: ReactNode;
  state: InputState;
}): ReactElement {
  const captionTextVariant = useFindActiveRV(props.size, getCaptionTextVariant);

  return (
    <Text
      variant={captionTextVariant}
      sx={{ color: theme => getCaptionColor(props.state, theme) }}
    >
      {props.caption}
    </Text>
  );
}

function getCaptionColor(state: InputState, theme: Theme): string {
  switch (state) {
    case "alert":
      return theme.colors.alert50 as string;
    default:
      return theme.colors.deepGray100 as string;
  }
}

function getCaptionTextVariant(size: InputSize) {
  switch (size) {
    case "small":
    case "medium":
      return "bodySmall";
    case "large":
      return "bodyMedium";
  }
}

export function getInputHoverBorderColor(state: InputState): string {
  const { theme } = useThemeUI();

  switch (state) {
    case "alert":
      return theme.colors.alert50 as string;
    default:
      return theme.colors.secondary20 as string;
  }
}

export function getInputBorderColor(state: InputState): string {
  const { theme } = useThemeUI();

  switch (state) {
    case "alert":
      return theme.colors.alert50 as string;
    default:
      return theme.colors.midGray100 as string;
  }
}

function getTextareaHeight(inputSize: InputSize): number {
  switch (inputSize) {
    case "small":
    case "medium":
      return 108;
    case "large":
      return 144;
  }
}

export function getInputHeight(inputSize: InputSize): number {
  switch (inputSize) {
    case "small":
      return 32;
    case "medium":
      return 40;
    case "large":
      return 48;
  }
}

function getIconOffset(size: InputSize): number {
  switch (size) {
    case "small":
      return 12;
    case "medium":
    case "large":
      return 16;
  }
}

function getPlWithIcon(size: InputSize): number {
  switch (size) {
    case "small":
      return 36;
    case "medium":
    case "large":
      return 44;
  }
}

function getPxWithoutIcon(size: InputSize): number {
  switch (size) {
    case "small":
      return 12;
    case "medium":
    case "large":
      return 16;
  }
}

function getIconBoxSize(size: InputSize): number {
  switch (size) {
    case "small":
      return 16;
    case "medium":
      return 20;
    case "large":
      return 24;
  }
}

export function getLabelVariant(size: InputSize): string {
  switch (size) {
    case "small":
      return "h100";
    case "medium":
      return "h200";
    case "large":
      return "h300";
  }
}

export function getInputSpacing(inputSize: InputSize): number {
  switch (inputSize) {
    case "small":
      return 4;
    case "medium":
    case "large":
      return 8;
  }
}

export function getFontSize(inputSize: InputSize): string {
  // TODO: don't reference fontVariants directly, instead use the theme
  switch (inputSize) {
    case "small":
      return fontVariants.bodySmall.fontSize;
    case "medium":
      return fontVariants.bodyMedium.fontSize;
    case "large":
      return fontVariants.bodyLarge.fontSize;
  }
}

export default Input;
