import {
  ReleaseTypeEnum,
  useFeaturedDealTitle$key
} from "__generated__/useFeaturedDealTitle.graphql";
import { flatten, partition, sum } from "lodash";
import { Fragment, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { uppercaseFirstLetter } from "../../../utils/stringFormatting";

export default function useFeaturedDealTitle(
  featuredDeal: useFeaturedDealTitle$key,
  formatted = true
): ReactNode[] {
  const { releases, unreleasedReleases } = useFragment(
    graphql`
      fragment useFeaturedDealTitle on OfferFeaturedDealInterface {
        releases {
          edges {
            node {
              name
              releaseType
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              name
              releaseType
            }
          }
        }
      }
    `,
    featuredDeal
  );

  const allReleases = [releases, unreleasedReleases].flatMap(a =>
    a.edges.map(e => e.node)
  );
  return getFeaturedDealTitle(allReleases, formatted);
}

const numberEnglish = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine"
];

type Release = { name?: string; releaseType?: ReleaseTypeEnum };
export function getFeaturedDealTitle(
  releases: readonly Release[],
  formatted = true
): ReactNode[] {
  // Sort releases with no name to the back of the list
  const allReleases = flatten(partition(releases, r => r.name != null));

  const segments = allReleases
    .map((r, i) => {
      // Old code for handling null names, which are currently not permitted
      if (!r.name) {
        return r.releaseType
          ? `not yet titled ${r.releaseType}`
          : "unspecified release";
      }
      if (!formatted) return r.name;
      return r.releaseType === "single" ? (
        `"${r.name}"`
      ) : (
        <em key={i}>{r.name}</em>
      );
    })
    // Group identical names together (for missing names)
    .reduce((acc, name) => {
      const existingGroup = acc.find(group => group[0] === name);
      if (existingGroup) {
        existingGroup.push(name);
      } else {
        acc.push([name]);
      }
      return acc;
    }, [] as ReactNode[][])
    // Sort the biggest groups to the end
    .sort((a, b) => a.length - b.length)
    // Build labels from grouped names
    .map((nameGroup, i) => {
      const groupSize = nameGroup.length;
      let label =
        groupSize === 1
          ? nameGroup[0]
          : `${numberEnglish[groupSize] ?? groupSize} ${nameGroup[0]}s`;
      if (i === 0 && typeof label === "string") {
        label = uppercaseFirstLetter(label);
      }
      return { label, nameGroup };
    });

  switch (segments.length) {
    case 0:
      return ["No releases"];
    case 1:
      return [segments[0].label];
    case 2:
      return [segments[0].label, " and ", segments[1].label];
    default: {
      const othersCount = sum(segments.slice(1).map(s => s.nameGroup.length));
      return [segments[0].label, ` + ${othersCount} others`];
    }
  }
}
