import { SpecialDeals_highlightDeals$key } from "__generated__/SpecialDeals_highlightDeals.graphql";
import { SpecialDeals_matchForCompanyDeals$key } from "__generated__/SpecialDeals_matchForCompanyDeals.graphql";
import {
  SpecialDealsDismissCompanyMatchMutation,
  SpecialDealsDismissCompanyMatchMutation$variables
} from "__generated__/SpecialDealsDismissCompanyMatchMutation.graphql";
import SpecialDealsMatchForCompanyDealsRefetchQuery, {
  SpecialDealsMatchForCompanyDealsRefetchQuery as SpecialDealsMatchForCompanyDealsRefetchQueryType
} from "__generated__/SpecialDealsMatchForCompanyDealsRefetchQuery.graphql";
import { SpecialDealsQuery as SpecialDealsQueryType } from "__generated__/SpecialDealsQuery.graphql";
import { motion } from "framer-motion";
import { ReactElement, useContext } from "react";
import {
  fetchQuery,
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery
} from "react-relay";

import { BannerContext } from "../../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import useMutationPromise from "../../../hooks/useMutationPromise";
import SpecialDeal from "./SpecialDeal";

export default function SpecialDeals(props: {
  queryRef: PreloadedQuery<SpecialDealsQueryType>;
}): ReactElement {
  const root = usePreloadedQuery<SpecialDealsQueryType>(
    SpecialDealsQuery,
    props.queryRef
  );

  const matchForCompanyDeals =
    useFragment<SpecialDeals_matchForCompanyDeals$key>(
      graphql`
        fragment SpecialDeals_matchForCompanyDeals on Query
        @refetchable(
          queryName: "SpecialDealsMatchForCompanyDealsRefetchQuery"
        ) {
          matchForCompanyDeals: availableFeaturedDeals(
            isMatchForCompany: true
            isMatchDismissed: false
            first: 3
            orderBy: "-date"
          ) {
            edges {
              node {
                id
                ...SpecialDeal_featuredDeal
              }
            }
          }
        }
      `,
      root
    ).matchForCompanyDeals;

  const highlightDeals = useFragment<SpecialDeals_highlightDeals$key>(
    graphql`
      fragment SpecialDeals_highlightDeals on Query {
        highlightDeals: availableFeaturedDeals(
          isMatchForCompany: false
          isHighlight: true
          first: 3
          orderBy: "-date"
        ) {
          edges {
            node {
              id
              ...SpecialDeal_featuredDeal
            }
          }
        }
      }
    `,
    root
  ).highlightDeals;

  const notifyBanner = useContext(BannerContext);

  const [commitDismissMatch] =
    useMutationPromise<SpecialDealsDismissCompanyMatchMutation>(graphql`
      mutation SpecialDealsDismissCompanyMatchMutation(
        $featuredDealNodeId: String!
      ) {
        companyFeaturedDealMatchDismiss(
          featuredDealNodeId: $featuredDealNodeId
        ) {
          featuredDeal {
            id
          }
        }
      }
    `);

  const dismissMatch = (
    variables: SpecialDealsDismissCompanyMatchMutation$variables
  ) =>
    commitDismissMatch({ variables })
      .then(() =>
        fetchQuery<SpecialDealsMatchForCompanyDealsRefetchQueryType>(
          props.queryRef.environment,
          SpecialDealsMatchForCompanyDealsRefetchQuery,
          {}
        ).toPromise()
      )
      .then(() =>
        notifyBanner({
          type: "neutral",
          children:
            "This deal has been removed from your personal recommendations."
        })
      )
      .catch(() =>
        notifyBanner({
          type: "alert",
          children: "Something went wrong."
        })
      );

  const specialDeals = [
    {
      deals: matchForCompanyDeals,
      canDismiss: true,
      dealProps: {
        ribbonProps: {
          textColor: "white100",
          ribbonFrontColor: "alert50",
          ribbonBackColor: "alert100",
          children: "For You"
        }
      }
    },
    {
      deals: highlightDeals,
      dealProps: {
        ribbonProps: {
          textColor: "white100",
          ribbonFrontColor: "black100",
          ribbonBackColor: "#555555",
          children: "Featured"
        }
      }
    }
  ]
    .flatMap(({ deals, canDismiss, dealProps }) =>
      deals.edges.map(({ node: deal }) => {
        return (
          <motion.div
            key={deal.id}
            layout
            initial={{ opacity: 0, scaleX: 0, scaleY: 0 }}
            animate={{ opacity: 1, scaleX: 1, scaleY: 1 }}
          >
            <SpecialDeal
              {...dealProps}
              featuredDeal={deal}
              showEditorialNote={true}
              size={"small"}
              onDismissMatch={
                canDismiss &&
                (() => dismissMatch({ featuredDealNodeId: deal.id }))
              }
            />
          </motion.div>
        );
      })
    )
    .slice(0, 3);

  return (
    <AutoLayout
      dependentProps={[
        { direction: "vertical", alignment: "center" },
        { direction: "vertical", alignment: "center" },
        { direction: "horizontal", alignment: "center" },
        { direction: "horizontal", alignment: "center" }
      ]}
      spacing={24}
      resizingX={FillContainer()}
    >
      {specialDeals}
    </AutoLayout>
  );
}

export const SpecialDealsQuery = graphql`
  query SpecialDealsQuery {
    ...SpecialDeals_matchForCompanyDeals
    ...SpecialDeals_highlightDeals
  }
`;
