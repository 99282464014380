import {
  ProfilePlatform,
  SocialLinksIcons_profiles$key
} from "__generated__/SocialLinksIcons_profiles.graphql";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { Link as ExternalLink } from "theme-ui";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import { getPlatformIcons } from "../../../../utils/getPlatformIcons";

const PLATFORMS = GLOBALS.PLATFORMS;

interface ISocialLinksIconsProps {
  profiles: SocialLinksIcons_profiles$key;
  spacing?: number;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=1047%3A18201
 */
function SocialLinksIcons(props: ISocialLinksIconsProps): ReactElement {
  const profiles = useFragment(
    graphql`
      fragment SocialLinksIcons_profiles on ProfileNode @relay(plural: true) {
        id
        url
        platform
      }
    `,
    props.profiles
  );

  return (
    <AutoLayout
      spacing={props.spacing ?? 16}
      dependentProps={{ direction: "horizontal", alignment: "center" }}
      sx={{ flexWrap: "wrap" }}
    >
      {profiles
        .filter(p => PLATFORMS[p.platform].regexes.profile)
        .map(p => (
          <IconLink key={p.id} url={p.url} platform={p.platform} />
        ))}
    </AutoLayout>
  );
}

function IconLink(props: {
  platform: ProfilePlatform;
  url: string;
}): ReactElement {
  const [, [IconMedium]] = getPlatformIcons(props.platform);

  const httpFormattedUrl = `${/^http/i.test(props.url) ? "" : "https://"}${
    props.url
  }`;

  return (
    <ExternalLink href={httpFormattedUrl} target={"_blank"}>
      <IconMedium />
    </ExternalLink>
  );
}

export default SocialLinksIcons;
