import { HomebasePartners_partner$key } from "__generated__/HomebasePartners_partner.graphql";
import { HomebasePartners_partnerLocations$key } from "__generated__/HomebasePartners_partnerLocations.graphql";
import { HomebasePartners_partners$key } from "__generated__/HomebasePartners_partners.graphql";
import { HomebasePartners_query$key } from "__generated__/HomebasePartners_query.graphql";
import { HomebasePartnersPaginationQuery } from "__generated__/HomebasePartnersPaginationQuery.graphql";
import { ReactElement, Suspense, useEffect, useState } from "react";
import { Form, useFormState } from "react-final-form";
import { graphql, useFragment, useRefetchableFragment } from "react-relay";
import { Box, Link as ExternalLink, Text } from "theme-ui";

import Ic20OpenInNew from "../../../../../imgs/icons/ic20-open-in-new.svg";
import Ic24ArrowLeft from "../../../../../imgs/icons/ic24-arrow-left.svg";
import Ic24ArrowRight from "../../../../../imgs/icons/ic24-arrow-right.svg";
import Ic24Dollar from "../../../../../imgs/icons/ic24-dollar.svg";
import Ic24Filter from "../../../../../imgs/icons/ic24-filter.svg";
import Ic24Map from "../../../../../imgs/icons/ic24-map.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import Linkify from "../../../../utils/Linkify";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { noop } from "../../../../utils/utils";
import RowCell from "../03_UI_Kit/Discover/RowCell";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";

const mediumGridTemplateAreas = `
    "nm nm nm nm lo lo lo lo"
    "fe fe fe fe fe fe fe fe"
  `; // 8

const rvGridTemplateAreas = [
  `
      "nm nm nm nm"
      "fe fe fe fe"
      "lo lo lo lo"
    `, // 4
  mediumGridTemplateAreas,
  mediumGridTemplateAreas,
  `"nm nm nm nm fe fe fe fe lo lo lo lo"` // 12
];

interface HomebasePartnersForm {
  locations: { value: string; label: string }[];
}

const PAGE_SIZE = 5;

export default function HomebasePartners(props: {
  query: HomebasePartners_query$key;
}): ReactElement {
  const query = useFragment(
    graphql`
      fragment HomebasePartners_query on Query {
        ...HomebasePartners_partnerLocations
        ...HomebasePartners_partners
      }
    `,
    props.query
  );

  return (
    <AutoLayout
      spacing={32}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
    >
      <Text variant={"h500"}>Content Strategy Partners</Text>
      <Text variant={"bodyMedium"}>
        <Form<HomebasePartnersForm>
          onSubmit={noop}
          initialValues={{ locations: [] }}
        >
          {_ => {
            return (
              <>
                <HomebasePartnersFilters query={query} />
                <Suspense fallback={<HomebasePartnerRowsPlaceholder />}>
                  <HomebasePartnerRows query={query} />
                </Suspense>
              </>
            );
          }}
        </Form>
      </Text>
    </AutoLayout>
  );
}

function HomebasePartnerRowsPlaceholder() {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white100",
          width: "100%",
          border: "1px solid",
          borderColor: "midGray70",
          borderBottom: "unset"
        }}
      >
        {Array.from({ length: 3 }).map((_num, i) => (
          <HomebasePartnerRowPlaceholder key={i} />
        ))}
      </Box>
      <HomebasePartnersPageControls
        pageNum={0}
        setPageNum={noop}
        disabled={true}
        hasNext={false}
      />
    </>
  );
}

function HomebasePartnerRows(props: { query: HomebasePartners_partners$key }) {
  const [data, refetch] = useRefetchableFragment<
    HomebasePartnersPaginationQuery,
    HomebasePartners_partners$key
  >(
    graphql`
      fragment HomebasePartners_partners on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 3 }
        offset: { type: "Int", defaultValue: 0 }
        locations: { type: "[String!]", defaultValue: [] }
      )
      @refetchable(queryName: "HomebasePartnersPaginationQuery") {
        homebasePartners(
          first: $count
          offset: $offset
          locations: $locations
        ) {
          edges {
            node {
              id
              ...HomebasePartners_partner
            }
          }
        }
      }
    `,
    props.query
  );

  const formValues = useFormState<HomebasePartnersForm>().values;

  const [pageNum, setPageNum] = useState(0);
  // Reset page number when filters change
  useEffect(() => setPageNum(0), [formValues.locations]);

  const edges = data.homebasePartners.edges;

  useEffect(() => {
    refetch({
      locations: formValues.locations.map(o => o.value),
      count: PAGE_SIZE + 1, // Fetch one extra to determine if there are more pages
      offset: pageNum * PAGE_SIZE
    });
  }, [formValues.locations, pageNum]);

  return edges.length > 0 ? (
    <>
      {
        <Box
          sx={{
            backgroundColor: "white100",
            width: "100%",
            border: "1px solid",
            borderColor: "midGray70",
            borderBottom: "unset"
          }}
        >
          {edges
            .slice(0, PAGE_SIZE)
            .map(e => e.node)
            .map(p => (
              <HomebasePartnerRow key={p.id} query={p} />
            ))}
        </Box>
      }
      <HomebasePartnersPageControls
        pageNum={pageNum}
        setPageNum={setPageNum}
        disabled={false}
        hasNext={edges.length > PAGE_SIZE}
      />
    </>
  ) : (
    <Box sx={{ textAlign: "center", padding: "16px" }}>
      <Text variant={"bodyLarge"}>
        <em>No partners found</em>
      </Text>
    </Box>
  );
}

function HomebasePartnerRowPlaceholder() {
  const gridTemplateAreas = useActiveResponsiveValue(rvGridTemplateAreas);
  function BlankCell() {
    return (
      <Box
        sx={{ width: "100%", height: "1lh", backgroundColor: "lightGray100" }}
      />
    );
  }
  return (
    <Text variant="bodyLarge">
      <FixedGrid sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"nm"}>
          <RowCell styles={{ paddingLeft: [0, 0, "16px", "16px"] }}>
            <BlankCell />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"fe"}>
          <RowCell>
            <BlankCell />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"lo"}>
          <RowCell styles={{ paddingRight: [0, 0, "16px", "16px"] }}>
            <BlankCell />
          </RowCell>
        </GridItem>
        <GridItem sx={{ gridColumn: "1 / -1" }}>
          <RowCell
            styles={{
              paddingX: [0, 0, "32px", "32px"],
              paddingBottom: "16px",
              height: "fit-content"
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "2lh",
                backgroundColor: "lightGray100"
              }}
            />
          </RowCell>
        </GridItem>
      </FixedGrid>
      <Divider />
    </Text>
  );
}

function HomebasePartnerRow(props: {
  query: HomebasePartners_partner$key;
}): ReactElement {
  const partner = useFragment(
    graphql`
      fragment HomebasePartners_partner on HomebasePartnerNode {
        id
        name
        url
        fee
        location
        deliverables
        about
      }
    `,
    props.query
  );

  const gridTemplateAreas = useActiveResponsiveValue(rvGridTemplateAreas);

  const name = (
    <Text
      sx={{
        borderBottom: "1px solid",
        borderColor: "transparent",
        transition: "border-color 0.2s",
        ...(partner.url && { ":hover": { borderColor: "black" } })
      }}
    >
      {partner.name}
    </Text>
  );

  return (
    <>
      <FixedGrid key={partner.id} sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"nm"}>
          <RowCell styles={{ paddingLeft: [0, 0, "16px", "16px"] }}>
            <Text variant="bodyLarge">
              <strong>
                {partner.url ? (
                  <ExternalLink href={partner.url} target="_blank">
                    <IconLabel
                      Icon={Ic20OpenInNew}
                      iconSide="right"
                      size="0.9em"
                    >
                      {name}
                    </IconLabel>
                  </ExternalLink>
                ) : (
                  name
                )}
              </strong>
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"fe"}>
          <RowCell styles={{ paddingX: [0, 0, "16px", 0] }}>
            <Text color={partner.fee ? "deepGray100" : "midGray100"}>
              <IconLabel Icon={Ic24Dollar} size="1.5em" spacing="0.55ch">
                {partner.fee ? (
                  <Text color="black100" sx={{ width: "fit-content" }}>
                    {partner.fee}
                  </Text>
                ) : (
                  "—"
                )}
              </IconLabel>
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"lo"}>
          <RowCell>
            <Text color={partner.location ? "deepGray100" : "midGray100"}>
              <IconLabel Icon={Ic24Map} size="1.3em" spacing="0.9ch">
                {partner.location ? (
                  <Text color="black100" sx={{ width: "fit-content" }}>
                    {partner.location}
                  </Text>
                ) : (
                  "—"
                )}
              </IconLabel>
            </Text>
          </RowCell>
        </GridItem>
        {partner.deliverables && (
          <GridItem sx={{ gridColumn: "1 / -1" }}>
            <RowCell
              styles={{
                paddingX: [0, 0, "16px", "16px"],
                paddingBottom: "16px",
                height: "fit-content"
              }}
            >
              <AutoLayout
                spacing={4}
                dependentProps={{ direction: "vertical" }}
              >
                <Text variant={"bodyMedium"}>
                  <strong>Ideal Deliverables</strong>
                </Text>
                <Text sx={{ whiteSpace: "pre-line" }}>
                  <Linkify>{partner.deliverables}</Linkify>
                </Text>
              </AutoLayout>
            </RowCell>
          </GridItem>
        )}
        {partner.about && (
          <GridItem sx={{ gridColumn: "1 / -1" }}>
            <RowCell
              styles={{
                paddingX: [0, 0, "16px", "16px"],
                paddingBottom: "16px",
                height: "fit-content"
              }}
            >
              <AutoLayout
                spacing={4}
                dependentProps={{ direction: "vertical" }}
              >
                <Text variant={"bodyMedium"}>
                  <strong>About</strong>
                </Text>
                <Text sx={{ whiteSpace: "pre-line" }}>
                  <Linkify>{partner.about}</Linkify>
                </Text>
              </AutoLayout>
            </RowCell>
          </GridItem>
        )}
      </FixedGrid>
      <Divider />
    </>
  );
}

function HomebasePartnersFilters(props: {
  query: HomebasePartners_partnerLocations$key;
}): ReactElement {
  const { homebasePartnerLocations } = useFragment(
    graphql`
      fragment HomebasePartners_partnerLocations on Query {
        homebasePartnerLocations
      }
    `,
    props.query
  );
  const gridTemplateAreas = useActiveResponsiveValue(rvGridTemplateAreas);
  return (
    <FixedGrid
      sx={{
        ...{ gridTemplateAreas },
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        backgroundColor: theme => theme.colors.lightGray95
      }}
    >
      <GridItem gridArea={"nm"}>
        <RowCell
          styles={{
            paddingLeft: [0, 0, "16px", "16px"],
            alignItems: "center",
            minHeight: "100%"
          }}
        >
          <IconLabel Icon={Ic24Filter}>
            <Text variant="bodyMedium">
              <strong>Filters</strong>
            </Text>
          </IconLabel>
        </RowCell>
      </GridItem>
      <GridItem gridArea={"lo"}>
        <RowCell
          styles={{
            width: "100%",
            paddingY: "16px",
            paddingRight: [0, 0, "16px", "16px"],
            height: "fit-content"
          }}
        >
          <IconLabel
            Icon={Ic24Map}
            size="1.3em"
            spacing="0.9ch"
            styles={{ width: "100%" }}
          >
            <Field name="locations">
              {fieldProps => {
                return (
                  <SelectInput
                    {...fieldProps}
                    menuPortalTarget={document.body}
                    options={homebasePartnerLocations
                      .filter(Boolean)
                      .sort()
                      .map(s => ({
                        value: s,
                        label: s
                      }))}
                    size="medium"
                    isMulti
                    isClearable
                    isCreatable
                    isSearchable
                  />
                );
              }}
            </Field>
          </IconLabel>
        </RowCell>
      </GridItem>
    </FixedGrid>
  );
}

function HomebasePartnersPageControls(props: {
  pageNum: number;
  setPageNum: (pageNum: number) => void;
  hasNext: boolean;
  disabled: boolean;
}) {
  const { hasNext, disabled, pageNum, setPageNum } = props;
  const gridTemplateAreas = useActiveResponsiveValue(rvGridTemplateAreas);
  return (
    <FixedGrid
      sx={{
        ...{ gridTemplateAreas },
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        backgroundColor: theme => theme.colors.lightGray95
      }}
    >
      <GridItem sx={{ gridColumn: "1 / -1" }}>
        <RowCell styles={{ paddingX: [0, 0, "16px", "16px"] }}>
          <AutoLayout
            spacing={0}
            resizingX={FillContainer()}
            dependentProps={{
              direction: "horizontal",
              distribution: "spaceBetween"
            }}
          >
            <TextButton
              type="primary"
              size="medium"
              iconProps={{
                icon: <Ic24ArrowLeft />,
                placement: "left"
              }}
              disabled={disabled || pageNum === 0}
              onClick={() => setPageNum(pageNum - 1)}
            >
              Previous
            </TextButton>
            <TextButton
              type="primary"
              size="medium"
              iconProps={{
                icon: <Ic24ArrowRight />,
                placement: "right"
              }}
              disabled={disabled || !hasNext}
              onClick={() => setPageNum(pageNum + 1)}
            >
              Next
            </TextButton>
          </AutoLayout>
        </RowCell>
      </GridItem>
    </FixedGrid>
  );
}
