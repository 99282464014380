/**
 * @generated SignedSource<<b6536e0ef422f157060ad6d92a80a7ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistManageDeals_artist$data = {
  readonly managerCompany: string | null;
  readonly managerEmail: string | null;
  readonly managerLink: string | null;
  readonly managerName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DealMerchandisingFormPage_artist">;
  readonly " $fragmentType": "ArtistManageDeals_artist";
};
export type ArtistManageDeals_artist$key = {
  readonly " $data"?: ArtistManageDeals_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistManageDeals_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistManageDeals_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerCompany",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerEmail",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealMerchandisingFormPage_artist"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "35b1a2d5d9c365354401fe905d30c25a";

export default node;
