import {
  ArtistInstagramFollowersRange,
  ArtistListPaginationQuery$variables,
  ArtistSpotifyMonthlyListenersRange,
  ArtistStreamsThisWeekRange,
  ArtistTiktokFollowersRange,
  ArtistTiktokPostsRange
} from "__generated__/ArtistListPaginationQuery.graphql";
import _ from "lodash";
import { ReactElement, useState } from "react";
import { ResponsiveValue } from "styled-system";

import Ic24Difference from "../../../../../imgs/icons/ic24-difference.svg";
import Ic24Suitcase from "../../../../../imgs/icons/ic24-suitcase.svg";
import Ic24Voice from "../../../../../imgs/icons/ic24-voice.svg";
import Ic24VolumeMax from "../../../../../imgs/icons/ic24-volume-max.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { InputSize } from "../../../../components/01_Core/Forms/Input";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import ColumnHeader, {
  IColumnHeaderProps
} from "../../../../components/01_Core/Tables/ColumnHeader";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { arrayOfAll } from "../../../../utils/utils";
import { useStaticData } from "../../hooks/useStaticData";
import { ArtistOrderingOption } from "../03_UI_Kit/Discover/ArtistDirectorySearchSort";
import {
  IconLabel,
  SocialIconLabel
} from "../03_UI_Kit/Discover/RowHeaderDeal";
import FilterAccordion, { FilterAccordionSize } from "./FilterAccordion";
import FilterRangesWithHeader from "./FilterRangesWithHeader";

type FilterRangeOptions = Pick<
  ArtistListPaginationQuery$variables,
  | "instagramFollowers"
  | "streamsThisWeek"
  | "spotifyMonthlyListeners"
  | "tiktokFollowers"
  | "tiktokPosts"
>;

type GenreOption = {
  label: string;
  value: string;
};
export type IArtistsFilterForm = {
  orderBy: ArtistOrderingOption;
  genres: GenreOption[];
  partnershipStatuses: {
    value: keyof GLOBALS["ARTIST_PARTNERSHIP_STATUSES"];
  }[];
  seekingServices: { value: keyof GLOBALS["PARTNER_SERVICES"] }[];
} & {
  [T in keyof FilterRangeOptions]: {
    [key in FilterRangeOptions[T][number]]: boolean;
  };
};

// These need to be declared because you can't iterate over an enum type directly
const FILTER_RANGE_OPTIONS: FilterRangeOptions = {
  instagramFollowers: arrayOfAll<ArtistInstagramFollowersRange>()([
    "_0_9999",
    "_10000_99999",
    "_100000_499999",
    "_500000_inf"
  ]),
  streamsThisWeek: arrayOfAll<ArtistStreamsThisWeekRange>()([
    "_0_99999",
    "_100000_749999",
    "_750000_1999999",
    "_2000000_inf"
  ]),
  spotifyMonthlyListeners: arrayOfAll<ArtistSpotifyMonthlyListenersRange>()([
    "_0_99999",
    "_100000_749999",
    "_750000_1999999",
    "_2000000_inf"
  ]),
  tiktokPosts: arrayOfAll<ArtistTiktokPostsRange>()([
    "_0_2499",
    "_2500_9999",
    "_10000_49999",
    "_50000_inf"
  ]),
  tiktokFollowers: arrayOfAll<ArtistTiktokFollowersRange>()([
    "_0_9999",
    "_10000_99999",
    "_100000_499999",
    "_500000_inf"
  ])
};

export const ARTIST_STAT_LABELS = {
  genres: <IconLabel Icon={Ic24VolumeMax}>Genre</IconLabel>,
  partnershipStatuses: (
    <IconLabel Icon={Ic24Difference}>Partnership Status</IconLabel>
  ),
  seekingServices: <IconLabel Icon={Ic24Suitcase}>Seeking Services</IconLabel>,
  instagramFollowers: (
    <SocialIconLabel platform="INSTAGRAM">Followers</SocialIconLabel>
  ),
  tiktokPosts: <SocialIconLabel platform="TIKTOK">UGCs</SocialIconLabel>,
  tiktokFollowers: (
    <SocialIconLabel platform="TIKTOK">Followers</SocialIconLabel>
  ),
  spotifyMonthlyListeners: (
    <SocialIconLabel platform="SPOTIFY">Monthly Listeners</SocialIconLabel>
  ),
  streamsThisWeek: <IconLabel Icon={Ic24Voice}>Streams Last Week</IconLabel>
};

export default function ArtistDirectoryFilters(props: {
  size: ResponsiveValue<FilterAccordionSize>;
}): ReactElement {
  const genreOptions: GenreOption[] = _.sortBy(
    useStaticData().genres,
    g => g.genre
  ).map(genre => ({
    label: genre.genre,
    value: genre.id
  }));

  const partnershipStatusOptions = _.map(
    GLOBALS.ARTIST_PARTNERSHIP_STATUSES,
    ({ displayName: label }, value) => ({ label, value })
  );
  const seekingServicesOptions = _.map(
    GLOBALS.PARTNER_SERVICES,
    ({ displayName: label }, value) => ({ label, value })
  );

  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

  const activeSize = useActiveResponsiveValue(props.size);
  const gridTemplateAreas = (() => {
    switch (activeSize) {
      case "small":
        return `
            "gn gn gn gn"
            "st st ml ml"
            "tf tf tp tp"
            "if if -- --"
          `;
      case "medium":
      case "large":
        return `
            "gn gn gn gn st st ml ml"
            "if if if if tf tf tp tp"
          `;
      case "extraLarge":
        return `
            "gn gn gn gn st st st st ml ml ml ml"
            "if if if if tf tf tf tf tp tp tp tp"
          `;
      default:
        activeSize satisfies never;
    }
  })();

  const columnHeaderProps: Pick<
    IColumnHeaderProps,
    "align" | "size" | "state" | "styles"
  > = {
    align: "left",
    size: ["small", "small", "large", "large"],
    state: "default",
    styles: { ":hover": {} }
  };

  const filterRangesWithHeaderProps = {
    columnHeaderProps,
    settings: FILTER_RANGE_OPTIONS
  };

  const selectProps = {
    size: useActiveResponsiveValue([
      "small",
      "small",
      "medium",
      "medium"
    ]) as InputSize,
    isClearable: true,
    isMulti: true,
    isSearchable: true,
    menuIsOpen: accordionOpen ? undefined : false
  };

  return (
    <FilterAccordion<Omit<IArtistsFilterForm, "orderBy">>
      labels={ARTIST_STAT_LABELS}
      accordionOpen={accordionOpen}
      setAccordionOpen={setAccordionOpen}
      size={props.size}
    >
      <FixedGrid
        sx={{
          gridTemplateAreas,
          width: "100%",
          borderTop: "1px solid",
          borderColor: "midGray70",
          paddingBottom: "16px"
        }}
      >
        <GridItem
          gridArea={"gn"}
          sx={{
            paddingLeft: ["large", "extraLarge"].includes(activeSize)
              ? "16px"
              : "0px"
          }}
        >
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={0}
            resizingX={FillContainer()}
          >
            <ColumnHeader {...columnHeaderProps} divider={false}>
              {ARTIST_STAT_LABELS.genres}
            </ColumnHeader>
            <Field name="genres">
              {fieldProps => {
                return (
                  <SelectInput
                    {...fieldProps}
                    {...selectProps}
                    options={genreOptions}
                  />
                );
              }}
            </Field>
            <ColumnHeader {...columnHeaderProps} divider={false}>
              {ARTIST_STAT_LABELS.partnershipStatuses}
            </ColumnHeader>
            <Field name="partnershipStatuses">
              {fieldProps => {
                return (
                  <SelectInput
                    {...fieldProps}
                    {...selectProps}
                    options={partnershipStatusOptions}
                  />
                );
              }}
            </Field>
            <ColumnHeader {...columnHeaderProps} divider={false}>
              {ARTIST_STAT_LABELS.seekingServices}
            </ColumnHeader>
            <Field name="seekingServices">
              {fieldProps => {
                return (
                  <SelectInput
                    {...fieldProps}
                    {...selectProps}
                    options={seekingServicesOptions}
                  />
                );
              }}
            </Field>
          </AutoLayout>
        </GridItem>
        <GridItem
          gridArea={"if"}
          sx={{
            paddingLeft: ["large", "extraLarge"].includes(activeSize)
              ? "16px"
              : "0px"
          }}
        >
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={ARTIST_STAT_LABELS.instagramFollowers}
            settingName="instagramFollowers"
          />
        </GridItem>
        <GridItem
          gridArea={"tp"}
          sx={{
            paddingRight: ["large", "extraLarge"].includes(activeSize)
              ? "16px"
              : "0px"
          }}
        >
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={ARTIST_STAT_LABELS.tiktokPosts}
            settingName="tiktokPosts"
          />
        </GridItem>
        <GridItem gridArea={"tf"}>
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={ARTIST_STAT_LABELS.tiktokFollowers}
            settingName="tiktokFollowers"
          />
        </GridItem>
        <GridItem
          gridArea={"ml"}
          sx={{
            paddingRight: ["large", "extraLarge"].includes(activeSize)
              ? "16px"
              : "0px"
          }}
        >
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={ARTIST_STAT_LABELS.spotifyMonthlyListeners}
            settingName="spotifyMonthlyListeners"
          />
        </GridItem>
        <GridItem gridArea={"st"}>
          <FilterRangesWithHeader
            {...filterRangesWithHeaderProps}
            label={ARTIST_STAT_LABELS.streamsThisWeek}
            settingName="streamsThisWeek"
          />
        </GridItem>
      </FixedGrid>
    </FilterAccordion>
  );
}
