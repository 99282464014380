import { ReactElement } from "react";
import { Box } from "theme-ui";

interface IProgressProps {
  percent: number;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1420%3A33
 */
function ProgressBar(props: IProgressProps): ReactElement {
  return (
    <Box
      bg={"midGray100"}
      sx={{ position: "relative", width: "100%", height: "8px" }}
    >
      <Box
        bg={"primary85"}
        sx={{
          position: "absolute",
          left: 0,
          width: `${props.percent}%`,
          height: "8px"
        }}
      />
    </Box>
  );
}

export default ProgressBar;
