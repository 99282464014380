/**
 * @generated SignedSource<<f0c28bf1bb1ed32f725b9b22efa984f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useArtistThumbnailUrl_artist$data = {
  readonly spotifyProfile: {
    readonly thumbnailUrl: string | null;
  } | null;
  readonly thumbnailUrl: string;
  readonly " $fragmentType": "useArtistThumbnailUrl_artist";
};
export type useArtistThumbnailUrl_artist$key = {
  readonly " $data"?: useArtistThumbnailUrl_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"useArtistThumbnailUrl_artist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useArtistThumbnailUrl_artist",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "spotifyProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};
})();

(node as any).hash = "a179f83ad08a8c63b57e81b9d5d2995a";

export default node;
