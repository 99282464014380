import { AnimatePresence } from "framer-motion";
import {
  createContext,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from "react";
import { Subscription, timer } from "rxjs";

import Banner, { IBannerProps } from "./Banner";

interface IBannerProviderProps {
  children: ReactNode;
}

export const BannerContext =
  createContext<(bannerProps: IBannerProps) => void>(null);

function BannerProvider(props: IBannerProviderProps): ReactElement {
  const [bannerProps, setBannerProps] = useState<IBannerProps | null>(null);
  const [removalTimer, setRemovalTimer] = useState<Subscription | null>(null);
  const bannerProviderCallback = (bannerProps: IBannerProps | null) =>
    setBannerProps(bannerProps);

  useEffect(() => {
    if (bannerProps != null && removalTimer == null) {
      const timerSubscription = timer(2500).subscribe({
        next: () => {
          setBannerProps(null);
        },
        complete: () => {
          setRemovalTimer(null);
        }
      });
      setRemovalTimer(timerSubscription);
    }
  }, [bannerProps, removalTimer]);

  return (
    <Fragment>
      <AnimatePresence>
        {bannerProps != null
          ? (() => {
              // For some reason tsc is giving me an error about missing a children
              // prop when banner is created like this <Banner {...bannerProps}/>
              // Very weird... but IIFE to the rescue!
              const { children, ...rest } = bannerProps;
              return <Banner {...rest}>{children}</Banner>;
            })()
          : null}
      </AnimatePresence>
      <BannerContext.Provider value={bannerProviderCallback}>
        {props.children}
      </BannerContext.Provider>
    </Fragment>
  );
}

export default BannerProvider;
