import {
  ReleaseListItem_release$data,
  ReleaseListItem_release$key
} from "__generated__/ReleaseListItem_release.graphql";
import { ReactElement, useRef } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text } from "theme-ui";

import Ic16VisibilityOff from "../../../../../../imgs/icons/ic16-visibility-off.svg";
import AutoLayout, {
  AutoLayoutDependentProps,
  Direction,
  Distribution,
  FillContainer,
  Fixed
} from "../../../../../components/01_Core/AutoLayout";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import ReleaseDetails from "../../../../../components/01_Core/Releases/ReleaseDetails";
import ReleaseImageIcon from "../../../../../components/01_Core/Releases/ReleaseImageIcon";
import {
  rvMap,
  useActiveResponsiveValue,
  useFindActiveRV
} from "../../../../../utils/responsiveUtils";
import ReleaseStatusMenu from "./ReleaseStatusMenu";

type ReleaseListItemSize = "small" | "large";
interface IReleaseListItemProps {
  release: ReleaseListItem_release$key;
  size: ResponsiveValue<ReleaseListItemSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1740%3A0
 */
function ReleaseListItem(props: IReleaseListItemProps): ReactElement {
  const activeSize = useActiveResponsiveValue<ReleaseListItemSize>(props.size);

  const release = useFragment(
    graphql`
      fragment ReleaseListItem_release on ReleaseInterface @relay {
        thumbnailUrl
        isVisible
        ...ReleaseDetails_release
        ...ReleaseStatusMenu_release
      }
    `,
    props.release
  );

  return (
    <AutoLayout
      spacing={rvMap(props.size, getSpacing)}
      resizingX={FillContainer()}
      dependentProps={{
        direction: activeSize === "small" ? "vertical" : "horizontal",
        alignment: "center"
      }}
      sx={{
        padding: activeSize === "small" ? "12px" : "16px",
        border: theme => `1px solid ${theme.colors.midGray70}`,
        borderRadius: "2px",
        backgroundColor: release.isVisible ? "inherit" : "lightGray100"
      }}
    >
      <Info release={release} size={props.size} />
      {activeSize === "small" && <Divider />}
      <Status release={release} size={props.size} />
    </AutoLayout>
  );
}

function Info(
  props: Omit<IReleaseListItemProps, "release"> & {
    release: ReleaseListItem_release$data;
  }
) {
  const activeSize = useActiveResponsiveValue<ReleaseListItemSize>(props.size);

  return (
    <AutoLayout
      spacing={16}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "horizontal",
        alignment: "center"
      }}
    >
      <ReleaseImageIcon
        src={props.release.thumbnailUrl}
        size={activeSize === "small" ? "medium" : "large"}
        sxOverrides={{
          border: theme => `1px solid ${theme.colors.midGray70}`,
          opacity: props.release.isVisible ? 1 : 0.5
        }}
      />
      <ReleaseDetails
        release={props.release}
        size={activeSize === "small" ? "small" : "medium"}
      />
    </AutoLayout>
  );
}

function Status(
  props: Omit<IReleaseListItemProps, "release"> & {
    release: ReleaseListItem_release$data;
  }
) {
  const activeSize = useActiveResponsiveValue<ReleaseListItemSize>(props.size);
  const dropdownRef = useRef(null);
  const statusTextVariant = useFindActiveRV(props.size, getStatusTextVariant);

  const resizingX = activeSize === "small" ? FillContainer() : Fixed("200px");

  const releaseStatusMenu = (
    <ReleaseStatusMenu
      release={props.release}
      ref={dropdownRef}
      openDirection={activeSize === "small" ? "right" : "left"}
    />
  );

  return (
    <AutoLayout
      spacing={rvMap(props.size, getStatusWrapperSpacing)}
      resizingX={resizingX}
      dependentProps={{
        direction: "horizontal",
        alignment: "center"
      }}
      sx={{ flexShrink: 0 }}
    >
      {activeSize === "small" && releaseStatusMenu}
      <AutoLayout
        spacing={rvMap(props.size, getStatusSpacing)}
        dependentProps={rvMap(props.size, getDependentProps)}
        sx={{ flexGrow: 1 }}
      >
        <Flex
          sx={{
            alignItems: "center",
            color: props.release.isVisible ? "black100" : "deepGray70"
          }}
        >
          {!props.release.isVisible && (
            <Box sx={{ marginRight: "6px" }}>
              <Ic16VisibilityOff />
            </Box>
          )}
          <Text
            variant={statusTextVariant}
            color={getVisibilityTextColor(props.release.isVisible)}
          >
            {getStatus(props.release.isVisible)}
          </Text>
        </Flex>
      </AutoLayout>
      {activeSize === "large" && (
        <Box sx={{ marginLeft: "16px" }}>{releaseStatusMenu}</Box>
      )}
    </AutoLayout>
  );
}

function getSpacing(size: ReleaseListItemSize): number {
  switch (size) {
    case "large":
      return 0;
    case "small":
      return 12;
  }
}

function getStatusWrapperSpacing(size: ReleaseListItemSize): number {
  switch (size) {
    case "large":
      return 0;
    case "small":
      return 24;
  }
}

function getStatusSpacing(size: ReleaseListItemSize): number {
  switch (size) {
    case "large":
      return 2;
    case "small":
      return 8;
  }
}

function getDependentProps(
  size: ReleaseListItemSize
): AutoLayoutDependentProps<Direction, Distribution> {
  switch (size) {
    case "small":
      return {
        direction: "horizontal",
        alignment: "left"
      };
    case "large":
      return {
        direction: "vertical",
        alignment: "right"
      };
  }
}

function getStatusTextVariant(size: ReleaseListItemSize): string {
  switch (size) {
    case "small":
      return "bodySmall";
    case "large":
      return "bodyMedium";
  }
}

function getVisibilityTextColor(isVisible: boolean): string {
  switch (isVisible) {
    case false:
      return "deepGray70";
    case true:
      return "black100";
  }
}

function getStatus(isVisible: boolean): string {
  switch (isVisible) {
    case true:
      return "Visible on profile";
    case false:
      return "Hidden on profile";
  }
}

export default ReleaseListItem;
