import { Properties } from "csstype";
import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Image, ImageProps, ThemeUIStyleObject } from "theme-ui";

import EmptyReleaseImage from "../../../../imgs/empty_release.png";
import { rvMap } from "../../../utils/responsiveUtils";

export type ReleaseImageIconSize =
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";
interface IReleaseImageIconProps {
  size: ResponsiveValue<ReleaseImageIconSize>;
  src?: ImageProps["src"];
  sxOverrides?: ThemeUIStyleObject;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2426%3A0
 */
function ReleaseImageIcon(props: IReleaseImageIconProps): ReactElement {
  return (
    <Image
      src={props.src || EmptyReleaseImage}
      sx={{
        size: rvMap(props.size, getReleaseImageIconWidth),
        flexShrink: 0,
        ...props.sxOverrides
      }}
    />
  );
}

export function getReleaseImageIconWidth(
  size: ReleaseImageIconSize
): Properties["width"] {
  switch (size) {
    case "extraSmall":
      return "24px";
    case "small":
      return "32px";
    case "medium":
      return "40px";
    case "large":
      return "48px";
    case "extraLarge":
      return "64px";
  }
}

export default ReleaseImageIcon;
