import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex, FlexProps, Text } from "theme-ui";

import { useActiveResponsiveValue } from "../../../utils/responsiveUtils";

type RowHeaderSize = "small" | "large";

interface IRowHeaderProps extends FlexProps {
  size: ResponsiveValue<RowHeaderSize>;
  align: "left" | "right";
  emphasis: "low" | "high";
  children: string;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2871%3A11324
 */
function RowHeader(props: IRowHeaderProps): ReactElement {
  const { size, emphasis, align, sx, ...rest } = props;
  const activeSize = useActiveResponsiveValue(size);
  const paddingX = activeSize === "small" ? "8px" : "16px";
  const backgroundColor = emphasis === "low" ? "white100" : "lightGray100";
  const justifyContent = align === "left" ? "flex-start" : "flex-end";
  const textVariant = activeSize === "small" ? "bodySmall" : "bodyMedium";

  return (
    <Flex
      sx={{
        paddingY: "8px",
        paddingX,
        backgroundColor,
        justifyContent,
        ...sx
      }}
      {...rest}
    >
      <Text variant={textVariant}>{props.children}</Text>
    </Flex>
  );
}

export default RowHeader;
