import { ArtistManagerInfo_artist$key } from "__generated__/ArtistManagerInfo_artist.graphql";
import {
  ArtistManagerInfoEditMutation,
  EditArtistManagerInfoMutationInput
} from "__generated__/ArtistManagerInfoEditMutation.graphql";
import { ReactElement, useContext } from "react";
import { Form } from "react-final-form";
import { graphql, useFragment } from "react-relay";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../components/01_Core/Forms/Inputs/TextInput";
import {
  emailValidator,
  optional,
  urlValidator
} from "../../../../components/01_Core/Forms/utils/validators";
import useMutationPromise from "../../hooks/useMutationPromise";
import { ManagerEmailWarning } from "../03_UI_Kit/Offers/OfferFormManagerInfo";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import Footer from "./Footer";

export default function ArtistManagerInfo(props: {
  artist: ArtistManagerInfo_artist$key;
}): ReactElement {
  const notifyBanner = useContext(BannerContext);

  const artist = useFragment(
    graphql`
      fragment ArtistManagerInfo_artist on ArtistNode {
        id
        managerName
        managerLink
        managerCompany
        managerEmail
      }
    `,
    props.artist
  );

  const [commit] = useMutationPromise<ArtistManagerInfoEditMutation>(graphql`
    mutation ArtistManagerInfoEditMutation(
      $input: EditArtistManagerInfoMutationInput!
    ) {
      editArtistManagerInfo(data: $input) {
        artist {
          managerName
          managerLink
          managerCompany
          managerEmail
        }
      }
    }
  `);
  const doMutation = (values: EditArtistManagerInfoMutationInput) =>
    commit({ variables: { input: values } })
      .then(() => {
        notifyBanner({
          type: "neutral",
          children: "Your manager info has been updated."
        });
      })
      .catch(() => {
        notifyBanner({
          type: "alert",
          children: "Something went wrong."
        });
      });

  return (
    <Form<EditArtistManagerInfoMutationInput>
      initialValues={{
        artistNodeId: artist.id,
        managerName: artist.managerName,
        managerLink: artist.managerLink,
        managerCompany: artist.managerCompany,
        managerEmail: artist.managerEmail
      }}
      onSubmit={doMutation}
    >
      {formProps => {
        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100%" }}>
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Manager"}
                description={"Set your manager's contact information."}
              />
              <AutoLayout
                spacing={[32, 48, 48, 48]}
                resizingX={FillContainer()}
                dependentProps={{
                  direction: "vertical"
                }}
              >
                <FieldBuilder
                  inputField={TextInput}
                  fieldName={"managerName"}
                  inputFieldProps={{
                    label: "Manager name",
                    size: ["medium", "medium", "large", "large"]
                  }}
                />
                <FieldBuilder
                  inputField={TextInput}
                  fieldName={"managerCompany"}
                  inputFieldProps={{
                    label: "Manager company",
                    size: ["medium", "medium", "large", "large"]
                  }}
                />
                <AutoLayout
                  spacing={16}
                  dependentProps={{ direction: "vertical" }}
                  sx={{ width: "100%" }}
                >
                  <FieldBuilder
                    inputField={TextInput}
                    fieldName={"managerEmail"}
                    validator={optional(emailValidator)}
                    showErrorsBeforeTouched={true}
                    inputFieldProps={{
                      label: "Manager email",
                      size: ["medium", "medium", "large", "large"]
                    }}
                  />
                  <ManagerEmailWarning />
                </AutoLayout>
                <FieldBuilder
                  inputField={TextInput}
                  fieldName={"managerLink"}
                  validator={optional(urlValidator)}
                  showErrorsBeforeTouched={true}
                  inputFieldProps={{
                    label: "Manager website",
                    size: ["medium", "medium", "large", "large"]
                  }}
                />
              </AutoLayout>
              <Footer
                buttonDisabled={
                  formProps.pristine || formProps.hasValidationErrors
                }
                loading={formProps.submitting}
                onClick={formProps.handleSubmit}
                size={["small", "large", "large", "large"]}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}
