import { FundCaseStudyOffer_offer$key } from "__generated__/FundCaseStudyOffer_offer.graphql";
import { FundCaseStudyOfferTrackDataQuery as FundCaseStudyOfferTrackDataQueryType } from "__generated__/FundCaseStudyOfferTrackDataQuery.graphql";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import _ from "lodash";
import { ReactElement, Suspense } from "react";
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery
} from "react-relay";
import { ReferenceLine } from "recharts";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text, useThemeUI } from "theme-ui";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { useOfferName } from "../../hooks/useOfferName";
import Timeseries, { ChartFallback, responsiveChartSize } from "./Timeseries";
dayjs.extend(weekday);

export interface IFundCaseStudyOfferProps {
  size: ResponsiveValue<"small" | "large">;
  caseStudyOffer: FundCaseStudyOffer_offer$key;
  queryRef: PreloadedQuery<FundCaseStudyOfferTrackDataQueryType>;
}

export default function FundCaseStudyOffer(
  props: IFundCaseStudyOfferProps
): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);

  const caseStudyOffer = useFragment(
    graphql`
      fragment FundCaseStudyOffer_offer on OfferNode {
        ...useOfferName
      }
    `,
    props.caseStudyOffer
  );

  const chartSize = useActiveResponsiveValue(responsiveChartSize);

  return (
    <GridItem gridColumn={"1 / -1"}>
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical", alignment: "left" }}
        sx={{ alignSelf: "flex-start" }}
      >
        <Flex
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: activeSize === "small" ? "48px" : "64px"
          }}
        >
          <AutoLayout spacing={8} dependentProps={{ direction: "vertical" }}>
            <Text
              variant={activeSize === "small" ? "h400" : "h600"}
              sx={{ textAlign: "center", width: "100%" }}
            >
              Featured Partnership
            </Text>
            <Text
              variant={activeSize === "small" ? "h300" : "h400"}
              sx={{ textAlign: "center", width: "100%" }}
            >
              {useOfferName(caseStudyOffer, true)}
            </Text>
          </AutoLayout>
        </Flex>
        <FixedGrid sx={{ justifyContent: "center" }}>
          <GridItem gridColumn={"1 / -1"}>
            <Box
              sx={{ width: "100%", paddingY: chartSize === "large" ? 40 : 32 }}
            >
              <Suspense fallback={<ChartFallback />}>
                <DataChart {...props} />
              </Suspense>
            </Box>
          </GridItem>
        </FixedGrid>
        <FixedGrid>
          <GridItem
            gridColumn={[
              "1 / span 4",
              "1 / span 8",
              "1 / span 8",
              "3 / span 8"
            ]}
            sx={{ flexDirection: "column" }}
          >
            <Box
              sx={{
                paddingX: activeSize === "large" ? 80 : 48
              }}
            ></Box>
            <Divider />
          </GridItem>
        </FixedGrid>
      </AutoLayout>
    </GridItem>
  );
}

export const FundCaseStudyOfferTrackDataQuery = graphql`
  query FundCaseStudyOfferTrackDataQuery($companySlug: String!) {
    caseStudyOfferByCompanySlug(companySlug: $companySlug) {
      dateOfferAccepted
      tracksUniqByLuminateSongId {
        edges {
          node {
            name
            data(country: "G1", metric: "Streaming On-Demand Total") {
              edges {
                node {
                  value
                  weekId {
                    endDay
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function DataChart(props: IFundCaseStudyOfferProps) {
  const { theme } = useThemeUI();
  const activeSize = useActiveResponsiveValue(props.size);

  const caseStudyOffer =
    usePreloadedQuery<FundCaseStudyOfferTrackDataQueryType>(
      FundCaseStudyOfferTrackDataQuery,
      props.queryRef
    ).caseStudyOfferByCompanySlug;

  const dateOfferAccepted = new Date(caseStudyOffer.dateOfferAccepted);
  const tracks = caseStudyOffer.tracksUniqByLuminateSongId.edges.map(
    edge => edge.node
  );

  // Sum weekly totals across all tracks
  const weekTotals = {} as { [key: string]: number };

  for (const track of tracks) {
    for (const data of track.data.edges) {
      const weekEndDay = data.node.weekId.endDay as string;
      // Ignore data more than 8 weeks before the Friday (start of WeekId) before the accepted date
      if (
        dayjs(weekEndDay).isBefore(
          dayjs(dateOfferAccepted).weekday(-2).add(-8, "week")
        )
      )
        continue;

      weekTotals[weekEndDay] ||= 0;
      weekTotals[weekEndDay] += data.node.value;
    }
  }

  const fullTimeseries = _.sortBy(
    Object.entries(weekTotals),
    ([weekEndDay]) => weekEndDay
  ).map(([weekEndDay, y]) => ({
    x: new Date(weekEndDay),
    y
  }));

  const getStatName = (avg: boolean) => {
    return (
      <Text>
        {avg ? "Average weekly" : "Weekly"} streams for{" "}
        <Text sx={{ fontWeight: "bold" }}>{tracks[0].name}</Text>
        {tracks.length > 1 && (
          <>
            {" + "}
            <Text sx={{ fontWeight: "bold" }}>
              {tracks.length - 1} other track
              {tracks.length > 2 ? "s" : ""}
            </Text>
          </>
        )}
      </Text>
    );
  };

  const CustomLabel = (customLabelProps: any) => {
    return (
      <foreignObject
        x={customLabelProps.viewBox.x}
        y="0"
        style={{ overflow: "visible" }}
      >
        <SubtleBadge
          size={activeSize === "small" ? "small" : "medium"}
          color={"blue"}
          styles={{
            position: "absolute",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -0%)"
          }}
        >
          Offer signed
        </SubtleBadge>
      </foreignObject>
    );
  };

  return (
    <Timeseries
      timeseries={fullTimeseries}
      statName={getStatName(false)}
      windowSummaryStatName={getStatName(true)}
      emptyText={`No partnership data`}
      windowGranularity="week"
      showWindowOptionSelect={false}
    >
      <ReferenceLine
        x={dateOfferAccepted.getTime()}
        label={<CustomLabel />}
        stroke={theme.colors.secondary10 as string}
        strokeDasharray="5 3"
        strokeWidth={2}
      />
    </Timeseries>
  );
}
