import { ReactElement } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { FieldPrefix } from "../../../../components/01_Core/Forms/FieldBuilder";
import CheckboxInput from "../../../../components/01_Core/Forms/Inputs/CheckboxInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { formatNumeral } from "../../../../utils/utils";

export function FilterRanges<
  Settings extends { [key: string]: readonly string[] },
  SettingName extends keyof Settings & string
>(props: { settings: Settings; settingName: SettingName }): ReactElement {
  const rangeOptions = props.settings[props.settingName];
  return (
    <FieldPrefix prefix={props.settingName}>
      <AutoLayout
        dependentProps={{ direction: "vertical" }}
        spacing={4}
        resizingX={FillContainer()}
      >
        {rangeOptions.map(range => {
          const [lowerBound, upperBound] = range
            .split("_")
            .filter(Boolean)
            .map(Number);
          const label = isNaN(upperBound)
            ? `${formatNumeral(lowerBound)}+`
            : lowerBound === upperBound
            ? formatNumeral(lowerBound)
            : [lowerBound, upperBound + 1].map(n => formatNumeral(n)).join("—");
          return (
            <Field name={range} key={range}>
              {input => (
                <CheckboxInput
                  {...input}
                  label={label}
                  size={["small", "small", "medium", "medium"]}
                  state="normal"
                />
              )}
            </Field>
          );
        })}
      </AutoLayout>
    </FieldPrefix>
  );
}
