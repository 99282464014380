import { ArtistDirectoryQuery as ArtistDirectoryQueryType } from "__generated__/ArtistDirectoryQuery.graphql";
import { ReactElement, Suspense } from "react";
import { Form } from "react-final-form";
import { graphql, PreloadedQuery } from "react-relay";
import { Box } from "theme-ui";

import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import useDebouncedState from "../../hooks/useDebouncedState";
import ArtistDirectorySearchSort, {
  artistOrderingOptions
} from "../03_UI_Kit/Discover/ArtistDirectorySearchSort";
import DirectoryPageTitle from "../03_UI_Kit/Discover/DirectoryPageTitle";
import ArtistDirectoryCards, {
  PlaceholderArtistDirectoryCards
} from "./ArtistDirectoryCards";
import ArtistDirectoryFilters, {
  IArtistsFilterForm
} from "./ArtistDirectoryFilters";

export type ArtistSortType =
  | "ranked"
  | "joined"
  | "streams_this_week"
  | "weekly_change";
export type ArtistSortDirection = "asc" | "desc";
export type ArtistSort = {
  type: ArtistSortType;
  dir: ArtistSortDirection;
};

interface IArtistDirectoryProps {
  queryRef: PreloadedQuery<ArtistDirectoryQueryType>;
}

/**
 * figma: https://www.figma.com/file/xyf6tm0ZBXnA2XM1yAl78l/03-Discover?node-id=972%3A55345
 */
function ArtistDirectory(props: IArtistDirectoryProps): ReactElement {
  const [searchedArtist, setSearchedArtist] = useDebouncedState<string>("");

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}
    >
      <Form<IArtistsFilterForm>
        onSubmit={() => {}}
        initialValues={{
          orderBy: artistOrderingOptions.find(
            ({ value }) => value === "growth_score_desc"
          )
        }}
      >
        {_ => (
          <FixedGrid>
            <GridItem gridColumn={"1 / -1"}>
              <DirectoryPageTitle
                size={["small", "large", "large", "large"]}
                title={"Artists"}
                description={
                  "Discover the top independent artists looking for partners who can provide services and funding to grow their careers. Check out streaming performance and social presence, and message artists to explore partnership opportunities."
                }
              />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <ArtistDirectorySearchSort filterInput={setSearchedArtist} />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <ArtistDirectoryFilters
                size={["small", "medium", "large", "extraLarge"]}
              />
            </GridItem>
            <GridItem gridColumn={"1 / -1"}>
              <Box sx={{ width: "100%" }}>
                <Suspense fallback={<PlaceholderArtistDirectoryCards />}>
                  <ArtistDirectoryCards
                    displayName_Icontains={searchedArtist}
                    queryRef={props.queryRef}
                  />
                </Suspense>
              </Box>
            </GridItem>
          </FixedGrid>
        )}
      </Form>
    </GridItem>
  );
}

export const ArtistDirectoryQuery = graphql`
  query ArtistDirectoryQuery {
    artistCount
    ...ArtistDirectoryCards_allArtists
  }
`;

export default ArtistDirectory;
