/**
 * @generated SignedSource<<e1a6fe10d98d6a6645c90f531329984b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistThumbnail_featuredDeal$data = {
  readonly artist: {
    readonly thumbnailUrl: string;
    readonly " $fragmentSpreads": FragmentRefs<"useArtistThumbnailUrl_artist">;
  };
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly thumbnailUrl: string | null;
      } | null;
    } | null>;
  };
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly thumbnailUrl: string | null;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "ArtistThumbnail_featuredDeal";
};
export type ArtistThumbnail_featuredDeal$key = {
  readonly " $data"?: ArtistThumbnail_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistThumbnail_featuredDeal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistThumbnail_featuredDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useArtistThumbnailUrl_artist"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};
})();

(node as any).hash = "7fbda329bc364d1fa061627cc75d3a3e";

export default node;
