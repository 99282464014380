import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box } from "theme-ui";

import Ic24Company from "../../../../../../imgs/icons/ic24-company.svg";
import Ic24Trophy from "../../../../../../imgs/icons/ic24-trophy.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import ColumnHeader from "../../../../../components/01_Core/Tables/ColumnHeader";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { COMPANY_ATTRIBUTE_LABELS } from "../../03_Discover/FundDirectoryFilters";
import BookmarkIcon from "./BookmarkIcon";
import { getGridTemplateAreas } from "./RowFund";
import { IconLabel } from "./RowHeaderDeal";

type RowHeaderFundSize = "small" | "medium" | "large" | "extraLarge";

interface IRowHeaderFundProps {
  size: ResponsiveValue<RowHeaderFundSize>;
  showWatchedOnly: boolean;
  toggleShowWatchedOnly: () => void;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=3210%3A19457
 */
function RowHeaderFund(props: IRowHeaderFundProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const gridTemplateAreas = useActiveResponsiveValue(
    getGridTemplateAreas(activeSize)
  )
    // Only show headers for the first row of grid areas
    .split("\n")
    .find(line => line.trim());

  const gridItemProps = { gridTemplateAreas };
  return (
    <Box
      sx={{
        backgroundColor: "white100",
        border: "1px solid",
        borderColor: "midGray70",
        width: "100%"
      }}
    >
      <FixedGrid sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"rk"} {...gridItemProps}>
          <ColumnHeader
            state={"default"}
            align={"left"}
            size={"small"}
            styles={{ paddingLeft: activeSize === "small" ? "0" : "16px" }}
          >
            <IconLabel Icon={Ic24Trophy}>Rank</IconLabel>
          </ColumnHeader>
        </GridItem>
        <GridItem gridArea={"fn"} {...gridItemProps}>
          <ColumnHeader align={"left"} size={"small"} state={"default"}>
            <IconLabel Icon={Ic24Company}>Company</IconLabel>
          </ColumnHeader>
        </GridItem>
        <GridItem gridArea={"ab"} {...gridItemProps}>
          <ColumnHeader align={"left"} size={"small"} state={"default"}>
            {COMPANY_ATTRIBUTE_LABELS.partnerships}
          </ColumnHeader>
        </GridItem>
        <GridItem gridArea={"sv"} {...gridItemProps}>
          <ColumnHeader align={"left"} size={"small"} state={"default"}>
            {COMPANY_ATTRIBUTE_LABELS.services}
          </ColumnHeader>
        </GridItem>
        <GridItem gridArea={"ts"} {...gridItemProps}>
          <ColumnHeader state="default" align={"right"} size={"small"}>
            {COMPANY_ATTRIBUTE_LABELS.totalStreams}
          </ColumnHeader>
        </GridItem>
        <GridItem gridArea={"wa"} {...gridItemProps}>
          <ColumnHeader
            align={activeSize === "small" ? "right" : "center"}
            size={"small"}
            state={"default"}
          >
            <AutoLayout
              spacing={8}
              dependentProps={{ direction: "horizontal" }}
              sx={{
                justifyContent: "flex-end",
                paddingRight: activeSize === "small" ? 0 : "4px",
                alignItems: "center"
              }}
            >
              <BookmarkIcon
                watching={props.showWatchedOnly}
                onClick={props.toggleShowWatchedOnly}
              />
              {/* To match spacing in the rows */}
              <Box sx={{ width: "20px" }} />
            </AutoLayout>
          </ColumnHeader>
        </GridItem>
      </FixedGrid>
    </Box>
  );
}

export default RowHeaderFund;
