/**
 * @generated SignedSource<<98124f87833456fb53fdeebb803dfb0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReleaseTypeEnum = "album" | "compilation" | "single";
import { FragmentRefs } from "relay-runtime";
export type Title_releases$data = ReadonlyArray<{
  readonly name: string;
  readonly releaseType: ReleaseTypeEnum | null;
  readonly " $fragmentSpreads": FragmentRefs<"useReleaseMetadata_release">;
  readonly " $fragmentType": "Title_releases";
}>;
export type Title_releases$key = ReadonlyArray<{
  readonly " $data"?: Title_releases$data;
  readonly " $fragmentSpreads": FragmentRefs<"Title_releases">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Title_releases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReleaseMetadata_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "135fa56d7560f07cd54b5aa49834ff7f";

export default node;
