import { ReactElement } from "react";
import { Button as ThemeUIButton, ThemeUIStyleObject } from "theme-ui";

import { rvMap } from "../../../utils/responsiveUtils";
import { getHeight, IButtonProps } from "./Button";

export interface IIconButtonProps extends Omit<IButtonProps, "iconProps"> {
  children: ReactElement;
  sxOverrides?: ThemeUIStyleObject;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1278%3A23045
 */
function IconButton(props: IIconButtonProps): ReactElement {
  return (
    <ThemeUIButton
      onClick={props.onClick}
      variant={props.variant}
      disabled={props.disabled}
      p={0}
      sx={{
        display: "flex",
        cursor: props.disabled ? "auto" : "pointer",
        borderRadius: "6px",
        height: rvMap(props.size, getHeight),
        width: rvMap(props.size, getHeight),
        justifyContent: "center",
        alignItems: "center",
        borderColor: "midGray70",
        ":hover": {
          borderColor: "deepGray100"
        },
        transition: "border-color 0.2s",
        ...props.sxOverrides
      }}
    >
      {props.children}
    </ThemeUIButton>
  );
}

export default IconButton;
