import { RecoupmentProfile_featuredDeal$key } from "__generated__/RecoupmentProfile_featuredDeal.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import ColumnHeader from "../../../../../../components/01_Core/Tables/ColumnHeader";
import ComplexRowText from "../../../../../../components/01_Core/Tables/ComplexRowText";
import { useActiveResponsiveValue } from "../../../../../../utils/responsiveUtils";
import { formatNumber } from "../../../../../../utils/stringFormatting";
import { DealAnalysisSize } from "./DealAnalysis";

interface IRecoupmentProfileProps {
  size: ResponsiveValue<DealAnalysisSize>;
  featuredDeal: RecoupmentProfile_featuredDeal$key;
}

/**
 * figma: N/A
 */
function RecoupmentProfile(props: IRecoupmentProfileProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const titleVariant = activeSize === "large" ? "h400" : "h300";
  const featuredDeal = useFragment(
    graphql`
      fragment RecoupmentProfile_featuredDeal on FeaturedDealNode {
        totalAmountRaising
      }
    `,
    props.featuredDeal
  );
  const partnerPreRecoupmentSplitPercentages = [0.8, 0.9, 1];
  const investmentAmountPercentages = [0.75, 1, 1.25];

  return (
    <AutoLayout
      spacing={24}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      <Text variant={titleVariant}>
        Recoupment profile for varying investment types
      </Text>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          width: "100%"
        }}
      >
        <ColumnHeader
          size={"small"}
          state={"default"}
          align={"right"}
          styles={{
            gridColumn: "1 / -1"
          }}
        >
          Investment Amount
        </ColumnHeader>
        {/* Investment Amounts Row */}
        {[
          null,
          featuredDeal.totalAmountRaising * 0.75,
          featuredDeal.totalAmountRaising,
          featuredDeal.totalAmountRaising * 1.25
        ].map((amount, i) => (
          <ComplexRowText
            key={`recoupmentProfile-${i}`}
            align={"right"}
            size={props.size}
            type={"important"}
            emphasis={"high"}
          >
            {amount ? `$${formatNumber(amount)}` : ""}
          </ComplexRowText>
        ))}
        {/* Row labels column */}
        {partnerPreRecoupmentSplitPercentages.map((splitPercentage, i) => (
          <ComplexRowText
            key={`recoupmentProfileRowLabel-${splitPercentage}`}
            size={"small"}
            align={"right"}
            type={"default"}
            emphasis={"high"}
            sx={{
              gridRow: i + 3
            }}
          >
            {`${splitPercentage * 100}% pre-recoup`}
          </ComplexRowText>
        ))}

        {/* Recoupment amount cells */}
        {partnerPreRecoupmentSplitPercentages.map(splitPercentage => {
          return (
            <Box
              key={`recoupmentProfileData-${splitPercentage}`}
              sx={{ display: "contents" }}
            >
              {investmentAmountPercentages.map(investmentAmountPercentage => {
                return (
                  <ComplexRowText
                    key={`recoupmentProfileData-${splitPercentage}-${investmentAmountPercentage}`}
                    size={props.size}
                    align={"right"}
                    type={"default"}
                    emphasis={"low"}
                  >
                    {formatNumber(
                      (featuredDeal.totalAmountRaising *
                        investmentAmountPercentage) /
                        splitPercentage /
                        GLOBALS.DOLLARS_PER_STREAM
                    )}
                  </ComplexRowText>
                );
              })}
            </Box>
          );
        })}
      </Box>
      <Text variant={"h200"}>
        Streams needed to recoup for advance amounts and pre-recoupment splits
      </Text>
    </AutoLayout>
  );
}

export default RecoupmentProfile;
