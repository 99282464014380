/**
 * @generated SignedSource<<3415999d90a136e5af06fe41fbf3892f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Hero_FeaturedDeal$data = {
  readonly artist: {
    readonly " $fragmentSpreads": FragmentRefs<"Title_artist">;
  };
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"Title_releases">;
      } | null;
    } | null>;
  };
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"Title_releases">;
      } | null;
    } | null>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ArtistThumbnail_featuredDeal" | "Deal_featuredDeal">;
  readonly " $fragmentType": "Hero_FeaturedDeal";
};
export type Hero_FeaturedDeal$key = {
  readonly " $data"?: Hero_FeaturedDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"Hero_FeaturedDeal">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "Title_releases"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Hero_FeaturedDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Title_artist"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Deal_featuredDeal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArtistThumbnail_featuredDeal"
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};
})();

(node as any).hash = "db61cf70ed95cee150fcc1b419e2c687";

export default node;
