/**
 * @generated SignedSource<<61e2922aba7b6ceb25a9a5cf31a44b78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useArtistCanMessageCompany_chat$data = {
  readonly artistHasMessaged: boolean | null;
  readonly partnerHasMessaged: boolean | null;
  readonly " $fragmentType": "useArtistCanMessageCompany_chat";
};
export type useArtistCanMessageCompany_chat$key = {
  readonly " $data"?: useArtistCanMessageCompany_chat$data;
  readonly " $fragmentSpreads": FragmentRefs<"useArtistCanMessageCompany_chat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useArtistCanMessageCompany_chat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnerHasMessaged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artistHasMessaged",
      "storageKey": null
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};

(node as any).hash = "7e7cb60b57ea8f5931ac129f8e8ec330";

export default node;
