import { ArtistProfileQuery as ArtistProfileQueryType } from "__generated__/ArtistProfileQuery.graphql";
import { Routing_ArtistChartsQuery as ArtistChartsQueryType } from "__generated__/Routing_ArtistChartsQuery.graphql";
import { Fragment, ReactElement, Suspense, useState } from "react";
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay";
import { Redirect } from "react-router-dom";
import { Box, Flex } from "theme-ui";

import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { useBreakpoint } from "../../../../utils/useBreakpoints";
import { noop } from "../../../../utils/utils";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import PageHeadingSecondLevel from "../03_UI_Kit/Content/PageHeadingSecondLevel";
import ArtistProfileTheSound from "../03_UI_Kit/Releases/ArtistProfileTheSound";
import ArtistCharts from "./ArtistCharts";
import ArtistInfo from "./ArtistInfo";
import ReleasesGrid from "./ReleasesGrid";
import { ChartFallback, responsiveChartSize } from "./Timeseries";

export type ReleasesSort = "desc" | "asc";
type ReleaseSortOption = {
  label: string;
  value: ReleasesSort;
};

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=539%3A17321
 */
function ArtistProfile(props: {
  queryRef: PreloadedQuery<ArtistProfileQueryType>;
  chartQueryRef: PreloadedQuery<ArtistChartsQueryType>;
}): ReactElement {
  const breakpointMap = useBreakpoint();
  const artist = usePreloadedQuery<ArtistProfileQueryType>(
    ArtistProfileQuery,
    props.queryRef
  ).artistBySlug;

  if (artist == null) {
    return <Redirect to={InternalRoutes.featuredDealDirectory} />;
  }

  const releaseSortOptions: ReleaseSortOption[] = [
    {
      label: "By newest",
      value: "desc"
    },
    { label: "By oldest", value: "asc" }
  ];
  const [releasesSort, setReleasesSort] = useState<ReleasesSort>("desc");

  const chartSize = useActiveResponsiveValue(responsiveChartSize);

  return (
    <Fragment>
      <GridItem
        gridColumn={"1 / -1"}
        bg={"white100"}
        sx={{ justifyContent: "center" }}
      >
        <FixedGrid>
          <GridItem
            gridColumn={[
              "1 / span 4",
              "1 / span 8",
              "1 / span 8",
              "3 / span 8"
            ]}
          >
            <ArtistInfo
              artist={artist}
              size={["small", "large", "large", "large"]}
            />
          </GridItem>
        </FixedGrid>
      </GridItem>
      <GridItem
        gridColumn={"1 / -1"}
        bg={"white100"}
        sx={{ justifyContent: "center" }}
      >
        <FixedGrid>
          <GridItem gridColumn={"1 / -1"}>
            <Box
              sx={{ width: "100%", paddingY: chartSize === "large" ? 32 : 24 }}
            >
              <Suspense fallback={<ChartFallback />}>
                <ArtistCharts queryRef={props.chartQueryRef} />
              </Suspense>
            </Box>
          </GridItem>
        </FixedGrid>
      </GridItem>
      <GridItem gridColumn={"1 / -1"} sx={{ justifyContent: "center" }}>
        <FixedGrid sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <GridItem gridColumn={["1 / -1", "1 / -1", "1 / -1", "1 / span 9"]}>
            <PageHeadingSecondLevel
              size={"small"}
              sxOverrides={{
                justifyContent: ["center", "center", "center", "flex-start"]
              }}
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              {artist.displayName}'s catalog
            </PageHeadingSecondLevel>
          </GridItem>
          {breakpointMap.desktop && (
            <GridItem gridColumn={["10 / span 3"]}>
              <Flex
                sx={{
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <SelectInput
                  size={"medium"}
                  input={{
                    name: "releasesSort",
                    value: releaseSortOptions.find(
                      ({ value }) => value === releasesSort
                    ),
                    onChange: ({ value }: ReleaseSortOption) =>
                      setReleasesSort(value),
                    onBlur: noop,
                    onFocus: noop
                  }}
                  meta={null}
                  options={releaseSortOptions}
                />
              </Flex>
            </GridItem>
          )}
          <GridItem gridColumn={"1 / -1"}>
            <ReleasesGrid artist={artist} orderBy={releasesSort} />
          </GridItem>
        </FixedGrid>
      </GridItem>
      {(breakpointMap.desktop || breakpointMap.tabletLarge) &&
        artist.spotifyApiId && (
          <ArtistProfileTheSound
            type={"artist"}
            spotifyId={artist.spotifyApiId}
          />
        )}
    </Fragment>
  );
}

export const ArtistProfileQuery = graphql`
  query ArtistProfileQuery($slug: String!) {
    artistBySlug(slug: $slug) {
      displayName
      spotifyApiId
      ...ArtistInfo_artist
      ...ReleasesGrid_artist
    }
  }
`;

export default ArtistProfile;
