/**
 * @generated SignedSource<<60e1b408ae7c69a7243114ef466f483f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundInfo_FundMetrics_fund$data = {
  readonly artistsBacked: ReadonlyArray<{
    readonly __typename: "ArtistNode";
  } | null> | null;
  readonly numReleasesInClosedDeals: number | null;
  readonly ranked: number | null;
  readonly totalStreams: number;
  readonly " $fragmentType": "FundInfo_FundMetrics_fund";
};
export type FundInfo_FundMetrics_fund$key = {
  readonly " $data"?: FundInfo_FundMetrics_fund$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundInfo_FundMetrics_fund">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundInfo_FundMetrics_fund",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalStreams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ranked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numReleasesInClosedDeals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artistsBacked",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CompanyNode",
  "abstractKey": null
};

(node as any).hash = "f86f31c7dbf7ac7e58d68b63f0a70060";

export default node;
