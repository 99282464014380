import { SectionCompanyLookupQuery } from "__generated__/SectionCompanyLookupQuery.graphql";
import _ from "lodash";
import { ReactElement, useContext } from "react";
import { fetchQuery, graphql } from "react-relay";
import { components, OptionProps, SingleValueProps } from "react-select";
import { Flex, Text } from "theme-ui";

import { FieldErrors } from "../../../../../../components/01_Core/Forms/FieldBuilder";
import SelectInput from "../../../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import AvatarName from "../../../../../../components/01_Core/Users/AvatarName";
import environment from "../../../../../../environment";
import { CompanyKnownContext } from "../../../01_first-time_ux/IndifyToPartnerReferralFlow";

type CompanyOption = {
  label: string;
  value: string;
  thumbnailUrl: string;
};

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=471%3A24149
 */
function SectionCompanyLookup(): ReactElement {
  const [companyKnown, setCompanyKnown] = useContext(CompanyKnownContext);
  const CompanySearchQuery = graphql`
    query SectionCompanyLookupQuery($needle: String!) {
      allCompanies(displayName_Icontains: $needle) {
        edges {
          node {
            id
            displayName
            thumbnailUrl
          }
        }
      }
    }
  `;
  const loadOptions = (inputValue: string) => {
    return new Promise<CompanyOption[]>((resolve, reject) => {
      fetchQuery<SectionCompanyLookupQuery>(environment, CompanySearchQuery, {
        needle: inputValue
      }).subscribe({
        next: response => {
          const companies = response.allCompanies.edges
            .map(e => e.node)
            .map(c => {
              return {
                label: c.displayName,
                value: c.id,
                thumbnailUrl: c.thumbnailUrl
              };
            });

          return resolve(companies);
        },
        error: reject
      });
    });
  };
  const validate = (value: CompanyOption | null) => {
    if (value == null) {
      return "All indify partners must indicate which company they are associated with.";
    }
  };
  const noOptionsMessage: (inputValue: string) => null = inputValue => null;

  return (
    <Field name={"company"} allowNull={true} validate={validate}>
      {({ input, meta }) => {
        const hasError = (meta.error || meta.submitError) && meta.touched;
        const errors = _.compact(_.flatten([meta.error, meta.submitError]));
        const onChange = (v: any) => {
          setCompanyKnown(v && !v.__isNew__);
          input.onChange(v);
        };

        return (
          <Flex sx={{ flexDirection: "column", width: "100%" }}>
            <SelectInput
              size={["medium", "medium", "large", "large"]}
              label={"Company name"}
              caption={
                "All indify partners must indicate which company they are associated with."
              }
              asyncProps={{
                cacheOptions: true,
                loadOptions
              }}
              components={{
                Option: CompanyOption,
                SingleValue: CompanySingleValue,
                DropdownIndicator: null
              }}
              placeholder={null}
              isSearchable={true}
              isClearable={true}
              input={{ ...input, onChange }}
              meta={meta}
              isCreatable={true}
              formatCreateLabel={(inputValue: string) =>
                `Create new company "${inputValue}"`
              }
              isValidNewOption={(
                inputValue: string,
                selectValue: CompanyOption,
                selectOptions: CompanyOption[]
              ) => inputValue !== "" && selectOptions.length === 0}
              allowCreateWhileLoading={false}
              noOptionsMessage={noOptionsMessage}
              {...(hasError ? { state: "alert" } : {})}
            />
            {hasError && <FieldErrors errors={errors} />}
          </Flex>
        );
      }}
    </Field>
  );
}

function CompanyOption(props: OptionProps<CompanyOption, false>) {
  return (
    <components.Option {...props}>
      {props.data.thumbnailUrl ? (
        <AvatarName
          imageUrl={
            props.data.thumbnailUrl ??
            "https://storage.googleapis.com/indify-images/no_company.png"
          }
          size={"extraSmall"}
          displayName={props.data.label}
        />
      ) : (
        <Text
          variant={"bodyMedium"}
          sx={{ overflowX: "hidden" }}
          color={"black100"}
          ml={"8px"}
        >
          {props.data.label}
        </Text>
      )}
    </components.Option>
  );
}

function CompanySingleValue(props: SingleValueProps<any>) {
  return (
    <components.SingleValue {...props}>
      {props.data.thumbnailUrl ? (
        <AvatarName
          imageUrl={props.data.thumbnailUrl}
          size={"extraSmall"}
          displayName={props.data.label}
        />
      ) : (
        <Text
          variant={"bodyMedium"}
          sx={{ overflowX: "hidden" }}
          color={"black100"}
          ml={"8px"}
        >
          {props.data.label}
        </Text>
      )}
    </components.SingleValue>
  );
}

export default SectionCompanyLookup;
