import { Children, ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { useFindActiveRV } from "../../../../utils/responsiveUtils";

type AccountManagementHeadingSize = "small" | "large";
interface IAccountManagementHeadingProps {
  size: ResponsiveValue<AccountManagementHeadingSize>;
  title: string;
  description: string;
  moreDescription?: string | string[];
}

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1490%3A15621
 */
function AccountManagementHeading(
  props: IAccountManagementHeadingProps
): ReactElement {
  const titleVariant = useFindActiveRV(props.size, getTitleVariant);
  const descriptionVariant = useFindActiveRV(props.size, getDescriptionVariant);
  const moreDescriptionVariant = useFindActiveRV(
    props.size,
    getMoreDescriptionVariant
  );

  return (
    <AutoLayout
      spacing={16}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      <Text color={"black100"} variant={titleVariant} sx={{ width: "100%" }}>
        {props.title}
      </Text>
      <Text
        variant={descriptionVariant}
        color={"deepGray100"}
        sx={{ width: "100%" }}
      >
        {props.description}
      </Text>
      {Children.toArray(props.moreDescription).map((description, index) => (
        <Text
          key={index}
          variant={moreDescriptionVariant}
          color={"deepGray100"}
          sx={{ width: "100%" }}
        >
          {description}
        </Text>
      ))}
      <Divider />
    </AutoLayout>
  );
}

function getTitleVariant(size: AccountManagementHeadingSize) {
  switch (size) {
    case "small":
      return "h500";
    case "large":
      return "h600";
  }
}

function getDescriptionVariant(size: AccountManagementHeadingSize) {
  switch (size) {
    case "small":
      return "bodyLarge";
    case "large":
      return "subtitle";
  }
}

function getMoreDescriptionVariant(size: AccountManagementHeadingSize) {
  switch (size) {
    case "small":
      return "bodySmall";
    case "large":
      return "bodyMedium";
  }
}

export default AccountManagementHeading;
