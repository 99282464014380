/**
 * @generated SignedSource<<5af729d75b539a5ba31e650832a2a4fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Deal_featuredDeal$data = {
  readonly artist: {
    readonly " $fragmentSpreads": FragmentRefs<"ReleaseImagesGrid_artist" | "useMessage_actor">;
  };
  readonly creativeAmountRaising: number | null;
  readonly inPocketAmountRaising: number | null;
  readonly marketingAmountRaising: number | null;
  readonly partnerSplitPostRecoupment: number | null;
  readonly partnerSplitPreRecoupment: number | null;
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "ReleaseNode";
        readonly id: string;
        readonly isVisible: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"ReleaseImagesGrid_releases" | "UpdateOrCreateReleaseModal_release">;
      } | null;
    } | null>;
  };
  readonly soundRecordings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly termLengthMonths: number | null;
  readonly totalAmountRaising: number | null;
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "UnreleasedReleaseNode";
        readonly id: string;
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"ReleaseImagesGrid_unreleasedReleases" | "UpdateOrCreateReleaseModal_release">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "Deal_featuredDeal";
};
export type Deal_featuredDeal$key = {
  readonly " $data"?: Deal_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"Deal_featuredDeal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UpdateOrCreateReleaseModal_release"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Deal_featuredDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmountRaising",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inPocketAmountRaising",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "marketingAmountRaising",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creativeAmountRaising",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnerSplitPreRecoupment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnerSplitPostRecoupment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "termLengthMonths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useMessage_actor"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReleaseImagesGrid_artist"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SoundRecordingNodeConnection",
      "kind": "LinkedField",
      "name": "soundRecordings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SoundRecordingNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SoundRecordingNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isVisible",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReleaseImagesGrid_releases"
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReleaseImagesGrid_unreleasedReleases"
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};
})();

(node as any).hash = "54d1178ef74837784d990be1a2655f8a";

export default node;
