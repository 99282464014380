/**
 * @generated SignedSource<<be20d5c482172efabd189167fee5a8f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TikTokSoundsIncluded_tikTokSoundRemovable$data = {
  readonly thumbnailUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"TikTokSoundsIncluded_tikTokSoundDetails">;
  readonly " $fragmentType": "TikTokSoundsIncluded_tikTokSoundRemovable";
};
export type TikTokSoundsIncluded_tikTokSoundRemovable$key = {
  readonly " $data"?: TikTokSoundsIncluded_tikTokSoundRemovable$data;
  readonly " $fragmentSpreads": FragmentRefs<"TikTokSoundsIncluded_tikTokSoundRemovable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TikTokSoundsIncluded_tikTokSoundRemovable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TikTokSoundsIncluded_tikTokSoundDetails"
    }
  ],
  "type": "TikTokSoundNode",
  "abstractKey": null
};

(node as any).hash = "32a5357dbb42b950602dd6e6a68aeaaa";

export default node;
