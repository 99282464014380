/**
 * @generated SignedSource<<1996d419221b607f8ee6ede6ebf34b7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useOfferNextStep_authUser$data = {
  readonly id: string;
  readonly stripeConnectedAccountApiId: string | null;
  readonly " $fragmentType": "useOfferNextStep_authUser";
};
export type useOfferNextStep_authUser$key = {
  readonly " $data"?: useOfferNextStep_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOfferNextStep_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOfferNextStep_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeConnectedAccountApiId",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "3e9e383cf51d71509d5fda2f61211b31";

export default node;
