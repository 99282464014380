import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/**
 * This hook only runs the effect after the initial render.
 * Mimics the behavior of componentDidUpdate:
 * https://react.dev/reference/react/Component#componentdidupdate
 */
const useDidUpdateEffect = (
  func: EffectCallback,
  deps: DependencyList
): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidUpdateEffect;
