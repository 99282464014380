import { HomebaseInsights_homebase$key } from "__generated__/HomebaseInsights_homebase.graphql";
import { ReactElement, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Grid, Link as ExternalLink, Text } from "theme-ui";

import Ic24Instagram from "../../../../../imgs/icons/ic24-instagram.svg";
import Ic24TikTok from "../../../../../imgs/icons/ic24-social-tiktok.svg";
import Ic24Voice from "../../../../../imgs/icons/ic24-voice.svg";
import AutoLayout from "../../../../components/01_Core/AutoLayout";
import { IconBox } from "../../../../components/01_Core/Foundations/Icons";
import { formatNumber } from "../../../../utils/stringFormatting";
import { useTikTokSoundsTotal } from "../03_UI_Kit/Discover/RowDeal";
import BlockLowEmphasis from "../03_UI_Kit/Marketing/BlockLowEmphasis";

function HomebaseInsights(props: {
  homebase: HomebaseInsights_homebase$key;
}): ReactElement {
  const homebase = useFragment(
    graphql`
      fragment HomebaseInsights_homebase on HomebaseNode {
        artist {
          stats {
            streamsThisWeek
            weeklyChange
          }
          latestSpotifyMonthlyListenerCount {
            current
            percentChange
          }
          latestTiktokFollowerCount {
            current
            percentChange
          }
          instagramProfile {
            instagramProfileData(last: 1) {
              edges {
                node {
                  avgCommentsPerPost
                  avgLikesPerPost
                }
              }
            }
          }
          tiktokProfile {
            tiktokSounds {
              ...RowDeal_useTikTokSoundsTotal
            }
          }
          latestInstagramFollowerCount {
            current
            percentChange
          }
        }
      }
    `,
    props.homebase
  );

  const tikTokSoundUGCs =
    homebase.artist.tiktokProfile &&
    useTikTokSoundsTotal(homebase.artist.tiktokProfile.tiktokSounds);

  const latestInstagramData =
    homebase.artist.instagramProfile?.instagramProfileData?.edges.at(-1)?.node;

  const percentChangeText = (percentChange: number) => {
    const roundedPercentChange = Math.round(percentChange);

    if (roundedPercentChange === 0) {
      return <strong>unchanged</strong>;
    } else if (roundedPercentChange > 0) {
      return <strong>a {roundedPercentChange}% increase</strong>;
    } else {
      return <strong>a {Math.abs(roundedPercentChange)}% decrease</strong>;
    }
  };

  return (
    <AutoLayout spacing={32} dependentProps={{ direction: "vertical" }}>
      <Text variant={"h500"}>Insights</Text>
      <Grid columns={[1, 1, 2, 2]} gap={32}>
        {!homebase.artist.stats &&
          !homebase.artist.latestTiktokFollowerCount &&
          !homebase.artist.latestInstagramFollowerCount && (
            <Text variant={"bodyLarge"} color={"deepGray100"}>
              <em>No insights to show</em>
            </Text>
          )}
        {homebase.artist.stats && (
          <InsightItem icon={Ic24Voice} title={"Streaming"}>
            <AutoLayout spacing={8} dependentProps={{ direction: "vertical" }}>
              <Box>
                You had{" "}
                <strong>
                  {formatNumber(homebase.artist.stats?.streamsThisWeek)}
                </strong>{" "}
                streams last week, which is{" "}
                {percentChangeText(homebase.artist.stats?.weeklyChange * 100)}{" "}
                from the week before.
              </Box>
              <Box>
                You currently have{" "}
                <strong>
                  {formatNumber(
                    homebase.artist.latestSpotifyMonthlyListenerCount?.current
                  )}
                </strong>{" "}
                monthly listeners on Spotify, which is{" "}
                {percentChangeText(
                  homebase.artist.latestSpotifyMonthlyListenerCount
                    ?.percentChange
                )}{" "}
                from the week before.
              </Box>
            </AutoLayout>
          </InsightItem>
        )}
        {homebase.artist.latestTiktokFollowerCount && (
          <InsightItem icon={Ic24TikTok} title={"TikTok"}>
            <AutoLayout spacing={8} dependentProps={{ direction: "vertical" }}>
              <Box>
                You currently have{" "}
                <strong>
                  {formatNumber(
                    homebase.artist.latestTiktokFollowerCount?.current
                  )}
                </strong>{" "}
                followers on TikTok, which is{" "}
                {percentChangeText(
                  homebase.artist.latestTiktokFollowerCount?.percentChange
                )}{" "}
                from the week before.
              </Box>
              {tikTokSoundUGCs?.currentTotal && (
                <Box>
                  You currently have{" "}
                  <strong>{formatNumber(tikTokSoundUGCs?.currentTotal)}</strong>{" "}
                  total UGCs on TikTok, which is{" "}
                  {percentChangeText(tikTokSoundUGCs?.totalPercentChange)} from
                  the week before.
                </Box>
              )}
              {tikTokSoundUGCs.withHighestValue?.mostRecentStat && (
                <Box>
                  Your top sound on TikTok with the most UGCs is{" "}
                  <ExternalLink
                    href={tikTokSoundUGCs.withHighestValue.tiktokUrl}
                    target={"_blank"}
                    sx={{
                      borderBottom: "1px solid",
                      borderBottomColor: "black100"
                    }}
                  >
                    {tikTokSoundUGCs.withHighestValue.name}
                  </ExternalLink>{" "}
                  with{" "}
                  <strong>
                    {formatNumber(
                      tikTokSoundUGCs.withHighestValue.mostRecentStat.current
                    )}
                  </strong>{" "}
                  UGCs.
                </Box>
              )}
            </AutoLayout>
          </InsightItem>
        )}
        {homebase.artist.latestInstagramFollowerCount &&
          latestInstagramData && (
            <InsightItem icon={Ic24Instagram} title={"Instagram"}>
              <AutoLayout
                spacing={8}
                dependentProps={{ direction: "vertical" }}
              >
                <Box>
                  You have{" "}
                  <strong>
                    {formatNumber(
                      homebase.artist.latestInstagramFollowerCount?.current
                    )}
                  </strong>{" "}
                  followers on Instagram, which is{" "}
                  {percentChangeText(
                    homebase.artist.latestInstagramFollowerCount?.percentChange
                  )}{" "}
                  from the week before.
                </Box>
                <Box>
                  You average{" "}
                  <strong>
                    {formatNumber(latestInstagramData.avgCommentsPerPost)}
                  </strong>{" "}
                  comments per post and{" "}
                  <strong>
                    {formatNumber(latestInstagramData.avgLikesPerPost)}
                  </strong>{" "}
                  likes per post on Instagram.
                </Box>
              </AutoLayout>
            </InsightItem>
          )}
      </Grid>
    </AutoLayout>
  );
}

function InsightItem(props: {
  icon: SvgrComponent;
  title: string;
  children: ReactNode;
}): ReactElement {
  return (
    <BlockLowEmphasis size={"large"}>
      <AutoLayout spacing={24} dependentProps={{ direction: "vertical" }}>
        <AutoLayout
          spacing={12}
          dependentProps={{ direction: "horizontal", alignment: "center" }}
        >
          <IconBox icon={props.icon} sx={{ size: 32 }} />
          <Text variant={"h400"}>{props.title}</Text>
        </AutoLayout>
        <Text variant={"bodyMedium"}>{props.children}</Text>
      </AutoLayout>
    </BlockLowEmphasis>
  );
}

export default HomebaseInsights;
