/**
 * @generated SignedSource<<577af43f94244ab53856c22f7e00d623>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundPortfolioPage_offers$data = ReadonlyArray<{
  readonly monthlyEarnings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly month: any;
        readonly partnerEarnings: any | null;
        readonly partnerExpenses: any | null;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "FundPortfolioPage_offers";
}>;
export type FundPortfolioPage_offers$key = ReadonlyArray<{
  readonly " $data"?: FundPortfolioPage_offers$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundPortfolioPage_offers">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FundPortfolioPage_offers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MonthlyEarningsNodeConnection",
      "kind": "LinkedField",
      "name": "monthlyEarnings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MonthlyEarningsNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyEarningsNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "month",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partnerEarnings",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partnerExpenses",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "24d5b7c3d367d819ed2bb4fd7e403542";

export default node;
