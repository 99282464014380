import { ReferralAcceptance_referral$key } from "__generated__/ReferralAcceptance_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";

import Banner, { BannerSize } from "./Banner";

export default function ReferralAcceptance(props: {
  referral: ReferralAcceptance_referral$key;
  size: ResponsiveValue<BannerSize>;
}): ReactElement {
  const referral = useFragment(
    graphql`
      fragment ReferralAcceptance_referral on ReferralInterface {
        sender {
          displayName
          thumbnailUrl
        }
      }
    `,
    props.referral
  );

  return (
    <Banner
      size={props.size}
      senders={[referral.sender]}
      textFragments={[
        { kind: "emphasis", text: referral.sender.displayName },
        { kind: "normal", text: " " },
        { kind: "normal", text: "has invited you to join indify." }
      ]}
    />
  );
}
