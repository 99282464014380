/**
 * @generated SignedSource<<36b57b02cccc1cefcd33848b48dbbd28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseListItem_release$data = {
  readonly isVisible: boolean;
  readonly thumbnailUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseDetails_release" | "ReleaseStatusMenu_release">;
  readonly " $fragmentType": "ReleaseListItem_release";
};
export type ReleaseListItem_release$key = {
  readonly " $data"?: ReleaseListItem_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseListItem_release">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleaseListItem_release",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVisible",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseDetails_release"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseStatusMenu_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "c8acefb3e85bc9076478b2c3eecd68ee";

export default node;
