/**
 * @generated SignedSource<<353a65cff2b66dd7599395da220eb992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type ChatThreadHeading_chat$data = {
  readonly other: {
    readonly actor: {
      readonly " $fragmentSpreads": FragmentRefs<"AvatarIcon_actor">;
    } | null;
    readonly artist: {
      readonly slug: string;
      readonly visible: boolean;
    } | null;
    readonly lastActiveAt: any | null;
    readonly name: string;
    readonly partner: {
      readonly company: {
        readonly displayName: string;
        readonly slug: string;
        readonly visible: boolean;
      } | null;
      readonly displayName: string;
    } | null;
    readonly userType: UserType | null;
  } | null;
  readonly " $fragmentType": "ChatThreadHeading_chat";
};
export type ChatThreadHeading_chat$key = {
  readonly " $data"?: ChatThreadHeading_chat$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatThreadHeading_chat">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visible",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatThreadHeading_chat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "other",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastActiveAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "actor",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AvatarIcon_actor"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistNode",
          "kind": "LinkedField",
          "name": "artist",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerNode",
          "kind": "LinkedField",
          "name": "partner",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyNode",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};
})();

(node as any).hash = "489561c9f095d4a15003c481c0ada99a";

export default node;
