/**
 * @generated SignedSource<<770d346198d90bff78994ed162b6981b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseImagesGrid_releases$data = ReadonlyArray<{
  readonly id: string;
  readonly thumbnailUrl: string | null;
  readonly " $fragmentType": "ReleaseImagesGrid_releases";
}>;
export type ReleaseImagesGrid_releases$key = ReadonlyArray<{
  readonly " $data"?: ReleaseImagesGrid_releases$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseImagesGrid_releases">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ReleaseImagesGrid_releases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    }
  ],
  "type": "ReleaseNode",
  "abstractKey": null
};

(node as any).hash = "9c25e5692d5bc141da9169a0aacc7a3b";

export default node;
