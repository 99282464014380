import { PartnerToCompanyPartnerReferralFlowFragment_referral$key } from "__generated__/PartnerToCompanyPartnerReferralFlowFragment_referral.graphql";
import assert from "assert";
import { ReactElement, useEffect } from "react";
import { graphql, useFragment } from "react-relay";
import { BehaviorSubject, Observable } from "rxjs";

import useBehaviorSubjectState from "../../hooks/useBehaviorSubjectState";
import {
  INavState,
  IReferralFormValues,
  NavigationRequest
} from "./ClaimYourAccountFlow";
import Page, {
  ConfirmAccountInfoPage,
  Pages,
  PartnerAcceptTermsPage
} from "./Pages/Page";

interface IPartnerToCompanyPartnerReferralFlowProps {
  navRequests: Observable<NavigationRequest>;
  navState: BehaviorSubject<INavState>;
  referral: PartnerToCompanyPartnerReferralFlowFragment_referral$key;
}

export interface IPartnerToCompanyPartnerReferralFlowFormValues
  extends IReferralFormValues {
  email: string;
  password: string;
  name: string;
  tos: boolean;
  distroWorkflow: boolean;
}

const flow: Pages[] = [ConfirmAccountInfoPage, PartnerAcceptTermsPage];

/**
 * Manages stepping through the Partner => Company-Partner referral flow
 */
function PartnerToCompanyPartnerReferralFlow(
  props: IPartnerToCompanyPartnerReferralFlowProps
): ReactElement {
  const { current } = useBehaviorSubjectState(props.navState);
  const section = flow[current];
  // TODO: use form state to determine flow
  useEffect(() => {
    const { current, page } = props.navState.getValue();
    props.navState.next({
      current,
      page: page,
      total: flow.length
    });
  }, [flow]);
  useEffect(() => {
    const sub = props.navRequests.subscribe({
      next: navRequest => {
        const { current, total } = props.navState.getValue();
        switch (navRequest) {
          case "continue": {
            const next = current + 1;
            assert(next < total);
            props.navState.next({
              current: next,
              page: flow[next],
              total
            });
            return;
          }
          case "back": {
            const previous = current - 1;
            assert(current > 0);
            props.navState.next({
              current: previous,
              page: flow[previous],
              total
            });
            return;
          }
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const referral = useFragment(
    graphql`
      fragment PartnerToCompanyPartnerReferralFlowFragment_referral on ReferralInterface {
        ...Page_referral
      }
    `,
    props.referral
  );

  return <Page page={section} referral={referral} />;
}

export default PartnerToCompanyPartnerReferralFlow;
