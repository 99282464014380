import { AvatarIcon_actor$key } from "__generated__/AvatarIcon_actor.graphql";
import dayjs from "dayjs";
import { ReactElement, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Flex, Image, ImageProps, useThemeUI } from "theme-ui";

import useUserThumbnailUrl from "../../../pages/indify/hooks/useUserThumbnailUrl";
import { rvMap, useFindActiveRV } from "../../../utils/responsiveUtils";

export type AvatarIconSize =
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";

export type AvatarIconStatus = "active" | "invited";

export interface IBadgeProps {
  Icon?: SvgrComponent;
  title?: string;
  color?: string;
  bg?: string;
  border?: string;
  size?: number | string;
}

export function WithBadge(
  props: IBadgeProps & {
    children: ReactNode;
  }
): ReactElement {
  const { theme } = useThemeUI();
  return (
    <Flex sx={{ position: "relative", flexShrink: 0 }}>
      {props.children}
      {(props.Icon || props.bg) && (
        <Flex
          title={props.title}
          bg={props.bg ?? theme.colors.primary85}
          sx={{
            width: props.size,
            height: props.size,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            border: props.border,
            position: "absolute",
            right: 0,
            bottom: 0
          }}
        >
          {props.Icon && (
            <props.Icon
              color={(props.color ?? theme.colors.white100) as string}
              width={"70%"}
              height={"70%"}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}

export interface IAvatarIconProps extends ImageProps {
  imageUrl: string;
  size: ResponsiveValue<AvatarIconSize>;
  badge?: IBadgeProps;
  status?: AvatarIconStatus;
  outline?: boolean;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A1844
 */
export default function AvatarIcon(props: IAvatarIconProps): ReactElement {
  const { imageUrl, size, badge, status, outline, sx, ...imageProps } = props;

  const responsiveAvatarSize = rvMap(size, getAvatarIconSize);
  const badgeSize =
    badge?.size ??
    useFindActiveRV(size, s => {
      switch (s) {
        case "extraSmall":
        case "small":
        case "medium":
        case "large":
          return "40%";
        case "extraLarge":
          return "25%";
      }
    });
  const avatarStatus = status ?? "active";

  return (
    <WithBadge {...badge} size={badgeSize}>
      <Flex sx={{ flexShrink: 0 }}>
        <Image
          src={imageUrl}
          {...imageProps}
          sx={{
            borderRadius: "50%",
            objectFit: "cover",
            userSelect: "none",
            size: responsiveAvatarSize,
            ...(avatarStatus === "invited"
              ? {
                  border: "1px dashed",
                  borderColor: "midGray100"
                }
              : {}),
            ...(outline
              ? {
                  outline: "2px solid",
                  outlineColor: "white100"
                }
              : {}),
            ...sx
          }}
        />
      </Flex>
    </WithBadge>
  );
}

export function getUserActiveDotProps(lastActiveAt: string): IBadgeProps {
  if (!lastActiveAt) return null;
  const hoursSinceLastActive = dayjs().diff(dayjs(lastActiveAt), "hour");
  if (hoursSinceLastActive >= 24) return null;

  return {
    bg: "#1CD14F", // Copied from Instagram active user dot
    border: "2px solid white",
    size: "35%"
  };
}

export function ActorAvatarIcon(props: {
  actor: AvatarIcon_actor$key;
  badge?: IBadgeProps;
  disableActiveDot?: boolean;
  size: ResponsiveValue<AvatarIconSize>;
}): ReactElement {
  const { user } = useFragment(
    graphql`
      fragment AvatarIcon_actor on ActorInterface {
        user {
          lastActiveAt
          ...useUserThumbnailUrl_user
        }
      }
    `,
    props.actor
  );
  const imageUrl = useUserThumbnailUrl(user);
  const activeDotProps = props.disableActiveDot
    ? undefined
    : getUserActiveDotProps(user.lastActiveAt);
  return (
    <AvatarIcon
      imageUrl={imageUrl}
      size={props.size}
      badge={activeDotProps ?? props.badge}
    />
  );
}

const getAvatarIconSize = (size: AvatarIconSize): number => {
  switch (size) {
    case "extraSmall":
      return 24;
    case "small":
      return 32;
    case "medium":
      return 40;
    case "large":
      return 48;
    case "extraLarge":
      return 64;
  }
};
