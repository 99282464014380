import { InboxChats_chats$key } from "__generated__/InboxChats_chats.graphql";
import _ from "lodash";
import { ReactElement, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import { Loader } from "../../../../components/01_Core/999_Miscellaneous/Loader";
import { NodeId } from "../../../../types/relay_types";
import { rvMap } from "../../../../utils/responsiveUtils";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import InboxChat from "../03_UI_Kit/Messaging/InboxChat";

type InboxChatsSize = "small" | "medium";
export interface IInboxChats {
  chats: InboxChats_chats$key;
  size: ResponsiveValue<InboxChatsSize>;
  selectedChatId: NodeId;
  emptyText: string;
  infiniteScrollProps: {
    next: () => void;
    hasMore: boolean;
  };
}

function InboxChats(props: IInboxChats): ReactElement {
  const chats = useFragment(
    graphql`
      fragment InboxChats_chats on ChatNode @relay(plural: true) {
        id
        lastMessageSentAt
        ...InboxChat_chat
      }
    `,
    props.chats
  );
  const chatsContainerRef = useRef(null);

  const scrollToTop = () => {
    chatsContainerRef.current?.scrollTo({
      bottom: 0,
      behavior: "smooth"
    });
  };

  return (
    <Flex
      sx={{
        flexDirection: "column-reverse",
        width: "100%",
        overflowY: "auto"
      }}
      p={rvMap(props.size, getPadding)}
      id={"infinite-scroll-container-inbox-messages"}
      ref={chatsContainerRef}
    >
      <InfiniteScroll
        next={() => {
          scrollToTop();
          props.infiniteScrollProps.next();
        }}
        hasMore={props.infiniteScrollProps.hasMore}
        loader={
          <Flex
            my={"16px"}
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader
              size={rvMap(props.size, s =>
                s === "small" ? "medium" : "large"
              )}
            />
          </Flex>
        }
        endMessage={
          <Text
            variant={"bodyMedium"}
            color={"deepGray100"}
            sx={{ width: "100%", textAlign: "center", marginBottom: "24px" }}
          >
            {chats.length
              ? "You've reached the beginning of your chats."
              : props.emptyText}
          </Text>
        }
        dataLength={chats.length}
        inverse={true}
        scrollableTarget={"infinite-scroll-container-inbox-messages"}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          overflow: "hidden"
        }}
      >
        {_.sortBy(chats, c => c.lastMessageSentAt)
          .reverse()
          .map(c => {
            return (
              <Link
                to={generatePath(InternalRoutes.inbox, {
                  chatId: c.id
                })}
                key={c.id}
              >
                <InboxChat
                  key={c.id}
                  chat={c}
                  state={props.selectedChatId === c.id ? "active" : "normal"}
                  size={props.size}
                />
              </Link>
            );
          })}
      </InfiniteScroll>
    </Flex>
  );
}

function getPadding(size: InboxChatsSize) {
  switch (size) {
    case "small":
      return "0 16px 16px 16px";
    case "medium":
      return "0 24px 24px 24px";
  }
}

export default InboxChats;
