import Color from "color";
import ColorThief from "colorthief";
import { useEffect, useState } from "react";
import { useThemeUI } from "theme-ui";

import { darkenColor } from "../../../utils/color";

function useImageSampleColor(imageUrl: string): string {
  const { theme } = useThemeUI();
  const [color, setColor] = useState<string>(null);

  useEffect(() => {
    const colorThief = new ColorThief();
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = imageUrl;

    image.addEventListener("load", () => {
      const color = Color.rgb(colorThief.getColor(image));
      const darkenedColor = darkenColor(color);

      setColor(darkenedColor.rgb().string());
    });
  }, []);

  return color ?? (theme.colors.lightGray100 as string);
}

export default useImageSampleColor;
