import {
  CompanySocialLinks_company$data,
  CompanySocialLinks_company$key,
  ProfilePlatform
} from "__generated__/CompanySocialLinks_company.graphql";
import {
  BaseSocialLinks,
  CompanySocialLinksMutation
} from "__generated__/CompanySocialLinksMutation.graphql";
import { FORM_ERROR } from "final-form";
import { ReactElement, useContext } from "react";
import { Form } from "react-final-form";
import { graphql, useFragment } from "react-relay";
import { Text } from "theme-ui";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import useMutationPromise from "../../hooks/useMutationPromise";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import Footer from "./Footer";
import Platform from "./FormFields/Platform";

interface ICompanySocialLinksProps {
  company: CompanySocialLinks_company$key;
}

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=3114%3A31182
 */
function CompanySocialLinks(props: ICompanySocialLinksProps): ReactElement {
  const company = useFragment(
    graphql`
      fragment CompanySocialLinks_company on CompanyNode {
        id
        profiles {
          edges {
            node {
              id
              url
              platform
            }
          }
        }
      }
    `,
    props.company
  );

  const [commit] = useMutationPromise<CompanySocialLinksMutation>(graphql`
    mutation CompanySocialLinksMutation(
      $input: EditCompanySocialLinksMutationInput!
    ) {
      editCompanySocialLinks(data: $input) {
        company {
          profiles {
            edges {
              node {
                id
                url
                platform
              }
            }
          }
        }
      }
    }
  `);

  const onSubmit = (socialLinks: BaseSocialLinks) =>
    commit({
      variables: { input: { companyNodeId: company.id, socialLinks } }
    }).then(() =>
      notifyBanner({
        type: "neutral",
        children: "Your social links have been updated."
      })
    );

  const notifyBanner = useContext(BannerContext);

  const initialValues: BaseSocialLinks = {
    instagram: getProfileUrl("INSTAGRAM", company),
    tiktok: getProfileUrl("TIKTOK", company),
    youtube: getProfileUrl("YOUTUBE", company),
    soundcloud: getProfileUrl("SOUNDCLOUD", company),
    twitter: getProfileUrl("TWITTER", company)
  };

  const validateRequiresAtLeastOne = (values: BaseSocialLinks) => {
    if (!Object.values(values).some(v => v)) {
      return {
        [FORM_ERROR]: "Please provide at least one social link."
      };
    }

    return undefined;
  };

  return (
    <Form<BaseSocialLinks>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateRequiresAtLeastOne}
      key={"form"}
    >
      {formProps => {
        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100%" }}>
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Company social links"}
                description={
                  "Showcase your company’s social profiles on indify."
                }
              />
              <AutoLayout
                spacing={[32, 48, 48, 48]}
                resizingX={FillContainer()}
                dependentProps={{
                  direction: "vertical"
                }}
              >
                <Platform platform={"TIKTOK"} />
                <Platform platform={"YOUTUBE"} />
                <Platform platform={"SOUNDCLOUD"} />
                <Platform platform={"INSTAGRAM"} />
                <Platform platform={"TWITTER"} />
              </AutoLayout>
              {formProps.errors && formProps.errors[FORM_ERROR] && (
                <Text variant={"bodyLarge"} color={"alert50"}>
                  {formProps.errors[FORM_ERROR]}
                </Text>
              )}
              <Footer
                buttonDisabled={
                  formProps.pristine || formProps.hasValidationErrors
                }
                loading={formProps.submitting}
                onClick={formProps.handleSubmit}
                size={["small", "large", "large", "large"]}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}

function getProfileUrl(
  platform: ProfilePlatform,
  company: CompanySocialLinks_company$data
) {
  const edge = company.profiles.edges.find(e => e.node.platform === platform);

  return edge ? edge.node.url : null;
}

export default CompanySocialLinks;
