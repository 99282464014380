import { ReactElement, ReactNode } from "react";
import { Link as ExternalLink, Text } from "theme-ui";

import Ic16ArrowRight from "../../../../../../imgs/icons/ic16-arrow-right.svg";
import Ic20Info from "../../../../../../imgs/icons/ic20-info.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import theme from "../../../../../theme/theme";
import { ExternalRoutes } from "../../../Routing/ExternalRoutes";
import BlockLowEmphasis from "../Marketing/BlockLowEmphasis";

export default function OfferFormGuidance(props: {
  children: ReactNode;
  hideTermsLink?: boolean;
}): ReactElement {
  return (
    <BlockLowEmphasis size={"small"} sx={{ width: "100%" }}>
      <AutoLayout spacing={16} dependentProps={{ direction: "horizontal" }}>
        <Ic20Info
          color={theme.colors.deepGray70 as string}
          viewBox={`0 0 20 20`}
          style={{
            width: "24px",
            height: "auto",
            alignSelf: "flex-start"
          }}
        />
        <AutoLayout spacing={16} dependentProps={{ direction: "vertical" }}>
          <Text>{props.children}</Text>
          {!props.hideTermsLink && (
            <ExternalLink
              href={ExternalRoutes.dealGuide}
              target={"_blank"}
              sx={{
                borderBottom: "1px solid",
                borderBottomColor: "black100"
              }}
            >
              <AutoLayout
                spacing={4}
                dependentProps={{ direction: "horizontal" }}
              >
                <Text variant={"bodySmall"}>Learn more about these terms</Text>
                <Ic16ArrowRight />
              </AutoLayout>
            </ExternalLink>
          )}
        </AutoLayout>
      </AutoLayout>
    </BlockLowEmphasis>
  );
}
