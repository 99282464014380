/**
 * @generated SignedSource<<7090bed31620fc73f6a3db25d3fcb704>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewAppFeaturesQuery$variables = {};
export type NewAppFeaturesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewAppFeatures_features">;
};
export type NewAppFeaturesQuery = {
  response: NewAppFeaturesQuery$data;
  variables: NewAppFeaturesQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewAppFeaturesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NewAppFeatures_features"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewAppFeaturesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AppFeatureNode",
        "kind": "LinkedField",
        "name": "newAppFeatures",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eca67e061e62512d7aa684e363755c3a",
    "id": null,
    "metadata": {},
    "name": "NewAppFeaturesQuery",
    "operationKind": "query",
    "text": "query NewAppFeaturesQuery {\n  ...NewAppFeatures_features\n}\n\nfragment NewAppFeatures_features on Query {\n  newAppFeatures {\n    id\n    title\n    description\n    imageUrl\n  }\n}\n"
  }
};

(node as any).hash = "3173f1e6fbc02d1e787325cb5589e516";

export default node;
