import { WeeklyStreamsSection_entity$key } from "__generated__/WeeklyStreamsSection_entity.graphql";
import dayjs from "dayjs";
import _ from "lodash";
import numeral from "numeral";
import { ReactElement, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { ResponsiveValue } from "styled-system";
import { Flex, Link as ExternalLink, Text, useThemeUI } from "theme-ui";

import Ic16ArrowLeft from "../../../../../imgs/icons/ic16-arrow-left.svg";
import Ic16ArrowRight from "../../../../../imgs/icons/ic16-arrow-right.svg";
import AutoLayout, {
  AutoLayoutDependentProps,
  Direction,
  Distribution,
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { rvMap } from "../../../../utils/responsiveUtils";
import { useAuth } from "../../hooks/useAuth";

type WeeklyStreamsSectionSize = "small" | "large";

interface IWeeklyStreamsSectionProps {
  entity: WeeklyStreamsSection_entity$key;
  size: ResponsiveValue<WeeklyStreamsSectionSize>;
}

function WeeklyStreamsSection(props: IWeeklyStreamsSectionProps): ReactElement {
  const portfolio = useFragment(
    graphql`
      fragment WeeklyStreamsSection_entity on Node {
        ... on CompanyNode {
          partnershipStreamsByWeek {
            weekStartDay
            weekEndDay
            streams
          }
        }
        ... on ArtistNode {
          partnershipStreamsByWeek {
            weekStartDay
            weekEndDay
            streams
          }
        }
      }
    `,
    props.entity
  );
  const { theme } = useThemeUI();

  const { authUser } = useAuth();
  const dealsStr =
    authUser.userType === "partner" ? "your company's deals" : "your deals";

  const weeklyStreams = _.sortBy(
    portfolio.partnershipStreamsByWeek.map(weekStreams => ({
      ...weekStreams,
      weekStartDay: new Date(weekStreams.weekStartDay),
      weekEndDay: new Date(weekStreams.weekEndDay)
    })),
    p => p.weekStartDay
  );

  const weeklyStreamsChunks = useMemo(
    () =>
      _.chunk([...weeklyStreams].reverse(), 13).map(chunk => chunk.reverse()),
    [portfolio]
  );

  const [selectedChunk, setSelectedChunk] = useState<number>(0);
  const [currentWeeklyStreams, setCurrentWeeklyStreams] = useState(
    weeklyStreams[weeklyStreams.length - 1]
  );

  const formatDate = (date: Date | string) => dayjs(date).format("MMM D, YYYY");

  const PrevButton = () => (
    <TextButton
      onClick={() => {
        if (selectedChunk < weeklyStreamsChunks.length - 1) {
          setSelectedChunk(selectedChunk + 1);
        }
      }}
      size={"small"}
      type={"primary"}
      iconProps={{
        placement: "left",
        icon: <Ic16ArrowLeft />
      }}
      disabled={
        weeklyStreamsChunks.length === 0 ||
        selectedChunk === weeklyStreamsChunks.length - 1
      }
    >
      {"Prev"}
    </TextButton>
  );

  const NextButton = () => (
    <TextButton
      onClick={() => {
        if (selectedChunk > 0) {
          setSelectedChunk(selectedChunk - 1);
        }
      }}
      size={"small"}
      type={"primary"}
      iconProps={{
        placement: "right",
        icon: <Ic16ArrowRight />
      }}
      disabled={selectedChunk === 0}
    >
      {"Next"}
    </TextButton>
  );

  return (
    <AutoLayout
      spacing={12}
      dependentProps={rvMap(props.size, getDependentProps)}
      resizingX={FillContainer()}
    >
      <AutoLayout
        spacing={8}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical", alignment: "left" }}
      >
        <AutoLayout
          spacing={48}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <Text variant={"h400"} color={"black100"}>
            Streams
          </Text>
          {weeklyStreams.length === 0 ? (
            <Flex
              sx={{
                width: "100%",
                height: "300px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                backgroundColor: "midGray70"
              }}
            >
              <Text variant={"h300"} color={"deepGray100"}>
                No streams to show
              </Text>
            </Flex>
          ) : (
            <ResponsiveContainer width={"100%"} height={300}>
              <AreaChart
                data={weeklyStreamsChunks[selectedChunk]}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
                onMouseMove={e => {
                  if (e.isTooltipActive) {
                    setCurrentWeeklyStreams(e.activePayload[0].payload);
                  }
                }}
                onMouseLeave={() => {
                  setCurrentWeeklyStreams(
                    weeklyStreams[weeklyStreams.length - 1]
                  );
                }}
              >
                <XAxis
                  dataKey={"weekEndDay"}
                  tickFormatter={v => dayjs(v).format("MMM D")}
                  interval={2}
                />
                <YAxis
                  type={"number"}
                  tickFormatter={v => numeral(v).format("0[.]0[0]a")}
                  domain={["auto", "auto"]}
                  tickCount={4}
                  width={40}
                  padding={{ top: 8 }}
                />
                <Tooltip wrapperStyle={{ display: "none" }} />
                <Area
                  dataKey={"streams"}
                  type={"linear"}
                  stroke={theme.colors.black100 as string}
                  strokeWidth={"2px"}
                  fill={"#0000001A"}
                  activeDot={{ r: 8 }}
                  animationDuration={1000}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </AutoLayout>
        <AutoLayout
          spacing={0}
          resizingX={FillContainer()}
          dependentProps={{
            direction: "horizontal",
            distribution: "spaceBetween"
          }}
          mb={rvMap(props.size, getMarginBottom)}
          px={"16px"}
        >
          <PrevButton />
          <NextButton />
        </AutoLayout>
      </AutoLayout>
      <AutoLayout
        spacing={8}
        dependentProps={{ direction: "vertical" }}
        sx={{
          width: rvMap(props.size, getWidth),
          flexShrink: 0
        }}
      >
        <AutoLayout
          spacing={16}
          dependentProps={{ direction: "vertical", alignment: "left" }}
          resizingX={FillContainer()}
          p={16}
          sx={{
            backgroundColor: "white100",
            border: "1px solid",
            borderColor: "midGray100",
            borderRadius: "4px"
          }}
        >
          <AutoLayout
            spacing={4}
            resizingX={FillContainer()}
            dependentProps={{ direction: "vertical" }}
          >
            <Text variant={"h400"} color={"black100"}>
              {numeral(_.sumBy(weeklyStreams, "streams")).format("0,0")}
            </Text>
            <Text variant={"bodySmall"} color={"deepGray100"}>
              {`All time streams for ${dealsStr}`}
            </Text>
          </AutoLayout>
          <Divider saturation={"dark"} />
          <AutoLayout spacing={4} dependentProps={{ direction: "vertical" }}>
            <Text variant={"h300"} color={"black100"}>
              {currentWeeklyStreams
                ? numeral(currentWeeklyStreams.streams).format("0,0")
                : "0"}
            </Text>
            <AutoLayout spacing={2} dependentProps={{ direction: "vertical" }}>
              <Text variant={"bodySmall"} color={"deepGray100"}>
                {currentWeeklyStreams
                  ? `Total streams for ${dealsStr} between`
                  : `Recent streams for ${dealsStr}`}
              </Text>
              {currentWeeklyStreams && (
                <Text variant={"bodySmall"} color={"deepGray100"}>
                  <strong>
                    {formatDate(currentWeeklyStreams.weekStartDay)}
                  </strong>{" "}
                  -{" "}
                  <strong>{formatDate(currentWeeklyStreams.weekEndDay)}</strong>
                </Text>
              )}
            </AutoLayout>
          </AutoLayout>
        </AutoLayout>
        <Text variant={"bodySmall"} color={"deepGray100"} pl={"8px"}>
          <em>
            Streams sourced from{" "}
            <ExternalLink
              href={"https://luminatedata.com/"}
              target={"_blank"}
              sx={{ textDecoration: "underline" }}
            >
              Luminate
            </ExternalLink>
          </em>
        </Text>
      </AutoLayout>
    </AutoLayout>
  );
}

function getDependentProps(
  size: WeeklyStreamsSectionSize
): AutoLayoutDependentProps<Direction, Distribution> {
  switch (size) {
    case "small":
      return {
        direction: "vertical",
        alignment: "center"
      };
    case "large":
      return { direction: "horizontal", alignment: "top" };
  }
}

function getWidth(size: WeeklyStreamsSectionSize) {
  switch (size) {
    case "small":
      return "100%";
    case "large":
      return "300px";
  }
}

function getMarginBottom(size: WeeklyStreamsSectionSize) {
  switch (size) {
    case "small":
      return "24px";
    case "large":
      return "0";
  }
}

export default WeeklyStreamsSection;
