import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import Ic16ArrowRight from "../../../../../../imgs/icons/ic16-arrow-right.svg";
import Ic16TrendingDown from "../../../../../../imgs/icons/ic16-trending-down.svg";
import Ic16TrendingUp from "../../../../../../imgs/icons/ic16-trending-up.svg";
import Ic20ArrowRight from "../../../../../../imgs/icons/ic20-arrow-right-1.svg";
import Ic20TrendingDown from "../../../../../../imgs/icons/ic20-trending-down.svg";
import Ic20TrendingUp from "../../../../../../imgs/icons/ic20-trending-up.svg";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";

type StatDeltaSize = "small" | "large";
interface IStatDeltaProps {
  size: ResponsiveValue<StatDeltaSize>;
  delta: number;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=217%3A11
 */
function StatDelta(props: IStatDeltaProps): ReactElement {
  const activeSize = useActiveResponsiveValue<StatDeltaSize>(props.size);

  let delta = Math.round(props.delta);
  if (delta === 0) delta = 0; // Remove -0
  const deltaString = delta.toLocaleString();
  const isZero = delta === 0;
  const trendingUp = delta > 0;
  const trendIndicator = isZero ? "" : trendingUp ? "+" : "";
  const formattedDelta = `${trendIndicator}${deltaString}%`;
  const color = isZero ? undefined : trendingUp ? "upwards100" : "downwards100";

  const TrendIcon: SvgrComponent = isZero
    ? activeSize === "small"
      ? Ic16ArrowRight
      : Ic20ArrowRight
    : trendingUp
    ? activeSize === "small"
      ? Ic16TrendingUp
      : Ic20TrendingUp
    : activeSize === "small"
    ? Ic16TrendingDown
    : Ic20TrendingDown;

  return (
    <Flex color={color} sx={{ alignItems: "center" }}>
      <Text color={"inherit"} mr={"4px"}>
        {formattedDelta}
      </Text>
      <TrendIcon style={{ width: "1.2em", height: "1.2em" }} />
    </Flex>
  );
}

export default StatDelta;
