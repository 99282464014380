import { useStripeCheckoutRedirectMutation } from "__generated__/useStripeCheckoutRedirectMutation.graphql";
import { graphql } from "react-relay";

import useMutationPromise from "./useMutationPromise";

export function useStripeCheckoutRedirect(
  billingPeriod: string,
  redirectUrl?: string
): [() => void, boolean] {
  const [commitMutation, isInFlight] =
    useMutationPromise<useStripeCheckoutRedirectMutation>(graphql`
      mutation useStripeCheckoutRedirectMutation(
        $billingPeriod: String!
        $redirectUrl: String
      ) {
        createStripeCheckoutSession(
          billingPeriod: $billingPeriod
          redirectUrl: $redirectUrl
        ) {
          url
        }
      }
    `);

  const stripeCheckoutRedirect = () =>
    commitMutation({
      variables: {
        billingPeriod,
        redirectUrl
      }
    }).then(response => {
      window.location.href = response.createStripeCheckoutSession.url;
    });

  return [stripeCheckoutRedirect, isInFlight];
}
