/**
 * @generated SignedSource<<748fb7242f8a4ce94b057a9baaa6507f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundDirectoryQuery$variables = {};
export type FundDirectoryQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FundDirectoryRows_allCompanies">;
};
export type FundDirectoryQuery = {
  response: FundDirectoryQuery$data;
  variables: FundDirectoryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "displayName_Icontains",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  {
    "kind": "Literal",
    "name": "onlyWatching",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "total_streams_desc"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FundDirectoryQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FundDirectoryRows_allCompanies"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FundDirectoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CompanyNodeConnection",
        "kind": "LinkedField",
        "name": "allCompanies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalStreams",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ranked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "watching",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PartnerNode",
                    "kind": "LinkedField",
                    "name": "recipientPartner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ChatNode",
                            "kind": "LinkedField",
                            "name": "chatWithCurrentUser",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "partnerHasMessaged",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "artistHasMessaged",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isActorInterface"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PartnerNodeConnection",
                    "kind": "LinkedField",
                    "name": "partners",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PartnerNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PartnerNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastActiveAt",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArtistNode",
                    "kind": "LinkedField",
                    "name": "artistsBacked",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileNode",
                        "kind": "LinkedField",
                        "name": "spotifyProfile",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyServiceNodeConnection",
                    "kind": "LinkedField",
                    "name": "services",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompanyServiceNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CompanyServiceNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "service",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allCompanies(displayName_Icontains:\"\",first:25,onlyWatching:false,orderBy:\"total_streams_desc\")"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "orderBy",
          "displayName_Icontains",
          "onlyWatching",
          "onlyWithPartnerships",
          "onlyWithTestimonials",
          "services",
          "genres"
        ],
        "handle": "connection",
        "key": "FundDirectory_allCompanies",
        "kind": "LinkedHandle",
        "name": "allCompanies"
      }
    ]
  },
  "params": {
    "cacheID": "64544adff5a534531162160e73cedeb4",
    "id": null,
    "metadata": {},
    "name": "FundDirectoryQuery",
    "operationKind": "query",
    "text": "query FundDirectoryQuery {\n  ...FundDirectoryRows_allCompanies\n}\n\nfragment FundDirectoryRows_allCompanies on Query {\n  allCompanies(first: 25, orderBy: total_streams_desc, displayName_Icontains: \"\", onlyWatching: false) {\n    edges {\n      node {\n        id\n        ...RowFund_fund\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment MultipleArtists_artists on ArtistNode {\n  id\n  displayName\n  ...useArtistThumbnailUrl_artist\n}\n\nfragment RowFund_fund on CompanyNode {\n  displayName\n  slug\n  ...useToggleWatchCompany_company\n  thumbnailUrl\n  totalStreams\n  ranked\n  watching\n  recipientPartner {\n    user {\n      chatWithCurrentUser {\n        ...useArtistCanMessageCompany_chat\n        id\n      }\n      id\n    }\n    id\n    ...useMessage_actor\n  }\n  partners {\n    edges {\n      node {\n        user {\n          lastActiveAt\n          id\n        }\n        id\n      }\n    }\n  }\n  artistsBacked {\n    ...MultipleArtists_artists\n    id\n  }\n  services {\n    edges {\n      node {\n        service\n        id\n      }\n    }\n  }\n}\n\nfragment useArtistCanMessageCompany_chat on ChatNode {\n  partnerHasMessaged\n  artistHasMessaged\n}\n\nfragment useArtistThumbnailUrl_artist on ArtistNode {\n  thumbnailUrl\n  spotifyProfile {\n    thumbnailUrl\n    id\n  }\n}\n\nfragment useMessage_actor on ActorInterface {\n  __isActorInterface: __typename\n  user {\n    ...useMessage_user\n    id\n  }\n}\n\nfragment useMessage_user on UserNode {\n  id\n}\n\nfragment useToggleWatchCompany_company on CompanyNode {\n  slug\n}\n"
  }
};
})();

(node as any).hash = "fceb86e493ba7dd882f231e57987433b";

export default node;
