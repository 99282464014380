import { OfferNextStep_offer$key } from "__generated__/OfferNextStep_offer.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Link as ExternalLink, Text } from "theme-ui";

import Ic24OpenInNew from "../../../../../../../imgs/icons/ic24-open-in-new.svg";
import Button from "../../../../../../components/01_Core/Buttons/Button";
import { centsToDollars, formatDollars } from "../../../../../../utils/money";
import useOfferNextStep from "../../../../hooks/useOfferNextStep";
import useStripeConnectUrl from "../../../../hooks/useStripeConnectUrl";
import { IconLabel } from "../../Discover/RowHeaderDeal";
import OfferNextStepDisplay from "./OfferNextStepDisplay";

export type OfferNextStepEnum =
  | null
  | "actionArtistReviewOffer"
  | "waitingArtistReviewOffer"
  | "actionArtistDirectEarnings"
  | "waitingArtistDirectEarnings"
  | "actionArtistConnectStripe"
  | "waitingArtistConnectStripe"
  | "waitingIndifyCreateInvoice"
  | "actionPartnerSendTransfer"
  | "waitingPartnerSendTransfer"
  | "active"
  | "voided"
  | "contactIndify";

interface IOfferNextStepProps {
  offer: OfferNextStep_offer$key;
}

/**
 * TODO: Design and implement "Inactive Deal" status
 *
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2842%3A23794
 */
function OfferNextStep(props: IOfferNextStepProps): ReactElement {
  const offer = useFragment(
    graphql`
      fragment OfferNextStep_offer on OfferNode {
        moneyTransferAmountCents
        stripeInvoiceUrl
        partner {
          displayName
        }
        artist {
          displayName
        }
        transferRecipient {
          user {
            id
          }
        }
        ...useOfferNextStep_offer
      }
    `,
    props.offer
  );
  const status = useOfferNextStep(offer);

  const formattedTransferAmount = offer.moneyTransferAmountCents && (
    <strong>
      ${formatDollars(centsToDollars(offer.moneyTransferAmountCents))}
    </strong>
  );

  const formattedArtistName = <strong>{offer.artist.displayName}</strong>;
  const formattedPartnerName = <strong>{offer.partner.displayName}</strong>;

  switch (status) {
    case "actionArtistReviewOffer":
    case "waitingArtistReviewOffer":
      return null;
    case "actionArtistDirectEarnings":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Earnings for all content in this deal must be directed to indify
              before{" "}
              {offer.moneyTransferAmountCents ? (
                <Text>
                  {formattedPartnerName} sends your {formattedTransferAmount}{" "}
                  money transfer
                </Text>
              ) : (
                <Text>your deal with {formattedPartnerName} takes effect</Text>
              )}
              . Our team will be in touch shortly to guide you through the
              process.
            </Text>
          }
        />
      );
    case "waitingArtistDirectEarnings":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Once {formattedArtistName} directs earnings to indify for all
              content in this deal,{" "}
              {offer.moneyTransferAmountCents ? (
                <Text>
                  you can send your {formattedTransferAmount} money transfer
                </Text>
              ) : (
                <Text>your deal will take effect</Text>
              )}
              .
            </Text>
          }
        />
      );
    case "actionArtistConnectStripe": {
      const stripeConnectUrl = useStripeConnectUrl(
        offer.transferRecipient.user.id
      );

      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Connect your bank account to Stripe to receive your{" "}
              {formattedTransferAmount} money transfer.
            </Text>
          }
        >
          <Button
            onClick={() => (window.location.href = stripeConnectUrl)}
            variant={"primary"}
            size={"medium"}
            disabled={false}
          >
            Connect with Stripe
          </Button>
        </OfferNextStepDisplay>
      );
    }
    case "waitingArtistConnectStripe":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Once {formattedArtistName} connects their bank account to Stripe,
              they will receive your {formattedTransferAmount} money transfer.
            </Text>
          }
        />
      );

    case "actionPartnerSendTransfer":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Send your {formattedTransferAmount} money transfer to complete
              this deal.
            </Text>
          }
        >
          <ExternalLink href={offer.stripeInvoiceUrl} target="_blank">
            <Button variant="primary" size="medium">
              <IconLabel Icon={Ic24OpenInNew} iconSide="right">
                Continue to send transfer
              </IconLabel>
            </Button>
          </ExternalLink>
        </OfferNextStepDisplay>
      );
    case "waitingPartnerSendTransfer":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              Once {formattedPartnerName} completes your{" "}
              {formattedTransferAmount} money transfer, it will be available in
              your Stripe account.
            </Text>
          }
        />
      );
    case "waitingIndifyCreateInvoice":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>
              indify is setting up your {formattedTransferAmount} money
              transfer.
            </Text>
          }
        />
      );
    case "contactIndify":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>Please contact indify to complete this deal.</Text>
          }
        />
      );
    case "active":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={
            <Text>Congratulations! This deal is now in effect.</Text>
          }
        />
      );
    case "voided":
      return (
        <OfferNextStepDisplay
          badgeType={status}
          headerCaption={<Text>This deal is no longer in effect.</Text>}
        />
      );
  }
}

export default OfferNextStep;
