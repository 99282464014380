/**
 * @generated SignedSource<<9ceb59e7583f0f527f7ee81c5c4237f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleasesInfoRemovable_release$data = {
  readonly __typename: "UnreleasedReleaseNode";
  readonly id: string;
  readonly name: string;
  readonly soundLink: string | null;
  readonly thumbnailUrl: string | null;
  readonly tracks?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly soundRecording: {
          readonly id: string;
          readonly isrc: string;
        } | null;
        readonly trackNumber: number;
      } | null;
    } | null>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseDetails_release">;
  readonly " $fragmentType": "ReleasesInfoRemovable_release";
};
export type ReleasesInfoRemovable_release$key = {
  readonly " $data"?: ReleasesInfoRemovable_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleasesInfoRemovable_release">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleasesInfoRemovable_release",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "soundLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/)
      ],
      "type": "UnreleasedReleaseNode",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TrackNodeConnection",
          "kind": "LinkedField",
          "name": "tracks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TrackNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TrackNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "trackNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SoundRecordingNode",
                      "kind": "LinkedField",
                      "name": "soundRecording",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isrc",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ReleaseNode",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseDetails_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};
})();

(node as any).hash = "9bcfb5e20b8646840ec70bc966c127ae";

export default node;
