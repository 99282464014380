import * as Sentry from "@sentry/react";

import { history } from "../pages/indify/Routing/Routing";

export function initSentry(): void {
  const environment = SENTRY_ENVIRONMENT;
  const project = "indify";
  const release = `${project}@${GIT_COMMITHASH}`;
  const integrations = [
    ...Sentry.defaultIntegrations,
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost:8000",
        "staging.indify.io",
        "app.indify.io",
        /^\//
      ],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ];
  const tracesSampleRate = 1.0;

  return Sentry.init({
    dsn: SENTRY_DSN,
    environment,
    release,
    integrations,
    tracesSampleRate
  });
}
