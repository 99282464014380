import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box } from "theme-ui";

import FieldBuilder from "../../../../../../components/01_Core/Forms/FieldBuilder";
import DollarInput from "../../../../../../components/01_Core/Forms/Inputs/DollarInput";
import { rvMap } from "../../../../../../utils/responsiveUtils";
import { IFundingAdvance } from "../../../07_Deal_Flow/OfferFormFunding";
import { FormSectionSize } from "../FormSection";

export function OfferFormAdvanceField(props: {
  size: ResponsiveValue<FormSectionSize>;
  label: string;
  labelTooltip?: string;
  caption: string;
  fieldName: keyof IFundingAdvance;
  validator: (value?: number) => any;
  placeholder: string;
  disabled?: boolean;
}): ReactElement {
  return (
    <Box mr={rvMap(props.size, getInputContainerMr)} sx={{ width: "100%" }}>
      <FieldBuilder
        fieldName={props.fieldName}
        inputField={DollarInput}
        validator={props.validator}
        inputFieldProps={{
          ...props
        }}
        suppressValidationText={true}
        sx={{ justifyContent: "flex-end" }}
      />
    </Box>
  );
}

function getInputContainerMr(size: FormSectionSize) {
  switch (size) {
    case "small":
      return 0;
    case "medium":
      return "12px";
  }
}
