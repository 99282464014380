/**
 * @generated SignedSource<<c7949e1ef0d3e9aae501666376773ac3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AcceptOfferDialog_chat$data = {
  readonly id: string;
  readonly " $fragmentType": "AcceptOfferDialog_chat";
};
export type AcceptOfferDialog_chat$key = {
  readonly " $data"?: AcceptOfferDialog_chat$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferDialog_chat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptOfferDialog_chat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};

(node as any).hash = "7f719685b175ac4f4309ca9dea7770d1";

export default node;
