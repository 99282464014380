/**
 * @generated SignedSource<<c0c19df4b25ca00a140427fef4eaf336>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useToggleCheckActionItem_actionItem$data = {
  readonly checked: boolean | null;
  readonly id: string;
  readonly " $fragmentType": "useToggleCheckActionItem_actionItem";
};
export type useToggleCheckActionItem_actionItem$key = {
  readonly " $data"?: useToggleCheckActionItem_actionItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"useToggleCheckActionItem_actionItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useToggleCheckActionItem_actionItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checked",
      "storageKey": null
    }
  ],
  "type": "ActionItemNode",
  "abstractKey": null
};

(node as any).hash = "8072cd004696a02a3b1411f9d9fac187";

export default node;
