/**
 * @generated SignedSource<<ae256269edba6b8005562b214921189c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RowDeal_featuredDeal$data = {
  readonly artist: {
    readonly displayName: string;
    readonly id: string;
    readonly isWatchingCurrentUserCompany: boolean | null;
    readonly latestSpotifyMonthlyListenerCount: {
      readonly current: number;
      readonly percentChange: number | null;
    } | null;
    readonly latestTiktokFollowerCount: {
      readonly current: number;
      readonly percentChange: number | null;
    } | null;
    readonly spotifyProfile: {
      readonly url: string | null;
    } | null;
    readonly tiktokProfile: {
      readonly url: string | null;
    } | null;
  };
  readonly editorialNote: string | null;
  readonly id: string;
  readonly lastApprovedAt: any | null;
  readonly soundLink: string | null;
  readonly tiktokSounds: {
    readonly " $fragmentSpreads": FragmentRefs<"RowDeal_useTikTokSoundsTotal">;
  };
  readonly watching: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"OfferDealArtistReleases_query" | "RowDeal_dropdown">;
  readonly " $fragmentType": "RowDeal_featuredDeal";
};
export type RowDeal_featuredDeal$key = {
  readonly " $data"?: RowDeal_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"RowDeal_featuredDeal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "current",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentChange",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RowDeal_featuredDeal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RowDeal_dropdown"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferDealArtistReleases_query"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatWithDelta",
          "kind": "LinkedField",
          "name": "latestSpotifyMonthlyListenerCount",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isWatchingCurrentUserCompany",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "spotifyProfile",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatWithDelta",
          "kind": "LinkedField",
          "name": "latestTiktokFollowerCount",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "tiktokProfile",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastApprovedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watching",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "soundLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editorialNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TikTokSoundNodeConnection",
      "kind": "LinkedField",
      "name": "tiktokSounds",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RowDeal_useTikTokSoundsTotal"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};
})();

(node as any).hash = "4cb21178b76b0b79c9b9df2b07d88c26";

export default node;
