import { FundDirectoryPaginationQuery } from "__generated__/FundDirectoryPaginationQuery.graphql";
import { FundDirectoryQuery as FundDirectoryQueryType } from "__generated__/FundDirectoryQuery.graphql";
import { FundDirectoryRows_allCompanies$key } from "__generated__/FundDirectoryRows_allCompanies.graphql";
import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  graphql,
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery
} from "react-relay";
import { Flex, Text } from "theme-ui";

import { Loader } from "../../../../components/01_Core/999_Miscellaneous/Loader";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";
import RowFund from "../03_UI_Kit/Discover/RowFund";
import { FundDirectoryQuery } from "./FundDirectory";
import { IFundsFilterForm } from "./FundDirectoryFilters";

interface IFundDirectoryRowsProps {
  displayName_Icontains: string;
  onlyWatching: boolean;
  queryRef: PreloadedQuery<FundDirectoryQueryType>;
}

function FundDirectoryRows(props: IFundDirectoryRowsProps): ReactElement {
  const root = usePreloadedQuery<FundDirectoryQueryType>(
    FundDirectoryQuery,
    props.queryRef
  );

  const {
    data: refetchableData,
    loadNext,
    hasNext,
    refetch
  } = usePaginationFragment<
    FundDirectoryPaginationQuery,
    FundDirectoryRows_allCompanies$key
  >(
    graphql`
      fragment FundDirectoryRows_allCompanies on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        orderBy: { type: "CompanyOrdering", defaultValue: total_streams_desc }
        displayName_Icontains: { type: "String", defaultValue: "" }
        onlyWatching: { type: "Boolean", defaultValue: false }
        onlyWithPartnerships: { type: "Boolean" }
        onlyWithTestimonials: { type: "Boolean" }
        services: { type: "[CompanyServiceFilter]" }
        genres: { type: "[ID]" }
      )
      @refetchable(queryName: "FundDirectoryPaginationQuery") {
        allCompanies(
          first: $count
          after: $cursor
          orderBy: $orderBy
          displayName_Icontains: $displayName_Icontains
          onlyWatching: $onlyWatching
          onlyWithPartnerships: $onlyWithPartnerships
          onlyWithTestimonials: $onlyWithTestimonials
          services: $services
          genres: $genres
        ) @connection(key: "FundDirectory_allCompanies") {
          edges {
            node {
              id
              ...RowFund_fund
            }
          }
        }
      }
    `,
    root
  );

  const filterSettings = useFormState<IFundsFilterForm>().values;
  const filterValues = {
    orderBy: filterSettings.orderBy?.value,
    services: filterSettings.services?.map(s => s.value),
    genres: filterSettings.genres?.map(g => g.label),
    onlyWithPartnerships: filterSettings.partnerships,
    onlyWithTestimonials: filterSettings.testimonials
  };

  const fetchProps = { ...filterValues, ...props };

  useDidUpdateEffect(() => {
    refetch(fetchProps);
  }, [filterSettings, props.displayName_Icontains]);

  useDidUpdateEffect(() => {
    refetch(fetchProps, {
      // If a user has watched a company since last fetch, they won't
      // see it in the list unless we force a network refetch
      fetchPolicy: "network-only"
    });
  }, [props.onlyWatching]);

  const companies = refetchableData.allCompanies.edges.map(e => e.node);

  return (
    <InfiniteScroll
      next={() => loadNext(25)}
      hasMore={hasNext}
      loader={
        <Flex
          my={"24px"}
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </Flex>
      }
      dataLength={companies.length}
      endMessage={
        <Flex
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginY: "32px"
          }}
        >
          <Text variant={"bodyMedium"} color={"deepGray100"}>
            {companies.length
              ? "You've reached the end of the list."
              : "No companies found. Try adjusting your filters."}
          </Text>
        </Flex>
      }
      style={{ overflow: "hidden" }}
    >
      {companies.map(fund => {
        return (
          <RowFund
            key={fund.id}
            fund={fund}
            size={["small", "medium", "large", "extraLarge"]}
          />
        );
      })}
    </InfiniteScroll>
  );
}

export default FundDirectoryRows;
