import { Title_artist$key } from "__generated__/Title_artist.graphql";
import { Title_releases$key } from "__generated__/Title_releases.graphql";
import { Fragment, ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import { useReleaseMetadata } from "../../../../../components/01_Core/Releases/useReleaseMetadata";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { lineClampStyles } from "../../../../../utils/utils";
import { useAuth } from "../../../hooks/useAuth";
import { getFeaturedDealTitle } from "../../../hooks/useFeaturedDealTitle";
import ConditionalLink from "../../../Routing/ConditionalLink";
import { InternalRoutes } from "../../../Routing/InternalRoutes";

type TitleSize = "small" | "large";

interface ITitleProps {
  size: ResponsiveValue<TitleSize>;
  artist: Title_artist$key;
  releases: Title_releases$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
function Title(props: ITitleProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const artist = useFragment(
    graphql`
      fragment Title_artist on ArtistNode {
        slug
        displayName
      }
    `,
    props.artist
  );
  const releases = useFragment(
    graphql`
      fragment Title_releases on ReleaseInterface @relay(plural: true) {
        name
        releaseType
        ...useReleaseMetadata_release
      }
    `,
    props.releases
  );

  const firstReleaseMetadata = useReleaseMetadata(releases[0]);

  const authUser = useAuth().authUser;
  const userIsPartner = authUser?.userType === "partner";

  return (
    // Ideally we don't want to hide all text that overflows but this is
    // a quick solution to prevent the page from side-scrolling when the title
    // contains a long word that can't naturally wrap to a new line
    <Flex sx={{ flexDirection: "column", overflowX: "hidden" }}>
      <ConditionalLink
        to={generatePath(InternalRoutes.artistProfile, {
          artistSlug: artist.slug
        })}
        disabled={!userIsPartner}
      >
        <Text
          variant={activeSize === "small" ? "h700" : "h900"}
          color={"deepGray100"}
          title={artist.displayName}
          sx={{
            ...lineClampStyles(1),
            paddingBottom: "0.15em",
            textDecoration: "none",
            ":hover": { textDecoration: "underline" }
          }}
        >
          {artist.displayName}
        </Text>
      </ConditionalLink>
      <Text
        variant={activeSize === "small" ? "h700" : "h900"}
        color={"black100"}
        title={getFeaturedDealTitle(releases, false).join("")}
        sx={{ ...lineClampStyles(3), lineHeight: "normal" }}
      >
        {getFeaturedDealTitle(releases).map((node, i) => (
          <Fragment key={i}>{node}</Fragment>
        ))}
      </Text>
      {releases.length === 1 && (
        <Text
          variant={activeSize === "small" ? "h100" : "h300"}
          color={"black100"}
        >
          {firstReleaseMetadata}
        </Text>
      )}
    </Flex>
  );
}

export default Title;
