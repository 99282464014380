import { ReleasesGrid_artist$key } from "__generated__/ReleasesGrid_artist.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import ReleaseCard from "../03_UI_Kit/Releases/ReleaseCard";
import TeamCard from "../03_UI_Kit/User_Profile/TeamCard";
import { ReleasesSort } from "./ArtistProfile";
import ProfileCardsGrid from "./ProfileCardsGrid";

interface IReleasesGridProps {
  artist: ReleasesGrid_artist$key;
  orderBy: ReleasesSort;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=672%3A2876
 */
function ReleasesGrid(props: IReleasesGridProps): ReactElement {
  const artist = useFragment(
    graphql`
      fragment ReleasesGrid_artist on ArtistNode {
        offers(isAccepted: true) {
          edges {
            node {
              __typename
            }
          }
        }
        releases(isVisible: true) {
          edges {
            node {
              __typename
              id
              upc
              releaseDate
              ...ReleaseCard_release
            }
          }
        }
        unreleasedReleases(isVisible: true) {
          edges {
            node {
              __typename
              id
              updatedAt
              ...ReleaseCard_release
            }
          }
        }
        ...TeamCard_artist
      }
    `,
    props.artist
  );
  let releases = _.uniqBy(
    _.sortBy(
      [
        ...artist.releases.edges.map(e => e.node),
        ...artist.unreleasedReleases.edges.map(e => e.node)
      ],
      r => {
        const isReleased = r.__typename === "ReleaseNode";
        const date = isReleased ? r.releaseDate : r.updatedAt;

        const epoch = new Date(date).valueOf();
        return [isReleased ? 0 : 1, epoch];
      }
    ),
    r => (r.__typename === "ReleaseNode" ? r.upc : r.id)
  );
  if (props.orderBy === "desc") {
    releases = releases.reverse();
  }
  return (
    <ProfileCardsGrid>
      {_.compact([
        artist.offers.edges.length > 0 && (
          <GridItem
            key={"team"}
            gridColumn={"-2"}
            gridRow={"1"}
            sx={{ justifyContent: "center" }}
          >
            <TeamCard
              artist={artist}
              size={["small", "small", "small", "large"]}
            />
          </GridItem>
        ),
        ...releases.map(release => {
          return (
            <GridItem
              key={release.id}
              gridColumn={"span 1"}
              sx={{ justifyContent: "center" }}
            >
              <ReleaseCard
                release={release}
                size={["small", "small", "small", "large"]}
              />
            </GridItem>
          );
        })
      ])}
    </ProfileCardsGrid>
  );
}

export default ReleasesGrid;
