import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Link as ExternalLink } from "theme-ui";

import Button, {
  ButtonSize
} from "../../../../components/01_Core/Buttons/Button";
import { zIndices } from "../../../../theme/theme";
import { ExternalRoutes } from "../../Routing/ExternalRoutes";

interface IHomebaseFeedbackProps {
  size: ResponsiveValue<ButtonSize>;
}

function HomebaseFeedback(props: IHomebaseFeedbackProps): ReactElement {
  return (
    <ExternalLink href={ExternalRoutes.i4aFeedback} target={"_blank"}>
      <Button
        size={props.size}
        variant={"tertiary"}
        disabled={false}
        sx={{
          position: "fixed",
          bottom: 24,
          right: 24,
          boxShadow: "0px 4px 16px rgba(27, 27, 27, 0.16)",
          zIndex: zIndices.switch
        }}
      >
        Give Feedback
      </Button>
    </ExternalLink>
  );
}

export default HomebaseFeedback;
