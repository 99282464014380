/**
 * @generated SignedSource<<0a2639be07f564fe97e521fed2e04277>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMessage_actor$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"useMessage_user">;
  } | null;
  readonly " $fragmentType": "useMessage_actor";
};
export type useMessage_actor$key = {
  readonly " $data"?: useMessage_actor$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMessage_actor">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMessage_actor",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useMessage_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActorInterface",
  "abstractKey": "__isActorInterface"
};

(node as any).hash = "5eb21745fe6dca17a734e92f19604d82";

export default node;
