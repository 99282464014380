/**
 * @generated SignedSource<<e6974a1e1ebbe5d86170b3dfdc078ee7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebaseGraphs_homebase$data = {
  readonly instagramFollowers: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly spotifyFollowers: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly tiktokFollowers: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly weeklyStreams: {
    readonly timeseries: ReadonlyArray<{
      readonly x: any | null;
      readonly y: number | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "HomebaseGraphs_homebase";
};
export type HomebaseGraphs_homebase$key = {
  readonly " $data"?: HomebaseGraphs_homebase$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebaseGraphs_homebase">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "HomebaseTimeseriesNode",
    "kind": "LinkedField",
    "name": "timeseries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "x",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "y",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebaseGraphs_homebase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "instagramFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "tiktokFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "weeklyStreams",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "spotifyFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HomebaseNode",
  "abstractKey": null
};
})();

(node as any).hash = "c15750767b68a8e1e8cf2c5954a8975b";

export default node;
