import { ReleaseDetails_release$key } from "__generated__/ReleaseDetails_release.graphql";
import { UpdateOrCreateReleaseMutationInput } from "__generated__/UpdateOrCreateReleaseModal_Mutation.graphql";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import { useFindActiveRV } from "../../../utils/responsiveUtils";
import AutoLayout, { FillContainer } from "../AutoLayout";
import {
  formatReleaseMetadata,
  useReleaseMetadata
} from "./useReleaseMetadata";

export type ReleaseDetailsSize = "small" | "medium" | "large" | "extraLarge";

interface IReleaseDetailsProps {
  // `release` is nullable so that users can add a new upcoming release (that is not yet in the DB) during the deal creation flow, in which case `newUnreleasedRelease` will be present
  release?: ReleaseDetails_release$key;
  newUnreleasedRelease?: UpdateOrCreateReleaseMutationInput;
  size: ResponsiveValue<ReleaseDetailsSize>;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2388%3A11175
 */
export default function ReleaseDetails(
  props: IReleaseDetailsProps
): ReactElement {
  const release = useFragment(
    graphql`
      fragment ReleaseDetails_release on ReleaseInterface {
        isVisible
        name
        ...useReleaseMetadata_release
      }
    `,
    props.release
  );
  const releaseMetadata = release
    ? useReleaseMetadata(release)
    : formatReleaseMetadata(props.newUnreleasedRelease);
  const titleTextVariant = useFindActiveRV(props.size, getTitleTextVariant);

  // If we have `unreleasedTitle` instead of a `release`, treat that as a visible release
  const releaseIsVisible = props.newUnreleasedRelease || release?.isVisible;

  return (
    <AutoLayout
      spacing={4}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical"
      }}
    >
      <Text
        variant={titleTextVariant}
        color={releaseIsVisible ? "black100" : "deepGray70"}
      >
        {props.release ? release.name : props.newUnreleasedRelease.name}
      </Text>
      <Text
        variant={"bodySmall"}
        color={releaseIsVisible ? "deepGray100" : "deepGray70"}
      >
        {releaseMetadata}
      </Text>
    </AutoLayout>
  );
}

export function getTitleTextVariant(size: ReleaseDetailsSize): string {
  switch (size) {
    case "small":
      return "bodyMedium";
    case "medium":
      return "bodyLarge";
    case "large":
      return "subtitle";
    case "extraLarge":
      return "h500";
  }
}
