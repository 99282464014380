import { mutationsOfferDeclineOfferMutation } from "__generated__/mutationsOfferDeclineOfferMutation.graphql";
import { OfferMessageCTAArtist_chat$key } from "__generated__/OfferMessageCTAArtist_chat.graphql";
import { OfferMessageCTAArtist_offer$key } from "__generated__/OfferMessageCTAArtist_offer.graphql";
import { Fragment, ReactElement, useState } from "react";
import {
  commitMutation,
  ConnectionHandler,
  graphql,
  useFragment
} from "react-relay";
import { Link, Text } from "theme-ui";

import Ic20FileEdit from "../../../../../../../imgs/icons/ic20-file-edit.svg";
import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import Button from "../../../../../../components/01_Core/Buttons/Button";
import environment from "../../../../../../environment";
import { declineOfferMutation } from "../../../../../../mutations/mutations";
import theme from "../../../../../../theme/theme";
import AcceptOfferDialog from "../../../07_Deal_Flow/AcceptOfferDialog";

interface IOfferMessageCTAArtistProps {
  offer: OfferMessageCTAArtist_offer$key & { status: "needs_artist_response" };
  chat: OfferMessageCTAArtist_chat$key;
}

function OfferMessageCTAArtist(
  props: IOfferMessageCTAArtistProps
): ReactElement {
  const offer = useFragment(
    graphql`
      fragment OfferMessageCTAArtist_offer on OfferNode {
        id
        status
        ...AcceptOfferDialog_offer
      }
    `,
    props.offer
  );

  const chat = useFragment(
    graphql`
      fragment OfferMessageCTAArtist_chat on ChatNode {
        id
        ...AcceptOfferDialog_chat
      }
    `,
    props.chat
  );

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const openAcceptModal = () => setIsAcceptModalOpen(true);
  const closeAcceptModal = () => setIsAcceptModalOpen(false);

  const messagesConnectionId = ConnectionHandler.getConnectionID(
    chat.id,
    "ChatThread_messages"
  );

  const declineOffer = () => {
    const variables = {
      data: {
        chatNodeId: chat.id,
        offerNodeId: offer.id
      },
      connections: [messagesConnectionId]
    };
    commitMutation<mutationsOfferDeclineOfferMutation>(environment, {
      mutation: declineOfferMutation,
      variables
    });
  };

  return (
    <Fragment>
      <AcceptOfferDialog
        isAcceptModalOpen={isAcceptModalOpen}
        closeAcceptModal={closeAcceptModal}
        offer={offer}
        chat={chat}
      />
      <AutoLayout
        spacing={24}
        dependentProps={{ direction: "vertical", alignment: "center" }}
      >
        <Button
          variant={"primary"}
          size={"small"}
          disabled={false}
          onClick={openAcceptModal}
          iconProps={{
            placement: "left",
            icon: <Ic20FileEdit color={theme.colors.white100 as string} />
          }}
          sx={{ width: "100%" }}
        >
          Continue to accept
        </Button>
        <Text variant={"primary.bodySmall"} sx={{ textAlign: "center" }}>
          You can{" "}
          <Link
            onClick={declineOffer}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            decline this offer
          </Link>{" "}
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          at any time if you don't want to accept it.
        </Text>
      </AutoLayout>
    </Fragment>
  );
}

export default OfferMessageCTAArtist;
