import assert from "assert";
import { ReactElement, useEffect } from "react";
import { BehaviorSubject, Subject } from "rxjs";

import useBehaviorSubjectState from "../../hooks/useBehaviorSubjectState";
import { INavState, NavigationRequest } from "./ClaimYourAccountFlow";
import Page, { Pages } from "./Pages/Page";

interface ISignupFlowSwitchProps {
  navState: BehaviorSubject<INavState>;
  navRequests: Subject<NavigationRequest>;
  pages: Pages[];
}

function SignupFlowSwitch(props: ISignupFlowSwitchProps): ReactElement {
  const { current } = useBehaviorSubjectState(props.navState);
  const section = props.pages[current];

  useEffect(() => {
    const { current, page } = props.navState.getValue();
    props.navState.next({
      current,
      page: page,
      total: props.pages.length
    });
  }, [props.pages]);

  useEffect(() => {
    const sub = props.navRequests.subscribe({
      next: navRequest => {
        const { current, total } = props.navState.getValue();
        switch (navRequest) {
          case "continue": {
            const next = current + 1;
            assert(next < total);
            props.navState.next({
              current: next,
              page: props.pages[next],
              total
            });
            return;
          }
          case "back": {
            const previous = current - 1;
            assert(current > 0);
            props.navState.next({
              current: previous,
              page: props.pages[previous],
              total
            });
            return;
          }
        }
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return <Page page={section} />;
}

export default SignupFlowSwitch;
