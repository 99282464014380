import { ReactElement } from "react";
import { Box, BoxProps, Theme } from "theme-ui";

type LabelDotColor = "blue" | "gray" | "green" | "orange" | "red";
interface ILabelDotProps extends BoxProps {
  color: LabelDotColor;
}

function LabelDot(props: ILabelDotProps): ReactElement {
  const { color: propsColor, ...boxProps } = props;

  // Color names sourced from https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=0%3A18334
  const getThemeColor = (color: LabelDotColor, theme: Theme) => {
    switch (color) {
      case "blue":
        return theme.colors.primary85;
      case "gray":
        return theme.colors.deepGray100;
      case "green":
        return theme.colors.success50;
      case "orange":
        return theme.colors.warning50;
      case "red":
        return theme.colors.alert50;
    }
  };

  return (
    <Box
      backgroundColor={theme => getThemeColor(propsColor, theme)}
      sx={{ borderRadius: "100%", width: "8px", height: "8px", flexShrink: 0 }}
      {...boxProps}
    />
  );
}

export default LabelDot;
