/**
 * @generated SignedSource<<6ab4080a4e9ddd00ef6c70c7b424651b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseSelector_unreleasedReleases$data = ReadonlyArray<{
  readonly __typename: "UnreleasedReleaseNode";
  readonly createdAt: any;
  readonly hasExistingInvestmentPartnership: boolean | null;
  readonly hasExistingPartnership: boolean | null;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"Release_release">;
  readonly " $fragmentType": "ReleaseSelector_unreleasedReleases";
}>;
export type ReleaseSelector_unreleasedReleases$key = ReadonlyArray<{
  readonly " $data"?: ReleaseSelector_unreleasedReleases$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseSelector_unreleasedReleases">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ReleaseSelector_unreleasedReleases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExistingPartnership",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExistingInvestmentPartnership",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Release_release"
    }
  ],
  "type": "UnreleasedReleaseNode",
  "abstractKey": null
};

(node as any).hash = "37171f74bd1af3e6cdacc9fcc6929679";

export default node;
