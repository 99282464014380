/**
 * @generated SignedSource<<956d5da0b9af93e0a78b3390b374ab49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundTestimonial_companytestimonial$data = {
  readonly artist: {
    readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  };
  readonly createdAt: any;
  readonly testimonial: string;
  readonly " $fragmentType": "FundTestimonial_companytestimonial";
};
export type FundTestimonial_companytestimonial$key = {
  readonly " $data"?: FundTestimonial_companytestimonial$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundTestimonial_companytestimonial">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundTestimonial_companytestimonial",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testimonial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarName_actor"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CompanyTestimonialNode",
  "abstractKey": null
};

(node as any).hash = "ac01f0fb7e28ff794609a04fdbe99470";

export default node;
