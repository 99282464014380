import { ReactElement } from "react";
import { useRouteMatch } from "react-router-dom";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import InternalLink from "../../../../../../../utils/InternalLink";
import { InternalRoutes } from "../../../../../Routing/InternalRoutes";

interface IBaseProps {
  route: (typeof InternalRoutes)[keyof typeof InternalRoutes];
  badge?: string;
  disabled?: boolean;
  children: string;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=212%3A2077
 */
function Base(props: IBaseProps): ReactElement {
  const routeMatch = useRouteMatch(props.route);

  const borderStyles = {
    borderBottom: "2px solid",
    borderBottomColor: "black100",
    transition: "border-bottom-color 0.2s",
    marginBottom: "-2px"
  };

  const borderBottomActive = {
    ...borderStyles
  };

  const borderBottomNotActive = {
    ...borderStyles,
    borderBottomColor: "transparent",
    ":hover": borderStyles
  };

  const borderBottomStyle =
    routeMatch && routeMatch.isExact
      ? borderBottomActive
      : borderBottomNotActive;

  const label = (
    <AutoLayout
      spacing={4}
      dependentProps={{ direction: "horizontal", alignment: "center" }}
      resizingY={FillContainer()}
      sx={!props.disabled && borderBottomStyle}
    >
      <Text variant={"bodyMedium"}>
        <strong>{props.children}</strong>
      </Text>
      {props.badge && (
        <SubtleBadge size={"small"} color={"orange"}>
          <strong>{props.badge}</strong>
        </SubtleBadge>
      )}
    </AutoLayout>
  );

  return (
    <InternalLink
      to={props.route}
      style={{ height: "100%" }}
      disabled={props.disabled}
    >
      {label}
    </InternalLink>
  );
}

export default Base;
