import { FeaturedDealTheSound_featuredDeal$key } from "__generated__/FeaturedDealTheSound_featuredDeal.graphql";
import { Fragment, ReactElement } from "react";
import { Helmet } from "react-helmet";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";

type FeaturedDealTheSoundSize = "small" | "large";

interface IFeaturedDealTheSoundProps {
  size: ResponsiveValue<FeaturedDealTheSoundSize>;
  featuredDeal: FeaturedDealTheSound_featuredDeal$key;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=524%3A16258
 */
function FeaturedDealTheSound(props: IFeaturedDealTheSoundProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const featuredDeal = useFragment(
    graphql`
      fragment FeaturedDealTheSound_featuredDeal on FeaturedDealNode {
        embedInfo
      }
    `,
    props.featuredDeal
  );
  const {
    src,
    platform,
    sound_id: soundId
  } = JSON.parse(featuredDeal.embedInfo);

  return (
    <Fragment>
      <Flex
        sx={{
          backgroundColor: "black100",
          paddingY: activeSize === "large" ? "96px" : "48px",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <FixedGrid>
          <GridItem gridColumn={["1 / -1", "1 / -1", "1 / -1", "3 / span 8"]}>
            <AutoLayout
              spacing={32}
              resizingX={FillContainer()}
              dependentProps={{ direction: "vertical", alignment: "topCenter" }}
            >
              <Text
                variant={activeSize === "large" ? "h700" : "h500"}
                color={"white100"}
              >
                The Sound
              </Text>
              {platform === "tiktok" ? (
                <blockquote
                  className="tiktok-embed"
                  cite={src}
                  data-video-id={soundId}
                >
                  <section />
                </blockquote>
              ) : (
                <iframe
                  src={src}
                  width="100%"
                  height="380"
                  frameBorder="0"
                  allow="encrypted-media"
                />
              )}
            </AutoLayout>
          </GridItem>
        </FixedGrid>
      </Flex>
      {platform === "tiktok" && (
        <Helmet>
          <script async src="https://www.tiktok.com/embed.js" />
        </Helmet>
      )}
    </Fragment>
  );
}

export default FeaturedDealTheSound;
