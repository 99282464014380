import { ReactElement } from "react";

import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";

function Location(): ReactElement {
  return (
    <FieldBuilder
      fieldName={"location"}
      inputField={TextInput}
      inputFieldProps={{
        size: ["medium", "large", "large", "large"],
        label: "Location",
        placeholder: "City, Country"
      }}
    />
  );
}

export default Location;
