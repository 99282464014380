/**
 * @generated SignedSource<<0b958ea52f0591f4278344d5a7871ead>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SectionCompanyLookupQuery$variables = {
  needle: string;
};
export type SectionCompanyLookupQuery$data = {
  readonly allCompanies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly displayName: string;
        readonly id: string;
        readonly thumbnailUrl: string;
      } | null;
    } | null>;
  } | null;
};
export type SectionCompanyLookupQuery = {
  response: SectionCompanyLookupQuery$data;
  variables: SectionCompanyLookupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "needle"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "displayName_Icontains",
        "variableName": "needle"
      }
    ],
    "concreteType": "CompanyNodeConnection",
    "kind": "LinkedField",
    "name": "allCompanies",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SectionCompanyLookupQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SectionCompanyLookupQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "77917bed479eb92a3f12ab5a8bfe5b7f",
    "id": null,
    "metadata": {},
    "name": "SectionCompanyLookupQuery",
    "operationKind": "query",
    "text": "query SectionCompanyLookupQuery(\n  $needle: String!\n) {\n  allCompanies(displayName_Icontains: $needle) {\n    edges {\n      node {\n        id\n        displayName\n        thumbnailUrl\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e08c7f66a811e6dbc672bd4445b17a6";

export default node;
