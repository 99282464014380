import { forwardRef, MutableRefObject, ReactElement } from "react";
import { FieldRenderProps } from "react-final-form";
import ReactSelect from "react-select";
import AsyncReactSelect, { AsyncProps } from "react-select/async";
import AsyncCreatableReactSelect from "react-select/async-creatable";
import CreatableReactSelect from "react-select/creatable";
import { SelectComponentsConfig } from "react-select/src/components";
import { StylesConfig } from "react-select/src/styles";
import { Box } from "theme-ui";

import { rvMap, useFindActiveRV } from "../../../../utils/responsiveUtils";
import { Loader } from "../../999_Miscellaneous/Loader";
import AutoLayout from "../../AutoLayout";
import {
  getFontSize,
  getInputBorderColor,
  getInputHeight,
  getInputHoverBorderColor,
  getInputSpacing,
  IBaseRenderProps,
  InputCaption,
  InputLabel,
  InputState
} from "../Input";

interface ISelectInputProps<OptionType>
  extends FieldRenderProps<OptionType>,
    IBaseRenderProps {
  asyncProps?: AsyncProps<OptionType>;
  components?: SelectComponentsConfig<OptionType, false>;
  isCreatable?: boolean;
  isSearchable?: boolean;
}

/**
 * Select input component
 * A wrapper for ReactSelect's components <Select>, <AsyncSelect>, and <AsyncCreatableSelect>.
 * Requires final-form FieldRenderProps (input & meta). Applies style overrides.
 *
 * figma:
 * - https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1280%3A20
 * - https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1280%3A21
 * - https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1280%3A22
 * - https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1280%3A23
 */

function SelectInput<OptionType>(
  props: ISelectInputProps<OptionType>,
  ref: MutableRefObject<any>
): ReactElement {
  const {
    input,
    meta: _meta,
    size,
    label,
    caption,
    state,
    asyncProps,
    components,
    isCreatable,
    isSearchable,
    ...selectProps
  } = props;

  const LoadingIndicator = () => (
    <Loader mx={"10px"} size={["medium", "medium", "large", "large"]} />
  );

  const selectElementProps = {
    styles: getBaseStyles<OptionType>(size, state, isSearchable),
    components: {
      LoadingIndicator,
      ...components
    },
    value: input.value,
    onChange: input.onChange,
    isSearchable: isSearchable ?? false,
    ref,
    ...asyncProps,
    ...selectProps
  };

  const SelectElement = asyncProps ? (
    isCreatable ? (
      <AsyncCreatableReactSelect<OptionType, false> {...selectElementProps} />
    ) : (
      <AsyncReactSelect<OptionType, false> {...selectElementProps} />
    )
  ) : isCreatable ? (
    <CreatableReactSelect<OptionType, false> {...selectElementProps} />
  ) : (
    <ReactSelect<OptionType, false> {...selectElementProps} />
  );

  return (
    <AutoLayout
      spacing={rvMap(size, getInputSpacing)}
      dependentProps={{ direction: "vertical" }}
      sx={{ width: "inherit" }}
    >
      {label && <InputLabel size={size} label={label} />}
      <Box sx={{ minHeight: rvMap(size, getInputHeight), width: "inherit" }}>
        {SelectElement}
      </Box>
      {caption && state != "alert" && (
        <InputCaption size={size} caption={caption} state={state} />
      )}
    </AutoLayout>
  );
}

// Style overrides for ReactSelect.
// https://react-select.com/styles
export function getBaseStyles<OptionType>(
  size: ISelectInputProps<OptionType>["size"],
  state: InputState,
  isSearchable: boolean
): StylesConfig<OptionType, false> {
  const fontStyles = {
    fontSize: useFindActiveRV(size, getFontSize)
  };

  return {
    control: (provided, controlState) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: "4px",
      borderColor: controlState.isFocused
        ? getInputHoverBorderColor(state)
        : getInputBorderColor(state),
      height: "inherit",
      minHeight: "inherit",
      transition: "unset",
      ":hover": {
        borderColor: controlState.isFocused
          ? getInputHoverBorderColor(state)
          : getInputBorderColor(state)
      },
      ":focus": {
        borderColor: getInputHoverBorderColor(state)
      }
    }),
    container: provided => ({
      ...provided,
      height: "inherit",
      minHeight: "inherit",
      "*": fontStyles
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: useFindActiveRV(size, s => {
        switch (s) {
          case "small":
            return "4px";
          case "medium":
            return "8px";
          case "large":
            return "12px";
        }
      })
    }),
    multiValue: provided => ({
      ...provided,
      padding: "4px 8px",
      borderRadius: "4px",
      cursor: "default",
      position: "relative"
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: useFindActiveRV(size, s => {
        switch (s) {
          case "small":
            return "8px";
          case "medium":
            return "12px";
          case "large":
            return "16px";
        }
      }),
      "*": fontStyles,
      cursor: isSearchable ? "text" : "pointer"
    })
  };
}

export default forwardRef(SelectInput) as <OptionType>(
  props: ISelectInputProps<OptionType> & { ref?: MutableRefObject<any> }
) => ReturnType<typeof SelectInput>;
