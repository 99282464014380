import { GraphQLTaggedNode, useMutation, UseMutationConfig } from "react-relay";
import { MutationParameters } from "relay-runtime";

export default function useMutationPromise<
  MutationType extends MutationParameters
>(
  mutation: GraphQLTaggedNode
): [
  (
    config: UseMutationConfig<MutationType>
  ) => Promise<MutationType["response"]>,
  boolean
] {
  const [commit, isInFlight] = useMutation<MutationType>(mutation);
  const commitPromise = (config: UseMutationConfig<MutationType>) => {
    return new Promise((resolve, onError) => {
      commit({
        onError,
        onCompleted: (data, errors) =>
          errors ? onError(errors) : resolve(data),
        ...config
      });
    });
  };
  return [commitPromise, isInFlight];
}
