import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import splitAfterRecoupmentSchema from "../../../../../../../../schemas/v1-0-0/dealTerms/splitAfterRecoupment.json";
import splitBeforeRecoupmentSchema from "../../../../../../../../schemas/v1-0-0/dealTerms/splitBeforeRecoupment.json";
import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../../components/01_Core/Forms/FieldBuilder";
import { getInputSpacing } from "../../../../../../components/01_Core/Forms/Input";
import PercentInput, {
  percentInputMaxWidth
} from "../../../../../../components/01_Core/Forms/Inputs/PercentInput";
import { useField } from "../../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import useSchemaValidation from "../../../../../../components/01_Core/Forms/utils/useSchemaValidation";
import {
  rvMap,
  useFindActiveRV
} from "../../../../../../utils/responsiveUtils";
import { FormSectionSize } from "../FormSection";
import { useTotalAmountDollars } from "../OfferFormAdvance/AdvanceAggregate";

export interface ISplit {
  partnerPercent: number;
}

export interface ISplitProps {
  povType: "asArtist" | "asPartner";
  recoupmentType: "before" | "after";
  size: ResponsiveValue<FormSectionSize>;
  isRequired: boolean;
}
type FieldNames = "splitAfterRecoupment" | "splitBeforeRecoupment";

function Split(props: ISplitProps): ReactElement {
  const { validationSchema, fieldName } = (() => {
    switch (props.recoupmentType) {
      case "after":
        return {
          validationSchema: splitAfterRecoupmentSchema,
          fieldName: "splitAfterRecoupment" as FieldNames
        };
      case "before":
        return {
          validationSchema: splitBeforeRecoupmentSchema,
          fieldName: "splitBeforeRecoupment" as FieldNames
        };
    }
  })();

  const splitValidator = useSchemaValidation(
    validationSchema,
    props.isRequired
  );
  const validator = (value: ISplit | null) => {
    // If the split is an empty object, convert it to null for validation purposes
    if (value?.partnerPercent == null) value = null;
    return splitValidator(value);
  };
  useField(fieldName, {
    validate: validator,
    subscription: {}
  });

  const labelVariant = useFindActiveRV(props.size, getLabelVariant);

  const hasAdvanceAmount = useTotalAmountDollars() > 0;

  return (
    <AutoLayout
      spacing={rvMap(props.size, getInputSpacing)}
      dependentProps={{ direction: "vertical" }}
      sx={{ width: "100%" }}
    >
      <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
        <Flex sx={{ width: percentInputMaxWidth }}>
          <FieldBuilder
            fieldName={`${fieldName}.partnerPercent`}
            inputField={PercentInput}
            inputFieldProps={{
              size: props.size
            }}
            suppressValidationText={true}
          />
        </Flex>
        <Text variant={labelVariant} color={"black100"} ml={"12px"}>
          {props.povType === "asPartner" ? "to you" : "to your partner"}
        </Text>
      </Flex>
      {hasAdvanceAmount ? (
        <Text variant={"secondary.bodySmall"}>
          How earnings are divided up{" "}
          <Text
            variant={"secondary.bodySmall"}
            sx={{ display: "inline", fontWeight: 700 }}
          >
            {props.recoupmentType === "before" ? "until" : "after"}
          </Text>{" "}
          the initial advances are recovered.
        </Text>
      ) : (
        <Text variant={"secondary.bodySmall"}>
          The percentage of total earnings{" "}
          {props.povType === "asPartner" ? "you" : "your partner"} will receive.
        </Text>
      )}
    </AutoLayout>
  );
}

function getLabelVariant(size: FormSectionSize) {
  switch (size) {
    case "small":
      return "bodySmall";
    case "medium":
      return "bodyMedium";
  }
}

export default Split;
