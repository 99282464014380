/**
 * @generated SignedSource<<9364483f6f874d4f2baa2141aabdc1de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpecialDeal_featuredDeal$data = {
  readonly artist: {
    readonly displayName: string;
    readonly isWatchingCurrentUserCompany: boolean | null;
    readonly latestSpotifyMonthlyListenerCount: {
      readonly current: number;
      readonly percentChange: number | null;
    } | null;
    readonly spotifyProfile: {
      readonly url: string | null;
    } | null;
    readonly user: {
      readonly lastActiveAt: any | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"useArtistThumbnailUrl_artist" | "useMessage_actor">;
  };
  readonly editorialNote: string | null;
  readonly id: string;
  readonly soundLink: string | null;
  readonly tiktokSounds: {
    readonly " $fragmentSpreads": FragmentRefs<"RowDeal_useTikTokSoundsTotal">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseIconsNames_query" | "RowDeal_dropdown">;
  readonly " $fragmentType": "SpecialDeal_featuredDeal";
};
export type SpecialDeal_featuredDeal$key = {
  readonly " $data"?: SpecialDeal_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpecialDeal_featuredDeal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpecialDeal_featuredDeal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RowDeal_dropdown"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "soundLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editorialNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TikTokSoundNodeConnection",
      "kind": "LinkedField",
      "name": "tiktokSounds",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RowDeal_useTikTokSoundsTotal"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useMessage_actor"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useArtistThumbnailUrl_artist"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastActiveAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatWithDelta",
          "kind": "LinkedField",
          "name": "latestSpotifyMonthlyListenerCount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "current",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentChange",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isWatchingCurrentUserCompany",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "spotifyProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseIconsNames_query"
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};

(node as any).hash = "fae853f728990c1ac2c2c1dacfc2fcbe";

export default node;
