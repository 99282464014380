/**
 * @generated SignedSource<<387ccdb51d2f6a5957dc6d5c6f2e1c4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedDealTheSound_featuredDeal$data = {
  readonly embedInfo: any | null;
  readonly " $fragmentType": "FeaturedDealTheSound_featuredDeal";
};
export type FeaturedDealTheSound_featuredDeal$key = {
  readonly " $data"?: FeaturedDealTheSound_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedDealTheSound_featuredDeal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeaturedDealTheSound_featuredDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "embedInfo",
      "storageKey": null
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};

(node as any).hash = "96b3545b17627b8ae7efa291ad5d2a99";

export default node;
