/**
 * @generated SignedSource<<a64130ebc2753108ca92590c0e014b2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useStripePortalRedirectMutation$variables = {
  redirectUrl?: string | null;
};
export type useStripePortalRedirectMutation$data = {
  readonly createStripePortalSession: {
    readonly url: string | null;
  } | null;
};
export type useStripePortalRedirectMutation = {
  response: useStripePortalRedirectMutation$data;
  variables: useStripePortalRedirectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "redirectUrl"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "redirectUrl",
        "variableName": "redirectUrl"
      }
    ],
    "concreteType": "CreateStripePortalSessionMutation",
    "kind": "LinkedField",
    "name": "createStripePortalSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useStripePortalRedirectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useStripePortalRedirectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d7bbed4410f9a55bb38fa8a5912dacd4",
    "id": null,
    "metadata": {},
    "name": "useStripePortalRedirectMutation",
    "operationKind": "mutation",
    "text": "mutation useStripePortalRedirectMutation(\n  $redirectUrl: String\n) {\n  createStripePortalSession(redirectUrl: $redirectUrl) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "b07f4eccf7f8716126d5157592ccd099";

export default node;
