/**
 * @generated SignedSource<<5631ff8bade0588cb4e1bbe175a7a63f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferralAcceptance_referral$data = {
  readonly sender: {
    readonly displayName: string;
    readonly thumbnailUrl: string;
  } | null;
  readonly " $fragmentType": "ReferralAcceptance_referral";
};
export type ReferralAcceptance_referral$key = {
  readonly " $data"?: ReferralAcceptance_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralAcceptance_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralAcceptance_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sender",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};

(node as any).hash = "6b33875775a5e226deac5bfdc4d75acc";

export default node;
