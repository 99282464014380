import {
  ReleaseSelector_releases$data,
  ReleaseSelector_releases$key
} from "__generated__/ReleaseSelector_releases.graphql";
import {
  ReleaseSelector_unreleasedReleases$data,
  ReleaseSelector_unreleasedReleases$key
} from "__generated__/ReleaseSelector_unreleasedReleases.graphql";
import dayjs from "dayjs";
import _ from "lodash";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useFragment } from "react-relay";
import StateManager, { OptionProps } from "react-select";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";

import Release from "../../../../../components/01_Core/Dropdown_Menus/Release";
import { InputSize } from "../../../../../components/01_Core/Forms/Input";
import SelectInput from "../../../../../components/01_Core/Forms/Inputs/SelectInput";
import { useField } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { noop } from "../../../../../utils/utils";
import { IFundingOfferFormValues } from "../../07_Deal_Flow/OfferFormFunding";
import { contentFieldName } from "../Offers/ReleasesIncluded/ReleasesIncluded";

export type ReleaseOption = {
  value: IFundingOfferFormValues["allReleases"][number];
};

interface IReleaseSelectorProps {
  onChange: (releaseOption: ReleaseOption) => void;
  size: ResponsiveValue<InputSize>;
  releases: ReleaseSelector_releases$key;
  unreleasedReleases: ReleaseSelector_unreleasedReleases$key;
  canCreateUnreleasedContent: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2001%3A20127
 */
function ReleaseSelector(props: IReleaseSelectorProps): ReactElement {
  const releases = useFragment(
    graphql`
      fragment ReleaseSelector_releases on ReleaseNode @relay(plural: true) {
        __typename
        id
        name
        releaseDate
        hasExistingPartnership
        hasExistingInvestmentPartnership
        ...Release_release
      }
    `,
    props.releases
  );
  const unreleasedReleases = useFragment(
    graphql`
      fragment ReleaseSelector_unreleasedReleases on UnreleasedReleaseNode
      @relay(plural: true) {
        __typename
        id
        name
        createdAt
        hasExistingPartnership
        hasExistingInvestmentPartnership
        ...Release_release
      }
    `,
    props.unreleasedReleases
  );

  const inputValue =
    useField<IFundingOfferFormValues["allReleases"]>(contentFieldName).input
      .value;
  const selectedIds = inputValue.map(release => release.id).filter(Boolean);
  const isReleaseSelected = (
    release:
      | ReleaseSelector_releases$data[number]
      | ReleaseSelector_unreleasedReleases$data[number]
  ) => {
    return selectedIds.includes(release.id);
  };
  const releaseOptions = _.sortBy(
    [...releases, ...unreleasedReleases].filter(r => !isReleaseSelected(r)),
    r => -dayjs(r.__typename === "ReleaseNode" ? r.releaseDate : r.createdAt)
  );
  return (
    <SearchableSelector<ReleaseOption>
      inputProps={{
        onChange: props.onChange,
        name: "releasesLookup"
      }}
      options={releaseOptions.map(o => {
        return {
          label: o.name,
          value: {
            typename: o.__typename,
            id: o.id,
            hasExistingPartnership: o.hasExistingPartnership,
            hasExistingInvestmentPartnership: o.hasExistingInvestmentPartnership
          },
          fragment: o
        };
      })}
      size={props.size}
      placeholder="Search or create a release..."
      selectProps={{
        isCreatable: props.canCreateUnreleasedContent,
        getNewOptionData: (v: string) => {
          return {
            label: `Add "${v}" as an upcoming release`,
            value: {
              typename: "UnreleasedReleaseNode",
              name: v,
              isVisible: true
            }
          };
        }
      }}
      Option={Release}
    />
  );
}

export function SearchableSelector<OptionType>(props: {
  inputProps: {
    onChange: (option: OptionType) => void;
    name: string;
  };
  options: OptionType[];
  size: ResponsiveValue<InputSize>;
  placeholder?: string;
  selectProps?: Partial<Parameters<typeof SelectInput<OptionType>>[0]>;
  Option: (optionProps: OptionProps<OptionType, false>) => ReactElement;
}): ReactElement {
  const [needle, setNeedle] = useState<OptionType>(null);
  const onChange = (option: OptionType) => {
    setNeedle(null);
    props.inputProps.onChange(option);
  };

  const selectRef = useRef<StateManager>();

  useEffect(() => {
    selectRef.current.focus();
  }, []);

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      selectRef.current.blur();
      e.stopPropagation();
    }
  }

  return (
    <SelectInput<OptionType>
      size={props.size}
      placeholder={props.placeholder}
      isClearable={true}
      isSearchable={true}
      input={{
        name: props.inputProps.name,
        onChange,
        value: needle,
        onBlur: noop,
        onFocus: noop
      }}
      meta={null}
      options={props.options}
      ref={selectRef}
      onKeyDown={handleKeyDown}
      components={props.Option ? { Option: props.Option } : undefined}
      {...props.selectProps}
    />
  );
}

export default ReleaseSelector;
