import { ReleaseImagesGrid_artist$key } from "__generated__/ReleaseImagesGrid_artist.graphql";
import { ReleaseImagesGrid_releases$key } from "__generated__/ReleaseImagesGrid_releases.graphql";
import { ReleaseImagesGrid_unreleasedReleases$key } from "__generated__/ReleaseImagesGrid_unreleasedReleases.graphql";
import * as _ from "lodash";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Image } from "theme-ui";

import Indiglyph from "../../../../../components/02_Brand_Assets/Indiglyph";
import useArtistThumbnailUrl from "../../../hooks/useArtistThumbnailUrl";
import useImageSampleColor from "../../../hooks/useImageSampleColor";

interface IReleaseImagesGridProps {
  artist: ReleaseImagesGrid_artist$key;
  releases: ReleaseImagesGrid_releases$key;
  unreleasedReleases: ReleaseImagesGrid_unreleasedReleases$key;
}

/**
 * figma: child of https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
function ReleaseImagesGrid(props: IReleaseImagesGridProps): ReactElement {
  const artist = useFragment(
    graphql`
      fragment ReleaseImagesGrid_artist on ArtistNode {
        ...useArtistThumbnailUrl_artist
      }
    `,
    props.artist
  );
  const releases = useFragment(
    graphql`
      fragment ReleaseImagesGrid_releases on ReleaseNode @relay(plural: true) {
        id
        thumbnailUrl
      }
    `,
    props.releases
  );
  const unreleasedReleases = useFragment(
    graphql`
      fragment ReleaseImagesGrid_unreleasedReleases on UnreleasedReleaseNode
      @relay(plural: true) {
        id
        thumbnailUrl
        indiglyphType
      }
    `,
    props.unreleasedReleases
  );
  const allReleases = [].concat(releases, unreleasedReleases);
  const artistSampleColor = useImageSampleColor(useArtistThumbnailUrl(artist));

  if (allReleases.length === 1) {
    const release = allReleases[0];

    return release.thumbnailUrl ? (
      <Image width={"100%"} src={allReleases[0].thumbnailUrl} />
    ) : release.indiglyphType ? (
      <Indiglyph
        color={"sampled"}
        sampleColor={artistSampleColor}
        type={release.indiglyphType}
      />
    ) : null;
  }

  const gridTemplateAreas = (() => {
    switch (allReleases.length) {
      case 2:
        return `
          "bl0 release0"
          "release1 bl1"
        `;
      case 3:
        return `
          "release0 release1"
          "release2 bl0"
        `;
      default:
        return `
          "release0 release1"
          "release2 release3"
        `;
    }
  })();
  const numBlanks = 4 - allReleases.length <= 0 ? 0 : 4 - allReleases.length;

  return (
    <Box sx={{ display: "grid", gridTemplateAreas }}>
      {[].concat(
        allReleases.map(({ id, thumbnailUrl, indiglyphType }, i) => {
          return thumbnailUrl ? (
            <Image
              key={id}
              src={thumbnailUrl}
              sx={{ gridArea: `release${i}` }}
            />
          ) : indiglyphType ? (
            <Indiglyph
              key={id}
              sampleColor={artistSampleColor}
              color={"sampled"}
              type={indiglyphType}
              sxOverrides={{ gridArea: `release${i}` }}
            />
          ) : null;
        }),
        _.range(numBlanks).map(blankIdx => (
          <Box
            key={`bl${blankIdx}`}
            sx={{
              gridArea: `bl${blankIdx}`,
              backgroundColor: "black100",
              width: "100%",
              height: "auto"
            }}
          />
        ))
      )}
    </Box>
  );
}

export default ReleaseImagesGrid;
