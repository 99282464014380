import { ChangeEvent, forwardRef, ReactElement } from "react";
import { Input as ThemeUIInput } from "theme-ui";

import Input, { IInputElementProps, InputProps } from "../Input";

function TextInput(
  props: InputProps<HTMLInputElement, string | null>
): ReactElement {
  return <Input InputComponent={BaseTextInput} {...props} />;
}

const BaseTextInput = forwardRef<
  HTMLInputElement,
  IInputElementProps<HTMLInputElement, string | null>
>((props, ref) => {
  const {
    input: { value, onChange, ...inputRest },
    meta,
    ...rest
  } = props;

  const serializingOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v: string = e.target.value;
    onChange(v === "" ? null : v);
  };

  const serializedValue = value?.toString();

  return (
    <ThemeUIInput
      {...rest}
      {...inputRest}
      ref={ref}
      value={serializedValue}
      onChange={serializingOnChange}
      type={props.type ?? "text"}
    />
  );
});

export default TextInput;
