import { CompanyOrdering } from "__generated__/FundDirectoryPaginationQuery.graphql";
import { ReactElement } from "react";

import Ic20Search from "../../../../../../imgs/icons/ic20-search.svg";
import Ic24Sort from "../../../../../../imgs/icons/ic24-sort.svg";
import Ic24UserAdd from "../../../../../../imgs/icons/ic24-user-add.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import SelectInput from "../../../../../components/01_Core/Forms/Inputs/SelectInput";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { Field } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { arrayOfAll } from "../../../../../utils/utils";
import { COMPANY_ATTRIBUTE_LABELS } from "../../03_Discover/FundDirectoryFilters";
import { IconLabel } from "./RowHeaderDeal";

export const companyOrderingOptions = arrayOfAll<CompanyOrdering>()([
  "total_streams_desc",
  "created_at_desc"
]).map(option => ({
  value: option,
  label: (() => {
    switch (option) {
      case "total_streams_desc":
        return COMPANY_ATTRIBUTE_LABELS.totalStreams;
      case "created_at_desc":
        return <IconLabel Icon={Ic24UserAdd}>Recently Joined</IconLabel>;
    }
  })()
}));

export type CompanyOrderingOption = (typeof companyOrderingOptions)[number];

function FundDirectorySearchSort(props: {
  filterInput: (value: string) => void;
}): ReactElement {
  const gridTemplateAreas = useActiveResponsiveValue([
    `
      "srch srch srch srch"
      "sort sort sort sort"
    `,
    `
      "srch srch srch srch sort sort sort sort"
    `,
    `
      "srch srch srch srch sort sort sort sort"
    `,
    `
    "srch srch srch srch ---- ---- ---- ---- sort sort sort sort"
    `
  ]);

  return (
    <FixedGrid
      sx={{
        gridTemplateAreas,
        padding: "16px",
        gridRowGap: "16px",
        backgroundColor: "white100",
        border: "1px solid",
        borderBottom: "none",
        borderColor: "midGray70",
        width: "100%"
      }}
    >
      <GridItem
        gridArea={"srch"}
        sx={{
          width: "100%",
          paddingLeft: useActiveResponsiveValue([0, 0, "24px", "24px"])
        }}
      >
        <TextInput
          input={{
            name: "filter",
            onChange: props.filterInput
          }}
          size={["small", "medium", "medium", "medium"]}
          placeholder={"Search by company name..."}
          iconProps={{
            placement: "left",
            Icon: Ic20Search,
            color: "deepGray100"
          }}
        />
      </GridItem>
      <GridItem
        gridArea={"sort"}
        sx={{
          width: "100%",
          paddingRight: useActiveResponsiveValue([0, 0, "24px", "24px"])
        }}
      >
        <AutoLayout
          spacing={6}
          dependentProps={{ direction: "horizontal" }}
          sx={{ alignItems: "center", width: "100%" }}
        >
          <AutoLayout
            spacing={0}
            dependentProps={{ direction: "horizontal" }}
            sx={{ color: "deepGray100" }}
          >
            <Ic24Sort
              style={{
                verticalAlign: "middle",
                width: "20px"
              }}
            />
          </AutoLayout>
          <Field name={"orderBy"}>
            {({ input, ...fieldProps }) => (
              <SelectInput
                {...fieldProps}
                controlShouldRenderValue={true}
                input={input}
                size={["small", "medium", "medium", "medium"]}
                options={companyOrderingOptions}
              />
            )}
          </Field>
        </AutoLayout>
      </GridItem>
    </FixedGrid>
  );
}

export default FundDirectorySearchSort;
