import { ReactElement, ReactNode } from "react";
import ReactModal from "react-modal";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

import Ic24Close from "../../../../../../imgs/icons/ic24-close.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import { IconBox } from "../../../../../components/01_Core/Foundations/Icons";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { zIndices } from "../../../../../theme/theme";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { lockBodyScroll, unlockBodyScroll } from "../../../../../utils/utils";
import ModalFooterCTA, { IModalFooterCTAProps } from "./ModalFooterCTA";

interface IBasicModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement;
  title?: ReactNode;
  headerStyles?: ThemeUIStyleObject;
  modalFooterCtaProps?: IModalFooterCTAProps;
  fullScreenMobile?: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2818%3A18777
 */
function BasicModal(props: IBasicModalProps): ReactElement {
  return (
    <ReactModal
      onAfterOpen={lockBodyScroll}
      onAfterClose={unlockBodyScroll}
      isOpen={props.isOpen}
      ariaHideApp={false}
      onRequestClose={props.onClose}
      shouldCloseOnEsc={true}
      style={{
        overlay: {
          zIndex: zIndices.modal,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backdropFilter: "blur(2px)"
        },
        content: {
          backgroundColor: "transparent",
          maxHeight: "95vh",
          overflowY: "auto",
          inset: "",
          border: "",
          padding: "",
          borderRadius: "4px"
        }
      }}
    >
      <FixedGrid
        sx={{
          px: [props.fullScreenMobile ? 0 : null, null, null, null],
          height: "100%"
        }}
      >
        <GridItem gridColumn={"1 / -1"}>
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <AutoLayout
              spacing={0}
              resizingX={FillContainer()}
              dependentProps={{ direction: "vertical" }}
              sx={{
                backgroundColor: "white",
                height: [
                  props.fullScreenMobile ? "100vh" : "unset",
                  "unset",
                  "unset",
                  "unset"
                ],
                overflowY: "auto",
                borderRadius: [
                  props.fullScreenMobile ? 0 : "4px",
                  "4px",
                  "4px",
                  "4px"
                ]
              }}
            >
              {/* Header Row (w/ close button) */}
              <AutoLayout
                spacing={16}
                resizingX={FillContainer()}
                dependentProps={{
                  direction: "horizontal",
                  alignment: "topRight"
                }}
                sx={{ padding: "24px", ...props.headerStyles }}
              >
                {props.title && (
                  <Text
                    variant={useActiveResponsiveValue([
                      "h450",
                      "h500",
                      "h500",
                      "h500"
                    ])}
                    sx={{ flexGrow: 1 }}
                  >
                    {props.title}
                  </Text>
                )}
                <IconBox
                  icon={Ic24Close}
                  onClick={props.onClose}
                  sx={{ cursor: "pointer", size: "24px" }}
                />
              </AutoLayout>
              {props.children}
              {props.modalFooterCtaProps && (
                <ModalFooterCTA {...props.modalFooterCtaProps} />
              )}
            </AutoLayout>
          </Flex>
        </GridItem>
      </FixedGrid>
    </ReactModal>
  );
}

export default BasicModal;
