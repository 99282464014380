/**
 * @generated SignedSource<<da1402a91c2b1e6856d7c290cb5060e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartnerAddCompanyInfo_referral$data = {
  readonly id: string;
  readonly thumbnailUrl?: string;
  readonly " $fragmentType": "PartnerAddCompanyInfo_referral";
};
export type PartnerAddCompanyInfo_referral$key = {
  readonly " $data"?: PartnerAddCompanyInfo_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartnerAddCompanyInfo_referral">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnailUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartnerAddCompanyInfo_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "IndifyToPartnerReferralNode",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "PartnerToPartnerReferralNode",
      "abstractKey": null
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};
})();

(node as any).hash = "34f0898eabb35e7a63a8b794ee5eb086";

export default node;
