/**
 * @generated SignedSource<<fce3ab0811c80f3f9bb4592151ecb0ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TikTokSoundsIncluded_tikTokSoundDetails$data = {
  readonly firstSeenAt: any;
  readonly mostRecentStat: {
    readonly current: number;
  } | null;
  readonly name: string | null;
  readonly thumbnailUrl: string | null;
  readonly " $fragmentType": "TikTokSoundsIncluded_tikTokSoundDetails";
};
export type TikTokSoundsIncluded_tikTokSoundDetails$key = {
  readonly " $data"?: TikTokSoundsIncluded_tikTokSoundDetails$data;
  readonly " $fragmentSpreads": FragmentRefs<"TikTokSoundsIncluded_tikTokSoundDetails">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TikTokSoundsIncluded_tikTokSoundDetails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstSeenAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StatWithDelta",
      "kind": "LinkedField",
      "name": "mostRecentStat",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TikTokSoundNode",
  "abstractKey": null
};

(node as any).hash = "ce34e183958092fbb9e905ad754cfed6";

export default node;
