/**
 * @generated SignedSource<<b85deff6367627caf8ffd1dd1b22d125>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserEmailSubscriptionEmailType = "E01_PARTNER_GENERAL_INVITE" | "E02_ARTIST_GENERAL_INVITE" | "E03_PARTNER_INVITE_TO_COMPANY_TEAM" | "E04_MESSAGE_RECEIVED" | "E05_ARTIST_OFFER_RECEIVED" | "E07_PARTNER_OFFER_DECLINED" | "E09_ARTIST_OFFER_CANCELED" | "E10_TRANSFER_SENDER_MONEY_ON_THE_WAY" | "E11_TRANSFER_RECIPIENT_MONEY_ON_THE_WAY" | "E12_TRANSFER_SENDER_TRANSFER_COMPLETE" | "E13_ARTIST_DEAL_APPROVED" | "E13_TRANSFER_RECIPIENT_TRANSFER_COMPLETE" | "E15_PARTNER_NEW_ACTIONED_ARTIST_PROPOSAL" | "E16_PARTNER_NEW_HIGHLIGHTED_DEALS" | "E17_PARTNER_NEW_DEALS" | "E18_PARTNER_INVITE_FROM_PARTNER" | "E19_ARTIST_REMINDER_TO_CREATE_FEATURED_DEAL_FOR_NEW_RELEASES" | "E20_ARTIST_INVITE_FROM_PARTNER" | "E21_ARTIST_OFFER_ACCEPTED" | "E22_PARTNER_OFFER_ACCEPTED" | "E23_ARTIST_EARNINGS_DIRECTED" | "E24_PARTNER_EARNINGS_DIRECTED" | "E25_ARTIST_OFFER_REMINDER";
export type UserSetEmailSubscriptionsMutationInput = {
  E15PartnerNewActionedArtistProposal?: boolean | null;
  E16PartnerNewHighlightedDeals?: boolean | null;
  E17PartnerNewDeals?: boolean | null;
  E19ArtistReminderToCreateFeaturedDealForNewReleases?: boolean | null;
  E25ArtistOfferReminder?: boolean | null;
};
export type EmailSubscriptionsMutation$variables = {
  input: UserSetEmailSubscriptionsMutationInput;
};
export type EmailSubscriptionsMutation$data = {
  readonly userSetEmailSubcriptions: {
    readonly user: {
      readonly emailSubscriptions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly emailType: UserEmailSubscriptionEmailType;
            readonly id: string;
            readonly isSubscribed: boolean;
          } | null;
        } | null>;
      };
      readonly id: string;
    } | null;
  } | null;
};
export type EmailSubscriptionsMutation = {
  response: EmailSubscriptionsMutation$data;
  variables: EmailSubscriptionsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "input"
      }
    ],
    "concreteType": "UserSetEmailSubscriptionsMutation",
    "kind": "LinkedField",
    "name": "userSetEmailSubcriptions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmailSubscriptionNodeConnection",
            "kind": "LinkedField",
            "name": "emailSubscriptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEmailSubscriptionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEmailSubscriptionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emailType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isSubscribed",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailSubscriptionsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailSubscriptionsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9e4338ece012404280ae1e489593ab49",
    "id": null,
    "metadata": {},
    "name": "EmailSubscriptionsMutation",
    "operationKind": "mutation",
    "text": "mutation EmailSubscriptionsMutation(\n  $input: UserSetEmailSubscriptionsMutationInput!\n) {\n  userSetEmailSubcriptions(data: $input) {\n    user {\n      id\n      emailSubscriptions {\n        edges {\n          node {\n            id\n            emailType\n            isSubscribed\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3295610b3f73fa1f8b24e9a0aad5d9eb";

export default node;
