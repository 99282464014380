import { HomebaseHeader_homebase$key } from "__generated__/HomebaseHeader_homebase.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Grid, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import DisplayAvatar from "../../../../components/01_Core/Users/DisplayAvatar";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { formatNumber } from "../../../../utils/stringFormatting";
import SocialLinksIcons from "../04_User_Profile/SocialLinksIcons";

function HomebaseHeader(props: {
  homebase: HomebaseHeader_homebase$key;
}): ReactElement {
  const homebase = useFragment(
    graphql`
      fragment HomebaseHeader_homebase on HomebaseNode {
        instagramFollowers {
          value
        }
        tiktokFollowers {
          value
        }
        weeklyStreams {
          value
        }
        artist {
          displayName
          location
          imageUrl
          stats {
            ranked
          }
          genre {
            genre
          }
          profiles {
            edges {
              node {
                ...SocialLinksIcons_profiles
              }
            }
          }
        }
      }
    `,
    props.homebase
  );

  const activeSize = useActiveResponsiveValue([
    "small",
    "large",
    "large",
    "large"
  ]);

  return (
    <AutoLayout
      spacing={32}
      resizingX={FillContainer()}
      dependentProps={[
        {
          direction: "vertical",
          alignment: "center"
        },
        {
          direction: "vertical",
          alignment: "center"
        },
        {
          direction: "horizontal",
          alignment: "center"
        },
        {
          direction: "horizontal",
          alignment: "center"
        }
      ]}
    >
      <DisplayAvatar
        type={"roundedSquare"}
        size={"extraLarge"}
        imageUrl={homebase.artist.imageUrl}
      />
      <AutoLayout
        spacing={24}
        dependentProps={[
          { direction: "vertical", alignment: "center" },
          { direction: "vertical", alignment: "center" },
          { direction: "vertical", alignment: "left" },
          { direction: "vertical", alignment: "left" }
        ]}
      >
        <AutoLayout
          spacing={12}
          resizingX={FillContainer()}
          dependentProps={[
            { direction: "vertical", alignment: "center" },
            { direction: "vertical", alignment: "center" },
            { direction: "vertical", alignment: "left" },
            { direction: "vertical", alignment: "left" }
          ]}
        >
          <Text variant={activeSize === "small" ? "h500" : "h700"}>
            {homebase.artist.displayName}
          </Text>
          <AutoLayout spacing={16} dependentProps={{ direction: "vertical" }}>
            <AutoLayout
              spacing={16}
              resizingX={FillContainer()}
              dependentProps={[
                { direction: "horizontal", alignment: "center" },
                { direction: "horizontal", alignment: "center" },
                { direction: "horizontal", alignment: "left" },
                { direction: "horizontal", alignment: "left" }
              ]}
            >
              {homebase.artist.genre?.genre && (
                <SubtleBadge size={"small"} color={"gray"}>
                  {homebase.artist.genre.genre}
                </SubtleBadge>
              )}
              {homebase.artist.location && (
                <SubtleBadge size={"small"} color={"gray"}>
                  {homebase.artist.location}
                </SubtleBadge>
              )}
            </AutoLayout>
            <SocialLinksIcons
              profiles={homebase.artist.profiles.edges.map(e => e.node)}
            />
          </AutoLayout>
        </AutoLayout>
        <Grid
          columns={[2]}
          gap={32}
          p={24}
          sx={{
            backgroundColor: "white100",
            border: "1px solid",
            borderColor: "midGray100",
            borderRadius: "4px"
          }}
        >
          <AutoLayout
            spacing={4}
            dependentProps={{ direction: "vertical", alignment: "center" }}
            sx={{
              margin: "0 auto"
            }}
          >
            <Text variant={"h400"}>
              {homebase.artist.stats?.ranked
                ? `#${homebase.artist.stats.ranked}`
                : "-"}
            </Text>
            <Text variant={"bodySmall"} color={"deepGray100"}>
              indify Rank
            </Text>
          </AutoLayout>
          <AutoLayout
            spacing={4}
            dependentProps={{ direction: "vertical", alignment: "center" }}
            sx={{
              margin: "0 auto"
            }}
          >
            <Text variant={"h400"}>
              {homebase.tiktokFollowers.value
                ? formatNumber(homebase.tiktokFollowers.value)
                : "-"}
            </Text>
            <Text variant={"bodySmall"} color={"deepGray100"}>
              TiKTok Followers
            </Text>
          </AutoLayout>
          <AutoLayout
            spacing={4}
            dependentProps={{ direction: "vertical", alignment: "center" }}
            sx={{
              margin: "0 auto"
            }}
          >
            <Text variant={"h400"}>
              {homebase.weeklyStreams.value
                ? formatNumber(homebase.weeklyStreams.value)
                : "-"}
            </Text>
            <Text variant={"bodySmall"} color={"deepGray100"}>
              Weekly Streams
            </Text>
          </AutoLayout>
          <AutoLayout
            spacing={4}
            dependentProps={{ direction: "vertical", alignment: "center" }}
            sx={{
              margin: "0 auto"
            }}
          >
            <Text variant={"h400"}>
              {homebase.instagramFollowers.value
                ? formatNumber(homebase.instagramFollowers.value)
                : "-"}
            </Text>
            <Text variant={"bodySmall"} color={"deepGray100"}>
              Instagram Followers
            </Text>
          </AutoLayout>
        </Grid>
      </AutoLayout>
    </AutoLayout>
  );
}

export default HomebaseHeader;
