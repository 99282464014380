import { WhyInvest_featuredDeal$key } from "__generated__/WhyInvest_featuredDeal.graphql";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import LogoGlyph from "../../../../../../imgs/logo-glyph.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { IconBox } from "../../../../../components/01_Core/Foundations/Icons";
import { TooltipIconRight } from "../../../../../components/01_Core/Tooltips/Tooltip";
import Linkify from "../../../../../utils/Linkify";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import BlockLowEmphasis from "../Marketing/BlockLowEmphasis";
import { ExistingAgreementTooltips } from "../Offers/OfferFormExistingAgreements";

type WhyInvestSize = "small" | "large";

interface IWhyInvestProps {
  size: ResponsiveValue<WhyInvestSize>;
  featuredDeal: WhyInvest_featuredDeal$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
function WhyInvest(props: IWhyInvestProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const featuredDeal = useFragment(
    graphql`
      fragment WhyInvest_featuredDeal on FeaturedDealNode {
        whyInvestNow
        editorialNote
        distributor
        distributionAgreement
        producerAgreement
        lastApprovedAt
      }
    `,
    props.featuredDeal
  );
  return (
    <AutoLayout
      spacing={activeSize === "large" ? 24 : 16}
      dependentProps={{ direction: "vertical" }}
      sx={{ width: "100%" }}
    >
      {!!featuredDeal.editorialNote && (
        <BlockLowEmphasis size={activeSize} sx={{ width: "100%" }}>
          <AutoLayout
            spacing={activeSize === "large" ? 24 : 16}
            dependentProps={{ direction: "vertical" }}
          >
            <AutoLayout
              spacing={activeSize === "large" ? 12 : 8}
              dependentProps={{ direction: "horizontal" }}
              sx={{
                alignItems: "center"
              }}
            >
              <IconBox
                icon={LogoGlyph}
                sx={{
                  size: activeSize === "large" ? 48 : 24
                }}
              />
              <Text
                variant={activeSize === "large" ? "h500" : "h300"}
                sx={{
                  fontWeight: "bold"
                }}
              >
                {"indify's Take"}
              </Text>
            </AutoLayout>
            <Text
              variant={"bodyMedium"}
              sx={{ maxWidth: "100%", overflowWrap: "break-word" }}
            >
              {featuredDeal.editorialNote}
            </Text>
          </AutoLayout>
        </BlockLowEmphasis>
      )}
      {!!featuredDeal.whyInvestNow && (
        <Text variant={activeSize === "large" ? "h500" : "h300"}>
          Artist Pitch
        </Text>
      )}
      {!!featuredDeal.whyInvestNow && (
        <Text
          variant={"bodyMedium"}
          sx={{
            maxWidth: "100%",
            overflowWrap: "break-word",
            whiteSpace: "pre-line"
          }}
        >
          <Linkify>{featuredDeal.whyInvestNow}</Linkify>
        </Text>
      )}
      {(!!featuredDeal.distributor ||
        !!featuredDeal.distributionAgreement ||
        !!featuredDeal.producerAgreement) && (
        <Text variant={activeSize === "large" ? "h500" : "h300"}>
          Existing Agreements
        </Text>
      )}
      {!!featuredDeal.distributor && (
        <TooltipIconRight
          size={activeSize === "large" ? "large" : "medium"}
          content={ExistingAgreementTooltips.distributor}
        >
          <Text variant={"bodyLarge"}>Current Distributor</Text>
        </TooltipIconRight>
      )}
      {!!featuredDeal.distributor && (
        <Text
          variant={"bodyMedium"}
          sx={{ maxWidth: "100%", overflowWrap: "break-word" }}
        >
          {featuredDeal.distributor}
        </Text>
      )}
      {!!featuredDeal.distributionAgreement && (
        <TooltipIconRight
          size={activeSize === "large" ? "large" : "medium"}
          content={ExistingAgreementTooltips.distributionAgreement}
        >
          <Text variant={"bodyLarge"}>Distribution Agreement</Text>
        </TooltipIconRight>
      )}
      {!!featuredDeal.distributionAgreement && (
        <Text
          variant={"bodyMedium"}
          sx={{ maxWidth: "100%", overflowWrap: "break-word" }}
        >
          {featuredDeal.distributionAgreement}
        </Text>
      )}
      {!!featuredDeal.producerAgreement && (
        <TooltipIconRight
          size={activeSize === "large" ? "large" : "medium"}
          content={ExistingAgreementTooltips.producerAgreement}
        >
          <Text variant={"bodyLarge"}>Producer Agreement</Text>
        </TooltipIconRight>
      )}
      {!!featuredDeal.producerAgreement && (
        <Text
          variant={"bodyMedium"}
          sx={{ maxWidth: "100%", overflowWrap: "break-word" }}
        >
          {featuredDeal.producerAgreement}
        </Text>
      )}
      <Text
        variant={activeSize === "large" ? "bodyMedium" : "bodySmall"}
        color={"deepGray100"}
      >
        Last updated {dayjs(featuredDeal.lastApprovedAt).format("MMMM D, YYYY")}
      </Text>
    </AutoLayout>
  );
}

export default WhyInvest;
