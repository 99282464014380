/**
 * @generated SignedSource<<7614599f8a9971049b2e896cfd9bdb09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProfilePlatform = "FACEBOOK" | "INSTAGRAM" | "LUMINATE" | "SOUNDCLOUD" | "SPOTIFY" | "TIKTOK" | "TWITTER" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type SocialLinks_profiles$data = ReadonlyArray<{
  readonly id: string;
  readonly platform: ProfilePlatform;
  readonly url: string | null;
  readonly " $fragmentType": "SocialLinks_profiles";
}>;
export type SocialLinks_profiles$key = ReadonlyArray<{
  readonly " $data"?: SocialLinks_profiles$data;
  readonly " $fragmentSpreads": FragmentRefs<"SocialLinks_profiles">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SocialLinks_profiles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    }
  ],
  "type": "ProfileNode",
  "abstractKey": null
};

(node as any).hash = "a46ac3abf5f89c1aca2fd5d37b5b4ba5";

export default node;
