import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex } from "theme-ui";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import { rvMap } from "../../../../../utils/responsiveUtils";

type ModalFooterCTASize = "small" | "medium" | "large";
export interface IModalFooterCTAProps {
  size: ResponsiveValue<ModalFooterCTASize>;
  ctaLabel: string;
  onClick: () => void;
  description?: ReactElement;
  disabled?: boolean;
  loading: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=390%3A27
 */
function ModalFooterCTA(props: IModalFooterCTAProps): ReactElement {
  return (
    <Flex
      sx={{
        justifyContent: "center",
        backgroundColor: "lightGray100",
        marginTop: "auto",
        width: "100%"
      }}
    >
      <AutoLayout
        spacing={24}
        dependentProps={{ direction: "vertical", alignment: "topCenter" }}
        sx={{
          padding: rvMap(props.size, getPadding),
          width: "100%"
        }}
      >
        <LoadingButton
          onClick={props.onClick}
          size={props.size}
          variant={"primary"}
          disabled={props.disabled ?? false}
          loading={props.loading}
        >
          {props.ctaLabel}
        </LoadingButton>
        {props.description}
      </AutoLayout>
    </Flex>
  );
}

function getPadding(size: ModalFooterCTASize) {
  switch (size) {
    case "small":
      return "16px";
    case "medium":
      return "24px";
    case "large":
      return "32px";
  }
}

export default ModalFooterCTA;
