import { NodeId } from "../../../types/relay_types";

function useStripeConnectUrl(userId: NodeId): string {
  const stripeOauthUrl = new URL(
    "https://connect.stripe.com/express/oauth/authorize"
  );

  stripeOauthUrl.searchParams.append(
    "redirect_uri",
    `${window.location.hostname}/api/me/stripe_oauth_redirect/`
  );
  stripeOauthUrl.searchParams.append("client_id", STRIPE_CLIENT_ID);
  stripeOauthUrl.searchParams.append(
    "state",
    JSON.stringify({
      userId: userId,
      redirect: window.location.href
    })
  );

  return stripeOauthUrl.href;
}

export default useStripeConnectUrl;
