import { ReactElement } from "react";
import ReactModal from "react-modal";

import { zIndices } from "../../../../../theme/theme";
import { lockBodyScroll, unlockBodyScroll } from "../../../../../utils/utils";
import { IModalFooterCTAProps } from "./ModalFooterCTA";

interface IFullScreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement;
  title?: string;
  modalFooterCtaProps?: IModalFooterCTAProps;
}

/**
 * figma: N/A
 */
function FullScreenModal(props: IFullScreenModalProps): ReactElement {
  return (
    <ReactModal
      onAfterOpen={lockBodyScroll}
      onAfterClose={unlockBodyScroll}
      isOpen={props.isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={props.onClose}
      shouldCloseOnEsc={true}
      style={{
        overlay: {
          zIndex: zIndices.modal
        },
        content: {
          border: "",
          padding: "",
          boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          height: "100vh",
          width: "100%"
        }
      }}
    >
      {props.isOpen && props.children}
    </ReactModal>
  );
}

export default FullScreenModal;
