/**
 * @generated SignedSource<<5cd4ffe5d420d9df7625999ae71f4da2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistManageReleases_artist$data = {
  readonly id: string;
  readonly releasesPrefetch: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ArtistManageReleases_releasesSection">;
      } | null;
    } | null>;
  } | null;
  readonly unreleasedReleasesPrefetch: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ArtistManageReleases_releasesSection">;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "ArtistManageReleases_artist";
};
export type ArtistManageReleases_artist$key = {
  readonly " $data"?: ArtistManageReleases_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistManageReleases_artist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ArtistManageReleases_releasesSection"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ArtistManageReleasesRefreshQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ArtistManageReleases_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-releaseDate"
        }
      ],
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releasesPrefetch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "releasesPrefetch(first:1000,orderBy:\"-releaseDate\")"
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        }
      ],
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleasesPrefetch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "unreleasedReleasesPrefetch(first:1000,orderBy:\"-createdAt\")"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};
})();

(node as any).hash = "862897c41321e5335e646630d4dc6c5b";

export default node;
