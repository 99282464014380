import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Grid, Text } from "theme-ui";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import {
  GridItem,
  GridItemProps
} from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { Tooltip } from "../../../../../components/01_Core/Tooltips/Tooltip";
import AuthUserAvatarName from "../../../../../components/01_Core/Users/AuthUserAvatarName";
import { Artist } from "../../../../../types/users";
import MarketplaceAccessGate from "../../../../../utils/MarketplaceAccessGate";
import { rvMap } from "../../../../../utils/responsiveUtils";
import useArtistCanViewDeals from "../../../hooks/useArtistCanViewDeals";
import { useAuth } from "../../../hooks/useAuth";
import useCanAccessMarketplace from "../../../hooks/useCanAccessMarketplace";
import usePartnerCanViewDeals from "../../../hooks/usePartnerCanViewDeals";
import {
  artistReferralsSubpage,
  artistRosterSubpage,
  bankSettingsSubpage,
  basicInfoSubpage,
  companyInfoSubpage,
  companySocialLinksSubpage,
  companyTeamSubpage,
  dealsSubpage,
  emailSubscriptionsSubpage,
  manageReleasesSubpage,
  managerInfoSubpage,
  partnerReferralsSubpage,
  passwordSubpage,
  socialLinksSubpage,
  Subpage,
  subscriptionSubpage,
  teamMembersSubpage
} from "../../08_Account_Management/AccountManagementPage";
import NavItem from "../../08_Account_Management/NavItem";

type AccountNavSize = "small" | "large";

interface IAccountNavProps {
  size: ResponsiveValue<AccountNavSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1340%3A1168
 */
function AccountNav(props: IAccountNavProps): ReactElement {
  const { authUser, signOut } = useAuth();
  const [canAccessMarketplace] = useCanAccessMarketplace();
  const [partnerCanViewDeals, partnerCannotViewDealsText] =
    usePartnerCanViewDeals();
  const [artistCanViewDeals, artistCannotViewDealsText] =
    useArtistCanViewDeals();

  const userType = authUser.userType;

  function getArtistNavAreas(size: AccountNavSize) {
    const deals = canAccessMarketplace ? "de" : "";
    const releases = canAccessMarketplace ? "mr" : "";
    const manager = canAccessMarketplace ? "mg" : "";
    const team = artistCanViewDeals ? "tm" : "";
    const bank = artistCanViewDeals ? "bs" : "";
    const email = artistCanViewDeals ? "em" : "";
    switch (size) {
      case "small":
        return `
          "bi ${deals} ${releases} sl ${manager} ${team} ${bank} ${email} sb pw lo"
          `;
      case "large":
        return `
          "d1"
          "bi"
          ${deals && `"${deals}"`}
          ${releases && `"${releases}"`}
          "sl"
          ${manager && `"${manager}"`}
          ${team && `"${team}"`}
          ${bank && `"${bank}"`}
          "d2"
          ${email && `"${email}"`}
          "sb"
          "pw"
          "lo"
          `;
    }
  }

  function getPartnerNavAreas(size: AccountNavSize) {
    const artistRoster = partnerCanViewDeals ? "ar" : "";
    const referPartners = canAccessMarketplace ? "rp" : "";
    const referArtists = canAccessMarketplace ? "ra" : "";
    const email = partnerCanViewDeals ? "em" : "";
    switch (size) {
      case "small":
        return `
          "bi ci cs ct ${artistRoster} ${referPartners} ${referArtists} ${email} sb pw lo"
    `;
      case "large":
        return `
          "d1"
          "bi"
          "ci"
          "cs"
          "ct"
          ${artistRoster && `"${artistRoster}"`}
          "d1pt5"
          ${referPartners && `"${referPartners}"`}
          ${referArtists && `"${referArtists}"`}
          ${referPartners || referArtists ? '"d2"' : ""}
          ${email && `"${email}"`}
          "sb"
          "pw"
          "lo"
    `;
    }
  }

  const gridTemplateAreas =
    userType === Artist
      ? rvMap(props.size, getArtistNavAreas)
      : rvMap(props.size, getPartnerNavAreas);

  const avatarName = <AuthUserAvatarName size={"extraLarge"} />;

  return (
    <Box sx={{ width: "100%" }}>
      <Flex
        padding={"32px"}
        bg={"white100"}
        sx={{
          display: rvMap(props.size, getDisplayOuterAvatar),
          border: "1px solid",
          borderColor: "midGray70"
        }}
      >
        {avatarName}
      </Flex>
      <AutoLayout
        spacing={24}
        dependentProps={{
          direction: "vertical"
        }}
        p={rvMap(props.size, getPadding)}
        backgroundColor={rvMap(props.size, getBg)}
        sx={{
          border: rvMap(props.size, getBorder),
          borderColor: rvMap(props.size, getBorderColor),
          height: "fit-content",
          overflowX: "auto",
          width: "100%"
        }}
      >
        <Box sx={{ display: rvMap(props.size, getDisplayInnerAvatar) }}>
          {avatarName}
        </Box>
        <Grid
          gap={"24px"}
          sx={{
            gridTemplateColumns: rvMap(props.size, getGridTemplateColumns),
            gridRowGap: rvMap(props.size, getGridRowGap),
            gridTemplateAreas: gridTemplateAreas,
            width: "100%"
          }}
        >
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"ci"}
            label={"Company Info"}
            subpage={companyInfoSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"cs"}
            label={"Company Social Links"}
            subpage={companySocialLinksSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"ct"}
            label={"Company Team"}
            subpage={companyTeamSubpage}
          />
          <GridItem {...{ gridTemplateAreas }} gridArea={"ar"}>
            <Tooltip content={partnerCannotViewDealsText} size="medium">
              <NavItem
                label={"Artist Roster"}
                subpage={artistRosterSubpage}
                disabled={!partnerCanViewDeals}
              />
            </Tooltip>
          </GridItem>
          <GridItem gridArea={"d1"} {...{ gridTemplateAreas }} pb={"12px"}>
            <Divider />
          </GridItem>
          <ConditionalGridNavItem
            gridArea={"bi"}
            label={"Basic Info"}
            subpage={basicInfoSubpage}
          />
          <GridItem {...{ gridTemplateAreas }} gridArea={"de"}>
            <MarketplaceAccessGate>
              <NavItem label={"Deal Proposals"} subpage={dealsSubpage} />
            </MarketplaceAccessGate>
          </GridItem>
          <GridItem {...{ gridTemplateAreas }} gridArea={"mr"}>
            <MarketplaceAccessGate>
              <NavItem label={"Releases"} subpage={manageReleasesSubpage} />
            </MarketplaceAccessGate>
          </GridItem>
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"sl"}
            label={"Social Links"}
            subpage={socialLinksSubpage}
          />
          <GridItem {...{ gridTemplateAreas }} gridArea={"mg"}>
            <MarketplaceAccessGate>
              <NavItem label={"Manager"} subpage={managerInfoSubpage} />
            </MarketplaceAccessGate>
          </GridItem>
          <GridItem {...{ gridTemplateAreas }} gridArea={"tm"}>
            <Tooltip content={artistCannotViewDealsText} size="medium">
              <NavItem
                label={"Partners"}
                subpage={teamMembersSubpage}
                disabled={!artistCanViewDeals}
              />
            </Tooltip>
          </GridItem>
          <GridItem {...{ gridTemplateAreas }} gridArea={"bs"}>
            <Tooltip content={artistCannotViewDealsText} size="medium">
              <NavItem
                label={"Bank Settings"}
                subpage={bankSettingsSubpage}
                disabled={!artistCanViewDeals}
              />
            </Tooltip>
          </GridItem>
          <GridItem gridArea={"d1pt5"} {...{ gridTemplateAreas }} py={"12px"}>
            <Divider />
          </GridItem>
          <GridItem {...{ gridTemplateAreas }} gridArea={"rp"}>
            <MarketplaceAccessGate>
              <NavItem
                label={"Refer Other Investors"}
                subpage={partnerReferralsSubpage}
              />
            </MarketplaceAccessGate>
          </GridItem>
          <GridItem {...{ gridTemplateAreas }} gridArea={"ra"}>
            <MarketplaceAccessGate>
              <NavItem
                label={"Refer Artists"}
                subpage={artistReferralsSubpage}
              />
            </MarketplaceAccessGate>
          </GridItem>
          <GridItem gridArea={"d2"} {...{ gridTemplateAreas }} py={"12px"}>
            <Divider />
          </GridItem>
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"em"}
            label={"Email Notifications"}
            subpage={emailSubscriptionsSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"sb"}
            label={"Subscription"}
            subpage={subscriptionSubpage}
          />
          <GridItem {...{ gridTemplateAreas }} gridArea={"pw"}>
            <NavItem label={"Password"} subpage={passwordSubpage} />
          </GridItem>
          <GridItem {...{ gridTemplateAreas }} gridArea={"lo"}>
            <Flex
              onClick={signOut}
              sx={{
                cursor: "pointer",
                alignItems: "center",
                height: "100%",
                paddingRight: rvMap(props.size, getLogoutPaddingRight)
              }}
            >
              <Text
                variant={"bodyMedium"}
                color={"deepGray100"}
                sx={{
                  whiteSpace: "nowrap",
                  ":hover": {
                    color: "black100"
                  }
                }}
              >
                Logout
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </AutoLayout>
    </Box>
  );
}

function ConditionalGridNavItem(
  props: {
    label: string;
    subpage: Subpage;
    disabled?: boolean;
  } & Omit<GridItemProps, "children">
): ReactElement {
  const { label, subpage, disabled, ...rest } = props;

  return (
    <GridItem {...rest}>
      <NavItem label={label} subpage={subpage} disabled={disabled} />
    </GridItem>
  );
}

function getBg(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "unset";
    case "large":
      return "white100";
  }
}

function getBorder(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "none";
    case "large":
      return "1px solid";
  }
}

function getBorderColor(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "none";
    case "large":
      return "midGray70";
  }
}

function getGridRowGap(size: AccountNavSize) {
  switch (size) {
    case "small":
      return 0;
    case "large":
      return 12;
  }
}

function getGridTemplateColumns(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "repeat(10, min-content)";
    case "large":
      return "auto";
  }
}

function getPadding(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "22px 88px 22px 32px";
    case "large":
      return "32px";
  }
}

function getDisplayInnerAvatar(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "none";
    case "large":
      return "initial";
  }
}

function getDisplayOuterAvatar(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "flex";
    case "large":
      return "none";
  }
}

function getLogoutPaddingRight(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "88px";
    case "large":
      return "unset";
  }
}

export default AccountNav;
