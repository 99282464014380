/**
 * @generated SignedSource<<efb410cdc6c2af4e6a8f041729e61a77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamList_offers$data = ReadonlyArray<{
  readonly artist: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  };
  readonly company: {
    readonly displayName: string;
  };
  readonly id: string;
  readonly partner: {
    readonly displayName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useFeaturedDealTitle">;
  readonly " $fragmentType": "TeamList_offers";
}>;
export type TeamList_offers$key = ReadonlyArray<{
  readonly " $data"?: TeamList_offers$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamList_offers">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AvatarName_actor"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TeamList_offers",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyNode",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeaturedDealTitle"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};
})();

(node as any).hash = "10fd4df38f2bfa8cfe9571b40f6a7224";

export default node;
