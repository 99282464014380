import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, BoxProps, Flex, Text } from "theme-ui";

import { useActiveResponsiveValue } from "../../../utils/responsiveUtils";
import Divider from "../Miscelleneous/Divider";

type ComplexRowTextSize = "small" | "large";
type ComplexRowTextType = "default" | "important";

interface IComplexRowTextProps extends BoxProps {
  size: ResponsiveValue<ComplexRowTextSize>;
  type: ComplexRowTextType;
  align: "left" | "center" | "right";
  emphasis: "low" | "high";
  children: ReactElement | string;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A1543
 */
function ComplexRowText(props: IComplexRowTextProps): ReactElement {
  const { align, children, size, ...rest } = props;
  const activeSize = useActiveResponsiveValue(size);
  const textVariant = activeSize === "large" ? "bodyLarge" : "bodyMedium";

  const justifyContent = (() => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
    }
  })();
  const textAlign = (() => {
    switch (align) {
      case "left":
        return "left";
      case "center":
        return "center";
      case "right":
        return "right";
    }
  })();
  const backgroundColor =
    props.emphasis === "low" ? "white100" : "lightGray100";

  return (
    <Box
      sx={{
        display: "inline-block",
        backgroundColor,
        ...rest.sx
      }}
      {...rest}
    >
      <Flex
        sx={{
          justifyContent,
          alignItems: "center",
          padding: "22px 16px",
          height: "72px"
        }}
      >
        <Text variant={textVariant} sx={{ textAlign }}>
          {props.type === "important" ? <strong>{children}</strong> : children}
        </Text>
      </Flex>
      <Divider />
    </Box>
  );
}

export default ComplexRowText;
