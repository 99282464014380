import { ArtistManageBank_user$key } from "__generated__/ArtistManageBank_user.graphql";
import { ReactElement, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import Button from "../../../../components/01_Core/Buttons/Button";
import useStripeConnectUrl from "../../hooks/useStripeConnectUrl";
import BlockLowEmphasis from "../03_UI_Kit/Marketing/BlockLowEmphasis";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";

interface IBankActionProps {
  buttonUrl: string;
  buttonText: string;
  children: ReactNode;
}

function BankAction(props: IBankActionProps) {
  return (
    <AutoLayout spacing={32} dependentProps={{ direction: "vertical" }}>
      <BlockLowEmphasis size={["small", "small", "large", "large"]}>
        {props.children}
      </BlockLowEmphasis>
      <Button
        onClick={() => (window.location.href = props.buttonUrl)}
        variant={"primary"}
        size={"large"}
        disabled={false}
        sx={{ width: "100%" }}
      >
        {props.buttonText}
      </Button>
    </AutoLayout>
  );
}

function ArtistManageBank(props: {
  user: ArtistManageBank_user$key;
}): ReactElement {
  const user = useFragment(
    graphql`
      fragment ArtistManageBank_user on UserNode {
        id
        stripeConnectedAccountApiId
        stripeLoginLink
      }
    `,
    props.user
  );

  const stripeConnectUrl = useStripeConnectUrl(user.id);

  return (
    <AccountManagementFormContent>
      <AccountManagementHeading
        key={"heading"}
        size={["small", "large", "large", "large"]}
        title={"Bank Settings"}
        description={
          "Connect and manage your bank account with Stripe, our payments provider."
        }
      />
      {user.stripeConnectedAccountApiId ? (
        <BankAction
          buttonUrl={user.stripeLoginLink}
          buttonText={"Go to Stripe dashboard"}
        >
          You have linked your bank account with Stripe. Click the button below
          to go to your Stripe dashboard to manage your information.
        </BankAction>
      ) : (
        <BankAction
          buttonUrl={stripeConnectUrl}
          buttonText={"Connect with Stripe"}
        >
          You have not connected a bank account with Stripe. Click the button
          below to get started.
        </BankAction>
      )}
    </AccountManagementFormContent>
  );
}
export default ArtistManageBank;
