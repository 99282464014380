import _ from "lodash";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useFormState } from "react-final-form";

import Ic16PlayCircle from "../../../../../../imgs/icons/ic16-play-circle.svg";
import Ic24PlayCircle from "../../../../../../imgs/icons/ic24-play-circle.svg";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import getHasError from "../../../../../components/01_Core/Forms/utils/getHasError";
import { getPlatformIcons } from "../../../../../utils/getPlatformIcons";
import { useBreakpoint } from "../../../../../utils/useBreakpoints";
import { IFeaturedDealFormValues } from "../../06_Deal_Merchandising/DealMerchandisingFormPage";
import { IFundingOfferFormFieldProps } from "../../07_Deal_Flow/OfferFormFunding";
import {
  getFinalURLWithRedirects,
  getURLValidatorWithRedirects
} from "../../08_Account_Management/FormFields/Platform";
import FormSection from "./FormSection";

export const soundLinkFieldName = "soundLink";

export const soundLinkCaptionText =
  "public link to a Spotify or SoundCloud track, album, playlist, or artist profile, YouTube video or playlist, or TikTok video.";

type IOfferFormSoundLinkProps = IFundingOfferFormFieldProps;

// These should be maintained alongside the server-side regexes in the FeaturedDeal Django model
const REGEXES = {
  SPOTIFY: [
    /^(https:\/\/open.spotify.com\/artist\/)([0-9a-zA-Z]+)/,
    /^(https:\/\/open.spotify.com\/album\/)([0-9a-zA-Z]+)/,
    /^(https:\/\/open.spotify.com\/track\/)([0-9a-zA-Z]+)/,
    /^(https:\/\/open.spotify.com\/playlist\/)([0-9a-zA-Z]+)/
  ],
  YOUTUBE: [
    /^https:\/\/www.youtube.com\/watch\?.*v=([0-9a-zA-Z_-]+)/,
    /^(https:\/\/www.youtube.com\/playlist\?.*list=)([0-9a-zA-Z_-]+)/
  ],
  SOUNDCLOUD: [/^(https:\/\/soundcloud.com\/)([0-9a-zA-Z-/][^?]+)/],
  TIKTOK: [/^(https:\/\/www.tiktok.com\/)(@[^/]*)\/([^/]+)\/([0-9]+)/]
};

const getPlatformFromSoundLink = (soundLink: string) => {
  return _.findKey(REGEXES, regexes =>
    _.some(regexes, regex => soundLink.match(regex))
  ) as keyof typeof REGEXES;
};

const getPlatformFromSoundLinkWithFollow = async (soundLink: string) => {
  if (!soundLink) return;
  const finalSoundLink = await getFinalURLWithRedirects(
    value => (getPlatformFromSoundLink(value) ? null : "nope"),
    soundLink
  );

  return getPlatformFromSoundLink(finalSoundLink);
};

export const validateSoundLink = (value: string): string => {
  if (!value) return;

  if (!getPlatformFromSoundLink(value)) {
    return `Please provide a valid ${soundLinkCaptionText}`;
  }
};

export function useSoundLinkIcons(soundLink: string): SvgrComponent[] {
  const [icons, setIcons] = useState<SvgrComponent[]>([
    Ic16PlayCircle,
    Ic24PlayCircle
  ]);

  useEffect(() => {
    getPlatformFromSoundLinkWithFollow(soundLink).then(platform => {
      if (!platform) {
        setIcons([Ic16PlayCircle, Ic24PlayCircle]);
        return;
      }
      const [[PlatformIconSmall], [PlatformIconMedium]] =
        getPlatformIcons(platform);
      setIcons([PlatformIconSmall, PlatformIconMedium]);
    });
  }, [soundLink]);

  return icons;
}

/**
 *
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2363%3A17324
 */
function OfferFormSoundLink(props: IOfferFormSoundLinkProps): ReactElement {
  const hasError = getHasError<IFeaturedDealFormValues>(
    props.showAllErrors,
    soundLinkFieldName
  );

  const validator = useCallback(
    getURLValidatorWithRedirects(validateSoundLink),
    []
  );

  const breakpointMap = useBreakpoint();

  const formProps = useFormState<IFeaturedDealFormValues>();
  const icons = useSoundLinkIcons(formProps.values.soundLink);

  return (
    <FormSection
      heading={"Sound Link"}
      error={hasError ? `Please provide a valid ${soundLinkCaptionText}` : null}
      size={props.size}
    >
      <FieldBuilder
        inputField={TextInput}
        fieldName={soundLinkFieldName}
        inputFieldProps={{
          iconProps: {
            Icon: breakpointMap.mobile ? icons[0] : icons[1],
            placement: "left",
            color: "deepGray100"
          },
          size: props.size,
          placeholder: "http://",
          caption: `Optional. Provide a ${soundLinkCaptionText}`
        }}
        validator={validator}
        suppressValidationText={true}
      />
    </FormSection>
  );
}

export default OfferFormSoundLink;
