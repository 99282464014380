import { SectionCompany_referral$key } from "__generated__/SectionCompany_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import SectionCompanyKnown from "./SectionCompanyKnown";
import SectionCompanyLookup from "./SectionCompanyLookup";

interface ISectionCompanyProps {
  referral: SectionCompany_referral$key;
}

function SectionCompany(props: ISectionCompanyProps): ReactElement {
  const referral = useFragment(
    graphql`
      fragment SectionCompany_referral on ReferralInterface {
        __typename
        ...SectionCompanyKnown_referral
        ... on IndifyToPartnerReferralNode {
          company {
            id
          }
        }
      }
    `,
    props.referral
  );

  switch (referral.__typename) {
    case "IndifyToPartnerReferralNode":
      return referral.company?.id ? (
        <SectionCompanyKnown referral={referral} />
      ) : (
        <SectionCompanyLookup />
      );
    case "PartnerToPartnerReferralNode":
      return <SectionCompanyLookup />;
    case "PartnerToCompanyPartnerReferralNode":
      return <SectionCompanyKnown referral={referral} />;
    default:
      return <div>TODO: Implement flow</div>;
  }
}

export default SectionCompany;
