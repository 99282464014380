import dayjs from "dayjs";
import _ from "lodash";
import numeral from "numeral";
import { ReactElement, ReactNode, useState } from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Image, Text, useThemeUI } from "theme-ui";

import Ic24ArrowLeft from "../../../../../imgs/icons/ic24-arrow-left.svg";
import Ic24ArrowRight from "../../../../../imgs/icons/ic24-arrow-right.svg";
import { Loader } from "../../../../components/01_Core/999_Miscellaneous/Loader";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import {
  avgTimeseries,
  getTimeseriesPercentChange,
  graphablePoints,
  ITimeseriesPoint
} from "../../../../utils/timeseries";
import { formatNumeral, noop } from "../../../../utils/utils";
import StatDelta from "../03_UI_Kit/User_Directory/StatDelta";

export type TimeseriesSize = "small" | "large";

const windowOptionsForGranularity = {
  day: [
    { value: 7, label: "Past 7 days" },
    { value: 30, label: "Past 30 days" },
    { value: 90, label: "Past 90 days" },
    { value: 365, label: "Past year" },
    { value: "all", label: "All time" }
  ],
  week: [
    { value: 4, label: "Past 4 weeks" },
    { value: 12, label: "Past 12 weeks" },
    { value: 52, label: "Past year" },
    { value: "all", label: "All time" }
  ]
};
type WindowOption =
  (typeof windowOptionsForGranularity)[keyof typeof windowOptionsForGranularity][number];

interface ITimeseriesEmptyProps {
  emptyText: string;
}
export interface ITimeseriesProps extends ITimeseriesEmptyProps {
  timeseries: Array<ITimeseriesPoint>;
  statName: ReactNode;
  windowSummaryStatName: ReactNode;
  windowSummaryFn?: (timeseries: Array<ITimeseriesPoint>) => number;
  windowGranularity: keyof typeof windowOptionsForGranularity;
  showWindowOptionSelect?: boolean;
  xAxis?: ReturnType<typeof XAxis>;
  children?: ReactNode;
}

export default function Timeseries(props: ITimeseriesProps): ReactElement {
  const { theme } = useThemeUI();

  const fullTimeseries = _.sortBy(
    graphablePoints(props.timeseries),
    ({ x }) => x
  );

  if (fullTimeseries.length === 0) {
    return <TimeseriesEmpty {...props} />;
  }

  const [activeTimeseriesPoint, setActiveTimeseriesPoint] =
    useState<ITimeseriesPoint | null>(null);

  const showWindowOptionSelect = props.showWindowOptionSelect ?? true;
  const windowOptions = windowOptionsForGranularity[props.windowGranularity];
  const [selectedWindowOption, setSelectedWindowOption] =
    useState<WindowOption>(
      showWindowOptionSelect
        ? windowOptions[2]
        : windowOptions[windowOptions.length - 1] // Use the largest window
    );

  let currentTimeseries = fullTimeseries;
  if (typeof selectedWindowOption.value === "number") {
    const windowCutoff = dayjs(fullTimeseries[fullTimeseries.length - 1].x)
      .subtract(selectedWindowOption.value, props.windowGranularity)
      .valueOf();
    currentTimeseries = fullTimeseries.filter(
      ({ x }) => x.getTime() > windowCutoff
    );
  }

  const summaryFn = props.windowSummaryFn ?? avgTimeseries;

  const displayNumber = activeTimeseriesPoint
    ? activeTimeseriesPoint.y
    : summaryFn(currentTimeseries);
  const activeSize = useActiveResponsiveValue(responsiveChartSize);
  const previousTimeseries = fullTimeseries.slice(
    fullTimeseries.length - currentTimeseries.length * 2,
    fullTimeseries.length - currentTimeseries.length
  );
  const percentChangeStats = getTimeseriesPercentChange(
    previousTimeseries,
    currentTimeseries
  );

  // Include year if series goes back more than a year
  const firstTickTime = currentTimeseries[0].x.getTime();
  const timeFormatIncludesYear =
    firstTickTime < dayjs().subtract(1, "year").valueOf();
  const timeFormatter = (() => {
    if (timeFormatIncludesYear) {
      return "MMM YYYY";
    } else if (firstTickTime < dayjs().subtract(1, "week").valueOf()) {
      return "MMM D";
    } else {
      return "ddd";
    }
  })();

  const formatDate = (date: Date) => dayjs(date).format("MMMM D, YYYY");

  // Note: if we ever need to switch to type=number, we could encode `x` as "days since start of timeseries" to avoid having to specify a domain
  const timeseriesData = _.map(currentTimeseries, v => ({
    ...v,
    x: v.x.getTime()
  }));

  const windowOptionSelect = showWindowOptionSelect && (
    <AutoLayout
      spacing={6}
      dependentProps={{ direction: "horizontal" }}
      sx={{ alignItems: "center", width: "100%" }}
    >
      <AutoLayout
        spacing={0}
        dependentProps={{ direction: "horizontal" }}
        sx={{ color: "deepGray100" }}
      >
        <Ic24ArrowLeft
          style={{
            verticalAlign: "middle",
            width: "20px",
            marginRight: "-4px"
          }}
        />
        <Ic24ArrowRight style={{ verticalAlign: "middle", width: "20px" }} />
      </AutoLayout>
      <SelectInput
        size={useActiveResponsiveValue(["small", "small", "medium", "medium"])}
        input={{
          name: "window",
          value: selectedWindowOption,
          onChange: setSelectedWindowOption,
          onBlur: noop,
          onFocus: noop
        }}
        meta={null}
        options={windowOptions}
        sx={{ color: "black100" }}
      />
    </AutoLayout>
  );

  return (
    <Text
      variant={useActiveResponsiveValue([
        "bodySmall",
        "bodySmall",
        "bodyMedium",
        "bodyMedium"
      ])}
      color={"deepGray100"}
      sx={{ width: "100%" }}
    >
      <AutoLayout
        spacing={24}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical" }}
        // padding={rvMap(responsiveChartSize, getPadding)}
      >
        <AutoLayout
          spacing={8}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <Flex sx={{ width: "100%", justifyContent: "space-between" }}>
            <AutoLayout
              spacing={8}
              dependentProps={{
                direction: "horizontal",
                alignment: "bottomLeft"
              }}
            >
              <Text
                variant={useActiveResponsiveValue([
                  "h300",
                  "h300",
                  "h400",
                  "h400"
                ])}
                color={"black100"}
              >
                {numeral(displayNumber).format("0,0")}
              </Text>
              {!!previousTimeseries.length && !activeTimeseriesPoint ? (
                <StatDelta delta={percentChangeStats} size={"large"} />
              ) : null}
            </AutoLayout>
            {activeSize === "large" && (
              <Box sx={{ width: "208px" }}>{windowOptionSelect}</Box>
            )}
          </Flex>
          <Text color={"black100"}>
            {activeTimeseriesPoint
              ? props.statName
              : props.windowSummaryStatName}
          </Text>
          {activeTimeseriesPoint
            ? props.windowGranularity === "day"
              ? formatDate(activeTimeseriesPoint.x)
              : `Week ending ${formatDate(activeTimeseriesPoint.x)}`
            : `${formatDate(currentTimeseries[0].x)} - ${formatDate(
                currentTimeseries[currentTimeseries.length - 1].x
              )}`}
        </AutoLayout>
        {activeSize === "small" && windowOptionSelect}
        <ResponsiveContainer width={"100%"} height={200}>
          <AreaChart
            data={timeseriesData}
            onMouseMove={(e: {
              isTooltipActive: boolean;
              activePayload?: { payload: ITimeseriesPoint }[];
            }) =>
              e.isTooltipActive &&
              setActiveTimeseriesPoint(e.activePayload[0].payload)
            }
            onMouseLeave={() => setActiveTimeseriesPoint(null)}
          >
            {
              <XAxis
                // type=number is required for drawing a ReferenceLine that is not on a data point, but it also necessitates specifying a domain (else the X axis starts at 0 epoch time)
                type="number"
                domain={
                  currentTimeseries.length
                    ? [
                        currentTimeseries[0],
                        currentTimeseries[currentTimeseries.length - 1]
                      ].map(({ x }) => x.getTime())
                    : undefined
                }
                dataKey="x"
                tickFormatter={(date: Date) =>
                  dayjs(date).format(timeFormatter)
                }
                minTickGap={timeFormatIncludesYear ? 16 : 12}
                ticks={[
                  ...currentTimeseries.map(({ x }) => x.getTime())
                ].sort()}
                padding={{ left: timeFormatIncludesYear ? 48 : 32 }}
              />
            }
            <YAxis
              orientation={"right"}
              tickCount={4}
              interval={0}
              domain={["auto", "auto"]}
              tickFormatter={v => formatNumeral(v)}
              width={40}
              padding={{ top: 8 }}
            />
            {/* Recharts requires that a tooltip be rendered in order to show the activeDot */}
            <Tooltip wrapperStyle={{ display: "none" }} />
            <Area
              type="linear"
              dataKey="y"
              stroke={theme.colors.black100 as string}
              strokeWidth={"2px"}
              fill={"#0000001A"}
              activeDot={{ r: 8 }}
              animationDuration={1000}
            />
            {props.children}
          </AreaChart>
        </ResponsiveContainer>
      </AutoLayout>
    </Text>
  );
}

function TimeseriesEmpty(props: ITimeseriesEmptyProps) {
  return (
    <Flex
      // padding={rvMap(responsiveChartSize, getPadding)}
      sx={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Image
        src={
          "https://storage.googleapis.com/indify-images/chart_empty_state.png"
        }
      />
      <Text
        variant={"h500"}
        color={"deepGray70"}
        px={"16px"}
        sx={{ position: "absolute" }}
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        {props.emptyText}
      </Text>
    </Flex>
  );
}

export const responsiveChartSize: ResponsiveValue<TimeseriesSize> = [
  "small",
  "large",
  "large",
  "large"
];

export function ChartFallback(): ReactElement {
  return (
    <Box
      // p={rvMap(responsiveChartSize, getPadding)}
      sx={{ position: "relative" }}
    >
      <Image
        src={
          "https://storage.googleapis.com/indify-images/chart_skeleton_state.png"
        }
      />
      <Box sx={{ position: "absolute", top: "50%", left: "50%" }}>
        <Loader />
      </Box>
    </Box>
  );
}
