/**
 * @generated SignedSource<<2f604cba885880934a42a6d49e2aee56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferMessageReleasesIncluded_offer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReleasesInfoRemovable_offerFeaturedDeal">;
  readonly " $fragmentType": "OfferMessageReleasesIncluded_offer";
};
export type OfferMessageReleasesIncluded_offer$key = {
  readonly " $data"?: OfferMessageReleasesIncluded_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageReleasesIncluded_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferMessageReleasesIncluded_offer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleasesInfoRemovable_offerFeaturedDeal"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "54f6a58b6236caec2a95dff6e1b3e244";

export default node;
