/**
 * @generated SignedSource<<ca488a48cc111d7e4b70711509111f8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OfferStatus = "active" | "canceled" | "declined" | "legacy_incomplete_transfer" | "needs_artist_response" | "needs_earnings_directed" | "needs_invoice_creation" | "needs_invoice_payment" | "voided";
import { FragmentRefs } from "relay-runtime";
export type OfferMessageBody_offer$data = {
  readonly artist: {
    readonly displayName: string;
    readonly " $fragmentSpreads": FragmentRefs<"OfferPartnership_artist">;
  };
  readonly artistSignature: string | null;
  readonly dateOfferAccepted: any | null;
  readonly partner: {
    readonly displayName: string;
    readonly " $fragmentSpreads": FragmentRefs<"OfferPartnership_partner">;
  };
  readonly partnerSignature: string | null;
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"OfferMessageReleasesIncluded_releases">;
      } | null;
    } | null>;
  };
  readonly status: OfferStatus;
  readonly terms: any;
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"OfferMessageReleasesIncluded_unreleasedReleases">;
      } | null;
    } | null>;
  };
  readonly willTransferCreativeAdvance: boolean;
  readonly willTransferMarketingAdvance: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageReleasesIncluded_offer" | "OfferNextStep_offer">;
  readonly " $fragmentType": "OfferMessageBody_offer";
};
export type OfferMessageBody_offer$key = {
  readonly " $data"?: OfferMessageBody_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageBody_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferMessageBody_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfferAccepted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnerSignature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artistSignature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "willTransferCreativeAdvance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "willTransferMarketingAdvance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "OfferMessageReleasesIncluded_releases"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "OfferMessageReleasesIncluded_unreleasedReleases"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferPartnership_artist"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OfferPartnership_partner"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferNextStep_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferMessageReleasesIncluded_offer"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};
})();

(node as any).hash = "6faf764723f94dc181974078fbc6e753";

export default node;
