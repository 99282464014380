/**
 * @generated SignedSource<<01125a5ab22a861cad3cde03ce67617b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImageUploadButtonSetImageUrlMutation$variables = {
  nodeId?: string | null;
  publicUrl?: string | null;
};
export type ImageUploadButtonSetImageUrlMutation$data = {
  readonly setImageUrl: {
    readonly imageInfo: {
      readonly blurhash: string;
      readonly id: string;
      readonly imageUrl: string;
      readonly thumbnailUrl: string;
    } | null;
  } | null;
};
export type ImageUploadButtonSetImageUrlMutation = {
  response: ImageUploadButtonSetImageUrlMutation$data;
  variables: ImageUploadButtonSetImageUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nodeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "publicUrl"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "nodeId",
    "variableName": "nodeId"
  },
  {
    "kind": "Variable",
    "name": "publicUrl",
    "variableName": "publicUrl"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blurhash",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImageUploadButtonSetImageUrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetImageUrl",
        "kind": "LinkedField",
        "name": "setImageUrl",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "imageInfo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImageUploadButtonSetImageUrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetImageUrl",
        "kind": "LinkedField",
        "name": "setImageUrl",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "imageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e64a873a9d0e95bb4c8dabd52eb32968",
    "id": null,
    "metadata": {},
    "name": "ImageUploadButtonSetImageUrlMutation",
    "operationKind": "mutation",
    "text": "mutation ImageUploadButtonSetImageUrlMutation(\n  $nodeId: String\n  $publicUrl: String\n) {\n  setImageUrl(nodeId: $nodeId, publicUrl: $publicUrl) {\n    imageInfo {\n      __typename\n      id\n      imageUrl\n      thumbnailUrl\n      blurhash\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aae87a93f75b60d0db05cd73307018b1";

export default node;
