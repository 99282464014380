import { PortfolioRowDeal_offer$key } from "__generated__/PortfolioRowDeal_offer.graphql";
import { ReactElement, ReactNode, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Link as ExternalLink } from "theme-ui";

import Ic20Download from "../../../../../imgs/icons/ic20-download.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Button from "../../../../components/01_Core/Buttons/Button";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import OfferMessageBody from "../03_UI_Kit/Messaging/OfferMessage/OfferMessageBody";
import BasicModal from "../03_UI_Kit/Modals/BasicModal";

export const expandCssId = "expand";

export default function PortfolioRowDeal(props: {
  offer: PortfolioRowDeal_offer$key;
  children: ReactNode;
}): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const offer = useFragment(
    graphql`
      fragment PortfolioRowDeal_offer on OfferNode {
        pdfUrl
        ...OfferMessageBody_offer
      }
    `,
    props.offer
  );
  return (
    <>
      <Box
        onClick={() => setModalOpen(true)}
        sx={{
          cursor: "pointer",
          backgroundColor: "white100",
          width: "100%",
          transition: "background-color 0.2s",
          ":hover": {
            backgroundColor: "lightGray100",
            [`#${expandCssId}`]: { color: "black100" }
          }
        }}
      >
        {props.children}
      </Box>
      <BasicModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={"Partnership terms"}
      >
        <AutoLayout
          spacing={8}
          dependentProps={{
            direction: "vertical",
            alignment: "center"
          }}
          resizingX={FillContainer()}
        >
          {offer.pdfUrl && (
            <ExternalLink
              href={offer.pdfUrl}
              target={"_blank"}
              download={"terms.pdf"}
            >
              <Button
                iconProps={{ icon: <Ic20Download />, placement: "left" }}
                variant="secondary"
                size="medium"
                disabled={false}
              >
                Download PDF
              </Button>
            </ExternalLink>
          )}
          <OfferMessageBody
            offer={offer}
            size={useActiveResponsiveValue([
              "small",
              "medium",
              "medium",
              "medium"
            ])}
            showDefinitionsByDefault={false}
          />
        </AutoLayout>
      </BasicModal>
    </>
  );
}
