import { PartnerReferrals_partner$key } from "__generated__/PartnerReferrals_partner.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box } from "theme-ui";

import ReferralRecipientActive from "../03_UI_Kit/User_Directory/ReferralRecipient/ReferralRecipientActive";
import ReferralRecipientInvited from "../03_UI_Kit/User_Directory/ReferralRecipient/ReferralRecipientInvited";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import ReferralInput from "./ReferralInput";

export default function PartnerReferrals(props: {
  partner: PartnerReferrals_partner$key;
}): ReactElement {
  const partner = useFragment(
    graphql`
      fragment PartnerReferrals_partner on PartnerNode {
        id
        partnerReferralsMaxAllowed
        partnerReferrals {
          edges {
            node {
              id
              ...ReferralRecipientInvited_referral
              recipientPartner {
                ...ReferralRecipientActive_recipient
              }
            }
          }
        }
      }
    `,
    props.partner
  );

  const referrals = partner.partnerReferrals.edges.map(e => e.node);
  const [joinedReferrals, invitedReferrals] = _.partition(
    referrals,
    r => r.recipientPartner
  );
  let invitesRemaining = partner.partnerReferralsMaxAllowed - referrals.length;
  if (invitesRemaining < 0) invitesRemaining = 0;

  return (
    <AccountManagementFormContent>
      <AccountManagementHeading
        key={"heading"}
        size={["small", "small", "small", "large"]}
        title={`Refer other investors`}
        description={"Invite new investors to join indify."}
      />
      <ReferralInput
        size={["small", "small", "small", "large"]}
        typename="PartnerToPartnerReferralNode"
        disabled={referrals.length >= partner.partnerReferralsMaxAllowed}
        inputFieldProps={{
          caption: `Invites remaining: ${invitesRemaining}`
        }}
      />
      <Box sx={{ width: "100%" }}>
        {joinedReferrals.map(p => (
          <ReferralRecipientActive key={p.id} recipient={p.recipientPartner} />
        ))}
        {invitedReferrals.map(r => (
          <ReferralRecipientInvited key={r.id} referral={r} />
        ))}
      </Box>
    </AccountManagementFormContent>
  );
}
