import { Properties } from "csstype";
import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, FlexProps, Text, ThemeUIStyleObject } from "theme-ui";

import Ic16ChevronDown from "../../../../imgs/icons/ic16-chevron-down.svg";
import Ic16ChevronUp from "../../../../imgs/icons/ic16-chevron-up.svg";
import { useActiveResponsiveValue } from "../../../utils/responsiveUtils";
import AutoLayout, { FillContainer, PackedAlignment } from "../AutoLayout";

export type ColumnHeaderSortDir = "asc" | "desc";
export type ColumnHeaderState = "default" | "active";
type ColumnHeaderSize = "small" | "large";
export interface IColumnHeaderProps extends FlexProps {
  align: "left" | "right" | "center";
  size: ResponsiveValue<ColumnHeaderSize>;
  state: ColumnHeaderState;
  children: ReactNode;
  sortDir?: ColumnHeaderSortDir;
  onClick?: () => void;
  styles?: ThemeUIStyleObject;
  divider?: boolean;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A1528
 */
function ColumnHeader(props: IColumnHeaderProps): ReactElement {
  const { styles, onClick, children, state } = props;
  const alignItems: Properties["alignItems"] = (() => {
    switch (props.align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
    }
  })();
  const alignment: PackedAlignment = (() => {
    switch (props.align) {
      case "left":
        return "topLeft";
      case "center":
        return "topCenter";
      case "right":
        return "topRight";
    }
  })();

  const dividerCssId = "column-header-divider";

  return (
    <Flex
      onClick={props.onClick}
      sx={{
        flexDirection: "column",
        alignItems,
        height: "48px",
        width: "100%",
        ...((props.divider ?? true) && {
          marginBottom: "-1px",
          borderBottom: "1px solid",
          borderColor: state === "active" ? "deepGray100" : "midGray70",
          transition: "border-color 0.2s",
          ":hover": props.onClick && {
            borderColor: "deepGray100",
            cursor: "pointer"
          }
        }),
        ...styles
      }}
    >
      <AutoLayout
        spacing={0}
        resizingY={FillContainer()}
        dependentProps={{
          direction: "vertical",
          alignment
        }}
        onClick={onClick}
        sx={{
          width: "100%",
          ...(onClick
            ? {
                cursor: "pointer"
              }
            : {})
        }}
      >
        <Label {...props}>{children}</Label>
      </AutoLayout>
    </Flex>
  );
}

export function Label(props: IColumnHeaderProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);

  return (
    <AutoLayout
      spacing={8}
      dependentProps={{ direction: "horizontal", alignment: "center" }}
      sx={{ flexGrow: 2 }}
    >
      <Text
        variant={activeSize === "small" ? "bodySmall" : "bodyMedium"}
        color={props.state === "active" ? "black100" : "deepGray100"}
      >
        {props.children}
      </Text>
      {props.sortDir && (
        <Box color={props.state === "active" ? "black100" : "deepGray100"}>
          {props.sortDir === "asc" ? <Ic16ChevronUp /> : <Ic16ChevronDown />}
        </Box>
      )}
    </AutoLayout>
  );
}

export default ColumnHeader;
