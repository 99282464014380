/**
 * @generated SignedSource<<d60d672b2d7dc73798d0b2988cb3eade>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionCompany_referral$data = {
  readonly __typename: string;
  readonly company?: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SectionCompanyKnown_referral">;
  readonly " $fragmentType": "SectionCompany_referral";
};
export type SectionCompany_referral$key = {
  readonly " $data"?: SectionCompany_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"SectionCompany_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SectionCompany_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SectionCompanyKnown_referral"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyNode",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IndifyToPartnerReferralNode",
      "abstractKey": null
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};

(node as any).hash = "dac2fdd0b072d5a5ffdb75b64c5db02b";

export default node;
