import { ReactElement } from "react";

import SelectInput from "../../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { useStaticData } from "../../../hooks/useStaticData";

function Genre(
  props: Omit<Parameters<typeof SelectInput>[0], "input" | "meta" | "size"> & {
    fieldName: string;
  }
): ReactElement {
  const options = useStaticData().genres.map(genre => ({
    label: genre.genre,
    value: genre.id
  }));

  const { isMulti } = props;

  return (
    <Field name={props.fieldName}>
      {({ input, meta }) => {
        const currentValue = isMulti
          ? options.filter(o =>
              input.value
                .map(({ value }: (typeof options)[number]) => value)
                .includes(o.value)
            )
          : options.find(o => o.value === input.value?.value);
        return (
          <SelectInput
            size={["medium", "large", "large", "large"]}
            input={{
              ...input,
              onChange: input.onChange,
              value: currentValue
            }}
            meta={meta}
            options={options}
            isClearable={true}
            isSearchable={true}
            {...props}
          />
        );
      }}
    </Field>
  );
}

export default Genre;
