import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, BoxProps, Text, Theme, ThemeUIStyleObject } from "theme-ui";

import {
  rvMap,
  useActiveResponsiveValue,
  useFindActiveRV
} from "../../../utils/responsiveUtils";
import AutoLayout from "../AutoLayout";

export type SubtleBadgeSize = "small" | "medium" | "large";
type SubtleBadgeColor =
  | "blue"
  | "darkBlue"
  | "gray"
  | "stroked"
  | "green"
  | "orange"
  | "red";
export interface ISubtleBadgeProps extends BoxProps {
  children: ReactNode;
  size: ResponsiveValue<SubtleBadgeSize>;
  color: SubtleBadgeColor;
  icon?: ReactElement;
  // This is a ThemeUI "sx" prop. For some reason, the sx prop was being
  // omitted after compilation, but giving it a different name preserved it.
  // This is probably a bug with ThemeUI
  styles?: ThemeUIStyleObject;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A1741
 */
function SubtleBadge(props: ISubtleBadgeProps): ReactElement {
  const textVariant = useFindActiveRV(props.size, getTextVariant);

  function getBg(theme: Theme) {
    switch (props.color) {
      case "blue":
        return theme.colors.secondary10;
      case "darkBlue":
        return theme.colors.secondary20;
      case "gray":
        return theme.colors.midGray70;
      case "stroked":
        return theme.colors.white100;
      case "green":
        return theme.colors.success05;
      case "orange":
        return theme.colors.warning05;
      case "red":
        return theme.colors.alert05;
      default:
        props.color satisfies never;
    }
  }

  function getTextVariant(size: SubtleBadgeSize) {
    switch (size) {
      case "small":
        return "bodySmall";
      case "medium":
        return "bodyMedium";
      case "large":
        return "bodyLarge";
    }
  }

  return (
    <Box
      px={"8px"}
      py={rvMap(props.size, getPy)}
      bg={theme => getBg(theme)}
      sx={{
        borderRadius: "8px",
        border: theme =>
          props.color === "stroked"
            ? `1px solid ${theme.colors.deepGray100}`
            : "",
        minWidth: "initial",
        width: "fit-content",
        height: "initial",
        ...props.styles
      }}
    >
      <AutoLayout
        spacing={(() => {
          switch (useActiveResponsiveValue(props.size)) {
            case "small":
              return 4;
            case "medium":
              return 6;
            case "large":
              return 8;
          }
        })()}
        dependentProps={{ direction: "horizontal", alignment: "center" }}
      >
        {props.icon}
        <Text
          variant={textVariant}
          color={"black100"}
          sx={{ whiteSpace: "nowrap" }}
        >
          {props.children}
        </Text>
      </AutoLayout>
    </Box>
  );
}

function getPy(size: SubtleBadgeSize) {
  switch (size) {
    case "small":
      return "4px";
    case "medium":
      return "6px";
    case "large":
      return "8px";
  }
}

export default SubtleBadge;
