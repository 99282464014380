import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text } from "theme-ui";

import { zIndices } from "../../../../theme/theme";
import { rvMap, useFindActiveRV } from "../../../../utils/responsiveUtils";
import { InputProps } from "../Input";

export type SwitchSize = "small" | "medium" | "large";

export interface ISwitchInputProps
  extends InputProps<HTMLInputElement, boolean | null> {
  size: ResponsiveValue<SwitchSize>;
  label?: string;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A0
 */
function SwitchInput(props: ISwitchInputProps): ReactElement {
  const {
    input: { value: isSwitchOn, onChange },
    label
  } = props;

  const responsiveCircleSize = rvMap(props.size, getCircleSize);
  const responsiveContainerWidth = rvMap(props.size, getContainerWidth);
  const responsiveContainerHeight = rvMap(props.size, getContainerHeight);
  const labelVariant = useFindActiveRV(props.size, getLabelVariant);

  const Switch = () => (
    <Box sx={{ zIndex: zIndices.switch }}>
      <Flex
        bg={isSwitchOn ? "black100" : "midGray100"}
        onClick={() => onChange(!isSwitchOn)}
        sx={{
          alignItems: "center",
          justifyContent: isSwitchOn ? "flex-end" : "flex-start",
          borderRadius: "100px",
          cursor: "pointer",
          position: "relative",
          width: responsiveContainerWidth,
          height: responsiveContainerHeight,
          ":hover": {
            ":after": {
              borderRadius: "100px",
              position: "absolute",
              content: "''",
              height: "calc(100% + 12px)",
              width: "calc(100% + 12px)",
              left: "-6px",
              bg: "aliceblue",
              zIndex: zIndices.hidden
            }
          }
        }}
      >
        <Box
          bg={"white100"}
          mx="2px"
          sx={{ borderRadius: "50%", size: responsiveCircleSize }}
        />
      </Flex>
    </Box>
  );

  return label ? (
    <Flex sx={{ justifyContent: "space-between", width: "100%" }}>
      <Text variant={labelVariant}>{label}</Text>
      <Switch />
    </Flex>
  ) : (
    <Switch />
  );
}

function getLabelVariant(size: SwitchSize): string {
  switch (size) {
    case "small":
      return "bodySmall";
    case "medium":
      return "bodyMedium";
    case "large":
      return "bodyLarge";
  }
}

function getContainerWidth(size: SwitchSize) {
  switch (size) {
    case "small":
      return "28px";
    case "medium":
      return "36px";
    case "large":
      return "44px";
  }
}

function getContainerHeight(size: SwitchSize) {
  switch (size) {
    case "small":
      return "16px";
    case "medium":
      return "20px";
    case "large":
      return "24px";
  }
}

function getCircleSize(size: SwitchSize) {
  switch (size) {
    case "small":
      return "12px";
    case "medium":
      return "16px";
    case "large":
      return "20px";
  }
}

export default SwitchInput;
