import _ from "lodash";
import { ReactElement, useState } from "react";
import { ResponsiveValue } from "styled-system";

import Ic20ThumbUp from "../../../../../imgs/icons/ic20-thumb-up.svg";
import Ic24Difference from "../../../../../imgs/icons/ic24-difference.svg";
import Ic24Suitcase from "../../../../../imgs/icons/ic24-suitcase.svg";
import Ic24Voice from "../../../../../imgs/icons/ic24-voice.svg";
import Ic24VolumeMax from "../../../../../imgs/icons/ic24-volume-max.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { InputSize } from "../../../../components/01_Core/Forms/Input";
import CheckboxInput from "../../../../components/01_Core/Forms/Inputs/CheckboxInput";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import ColumnHeader, {
  IColumnHeaderProps
} from "../../../../components/01_Core/Tables/ColumnHeader";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { useStaticData } from "../../hooks/useStaticData";
import { CompanyOrderingOption } from "../03_UI_Kit/Discover/FundDirectorySearchSort";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";
import FilterAccordion, { FilterAccordionSize } from "./FilterAccordion";

type GenreOption = {
  label: string;
  value: string;
};

export type IFundsFilterForm = {
  orderBy: CompanyOrderingOption;
  services: { value: keyof GLOBALS["PARTNER_SERVICES"] }[];
  partnerships: boolean;
  testimonials: boolean;
  genres: GenreOption[];
};

export const COMPANY_ATTRIBUTE_LABELS = {
  services: <IconLabel Icon={Ic24Suitcase}>Services Provided</IconLabel>,
  genres: <IconLabel Icon={Ic24VolumeMax}>Genres</IconLabel>,
  partnerships: <IconLabel Icon={Ic24Difference}>Partnerships</IconLabel>,
  testimonials: <IconLabel Icon={Ic20ThumbUp}>Testimonials</IconLabel>,
  totalStreams: <IconLabel Icon={Ic24Voice}>Total Streams</IconLabel>
};

export default function FundDirectoryFilters(props: {
  size: ResponsiveValue<FilterAccordionSize>;
}): ReactElement {
  const serviceOptions = _.map(
    GLOBALS.PARTNER_SERVICES,
    ({ displayName: label }, value) => ({ label, value })
  );

  const genreOptions = _.sortBy(useStaticData().genres, g => g.genre).map(
    genre => ({
      label: genre.genre,
      value: genre.id
    })
  );

  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

  const activeSize = useActiveResponsiveValue(props.size);
  const gridTemplateAreas = (() => {
    switch (activeSize) {
      case "small":
        return `
          "sv sv sv sv"
          "gn gn gn gn"
          "pa pa pa pa"
          "ts ts ts ts"
        `;
      case "medium":
      case "large":
        return `
          "sv sv sv sv gn gn gn gn"
          "pa pa pa pa ts ts ts ts"
        `;
      case "extraLarge":
        return `
          "sv sv sv sv gn gn gn gn pa pa pa pa"
          "ts ts ts ts -- -- -- -- -- -- -- --"
        `;
      default:
        activeSize satisfies never;
    }
  })();

  const labels = COMPANY_ATTRIBUTE_LABELS;

  const columnHeaderProps: Pick<
    IColumnHeaderProps,
    "align" | "size" | "state" | "styles"
  > = {
    align: "left",
    size: ["small", "small", "large", "large"],
    state: "default",
    styles: { ":hover": {} }
  };

  const selectProps = {
    size: useActiveResponsiveValue([
      "small",
      "small",
      "medium",
      "medium"
    ]) as InputSize,
    isClearable: true,
    isMulti: true,
    isSearchable: true,
    menuIsOpen: accordionOpen ? undefined : false
  };

  const padLeft = { paddingLeft: "16px" };
  const padRight = { paddingRight: "16px" };

  return (
    <FilterAccordion<Omit<IFundsFilterForm, "orderBy">>
      labels={labels}
      accordionOpen={accordionOpen}
      setAccordionOpen={setAccordionOpen}
      size={props.size}
      styles={{ borderBottom: "unset" }}
    >
      <FixedGrid
        sx={{
          gridTemplateAreas,
          width: "100%",
          borderTop: "1px solid",
          borderColor: "midGray70",
          paddingBottom: "16px"
        }}
      >
        <GridItem
          gridArea={"sv"}
          sx={["large", "extraLarge"].includes(activeSize) && padLeft}
        >
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={0}
            resizingX={FillContainer()}
          >
            <ColumnHeader {...columnHeaderProps} divider={false}>
              {labels.services}
            </ColumnHeader>
            <Field name="services">
              {fieldProps => {
                return (
                  <SelectInput
                    {...fieldProps}
                    {...selectProps}
                    menuPortalTarget={document.body}
                    options={serviceOptions}
                  />
                );
              }}
            </Field>
          </AutoLayout>
        </GridItem>
        <GridItem
          gridArea={"gn"}
          sx={["large"].includes(activeSize) && padRight}
        >
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={0}
            resizingX={FillContainer()}
          >
            <ColumnHeader {...columnHeaderProps} divider={false}>
              {labels.genres}
            </ColumnHeader>
            <Field name="genres">
              {fieldProps => {
                return (
                  <SelectInput
                    {...fieldProps}
                    {...selectProps}
                    menuPortalTarget={document.body}
                    options={genreOptions}
                  />
                );
              }}
            </Field>
          </AutoLayout>
        </GridItem>
        <GridItem
          gridArea={"pa"}
          sx={{
            ...(["large"].includes(activeSize) && padLeft),
            ...(["extraLarge"].includes(activeSize) && padRight)
          }}
        >
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={8}
            resizingX={FillContainer()}
          >
            <ColumnHeader {...columnHeaderProps}>
              {labels.partnerships}
            </ColumnHeader>
            <Field name="partnerships">
              {fieldProps => {
                return (
                  <CheckboxInput
                    {...fieldProps}
                    label={"Has partnerships"}
                    size={["small", "small", "medium", "medium"]}
                    state="normal"
                  />
                );
              }}
            </Field>
          </AutoLayout>
        </GridItem>
        <GridItem
          gridArea={"ts"}
          sx={{
            ...(["extraLarge"].includes(activeSize) && padLeft),
            ...(["large"].includes(activeSize) && padRight)
          }}
        >
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={8}
            resizingX={FillContainer()}
          >
            <ColumnHeader {...columnHeaderProps}>
              {labels.testimonials}
            </ColumnHeader>
            <Field name="testimonials">
              {fieldProps => {
                return (
                  <CheckboxInput
                    {...fieldProps}
                    label={"Has testimonials"}
                    size={["small", "small", "medium", "medium"]}
                    state="normal"
                  />
                );
              }}
            </Field>
          </AutoLayout>
        </GridItem>
      </FixedGrid>
    </FilterAccordion>
  );
}
