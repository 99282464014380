import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

import { InternalRoutes } from "../Routing/InternalRoutes";

interface IPathState {
  value: string | null;
  setValue: (v: string | null) => void;
}

function usePathState<PathParam extends string>(
  routeName: keyof typeof InternalRoutes,
  // TODO: Encode dependency that the route must have a parameter with this name
  routeParam: PathParam
): IPathState {
  const route = InternalRoutes[routeName];

  const match = useRouteMatch<{ [k in PathParam]: string }>(route);
  const history = useHistory();
  const value =
    match?.params?.[routeParam] != null
      ? decodeURIComponent(match.params[routeParam])
      : null;
  const setValue = (v: string) => {
    const path = generatePath(route, {
      [routeParam]: v
    });
    history.push(path);
  };

  return {
    value,
    setValue
  };
}

export default usePathState;
