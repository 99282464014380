import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Text } from "theme-ui";

export default function InternalLink(
  props: Parameters<typeof Link>[0] & {
    disabled?: boolean;
    disabledColor?: string;
  }
): ReactElement {
  const { disabled, children, ...linkProps } = props;

  if (disabled) {
    return (
      <Text
        color={props.disabledColor ?? "deepGray70"}
        sx={{ cursor: "not-allowed" }}
      >
        {children}
      </Text>
    );
  }

  return <Link {...linkProps}>{children}</Link>;
}
