import { RecipientPartner_company$key } from "__generated__/RecipientPartner_company.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import SelectInput from "../../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";

export default function RecipientPartner(props: {
  company: RecipientPartner_company$key;
}): ReactElement {
  const company = useFragment(
    graphql`
      fragment RecipientPartner_company on CompanyNode {
        activePartners: partners(isActive: true) {
          edges {
            node {
              id
              displayName
            }
          }
        }
      }
    `,
    props.company
  );
  const options = company.activePartners.edges.map(({ node }) => {
    return {
      label: node.displayName,
      value: node.id
    };
  });
  return (
    <Field name={"recipientPartner"}>
      {({ input, meta }) => {
        const currentValue = options.find(o => o.value === input.value.value);
        return (
          <SelectInput
            size={"large"}
            label={"Initial message recipient"}
            caption={
              "Select a partner to receive introductory messages sent by artists."
            }
            input={{
              ...input,
              onChange: input.onChange,
              value: currentValue
            }}
            meta={meta}
            options={options}
          />
        );
      }}
    </Field>
  );
}
