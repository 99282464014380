/**
 * @generated SignedSource<<c50cf17468ff9669c5f1c66d3f8a435b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SpecialDealsDismissCompanyMatchMutation$variables = {
  featuredDealNodeId: string;
};
export type SpecialDealsDismissCompanyMatchMutation$data = {
  readonly companyFeaturedDealMatchDismiss: {
    readonly featuredDeal: {
      readonly id: string;
    } | null;
  } | null;
};
export type SpecialDealsDismissCompanyMatchMutation = {
  response: SpecialDealsDismissCompanyMatchMutation$data;
  variables: SpecialDealsDismissCompanyMatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "featuredDealNodeId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "featuredDealNodeId",
        "variableName": "featuredDealNodeId"
      }
    ],
    "concreteType": "CompanyFeaturedDealMatchDismissMutation",
    "kind": "LinkedField",
    "name": "companyFeaturedDealMatchDismiss",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeaturedDealNode",
        "kind": "LinkedField",
        "name": "featuredDeal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpecialDealsDismissCompanyMatchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpecialDealsDismissCompanyMatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2d4f58021bd8d7938afef0639ade4b19",
    "id": null,
    "metadata": {},
    "name": "SpecialDealsDismissCompanyMatchMutation",
    "operationKind": "mutation",
    "text": "mutation SpecialDealsDismissCompanyMatchMutation(\n  $featuredDealNodeId: String!\n) {\n  companyFeaturedDealMatchDismiss(featuredDealNodeId: $featuredDealNodeId) {\n    featuredDeal {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9746d7e9ccfdff6775bf23a7f3e2d3e1";

export default node;
