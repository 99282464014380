export function formatNumber(x: number | null): string {
  return typeof x === "number"
    ? x.toLocaleString(undefined, { maximumFractionDigits: 0 })
    : "";
}

export function lowercaseFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function uppercaseFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Format a number as an ordinal, e.g. `3` to `3rd`.
 * @link https://stackoverflow.com/a/57518703/223225
 * @param {number} number To format
 */
export function formatNumberOrdinal(number: number): string {
  const englishOrdinalRules = new Intl.PluralRules("en", { type: "ordinal" });
  const category = englishOrdinalRules.select(number);

  switch (category) {
    case "one":
      return `${number}st`;
    case "two":
      return `${number}nd`;
    case "few":
      return `${number}rd`;
    default:
      return `${number}th`;
  }
}
