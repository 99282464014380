import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { components, OptionProps } from "react-select";
import { graphql } from "relay-runtime";
import { Text } from "theme-ui";

import { ReleaseOption } from "../../../pages/indify/components/03_UI_Kit/Releases/ReleaseSelector";
import AutoLayout, { FillContainer } from "../AutoLayout";
import ReleaseDetails from "../Releases/ReleaseDetails";
import ReleaseImageIcon from "../Releases/ReleaseImageIcon";

function Release(props: OptionProps<ReleaseOption, false>): ReactElement {
  const release = useFragment(
    graphql`
      fragment Release_release on ReleaseInterface {
        thumbnailUrl
        ...ReleaseDetails_release
      }
    `,
    props.data.fragment
  );

  return (
    <components.Option {...props}>
      {release ? (
        <AutoLayout
          spacing={10}
          resizingX={FillContainer()}
          dependentProps={{ direction: "horizontal", alignment: "center" }}
        >
          <ReleaseImageIcon size={"medium"} src={release.thumbnailUrl} />
          <ReleaseDetails release={release} size={"small"} />
        </AutoLayout>
      ) : (
        <Text
          variant={"bodyMedium"}
          sx={{ overflowX: "hidden" }}
          color={"black100"}
          ml={"8px"}
        >
          {props.data.label}
        </Text>
      )}
    </components.Option>
  );
}

export default Release;
