/**
 * @generated SignedSource<<d99d0a20f8cfd7298b0eb1c6f82aa70d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartnerToCompanyPartnerReferralFlowFragment_referral$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Page_referral">;
  readonly " $fragmentType": "PartnerToCompanyPartnerReferralFlowFragment_referral";
};
export type PartnerToCompanyPartnerReferralFlowFragment_referral$key = {
  readonly " $data"?: PartnerToCompanyPartnerReferralFlowFragment_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartnerToCompanyPartnerReferralFlowFragment_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartnerToCompanyPartnerReferralFlowFragment_referral",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Page_referral"
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};

(node as any).hash = "61535db86e36a755e01671af6615e1d9";

export default node;
