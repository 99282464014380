import { AcceptOfferDialog_chat$key } from "__generated__/AcceptOfferDialog_chat.graphql";
import { AcceptOfferDialog_offer$key } from "__generated__/AcceptOfferDialog_offer.graphql";
import { mutationsOfferAcceptOfferMutation } from "__generated__/mutationsOfferAcceptOfferMutation.graphql";
import { FORM_ERROR } from "final-form";
import { Fragment, ReactElement } from "react";
import { Form } from "react-final-form";
import {
  commitMutation,
  ConnectionHandler,
  graphql,
  useFragment
} from "react-relay";
import { Box, Text } from "theme-ui";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import {
  getInputSpacing,
  InputLabel
} from "../../../../components/01_Core/Forms/Input";
import CheckboxInput from "../../../../components/01_Core/Forms/Inputs/CheckboxInput";
import SelectInput from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import {
  required,
  requiredTrue
} from "../../../../components/01_Core/Forms/utils/validators";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import environment from "../../../../environment";
import { acceptOfferMutation } from "../../../../mutations/mutations";
import { rvMap } from "../../../../utils/responsiveUtils";
import { ExternalRoutes } from "../../Routing/ExternalRoutes";
import BasicModal from "../03_UI_Kit/Modals/BasicModal";
import FormSignatureField from "../03_UI_Kit/Offers/FormSignatureField";
import OfferFormLegalName from "../03_UI_Kit/Offers/OfferFormLegalName";

interface IAcceptOfferDialogProps {
  offer: AcceptOfferDialog_offer$key;
  chat: AcceptOfferDialog_chat$key;
  isAcceptModalOpen: boolean;
  closeAcceptModal: () => void;
}

interface IAcceptOfferDialogValues {
  agreeDistroWorkflow: boolean;
  distributor?: "toolost" | "onerpm";
  agreeTos: boolean;
  understandBindingLegalAgreement: boolean;
  legalName: string;
  signature: string;
}

type distroOption = {
  label: string;
  value: IAcceptOfferDialogValues["distributor"];
};

/**
 * figma: https://www.figma.com/file/DwRAHDiXrsacj54PzBmIv1/07-Deal-Flow?node-id=1805%3A51103
 */
function AcceptOfferDialog(props: IAcceptOfferDialogProps): ReactElement {
  const offer = useFragment(
    graphql`
      fragment AcceptOfferDialog_offer on OfferNode {
        id
        company {
          providesOwnDistribution
        }
      }
    `,
    props.offer
  );

  const chat = useFragment(
    graphql`
      fragment AcceptOfferDialog_chat on ChatNode {
        id
      }
    `,
    props.chat
  );

  const acceptOffer = (values: IAcceptOfferDialogValues) => {
    const connectionId = ConnectionHandler.getConnectionID(
      chat.id,
      "ChatThread_messages"
    );
    const variables = {
      data: {
        offerNodeId: offer.id,
        chatNodeId: chat.id,
        distributor: values.distributor,
        artistLegalName: values.legalName,
        artistSignature: values.signature
      },
      connections: [connectionId]
    };
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });
      commitMutation<mutationsOfferAcceptOfferMutation>(environment, {
        mutation: acceptOfferMutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) return resolveUnknownError();
          resolve(undefined);
        },
        onError: () => {
          resolveUnknownError();
        }
      });
    });
  };

  // Should be maintained alongside list of distributor choices in Offer model on backend
  const distroOptions: distroOption[] = [
    { label: "Too Lost", value: "toolost" },
    { label: "ONErpm", value: "onerpm" }
  ];

  return (
    <Form<IAcceptOfferDialogValues> onSubmit={acceptOffer}>
      {formProps => {
        return (
          <BasicModal
            title={"Confirm and accept"}
            isOpen={props.isAcceptModalOpen}
            onClose={props.closeAcceptModal}
            fullScreenMobile={true}
            modalFooterCtaProps={{
              size: "large",
              ctaLabel: "Accept offer",
              onClick: formProps.handleSubmit,
              loading: formProps.submitting,
              disabled: !formProps.valid
            }}
          >
            <form
              style={{ height: "fit-content", width: "100%", padding: "24px" }}
            >
              <AutoLayout
                spacing={24}
                dependentProps={{ direction: "vertical" }}
                sx={{ width: "100%" }}
              >
                <Field
                  name={"understandBindingLegalAgreement"}
                  validate={requiredTrue("Required")}
                >
                  {({ input, meta }) => (
                    <CheckboxInput
                      input={input}
                      meta={meta}
                      size={"large"}
                      label={[
                        {
                          kind: "normal",
                          text: 'I understand that by clicking "Accept offer" I am entering into a binding legal agreement.'
                        }
                      ]}
                      state={"normal"}
                    />
                  )}
                </Field>
                <Field name={"agreeTos"} validate={requiredTrue("Required")}>
                  {({ input, meta }) => (
                    <CheckboxInput
                      input={input}
                      meta={meta}
                      size={"large"}
                      label={[
                        { kind: "normal", text: "I agree to the " },
                        {
                          kind: "link",
                          text: "Terms of Service",
                          href: ExternalRoutes.terms
                        },
                        { kind: "normal", text: " that bind this deal." }
                      ]}
                      state={"normal"}
                    />
                  )}
                </Field>
                {!offer.company.providesOwnDistribution && (
                  <Field
                    name={"agreeDistroWorkflow"}
                    validate={requiredTrue("Required")}
                  >
                    {({ input, meta }) => (
                      <CheckboxInput
                        input={input}
                        meta={meta}
                        size={"large"}
                        label={[
                          { kind: "normal", text: "I agree to the " },
                          {
                            kind: "link",
                            text: "Payout Distribution Terms",
                            href: ExternalRoutes.payoutDistributionTerms
                          },
                          { kind: "normal", text: " that bind this deal." }
                        ]}
                        caption={
                          "Participation in this workflow allows indify to ensure all parties are paid out accurately and on time."
                        }
                        state={"normal"}
                      />
                    )}
                  </Field>
                )}
                {!offer.company.providesOwnDistribution &&
                  formProps.values.agreeDistroWorkflow && (
                    <Fragment>
                      <Text variant={"bodyLarge"}>
                        Select the distributor you would like to use:
                      </Text>
                      <Field
                        name={"distributor"}
                        validate={required("Required")}
                      >
                        {({ input, meta }) => (
                          <Box sx={{ width: "100%" }}>
                            <SelectInput
                              size={"large"}
                              input={{
                                ...input,
                                onChange: (v: distroOption) =>
                                  input.onChange(v.value),
                                value: distroOptions.find(
                                  o => o.value === input.value
                                )
                              }}
                              meta={meta}
                              options={distroOptions}
                            />
                          </Box>
                        )}
                      </Field>
                    </Fragment>
                  )}
                <Divider saturation={"light"} />
                <AutoLayout
                  spacing={24}
                  dependentProps={{ direction: "vertical" }}
                  sx={{ maxWidth: "450px", width: "inherit" }}
                >
                  <OfferFormLegalName size={"large"} label={"Legal Name"} />
                  <AutoLayout
                    spacing={rvMap("large", getInputSpacing)}
                    dependentProps={{ direction: "vertical" }}
                    sx={{ width: "inherit" }}
                  >
                    <InputLabel size={"large"} label={"Signature"} />
                    <FormSignatureField size={"medium"} />
                    <Text
                      variant={"bodyMedium"}
                      color={"deepGray100"}
                      pt={"8px"}
                    >
                      Please draw your signature in the above area.
                    </Text>
                  </AutoLayout>
                </AutoLayout>
              </AutoLayout>
            </form>
          </BasicModal>
        );
      }}
    </Form>
  );
}

export default AcceptOfferDialog;
