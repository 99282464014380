import _ from "lodash";
import { ReactElement } from "react";
import { Grid } from "theme-ui";

import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import ReleaseCardEmpty from "../03_UI_Kit/Releases/ReleaseCardEmpty";

interface IProfileCardsGridProps {
  children: ReactElement[];
}

/**
 * figma: N/A
 */
function ProfileCardsGrid(props: IProfileCardsGridProps): ReactElement {
  const numColumns = useActiveResponsiveValue([2, 2, 3, 4]);
  const numRows = Math.ceil(props.children.length / numColumns);
  const numBlanks = (numColumns * numRows) % props.children.length;

  return (
    <Grid
      gap={["16px", "16px", "24px", "24px"]}
      columns={`repeat(${numColumns}, 1fr)`}
      paddingBottom={"200px"}
      sx={{ width: "100%" }}
    >
      {[
        ...props.children,
        ..._.range(numBlanks).map(n => {
          return (
            <GridItem
              gridColumn={"span 1"}
              key={n}
              sx={{ justifyContent: "center" }}
            >
              <ReleaseCardEmpty size={["small", "small", "small", "large"]} />
            </GridItem>
          );
        })
      ]}
    </Grid>
  );
}

export default ProfileCardsGrid;
