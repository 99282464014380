import { ManageSubscription_subscribedUser$key } from "__generated__/ManageSubscription_subscribedUser.graphql";
import { ManageSubscription_updateStripePriceForUserMutation } from "__generated__/ManageSubscription_updateStripePriceForUserMutation.graphql";
import { ManageSubscription_user$key } from "__generated__/ManageSubscription_user.graphql";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { Text } from "theme-ui";

import Ic24ArrowRight from "../../../../../imgs/icons/ic24-arrow-right.svg";
import Ic24Edit from "../../../../../imgs/icons/ic24-edit.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import LoadingButton, {
  ILoadingButtonProps
} from "../../../../components/01_Core/Buttons/LoadingButton";
import { IconBox } from "../../../../components/01_Core/Foundations/Icons";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import useMutationPromise from "../../hooks/useMutationPromise";
import { useStripeCheckoutRedirect } from "../../hooks/useStripeCheckoutRedirect";
import { useStripePortalRedirect } from "../../hooks/useStripePortalRedirect";
import useSubscribedUser from "../../hooks/useSubscribedUser";
import BlockLowEmphasis from "../03_UI_Kit/Marketing/BlockLowEmphasis";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";

function ManageSubscription(props: {
  user: ManageSubscription_user$key;
}): ReactElement {
  const user = useFragment(
    graphql`
      fragment ManageSubscription_user on UserNode {
        id
        availableProductName
        stripePriceId
        availableSubscriptionPrices {
          id
          price
          period
        }
      }
    `,
    props.user
  );
  const { stripePriceId, availableSubscriptionPrices } = user;

  const subscribedUser = useFragment<ManageSubscription_subscribedUser$key>(
    graphql`
      fragment ManageSubscription_subscribedUser on UserNode {
        id
        partner {
          displayName
        }
      }
    `,
    useSubscribedUser()
  );
  const canControlSubscription =
    !subscribedUser || subscribedUser.id === user.id;
  const activelyBilledPrice = availableSubscriptionPrices.find(
    price => price.id === stripePriceId
  );
  const [selectedPrice, setSelectedPrice] = useState(
    activelyBilledPrice ?? availableSubscriptionPrices[0]
  );

  const buttonIconProps = {
    iconProps: {
      icon: <IconBox icon={Ic24ArrowRight} sx={{ size: 24 }} />,
      placement: "right"
    }
  } as ILoadingButtonProps;
  const buttonProps = {
    variant: "primary",
    size: useActiveResponsiveValue(["medium", "medium", "large", "large"]),
    sx: { width: "100%" }
  } as ILoadingButtonProps;

  const [checkoutRedirect, isCheckoutRedirecting] = useStripeCheckoutRedirect(
    selectedPrice.period,
    window.location.href
  );
  const [portalRedirect, isPortalRedirecting] = useStripePortalRedirect(
    window.location.href
  );

  const productName = (() => {
    switch (user.availableProductName) {
      case "artist":
        return "indify for Artists";
      case "partner":
        return "indify for Partners";
      case "partner_enterprise":
        return "indify for Partners (Enterprise)";
    }
  })();

  const [updateStripePriceForUser, isUpdatingStripePriceForUser] =
    useMutationPromise<ManageSubscription_updateStripePriceForUserMutation>(
      graphql`
        mutation ManageSubscription_updateStripePriceForUserMutation(
          $billingPeriod: String!
        ) {
          updateStripePriceForUser(billingPeriod: $billingPeriod) {
            user {
              stripePriceId
            }
          }
        }
      `
    );

  return (
    <AccountManagementFormContent>
      <AccountManagementHeading
        key={"heading"}
        size={["small", "large", "large", "large"]}
        title={"Subscription settings"}
        description={"Manage your subscription to indify."}
      />
      <AutoLayout
        spacing={24}
        dependentProps={{ direction: "vertical" }}
        resizingX={FillContainer()}
      >
        <BlockLowEmphasis
          size={["small", "small", "large", "large"]}
          sx={{ width: "100%" }}
          alert={!subscribedUser}
        >
          {subscribedUser ? (
            canControlSubscription ? (
              <Text>
                You are currently subscribed to <strong>{productName}</strong>.
              </Text>
            ) : (
              <Text>
                Your company is subscribed to <strong>{productName}</strong>.
                Contact <strong>{subscribedUser.partner?.displayName}</strong>{" "}
                to manage this subscription.
              </Text>
            )
          ) : (
            <Text>
              You are not currently subscribed to <strong>{productName}</strong>
              . Select a billing interval and click the button below to
              subscribe.
            </Text>
          )}
        </BlockLowEmphasis>
        {canControlSubscription && subscribedUser && (
          <LoadingButton
            {...buttonProps}
            {...buttonIconProps}
            variant="primary"
            size={useActiveResponsiveValue([
              "medium",
              "medium",
              "medium",
              "large"
            ])}
            onClick={portalRedirect}
            loading={isPortalRedirecting}
          >
            Manage subscription
          </LoadingButton>
        )}
        {canControlSubscription && subscribedUser && <Divider />}
        {canControlSubscription && (
          <AutoLayout
            spacing={8}
            dependentProps={{
              direction: useActiveResponsiveValue([
                "vertical",
                "vertical",
                "vertical",
                "horizontal"
              ])
            }}
            resizingX={FillContainer()}
          >
            {availableSubscriptionPrices.map(price => (
              <AutoLayout
                key={price.id}
                spacing={4}
                dependentProps={{ direction: "vertical" }}
                resizingX={FillContainer()}
                p={"16px"}
                onClick={() => setSelectedPrice(price)}
                sx={{
                  cursor: "pointer",
                  border: "1px solid",
                  transition: "border-color 0.2s",
                  borderColor: theme =>
                    selectedPrice.id === price.id
                      ? theme.colors.black100
                      : theme.colors.midGray70,
                  backgroundColor: theme =>
                    selectedPrice.id === price.id
                      ? theme.colors.lightGray100
                      : "inherit",
                  borderRadius: "4px",
                  ":hover": {
                    borderColor: theme =>
                      selectedPrice.id === price.id
                        ? "inherit"
                        : theme.colors.midGray100
                  }
                }}
              >
                <AutoLayout
                  spacing={4}
                  dependentProps={{ direction: "horizontal" }}
                  resizingX={FillContainer()}
                >
                  <AutoLayout
                    spacing={4}
                    dependentProps={{
                      direction: "horizontal",
                      alignment: "bottomCenter"
                    }}
                  >
                    <Text variant={"subtitle"}>${price.price}</Text>
                    <Text variant={"bodyMedium"}>/ {price.period}</Text>
                  </AutoLayout>
                  {activelyBilledPrice?.id === price.id && (
                    <SubtleBadge
                      size={"small"}
                      color={"blue"}
                      styles={{ marginLeft: "auto" }}
                    >
                      Active
                    </SubtleBadge>
                  )}
                </AutoLayout>
                <Text variant={"bodyMedium"}>Billed {price.period}ly</Text>
                {!subscribedUser && (
                  <Text variant={"bodyMedium"}>14 day free trial</Text>
                )}
              </AutoLayout>
            ))}
          </AutoLayout>
        )}
        {canControlSubscription &&
          (subscribedUser ? (
            <LoadingButton
              disabled={selectedPrice.id === activelyBilledPrice?.id}
              {...buttonProps}
              iconProps={{
                icon: <IconBox icon={Ic24Edit} sx={{ size: 24 }} />,
                placement: "left"
              }}
              variant="secondary"
              onClick={() =>
                updateStripePriceForUser({
                  variables: { billingPeriod: selectedPrice.period }
                })
              }
              loading={isUpdatingStripePriceForUser}
              caption={
                <Text variant="bodySmall">
                  This may result in charges to your payment method.
                </Text>
              }
              sx={{ width: "100%", height: "fit-content" }}
            >
              Change billing period
            </LoadingButton>
          ) : (
            <LoadingButton
              {...buttonProps}
              {...buttonIconProps}
              onClick={checkoutRedirect}
              loading={isCheckoutRedirecting}
            >
              Continue to payment
            </LoadingButton>
          ))}
      </AutoLayout>
    </AccountManagementFormContent>
  );
}

export default ManageSubscription;
