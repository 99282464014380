/**
 * @generated SignedSource<<daed85e0ac452337befe9718b8f50d53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArtistPartnershipStatus = "ACTIVELY_LOOKING" | "NOT_LOOKING" | "OPEN";
export type ArtistSeekingServiceService = "CONTENT_PRODUCTION" | "DISTRIBUTION" | "FUNDING" | "INDUSTRY" | "INFLUENCER" | "MARKETING";
import { FragmentRefs } from "relay-runtime";
export type ArtistAttributes_artist$data = {
  readonly genre: {
    readonly genre: string;
  } | null;
  readonly location: string | null;
  readonly partnershipStatus: ArtistPartnershipStatus;
  readonly seekingServices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly service: ArtistSeekingServiceService;
      } | null;
    } | null>;
  };
  readonly user: {
    readonly lastActiveAt: any | null;
  } | null;
  readonly " $fragmentType": "ArtistAttributes_artist";
};
export type ArtistAttributes_artist$key = {
  readonly " $data"?: ArtistAttributes_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistAttributes_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistAttributes_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastActiveAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GenreNode",
      "kind": "LinkedField",
      "name": "genre",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "genre",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnershipStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistSeekingServiceNodeConnection",
      "kind": "LinkedField",
      "name": "seekingServices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistSeekingServiceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArtistSeekingServiceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "service",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "a0d278a3aa54c00c693743b977eb3946";

export default node;
