import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import AutoLayout, {
  Fixed
} from "../../../../../../components/01_Core/AutoLayout";
import AvatarIcon from "../../../../../../components/01_Core/Users/AvatarIcon";
import {
  rvMap,
  useFindActiveRV
} from "../../../../../../utils/responsiveUtils";
import { ITextFragment } from "../../../../../../utils/textFragments";

export type BannerSize = "small" | "large";

interface ISender {
  displayName: string;
  thumbnailUrl: string;
}

interface IBannerProps {
  size: ResponsiveValue<BannerSize>;
  senders: ISender[];
  textFragments: ITextFragment[];
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1432%3A24835
 */
function Banner(props: IBannerProps): ReactElement {
  const textType = useFindActiveRV(props.size, getTextType);

  const avatars = props.senders.map((sender, i) => {
    return (
      <AvatarIcon
        key={i}
        imageUrl={sender.thumbnailUrl}
        size={props.size}
        sx={{
          border: "1px solid",
          borderColor: "midGray100",
          position: "relative",
          left: `-${12 * i}px`
        }}
      />
    );
  });

  return (
    <AutoLayout
      p={rvMap(props.size, getPadding)}
      bg={"white100"}
      sx={{
        border: "1px solid",
        borderColor: "midGray70"
      }}
      spacing={rvMap(props.size, getSpacing)}
      dependentProps={{
        direction: "horizontal"
      }}
      resizingX={Fixed("auto")}
      resizingY={Fixed("auto")}
    >
      <Flex>{avatars}</Flex>
      <Flex sx={{ flexWrap: "wrap" }}>
        {props.textFragments.map(({ kind, text }, i) => {
          return (
            <Text
              key={i}
              variant={textType}
              color={"black100"}
              sx={{
                whiteSpace: "pre"
              }}
            >
              {kind === "emphasis" ? <strong>{text}</strong> : text}
            </Text>
          );
        })}
      </Flex>
    </AutoLayout>
  );
}

function getTextType(size: BannerSize) {
  switch (size) {
    case "small":
      return "bodyMedium";
    case "large":
      return "bodyLarge";
  }
}

function getSpacing(size: BannerSize): number {
  switch (size) {
    case "large":
      return 24;
    case "small":
      return 16;
  }
}

export function getPadding(size: BannerSize): number {
  switch (size) {
    case "large":
      return 24;
    case "small":
      return 16;
  }
}

export default Banner;
