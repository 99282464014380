import { Properties } from "csstype";
import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Image } from "theme-ui";

import { rvMap } from "../../../utils/responsiveUtils";
import Indiglyph from "../../02_Brand_Assets/Indiglyph";

type DisplayAvatarSize = "small" | "medium" | "large" | "extraLarge";
type DisplayAvatarType = "rounded" | "square" | "sampled" | "roundedSquare";

export interface IDisplayAvatarProps {
  imageUrl: string;
  size: ResponsiveValue<DisplayAvatarSize>;
  type?: DisplayAvatarType;
  sampleColor?: Properties["color"];
}

/**
 * 04 Display Avatar
 *
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1499%3A9359
 */
function DisplayAvatar(props: IDisplayAvatarProps): ReactElement {
  const { imageUrl, size, type: rawType, sampleColor, ...imageProps } = props;
  const type: DisplayAvatarType = rawType ?? "rounded";

  return (
    <Box sx={{ size: rvMap(size, getAvatarSize), position: "relative" }}>
      {props.type === "sampled" && (
        <Indiglyph
          type={"01_spotlight"}
          color={"sampled"}
          sampleColor={sampleColor}
          sxOverrides={{ position: "absolute", width: "100%", height: "100%" }}
        />
      )}
      <Image
        src={imageUrl}
        sx={{
          width: "100%",
          userSelect: "none",
          objectFit: "cover",
          aspectRatio: "1/1",
          objectPosition: "50% 30%",
          borderRadius: () => {
            switch (type) {
              case "rounded":
                return "50%";
              case "square":
              case "sampled":
                return undefined;
              case "roundedSquare":
                return "10%";
            }
          }
        }}
        {...imageProps}
      />
    </Box>
  );
}

function getAvatarSize(size: DisplayAvatarSize) {
  switch (size) {
    case "small":
      return 148;
    case "medium":
      return 192;
    case "large":
      return 240;
    case "extraLarge":
      return 320;
  }
}

export default DisplayAvatar;
