import numeral from "numeral";
import { ReactNode } from "react";
import { ThemeUIStyleObject } from "theme-ui";

export function noop(): void {
  return;
}

export function lockBodyScroll(): void {
  document.body.style.overflow = "hidden";
}

export function unlockBodyScroll(): void {
  document.body.style.overflow = "unset";
}

// https://stackoverflow.com/a/13924997
export function lineClampStyles(lines: number): ThemeUIStyleObject {
  return {
    overflow: "hidden",
    paddingRight: "0.15em", // Prevents italics from being cut off by overflow: hidden
    display: "-webkit-box",
    WebkitLineClamp: lines.toString(),
    lineClamp: lines.toString(),
    WebkitBoxOrient: "vertical"
  };
}

export function formatNumeral(num: number, forceMinTwoSigFigs = false): string {
  const numFormattedDigitsWithoutDecimal = numeral(num)
    .format("0a")
    .match(/\d+/)?.[0]?.length;

  const forceDecimal =
    forceMinTwoSigFigs && num >= 10 && numFormattedDigitsWithoutDecimal === 1;
  let format = forceDecimal ? "0.0a" : "0[.]0a";
  // Hide decimals if the number has more than 2 digits
  if (numFormattedDigitsWithoutDecimal >= 2) format = "0a";
  return numeral(num).format(format);
}

// Check that array includes all possible values of its type
// https://stackoverflow.com/a/75418615/215044
const arrayOfAll =
  <T>() =>
  <U extends T[]>(
    array: U & ([T] extends [U[number]] ? unknown : "Invalid") & { 0: T }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ) =>
    array;
export { arrayOfAll };

export function uploadedImageOrFallback(
  uploadedImageUrl: string,
  fallbackImageUrl: string
): string {
  const hasUploadedImage =
    uploadedImageUrl && !uploadedImageUrl.includes("no_user.png");
  if (hasUploadedImage) return uploadedImageUrl;
  if (fallbackImageUrl) return fallbackImageUrl;
  return uploadedImageUrl;
}

export function summarizeList(
  labels: ReactNode[],
  maxToShow: number,
  summaryWord: string = "total"
): ReactNode[] {
  const numToShowDirectly =
    labels.length <= maxToShow ? labels.length : maxToShow - 1;
  return [
    ...labels.slice(0, numToShowDirectly),
    ...(labels.length > numToShowDirectly
      ? [
          maxToShow === 1
            ? `${labels.length - numToShowDirectly} ${summaryWord}`
            : `${labels.length - numToShowDirectly} more`
        ]
      : [])
  ];
}

export function toSentence(
  labels: ReactNode[],
  conjunction: "and" | "or" = "and"
): ReactNode[] {
  switch (labels.length) {
    case 1:
      return [labels[0]];
    case 2:
      return [labels[0], ` ${conjunction} `, labels[1]];
    default:
      return [
        ...labels.slice(0, -1).flatMap(label => [label, ", "]),
        ` ${conjunction} `,
        labels[labels.length - 1]
      ];
  }
}
