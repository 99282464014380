import {
  ArtistEditSocialLinks_artist$data,
  ArtistEditSocialLinks_artist$key
} from "__generated__/ArtistEditSocialLinks_artist.graphql";
import {
  ArtistEditSocialLinksMutation,
  ArtistSocialLinks,
  ProfilePlatform
} from "__generated__/ArtistEditSocialLinksMutation.graphql";
import { ReactElement, useContext } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { graphql, useFragment } from "react-relay";
import { Link, Text } from "theme-ui";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import useMutationPromise from "../../hooks/useMutationPromise";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import Footer from "./Footer";
import Platform from "./FormFields/Platform";

function ArtistEditSocialLinks(props: {
  artist: ArtistEditSocialLinks_artist$key;
}): ReactElement {
  const artist = useFragment(
    graphql`
      fragment ArtistEditSocialLinks_artist on ArtistNode {
        id
        profiles {
          edges {
            node {
              id
              url
              platform
            }
          }
        }
      }
    `,
    props.artist
  );

  const notifyBanner = useContext(BannerContext);
  const [commit] = useMutationPromise<ArtistEditSocialLinksMutation>(graphql`
    mutation ArtistEditSocialLinksMutation(
      $input: EditArtistSocialLinksMutationInput!
    ) {
      editArtistSocialLinks(data: $input) {
        artist {
          profiles {
            edges {
              node {
                id
                url
                platform
              }
            }
          }
        }
      }
    }
  `);
  const onSubmit = (socialLinks: ArtistSocialLinks) =>
    commit({
      variables: { input: { artistNodeId: artist.id, socialLinks } }
    }).then(() =>
      notifyBanner({
        type: "neutral",
        children: "Your social links have been updated."
      })
    );
  const initialValues: ArtistSocialLinks = {
    spotify: getProfileUrl("SPOTIFY", artist),
    instagram: getProfileUrl("INSTAGRAM", artist),
    tiktok: getProfileUrl("TIKTOK", artist),
    youtube: getProfileUrl("YOUTUBE", artist),
    soundcloud: getProfileUrl("SOUNDCLOUD", artist),
    twitter: getProfileUrl("TWITTER", artist)
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} key={"form"}>
      {(formProps: FormRenderProps<ArtistSocialLinks>) => {
        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100%" }}>
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Social Links"}
                description={
                  "Connect your social profiles to share your stats."
                }
              />
              <ArtistSocialLinkFields initialValues={initialValues} />
              <Footer
                buttonDisabled={
                  formProps.pristine || formProps.hasValidationErrors
                }
                loading={formProps.submitting}
                onClick={formProps.handleSubmit}
                size={["small", "large", "large", "large"]}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}

export function ArtistSocialLinkFields(props: {
  initialValues: ArtistSocialLinks;
}): ReactElement {
  const SpotifySetOnceCaption = () => (
    <Text variant={"bodyMedium"} color={"black100"}>
      Your Spotify URL can only be set once. If there is an issue with this, you
      can contact{" "}
      <strong>
        <Link
          href={"mailto:support@indify.io"}
          sx={{
            color: "secondary100",
            textDecoration: "none",
            ":hover": {
              textDecoration: "underline"
            }
          }}
        >
          support@indify.io
        </Link>
      </strong>{" "}
      for help.
    </Text>
  );

  return (
    <AutoLayout
      spacing={[32, 48, 48, 48]}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical"
      }}
    >
      <Platform
        platform={"SPOTIFY"}
        disabled={!!props.initialValues.spotify}
        caption={<SpotifySetOnceCaption />}
      />
      <Platform platform={"TIKTOK"} />
      <Platform platform={"YOUTUBE"} />
      <Platform platform={"SOUNDCLOUD"} />
      <Platform platform={"INSTAGRAM"} />
      <Platform platform={"TWITTER"} />
    </AutoLayout>
  );
}

function getProfileUrl(
  platform: ProfilePlatform,
  artist: ArtistEditSocialLinks_artist$data
) {
  const edge = artist.profiles.edges.find(e => e.node.platform === platform);

  return edge ? edge.node.url : null;
}

export default ArtistEditSocialLinks;
