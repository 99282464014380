import { ReactElement } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder, {
  FieldPrefix
} from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { requiredStringValidator } from "../../../../../components/01_Core/Forms/utils/validators";
import Header from "../../03_UI_Kit/Forms/Header";
import Platform from "../../08_Account_Management/FormFields/Platform";

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=466%3A24331
 */
function ArtistNameSocialLinks(): ReactElement {
  return (
    <AutoLayout
      spacing={[32, 48, 48, 48]}
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
    >
      <Header size={["small", "small", "large", "large"]}>
        Add your details
      </Header>
      <FieldBuilder
        fieldName={"name"}
        inputField={TextInput}
        validator={requiredStringValidator}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Artist name",
          caption:
            "This is how you will appear to the indify Partnerships community. You can change this at any time."
        }}
      />
      <FieldPrefix prefix={"socialLinks"}>
        <AutoLayout
          spacing={[32, 48, 48, 48]}
          resizingX={FillContainer()}
          dependentProps={{
            direction: "vertical"
          }}
        >
          <Platform platform={"SPOTIFY"} label={"Spotify artist profile"} />
          <Platform platform={"TIKTOK"} label={"TikTok artist profile"} />
          <Platform platform={"INSTAGRAM"} label={"Instagram artist profile"} />
        </AutoLayout>
      </FieldPrefix>
    </AutoLayout>
  );
}

export default ArtistNameSocialLinks;
