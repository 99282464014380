import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  Fixed
} from "../../../../../components/01_Core/AutoLayout";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { useFindActiveRV } from "../../../../../utils/responsiveUtils";

type HeaderSize = "small" | "large";
interface IHeaderProps {
  size: ResponsiveValue<HeaderSize>;
  children: string;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1363%3A15
 */
function Header(props: IHeaderProps): ReactElement {
  const textType = useFindActiveRV(props.size, getTextType);

  return (
    <AutoLayout
      resizingX={Fixed("auto")}
      spacing={16}
      dependentProps={{
        direction: "vertical"
      }}
      sx={{ width: "100%" }}
    >
      <Text variant={textType} color={"black100"}>
        {props.children}
      </Text>
      <Divider />
    </AutoLayout>
  );
}

function getTextType(size: HeaderSize) {
  switch (size) {
    case "small":
      return "h500";
    case "large":
      return "h600";
  }
}

export default Header;
