import { FORM_ERROR } from "final-form";
import { Fragment, ReactElement, useState } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { Box, Flex, Link, Text } from "theme-ui";

import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { requiredEmailValidator } from "../../../../../components/01_Core/Forms/utils/validators";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { useAuth } from "../../../hooks/useAuth";

export interface IForgotPasswordFormValues {
  email: string;
}

function ForgotPasswordForm(): ReactElement {
  const { passwordReset } = useAuth();
  const [showSubmittedDisplay, setShowSubmittedDisplay] =
    useState<boolean>(false);
  const onSubmit = (values: IForgotPasswordFormValues) => {
    return passwordReset(values.email).then(
      _ => {
        setShowSubmittedDisplay(true);
      },
      reason => {
        return {
          [FORM_ERROR]: reason?.kind ?? "Forgot Password Request Failed"
        };
      }
    );
  };

  return (
    <Fragment>
      <GridItem sx={{ justifyContent: "center" }}>
        <Text variant={"h600"} mb={40} sx={{ textAlign: "center" }}>
          Reset your indify password
        </Text>
      </GridItem>
      <Form initialValues={{ email: "" }} onSubmit={onSubmit}>
        {(formProps: FormRenderProps<IForgotPasswordFormValues>) => (
          <form onSubmit={formProps.handleSubmit}>
            <Flex
              bg={"white100"}
              sx={{
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #EAE8E6",
                borderRadius: "4px",
                overflow: "hidden"
              }}
            >
              <Box p={[24, 48]} sx={{ width: "100%" }}>
                {showSubmittedDisplay ? (
                  <Text
                    variant={"bodyLarge"}
                    color={"black100"}
                    sx={{ lineHeight: "24px", textAlign: "center" }}
                  >
                    If your email was found in our system, an email with
                    instructions on how to reset your password was sent to{" "}
                    <Text
                      variant={"bodyLarge"}
                      sx={{ display: "inline", fontWeight: "bold" }}
                    >
                      {formProps.values.email}
                    </Text>
                    .
                  </Text>
                ) : (
                  <Fragment>
                    <FieldBuilder
                      inputField={TextInput}
                      fieldName={"email"}
                      validator={requiredEmailValidator}
                      inputFieldProps={{
                        type: "email",
                        label: "Email",
                        placeholder: "name@example.com",
                        size: ["medium", "large", "large", "large"]
                      }}
                    />
                    <Box mt={11} mb={26}>
                      <Text
                        variant={"h100"}
                        color={"#FF9900"}
                        sx={{
                          display:
                            formProps.hasSubmitErrors ||
                            formProps.hasValidationErrors
                              ? "initial"
                              : "none"
                        }}
                      >
                        {formProps.submitError === "bad_inputs" ? (
                          <Box>Please check the supplied email.</Box>
                        ) : null}
                      </Text>
                    </Box>
                    <LoadingButton
                      variant={"primary"}
                      size={"large"}
                      disabled={false}
                      onClick={formProps.handleSubmit}
                      loading={formProps.submitting}
                      type={"submit"}
                      sx={{
                        width: "100%"
                      }}
                    >
                      Reset password
                    </LoadingButton>
                  </Fragment>
                )}
              </Box>
            </Flex>
          </form>
        )}
      </Form>
      <GridItem sx={{ justifyContent: "center" }}>
        <Text
          variant={"h200"}
          mt={"24px"}
          color={"black100"}
          sx={{ textAlign: "center" }}
        >
          Have a question?
        </Text>
      </GridItem>
      <GridItem sx={{ justifyContent: "center" }}>
        <Text
          variant={"bodyMedium"}
          mt={"6px"}
          color={"black100"}
          sx={{ textAlign: "center" }}
        >
          You can always contact us at{" "}
          <Link href={""}>
            <Text
              variant={"bodyMedium"}
              color={"black100"}
              sx={{ display: "inline", fontWeight: "bold" }}
            >
              support@indify.io
            </Text>
          </Link>
        </Text>
      </GridItem>
    </Fragment>
  );
}

export default ForgotPasswordForm;
