/**
 * @generated SignedSource<<64f1581f42fcc9335852fb6dd30d54ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArtistPartnershipStatus = "ACTIVELY_LOOKING" | "NOT_LOOKING" | "OPEN";
import { FragmentRefs } from "relay-runtime";
export type AuthUserAvatarName_authUser$data = {
  readonly actor: {
    readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  } | null;
  readonly artist: {
    readonly partnershipStatus: ArtistPartnershipStatus;
    readonly visible: boolean;
  } | null;
  readonly " $fragmentType": "AuthUserAvatarName_authUser";
};
export type AuthUserAvatarName_authUser$key = {
  readonly " $data"?: AuthUserAvatarName_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthUserAvatarName_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthUserAvatarName_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visible",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "partnershipStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarName_actor"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "bd8ea9730589da3b52e8b9a7b4a9ba83";

export default node;
