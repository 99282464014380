import Color from "color";

export function darkenColor(color: Color): Color {
  const targetSaturation = 30;
  const maxSaturation = 40;
  const targetLightness = 26;
  const maxLightness = 45;

  const [h, s, l] = color.hsl().array();

  return Color.hsl(
    h,
    s > maxSaturation ? targetSaturation : s,
    l > maxLightness ? targetLightness : l
  );
}
