import _ from "lodash";
import { ResponsiveValue } from "styled-system";

import { useBreakpoint } from "./useBreakpoints";

export function rvMap<From, To>(
  input: ResponsiveValue<From>,
  func: (i: From) => To
): ResponsiveValue<To> {
  if (input == null) {
    return null;
  } else if (Array.isArray(input)) {
    return input.map(func);
  } else if (typeof input === "object") {
    return func(input as From);
  } else {
    return func(input);
  }
}

export function rvZip<A, B>(
  responsiveValueA: ResponsiveValue<A>,
  responsiveValueB: ResponsiveValue<B>
): ResponsiveValue<{ a: A; b: B }> {
  if (responsiveValueA == null) {
    return rvMap(responsiveValueB, b => ({
      a: null,
      b
    }));
  } else if (Array.isArray(responsiveValueA)) {
    if (Array.isArray(responsiveValueB)) {
      return _.zip(responsiveValueA, responsiveValueB).map(([a, b]) => ({
        a,
        b
      }));
    } else {
      return rvMap(rvZip(responsiveValueB, responsiveValueA), ({ a, b }) => ({
        a: b,
        b: a
      }));
    }
  } else if (typeof responsiveValueA === "object") {
    throw new Error("Responsive objects not supported");
  } else {
    return rvMap(responsiveValueB, b => ({
      a: responsiveValueA,
      b
    }));
  }
}

export function useActiveResponsiveValue<RvType>(
  rv: ResponsiveValue<RvType>
): RvType {
  const breakpointMap = useBreakpoint();

  if (rv == null) {
    return null;
  } else if (Array.isArray(rv)) {
    if (breakpointMap["mobile"]) {
      return rv[0];
    } else if (breakpointMap["tablet"]) {
      return rv[1];
    } else if (breakpointMap["tabletLarge"]) {
      return rv[2];
    } else if (breakpointMap["desktop"]) {
      return rv[3];
    }
  } else if (typeof rv === "object") {
    throw new Error("Responsive objects not supported");
  } else {
    return rv;
  }
}

export function useFindActiveRV<From, To>(
  rv: ResponsiveValue<From>,
  func: (i: From) => To
): To {
  const activeValue = useActiveResponsiveValue(rv);
  return func(activeValue);
}
