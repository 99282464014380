/**
 * @generated SignedSource<<ec472b14093b247e77bf8c176aeaad83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistDetails_referral$data = {
  readonly id: string;
  readonly thumbnailUrl: string;
  readonly " $fragmentType": "ArtistDetails_referral";
};
export type ArtistDetails_referral$key = {
  readonly " $data"?: ArtistDetails_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistDetails_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistDetails_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    }
  ],
  "type": "IndifyToArtistReferralNode",
  "abstractKey": null
};

(node as any).hash = "f85f4dae74e2bb463cf5f68e0a1c1c28";

export default node;
