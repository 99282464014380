import { motion } from "framer-motion";
import { ReactElement, ReactNode } from "react";
import { Flex, Text, Theme } from "theme-ui";

import { navigationHeight, zIndices } from "../../../theme/theme";
import { GridItem } from "../Grids/GridItem";

type BannerType = "neutral" | "success" | "warning" | "alert";
export interface IBannerProps {
  children: ReactNode;
  type: BannerType;
}

/**
 * TODO:
 *   - Implement icon & action variants
 *   - Implement dismissable variant
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A2037
 */
function Banner(props: IBannerProps): ReactElement {
  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{
        position: "sticky",
        zIndex: zIndices.nav,
        width: "100%",
        height: 0,
        top: navigationHeight
      }}
    >
      <motion.div
        key={"banner"}
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 0 }}
        style={{ width: "100%", height: "100%" }}
      >
        <Flex
          bg={theme => getBg(props.type, theme)}
          padding={"10px"}
          sx={{ justifyContent: "center", width: "100%" }}
        >
          <Text variant={"bodyMedium"} color={"black100"}>
            {props.children}
          </Text>
        </Flex>
      </motion.div>
    </GridItem>
  );
}

function getBg(type: BannerType, theme: Theme) {
  switch (type) {
    case "neutral":
      return theme.colors.secondary10;
    case "success":
      return theme.colors.success05;
    case "warning":
      return theme.colors.warning05;
    case "alert":
      return theme.colors.alert05;
  }
}

export default Banner;
