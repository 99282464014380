import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex } from "theme-ui";

import { NodeId } from "../../../types/relay_types";
import { useFindActiveRV } from "../../../utils/responsiveUtils";
import AvatarIcon from "./AvatarIcon";

type Avatar = {
  id: NodeId;
  imageUrl: string;
};

type MultipleAvatarsSize =
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";

interface IMultipleAvatarsProps {
  size: ResponsiveValue<MultipleAvatarsSize>;
  avatars: Avatar[];
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1435%3A184
 */
function MultipleAvatars(props: IMultipleAvatarsProps): ReactElement {
  if (props.avatars.length === 0) {
    return null;
  } else if (props.avatars.length === 1) {
    return (
      <AvatarIcon imageUrl={props.avatars[0].imageUrl} size={props.size} />
    );
  }

  const overlapPx = useFindActiveRV(props.size, getOverlapPx);

  return (
    <Flex sx={{ flexShrink: 0 }}>
      {props.avatars.slice(0, 2).map((avatar, i) => (
        <AvatarIcon
          key={avatar.id}
          imageUrl={avatar.imageUrl}
          size={props.size}
          outline={true}
          sx={{
            position: "relative",
            left: `${-1 * overlapPx * i}px`
          }}
        />
      ))}
    </Flex>
  );
}

function getOverlapPx(size: MultipleAvatarsSize) {
  switch (size) {
    case "extraLarge":
      return 16;
    case "large":
    case "medium":
    case "small":
      return 8;
    case "extraSmall":
      return 4;
  }
}

export default MultipleAvatars;
