import { ReactElement, useState } from "react";
import { Flex, Text } from "theme-ui";

import Ic24Minus from "../../../../../../imgs/icons/ic24-minus.svg";
import Ic24PlusCircle from "../../../../../../imgs/icons/ic24-plus-circle.svg";
import { Loader } from "../../../../../components/01_Core/999_Miscellaneous/Loader";
import Button from "../../../../../components/01_Core/Buttons/Button";

type EmbedType = "album" | "track" | "artist";

interface IArtistProfileTheSoundProps {
  type: EmbedType;
  spotifyId: string;
  inline?: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2402%3A18724
 */
function ArtistProfileTheSound(
  props: IArtistProfileTheSoundProps
): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const openTheSound = () => setIsOpen(true);
  const closeTheSound = () => setIsOpen(false);

  const IFrameEmbed = () => (
    <iframe
      src={`https://open.spotify.com/embed/${props.type}/${props.spotifyId}`}
      width={props.inline ? "100%" : 415}
      height={80}
      frameBorder={0}
      allowTransparency={true}
      allow={"encrypted-media"}
      onLoad={() => {
        setIsLoading(false);
      }}
      style={{
        display: isLoading ? "none" : "block"
      }}
    />
  );

  return props.inline ? (
    <IFrameEmbed />
  ) : isOpen ? (
    <Flex
      onClick={closeTheSound}
      backgroundColor={"lightGray100"}
      sx={{
        flexDirection: "column",
        position: "fixed",
        bottom: 0,
        left: 24,
        boxShadow: "0px 4px 16px rgba(27, 27, 27, 0.16)",
        borderRadius: "8px 8px 0 0",
        ":hover": {
          cursor: "pointer"
        }
      }}
    >
      <Flex
        p={"12px 32px"}
        backgroundColor={"primary100"}
        sx={{
          justifyContent: "space-between",
          width: "100%",
          borderRadius: "8px 8px 0 0"
        }}
      >
        <Text variant={"bodyLarge"} color={"white"}>
          The sound
        </Text>
        <Ic24Minus color={"white"} />
      </Flex>
      {isLoading ? (
        <Flex
          sx={{
            justifyContent: "center",
            width: props.inline ? "100%" : "415px",
            height: "80px"
          }}
        >
          <Loader />
        </Flex>
      ) : null}
      <IFrameEmbed />
    </Flex>
  ) : (
    <Button
      onClick={openTheSound}
      size={"large"}
      variant={"tertiary"}
      disabled={false}
      iconProps={{
        placement: "right",
        icon: <Ic24PlusCircle />
      }}
      sx={{
        position: "fixed",
        bottom: 24,
        left: 24,
        boxShadow: "0px 4px 16px rgba(27, 27, 27, 0.16)"
      }}
    >
      The sound
    </Button>
  );
}

export default ArtistProfileTheSound;
