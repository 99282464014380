/**
 * @generated SignedSource<<a9d87ed5c2852f64932f6577bdd27a51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistManagerInfo_artist$data = {
  readonly id: string;
  readonly managerCompany: string | null;
  readonly managerEmail: string | null;
  readonly managerLink: string | null;
  readonly managerName: string | null;
  readonly " $fragmentType": "ArtistManagerInfo_artist";
};
export type ArtistManagerInfo_artist$key = {
  readonly " $data"?: ArtistManagerInfo_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistManagerInfo_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistManagerInfo_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerCompany",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerEmail",
      "storageKey": null
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "db59ce70e739b71a0bae13b7a5b730aa";

export default node;
