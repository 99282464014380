/**
 * @generated SignedSource<<02785e747e0da5b320111111985f247d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleasesGrid_artist$data = {
  readonly offers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "OfferNode";
      } | null;
    } | null>;
  };
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "ReleaseNode";
        readonly id: string;
        readonly releaseDate: any | null;
        readonly upc: string;
        readonly " $fragmentSpreads": FragmentRefs<"ReleaseCard_release">;
      } | null;
    } | null>;
  };
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "UnreleasedReleaseNode";
        readonly id: string;
        readonly updatedAt: any;
        readonly " $fragmentSpreads": FragmentRefs<"ReleaseCard_release">;
      } | null;
    } | null>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TeamCard_artist">;
  readonly " $fragmentType": "ReleasesGrid_artist";
};
export type ReleasesGrid_artist$key = {
  readonly " $data"?: ReleasesGrid_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleasesGrid_artist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "isVisible",
    "value": true
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ReleaseCard_release"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleasesGrid_artist",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isAccepted",
          "value": true
        }
      ],
      "concreteType": "OfferNodeConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OfferNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "offers(isAccepted:true)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "upc",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "releaseDate",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "releases(isVisible:true)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "unreleasedReleases(isVisible:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TeamCard_artist"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};
})();

(node as any).hash = "a489662e0efea020a64fdf6e6556b54c";

export default node;
