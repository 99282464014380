import { RowFund_fund$key } from "__generated__/RowFund_fund.graphql";
import { RowFund_userArtist$key } from "__generated__/RowFund_userArtist.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import Ic20Email from "../../../../../../imgs/icons/ic20-email.svg";
import Ic24Suitcase from "../../../../../../imgs/icons/ic24-suitcase.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { ClickableIconBox } from "../../../../../components/01_Core/Foundations/Icons";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { Tooltip } from "../../../../../components/01_Core/Tooltips/Tooltip";
import { getUserActiveDotProps } from "../../../../../components/01_Core/Users/AvatarIcon";
import AvatarName from "../../../../../components/01_Core/Users/AvatarName";
import useToggleWatchCompany from "../../../../../mutations/useToggleWatchCompany";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import {
  formatNumeral,
  lineClampStyles,
  summarizeList
} from "../../../../../utils/utils";
import { useArtistCanMessageCompany } from "../../../hooks/useArtistCanMessageCompany";
import { useAuth } from "../../../hooks/useAuth";
import { useMessageActor } from "../../../hooks/useMessage";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import BookmarkIcon from "./BookmarkIcon";
import MultipleArtists from "./MultipleArtists";
import RowCell from "./RowCell";
import { actionIconSx, formatStat } from "./RowDeal";

type RowFundSize = "small" | "medium" | "large" | "extraLarge";

export function getGridTemplateAreas(size: RowFundSize): string {
  switch (size) {
    case "small":
      return `
        "fn fn fn wa"
        "sv sv ts ts"
      `;
    case "medium":
      return `"rk fn fn fn sv sv ts wa"`;
    case "large":
      return `"rk fn fn fn sv sv ts wa"`;
    case "extraLarge":
      return `"rk fn fn fn ab ab ab sv sv ts ts wa"`;
  }
}

interface IRowFundProps {
  fund: RowFund_fund$key;
  size: ResponsiveValue<RowFundSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=3212%3A19692
 */
function RowFund(props: IRowFundProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const gridTemplateAreas = getGridTemplateAreas(activeSize);

  const fund = useFragment(
    graphql`
      fragment RowFund_fund on CompanyNode {
        displayName
        slug
        ...useToggleWatchCompany_company
        thumbnailUrl
        totalStreams
        ranked
        watching
        recipientPartner {
          user {
            chatWithCurrentUser {
              ...useArtistCanMessageCompany_chat
            }
          }
        }
        partners {
          edges {
            node {
              user {
                lastActiveAt
              }
            }
          }
        }
        artistsBacked {
          ...MultipleArtists_artists
        }
        services {
          edges {
            node {
              service
            }
          }
        }
        recipientPartner {
          ...useMessage_actor
        }
      }
    `,
    props.fund
  );

  const { authUser } = useAuth();
  const { artist } = useFragment<RowFund_userArtist$key>(
    graphql`
      fragment RowFund_userArtist on UserNode {
        artist {
          ...useArtistCanMessageCompany_artist
        }
      }
    `,
    authUser
  );

  const [canMessagePartner, canMessageExplanation] = artist
    ? fund.recipientPartner
      ? useArtistCanMessageCompany(
          artist,
          fund.recipientPartner.user.chatWithCurrentUser,
          false
        )
      : [
          false,
          "This company has not designated a partner to receive messages."
        ]
    : [null, null];
  const [messagePartner, messagePartnerIsInFlight] = fund.recipientPartner
    ? useMessageActor(fund.recipientPartner)
    : [null, false];

  const [toggleWatchCompany, toggleWatchCompanyIsInFlight] =
    useToggleWatchCompany(fund);

  const activeDotProps = getUserActiveDotProps(
    _.max(fund.partners.edges.map(({ node }) => node.user?.lastActiveAt))
  );

  const gridItemProps = { gridTemplateAreas };
  return (
    <Box sx={{ backgroundColor: "white", width: "100%" }}>
      <FixedGrid sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"rk"} {...gridItemProps}>
          <RowCell align={activeSize !== "small" ? "right" : "left"}>
            <Text color={"deepGray100"}>
              {formatStat(fund.totalStreams ? fund.ranked : null)}
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"fn"} {...gridItemProps}>
          <RowCell>
            <AvatarName
              link={generatePath(InternalRoutes.fundProfile, {
                companySlug: fund.slug
              })}
              size={"small"}
              displayName={fund.displayName}
              imageUrl={fund.thumbnailUrl}
              textStyles={{ ...lineClampStyles(2), whiteSpace: undefined }}
              badge={activeDotProps}
            />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"ab"} {...gridItemProps}>
          <RowCell align={activeSize === "small" ? "left" : "left"}>
            <MultipleArtists
              artists={fund.artistsBacked}
              size={activeSize === "small" ? "extraSmall" : "small"}
            />
          </RowCell>
        </GridItem>
        <GridItem gridArea={"sv"} {...gridItemProps}>
          <RowCell>
            <AutoLayout
              dependentProps={{ direction: "horizontal" }}
              spacing={16}
              sx={{ width: "100%" }}
            >
              {summarizeList(
                fund.services.edges.map(
                  ({ node }) =>
                    GLOBALS.PARTNER_SERVICES[node.service].displayName
                ),
                useActiveResponsiveValue([1, 1, 1, 2]),
                "services"
              ).map((label, index) => (
                <SubtleBadge
                  key={index}
                  size={"small"}
                  color={"blue"}
                  icon={
                    <Ic24Suitcase style={{ width: "1.1em", height: "1.1em" }} />
                  }
                >
                  {label}
                </SubtleBadge>
              ))}
            </AutoLayout>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"ts"} {...gridItemProps}>
          <RowCell
            align={activeSize === "small" ? "left" : "right"}
            styles={{ width: "100%" }}
          >
            <Text
              variant={"bodyMedium"}
              color={"deepGray100"}
              sx={{ textAlign: "right", width: "100%" }}
            >
              {fund.totalStreams
                ? formatNumeral(fund.totalStreams, true)
                : formatStat(null)}
              {activeSize === "small" && " total streams"}
            </Text>
          </RowCell>
        </GridItem>
        <GridItem gridArea={"wa"} {...gridItemProps}>
          <RowCell align={activeSize === "small" ? "right" : "center"}>
            <AutoLayout
              spacing={8}
              dependentProps={{ direction: "horizontal" }}
              sx={{
                justifyContent: "flex-end",
                paddingRight: activeSize === "small" ? 0 : "4px",
                alignItems: "center"
              }}
            >
              <BookmarkIcon
                watching={fund.watching}
                onClick={toggleWatchCompany}
                loading={toggleWatchCompanyIsInFlight}
              />
              {authUser.userType === "artist" ? (
                <Tooltip
                  content={
                    canMessageExplanation ??
                    "Continue your conversation with this company."
                  }
                  size={"medium"}
                >
                  <ClickableIconBox
                    icon={Ic20Email}
                    loading={messagePartnerIsInFlight}
                    sx={actionIconSx(!canMessagePartner)}
                    onClick={canMessagePartner ? messagePartner : undefined}
                    size={20}
                  />
                </Tooltip>
              ) : (
                <Box sx={{ width: 20 }} />
              )}
            </AutoLayout>
          </RowCell>
        </GridItem>
      </FixedGrid>
      <Divider />
    </Box>
  );
}

export default RowFund;
