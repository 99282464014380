import { OfferFormHeader_artist$key } from "__generated__/OfferFormHeader_artist.graphql";
import { OfferFormHeader_partner$key } from "__generated__/OfferFormHeader_partner.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import Ic24Close from "../../../../../../imgs/icons/ic24-close.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import { IconBox } from "../../../../../components/01_Core/Foundations/Icons";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { useFindActiveRV } from "../../../../../utils/responsiveUtils";
import OfferPartnership from "./OfferPartnership";

export type OfferFormHeaderSize = "small" | "medium";

interface IOfferFormHeaderProps {
  size: ResponsiveValue<OfferFormHeaderSize>;
  artist: OfferFormHeader_artist$key;
  partner: OfferFormHeader_partner$key;
  closeModal: () => void;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2082%3A18677
 */
function OfferFormHeader(props: IOfferFormHeaderProps): ReactElement {
  const artist = useFragment(
    graphql`
      fragment OfferFormHeader_artist on ArtistNode {
        ...OfferPartnership_artist
      }
    `,
    props.artist
  );
  const partner = useFragment(
    graphql`
      fragment OfferFormHeader_partner on PartnerNode {
        ...OfferPartnership_partner
      }
    `,
    props.partner
  );

  const headerTextVariant = useFindActiveRV(props.size, getHeaderTextVariant);

  return (
    <AutoLayout
      spacing={0}
      dependentProps={{ direction: "vertical", alignment: "topCenter" }}
      resizingX={FillContainer()}
      sx={{
        backgroundColor: "lightGray100"
      }}
    >
      <FixedGrid py={"24px"}>
        <AutoLayout
          spacing={24}
          dependentProps={{ direction: "vertical", alignment: "center" }}
          px={"24px"}
          sx={{
            gridColumn: [
              "1 / span 4",
              "2 / span 6",
              "2 / span 6",
              "4 / span 6"
            ],
            justifySelf: "center",
            order: [2, 1, 1, 1]
          }}
        >
          <Text variant={headerTextVariant} color={"black100"}>
            Make an offer
          </Text>
          <OfferPartnership artist={artist} partner={partner} />
        </AutoLayout>
        <IconBox
          icon={Ic24Close}
          onClick={props.closeModal}
          sx={{
            cursor: "pointer",
            size: "24px",
            gridColumn: [
              "4 / span 1",
              "8 / span 1",
              "8 / span 1",
              "10 / span 1"
            ],
            justifySelf: ["end", "center", "center", "center"],
            order: [1, 2, 2, 2]
          }}
        />
      </FixedGrid>
      <Divider />
    </AutoLayout>
  );
}

function getHeaderTextVariant(size: OfferFormHeaderSize) {
  switch (size) {
    case "small":
      return "h500";
    case "medium":
      return "h600";
  }
}

export default OfferFormHeader;
