import { DealAnalysis_featuredDeal$key } from "__generated__/DealAnalysis_featuredDeal.graphql";
import { Fragment, ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../../components/01_Core/Grids/GridItem";
import { useActiveResponsiveValue } from "../../../../../../utils/responsiveUtils";
import { getFeaturedDealTitle } from "../../../../hooks/useFeaturedDealTitle";
import RecoupmentProfile from "./RecoupmentProfile";
import ReturnProfile from "./ReturnProfile";

export type DealAnalysisSize = "small" | "large";

interface IDealAnalysisProps {
  size: ResponsiveValue<DealAnalysisSize>;
  featuredDeal: DealAnalysis_featuredDeal$key;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=524%3A16259
 */

function DealAnalysis(props: IDealAnalysisProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const featuredDeal = useFragment(
    graphql`
      fragment DealAnalysis_featuredDeal on FeaturedDealNode {
        streamsPerDay
        releases {
          edges {
            node {
              ... on ReleaseInterface {
                name
                releaseType
                ...useReleaseMetadata_release
              }
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              ... on ReleaseInterface {
                name
                releaseType
                ...useReleaseMetadata_release
              }
            }
          }
        }
        ...ReturnProfile_featuredDeal
        ...RecoupmentProfile_featuredDeal
      }
    `,
    props.featuredDeal
  );
  const padding = ["48px 16px", "48px 16px", "48px 16px", "80px 0"];
  const title = getFeaturedDealTitle([
    ...featuredDeal.releases.edges.map(({ node: { name, releaseType } }) => ({
      name,
      releaseType
    })),
    ...featuredDeal.unreleasedReleases.edges.map(
      ({ node: { name, releaseType } }) => ({ name, releaseType })
    )
  ]);
  const titleVariant = activeSize === "large" ? "h600" : "h400";

  return (
    <FixedGrid sx={{ padding }}>
      <GridItem gridColumn={["1 / -1", "1 / -1", "1 / -1", "3 / span 8"]}>
        <AutoLayout
          spacing={32}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          {/* Title */}
          <AutoLayout spacing={0} dependentProps={{ direction: "vertical" }}>
            <Text variant={titleVariant} color={"deepGray100"}>
              {title.map((node, i) => (
                <Fragment key={i}>{node}</Fragment>
              ))}
            </Text>
            <Text variant={titleVariant}>Deal Analysis</Text>
          </AutoLayout>
          {/* Tables */}
          <AutoLayout
            spacing={64}
            resizingX={FillContainer()}
            dependentProps={{ direction: "vertical" }}
          >
            {!!featuredDeal.streamsPerDay && (
              <ReturnProfile size={props.size} featuredDeal={featuredDeal} />
            )}
            <RecoupmentProfile size={props.size} featuredDeal={featuredDeal} />
          </AutoLayout>
        </AutoLayout>
      </GridItem>
    </FixedGrid>
  );
}

export default DealAnalysis;
