import { DealCard_offer$key } from "__generated__/DealCard_offer.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Flex, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { ActorAvatarName } from "../../../../../components/01_Core/Users/AvatarName";
import useFeaturedDealTitle from "../../../hooks/useFeaturedDealTitle";
import ReleaseImagesGrid from "./ReleaseImagesGrid";

interface IDealCardProps {
  size: "small" | "large";
  offer: DealCard_offer$key;
}

dayjs.extend(relativeTime);

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=3316%3A19631
 */
function DealCard(props: IDealCardProps): ReactElement {
  const offer = useFragment(
    graphql`
      fragment DealCard_offer on OfferNode {
        dateOfferAccepted
        artist {
          ...AvatarName_actor
          ...ReleaseImagesGrid_artist
        }
        releases {
          edges {
            node {
              name
              type
              ...ReleaseImagesGrid_releases
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              name
              type
              ...ReleaseImagesGrid_unreleasedReleases
            }
          }
        }
        ...useFeaturedDealTitle
      }
    `,
    props.offer
  );

  return (
    <Flex
      sx={{
        border: "1px solid",
        borderColor: "midGray70",
        borderRadius: "4px",
        flexDirection: "column",
        height: props.size === "small" ? "408px" : "500px",
        width: "100%"
      }}
    >
      {/* Artist Block */}
      <Box
        sx={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          backgroundColor: "lightGray100",
          padding: props.size === "small" ? "8px" : "8px 16px",
          width: "100%"
        }}
      >
        <ActorAvatarName
          actor={offer.artist}
          size={props.size === "small" ? "small" : "medium"}
        />
      </Box>
      {/* Frame 1 */}
      <Flex
        sx={{
          flexGrow: 2,
          flexDirection: "column",
          justifyContent: "space-between",
          padding: props.size === "small" ? "8px" : "24px",
          width: "100%"
        }}
      >
        {/*Body*/}
        <AutoLayout
          spacing={16}
          dependentProps={{ direction: "vertical" }}
          resizingX={FillContainer()}
        >
          <ReleaseImagesGrid
            artist={offer.artist}
            releases={offer.releases.edges.map(e => e.node)}
            unreleasedReleases={offer.unreleasedReleases.edges.map(e => e.node)}
          />
          <Text variant={props.size === "small" ? "bodyMedium" : "bodyLarge"}>
            {useFeaturedDealTitle(offer)}
          </Text>
          <Divider />
        </AutoLayout>
        {/*Closed when*/}
        <Text
          variant={props.size === "small" ? "bodySmall" : "bodyMedium"}
          color={"deepGray100"}
        >
          Closed {dayjs().to(dayjs(offer.dateOfferAccepted))}
        </Text>
      </Flex>
    </Flex>
  );
}

export default DealCard;
