/**
 * @generated SignedSource<<6fb7572118d0a8118d9a9b6d6b27f361>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReleaseStatusMenu_DeleteMutation$variables = {
  id: string;
};
export type ReleaseStatusMenu_DeleteMutation$data = {
  readonly deleteUnreleasedRelease: {
    readonly artist: {
      readonly id: string;
    } | null;
  } | null;
};
export type ReleaseStatusMenu_DeleteMutation = {
  response: ReleaseStatusMenu_DeleteMutation$data;
  variables: ReleaseStatusMenu_DeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeleteUnreleasedReleaseMutation",
    "kind": "LinkedField",
    "name": "deleteUnreleasedRelease",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArtistNode",
        "kind": "LinkedField",
        "name": "artist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReleaseStatusMenu_DeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReleaseStatusMenu_DeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "033d6d9fb5110c81eff1568fc6586850",
    "id": null,
    "metadata": {},
    "name": "ReleaseStatusMenu_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ReleaseStatusMenu_DeleteMutation(\n  $id: ID!\n) {\n  deleteUnreleasedRelease(id: $id) {\n    artist {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e0fefcb0840eea9c848e1718f9f0165";

export default node;
