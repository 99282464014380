import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";

import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { requiredStringValidator } from "../../../../../components/01_Core/Forms/utils/validators";

export const legalNameFieldName = "legalName";

interface IOfferFormLegalName {
  size: ResponsiveValue<"small" | "medium" | "large">;
  label?: string;
  suppressValidationText?: boolean;
}

function OfferFormLegalName(props: IOfferFormLegalName): ReactElement {
  return (
    <FieldBuilder
      inputField={TextInput}
      fieldName={legalNameFieldName}
      inputFieldProps={{
        label: props.label ?? null,
        size: props.size,
        caption: "Please enter your name as it appears on your government ID."
      }}
      validator={requiredStringValidator}
      suppressValidationText={props.suppressValidationText ?? false}
    />
  );
}

export default OfferFormLegalName;
