import { ReactElement } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import getHasError from "../../../../../components/01_Core/Forms/utils/getHasError";
import {
  IFundingOfferFormFieldProps,
  IFundingOfferFormValues
} from "../../07_Deal_Flow/OfferFormFunding";
import FormSection from "./FormSection";
import FormSignatureField, { signatureFieldName } from "./FormSignatureField";
import OfferFormLegalName, { legalNameFieldName } from "./OfferFormLegalName";

export type IOfferFormSignatureProps = IFundingOfferFormFieldProps;

function OfferFormSignature(props: IOfferFormSignatureProps): ReactElement {
  const hasLegalNameError = getHasError<IFundingOfferFormValues>(
    props.showAllErrors,
    legalNameFieldName
  );
  const hasSignatureError = getHasError<IFundingOfferFormValues>(
    props.showAllErrors,
    signatureFieldName
  );

  return (
    <AutoLayout
      spacing={0}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
    >
      <FormSection
        heading={"Legal Name"}
        error={hasLegalNameError ? "Please enter your legal name." : null}
        size={props.size}
      >
        <OfferFormLegalName size={props.size} suppressValidationText={true} />
      </FormSection>
      <FormSection
        heading={"Signature"}
        error={
          hasSignatureError
            ? "Please draw your signature in the box below."
            : null
        }
        caption={"Please draw your signature in the above area."}
        size={props.size}
      >
        <FormSignatureField size={props.size} />
      </FormSection>
    </AutoLayout>
  );
}

export default OfferFormSignature;
