/**
 * @generated SignedSource<<b490bef83c271f3ed5a07c3fa5143e6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseIconsNames_releaseImages$data = {
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly thumbnailUrl: string | null;
      } | null;
    } | null>;
  };
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly thumbnailUrl: string | null;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "ReleaseIconsNames_releaseImages";
};
export type ReleaseIconsNames_releaseImages$key = {
  readonly " $data"?: ReleaseIconsNames_releaseImages$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseIconsNames_releaseImages">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnailUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleaseIconsNames_releaseImages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OfferFeaturedDealInterface",
  "abstractKey": "__isOfferFeaturedDealInterface"
};
})();

(node as any).hash = "eaaa0b128490e12887d85326299e35b7";

export default node;
