import { ReactElement, ReactNode, SyntheticEvent } from "react";
import { ResponsiveValue } from "styled-system";
import { Button as ThemeUIButton, ButtonProps, Flex, Text } from "theme-ui";

import { actionBorderSx } from "../../../pages/indify/components/03_UI_Kit/Discover/RowDeal";
import { rvMap, useFindActiveRV } from "../../../utils/responsiveUtils";
import AutoLayout, { FillContainer } from "../AutoLayout";
import { TextButtonType } from "./TextButton";

export type ButtonSize = "small" | "medium" | "large";
export type ButtonIconPlacement = "left" | "right";

export interface IButtonIconProps {
  icon: ReactNode;
  placement: ButtonIconPlacement;
}

export interface IButtonProps extends ButtonProps {
  onClick?: (e: SyntheticEvent) => void;
  children: ReactNode;
  caption?: ReactNode;
  variant: TextButtonType;
  size: ResponsiveValue<ButtonSize>;
  disabled?: boolean;
  iconProps?: IButtonIconProps;
  type?: "button" | "submit" | "reset";
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1278%3A22958
 */
function Button(props: IButtonProps): ReactElement {
  const {
    onClick,
    children,
    caption,
    variant,
    size,
    disabled,
    iconProps,
    sx,
    type,
    ...rest
  } = props;

  const fontVariant = useFindActiveRV(props.size, getFontVariant);

  const icon = props.iconProps && (
    <Flex
      mr={iconProps.placement === "left" ? "4px" : null}
      ml={iconProps.placement === "right" ? "4px" : null}
      sx={{
        size: rvMap(size, getIconSize),
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {iconProps.icon}
    </Flex>
  );

  return (
    <ThemeUIButton
      onClick={onClick}
      variant={variant}
      type={type ?? "button"}
      disabled={disabled}
      px={rvMap(size, getPx)}
      sx={{
        cursor: disabled ? "auto" : "pointer",
        borderRadius: rvMap(props.size, getBorderRadius),
        minWidth: "fit-content",
        height: rvMap(props.size, getHeight),
        ...actionBorderSx(disabled),
        ...sx
      }}
      {...rest}
    >
      <Text variant={fontVariant}>
        <AutoLayout
          spacing={8}
          dependentProps={{ direction: "vertical", alignment: "center" }}
          resizingX={FillContainer()}
        >
          <Flex
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {icon && iconProps.placement === "left" ? icon : null}
            <Text sx={{ whiteSpace: "nowrap" }}>
              <strong>{children}</strong>
            </Text>
            {icon && iconProps.placement === "right" ? icon : null}
          </Flex>
          {caption && <Text>{caption}</Text>}
        </AutoLayout>
      </Text>
    </ThemeUIButton>
  );
}

export default Button;

function getFontVariant(size: ButtonSize) {
  switch (size) {
    case "small":
      return "h100";
    case "medium":
      return "h200";
    case "large":
      return "h300";
  }
}

export function getHeight(size: ButtonSize): string {
  switch (size) {
    case "small":
      return "32px";
    case "medium":
      return "40px";
    case "large":
      return "48px";
  }
}

function getPx(size: ButtonSize) {
  switch (size) {
    case "small":
      return "16px";
    case "medium":
      return "24px";
    case "large":
      return "32px";
  }
}

function getIconSize(size: ButtonSize) {
  switch (size) {
    case "small":
      return "16px";
    case "medium":
      return "20px";
    case "large":
      return "24px";
  }
}

function getBorderRadius(size: ButtonSize) {
  switch (size) {
    case "small":
      return "4px";
    case "medium":
      return "6px";
    case "large":
      return "8px";
  }
}
