import { Children, ReactElement, ReactNode, useRef, useState } from "react";
import { Box } from "theme-ui";

export type IPagerPageLayoutArgs = {
  nextPage?: () => void;
  prevPage?: () => void;
  activePage: ReactElement;
  activePageIndex: number;
  numPages: number;
};

export type IPagerProps = {
  pageLayout: (pageLayoutProps: IPagerPageLayoutArgs) => ReactNode;
  children: ReactElement[];
};

export default function Pager(props: IPagerProps): ReactElement {
  const { children, pageLayout } = props;
  const [pageNum, setPageNum] = useState(0);

  const pageTopRef = useRef(null);

  const scrollToTop = () =>
    pageTopRef.current?.scrollIntoView({ behavior: "smooth" });

  const nextPage = () => {
    setPageNum(p => Math.min(p + 1, children.length - 1));
    scrollToTop();
  };
  const prevPage = () => {
    setPageNum(p => Math.max(p - 1, 0));
    scrollToTop();
  };

  const activePage = children[pageNum];
  const isLastPage = pageNum === Children.count(children) - 1;

  return (
    <Box ref={pageTopRef} sx={{ height: "inherit" }}>
      {pageLayout({
        nextPage: isLastPage ? undefined : nextPage,
        prevPage: pageNum === 0 ? undefined : prevPage,
        activePage,
        activePageIndex: pageNum,
        numPages: Children.count(children)
      })}
    </Box>
  );
}

export function PagerPage({ children }: { children: ReactNode }): ReactNode {
  return children;
}
