import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import { rvMap, useFindActiveRV } from "../../../../../utils/responsiveUtils";

export type CardHeadingLevel = "h2" | "h3";
export type CardHeadingSize = "extraLarge" | "large" | "medium" | "small";
interface ICardHeadingProps {
  size: ResponsiveValue<CardHeadingSize>;
  level: CardHeadingLevel;
  children: ReactNode;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=387%3A11248
 */
function CardHeadingPlain(props: ICardHeadingProps): ReactElement {
  const textVariant = useFindActiveRV(props.size, size =>
    getTextVariant(size, props.level)
  );

  return (
    <Box
      padding={rvMap(props.size, getBoxPadding)}
      sx={{ textAlign: "center" }}
    >
      <Text variant={textVariant} color={"black100"}>
        {props.children}
      </Text>
    </Box>
  );
}

function getTextVariant(size: CardHeadingSize, level: CardHeadingLevel) {
  switch (size) {
    case "extraLarge":
    case "large":
      switch (level) {
        case "h2":
          return "h600";
        case "h3":
          return "h400";
      }
    case "medium":
    case "small":
      switch (level) {
        case "h2":
          return "h500";
        case "h3":
          return "h300";
      }
  }
}

function getBoxPadding(size: CardHeadingSize) {
  switch (size) {
    case "extraLarge":
      return "64px 32px";
    case "large":
      return "32px";
    case "medium":
      return "24px";
    case "small":
      return "16px";
  }
}

export default CardHeadingPlain;
