import color from "./color";

const {
  black100,
  deepGray100,
  deepGray70,
  white100,
  white70,
  white40,
  primary85,
  alert50,
  success50,
  warning50
} = color;
const subVariants = [
  {
    name: "h900",
    fontFamily: "NB Akademie",
    fontSize: "80px",
    lineHeight: "84px",
    letterSpacing: "-.05em"
  },
  {
    name: "h800",
    fontFamily: "NB Akademie",
    fontSize: "72px",
    lineHeight: "80px",
    letterSpacing: "-.05em"
  },
  {
    name: "h700",
    fontFamily: "NB Akademie",
    fontSize: "64px",
    lineHeight: "72px",
    letterSpacing: "-.05em"
  },
  {
    name: "h600",
    fontFamily: "NB Akademie",
    fontSize: "56px",
    lineHeight: "64px",
    letterSpacing: "-.04em"
  },
  {
    name: "h500",
    fontFamily: "NB Akademie",
    fontSize: "48px",
    lineHeight: "56px",
    letterSpacing: "-.04em"
  },
  {
    name: "h450",
    fontFamily: "NB Akademie",
    fontSize: "36px",
    lineHeight: "40px",
    letterSpacing: "-.03em"
  },
  {
    name: "h400",
    fontFamily: "NB Akademie",
    fontSize: "28px",
    lineHeight: "32px",
    letterSpacing: "-.03em"
  },
  {
    name: "h300",
    fontFamily: "NB Akademie",
    fontSize: "22px",
    lineHeight: "28px",
    letterSpacing: "-.03em"
  },
  {
    name: "h200",
    fontFamily: "NB Akademie",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-.03em"
  },
  {
    name: "h100",
    fontFamily: "NB Akademie",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-.03em"
  },
  {
    fontFamily: "NB Akademie",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-.03em"
  },
  {
    fontFamily: "NB Akademie",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-.03em"
  },
  {
    name: "subtitle",
    fontFamily: "NB Akademie",
    fontSize: "28px",
    lineHeight: "32px",
    letterSpacing: "-.03em"
  },
  {
    name: "bodyLarge",
    fontFamily: "NB Akademie",
    fontSize: "22px",
    lineHeight: "28px",
    letterSpacing: "-.03em"
  },
  {
    name: "bodyMedium",
    fontFamily: "NB Akademie",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-.03em"
  },
  {
    name: "bodySmall",
    fontFamily: "NB Akademie",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-.03em"
  }
];

const buildColorlessVariant = () =>
  Object.fromEntries(
    subVariants.map(({ name, ...rest }) => [name, { ...rest }])
  );

const buildVariant = (variantColor: string) =>
  Object.fromEntries(
    subVariants.map(({ name, ...rest }) => [
      name,
      { color: variantColor, ...rest }
    ])
  );

export const fontVariants = {
  primary: buildVariant(black100),
  secondary: buildVariant(deepGray100),
  disabled: buildVariant(deepGray70),
  contrast: {
    primary: buildVariant(white100),
    secondary: buildVariant(white70),
    disabled: buildVariant(white40)
  },
  link: buildVariant(primary85),
  alert: buildVariant(alert50),
  success: buildVariant(success50),
  warning: buildVariant(warning50),
  h900: { fontSize: "" },
  h800: { fontSize: "" },
  h700: { fontSize: "" },
  h600: { fontSize: "" },
  h500: { fontSize: "" },
  h400: { fontSize: "" },
  h300: { fontSize: "" },
  h200: { fontSize: "" },
  h100: { fontSize: "" },
  subtitle: { fontSize: "" },
  bodyLarge: { fontSize: "" },
  bodyMedium: { fontSize: "" },
  bodySmall: { fontSize: "" },
  ...buildColorlessVariant()
};
