import { ToggleWatchFeaturedDealMutation } from "__generated__/ToggleWatchFeaturedDealMutation.graphql";
import { commitMutation, graphql } from "react-relay";

import environment from "../environment";
import { NodeId } from "../types/relay_types";

const toggleWatchFeaturedDealMutation = graphql`
  mutation ToggleWatchFeaturedDealMutation($featuredDealNodeId: String!) {
    toggleWatchFeaturedDeal(featuredDealId: $featuredDealNodeId) {
      featuredDeal {
        watching
      }
    }
  }
`;

const getToggleWatchFeaturedDeal = (featuredDealNodeId: NodeId) => (): void => {
  const variables = {
    featuredDealNodeId
  };
  commitMutation<ToggleWatchFeaturedDealMutation>(environment, {
    mutation: toggleWatchFeaturedDealMutation,
    variables
  });
};

export default getToggleWatchFeaturedDeal;
