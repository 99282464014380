/**
 * @generated SignedSource<<8781993d071bd04fa5f5829b943afec9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferPartnership_partner$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  readonly " $fragmentType": "OfferPartnership_partner";
};
export type OfferPartnership_partner$key = {
  readonly " $data"?: OfferPartnership_partner$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferPartnership_partner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferPartnership_partner",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarName_actor"
    }
  ],
  "type": "PartnerNode",
  "abstractKey": null
};

(node as any).hash = "28b6d912f9e2083478dc5616354871d1";

export default node;
