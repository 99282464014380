import { ArtistInfo_artist$key } from "__generated__/ArtistInfo_artist.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import DisplayAvatar from "../../../../components/01_Core/Users/DisplayAvatar";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { uploadedImageOrFallback } from "../../../../utils/utils";
import useImageSampleColor from "../../hooks/useImageSampleColor";
import ArtistProfileTheSound from "../03_UI_Kit/Releases/ArtistProfileTheSound";
import ArtistAttributes from "../03_UI_Kit/User_Profile/ArtistAttributes";
import About from "./About";
import FeaturedDeals from "./FeaturedDeals";
import SocialLinks from "./SocialLinks";

type ArtistInfoSize = "small" | "large";

interface IArtistInfoProps {
  artist: ArtistInfo_artist$key;
  size: ResponsiveValue<ArtistInfoSize>;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=530%3A4175
 */
function ArtistInfo(props: IArtistInfoProps): ReactElement {
  const artist = useFragment(
    graphql`
      fragment ArtistInfo_artist on ArtistNode {
        displayName
        imageUrl
        spotifyApiId
        spotifyProfile {
          imageUrl
        }
        about
        featuredDeals {
          edges {
            __typename
          }
        }
        profiles {
          edges {
            node {
              ...SocialLinks_profiles
            }
          }
        }
        ...ArtistAttributes_artist
        ...FeaturedDeals_artist
      }
    `,
    props.artist
  );
  const activeSize = useActiveResponsiveValue(props.size);
  const artistSampleColor = useImageSampleColor(artist.imageUrl);

  const imageUrl = uploadedImageOrFallback(
    artist.imageUrl,
    artist.spotifyProfile?.imageUrl
  );

  const hasFeaturedDeals = artist.featuredDeals.edges.length > 0;
  const shouldShowTheSound = activeSize === "small" && artist.spotifyApiId;

  return (
    <AutoLayout
      spacing={activeSize === "small" ? 32 : 48}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical",
        alignment: "topCenter"
      }}
      py={"32px"}
    >
      <AutoLayout
        spacing={activeSize === "small" ? 24 : 16}
        dependentProps={{ direction: "vertical", alignment: "topCenter" }}
      >
        <Text
          variant={activeSize === "small" ? "h700" : "h900"}
          color={"black100"}
          sx={{ textAlign: "center" }}
        >
          {artist.displayName}
        </Text>
        <ArtistAttributes artist={artist} />
      </AutoLayout>
      <DisplayAvatar
        type={"roundedSquare"}
        size={activeSize === "small" ? "large" : "extraLarge"}
        imageUrl={imageUrl}
        sampleColor={artistSampleColor}
      />
      <SocialLinks profiles={artist.profiles.edges.map(e => e.node)} />
      {hasFeaturedDeals && <FeaturedDeals artist={artist} />}
      {shouldShowTheSound && (
        <AutoLayout
          spacing={16}
          dependentProps={{ direction: "vertical", alignment: "left" }}
          resizingX={FillContainer()}
          sx={{ alignSelf: "flex-start" }}
        >
          <Text variant={"h400"} color={"black100"}>
            The sound
          </Text>
          <ArtistProfileTheSound
            type={"artist"}
            spotifyId={artist.spotifyApiId}
            inline={true}
          />
        </AutoLayout>
      )}
      {shouldShowTheSound && <Divider />}
      {artist.about && <About about={artist.about} size={props.size} />}
      {artist.about && <Divider />}
    </AutoLayout>
  );
}

export default ArtistInfo;
