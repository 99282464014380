import { graphql } from "react-relay";

// TODO: Come up with better name and file structure for these mutations
export const acceptOfferMutation = graphql`
  mutation mutationsOfferAcceptOfferMutation(
    $connections: [ID!]!
    $data: AcceptOfferMutationInput!
  ) {
    acceptOffer(data: $data) {
      chatMessage
        @prependNode(connections: $connections, edgeTypeName: "MessageEdge") {
        ...MessageWithOffer_message
        chat {
          ...InboxChat_chat
        }
      }
    }
  }
`;

export const declineOfferMutation = graphql`
  mutation mutationsOfferDeclineOfferMutation(
    $connections: [ID!]!
    $data: DeclineOfferMutationInput!
  ) {
    declineOffer(data: $data) {
      chatMessage
        @prependNode(connections: $connections, edgeTypeName: "MessageEdge") {
        ...MessageWithOffer_message
        chat {
          ...InboxChat_chat
        }
      }
    }
  }
`;

export const cancelOfferMutation = graphql`
  mutation mutationsOfferCancelOfferMutation(
    $connections: [ID!]!
    $data: CancelOfferMutationInput!
  ) {
    cancelOffer(data: $data) {
      chatMessage
        @prependNode(connections: $connections, edgeTypeName: "MessageEdge") {
        ...MessageWithOffer_message
        chat {
          ...InboxChat_chat
        }
      }
    }
  }
`;

export const sendOfferMutation = graphql`
  mutation mutationsOfferButtonSendMutation(
    $connections: [ID!]!
    $data: SendOfferMutationInput!
  ) {
    sendOffer(data: $data) {
      chatMessage
        @prependNode(connections: $connections, edgeTypeName: "MessageEdge") {
        ...MessageWithOffer_message
        chat {
          ...InboxChat_chat
        }
      }
    }
  }
`;

export const updateOrCreateFeaturedDealMutation = graphql`
  mutation mutationsCreateFeaturedDealMutation(
    $data: UpdateOrCreateFeaturedDealMutationInput!
  ) {
    updateOrCreateFeaturedDeal(data: $data) {
      featuredDeal {
        artist {
          ...ArtistManagerInfo_artist
          ...ArtistManageDeals_artist
          featuredDeals {
            edges {
              node {
                ...ArtistManageDeals_featuredDeals
              }
            }
          }
          ...ArtistManageReleases_artist
        }
      }
    }
  }
`;

export const deleteFeaturedDealMutation = graphql`
  mutation mutationsDeleteFeaturedDealMutation($id: ID!) {
    deleteFeaturedDeal(id: $id) {
      artist {
        featuredDeals {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const toggleDraftFeaturedDealMutation = graphql`
  mutation mutationsToggleDraftFeaturedDealMutation($id: ID!) {
    toggleDraftFeaturedDeal(id: $id) {
      featuredDeal {
        id
        status
      }
    }
  }
`;
