/**
 * @generated SignedSource<<dfaee4a1f431b5558797c19acd5c4c73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseInfoCardView_release$data = {
  readonly name: string;
  readonly soundLink: string | null;
  readonly thumbnailUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"useReleaseMetadata_release">;
  readonly " $fragmentType": "ReleaseInfoCardView_release";
};
export type ReleaseInfoCardView_release$key = {
  readonly " $data"?: ReleaseInfoCardView_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseInfoCardView_release">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleaseInfoCardView_release",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "soundLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReleaseMetadata_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "78ed135b61e8c002d65b375e7d8e11ac";

export default node;
