import { MessageWithOffer_message$key } from "__generated__/MessageWithOffer_message.graphql";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Text } from "theme-ui";

import { FluidGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { ActorAvatarIcon } from "../../../../../components/01_Core/Users/AvatarIcon";
import Linkify from "../../../../../utils/Linkify";
import { useAuth } from "../../../hooks/useAuth";
import { gridTemplateColumns } from "./ChatThread";
import MessageBody from "./MessageBody";
import OfferMessageShell from "./OfferMessage/OfferMessageShell";
import OfferMessageStatusUpdate from "./OfferMessage/OfferMessageStatusUpdate";
import OfferNotification from "./OfferNotification";

export interface IMessageWithOfferProps {
  message: MessageWithOffer_message$key;
}

function MessageWithOffer(props: IMessageWithOfferProps): ReactElement {
  const message = useFragment(
    graphql`
      fragment MessageWithOffer_message on ChatMessageNode {
        id
        message
        sentAt
        offer {
          id
          ...OfferMessageStatusUpdate_offer
        }
        user {
          name
          id
          actor {
            ...AvatarIcon_actor
          }
        }
        ...OfferMessageShell_message
      }
    `,
    props.message
  );

  const { authUser } = useAuth();

  dayjs.extend(calendar);
  const wrappedDate = dayjs(message.sentAt);

  const renderMessage = () => {
    const offerEvent = (() => {
      switch (message.message) {
        case GLOBALS.CHAT_MESSAGE_TYPES.OFFER_ACCEPTED:
          return "accepted";
        case GLOBALS.CHAT_MESSAGE_TYPES.OFFER_DECLINED:
          return "declined";
        case GLOBALS.CHAT_MESSAGE_TYPES.OFFER_CANCELED:
          return "canceled";
        default:
          return null;
      }
    })();

    if (message.message === GLOBALS.CHAT_MESSAGE_TYPES.OFFER_CREATED) {
      return message.offer ? (
        <OfferMessageShell
          message={message}
          size={["small", "medium", "medium", "medium"]}
        />
      ) : (
        <OfferNotification>Offer not found</OfferNotification>
      );
    } else if (offerEvent != null) {
      return message.offer ? (
        <OfferMessageStatusUpdate offer={message.offer} event={offerEvent} />
      ) : (
        <OfferNotification>Offer not found</OfferNotification>
      );
    } else {
      return (
        <MessageBody key={message.id} sender={message.user.name}>
          <Linkify>
            <Text sx={{ whiteSpace: "pre-line" }}>{message.message}</Text>
          </Linkify>
        </MessageBody>
      );
    }
  };

  return (
    <FluidGrid
      mt={"24px"}
      sx={{
        gridTemplateColumns,
        rowGap: "8px"
      }}
    >
      <GridItem
        gridColumn={[
          "1 / -1",
          "1 / -1",
          "1 / -1",
          "message-block-start / message-block-end"
        ]}
        sx={{ justifyContent: "center" }}
      >
        <Text
          variant={"secondary.bodySmall"}
          sx={{ textAlign: "center" }}
          title={wrappedDate.format("MMMM D, YYYY [at] h:mm A")}
        >
          {wrappedDate.calendar(null, {
            sameElse: "MMMM D, YYYY [at] h:mm A"
          })}
        </Text>
      </GridItem>
      <GridItem
        gridColumn={"avatar-start / avatar-end"}
        sx={{ justifyContent: "flex-end", height: "fit-content" }}
      >
        <ActorAvatarIcon
          actor={message.user.actor}
          size={"small"}
          disableActiveDot={authUser?.id === message.user.id}
        />
      </GridItem>
      <GridItem
        key={message.id}
        gridColumn={"message-block-start / message-block-end"}
      >
        {renderMessage()}
      </GridItem>
    </FluidGrid>
  );
}

export default MessageWithOffer;
