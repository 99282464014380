import { NewAppFeatures_features$key } from "__generated__/NewAppFeatures_features.graphql";
import { NewAppFeaturesMutation } from "__generated__/NewAppFeaturesMutation.graphql";
import { NewAppFeaturesQuery } from "__generated__/NewAppFeaturesQuery.graphql";
import NewAppFeaturesRefetchQuery from "__generated__/NewAppFeaturesRefetchQuery.graphql";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { fetchQuery, useLazyLoadQuery } from "react-relay/hooks";
import { Box, Image, Text } from "theme-ui";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import Wizard, {
  WizardPage
} from "../../../../components/01_Core/Forms/Wizard";
import environment from "../../../../environment";
import Linkify from "../../../../utils/Linkify";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { useAuth } from "../../hooks/useAuth";
import useMutationPromise from "../../hooks/useMutationPromise";
import ProgressBar from "../03_UI_Kit/Forms/ProgressBar";
import BasicModal from "../03_UI_Kit/Modals/BasicModal";
import OfferFormFooter from "../03_UI_Kit/Offers/OfferFormFooter";

const MAX_FEATURES_TO_SHOW = 6;

export default function NewAppFeatures(): ReactElement {
  //   const query = usePreloadedQuery(NewAppFeaturesQuery, props.query);
  const query = useLazyLoadQuery<NewAppFeaturesQuery>(
    graphql`
      query NewAppFeaturesQuery {
        ...NewAppFeatures_features
      }
    `,
    null
  );
  const { newAppFeatures } = useFragment<NewAppFeatures_features$key>(
    graphql`
      fragment NewAppFeatures_features on Query
      @refetchable(queryName: "NewAppFeaturesRefetchQuery") {
        newAppFeatures {
          id
          title
          description
          imageUrl
        }
      }
    `,
    query
  );

  const [commit] = useMutationPromise<NewAppFeaturesMutation>(graphql`
    mutation NewAppFeaturesMutation($input: [ID!]!) {
      userSetAppFeaturesSeen(data: $input) {
        user {
          id
        }
      }
    }
  `);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const { authUser } = useAuth();

  if (!authUser) return null;
  if (!newAppFeatures?.length) return null;

  const onSubmit = () => {
    commit({
      variables: {
        input: newAppFeatures.map(f => f.id)
      }
    }).then(() =>
      fetchQuery(environment, NewAppFeaturesRefetchQuery, {}).toPromise()
    );
    // Close the modal, even if the commit/refetch isn't done yet
    setIsModalOpen(false);
  };

  const padding = useActiveResponsiveValue(["24px", "32px", "48px", "48px"]);

  return (
    <Wizard
      pages={() =>
        newAppFeatures
          .slice(Math.max(newAppFeatures.length - MAX_FEATURES_TO_SHOW, 0))
          .map((appFeature, index) => (
            <WizardPage key={appFeature.id}>
              <AutoLayout
                dependentProps={{ direction: "vertical" }}
                spacing={padding}
                sx={{ width: "100%" }}
              >
                <Text
                  variant={useActiveResponsiveValue([
                    "h300",
                    "h400",
                    "h400",
                    "h400"
                  ])}
                >
                  <strong>{appFeature.title}</strong>
                </Text>
                <Image
                  src={appFeature.imageUrl}
                  sx={{
                    alignSelf: "center",
                    maxWidth: "95%",
                    height: "40vh",
                    objectFit: "cover",
                    display: "block"
                  }}
                />
                <Text variant={"bodyLarge"} sx={{ whiteSpace: "pre-line" }}>
                  <Linkify>{appFeature.description}</Linkify>
                </Text>
              </AutoLayout>
            </WizardPage>
          ))
      }
      pageLayout={pageLayoutProps => (
        <BasicModal
          headerStyles={{ backgroundColor: "lightGray100" }}
          title={`New ${
            pageLayoutProps.numPages > 1 ? "features" : "feature"
          } on indify`}
          isOpen={isModalOpen}
          onClose={onSubmit}
        >
          <>
            <ProgressBar
              percent={
                ((pageLayoutProps.activePageIndex + 1) * 100) /
                pageLayoutProps.numPages
              }
            />
            <Box padding={padding} sx={{ width: "100%" }}>
              {pageLayoutProps.activePage}
            </Box>
            <OfferFormFooter {...pageLayoutProps} submitButtonLabel="Got it!" />
          </>
        </BasicModal>
      )}
      onSubmit={onSubmit}
    />
  );
}
