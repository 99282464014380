import { DealListItem_featureddeal$key } from "__generated__/DealListItem_featureddeal.graphql";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import ReleaseIconsNames from "../../../../../components/01_Core/Releases/ReleaseIconsNames";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { lineClampStyles } from "../../../../../utils/utils";
import { InternalRoutes } from "../../../Routing/InternalRoutes";

type DealListItemSize = "small" | "large";

interface IDealListItemProps {
  deal: DealListItem_featureddeal$key;
  size: ResponsiveValue<DealListItemSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2424%3A20915
 */
function DealListItem(props: IDealListItemProps): ReactElement {
  const activeSize = useActiveResponsiveValue<DealListItemSize>(props.size);

  const featuredDeal = useFragment(
    graphql`
      fragment DealListItem_featureddeal on FeaturedDealNode {
        id
        totalAmountRaising
        ...ReleaseIconsNames_query
      }
    `,
    props.deal
  );

  return (
    <Link
      to={generatePath(InternalRoutes.featuredDeal, {
        dealId: featuredDeal.id
      })}
      style={{ width: "100%" }}
    >
      <AutoLayout
        spacing={[8, 16, 16, 16]}
        resizingX={FillContainer()}
        dependentProps={{ direction: "horizontal" }}
        p={["8px", "16px", "16px", "16px"]}
        sx={{
          border: "1px solid",
          borderColor: "midGray100",
          "&:hover": { borderColor: "deepGray100" },
          transition: "border-color 0.2s",
          borderRadius: "4px",
          alignItems: "center"
        }}
      >
        <ReleaseIconsNames
          size={["small", "large", "large", "large"]}
          query={featuredDeal}
          textStyles={{ ...lineClampStyles(1) }}
        />
      </AutoLayout>
    </Link>
  );
}

export default DealListItem;
