import { ReleasesIncludedQuery } from "__generated__/ReleasesIncludedQuery.graphql";
import _ from "lodash";
import { ReactElement, Suspense, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Box, Spinner } from "theme-ui";

import Ic16PlusCircle from "../../../../../../../imgs/icons/ic16-plus-circle.svg";
import Ic20PlusCircle from "../../../../../../../imgs/icons/ic20-plus-circle.svg";
import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../../../components/01_Core/Buttons/TextButton";
import { getErrors } from "../../../../../../components/01_Core/Forms/utils/getHasError";
import { Field } from "../../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { getReleaseImageIconWidth } from "../../../../../../components/01_Core/Releases/ReleaseImageIcon";
import ReleasesInfoRemovable, {
  getReleaseImageIconSize,
  ReleaseInfoRemovableSize,
  RemovableIcon
} from "../../../../../../components/01_Core/Releases/ReleasesInfoRemovable";
import { rvMap } from "../../../../../../utils/responsiveUtils";
import { useBreakpoint } from "../../../../../../utils/useBreakpoints";
import { noop } from "../../../../../../utils/utils";
import {
  IFundingFormValues,
  IFundingOfferFormFieldProps,
  IFundingOfferFormValues
} from "../../../07_Deal_Flow/OfferFormFunding";
import ReleaseSelector, { ReleaseOption } from "../../Releases/ReleaseSelector";
import FormSection, { FormSectionSize } from "../FormSection";
import { OfferTermsWarningBlock } from "../OfferTermsWarningBlock";

export interface IReleasesIncludedProps extends IFundingOfferFormFieldProps {
  artist: ReleasesIncludedQuery["variables"];
  canCreateUnreleasedContent: boolean;
  showCopartnerWarnings?: boolean;
}

export const contentFieldName = "allReleases" as const;

export function ReleasesIncludedField(
  props: IReleasesIncludedProps
): ReactElement {
  const formState = useFormState<IFundingOfferFormValues>();

  const breakpointMatchMap = useBreakpoint();
  const [isAddingRelease, setIsAddingRelease] = useState<boolean>(false);
  const toggleIsAddingRelease = () => setIsAddingRelease(!isAddingRelease);

  const formApi = useForm<IFundingOfferFormValues>();

  const inputValue = formState.values[contentFieldName];
  const anyReleaseHasExistingPartnership = formState.values.allReleases.some(
    r => r.hasExistingPartnership
  );
  const releaseImageIconWidth = rvMap(
    rvMap(
      rvMap(props.size, getReleasesInfoRemovableSize),
      getReleaseImageIconSize
    ),
    getReleaseImageIconWidth
  );

  return (
    <Suspense
      fallback={
        <AutoLayout
          spacing={16}
          dependentProps={{ direction: "vertical" }}
          sx={{ width: "100%" }}
        >
          {inputValue.map((_, index) => {
            return (
              <RemovableIcon
                key={index}
                size={rvMap(props.size, getReleasesInfoRemovableSize)}
                remove={noop}
              >
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: releaseImageIconWidth
                  }}
                >
                  <Spinner />
                </Box>
              </RemovableIcon>
            );
          })}
          <TextButton
            type={"primary"}
            size={props.size}
            disabled={true}
            iconProps={{
              placement: "left",
              icon: <Spinner />
            }}
          >
            Add a release
          </TextButton>
        </AutoLayout>
      }
    >
      <Field<IFundingOfferFormValues["allReleases"]>
        name={contentFieldName}
        validate={value => {
          if (!value.length) {
            return "Please include at least one release.";
          }
        }}
      >
        {({ input }) => {
          const { artist } = useLazyLoadQuery<ReleasesIncludedQuery>(
            graphql`
              query ReleasesIncludedQuery($id: ID!) {
                artist(id: $id) {
                  releasesPrefetch(orderBy: "-releaseDate", first: 1000) {
                    edges {
                      node {
                        __typename
                        id
                        hasExistingPartnership
                        hasExistingInvestmentPartnership
                        ...ReleasesInfoRemovable_release
                        ...ReleaseSelector_releases
                        tracks {
                          edges {
                            node {
                              soundRecording {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  unreleasedReleases(orderBy: "-createdAt", first: 1000) {
                    edges {
                      node {
                        __typename
                        id
                        hasExistingPartnership
                        hasExistingInvestmentPartnership
                        ...ReleasesInfoRemovable_release
                        ...ReleaseSelector_unreleasedReleases
                      }
                    }
                  }
                }
              }
            `,
            props.artist,
            { fetchPolicy: "network-only" }
          );
          const releases = artist.releasesPrefetch.edges.map(e => e.node);
          const unreleasedReleases = artist.unreleasedReleases.edges.map(
            e => e.node
          );

          const findExistingRelease = (inputRelease: ReleaseOption["value"]) =>
            inputRelease.id &&
            [...releases, ...unreleasedReleases].find(
              r => r.id === inputRelease.id
            );

          const addRelease = (releaseOption: ReleaseOption) => {
            if (!releaseOption) return;
            setIsAddingRelease(false);

            const { value } = releaseOption;
            input.onChange([...input.value, value]);

            // Reset the funding amounts when a new release with existing investment partnership is added
            if (value.hasExistingInvestmentPartnership) {
              formApi.change("advanceAmountCents", 0);
              formApi.change("marketingAmountCents", 0);
              formApi.change("creativeAmountCents", 0);
            }

            // Include sound recordings for all tracks in the release, if they haven't already been added or removed
            const release = findExistingRelease(value);
            if (release && release.__typename === "ReleaseNode") {
              const addedSoundRecordingIds = release.tracks.edges.map(
                ({ node: track }) => track.soundRecording.id
              );
              const newFormValues = _.mapValues(
                _.keyBy(addedSoundRecordingIds, id => id),
                () => true
              );
              formApi.change("soundRecordingIds", {
                ...newFormValues,
                ...formApi.getState().values.soundRecordingIds // Existing values override new values
              });
            }
          };

          return (
            <AutoLayout
              spacing={16}
              dependentProps={{ direction: "vertical" }}
              sx={{ width: "100%" }}
            >
              {props.showCopartnerWarnings &&
                anyReleaseHasExistingPartnership && (
                  <OfferTermsWarningBlock type={"warning"} size={"large"}>
                    The artist has already done a deal with another partner for
                    some of the selected releases. Please speak with the artist
                    to see what percentage of the releases are still available.
                  </OfferTermsWarningBlock>
                )}
              {input.value.length > 0 && (
                <AutoLayout
                  spacing={16}
                  dependentProps={{ direction: "vertical" }}
                  sx={{ width: "100%" }}
                >
                  {inputValue.map((inputRelease, index) => {
                    const release = findExistingRelease(inputRelease);
                    const isUnreleasedRelease =
                      !release ||
                      release.__typename === "UnreleasedReleaseNode";
                    return (
                      <ReleasesInfoRemovable
                        key={
                          release?.id ??
                          inputRelease.tempId ??
                          Math.random().toString()
                        }
                        // TODO we might want to remove sound recordings from the form state when a release is removed
                        remove={() =>
                          input.onChange([
                            ...input.value.slice(0, index),
                            ...input.value.slice(index + 1)
                          ])
                        }
                        onEditModalSubmit={
                          isUnreleasedRelease
                            ? (
                                releaseValues: IFundingFormValues["allReleases"][number]
                              ) => {
                                releaseValues.tempId = Math.random().toString();
                                input.onChange([
                                  ...input.value.slice(0, index),
                                  releaseValues,
                                  ...input.value.slice(index + 1)
                                ]);
                              }
                            : undefined
                        }
                        openEditModalOnRender={
                          !release &&
                          // Assume we assign this when the modal closes, so if it's assigned, we don't need to open the modal again
                          !inputRelease.tempId
                        }
                        size={rvMap(props.size, getReleasesInfoRemovableSize)}
                        {...(release
                          ? { release: release }
                          : { newUnreleasedRelease: inputRelease })}
                      />
                    );
                  })}
                </AutoLayout>
              )}
              {isAddingRelease ? (
                <ReleaseSelector
                  releases={releases}
                  unreleasedReleases={unreleasedReleases}
                  size={props.size}
                  onChange={addRelease}
                  canCreateUnreleasedContent={props.canCreateUnreleasedContent}
                />
              ) : (
                <TextButton
                  type={"primary"}
                  size={props.size}
                  onClick={toggleIsAddingRelease}
                  iconProps={{
                    placement: "left",
                    icon: breakpointMatchMap.mobile ? (
                      <Ic16PlusCircle />
                    ) : (
                      <Ic20PlusCircle />
                    )
                  }}
                >
                  Add a release
                </TextButton>
              )}
            </AutoLayout>
          );
        }}
      </Field>
    </Suspense>
  );
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=704%3A413
 */
function ReleasesIncluded(props: IReleasesIncludedProps): ReactElement {
  const errors = getErrors<IFundingOfferFormValues>(
    props.showAllErrors,
    contentFieldName,
    "releaseHasIncludedTracks"
  );

  return (
    <FormSection
      heading={"Releases Included"}
      headingTooltip={
        "The releases and sound recordings that are included in the deal. Alternate versions of these sound recordings (remix, live, fast/slowed, etc) are also included."
      }
      error={errors[0]}
      size={props.size}
    >
      <ReleasesIncludedField {...props} />
    </FormSection>
  );
}

export function getReleasesInfoRemovableSize(
  size: FormSectionSize
): ReleaseInfoRemovableSize {
  switch (size) {
    case "small":
      return "small";
    case "medium":
      return "large";
  }
}

export default ReleasesIncluded;
