import { TikTokSoundsIncluded_tikTokSoundDetails$key } from "__generated__/TikTokSoundsIncluded_tikTokSoundDetails.graphql";
import { TikTokSoundsIncluded_tikTokSoundRemovable$key } from "__generated__/TikTokSoundsIncluded_tikTokSoundRemovable.graphql";
import { TikTokSoundsIncluded_tikTokSoundSelectable$key } from "__generated__/TikTokSoundsIncluded_tikTokSoundSelectable.graphql";
import { TikTokSoundsIncludedQuery } from "__generated__/TikTokSoundsIncludedQuery.graphql";
import _ from "lodash";
import { ReactElement, Suspense, useState } from "react";
import { useFormState } from "react-final-form";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { components, OptionProps } from "react-select";
import { ResponsiveValue } from "styled-system";
import { Box, Spinner, Text } from "theme-ui";

import Ic16PlusCircle from "../../../../../../../imgs/icons/ic16-plus-circle.svg";
import Ic20PlusCircle from "../../../../../../../imgs/icons/ic20-plus-circle.svg";
import Ic24TikTok from "../../../../../../../imgs/icons/ic24-social-tiktok.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../../../components/01_Core/Buttons/TextButton";
import getHasError from "../../../../../../components/01_Core/Forms/utils/getHasError";
import { Field } from "../../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { getTitleTextVariant } from "../../../../../../components/01_Core/Releases/ReleaseDetails";
import ReleaseImageIcon, {
  getReleaseImageIconWidth
} from "../../../../../../components/01_Core/Releases/ReleaseImageIcon";
import {
  getReleaseDetailsSize,
  getReleaseImageIconSize,
  ReleaseInfoRemovableSize,
  RemovableIcon
} from "../../../../../../components/01_Core/Releases/ReleasesInfoRemovable";
import { formatReleaseDate } from "../../../../../../components/01_Core/Releases/useReleaseMetadata";
import {
  rvMap,
  useFindActiveRV
} from "../../../../../../utils/responsiveUtils";
import { useBreakpoint } from "../../../../../../utils/useBreakpoints";
import { noop } from "../../../../../../utils/utils";
import { InternalRoutes } from "../../../../Routing/InternalRoutes";
import { IFeaturedDealFormValues } from "../../../06_Deal_Merchandising/DealMerchandisingFormPage";
import { IFundingOfferFormFieldProps } from "../../../07_Deal_Flow/OfferFormFunding";
import { socialLinksSubpage } from "../../../08_Account_Management/AccountManagementPage";
import { SearchableSelector } from "../../Releases/ReleaseSelector";
import FormSection from "../FormSection";
import OfferFormGuidance from "../OfferFormGuidance";
import { getReleasesInfoRemovableSize } from "../ReleasesIncluded/ReleasesIncluded";

const fieldName = "tikTokSoundIds";

function TikTokSoundDetails(props: {
  tikTokSound: TikTokSoundsIncluded_tikTokSoundDetails$key;
  size: ResponsiveValue<ReleaseInfoRemovableSize>;
}) {
  const tikTokSound = useFragment(
    graphql`
      fragment TikTokSoundsIncluded_tikTokSoundDetails on TikTokSoundNode {
        name
        thumbnailUrl
        firstSeenAt
        mostRecentStat {
          current
        }
      }
    `,
    props.tikTokSound
  );

  const titleTextVariant = getTitleTextVariant(
    useFindActiveRV(props.size, getReleaseDetailsSize)
  );

  const currentStat = tikTokSound.mostRecentStat?.current;
  return (
    <AutoLayout
      spacing={4}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical"
      }}
    >
      <Text variant={titleTextVariant}>{tikTokSound.name}</Text>
      <Text variant={"bodySmall"} color={"deepGray100"}>
        {currentStat != null && `${currentStat} UGCs - `}
        {formatReleaseDate(tikTokSound.firstSeenAt)}
      </Text>
    </AutoLayout>
  );
}

function TikTokSoundRemovable(props: {
  size: ResponsiveValue<ReleaseInfoRemovableSize>;
  tikTokSound: TikTokSoundsIncluded_tikTokSoundRemovable$key;
}) {
  const tikTokSound = useFragment(
    graphql`
      fragment TikTokSoundsIncluded_tikTokSoundRemovable on TikTokSoundNode {
        thumbnailUrl
        ...TikTokSoundsIncluded_tikTokSoundDetails
      }
    `,
    props.tikTokSound
  );

  return (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "horizontal", alignment: "left" }}
      sx={{ width: "100%" }}
    >
      <ReleaseImageIcon
        src={tikTokSound?.thumbnailUrl}
        size={useFindActiveRV(props.size, getReleaseImageIconSize)}
        sxOverrides={{ borderColor: "midGray100" }}
      />
      <TikTokSoundDetails tikTokSound={tikTokSound} size={props.size} />
    </AutoLayout>
  );
}

type TikTokSoundOption = {
  label: string;
  value: string;
  fragment: TikTokSoundsIncluded_tikTokSoundSelectable$key;
};

function TikTokSoundSelectable(props: OptionProps<TikTokSoundOption, false>) {
  const tikTokSound = useFragment(
    graphql`
      fragment TikTokSoundsIncluded_tikTokSoundSelectable on TikTokSoundNode {
        thumbnailUrl
        ...TikTokSoundsIncluded_tikTokSoundDetails
      }
    `,
    props.data.fragment
  );

  return (
    <components.Option {...props}>
      <AutoLayout
        spacing={10}
        resizingX={FillContainer()}
        dependentProps={{ direction: "horizontal", alignment: "center" }}
      >
        <ReleaseImageIcon size={"medium"} src={tikTokSound.thumbnailUrl} />
        <TikTokSoundDetails tikTokSound={tikTokSound} size={"small"} />
      </AutoLayout>
    </components.Option>
  );
}

export default function TikTokSoundsIncluded(
  props: IFundingOfferFormFieldProps & {
    artist: TikTokSoundsIncludedQuery["variables"];
  }
): ReactElement {
  const hasError = getHasError<IFeaturedDealFormValues>(
    props.showAllErrors,
    fieldName
  );
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const breakpointMatchMap = useBreakpoint();

  const inputValue =
    useFormState<IFeaturedDealFormValues>().values[fieldName] ?? [];
  const releaseImageIconWidth = rvMap(
    rvMap(
      rvMap(props.size, getReleasesInfoRemovableSize),
      getReleaseImageIconSize
    ),
    getReleaseImageIconWidth
  );

  return (
    <FormSection
      heading="TikTok Sounds Included"
      headingTooltip="TikTok Sounds that showcase the deal's content and traction."
      error={hasError ? "Please select at least one TikTok Sound." : null}
      size={props.size}
    >
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical" }}
        sx={{ width: "100%" }}
      >
        <Suspense
          fallback={
            <AutoLayout
              spacing={16}
              dependentProps={{ direction: "vertical" }}
              sx={{ width: "100%" }}
            >
              {inputValue.map((_id, index) => {
                return (
                  <RemovableIcon
                    key={index}
                    size={rvMap(props.size, getReleasesInfoRemovableSize)}
                    remove={noop}
                  >
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                        height: releaseImageIconWidth
                      }}
                    >
                      <Spinner />
                    </Box>
                  </RemovableIcon>
                );
              })}
              <TextButton
                type={"primary"}
                size={props.size}
                disabled={true}
                iconProps={{
                  placement: "left",
                  icon: <Spinner />
                }}
              >
                Add a TikTok sound
              </TextButton>
            </AutoLayout>
          }
        >
          <Field<IFeaturedDealFormValues["tikTokSoundIds"]> name={fieldName}>
            {({ input }) => {
              const { artist } = useLazyLoadQuery<TikTokSoundsIncludedQuery>(
                graphql`
                  query TikTokSoundsIncludedQuery($id: ID!) {
                    artist(id: $id) {
                      tiktokProfile {
                        latestSeenPostAt
                        tiktokSounds {
                          edges {
                            node {
                              id
                              name
                              mostRecentStat {
                                current
                              }
                              ...TikTokSoundsIncluded_tikTokSoundRemovable
                              ...TikTokSoundsIncluded_tikTokSoundSelectable
                            }
                          }
                        }
                      }
                    }
                  }
                `,
                props.artist,
                { fetchPolicy: "network-only" }
              );

              if (!artist.tiktokProfile) {
                return (
                  <Link
                    to={generatePath(InternalRoutes.settings, {
                      subpage: socialLinksSubpage
                    })}
                  >
                    <TextButton
                      type={"primary"}
                      size={props.size}
                      iconProps={{
                        placement: "left",
                        icon: <Ic24TikTok />
                      }}
                    >
                      Connect your TikTok profile to add sounds
                    </TextButton>
                  </Link>
                );
              }

              const tikTokSounds = _.sortBy(
                artist.tiktokProfile.tiktokSounds.edges.map(e => e.node) ?? [],
                ts => -(ts.mostRecentStat?.current ?? 0)
              );

              return (
                <AutoLayout
                  spacing={16}
                  dependentProps={{ direction: "vertical" }}
                  sx={{ width: "100%" }}
                >
                  {!!input.value?.length && (
                    <AutoLayout
                      spacing={16}
                      dependentProps={{ direction: "vertical" }}
                      sx={{ width: "100%" }}
                    >
                      {input.value.map((tikTokSoundId, index) => {
                        return (
                          <RemovableIcon
                            key={tikTokSoundId}
                            size={rvMap(
                              props.size,
                              getReleasesInfoRemovableSize
                            )}
                            remove={() => {
                              input.onChange([
                                ...input.value.slice(0, index),
                                ...input.value.slice(index + 1)
                              ]);
                            }}
                          >
                            <TikTokSoundRemovable
                              size={rvMap(
                                props.size,
                                getReleasesInfoRemovableSize
                              )}
                              tikTokSound={tikTokSounds.find(
                                t => t.id === tikTokSoundId
                              )}
                            />
                          </RemovableIcon>
                        );
                      })}
                    </AutoLayout>
                  )}
                  {isAdding ? (
                    <SearchableSelector<TikTokSoundOption>
                      inputProps={{
                        onChange: (option: any) => {
                          input.onChange([
                            ...(input.value ?? []),
                            option.value
                          ]);
                          setIsAdding(false);
                        },
                        name: fieldName
                      }}
                      options={tikTokSounds
                        .filter(ts => (input.value ?? []).indexOf(ts.id) === -1)
                        .map(o => {
                          return { label: o.name, value: o.id, fragment: o };
                        })}
                      size={props.size}
                      placeholder="Enter a TikTok Sound name..."
                      selectProps={{ isCreatable: false }}
                      Option={TikTokSoundSelectable}
                    />
                  ) : (
                    <TextButton
                      type={"primary"}
                      size={props.size}
                      onClick={() => setIsAdding(b => !b)}
                      disabled={
                        !tikTokSounds.length ||
                        // Disable adding if there are 10+ sounds
                        (input.value ?? []).length >= 10
                      }
                      iconProps={{
                        placement: "left",
                        icon: breakpointMatchMap.mobile ? (
                          <Ic16PlusCircle />
                        ) : (
                          <Ic20PlusCircle />
                        )
                      }}
                    >
                      Add a TikTok sound
                    </TextButton>
                  )}
                  {!artist.tiktokProfile.latestSeenPostAt &&
                    !tikTokSounds.length && (
                      <Text variant={"bodySmall"} color={"deepGray100"}>
                        {
                          "We haven't loaded posts from your TikTok profile yet. Check back in a few minutes."
                        }
                      </Text>
                    )}
                  {artist.tiktokProfile.latestSeenPostAt &&
                    !tikTokSounds.length && (
                      <Text variant={"bodySmall"} color={"deepGray100"}>
                        {
                          "We couldn't find any original sounds posted recently on your TikTok profile."
                        }
                      </Text>
                    )}
                </AutoLayout>
              );
            }}
          </Field>
        </Suspense>
        <OfferFormGuidance hideTermsLink={true}>
          {
            "Partners will see the TikTok sounds' stats for User-Generated Content (UGCs), which increases the chance you'll receive an offer."
          }
        </OfferFormGuidance>
      </AutoLayout>
    </FormSection>
  );
}
