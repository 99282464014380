import { ChangeEvent, ReactElement } from "react";
import { Input as ThemeUIInput, ThemeUIStyleObject } from "theme-ui";

import Input, { IInputElementProps, InputProps } from "../Input";

function NumberInput(
  props: InputProps<HTMLInputElement, number | null>
): ReactElement {
  return <Input InputComponent={BaseNumberInput} {...props} />;
}

function BaseNumberInput(
  props: IInputElementProps<HTMLInputElement, number | null>
) {
  const {
    input: { value, onChange, ...inputRest },
    meta,
    sx,
    ...rest
  } = props;

  const serializingOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v: string = e.target.value;
    onChange(v === "" ? null : +v);
  };

  const serializedValue = value?.toString();

  const newSx: ThemeUIStyleObject = {
    ...sx,
    "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0
    }
  };

  return (
    <ThemeUIInput
      {...rest}
      {...inputRest}
      value={serializedValue}
      onChange={serializingOnChange}
      type={props.type ?? "number"}
      onWheel={e => (e.target as HTMLElement).blur()}
      sx={newSx}
    />
  );
}

export default NumberInput;
