/**
 * @generated SignedSource<<6fd58021809c6b8180696daa506cbe2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvatarName_actor$data = {
  readonly __typename: string;
  readonly company?: {
    readonly displayName: string;
    readonly slug: string;
  } | null;
  readonly displayName: string;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarIcon_actor">;
  readonly " $fragmentType": "AvatarName_actor";
};
export type AvatarName_actor$key = {
  readonly " $data"?: AvatarName_actor$data;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AvatarName_actor",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarIcon_actor"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyNode",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "PartnerNode",
      "abstractKey": null
    }
  ],
  "type": "ActorInterface",
  "abstractKey": "__isActorInterface"
};
})();

(node as any).hash = "b66e4fd8b13d30d87b5202a4948a473a";

export default node;
