/**
 * @generated SignedSource<<d7978d1eeae8b9c38bd0c3d1c49eb62f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeaturedDealStatus = "CLOSED" | "DRAFT" | "PENDING" | "PUBLISHED";
import { FragmentRefs } from "relay-runtime";
export type DealManageable_featuredDeal$data = {
  readonly id: string;
  readonly status: FeaturedDealStatus;
  readonly totalAmountRaising: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"DealManageMenu_featuredDeal" | "ReleaseIconsNames_query">;
  readonly " $fragmentType": "DealManageable_featuredDeal";
};
export type DealManageable_featuredDeal$key = {
  readonly " $data"?: DealManageable_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealManageable_featuredDeal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealManageable_featuredDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmountRaising",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseIconsNames_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealManageMenu_featuredDeal"
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};

(node as any).hash = "bfb2c8b777c7561d44befba3964f36b7";

export default node;
