/**
 * @generated SignedSource<<643443c9def49d9633f4085664c0cc0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebasePartners_partnerLocations$data = {
  readonly homebasePartnerLocations: ReadonlyArray<string | null> | null;
  readonly " $fragmentType": "HomebasePartners_partnerLocations";
};
export type HomebasePartners_partnerLocations$key = {
  readonly " $data"?: HomebasePartners_partnerLocations$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebasePartners_partnerLocations">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebasePartners_partnerLocations",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "homebasePartnerLocations",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2851fc5e612f299a45aceaa279562b71";

export default node;
