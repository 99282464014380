/**
 * @generated SignedSource<<e6897635054b4ddadc9249fff75c1841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvitedToCompanyTeam_referral$data = {
  readonly company?: {
    readonly displayName: string;
    readonly thumbnailUrl: string;
  } | null;
  readonly sender?: {
    readonly displayName: string;
    readonly thumbnailUrl: string;
  } | null;
  readonly " $fragmentType": "InvitedToCompanyTeam_referral";
};
export type InvitedToCompanyTeam_referral$key = {
  readonly " $data"?: InvitedToCompanyTeam_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitedToCompanyTeam_referral">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnailUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitedToCompanyTeam_referral",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "sender",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PartnerToCompanyPartnerReferralNode",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyNode",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "OptionalCompanyInterface",
      "abstractKey": "__isOptionalCompanyInterface"
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};
})();

(node as any).hash = "669259016654d885ccbe4f86a05b55a4";

export default node;
