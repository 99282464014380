import { SpecialDeal_featuredDeal$key } from "__generated__/SpecialDeal_featuredDeal.graphql";
import { ReactElement, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Link as ExternalLink, Text, useThemeUI } from "theme-ui";

import Ic24Email from "../../../../../../imgs/icons/ic24-email.svg";
import Ic24PlayCircle from "../../../../../../imgs/icons/ic24-play-circle.svg";
import Ic24ThumbDown from "../../../../../../imgs/icons/ic24-thumb-down.svg";
import LogoGlyph from "../../../../../../imgs/logo-glyph.svg";
import AutoLayout, {
  FillContainer,
  Fixed
} from "../../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import { IconBox } from "../../../../../components/01_Core/Foundations/Icons";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import ReleaseIconsNames from "../../../../../components/01_Core/Releases/ReleaseIconsNames";
import {
  getUserActiveDotProps,
  WithBadge
} from "../../../../../components/01_Core/Users/AvatarIcon";
import {
  ActorDisplayName,
  AvatarNameContainer
} from "../../../../../components/01_Core/Users/AvatarName";
import DisplayAvatar from "../../../../../components/01_Core/Users/DisplayAvatar";
import { rvMap } from "../../../../../utils/responsiveUtils";
import { lineClampStyles } from "../../../../../utils/utils";
import useArtistThumbnailUrl from "../../../hooks/useArtistThumbnailUrl";
import { useMessageActor } from "../../../hooks/useMessage";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import { getBookmarkElement } from "../Discover/BookmarkIcon";
import {
  actionIconSx,
  DealDropdown,
  formatStat,
  useTikTokSoundsTotal
} from "../Discover/RowDeal";
import { SocialIconLabel } from "../Discover/RowHeaderDeal";
import { IRibbonProps, Ribbon } from "../Marketing/Ribbon";
import StatDelta from "../User_Directory/StatDelta";
import MarketplaceAccessGate from "../../../../../utils/MarketplaceAccessGate";

export function IndifysTakeHeader(): ReactElement {
  return (
    <Text variant={"bodySmall"} color={"deepGray100"}>
      <LogoGlyph
        style={{
          verticalAlign: "middle",
          width: "1.3em",
          height: "1.3em"
        }}
      />{" "}
      <Text sx={{ verticalAlign: "middle" }}>{"indify's Take"}</Text>
    </Text>
  );
}

export function TextFade(props: {
  linesToShow: number;
  children: ReactNode;
}): ReactElement {
  return (
    <Text
      sx={{
        // Fade out the text at the bottom
        WebkitMaskImage:
          "linear-gradient(to bottom, black 50%, transparent 100%)",
        // Force height to a fixed number of lines, even if the text is shorter
        height: `${props.linesToShow}lh`,
        ...lineClampStyles(props.linesToShow)
      }}
    >
      {props.children}
    </Text>
  );
}

export interface ISpecialDealSharedProps {
  showEditorialNote: boolean;
  onDismissMatch?: () => void;
  ribbonProps: IRibbonProps;
}

type SpecialDealSize = "small" | "large";
interface ISpecialDealProps extends ISpecialDealSharedProps {
  size: ResponsiveValue<SpecialDealSize>;
  featuredDeal: SpecialDeal_featuredDeal$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
export default function SpecialDeal(props: ISpecialDealProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment SpecialDeal_featuredDeal on FeaturedDealNode {
        ...RowDeal_dropdown
        id
        soundLink
        editorialNote
        tiktokSounds {
          ...RowDeal_useTikTokSoundsTotal
        }
        artist {
          ...useMessage_actor
          ...useArtistThumbnailUrl_artist
          user {
            lastActiveAt
          }
          displayName
          latestSpotifyMonthlyListenerCount {
            current
            percentChange
          }
          isWatchingCurrentUserCompany
          spotifyProfile {
            url
          }
        }
        ...ReleaseIconsNames_query
      }
    `,
    props.featuredDeal
  );

  const [messageArtist, messageArtistLoading] = useMessageActor(
    featuredDeal.artist
  );

  const avatar = (
    <DisplayAvatar
      size={rvMap(props.size, getDisplayAvatarSize)}
      imageUrl={useArtistThumbnailUrl(featuredDeal.artist)}
    />
  );
  const { theme } = useThemeUI();
  const badgeProps =
    getUserActiveDotProps(featuredDeal.artist.user?.lastActiveAt) ??
    (featuredDeal.artist.isWatchingCurrentUserCompany
      ? {
          Icon: getBookmarkElement(true),
          title: "Watches your company",
          bg: theme.colors.warning05 as string,
          color: theme.colors.warning50 as string
        }
      : undefined);
  const avatarWithBadge = badgeProps ? (
    <WithBadge {...badgeProps} size={"20%"}>
      {avatar}
    </WithBadge>
  ) : (
    avatar
  );

  const editorialNoteStyles = {
    visibility: featuredDeal.editorialNote ? undefined : ("hidden" as const)
  };

  const tiktokSoundsTotal = useTikTokSoundsTotal(featuredDeal.tiktokSounds);
  const soundLink =
    featuredDeal.soundLink ?? tiktokSoundsTotal.withHighestValue?.tiktokUrl;

  return (
    <AutoLayout
      spacing={0}
      resizingX={[
        Fixed("328px"),
        Fixed("328px"),
        Fixed("211px"),
        Fixed("328px")
      ]}
      dependentProps={{ direction: "vertical" }}
      p={"16px"}
      sx={{
        backgroundColor: "white",
        border: t => `1px solid ${t.colors.midGray70}`,
        position: "relative"
      }}
    >
      <Ribbon {...props.ribbonProps} />
      <Box sx={{ position: "absolute", top: "12px", right: "8px" }}>
        <DealDropdown featuredDeal={featuredDeal} hideMessageArtist={true} />
      </Box>
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical" }}
        resizingX={FillContainer()}
        sx={{ marginTop: "16px" }}
      >
        <AvatarNameContainer
          link={generatePath(InternalRoutes.featuredDeal, {
            dealId: featuredDeal.id
          })}
          size="large"
          styles={{ width: "100%" }}
        >
          <AutoLayout
            spacing={16}
            resizingX={FillContainer()}
            dependentProps={{ direction: "vertical" }}
          >
            <Flex sx={{ width: "100%", justifyContent: "center" }}>
              {avatarWithBadge}
            </Flex>
            <ActorDisplayName
              displayName={featuredDeal.artist.displayName}
              size={rvMap(props.size, size =>
                size === "small" ? "medium" : "large"
              )}
              hasLink={true}
            />
          </AutoLayout>
        </AvatarNameContainer>
        <AutoLayout
          spacing={8}
          dependentProps={{ direction: "horizontal" }}
          resizingX={FillContainer()}
          // Make the height of the row consistent, regardless of the number of text lines in ReleaseIconsNames
          sx={{ alignItems: "center", minHeight: "44px" }}
        >
          <ReleaseIconsNames
            size={rvMap(props.size, getDisplayAvatarSize)}
            query={featuredDeal}
            textStyles={lineClampStyles(2)}
          />
          <ExternalLink href={soundLink} target={"_blank"}>
            <IconBox
              icon={Ic24PlayCircle}
              sx={{ ...actionIconSx(!soundLink), size: 32 }}
            />
          </ExternalLink>
        </AutoLayout>
        <Divider />
        <AutoLayout
          spacing={8}
          dependentProps={{
            direction: "horizontal",
            distribution: "spaceBetween"
          }}
          resizingX={FillContainer()}
        >
          <AutoLayout
            spacing={2}
            dependentProps={{ direction: "vertical" }}
            sx={{ width: "50%" }}
          >
            <Text variant={"bodySmall"} color={"deepGray100"}>
              <SocialIconLabel platform="TIKTOK">UGCs</SocialIconLabel>
            </Text>
            <ExternalLink
              href={tiktokSoundsTotal.withHighestValue?.tiktokUrl}
              target={"_blank"}
            >
              <Text variant={"bodyMedium"} color={"black100"}>
                <AutoLayout
                  spacing={8}
                  dependentProps={{
                    direction: "horizontal",
                    alignment: "center"
                  }}
                >
                  {formatStat(tiktokSoundsTotal.currentTotal)}
                  {tiktokSoundsTotal.totalPercentChange != null && (
                    <StatDelta
                      size={["small", "small", "large", "large"]}
                      delta={tiktokSoundsTotal.totalPercentChange}
                    />
                  )}
                </AutoLayout>
              </Text>
            </ExternalLink>
          </AutoLayout>
          <AutoLayout
            spacing={2}
            dependentProps={{ direction: "vertical" }}
            sx={{ width: "50%" }}
          >
            <Text variant={"bodySmall"} color={"deepGray100"}>
              <SocialIconLabel platform="SPOTIFY">
                Monthly Listeners
              </SocialIconLabel>
            </Text>
            <ExternalLink
              href={featuredDeal.artist.spotifyProfile?.url}
              target={"_blank"}
            >
              <AutoLayout
                spacing={8}
                dependentProps={{
                  direction: "horizontal",
                  alignment: "center"
                }}
              >
                <Text variant={"bodyMedium"} color={"black100"}>
                  {formatStat(
                    featuredDeal.artist.latestSpotifyMonthlyListenerCount
                      ?.current
                  )}
                </Text>
                {!!featuredDeal.artist.latestSpotifyMonthlyListenerCount
                  ?.percentChange && (
                  <StatDelta
                    size={["small", "small", "large", "large"]}
                    delta={
                      featuredDeal.artist.latestSpotifyMonthlyListenerCount
                        .percentChange
                    }
                  />
                )}
              </AutoLayout>
            </ExternalLink>
          </AutoLayout>
        </AutoLayout>
        {props.showEditorialNote && <Divider styles={editorialNoteStyles} />}
        {props.showEditorialNote && (
          <Box sx={editorialNoteStyles}>
            <AutoLayout
              spacing={2}
              dependentProps={{ direction: "vertical" }}
              sx={{ width: "100%" }}
            >
              <IndifysTakeHeader />
              <Text variant={"bodyMedium"} color={"black100"}>
                <TextFade linesToShow={4}>
                  {featuredDeal.editorialNote}
                </TextFade>
              </Text>
            </AutoLayout>
          </Box>
        )}
        <AutoLayout
          spacing={8}
          dependentProps={{ direction: "horizontal" }}
          resizingX={FillContainer()}
        >
          <MarketplaceAccessGate>
            <LoadingButton
              loading={messageArtistLoading}
              disabled={false}
              variant="secondary"
              onClick={messageArtist}
              size={"small"}
              sx={{ width: "100%", height: "unset" }}
            >
              <AutoLayout
                spacing={8}
                dependentProps={{ direction: "horizontal" }}
                sx={{ width: "100%", alignItems: "center" }}
              >
                <IconBox icon={Ic24Email} sx={{ size: 24 }} />
                Message
              </AutoLayout>
            </LoadingButton>
          </MarketplaceAccessGate>
          {props.onDismissMatch && (
            <LoadingButton
              loading={false}
              disabled={false}
              variant="tertiary"
              onClick={props.onDismissMatch}
              size={"small"}
              sx={{
                ...actionIconSx(),
                color: "deepGray70",
                padding: "8px",
                width: "fit-content",
                height: "unset"
              }}
            >
              <IconBox icon={Ic24ThumbDown} sx={{ size: 24 }} />
            </LoadingButton>
          )}
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
}

function getDisplayAvatarSize(size: SpecialDealSize) {
  switch (size) {
    case "small":
      return "small";
    case "large":
      return "medium";
  }
}
