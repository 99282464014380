import { useUserThumbnailUrl_user$key } from "__generated__/useUserThumbnailUrl_user.graphql";
import { graphql, useFragment } from "react-relay";

import useArtistThumbnailUrl from "./useArtistThumbnailUrl";

export default function useUserThumbnailUrl(
  query: useUserThumbnailUrl_user$key
): string {
  const { actor } = useFragment(
    graphql`
      fragment useUserThumbnailUrl_user on UserNode {
        actor {
          __typename
          thumbnailUrl
          ...useArtistThumbnailUrl_artist
        }
      }
    `,
    query
  );
  const artistThumbnailUrl = useArtistThumbnailUrl(actor);
  if (artistThumbnailUrl) return artistThumbnailUrl;

  return actor.thumbnailUrl;
}
