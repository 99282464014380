import { Theme } from "theme-ui";

import { buttonVariants } from "./button";
import color from "./color";
import { fontVariants } from "./font";

export const numberBreakpoints = [600, 768, 1048];
const breakpoints = numberBreakpoints.map(x => `${x}px`);

export const zIndices = {
  modal: 3,
  nav: 2,
  switch: 1,
  hidden: -1
};

export const navigationHeight = [57, 60, 60, 60];

// Default spacing scale: [ 0, 4, 8, 16, 32, 64, 128, 256, 512 ]

const theme: Theme = {
  breakpoints,
  colors: color,
  buttons: buttonVariants,
  text: fontVariants,
  forms: {
    checkbox: {
      color: color.midGray100,
      borderRadius: "4px",
      "input:focus ~ &": { background: "transparent" },
      "input:checked ~ &": { color: color.primary100 }
    }
  }
};

export default theme;
