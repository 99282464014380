/**
 * @generated SignedSource<<17817c18877b494ddac9bc30ba1da778>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferFormHeader_partner$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OfferPartnership_partner">;
  readonly " $fragmentType": "OfferFormHeader_partner";
};
export type OfferFormHeader_partner$key = {
  readonly " $data"?: OfferFormHeader_partner$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferFormHeader_partner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferFormHeader_partner",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferPartnership_partner"
    }
  ],
  "type": "PartnerNode",
  "abstractKey": null
};

(node as any).hash = "05c7ca93079ddacb7b098d891cd17bbe";

export default node;
