import { PartnerAddCompanyInfo_referral$key } from "__generated__/PartnerAddCompanyInfo_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Header from "../../03_UI_Kit/Forms/Header";
import SectionProfilePhoto from "../../03_UI_Kit/Forms/SectionProfilePhoto";
import Website from "../../08_Account_Management/FormFields/Website";

interface IPartnerAddCompanyInfoProps {
  referral: PartnerAddCompanyInfo_referral$key;
}

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=474%3A24493
 */
function PartnerAddCompanyInfo(
  props: IPartnerAddCompanyInfoProps
): ReactElement {
  const referral = useFragment(
    graphql`
      fragment PartnerAddCompanyInfo_referral on ReferralInterface {
        id
        ... on IndifyToPartnerReferralNode {
          thumbnailUrl
        }
        ... on PartnerToPartnerReferralNode {
          thumbnailUrl
        }
      }
    `,
    props.referral
  );

  return (
    <AutoLayout
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
      spacing={48}
    >
      <Header size={["small", "small", "large", "large"]}>
        Add company details
      </Header>
      <SectionProfilePhoto
        label={"Company logo"}
        ctaText={"Upload logo image"}
        thumbnailUrl={referral.thumbnailUrl}
        nodeId={referral.id}
        size={["medium", "large", "large", "large"]}
        isCompanyImage={true}
      />
      <Website label={"Company website"} showErrorsBeforeTouched={true} />
    </AutoLayout>
  );
}

export default PartnerAddCompanyInfo;
