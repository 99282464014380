import { FundTestimonials_company$key } from "__generated__/FundTestimonials_company.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import FundTestimonial from "./FundTestimonial";

export type FundTestimonialsSize = "small" | "large";
interface IFundTestimonialsProps {
  size: ResponsiveValue<FundTestimonialsSize>;
  fund: FundTestimonials_company$key;
}

function FundTestimonials(props: IFundTestimonialsProps): ReactElement {
  const testimonials = useFragment(
    graphql`
      fragment FundTestimonials_company on CompanyNode {
        testimonials {
          edges {
            node {
              id
              ...FundTestimonial_companytestimonial
            }
          }
        }
      }
    `,
    props.fund
  );

  const activeSize = useActiveResponsiveValue(props.size);

  return (
    !!testimonials.testimonials?.edges.length && (
      <GridItem
        gridColumn={["1 / span 4", "1 / span 8", "1 / span 8", "3 / span 8"]}
        sx={{ flexDirection: "column" }}
      >
        <AutoLayout
          spacing={32}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical", alignment: "center" }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingY: activeSize === "small" ? "48px" : "64px"
          }}
        >
          <Text
            variant={activeSize === "small" ? "h400" : "h600"}
            sx={{ textAlign: "center" }}
          >
            Testimonials
          </Text>

          <AutoLayout
            spacing={24}
            resizingX={FillContainer()}
            dependentProps={{ direction: "vertical", alignment: "center" }}
          >
            {testimonials.testimonials.edges.map(({ node }) => (
              <FundTestimonial
                key={node.id}
                testimonial={node}
                size={props.size}
              />
            ))}
          </AutoLayout>
        </AutoLayout>
        <Divider />
      </GridItem>
    )
  );
}

export default FundTestimonials;
