import { ReactElement } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { requiredStringValidator } from "../../../../../components/01_Core/Forms/utils/validators";
import Header from "../../03_UI_Kit/Forms/Header";

/**
 * figma: https://www.figma.com/file/OqGohDdI4vyivDgcXXH5BG/01-First-time-UX?node-id=466%3A24331
 */
function PartnerNameCompanyName(): ReactElement {
  return (
    <AutoLayout
      spacing={[32, 48, 48, 48]}
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
    >
      <Header size={["small", "small", "large", "large"]}>
        Add your details
      </Header>
      <FieldBuilder
        fieldName={"name"}
        inputField={TextInput}
        validator={requiredStringValidator}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Your full name",
          caption: "You can change this at any time."
        }}
      />
      <FieldBuilder
        fieldName={"companyName"}
        inputField={TextInput}
        validator={requiredStringValidator}
        inputFieldProps={{
          size: ["medium", "medium", "large", "large"],
          label: "Company name",
          caption:
            "All indify partners must indicate which company they are associated with."
        }}
      />
    </AutoLayout>
  );
}

export default PartnerNameCompanyName;
