import { ArtistAttributes_artist$key } from "__generated__/ArtistAttributes_artist.graphql";
import * as dayjs from "dayjs";
import { Fragment, ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import Ic20Map from "../../../../../../imgs/icons/ic20-map.svg";
import Ic24Chat from "../../../../../../imgs/icons/ic24-chat.svg";
import Ic24Difference from "../../../../../../imgs/icons/ic24-difference.svg";
import Ic24Suitcase from "../../../../../../imgs/icons/ic24-suitcase.svg";
import Ic24VisibilityOff from "../../../../../../imgs/icons/ic24-visibility-off.svg";
import Ic24VolumeMax from "../../../../../../imgs/icons/ic24-volume-max.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { DotLabel } from "../../../../../components/01_Core/Badges_and_Tags/DotLabel";
import SubtleBadge, {
  SubtleBadgeSize
} from "../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { getUserActiveDotProps } from "../../../../../components/01_Core/Users/AvatarIcon";
import { useBreakpoint } from "../../../../../utils/useBreakpoints";
import { toSentence } from "../../../../../utils/utils";

interface IArtistAttributeProps {
  artist: ArtistAttributes_artist$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=524%3A14151
 */
function ArtistAttributes(props: IArtistAttributeProps): ReactElement {
  const breakpointMatchMap = useBreakpoint();

  const artist = useFragment(
    graphql`
      fragment ArtistAttributes_artist on ArtistNode {
        user {
          lastActiveAt
        }
        location
        genre {
          genre
        }
        partnershipStatus
        seekingServices {
          edges {
            node {
              service
            }
          }
        }
      }
    `,
    props.artist
  );

  const PartnershipStatusIcon = (() => {
    switch (artist.partnershipStatus) {
      case "ACTIVELY_LOOKING":
        return Ic24Difference;
      case "OPEN":
        return Ic24Chat;
      case "NOT_LOOKING":
        return Ic24VisibilityOff;
    }
  })();
  const partnershipStatusLabel =
    GLOBALS.ARTIST_PARTNERSHIP_STATUSES[artist.partnershipStatus].displayName;

  const seekingServiceLabels = artist.seekingServices.edges.map(
    ({ node }, i) => (
      <strong key={i}>
        {GLOBALS.PARTNER_SERVICES[node.service].displayName}
      </strong>
    )
  );

  const badgeSize = [
    "small",
    "medium",
    "medium",
    "medium"
  ] as SubtleBadgeSize[];
  const iconStyle = { width: "1.1em", height: "1.1em" };

  const activeDotProps = getUserActiveDotProps(artist.user?.lastActiveAt);

  return (
    <AutoLayout
      spacing={12}
      dependentProps={{
        direction: breakpointMatchMap.mobile ? "vertical" : "horizontal",
        alignment: "center"
      }}
    >
      {activeDotProps && (
        <SubtleBadge size={badgeSize} color={"gray"}>
          <DotLabel dotProps={activeDotProps}>
            {`Active ${dayjs(artist.user?.lastActiveAt).fromNow()}`}
          </DotLabel>
        </SubtleBadge>
      )}
      {artist.location && (
        <SubtleBadge
          size={badgeSize}
          color={"gray"}
          icon={<Ic20Map style={iconStyle} />}
        >
          {artist.location}
        </SubtleBadge>
      )}
      {artist.genre?.genre && (
        <SubtleBadge
          size={badgeSize}
          color={"gray"}
          icon={<Ic24VolumeMax style={iconStyle} />}
        >
          {artist.genre.genre}
        </SubtleBadge>
      )}
      <SubtleBadge
        size={badgeSize}
        color={"blue"}
        icon={
          PartnershipStatusIcon && <PartnershipStatusIcon style={iconStyle} />
        }
      >
        {partnershipStatusLabel}
      </SubtleBadge>
      {!!seekingServiceLabels.length && (
        <SubtleBadge
          size={badgeSize}
          color={"blue"}
          icon={<Ic24Suitcase style={iconStyle} />}
        >
          Seeking:{" "}
          {toSentence(seekingServiceLabels).map((node, i) => (
            <Fragment key={i}>{node}</Fragment>
          ))}
        </SubtleBadge>
      )}
    </AutoLayout>
  );
}

export default ArtistAttributes;
