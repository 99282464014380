/**
 * @generated SignedSource<<105b6e0e7aa4e556426a91ff5726a311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageSubscription_subscribedUser$data = {
  readonly id: string;
  readonly partner: {
    readonly displayName: string;
  } | null;
  readonly " $fragmentType": "ManageSubscription_subscribedUser";
};
export type ManageSubscription_subscribedUser$key = {
  readonly " $data"?: ManageSubscription_subscribedUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageSubscription_subscribedUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageSubscription_subscribedUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "partner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "9e2808bf05a40d3334caed9aa32bd473";

export default node;
