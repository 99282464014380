/**
 * @generated SignedSource<<88425a1e637fa2f28dc6a1a9d42f626c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActionMakeOffer_chat$data = {
  readonly artistUser: {
    readonly artist: {
      readonly displayName: string;
      readonly " $fragmentSpreads": FragmentRefs<"OfferForm_artist">;
    } | null;
  };
  readonly id: string;
  readonly partnerUser: {
    readonly partner: {
      readonly " $fragmentSpreads": FragmentRefs<"OfferForm_partner">;
    } | null;
  } | null;
  readonly " $fragmentType": "ActionMakeOffer_chat";
};
export type ActionMakeOffer_chat$key = {
  readonly " $data"?: ActionMakeOffer_chat$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActionMakeOffer_chat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionMakeOffer_chat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "partnerUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerNode",
          "kind": "LinkedField",
          "name": "partner",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OfferForm_partner"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "artistUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistNode",
          "kind": "LinkedField",
          "name": "artist",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OfferForm_artist"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};

(node as any).hash = "4163eb8415180b490132159cbfacbdef";

export default node;
