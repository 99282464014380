import {
  ReferralCreateInput,
  ReferralInputMutation,
  ReferralInputMutation$data,
  ReferralTypename
} from "__generated__/ReferralInputMutation.graphql";
import { ReactElement, useState } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { graphql, useMutation } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import Ic20Email from "../../../../../imgs/icons/ic20-email.svg";
import Ic24Email from "../../../../../imgs/icons/ic24-email.svg";
import AutoLayout, {
  AutoLayoutDependentProps,
  FillContainer,
  HugContents
} from "../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../components/01_Core/Buttons/LoadingButton";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import FieldBuilder from "../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../components/01_Core/Forms/Inputs/TextInput";
import { requiredEmailValidator } from "../../../../components/01_Core/Forms/utils/validators";
import { IconBox } from "../../../../components/01_Core/Foundations/Icons";
import { rvMap } from "../../../../utils/responsiveUtils";

type ReferralInputSize = "small" | "large";

const emailFieldName: keyof ReferralCreateInput = "recipientEmail";

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1670%3A14603
 */
export default function ReferralInput(props: {
  typename: ReferralTypename;
  disabled?: boolean;
  inputFieldProps?: Partial<React.ComponentProps<typeof TextInput>>;
  size: ResponsiveValue<ReferralInputSize>;
}): ReactElement {
  const [sentReferral, setSentReferral] = useState<
    ReferralInputMutation$data["referralCreate"]["referral"] | null
  >(null);
  const clearSentReferral = () => setSentReferral(null);

  const [commitMutation] = useMutation<ReferralInputMutation>(graphql`
    mutation ReferralInputMutation($input: ReferralCreateInput!) {
      referralCreate(data: $input) {
        referral {
          id
          recipientEmail
          sender {
            ... on PartnerNode {
              partnerReferralsMaxAllowed
              partnerReferrals {
                edges {
                  node {
                    id
                  }
                }
              }
              artistReferrals {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
          ... on PartnerToCompanyPartnerReferralNode {
            company {
              id
              displayName
              partnerCompanyReferrals {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const onSubmit = (values: ReferralCreateInput) => {
    return new Promise((resolve, onError) => {
      commitMutation({
        variables: {
          input: { typename: props.typename, ...values }
        },
        onError,
        onCompleted: (data, errors) => {
          if (errors != null) {
            return resolve({
              [emailFieldName]: errors[0].message
            });
          }

          setSentReferral(data.referralCreate.referral);
          resolve(undefined);
        }
      });
    });
  };

  const joinDescription = (() => {
    switch (props.typename) {
      case "PartnerToPartnerReferralNode":
        return "join indify as a new company";
      case "PartnerToCompanyPartnerReferralNode":
        return "join your company on indify";
      case "PartnerToArtistReferralNode":
        return "join indify as an artist";
    }
  })();

  const recipientDescription = (() => {
    switch (props.typename) {
      case "PartnerToCompanyPartnerReferralNode":
        return "partner";
      case "PartnerToPartnerReferralNode":
        return "investor";
      case "PartnerToArtistReferralNode":
        return "artist";
    }
  })();

  return sentReferral ? (
    <Box p={"10px"}>
      <AutoLayout
        spacing={12}
        resizingX={FillContainer()}
        resizingY={HugContents()}
        dependentProps={{
          direction: "vertical",
          distribution: "packed",
          alignment: "topLeft"
        }}
        p={"16px"}
        bg={"secondary10"}
        sx={{ borderRadius: "4px" }}
      >
        <Text variant={"h300"} color={"black100"}>
          Invite sent!
        </Text>
        <Text variant={"bodyMedium"} color={"black100"}>
          You have invited <strong>{sentReferral.recipientEmail}</strong> to{" "}
          {joinDescription}. They should receive an email shortly.
        </Text>
        <TextButton
          onClick={clearSentReferral}
          size={"small"}
          type={"secondary"}
          iconProps={{
            placement: "left",
            icon: (
              <IconBox
                icon={Ic20Email}
                viewBoxSize={20}
                sx={{
                  size: "16px",
                  color: "black100"
                }}
              />
            )
          }}
        >
          Invite another {recipientDescription}
        </TextButton>
      </AutoLayout>
    </Box>
  ) : (
    <Form<ReferralCreateInput> onSubmit={onSubmit}>
      {(formProps: FormRenderProps<ReferralCreateInput>) => {
        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100%" }}>
            <AutoLayout
              spacing={16}
              resizingX={FillContainer()}
              resizingY={HugContents()}
              dependentProps={{
                direction: "vertical",
                distribution: "packed",
                alignment: "topLeft"
              }}
            >
              <AutoLayout
                spacing={16}
                resizingX={FillContainer()}
                resizingY={HugContents()}
                dependentProps={rvMap(props.size, getDependentProps)}
              >
                <FieldBuilder
                  inputField={TextInput}
                  inputFieldProps={{
                    iconProps: {
                      placement: "left",
                      Icon: Ic24Email
                    },
                    size: "large",
                    disabled: props.disabled,
                    placeholder: "Enter email address...",
                    ...(props.inputFieldProps || {})
                  }}
                  fieldName={emailFieldName}
                  validator={requiredEmailValidator}
                />
                <LoadingButton
                  variant={"primary"}
                  size={"large"}
                  sx={{
                    width: rvMap(props.size, getButtonWidth)
                  }}
                  onClick={formProps.handleSubmit}
                  loading={formProps.submitting}
                  disabled={props.disabled}
                >
                  Invite
                </LoadingButton>
              </AutoLayout>
            </AutoLayout>
          </form>
        );
      }}
    </Form>
  );
}
function getDependentProps(
  size: ReferralInputSize
): AutoLayoutDependentProps<any, any> {
  let direction;

  switch (size) {
    case "small":
      direction = "vertical";
      break;
    case "large":
      direction = "horizontal";
  }
  const dependentProps: AutoLayoutDependentProps<any, any> = {
    direction
  };
  return dependentProps;
}
function getButtonWidth(size: ReferralInputSize): string {
  switch (size) {
    case "small":
      return "100%";
    case "large":
      return "104px";
  }
}
