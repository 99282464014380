import { ReactElement } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import { FieldPrefix } from "../../../../../components/01_Core/Forms/FieldBuilder";
import Header from "../../03_UI_Kit/Forms/Header";
import { ArtistSocialLinkFields } from "../../08_Account_Management/ArtistEditSocialLinks";

export default function ArtistSocialLinks(): ReactElement {
  return (
    <AutoLayout
      dependentProps={{
        direction: "vertical"
      }}
      resizingX={FillContainer()}
      spacing={48}
    >
      <Header size={["small", "small", "large", "large"]}>
        Connect your social profiles to share your stats
      </Header>
      <FieldPrefix prefix={"socialLinks"}>
        <ArtistSocialLinkFields initialValues={{}} />
      </FieldPrefix>
    </AutoLayout>
  );
}
