/**
 * @generated SignedSource<<ec2e35b98dd83f9d5edefe05cf195e93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReleaseTypeEnum = "album" | "compilation" | "single";
import { FragmentRefs } from "relay-runtime";
export type useFeaturedDealTitle$data = {
  readonly releases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly releaseType: ReleaseTypeEnum | null;
      } | null;
    } | null>;
  };
  readonly unreleasedReleases: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly releaseType: ReleaseTypeEnum | null;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "useFeaturedDealTitle";
};
export type useFeaturedDealTitle$key = {
  readonly " $data"?: useFeaturedDealTitle$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFeaturedDealTitle">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "releaseType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFeaturedDealTitle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "releases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnreleasedReleaseNodeConnection",
      "kind": "LinkedField",
      "name": "unreleasedReleases",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnreleasedReleaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnreleasedReleaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OfferFeaturedDealInterface",
  "abstractKey": "__isOfferFeaturedDealInterface"
};
})();

(node as any).hash = "b20bb7ac5003969124d81561c2b91b24";

export default node;
