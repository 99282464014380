/**
 * @generated SignedSource<<147c7a531bfc103f672640816ef99731>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IndifyToPartnerReferralFlow_referral$data = {
  readonly company: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"Page_referral">;
  readonly " $fragmentType": "IndifyToPartnerReferralFlow_referral";
};
export type IndifyToPartnerReferralFlow_referral$key = {
  readonly " $data"?: IndifyToPartnerReferralFlow_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"IndifyToPartnerReferralFlow_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndifyToPartnerReferralFlow_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyNode",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Page_referral"
    }
  ],
  "type": "IndifyToPartnerReferralNode",
  "abstractKey": null
};

(node as any).hash = "25bc5d85b837d77297256e27ccafd98e";

export default node;
