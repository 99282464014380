import { Hero_FeaturedDeal$key } from "__generated__/Hero_FeaturedDeal.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Flex } from "theme-ui";

import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import ArtistThumbnail from "../../03_UI_Kit/Deals/ArtistThumbnail";
import Deal from "../../03_UI_Kit/Deals/Deal";
import Title from "../../03_UI_Kit/Deals/Title";

interface IHeroProps {
  featuredDeal: Hero_FeaturedDeal$key;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=524%3A16256
 */
function Hero(props: IHeroProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment Hero_FeaturedDeal on FeaturedDealNode {
        artist {
          ...Title_artist
        }
        releases {
          edges {
            node {
              ...Title_releases
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              ...Title_releases
            }
          }
        }
        ...Deal_featuredDeal
        ...ArtistThumbnail_featuredDeal
      }
    `,
    props.featuredDeal
  );

  return (
    <FixedGrid
      sx={{
        gridTemplateAreas: [
          `
          "title title title title"
           "deal deal deal deal"
           "thumbnail thumbnail thumbnail thumbnail"
          `,
          `
          "title title title title title title title title"
          "thumbnail thumbnail . . deal deal deal deal"
          `,
          `
          "title title title title title title title title"
          "thumbnail thumbnail . . deal deal deal deal"
          `,
          `
          "title title title title title title title title deal deal deal deal"
          "thumbnail thumbnail thumbnail thumbnail . . . . deal deal deal deal"
          `
        ]
      }}
    >
      <GridItem gridArea={"title"}>
        <Box
          sx={{
            marginTop: ["32px", "64px", "64px", "128px"]
          }}
        >
          <Title
            size={["small", "large", "large", "large"]}
            artist={featuredDeal.artist}
            releases={[].concat(
              featuredDeal.releases.edges.map(r => r.node),
              featuredDeal.unreleasedReleases.edges.map(r => r.node)
            )}
          />
        </Box>
      </GridItem>
      <GridItem
        gridArea={"thumbnail"}
        sx={{
          alignItems: ["flex-start", "flex-start", "flex-start", "flex-end"]
        }}
      >
        <Flex
          sx={{
            marginTop: ["48px", "95px", "95px", "0px"],
            justifyContent: "center",
            width: "100%"
          }}
        >
          <ArtistThumbnail
            layout={["horizontal", "vertical", "vertical", "horizontal"]}
            featuredDeal={featuredDeal}
          />
        </Flex>
      </GridItem>
      <GridItem gridArea={"deal"}>
        <Flex
          sx={{
            marginTop: ["64px", "80px", "80px", "80px"],
            marginBottom: ["0px", "68px", "68px", "80px"],
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Deal
            size={["small", "large", "large", "large"]}
            featuredDeal={featuredDeal}
          />
        </Flex>
      </GridItem>
    </FixedGrid>
  );
}

export default Hero;
