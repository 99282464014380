/**
 * @generated SignedSource<<2e4339a33b2e9e7645d3eb0aea2020b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferMessageReleasesIncluded_releases$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ReleasesInfoRemovable_release">;
  readonly " $fragmentType": "OfferMessageReleasesIncluded_releases";
}>;
export type OfferMessageReleasesIncluded_releases$key = ReadonlyArray<{
  readonly " $data"?: OfferMessageReleasesIncluded_releases$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageReleasesIncluded_releases">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OfferMessageReleasesIncluded_releases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleasesInfoRemovable_release"
    }
  ],
  "type": "ReleaseNode",
  "abstractKey": null
};

(node as any).hash = "0e5b4ee0ecd495a9eef19dc403147fd0";

export default node;
