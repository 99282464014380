/**
 * @generated SignedSource<<8237d338d953ccee0fc5b8da0f4ef77b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useArtistCanMessageCompany_artist$data = {
  readonly nextIntroSendableAt: any | null;
  readonly numIntrosSendable: number | null;
  readonly " $fragmentType": "useArtistCanMessageCompany_artist";
};
export type useArtistCanMessageCompany_artist$key = {
  readonly " $data"?: useArtistCanMessageCompany_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"useArtistCanMessageCompany_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useArtistCanMessageCompany_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numIntrosSendable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nextIntroSendableAt",
      "storageKey": null
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "736892499be387ec3812388f479cc499";

export default node;
