import { FundProfileQuery as FundProfileQueryType } from "__generated__/FundProfileQuery.graphql";
import { ReactElement } from "react";
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay";
import { Redirect } from "react-router-dom";

import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import FundCaseStudyOffer, {
  IFundCaseStudyOfferProps
} from "./FundCaseStudyOffer";
import FundInfo from "./FundInfo";
import FundTestimonials from "./FundTestimonials";
import PartnershipsGrid from "./PartnershipsGrid";

interface IFundProfileProps {
  queryRef: PreloadedQuery<FundProfileQueryType>;
  caseStudyOfferQueryRef: IFundCaseStudyOfferProps["queryRef"];
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=1903%3A42788
 */
function FundProfile(props: IFundProfileProps): ReactElement {
  const fund = usePreloadedQuery<FundProfileQueryType>(
    FundProfileQuery,
    props.queryRef
  ).companyBySlug;

  if (fund == null) {
    return <Redirect to={InternalRoutes.fundDirectory} />;
  }

  return (
    <GridItem
      gridColumn={"1 / -1"}
      bg={"white100"}
      sx={{ justifyContent: "center" }}
    >
      <FixedGrid>
        <GridItem
          gridColumn={["1 / span 4", "1 / span 8", "1 / span 8", "3 / span 8"]}
          sx={{ flexDirection: "column" }}
        >
          <FundInfo size={["small", "large", "large", "large"]} fund={fund} />
          <Divider />
        </GridItem>
        {!!fund.caseStudyOffer?.tracksUniqByLuminateSongId.edges.length && (
          <FundCaseStudyOffer
            size={["small", "small", "small", "large"]}
            caseStudyOffer={fund.caseStudyOffer}
            queryRef={props.caseStudyOfferQueryRef}
          />
        )}
        <FundTestimonials
          size={["small", "small", "small", "large"]}
          fund={fund}
        />
        {!!fund.offers.edges.length && (
          <GridItem gridColumn={"1 / -1"}>
            <PartnershipsGrid
              size={["small", "small", "small", "large"]}
              offers={fund.offers.edges.map(e => e.node)}
            />
          </GridItem>
        )}
      </FixedGrid>
    </GridItem>
  );
}

export const FundProfileQuery = graphql`
  query FundProfileQuery($slug: String!) {
    companyBySlug(slug: $slug) {
      ...FundInfo_fund
      ...FundTestimonials_company
      caseStudyOffer {
        ...FundCaseStudyOffer_offer
        tracksUniqByLuminateSongId {
          edges {
            node {
              id
            }
          }
        }
      }
      offers(
        isAccepted: true
        isVisible: true
        orderBy: "-date_offer_accepted"
      ) {
        edges {
          node {
            ...PartnershipsGrid_offers
          }
        }
      }
    }
  }
`;

export default FundProfile;
