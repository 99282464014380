import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import { Text } from "theme-ui";

import advanceAmountCentsSchema from "../../../../../../../../schemas/v1-0-0/dealTerms/advanceAmountCents.json";
import creativeAmountCentsSchema from "../../../../../../../../schemas/v1-0-0/dealTerms/creativeAmountCents.json";
import marketingAmountCentsSchema from "../../../../../../../../schemas/v1-0-0/dealTerms/marketingAmountCents.json";
import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import { getInputSpacing } from "../../../../../../components/01_Core/Forms/Input";
import SwitchInput from "../../../../../../components/01_Core/Forms/Inputs/SwitchInput";
import getHasError from "../../../../../../components/01_Core/Forms/utils/getHasError";
import { Field } from "../../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import useSchemaValidation from "../../../../../../components/01_Core/Forms/utils/useSchemaValidation";
import {
  rvMap,
  useFindActiveRV
} from "../../../../../../utils/responsiveUtils";
import { formatNumeral } from "../../../../../../utils/utils";
import { useAuth } from "../../../../hooks/useAuth";
import {
  IFundingOfferFormFieldProps,
  IFundingOfferFormValues
} from "../../../07_Deal_Flow/OfferFormFunding";
import { spendWarnings } from "../../Messaging/OfferMessage/OfferMessageBody";
import FormSection, { FormSectionSize } from "../FormSection";
import OfferFormGuidance from "../OfferFormGuidance";
import { OfferTermsWarningBlock } from "../OfferTermsWarningBlock";
import AdvanceAggregate, { useTotalAmountDollars } from "./AdvanceAggregate";
import { OfferFormAdvanceField } from "./OfferFormAdvanceField";

interface IOfferFormAdvanceProps extends IFundingOfferFormFieldProps {
  heading: string;
  includeTransferControls?: boolean;
  artistName?: string;
  placeholder: string;
  showCopartnerWarnings?: boolean;
}

/*
| Streams per Day on Content Included | Amount of Money Raised |
| --- | --- |
| 0 – 10,000 SPD | $1,000 – $5,000 |
| 11,000 – 60,000 | $5,000 – $20,000 |
| 61,000 – 100,000 SPD | $20,000 – $40,000 |
| 101,000 – 200,000 SPD | $40,000 – $70,000 |
| 200,000 — 300,000 SPD | $70,000 – $100,000 |
| 300,000+ SPD | $100,000+ |
*/
const recommendedMinMaxStreamsPerDay = (dollars: number) => {
  if (dollars <= 5000) return [0, 10000];
  if (dollars <= 20000) return [11000, 60000];
  if (dollars <= 40000) return [61000, 100000];
  if (dollars <= 70000) return [101000, 200000];
  if (dollars <= 100000) return [200000, 300000];
  return [300000, 1000000];
};

/*
| Pre-Release Metrics | Amount of Money Raised |
| --- | --- |
| <1000 pre-saves and/or <500 UGCs | $1,000 – $5,000 |
| 1000 — 10000 pre-saves and/or 500-2500 UGCs | $5,000 – $10,000 |
| 10000 — 25000 pre-saves and/or 2500 — 7500 UGCs | $10,000 – $20,000 |
| 25000 — 50000 pre-saves and/or 7500 — 20000 UGCs | $20,000 – $30,000 |
| 50,000 — 75,000 pre-saves and/or 20000 — 50000 UGCs | $30,000 – $50,000 |
| 75,000 — 100,000  pre-saves and/or 50000 — 75,000 UGCs | $50,000 – $70,000 |
| 100,000 — 150,000 pre-saves and/or 75,000 — 100,000 UGCs | $70,000 — $100,000 |
| 150,000+ pre-saves and/or 100,000+ UGCs | $100,00+ |
*/
const recommendedPreSavesAndUGCs = (dollars: number) => {
  if (dollars <= 5000)
    return [
      [0, 1000],
      [0, 500]
    ];
  if (dollars <= 10000)
    return [
      [1000, 10000],
      [500, 2500]
    ];
  if (dollars <= 20000)
    return [
      [10000, 25000],
      [2500, 7500]
    ];
  if (dollars <= 30000)
    return [
      [25000, 50000],
      [7500, 20000]
    ];
  if (dollars <= 50000)
    return [
      [50000, 75000],
      [20000, 50000]
    ];
  if (dollars <= 70000)
    return [
      [75000, 100000],
      [50000, 75000]
    ];
  if (dollars <= 100000)
    return [
      [100000, 150000],
      [75000, 100000]
    ];
  return [
    [150000, 1000000],
    [100000, 1000000]
  ];
};

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=688%3A1955
 */
function OfferFormAdvance(props: IOfferFormAdvanceProps): ReactElement {
  const validateAdvanceAmount = useSchemaValidation<
    IFundingOfferFormValues["advanceAmountCents"]
  >(advanceAmountCentsSchema, false);
  const validateCreativeAmount = useSchemaValidation<
    IFundingOfferFormValues["creativeAmountCents"]
  >(creativeAmountCentsSchema, false);
  const validateMarketingAmount = useSchemaValidation<
    IFundingOfferFormValues["marketingAmountCents"]
  >(marketingAmountCentsSchema, false);
  const hasError = getHasError<IFundingOfferFormValues>(
    props.showAllErrors,
    "advanceAmountCents",
    "marketingAmountCents",
    "creativeAmountCents"
  );
  const willTransferMarketingFieldName: keyof IFundingOfferFormValues =
    "willTransferMarketingAdvance";
  const willTransferCreativeFieldName: keyof IFundingOfferFormValues =
    "willTransferCreativeAdvance";

  const aggregateLabelVariant = useFindActiveRV(
    props.size,
    getAggregateLabelVariant
  );

  const [recommendedMinStreamsPerDay, recommendedMaxStreamsPerDay] =
    recommendedMinMaxStreamsPerDay(useTotalAmountDollars());
  const [
    [recommendedMinPreSaves, recommendedMaxPreSaves],
    [recommendedMinUGCs, recommendedMaxUGCs]
  ] = recommendedPreSavesAndUGCs(useTotalAmountDollars());

  const formValues = useFormState<IFundingOfferFormValues>().values;
  const anyReleaseHasExistingInvestmentPartnership =
    formValues.allReleases.some(r => r.hasExistingInvestmentPartnership);

  const partnerSpendAmountCents =
    (formValues.willTransferMarketingAdvance
      ? 0
      : formValues.marketingAmountCents) +
    (formValues.willTransferCreativeAdvance
      ? 0
      : formValues.creativeAmountCents);

  const { userType } = useAuth().authUser;

  const disableInvestmentFields =
    props.showCopartnerWarnings && anyReleaseHasExistingInvestmentPartnership;

  return (
    <FormSection
      heading={props.heading}
      error={
        hasError
          ? "Please enter a valid dollar amount for all categories."
          : null
      }
      size={props.size}
    >
      <AutoLayout
        spacing={32}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical" }}
      >
        {props.showCopartnerWarnings &&
          anyReleaseHasExistingInvestmentPartnership && (
            <OfferTermsWarningBlock type={"warning"} size={"large"}>
              {
                "Some of the releases included already have existing deals with an advance. You won't be able to include an advance amount."
              }
            </OfferTermsWarningBlock>
          )}
        <AutoLayout
          spacing={8}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <OfferFormAdvanceField
            fieldName={"advanceAmountCents"}
            label={"In-Pocket"}
            labelTooltip={
              "Money for the artist's cost-of-living expenses during the term length."
            }
            caption={"Optional. For day-to-day living expenses."}
            validator={validateAdvanceAmount}
            placeholder={props.placeholder}
            size={props.size}
            disabled={disableInvestmentFields}
          />
          {props.includeTransferControls && !disableInvestmentFields && (
            <Text variant={"bodySmall"} color={"deepGray100"}>
              In-pocket advance will be sent to {props.artistName}.
            </Text>
          )}
        </AutoLayout>
        <AutoLayout
          spacing={8}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <OfferFormAdvanceField
            fieldName={"marketingAmountCents"}
            label={"Marketing"}
            labelTooltip={
              "Money for promoting and marketing the release(s) during the term length. This may either be paid directly to the artist, or spent by the investor on the artist's behalf."
            }
            caption={"Optional. For promotional expenses."}
            validator={validateMarketingAmount}
            placeholder={props.placeholder}
            size={props.size}
            disabled={disableInvestmentFields}
          />
          {props.includeTransferControls && !disableInvestmentFields && (
            <Field name={willTransferMarketingFieldName}>
              {({ input }) => (
                <SwitchInput
                  input={input}
                  size={props.size}
                  label={`Send marketing advance to ${props.artistName}`}
                />
              )}
            </Field>
          )}
        </AutoLayout>
        <AutoLayout
          spacing={8}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <OfferFormAdvanceField
            fieldName={"creativeAmountCents"}
            label={"Creative"}
            labelTooltip={
              "Money for music production, music videos, artwork, and any other creative work during the term length. This may either be paid directly to the artist, or spent by the investor on the artist's behalf."
            }
            caption={"Optional. For production costs."}
            validator={validateCreativeAmount}
            placeholder={props.placeholder}
            size={props.size}
            disabled={disableInvestmentFields}
          />
          {props.includeTransferControls && !disableInvestmentFields && (
            <Field name={willTransferCreativeFieldName}>
              {({ input }) => (
                <SwitchInput
                  input={input}
                  size={props.size}
                  label={`Send creative advance to ${props.artistName}`}
                />
              )}
            </Field>
          )}
        </AutoLayout>
        <AutoLayout
          spacing={rvMap(props.size, getInputSpacing)}
          dependentProps={{ direction: "vertical" }}
          sx={{ width: "inherit" }}
        >
          <Text variant={aggregateLabelVariant} color={"black100"}>
            Total
          </Text>
          <AdvanceAggregate placeholder={props.placeholder} size={props.size} />
        </AutoLayout>
        {userType === "partner" && partnerSpendAmountCents > 0 && (
          <OfferTermsWarningBlock type={"info"} size={"large"}>
            {spendWarnings[userType]}
          </OfferTermsWarningBlock>
        )}
        {useTotalAmountDollars() > 0 && (
          <OfferFormGuidance>
            Deals raising this amount typically have{" "}
            <strong>
              {formatNumeral(recommendedMinStreamsPerDay)}-
              {formatNumeral(recommendedMaxStreamsPerDay)} streams per day
            </strong>
            ,{" "}
            <strong>
              {formatNumeral(recommendedMinPreSaves)}-
              {formatNumeral(recommendedMaxPreSaves)} pre-saves
            </strong>
            , and/or{" "}
            <strong>
              {formatNumeral(recommendedMinUGCs)}-
              {formatNumeral(recommendedMaxUGCs)} UGCs
            </strong>
            .
          </OfferFormGuidance>
        )}
      </AutoLayout>
    </FormSection>
  );
}

function getAggregateLabelVariant(size: FormSectionSize) {
  switch (size) {
    case "small":
      return "h100";
    case "medium":
      return "h200";
  }
}

export default OfferFormAdvance;
