/**
 * @generated SignedSource<<029de4949859f879b82360a16432a259>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferPartnership_artist$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  readonly " $fragmentType": "OfferPartnership_artist";
};
export type OfferPartnership_artist$key = {
  readonly " $data"?: OfferPartnership_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferPartnership_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferPartnership_artist",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarName_actor"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "10f220d1042f2e5751a62c7f3aa4ce84";

export default node;
