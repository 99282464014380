import { ReactElement, ReactNode } from "react";
import { Box, Text } from "theme-ui";

import { zIndices } from "../../../../../theme/theme";

export interface IRibbonProps {
  textColor: string;
  ribbonFrontColor: string;
  ribbonBackColor: string;
  children: ReactNode;
}

export function Ribbon(props: IRibbonProps): ReactElement {
  return (
    <Box
      sx={{
        zIndex: zIndices.switch,
        width: "120px",
        height: "120px",
        overflow: "hidden",
        position: "absolute",
        top: "-10px",
        left: "-10px",
        "::before, ::after": {
          position: "absolute",
          zIndex: zIndices.hidden,
          content: `''`,
          display: "block",
          border: "5px solid",
          borderColor: props.ribbonBackColor,
          borderTopColor: "transparent",
          borderLeftColor: "transparent"
        },
        "::before": {
          top: "0",
          right: "26px"
        },
        "::after": {
          bottom: "26px",
          left: "0"
        }
      }}
    >
      <Text
        variant={"bodySmall"}
        sx={{
          position: "absolute",
          display: "block",
          width: "200px",
          padding: "10px 0",
          backgroundColor: props.ribbonFrontColor,
          color: props.textColor,
          textAlign: "center",
          right: "-15px",
          top: "15px",
          transform: "rotate(-45deg)",
          lineHeight: "initial",
          textTransform: "uppercase"
        }}
      >
        <strong>{props.children}</strong>
      </Text>
    </Box>
  );
}
