/**
 * @generated SignedSource<<23b39e4296d4b61f566be3f6d1e70ac5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistManageBank_user$data = {
  readonly id: string;
  readonly stripeConnectedAccountApiId: string | null;
  readonly stripeLoginLink: string | null;
  readonly " $fragmentType": "ArtistManageBank_user";
};
export type ArtistManageBank_user$key = {
  readonly " $data"?: ArtistManageBank_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistManageBank_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArtistManageBank_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeConnectedAccountApiId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeLoginLink",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "0a7dc1cf02707df0a5610bae3e81cf28";

export default node;
