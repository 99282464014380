/**
 * @generated SignedSource<<364145cf7f358b36e0f02625d977da7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReleaseTypeEnum = "album" | "compilation" | "single";
import { FragmentRefs } from "relay-runtime";
export type useReleaseMetadata_release$data = {
  readonly releaseDate: any | null;
  readonly releaseType: ReleaseTypeEnum | null;
  readonly tracksCount: number | null;
  readonly " $fragmentType": "useReleaseMetadata_release";
};
export type useReleaseMetadata_release$key = {
  readonly " $data"?: useReleaseMetadata_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"useReleaseMetadata_release">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useReleaseMetadata_release",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tracksCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseDate",
      "storageKey": null
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "89112b225e6e3330ebf286ccb2fc4aed";

export default node;
