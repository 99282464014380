import { useMessage_actor$key } from "__generated__/useMessage_actor.graphql";
import { useMessage_user$key } from "__generated__/useMessage_user.graphql";
import { useMessageUser_GetOrCreateChatMutation } from "__generated__/useMessageUser_GetOrCreateChatMutation.graphql";
import { graphql, useFragment } from "react-relay";
import { generatePath, useHistory } from "react-router-dom";

import { IActionMakeOfferLocationState } from "../components/03_UI_Kit/Actions/ActionMakeOffer";
import { InternalRoutes } from "../Routing/InternalRoutes";
import useMutationPromise from "./useMutationPromise";

const getOrCreateChatMutation = graphql`
  mutation useMessageUser_GetOrCreateChatMutation($recipientUserId: String!) {
    getOrCreateChat(data: { recipientUserId: $recipientUserId }) {
      chat {
        id
        ...InboxChats_chats
      }
    }
  }
`;

export function useMessageUser(
  input: useMessage_user$key,
  locationState?: IActionMakeOfferLocationState
): [() => void, boolean] {
  const history = useHistory();

  const [commitMutation, isInFlight] =
    useMutationPromise<useMessageUser_GetOrCreateChatMutation>(
      getOrCreateChatMutation
    );

  const user = useFragment(
    graphql`
      fragment useMessage_user on UserNode {
        id
      }
    `,
    input
  );

  const messageUser = () =>
    commitMutation({
      variables: { recipientUserId: user.id }
    }).then(response => {
      const path = generatePath(InternalRoutes.inbox, {
        chatId: response.getOrCreateChat.chat.id
      });
      history.push(path, locationState);
    });

  return [messageUser, isInFlight];
}

export function useMessageActor(
  input: useMessage_actor$key,
  locationState?: IActionMakeOfferLocationState
): ReturnType<typeof useMessageUser> {
  const actor = useFragment(
    graphql`
      fragment useMessage_actor on ActorInterface {
        user {
          ...useMessage_user
        }
      }
    `,
    input
  );
  return useMessageUser(actor.user, locationState);
}
