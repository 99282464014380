/**
 * @generated SignedSource<<4840894756b38c448e71fc582b24881f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type useCanAccessMarketplace_authUser$data = {
  readonly canAccessMarketplace: boolean;
  readonly userType: UserType | null;
  readonly " $fragmentType": "useCanAccessMarketplace_authUser";
};
export type useCanAccessMarketplace_authUser$key = {
  readonly " $data"?: useCanAccessMarketplace_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCanAccessMarketplace_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCanAccessMarketplace_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessMarketplace",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "1fce9dbefc74a874b56f8859b981318f";

export default node;
