/**
 * @generated SignedSource<<3cffbad69ab5cccd5aa55bd78a87c645>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OfferStatus = "active" | "canceled" | "declined" | "legacy_incomplete_transfer" | "needs_artist_response" | "needs_earnings_directed" | "needs_invoice_creation" | "needs_invoice_payment" | "voided";
import { FragmentRefs } from "relay-runtime";
export type OfferMessageCTAArtist_offer$data = {
  readonly id: string;
  readonly status: OfferStatus;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferDialog_offer">;
  readonly " $fragmentType": "OfferMessageCTAArtist_offer";
};
export type OfferMessageCTAArtist_offer$key = {
  readonly " $data"?: OfferMessageCTAArtist_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageCTAArtist_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferMessageCTAArtist_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AcceptOfferDialog_offer"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "14cb6df26a68085f4fc666d9e68c2e48";

export default node;
