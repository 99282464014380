import {
  UpdateOrCreateReleaseModal_Mutation,
  UpdateOrCreateReleaseMutationInput
} from "__generated__/UpdateOrCreateReleaseModal_Mutation.graphql";
import { UpdateOrCreateReleaseModal_release$key } from "__generated__/UpdateOrCreateReleaseModal_release.graphql";
import { ReactElement, useCallback, useContext } from "react";
import { Field, Form } from "react-final-form";
import { graphql, useFragment } from "react-relay";

import { BannerContext } from "../../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import SelectInput from "../../../../../components/01_Core/Forms/Inputs/SelectInput";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import useMutationPromise from "../../../hooks/useMutationPromise";
import { ArtistContext } from "../../08_Account_Management/ArtistManageReleases";
import { getURLValidatorWithRedirects } from "../../08_Account_Management/FormFields/Platform";
import BasicModal from "../Modals/BasicModal";
import {
  soundLinkCaptionText,
  useSoundLinkIcons,
  validateSoundLink
} from "../Offers/OfferFormSoundLink";

type ReleaseTypeOption = {
  label: string;
  value: UpdateOrCreateReleaseMutationInput["releaseType"];
};

export type IEditReleaseFormValues = Omit<
  UpdateOrCreateReleaseMutationInput,
  "id" | "typename"
>;

const releaseTypeOptions: ReleaseTypeOption[] = [
  { label: "Single", value: "single" },
  { label: "Compilation", value: "compilation" },
  { label: "Album", value: "album" }
];

export function useUpdateOrCreateRelease(props: {
  artist: { id: string };
  release: UpdateOrCreateReleaseModal_release$key | null;
  onCompleted?: () => void;
}): [IEditReleaseFormValues, (values: IEditReleaseFormValues) => Promise<any>] {
  const notifyBanner = useContext(BannerContext);

  const release =
    props.release &&
    useFragment(
      graphql`
        fragment UpdateOrCreateReleaseModal_release on ReleaseInterface {
          __typename
          id
          name
          releaseType
          soundLink
          isVisible
          tracksCount
        }
      `,
      props.release
    );

  const currentValues: IEditReleaseFormValues = release
    ? {
        name: release.name,
        releaseType: release.releaseType,
        soundLink: release.soundLink,
        isVisible: release.isVisible,
        tracksCount: release.tracksCount
      }
    : { isVisible: true };

  const [commit] = useMutationPromise<UpdateOrCreateReleaseModal_Mutation>(
    graphql`
      mutation UpdateOrCreateReleaseModal_Mutation(
        $input: UpdateOrCreateReleaseMutationInput!
      ) {
        updateOrCreateRelease(data: $input) {
          release {
            id
          }
        }
      }
    `
  );

  const doMutation = (values: IEditReleaseFormValues) =>
    commit({
      variables: {
        input: {
          id: release && release.id,
          typename: (release
            ? release.__typename
            : "UnreleasedReleaseNode") as UpdateOrCreateReleaseMutationInput["typename"],
          ...currentValues,
          ...values
        }
      },
      updater: store => store.get(props.artist.id)?.invalidateRecord()
    })
      .then(() => {
        notifyBanner({
          type: "neutral",
          children: `Your release has been ${
            props.release ? "updated" : "created"
          } successfully.`
        });
        props.onCompleted?.();
      })
      .catch(() =>
        notifyBanner({
          type: "alert",
          children: "Something went wrong."
        })
      );

  return [currentValues, doMutation];
}

export default function UpdateOrCreateReleaseModal(props: {
  release?: UpdateOrCreateReleaseModal_release$key | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}): ReactElement {
  const artist = useContext(ArtistContext);
  const [initialValues, updateRelease] = useUpdateOrCreateRelease({
    artist,
    release: props.release,
    onCompleted: () => props.setIsOpen(false)
  });
  return (
    <EditReleaseModal
      initialValues={initialValues}
      onSubmit={updateRelease}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      releaseExists={!!props.release}
      submitLabel={"Save"}
    />
  );
}

export interface IEditReleaseModalProps {
  initialValues: IEditReleaseFormValues;
  onSubmit: (values: IEditReleaseFormValues) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  releaseExists: boolean;
  submitLabel: string;
}

export function EditReleaseModal(props: IEditReleaseModalProps): ReactElement {
  return (
    <Form<IEditReleaseFormValues>
      onSubmit={props.onSubmit}
      initialValues={props.initialValues}
    >
      {formProps => {
        const soundLinkValidator = useCallback(
          getURLValidatorWithRedirects(validateSoundLink),
          []
        );
        const soundLinkIcons = useSoundLinkIcons(formProps.values.soundLink);
        return (
          <BasicModal
            title={`${
              props.releaseExists ? "Edit" : "Create"
            } upcoming release`}
            fullScreenMobile={true}
            modalFooterCtaProps={{
              size: "large",
              ctaLabel: props.submitLabel,
              onClick: formProps.handleSubmit,
              loading: formProps.submitting,
              disabled: !formProps.valid
            }}
            isOpen={props.isOpen}
            onClose={() => props.setIsOpen(false)}
          >
            <form
              style={{
                height: "fit-content",
                width: "100%",
                padding: "24px"
              }}
            >
              <AutoLayout
                spacing={24}
                dependentProps={{ direction: "vertical" }}
                sx={{ width: "100%" }}
              >
                <FieldBuilder
                  inputField={TextInput}
                  fieldName={"name"}
                  inputFieldProps={{
                    size: "large",
                    label: "Name",
                    placeholder: "Enter release name..."
                  }}
                  validator={value => {
                    if (!value) return "Required";
                  }}
                />
                <Field name={"releaseType"} allowNull={true}>
                  {({ input, ...fieldProps }) => (
                    <SelectInput<ReleaseTypeOption>
                      {...fieldProps}
                      size={"large"}
                      label={"Release type"}
                      isClearable={true}
                      input={{
                        ...input,
                        onChange: (v: ReleaseTypeOption) =>
                          input.onChange(v?.value ?? null),
                        value: releaseTypeOptions.find(
                          o => o.value === input.value
                        )
                      }}
                      options={releaseTypeOptions}
                    />
                  )}
                </Field>
                <Field name={"tracksCount"} allowNull={true}>
                  {fieldProps => (
                    <TextInput
                      {...fieldProps}
                      size={"large"}
                      label={"Tracks count"}
                      type={"number"}
                    />
                  )}
                </Field>
                <FieldBuilder
                  inputField={TextInput}
                  fieldName={"soundLink"}
                  inputFieldProps={{
                    iconProps: {
                      Icon: soundLinkIcons[1],
                      placement: "left",
                      color: "deepGray100"
                    },
                    size: "large",
                    label: "Sound link",
                    placeholder: "http://",
                    caption: `Optional. Provide a ${soundLinkCaptionText}`
                  }}
                  validator={soundLinkValidator}
                />
              </AutoLayout>
            </form>
          </BasicModal>
        );
      }}
    </Form>
  );
}
