/**
 * @generated SignedSource<<2abe85c4b6abf5099ce5dfb6eeae2e06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferForm_artist$data = {
  readonly displayName: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"OfferFormHeader_artist">;
  readonly " $fragmentType": "OfferForm_artist";
};
export type OfferForm_artist$key = {
  readonly " $data"?: OfferForm_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferForm_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferForm_artist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferFormHeader_artist"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "f7841c64a77b0c9833fd8ed595ccea8a";

export default node;
