import { useToggleWatchCompany_company$key } from "__generated__/useToggleWatchCompany_company.graphql";
import { useToggleWatchCompany_Mutation } from "__generated__/useToggleWatchCompany_Mutation.graphql";
import { graphql, useFragment } from "react-relay";

import useMutationPromise from "../pages/indify/hooks/useMutationPromise";

export default function useToggleWatchCompany(
  company: useToggleWatchCompany_company$key
): [() => void, boolean] {
  const { slug } = useFragment(
    graphql`
      fragment useToggleWatchCompany_company on CompanyNode {
        slug
      }
    `,
    company
  );
  const [commit, isInFlight] =
    useMutationPromise<useToggleWatchCompany_Mutation>(graphql`
      mutation useToggleWatchCompany_Mutation($companySlug: String!) {
        toggleWatchCompany(companySlug: $companySlug) {
          company {
            watching
          }
        }
      }
    `);
  return [() => commit({ variables: { companySlug: slug } }), isInFlight];
}
