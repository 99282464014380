import { FieldValidator } from "final-form";
import _ from "lodash";
import { ComponentType, createContext, ReactElement, ReactNode } from "react";
import { Box, Flex, FlexProps, Spinner, Text } from "theme-ui";

import { InputProps } from "./Input";
import { Field } from "./utils/reactFinalFormWrappers";

// https://codesandbox.io/s/react-final-form-prefixed-fields-seiy8
export const FieldPrefixContext = createContext("");
export const FieldPrefix = ({
  prefix,
  children
}: {
  prefix: string;
  children: ReactNode;
}): ReactElement => (
  <FieldPrefixContext.Provider value={prefix}>
    {children}
  </FieldPrefixContext.Provider>
);

/**
 * generic FieldType: The type of data stored in the field, e.g. string, number, boolean, etc.
 * prop inputField: The component that will be rendered, e.g. TextInput, NumberInput, Switch.
 * prop inputFieldProps: The props that will be passed to the inputField component.
 */
interface IFieldBuilderProps<
  ElementType extends HTMLInputElement | HTMLTextAreaElement,
  FieldType
> extends FlexProps {
  inputField: ComponentType<InputProps<ElementType, FieldType>>;
  inputFieldProps?: Omit<
    InputProps<ElementType, FieldType>,
    "input" | "meta"
  > & { options?: any[]; isMulti?: boolean };
  fieldName: string;
  validator?: FieldValidator<FieldType>;
  suppressValidationText?: boolean;
  showErrorsBeforeTouched?: boolean;
  initialValue?: FieldType;
}

function FieldBuilder<
  ElementType extends HTMLInputElement | HTMLTextAreaElement,
  FieldType
>(props: IFieldBuilderProps<ElementType, FieldType>): ReactElement {
  const {
    inputField: InputField,
    inputFieldProps,
    fieldName,
    validator,
    suppressValidationText,
    showErrorsBeforeTouched,
    initialValue,
    ...flexProps
  } = props;
  const { sx: flexPropsSx, ...flexPropsRest } = flexProps;

  return (
    <Field<FieldType>
      name={fieldName}
      validate={validator}
      allowNull={true}
      initialValue={initialValue}
    >
      {({ input, meta }) => {
        const hasError =
          (meta.error || meta.submitError) &&
          (meta.touched || showErrorsBeforeTouched);
        const errors = _.compact(_.flatten([meta.error, meta.submitError]));

        const finalInputFieldProps = {
          input,
          meta,
          ...inputFieldProps,
          iconProps: inputFieldProps.iconProps
            ? {
                ...inputFieldProps.iconProps,
                ...(meta.validating && meta.dirty
                  ? { Icon: Spinner as SvgrComponent }
                  : {})
              }
            : undefined,
          ...(hasError ? { state: "alert" } : {})
        };

        return (
          <Flex
            {...flexPropsRest}
            sx={{ flexDirection: "column", width: "100%", ...flexPropsSx }}
          >
            <InputField {...(finalInputFieldProps as any)} />
            {hasError && !suppressValidationText && (
              <FieldErrors errors={errors} />
            )}
          </Flex>
        );
      }}
    </Field>
  );
}

export function FieldErrors(props: { errors: any[] }): ReactElement {
  const NonBreakingSpace = String.fromCharCode(160);

  return (
    <Box
      mt={"4px"}
      sx={{
        position: "relative"
      }}
    >
      <Text variant={"error"}>{NonBreakingSpace}</Text>
      {props.errors.map((error, i) => (
        <Text
          key={error}
          variant={"alert.bodyMedium"}
          sx={{
            overflow: "visible",
            position: "absolute",
            left: 0,
            top: i * 12,
            whiteSpace: "nowrap"
          }}
        >
          {error}
        </Text>
      ))}
    </Box>
  );
}

export default FieldBuilder;
