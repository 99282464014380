import { useArtistThumbnailUrl_artist$key } from "__generated__/useArtistThumbnailUrl_artist.graphql";
import { graphql, useFragment } from "react-relay";

import { uploadedImageOrFallback } from "../../../utils/utils";

export default function useArtistThumbnailUrl(
  query: useArtistThumbnailUrl_artist$key
): string {
  const artist = useFragment(
    graphql`
      fragment useArtistThumbnailUrl_artist on ArtistNode {
        thumbnailUrl
        spotifyProfile {
          thumbnailUrl
        }
      }
    `,
    query
  );
  return uploadedImageOrFallback(
    artist.thumbnailUrl,
    artist.spotifyProfile?.thumbnailUrl
  );
}
