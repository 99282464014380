import { Fragment, ReactElement, ReactNode, useRef, useState } from "react";
import { Form, FormRenderProps, useForm } from "react-final-form";
import { Box } from "theme-ui";

import Pager, { IPagerPageLayoutArgs, PagerPage } from "./Pager";

export interface IWizardPageLayoutProps extends IPagerPageLayoutArgs {
  nextOnClick: () => void;
  prevOnClick: () => void;
  hasAttemptedSubmit: boolean;
}

export interface IWizardProps<T> {
  pageLayout: (pageLayoutProps: IWizardPageLayoutProps) => ReactNode;
  pages: (pagesProps: { hasAttemptedSubmit: boolean }) => ReactNode[];
  onSubmit: (data: T) => void | Promise<any>;
  initialValues?: Partial<T>;
}

function Wizard<T>(props: IWizardProps<T>): ReactElement {
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  return (
    <Form onSubmit={props.onSubmit} initialValues={props.initialValues}>
      {formProps => {
        const formState = {
          hasAttemptedSubmit,
          formProps
        };

        const pageTopRef = useRef(null);
        const scrollToTop = () =>
          pageTopRef.current?.scrollIntoView({ behavior: "smooth" });

        return (
          <Box ref={pageTopRef}>
            <Pager
              pageLayout={pageLayoutProps => {
                return (
                  <form style={{ height: "inherit" }}>
                    <FormStateHandler
                      pageLayoutProps={pageLayoutProps}
                      setHasAttemptedSubmit={setHasAttemptedSubmit}
                      formState={formState}
                      pageLayout={props.pageLayout}
                      scrollToTop={scrollToTop}
                    />
                  </form>
                );
              }}
            >
              {props.pages(formState).map((child, i) => (
                <Fragment key={i}>{child}</Fragment>
              ))}
            </Pager>
          </Box>
        );
      }}
    </Form>
  );
}

function FormStateHandler<T>(props: {
  pageLayoutProps: IPagerPageLayoutArgs;
  setHasAttemptedSubmit: (hasAttemptedSubmit: boolean) => void;
  formState: {
    hasAttemptedSubmit: boolean;
    formProps: FormRenderProps<T>;
  };
  pageLayout: (pageLayoutProps: IWizardPageLayoutProps) => ReactNode;
  scrollToTop: () => void;
}): ReactNode {
  // This is a hack to handle a special case where the form uses an async validation on a non-first page -- `formProps.validating` does not update when returning to the first page, so we need to blur a field asynchonously to trigger the validation again
  const formApi = useForm<T>();
  const touchFormLater = () => {
    setTimeout(() => {
      for (const fieldName of Object.keys(formApi.getState().values)) {
        formApi.blur(fieldName as keyof T);
      }
    }, 0);
  };

  const { pageLayoutProps, setHasAttemptedSubmit, formState } = props;
  const { formProps } = formState;
  const { nextPage, prevPage } = pageLayoutProps;
  const prevOnClick = () => {
    prevPage();
    touchFormLater();
  };
  const nextOnClick = () => {
    setHasAttemptedSubmit(true);
    if (formProps.hasValidationErrors) {
      props.scrollToTop();
      return; // Disallow next/submit
    }

    if (nextPage) {
      setHasAttemptedSubmit(false);
      nextPage();
      touchFormLater();
      props.scrollToTop();
    } else {
      formProps.handleSubmit();
    }
  };

  return props.pageLayout({
    ...pageLayoutProps,
    ...formState,
    nextOnClick,
    prevOnClick
  });
}

export default Wizard;

export const WizardPage = PagerPage;
