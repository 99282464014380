import { OfferPartnership_artist$key } from "__generated__/OfferPartnership_artist.graphql";
import { OfferPartnership_partner$key } from "__generated__/OfferPartnership_partner.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, ThemeUIStyleObject, useThemeUI } from "theme-ui";

import Union from "../../../../../../imgs/icons/Union.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { ActorAvatarName } from "../../../../../components/01_Core/Users/AvatarName";

export interface IOfferPartnershipProps {
  artist: OfferPartnership_artist$key;
  partner: OfferPartnership_partner$key;
  styles?: ThemeUIStyleObject;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=640%3A68
 */
function OfferPartnership(props: IOfferPartnershipProps): ReactElement {
  const { theme } = useThemeUI();
  const artist = useFragment(
    graphql`
      fragment OfferPartnership_artist on ArtistNode {
        ...AvatarName_actor
      }
    `,
    props.artist
  );
  const partner = useFragment(
    graphql`
      fragment OfferPartnership_partner on PartnerNode {
        ...AvatarName_actor
      }
    `,
    props.partner
  );

  const avatarProps = {
    size: "small" as const,
    disableLink: true,
    disableActiveDot: true
  };

  return (
    <AutoLayout
      spacing={16}
      dependentProps={[
        {
          direction: "vertical",
          alignment: "center"
        },
        {
          direction: "vertical",
          alignment: "center"
        },
        {
          direction: "horizontal",
          alignment: "center"
        },
        {
          direction: "horizontal",
          alignment: "center"
        }
      ]}
      sx={{
        width: "100%",
        ...props.styles
      }}
    >
      <ActorAvatarName actor={partner} {...avatarProps} />
      <Box m={"6px"}>
        <Union color={theme.colors.black100 as string} />
      </Box>
      <ActorAvatarName actor={artist} {...avatarProps} />
    </AutoLayout>
  );
}

export default OfferPartnership;
