import { ReactElement } from "react";

import Ic16Dollar from "../../../../../imgs/icons/ic16-dollar.svg";
import Ic20Dollar from "../../../../../imgs/icons/ic20-dollar.svg";
import { centsToDollars, dollarsToCents } from "../../../../utils/money";
import { useFindActiveRV } from "../../../../utils/responsiveUtils";
import { InputProps, InputSize } from "../Input";
import NumberInput from "./NumberInput";

function DollarInput(
  props: InputProps<HTMLInputElement, number | null>
): ReactElement {
  const {
    input: { value, onChange, ...inputRest },
    meta,
    ...rest
  } = props;

  const serializingOnChange = (v: number) => {
    onChange(v == null ? null : dollarsToCents(v));
  };

  const serializedValue = value == null ? null : centsToDollars(value);

  return (
    <NumberInput
      {...rest}
      iconProps={{
        Icon: useFindActiveRV(props.size, getIcon),
        placement: "left",
        color: "deepGray70"
      }}
      input={{
        value: serializedValue,
        onChange: serializingOnChange,
        ...inputRest
      }}
      placeholder={props.placeholder ?? "0"}
    />
  );
}

function getIcon(size: InputSize) {
  switch (size) {
    case "small":
      return Ic16Dollar;
    case "medium":
    case "large":
      return Ic20Dollar;
  }
}

export default DollarInput;
