import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

import { rvMap, useFindActiveRV } from "../../../../../utils/responsiveUtils";

type PageHeadingSecondLevelSize = "small" | "large";
interface IPageHeadingSecondLevelProps {
  size: ResponsiveValue<PageHeadingSecondLevelSize>;
  children: ReactNode;
  sxOverrides?: ThemeUIStyleObject;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1684%3A25620
 */
function PageHeadingSecondLevel(
  props: IPageHeadingSecondLevelProps
): ReactElement {
  const textType = useFindActiveRV(props.size, getType);

  return (
    <Flex
      padding={rvMap(props.size, getPadding)}
      sx={{ justifyContent: "center", width: "100%", ...props.sxOverrides }}
    >
      <Text variant={textType} color={"black100"}>
        {props.children}
      </Text>
    </Flex>
  );
}

function getType(size: PageHeadingSecondLevelSize) {
  switch (size) {
    case "small":
      return "h400";
    case "large":
      return "h600";
  }
}

function getPadding(size: PageHeadingSecondLevelSize) {
  switch (size) {
    case "small":
      return "32px 0";
    case "large":
      return "88px 0 40px 0";
  }
}

export default PageHeadingSecondLevel;
