import _ from "lodash";
import { useFormState } from "react-final-form";

export default function getHasError<FormValues>(
  showAllErrors: boolean,
  ...fieldNames: (keyof FormValues & string)[]
): boolean {
  return _.some(getErrors<FormValues>(showAllErrors, ...fieldNames));
}

export function getErrors<FormValues>(
  showAllErrors: boolean,
  ...fieldNames: (keyof FormValues & string)[]
): string[] {
  const formProps = useFormState<FormValues>();
  return fieldNames
    .flatMap(fieldName => {
      const fieldCanShowError = showAllErrors || formProps.touched[fieldName];
      if (!fieldCanShowError) return;
      const fieldErrors = _.get(formProps.errors, fieldName);
      switch (typeof fieldErrors) {
        case "string":
          return [fieldErrors];
        case "object":
          return _.values(fieldErrors);
        default:
          return null;
      }
    })
    .filter(Boolean);
}
