import { ReferralRecipientActive_recipient$key } from "__generated__/ReferralRecipientActive_recipient.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer,
  HugContents
} from "../../../../../../components/01_Core/AutoLayout";
import Divider from "../../../../../../components/01_Core/Miscelleneous/Divider";
import { ActorAvatarName } from "../../../../../../components/01_Core/Users/AvatarName";

dayjs.extend(relativeTime);

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1549%3A28430
 */
function ReferralRecipientActive(props: {
  recipient: ReferralRecipientActive_recipient$key;
}): ReactElement {
  const recipient = useFragment(
    graphql`
      fragment ReferralRecipientActive_recipient on ActorInterface {
        ...AvatarName_actor
        dateJoined
        user {
          status
        }
      }
    `,
    props.recipient
  );

  return (
    <Box>
      <Divider />
      <AutoLayout
        spacing={16}
        resizingX={FillContainer()}
        resizingY={HugContents()}
        dependentProps={{
          direction: "horizontal",
          distribution: "spaceBetween",
          alignment: "center"
        }}
        py={"16px"}
      >
        <ActorAvatarName actor={recipient} size={"large"} disableLink />
        <Text variant={"bodySmall"} color={"deepGray100"}>
          {recipient.user?.status === "ACTIVE"
            ? `Joined ${dayjs().to(dayjs(recipient.dateJoined))}`
            : "Inactive"}
        </Text>
      </AutoLayout>
    </Box>
  );
}

export default ReferralRecipientActive;
