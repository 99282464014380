import { CSSProperties, ReactElement } from "react";
import { Link } from "react-router-dom";

export default function ConditionalLink(props: {
  children: ReactElement;
  to: string;
  disabled: boolean;
  style?: CSSProperties;
}): ReactElement {
  const { children, to, disabled, style } = props;
  return !disabled ? (
    <Link to={to} style={style}>
      {children}
    </Link>
  ) : (
    children
  );
}
