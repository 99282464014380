import { useUserCanViewChats_authUser$key } from "__generated__/useUserCanViewChats_authUser.graphql";
import { graphql, useFragment } from "react-relay";

import { useAuth } from "./useAuth";
import useCanAccessMarketplace from "./useCanAccessMarketplace";

export default function useUserCanViewChats(): [boolean, string] {
  const authUser = useFragment<useUserCanViewChats_authUser$key>(
    graphql`
      fragment useUserCanViewChats_authUser on UserNode {
        chats {
          edges {
            node {
              __typename
            }
          }
        }
      }
    `,
    useAuth().authUser
  );

  const [canAccessMarketplace, cannotAccessMarketplaceText] =
    useCanAccessMarketplace();

  if (canAccessMarketplace || authUser.chats.edges.length > 0) {
    return [true, null];
  }

  return [false, cannotAccessMarketplaceText];
}
