/**
 * @generated SignedSource<<55c7aa464e19f6bd85d8b18a85170e82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistDirectoryQuery$variables = {};
export type ArtistDirectoryQuery$data = {
  readonly artistCount: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistDirectoryCards_allArtists">;
};
export type ArtistDirectoryQuery = {
  response: ArtistDirectoryQuery$data;
  variables: ArtistDirectoryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "artistCount",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "displayName_Icontains",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 6
  },
  {
    "kind": "Literal",
    "name": "onlyWatching",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "growth_score_desc"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentChange",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArtistDirectoryQuery",
    "selections": [
      (v0/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ArtistDirectoryCards_allArtists"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ArtistDirectoryQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArtistNodeConnection",
        "kind": "LinkedField",
        "name": "allArtists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArtistNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastActiveAt",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "partnershipStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArtistSeekingServiceNodeConnection",
                    "kind": "LinkedField",
                    "name": "seekingServices",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ArtistSeekingServiceNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ArtistSeekingServiceNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "service",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GenreNode",
                    "kind": "LinkedField",
                    "name": "genre",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "genre",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArtistStatsObject",
                    "kind": "LinkedField",
                    "name": "stats",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "streamsThisWeek",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weeklyChange",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StatWithDelta",
                    "kind": "LinkedField",
                    "name": "latestSpotifyMonthlyListenerCount",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StatWithDelta",
                    "kind": "LinkedField",
                    "name": "latestSpotifyFollowerCount",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "spotifyProfile",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StatWithDelta",
                    "kind": "LinkedField",
                    "name": "latestTiktokFollowerCount",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "tiktokProfile",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TikTokSoundNodeConnection",
                        "kind": "LinkedField",
                        "name": "tiktokSounds",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TikTokSoundNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TikTokSoundNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "tiktokUrl",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StatWithDelta",
                                    "kind": "LinkedField",
                                    "name": "mostRecentStat",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "previous",
                                        "storageKey": null
                                      },
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StatWithDelta",
                    "kind": "LinkedField",
                    "name": "latestInstagramFollowerCount",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "instagramProfile",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allArtists(displayName_Icontains:\"\",first:6,onlyWatching:false,orderBy:\"growth_score_desc\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "displayName_Icontains",
          "onlyWatching",
          "genres",
          "partnershipStatuses",
          "seekingServices",
          "streamsThisWeek",
          "spotifyMonthlyListeners",
          "tiktokPosts",
          "tiktokFollowers",
          "instagramFollowers"
        ],
        "handle": "connection",
        "key": "ArtistList_allArtists",
        "kind": "LinkedHandle",
        "name": "allArtists"
      }
    ]
  },
  "params": {
    "cacheID": "58b5c20045f9373c29e12fb754755a44",
    "id": null,
    "metadata": {},
    "name": "ArtistDirectoryQuery",
    "operationKind": "query",
    "text": "query ArtistDirectoryQuery {\n  artistCount\n  ...ArtistDirectoryCards_allArtists\n}\n\nfragment ArtistDirectoryCard_artist on ArtistNode {\n  user {\n    lastActiveAt\n    id\n  }\n  slug\n  displayName\n  imageUrl\n  partnershipStatus\n  seekingServices {\n    edges {\n      node {\n        service\n        id\n      }\n    }\n  }\n  genre {\n    genre\n    id\n  }\n  stats {\n    streamsThisWeek\n    weeklyChange\n  }\n  latestSpotifyMonthlyListenerCount {\n    current\n    percentChange\n  }\n  latestSpotifyFollowerCount {\n    current\n    percentChange\n  }\n  spotifyProfile {\n    imageUrl\n    url\n    id\n  }\n  latestTiktokFollowerCount {\n    current\n    percentChange\n  }\n  tiktokProfile {\n    url\n    tiktokSounds {\n      ...RowDeal_useTikTokSoundsTotal\n    }\n    id\n  }\n  latestInstagramFollowerCount {\n    current\n    percentChange\n  }\n  instagramProfile {\n    url\n    id\n  }\n}\n\nfragment ArtistDirectoryCards_allArtists on Query {\n  allArtists(first: 6, orderBy: growth_score_desc, displayName_Icontains: \"\", onlyWatching: false) {\n    edges {\n      node {\n        id\n        ...ArtistDirectoryCard_artist\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment RowDeal_useTikTokSoundsTotal on TikTokSoundNodeConnection {\n  edges {\n    node {\n      tiktokUrl\n      name\n      mostRecentStat {\n        current\n        previous\n        percentChange\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "145e3a1778318fa00edebce8422d9098";

export default node;
