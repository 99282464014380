import { ReactElement, useEffect } from "react";
import { useField } from "react-final-form";

interface IHiddenFieldValue<
  FormValues,
  FormValuesKey extends keyof FormValues & string
> {
  field: FormValuesKey;
  value: FormValues[FormValuesKey];
}

export function HiddenFieldValue<
  FormValues,
  FormValuesKey extends keyof FormValues & string
>(props: IHiddenFieldValue<FormValues, FormValuesKey>): ReactElement {
  const {
    input: { onChange }
  } = useField(props.field);
  useEffect(() => {
    onChange(props.value);
  }, [props.value]);
  return null;
}
