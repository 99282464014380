import { Inbox_authUser$key } from "__generated__/Inbox_authUser.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text } from "theme-ui";

import Ic24Chat from "../../../../../../../../imgs/icons/ic24-chat.svg";
import { Tooltip } from "../../../../../../../components/01_Core/Tooltips/Tooltip";
import InternalLink from "../../../../../../../utils/InternalLink";
import { rvMap } from "../../../../../../../utils/responsiveUtils";
import { useAuth } from "../../../../../hooks/useAuth";
import useUserCanViewChats from "../../../../../hooks/useUserCanViewChats";
import { InternalRoutes } from "../../../../../Routing/InternalRoutes";

export type InboxSize = "small" | "large";

interface IInboxProps {
  size: ResponsiveValue<InboxSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=212%3A2081
 */
function Inbox(props: IInboxProps): ReactElement {
  const { numUnreadMessages } = useFragment<Inbox_authUser$key>(
    graphql`
      fragment Inbox_authUser on UserNode {
        numUnreadMessages
      }
    `,
    useAuth().authUser
  );

  const [userCanViewChats, userCannotViewChatsText] = useUserCanViewChats();

  return (
    <Tooltip
      size="medium"
      content={userCanViewChats ? null : userCannotViewChatsText}
    >
      <InternalLink
        to={generatePath(InternalRoutes.inbox)}
        disabled={!userCanViewChats}
      >
        <Flex
          paddingLeft={["12px", "12px", "16px", "16px"]}
          paddingRight={"12px"}
          paddingY={"8px"}
          sx={{
            alignItems: "center",
            border: "1px solid",
            borderColor: "midGray70",
            borderRadius: "4px",
            height: rvMap(props.size, getHeight),
            ...(userCanViewChats && {
              ":hover": { borderColor: "deepGray100" }
            }),
            transition: "border-color 0.2s"
          }}
        >
          <Text
            variant={"bodyMedium"}
            mr={10}
            sx={{
              display: ["none", "none", "initial", "initial"]
            }}
          >
            Inbox
          </Text>
          {numUnreadMessages > 0 ? (
            <Counter unreadCount={numUnreadMessages} />
          ) : (
            <Ic24Chat viewBox={"0 0 24 24"} height={"20px"} width={"20px"} />
          )}
        </Flex>
      </InternalLink>
    </Tooltip>
  );
}

function Counter(props: { unreadCount: number }) {
  return (
    <Box
      p={"5px 8px 3px 8px"}
      sx={{ borderRadius: "3px", backgroundColor: "black100" }}
    >
      <Text variant={"bodySmall"} color={"white100"}>
        <strong>{props.unreadCount}</strong>
      </Text>
    </Box>
  );
}

function getHeight(size: InboxSize) {
  switch (size) {
    case "small":
      return "40px";
    case "large":
      return "44px";
  }
}

export default Inbox;
