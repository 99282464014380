import { Platform_FollowUrlRedirectsQuery } from "__generated__/Platform_FollowUrlRedirectsQuery.graphql";
import { ProfilePlatform } from "__generated__/SocialLinks_profiles.graphql";
import debouncePromise from "debounce-promise";
import { FieldValidator } from "final-form";
import _ from "lodash";
import { ReactElement, useCallback } from "react";
import { fetchQuery, graphql } from "react-relay";

import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import environment from "../../../../../environment";
import { getPlatformIcons } from "../../../../../utils/getPlatformIcons";
import { useBreakpoint } from "../../../../../utils/useBreakpoints";

export interface IPlatformProps {
  platform: ProfilePlatform;
  label?: string;
  caption?: string | ReactElement;
  disabled?: boolean;
}

const PLATFORMS = GLOBALS.PLATFORMS;

export const getPlatformURLValidator = (
  platform: ProfilePlatform
): FieldValidator<string | null> => {
  const { regexes, displayName } = PLATFORMS[platform];
  return value => {
    if (!value) return;

    return value.match(regexes.profile)
      ? undefined
      : `Please enter a valid ${displayName} profile URL.`;
  };
};

const followUrlRedirects = _.memoize((url: string) =>
  fetchQuery<Platform_FollowUrlRedirectsQuery>(
    environment,
    FollowUrlRedirectsQuery,
    { url }
  ).toPromise()
);

export async function getFinalURLWithRedirects(
  urlValidator: FieldValidator<string | null>,
  value: string
): Promise<string> {
  const debouncedFollowUrlRedirects = debouncePromise(followUrlRedirects, 500);
  const error = urlValidator(value, []);
  if (!error) return value; // We already have an acceptable input; no need to follow redirects

  const { followUrlRedirects: newUrl } = await debouncedFollowUrlRedirects(
    value
  );
  return newUrl;
}

export function getURLValidatorWithRedirects(
  urlValidator: FieldValidator<string | null>
): FieldValidator<string | null> {
  return async (value, allValues) => {
    const newUrl = await getFinalURLWithRedirects(urlValidator, value);
    return urlValidator(newUrl, allValues);
  };
}

function Platform(props: IPlatformProps): ReactElement {
  const breakpointMap = useBreakpoint();
  const [[IconSmall], [IconMedium]] = getPlatformIcons(props.platform);
  const IconComponent = breakpointMap.mobile ? IconSmall : IconMedium;

  const validator = useCallback(
    getURLValidatorWithRedirects(getPlatformURLValidator(props.platform)),
    [props.platform]
  );

  return (
    <FieldBuilder
      inputField={TextInput}
      fieldName={props.platform.toLowerCase()}
      validator={validator}
      inputFieldProps={{
        placeholder: "Add profile URL",
        size: ["medium", "medium", "large", "large"],
        disabled: props.disabled,
        label: props.label ?? PLATFORMS[props.platform].displayName,
        caption: props.caption,
        iconProps: {
          Icon: IconComponent,
          placement: "left",
          color: "deepGray100"
        }
      }}
    />
  );
}

const FollowUrlRedirectsQuery = graphql`
  query Platform_FollowUrlRedirectsQuery($url: String!) {
    followUrlRedirects(url: $url)
  }
`;

export default Platform;
