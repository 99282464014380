import { ReactElement } from "react";
import { Form } from "react-final-form";
import { Redirect } from "react-router-dom";
import { BehaviorSubject, Subject } from "rxjs";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer,
  Fixed
} from "../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { rvMap } from "../../../../utils/responsiveUtils";
import { useAuth } from "../../hooks/useAuth";
import { BannerSize, getPadding } from "../03_UI_Kit/Forms/Banners/Banner";
import ProgressBar from "../03_UI_Kit/Forms/ProgressBar";
import HelperText from "./HelperText";
import { Pages } from "./Pages/Page";
import SignupFlowFooter from "./SignupFlowFooter";
import SignupFlowSwitch from "./SignupFlowSwitch";

export type NavigationRequest = "continue" | "back";

export interface INavState {
  current: number;
  total: number;
  page: Pages;
}

export interface BaseSignupFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  name: string;
  tos: boolean;
}

interface ISignupFlowProps<FormValues> {
  onSubmit: (values: FormValues) => void;
  pages: Pages[];
}

function SignupFlow<FormValues>(
  props: ISignupFlowProps<FormValues>
): ReactElement {
  if (useAuth().authUser) {
    return <Redirect to={"/"} />;
  }

  const navStateSubject = new BehaviorSubject<INavState>({
    current: 0,
    total: 1,
    page: props.pages[0]
  });
  const navRequestSubject = new Subject<NavigationRequest>();

  const responsiveBannerSize: ResponsiveValue<BannerSize> = [
    "small",
    "small",
    "large",
    "large"
  ];

  const validatePasswordMatch = (values: BaseSignupFormValues & FormValues) => {
    if (values.password !== values.passwordConfirm) {
      return {
        passwordConfirm: "Passwords do not match"
      };
    }

    return undefined;
  };

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ width: "100%", justifyContent: "center" }}
    >
      <FixedGrid>
        <Form<FormValues>
          onSubmit={props.onSubmit}
          validate={validatePasswordMatch}
        >
          {formProps => {
            if (formProps.submitSucceeded) {
              return <Redirect to={"/"} />;
            }

            const { current, total } = navStateSubject.value;
            const percent = ((current + 1) / total) * 100;

            return (
              <GridItem
                sx={{ flexDirection: "column" }}
                gridColumn={[
                  "1 / span 4",
                  "1 / span 8",
                  "1 / span 8",
                  "3 / span 8"
                ]}
                mt={[32, 32, 64, 64]}
              >
                <AutoLayout
                  spacing={4}
                  dependentProps={{
                    direction: "vertical",
                    alignment: "center"
                  }}
                  resizingX={FillContainer()}
                  p={rvMap(responsiveBannerSize, getPadding)}
                  bg={"white100"}
                >
                  <Text variant={"bodyLarge"} color={"black100"}>
                    Welcome to indify
                  </Text>
                </AutoLayout>
                <ProgressBar percent={percent} />
                <AutoLayout
                  spacing={48}
                  resizingX={Fixed("auto")}
                  dependentProps={{
                    direction: "vertical"
                  }}
                  bg={"white100"}
                  p={["16px", "32px", "88px", "88px"]}
                  sx={{
                    border: "1px solid",
                    borderColor: "midGray70"
                  }}
                >
                  <SignupFlowSwitch
                    navState={navStateSubject}
                    navRequests={navRequestSubject}
                    pages={props.pages}
                  />
                  {formProps.submitError && (
                    <Text variant={"bodyMedium"} color={"alert100"}>
                      {formProps.submitError}
                    </Text>
                  )}
                  <SignupFlowFooter<FormValues>
                    formRenderProps={formProps}
                    navState={navStateSubject}
                    navRequests={navRequestSubject}
                  />
                </AutoLayout>
                <HelperText />
              </GridItem>
            );
          }}
        </Form>
      </FixedGrid>
    </GridItem>
  );
}

export default SignupFlow;
