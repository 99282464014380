/**
 * @generated SignedSource<<5e01bd4c46d4e5fcdea31c3dcbce9c8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DealListItem_featureddeal$data = {
  readonly id: string;
  readonly totalAmountRaising: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseIconsNames_query">;
  readonly " $fragmentType": "DealListItem_featureddeal";
};
export type DealListItem_featureddeal$key = {
  readonly " $data"?: DealListItem_featureddeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealListItem_featureddeal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealListItem_featureddeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmountRaising",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseIconsNames_query"
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};

(node as any).hash = "7323ee35fc5c34106f4e51c74053952b";

export default node;
