import { ChatPageChatsQuery as ChatPageChatsQueryType } from "__generated__/ChatPageChatsQuery.graphql";
import { ChatThreadQuery as ChatThreadQueryType } from "__generated__/ChatThreadQuery.graphql";
import { Fragment, ReactElement, Suspense } from "react";
import { graphql, PreloadedQuery } from "react-relay";
import { useRouteMatch } from "react-router-dom";
import { Flex } from "theme-ui";

import { Loader } from "../../../../components/01_Core/999_Miscellaneous/Loader";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { navigationHeight } from "../../../../theme/theme";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import ChatThread from "../03_UI_Kit/Messaging/ChatThread";
import Inbox from "./Inbox";

export interface IChatPageProps {
  myChatsQueryRef: PreloadedQuery<ChatPageChatsQueryType>;
  chatQueryRef: PreloadedQuery<ChatThreadQueryType>;
}

function ChatPage(props: IChatPageProps): ReactElement {
  const match = useRouteMatch<{ chatId: string }>(InternalRoutes.inbox);
  const selectedChatId =
    match?.params?.chatId != null
      ? decodeURIComponent(match.params.chatId)
      : null;

  const Fallback = (
    <Flex
      py={"16px"}
      px={"24px"}
      sx={{
        size: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
      }}
    >
      <Loader />
    </Flex>
  );

  return (
    <Fragment>
      <GridItem
        display={[
          selectedChatId == null ? "flex" : "none",
          "flex",
          "flex",
          "flex"
        ]}
        gridColumn={["1 / -1", "1 / span 3", "1 / span 3", "1 / span 3"]}
        sx={{
          flexDirection: "column",
          marginRight: ["0", "-16px", "-24px", "-24px"], // Compensate for gridColumnGap so items stay centered
          height: navigationHeight.map(h => `calc(100vh - ${h}px)`)
        }}
      >
        <Suspense fallback={Fallback}>
          <Inbox
            myChatsQueryRef={props.myChatsQueryRef}
            selectedChatId={selectedChatId}
            size={["small", "small", "small", "medium"]}
          />
        </Suspense>
      </GridItem>
      <GridItem
        display={[
          selectedChatId == null ? "none" : "flex",
          "flex",
          "flex",
          "flex"
        ]}
        gridColumn={["1 / -1", "4 / -1", "4 / -1", "4 / -1"]}
        bg={"white100"}
        sx={{
          height: navigationHeight.map(h => `calc(100vh - ${h}px)`),
          overflowY: "hidden"
        }}
      >
        {selectedChatId && (
          <Suspense fallback={Fallback}>
            <ChatThread queryRef={props.chatQueryRef} />
          </Suspense>
        )}
      </GridItem>
    </Fragment>
  );
}

export const ChatPageChatsQuery = graphql`
  query ChatPageChatsQuery {
    ...Inbox_chats
  }
`;

export default ChatPage;
