import {
  ReleaseInfoCardView_release$data,
  ReleaseInfoCardView_release$key
} from "__generated__/ReleaseInfoCardView_release.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Image, Link, Text } from "theme-ui";

import EmptyReleaseImage from "../../../../imgs/empty_release.png";
import Ic20PlayCircle from "../../../../imgs/icons/ic20-play-circle.svg";
import {
  useActiveResponsiveValue,
  useFindActiveRV
} from "../../../utils/responsiveUtils";
import AutoLayout, { FillContainer } from "../AutoLayout";
import { IconBox } from "../Foundations/Icons";
import { useReleaseMetadataParts } from "./useReleaseMetadata";

type ReleaseInfoCardViewSize =
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";

interface IReleaseInfoCardViewProps {
  size: ResponsiveValue<ReleaseInfoCardViewSize>;
  release: ReleaseInfoCardView_release$key;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2388%3A12358
 */
function ReleaseInfoCardView(props: IReleaseInfoCardViewProps): ReactElement {
  const release = useFragment(
    graphql`
      fragment ReleaseInfoCardView_release on ReleaseInterface {
        thumbnailUrl
        soundLink
        name
        ...useReleaseMetadata_release
      }
    `,
    props.release
  );

  return (
    <AutoLayout
      spacing={16}
      dependentProps={{
        direction: "vertical"
      }}
      sx={{ width: "100%" }}
    >
      <Link
        href={release.soundLink}
        target={"_blank"}
        sx={{
          display: "inline",
          textDecoration: "none",
          color: "black100",
          ":hover": release.soundLink
            ? { textDecoration: "underline" }
            : undefined,
          width: "100%"
        }}
      >
        <Image
          src={release.thumbnailUrl ?? EmptyReleaseImage}
          sx={{
            objectFit: "cover",
            borderRadius: "8px",
            width: "100%"
          }}
        />
        <Details release={release} size={props.size} />
      </Link>
    </AutoLayout>
  );
}

function Details(props: {
  release: ReleaseInfoCardView_release$data;
  size: ResponsiveValue<ReleaseInfoCardViewSize>;
}) {
  const activeSize = useActiveResponsiveValue<ReleaseInfoCardViewSize>(
    props.size
  );
  const isCompact = ["extraSmall", "small"].includes(activeSize);
  let releaseMetadataParts = useReleaseMetadataParts(props.release);
  if (releaseMetadataParts.length > 2) {
    // Join all but the last part
    releaseMetadataParts = [
      releaseMetadataParts.slice(0, -1).join(" - "),
      releaseMetadataParts[releaseMetadataParts.length - 1]
    ];
  }

  return (
    <AutoLayout
      spacing={4}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical"
      }}
    >
      <Title release={props.release} size={props.size} />
      <Text variant={"bodySmall"} color={"deepGray100"}>
        <AutoLayout
          spacing={4}
          dependentProps={{ direction: "vertical" }}
          sx={{ width: "100%" }}
        >
          {isCompact
            ? releaseMetadataParts.map((part, index) => (
                <Text key={index}>
                  {part}
                  <br />
                </Text>
              ))
            : releaseMetadataParts.join(" - ")}
        </AutoLayout>
      </Text>
    </AutoLayout>
  );
}

function Title(props: {
  release: ReleaseInfoCardView_release$data;
  size: ResponsiveValue<ReleaseInfoCardViewSize>;
}) {
  const titleType = useFindActiveRV(props.size, getTitleType);

  return (
    <Text variant={titleType} sx={{ width: "100%" }}>
      <AutoLayout
        spacing={4}
        dependentProps={{
          direction: "horizontal",
          alignment: "left"
        }}
        sx={{
          width: "100%"
        }}
      >
        <IconBox
          sx={{
            flexShrink: 0,
            size: "1.1em",
            color: props.release.soundLink ? undefined : "midGray70"
          }}
          icon={Ic20PlayCircle}
        />
        <Text
          color={"black100"}
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden"
          }}
        >
          {props.release.name}
        </Text>
      </AutoLayout>
    </Text>
  );
}

function getTitleType(size: ReleaseInfoCardViewSize) {
  switch (size) {
    case "extraSmall":
    case "small":
      return "h200";
    case "medium":
    case "large":
    case "extraLarge":
      return "h300";
  }
}

export default ReleaseInfoCardView;
