import { ProfilePlatform } from "__generated__/SocialLinks_profiles.graphql";

import Ic16Placeholder from "../../imgs/icons/ic16-placeholder.svg";
import Ic16Soundcloud from "../../imgs/icons/ic16-social-soundcloud.svg";
import Ic16Spotify from "../../imgs/icons/ic16-social-spotify.svg";
import Ic16TikTok from "../../imgs/icons/ic16-social-tiktok.svg";
import Ic16Twitter from "../../imgs/icons/ic16-social-twitter.svg";
import Ic16YouTube from "../../imgs/icons/ic16-social-youtube.svg";
import Ic20Facebook from "../../imgs/icons/ic20-facebook.svg";
import Ic20Instagram from "../../imgs/icons/ic20-instagram.svg";
import Ic20Placeholder from "../../imgs/icons/ic20-placeholder.svg";
import Ic24Facebook from "../../imgs/icons/ic24-facebook.svg";
import Ic24Instagram from "../../imgs/icons/ic24-instagram.svg";
import Ic24Placeholder from "../../imgs/icons/ic24-placeholder.svg";
import Ic24Soundcloud from "../../imgs/icons/ic24-social-soundcloud.svg";
import Ic24Spotify from "../../imgs/icons/ic24-social-spotify.svg";
import Ic24TikTok from "../../imgs/icons/ic24-social-tiktok.svg";
import Ic24Twitter from "../../imgs/icons/ic24-social-twitter.svg";
import Ic24YouTube from "../../imgs/icons/ic24-social-youtube.svg";
import Ic40Soundcloud from "../../imgs/icons/ic40-social-soundcloud.svg";
import Ic40Spotify from "../../imgs/icons/ic40-social-spotify.svg";
import Ic40TikTok from "../../imgs/icons/ic40-social-tiktok.svg";
import Ic40Twitter from "../../imgs/icons/ic40-social-twitter.svg";
import Ic40YouTube from "../../imgs/icons/ic40-social-youtube.svg";

export function getPlatformIcons(
  platform?: ProfilePlatform
): [SvgrComponent, number][] {
  switch (platform) {
    case "SPOTIFY":
      return [
        [Ic16Spotify, 16],
        [Ic24Spotify, 24],
        [Ic40Spotify, 40]
      ];
    case "TIKTOK":
      return [
        [Ic16TikTok, 16],
        [Ic24TikTok, 24],
        [Ic40TikTok, 40]
      ];
    case "TWITTER":
      return [
        [Ic16Twitter, 16],
        [Ic24Twitter, 24],
        [Ic40Twitter, 40]
      ];
    case "INSTAGRAM":
      return [
        [Ic20Instagram, 20],
        [Ic24Instagram, 24],
        [Ic24Instagram, 24]
      ];
    case "YOUTUBE":
      return [
        [Ic16YouTube, 20],
        [Ic24YouTube, 24],
        [Ic40YouTube, 24]
      ];
    case "SOUNDCLOUD":
      return [
        [Ic16Soundcloud, 20],
        [Ic24Soundcloud, 24],
        [Ic40Soundcloud, 24]
      ];
    case "FACEBOOK":
      return [
        [Ic20Facebook, 20],
        [Ic24Facebook, 24],
        [Ic24Facebook, 24]
      ];
    default:
      return [
        [Ic16Placeholder, 16],
        [Ic20Placeholder, 20],
        [Ic24Placeholder, 24]
      ];
  }
}
