export const ExternalRoutes = {
  payoutDistributionTerms: "https://legal.indify.io/payout-terms",
  privacy: "https://legal.indify.io/privacy",
  terms: "https://legal.indify.io/terms",
  dealGuide:
    "https://indify.notion.site/indify/Building-a-Proposed-Deal-d03366f37caa409a9363046fc7709f04",
  standardDealTerms:
    "https://indify.notion.site/indify/Standard-Deal-Terms-481f908258c240e784d547a241905caf",
  help: "https://help.indify.io",
  curveLogin: "https://app.curveroyaltysystems.com/login",
  i4aFeedback:
    "https://docs.google.com/forms/d/e/1FAIpQLSfo3v_bui9TtgWROx5kw2S3CKozWa7iaxT2hKtBzULLb_OYUw/viewform"
} as const;
