/**
 * @generated SignedSource<<87821b98720f435e11437f9c7371804e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TikTokSoundsIncludedQuery$variables = {
  id: string;
};
export type TikTokSoundsIncludedQuery$data = {
  readonly artist: {
    readonly tiktokProfile: {
      readonly latestSeenPostAt: any | null;
      readonly tiktokSounds: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly mostRecentStat: {
              readonly current: number;
            } | null;
            readonly name: string | null;
            readonly " $fragmentSpreads": FragmentRefs<"TikTokSoundsIncluded_tikTokSoundRemovable" | "TikTokSoundsIncluded_tikTokSoundSelectable">;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type TikTokSoundsIncludedQuery = {
  response: TikTokSoundsIncludedQuery$data;
  variables: TikTokSoundsIncludedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestSeenPostAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "StatWithDelta",
  "kind": "LinkedField",
  "name": "mostRecentStat",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "current",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TikTokSoundsIncludedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArtistNode",
        "kind": "LinkedField",
        "name": "artist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "tiktokProfile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TikTokSoundNodeConnection",
                "kind": "LinkedField",
                "name": "tiktokSounds",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TikTokSoundNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TikTokSoundNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TikTokSoundsIncluded_tikTokSoundRemovable"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TikTokSoundsIncluded_tikTokSoundSelectable"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TikTokSoundsIncludedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArtistNode",
        "kind": "LinkedField",
        "name": "artist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "tiktokProfile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TikTokSoundNodeConnection",
                "kind": "LinkedField",
                "name": "tiktokSounds",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TikTokSoundNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TikTokSoundNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstSeenAt",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5757ee5df18e86db1a60a9ba3f216413",
    "id": null,
    "metadata": {},
    "name": "TikTokSoundsIncludedQuery",
    "operationKind": "query",
    "text": "query TikTokSoundsIncludedQuery(\n  $id: ID!\n) {\n  artist(id: $id) {\n    tiktokProfile {\n      latestSeenPostAt\n      tiktokSounds {\n        edges {\n          node {\n            id\n            name\n            mostRecentStat {\n              current\n            }\n            ...TikTokSoundsIncluded_tikTokSoundRemovable\n            ...TikTokSoundsIncluded_tikTokSoundSelectable\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment TikTokSoundsIncluded_tikTokSoundDetails on TikTokSoundNode {\n  name\n  thumbnailUrl\n  firstSeenAt\n  mostRecentStat {\n    current\n  }\n}\n\nfragment TikTokSoundsIncluded_tikTokSoundRemovable on TikTokSoundNode {\n  thumbnailUrl\n  ...TikTokSoundsIncluded_tikTokSoundDetails\n}\n\nfragment TikTokSoundsIncluded_tikTokSoundSelectable on TikTokSoundNode {\n  thumbnailUrl\n  ...TikTokSoundsIncluded_tikTokSoundDetails\n}\n"
  }
};
})();

(node as any).hash = "cb27a6ac99301378ad66b09274d2691d";

export default node;
