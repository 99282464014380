import { ReactElement } from "react";

import { GridItem } from "../../../../../../components/01_Core/Grids/GridItem";
import { zIndices } from "../../../../../../theme/theme";
import SimpleNav from "../SimpleNav";

/**
 * Wrapper around SimpleNav with full width grid
 */
export const LoggedOutNavBar = (): ReactElement => (
  <GridItem
    gridColumn={"1 / -1"}
    px={[17, 16, 16, 24]}
    bg={"white100"}
    sx={{
      position: "sticky",
      zIndex: zIndices.nav,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      top: 0,
      height: "56px",
      width: "100%"
    }}
  >
    <SimpleNav />
  </GridItem>
);
