/**
 * @generated SignedSource<<f32ec4e23c292501072257ef992ffd08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferralRecipientInvited_referral$data = {
  readonly __typename: string;
  readonly createdAt: any;
  readonly id: string;
  readonly recipientEmail: string;
  readonly " $fragmentType": "ReferralRecipientInvited_referral";
};
export type ReferralRecipientInvited_referral$key = {
  readonly " $data"?: ReferralRecipientInvited_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralRecipientInvited_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralRecipientInvited_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};

(node as any).hash = "be398fe4358655fad97ecbc6bbcbdd56";

export default node;
