import { BannerSwitch_banner$key } from "__generated__/BannerSwitch_banner.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";

import { BannerSize } from "./Banner";
import InvitedToCompanyTeam from "./InvitedToCompanyTeam";
import {
  NonReferralAcceptanceArtist,
  NonReferralAcceptancePartner
} from "./NonReferralAcceptance";
import ReferralAcceptance from "./ReferralAcceptance";

interface IBannerSwitchProps {
  referral: BannerSwitch_banner$key;
}

function BannerSwitch(props: IBannerSwitchProps): ReactElement {
  const responsiveBannerSize: ResponsiveValue<BannerSize> = [
    "small",
    "small",
    "large",
    "large"
  ];
  const referral = useFragment(
    graphql`
      fragment BannerSwitch_banner on ReferralInterface {
        __typename
        ... on IndifyToPartnerReferralNode {
          company {
            id
          }
        }
        ...InvitedToCompanyTeam_referral
        ...NonReferralAcceptanceArtist_referral
        ...NonReferralAcceptancePartner_referral
        ...ReferralAcceptance_referral
      }
    `,
    props.referral
  );

  switch (referral.__typename) {
    case "IndifyToArtistReferralNode":
      return (
        <NonReferralAcceptanceArtist
          size={responsiveBannerSize}
          referral={referral}
        />
      );
    case "IndifyToPartnerReferralNode":
      return referral.company?.id ? (
        <InvitedToCompanyTeam size={responsiveBannerSize} referral={referral} />
      ) : (
        <NonReferralAcceptancePartner
          size={responsiveBannerSize}
          referral={referral}
        />
      );
    case "PartnerToCompanyPartnerReferralNode":
      return (
        <InvitedToCompanyTeam size={responsiveBannerSize} referral={referral} />
      );
    case "PartnerToPartnerReferralNode":
    case "PartnerToArtistReferralNode":
      return (
        <ReferralAcceptance size={responsiveBannerSize} referral={referral} />
      );
    default:
      return <div>TODO: Implement flow</div>;
  }
}

export default BannerSwitch;
