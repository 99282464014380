import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store
} from "relay-runtime";

export function getGraphqlUrl(): string {
  return `${window.location.protocol}//${window.location.host}/api/graphql`;
}

const fetchQuery: FetchFunction = (request, variables) => {
  return fetch(getGraphqlUrl(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: request.text,
      variables
    })
  })
    .then(response => response.json())
    .then(json => {
      for (const error of json.errors ?? []) {
        const { message, ...rest } = error;
        console.error("GraphQL response error", message, rest);
      }
      return json;
    });
};

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource())
});

export default environment;
