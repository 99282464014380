/**
 * @generated SignedSource<<49c85b13d26803c694a0a9c212d847ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartnerViewRoster_artistRoster$data = {
  readonly offers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"TeamList_offers">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "PartnerViewRoster_artistRoster";
};
export type PartnerViewRoster_artistRoster$key = {
  readonly " $data"?: PartnerViewRoster_artistRoster$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartnerViewRoster_artistRoster">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartnerViewRoster_artistRoster",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isAccepted",
          "value": true
        }
      ],
      "concreteType": "OfferNodeConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OfferNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TeamList_offers"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "offers(isAccepted:true)"
    }
  ],
  "type": "PartnerNode",
  "abstractKey": null
};

(node as any).hash = "66fcbee7be502f463d806d740d0082cd";

export default node;
