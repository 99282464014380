/**
 * @generated SignedSource<<2a7b976d24e264abee682a8a4a6b1b67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseCard_release$data = {
  readonly __typename: string;
  readonly artist: {
    readonly " $fragmentSpreads": FragmentRefs<"useMessage_actor">;
  } | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseInfoCardView_release">;
  readonly " $fragmentType": "ReleaseCard_release";
};
export type ReleaseCard_release$key = {
  readonly " $data"?: ReleaseCard_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseCard_release">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleaseCard_release",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useMessage_actor"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseInfoCardView_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "029852b6237dabddf954b01eea8990d8";

export default node;
