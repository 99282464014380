/**
 * @generated SignedSource<<421c6b8db6af533f2adcec2f485bd5af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmAccountInfo_referral$data = {
  readonly __typename: string;
  readonly recipientDisplayName?: string;
  readonly recipientEmail: string;
  readonly " $fragmentSpreads": FragmentRefs<"SectionCompany_referral">;
  readonly " $fragmentType": "ConfirmAccountInfo_referral";
};
export type ConfirmAccountInfo_referral$key = {
  readonly " $data"?: ConfirmAccountInfo_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmAccountInfo_referral">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recipientDisplayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmAccountInfo_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientEmail",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SectionCompany_referral"
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "IndifyToArtistReferralNode",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "IndifyToPartnerReferralNode",
      "abstractKey": null
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};
})();

(node as any).hash = "c517364e396d5b1a8930f1f2ea6eb995";

export default node;
