/**
 * @generated SignedSource<<b3502a75bf3ac87a81149638ba8642fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProfilePlatform = "FACEBOOK" | "INSTAGRAM" | "LUMINATE" | "SOUNDCLOUD" | "SPOTIFY" | "TIKTOK" | "TWITTER" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type SocialLinksIcons_profiles$data = ReadonlyArray<{
  readonly id: string;
  readonly platform: ProfilePlatform;
  readonly url: string | null;
  readonly " $fragmentType": "SocialLinksIcons_profiles";
}>;
export type SocialLinksIcons_profiles$key = ReadonlyArray<{
  readonly " $data"?: SocialLinksIcons_profiles$data;
  readonly " $fragmentSpreads": FragmentRefs<"SocialLinksIcons_profiles">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SocialLinksIcons_profiles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    }
  ],
  "type": "ProfileNode",
  "abstractKey": null
};

(node as any).hash = "59a3dbc0cdb9cd2feb892a5b604099a6";

export default node;
