import { ReactElement } from "react";
import { Text } from "theme-ui";

type OfferMessageHeadingSize = "small" | "medium";
interface IOfferMessageHeadingProps {
  size: OfferMessageHeadingSize;
}

function getVariant(size: OfferMessageHeadingSize) {
  switch (size) {
    case "medium":
      return "primary.h400";
    case "small":
      return "primary.h300";
  }
}

function getPy(size: OfferMessageHeadingSize) {
  switch (size) {
    case "medium":
      return "16px";
    case "small":
      return "12px";
  }
}

function OfferMessageHeading(props: IOfferMessageHeadingProps): ReactElement {
  return (
    <Text
      variant={getVariant(props.size)}
      py={getPy(props.size)}
      sx={{ textAlign: "center", width: "100%" }}
    >
      Funding Offer
    </Text>
  );
}

export default OfferMessageHeading;
