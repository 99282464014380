import { ArtistThumbnail_featuredDeal$key } from "__generated__/ArtistThumbnail_featuredDeal.graphql";
import { compact } from "lodash";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Flex } from "theme-ui";

import DisplayAvatar from "../../../../../components/01_Core/Users/DisplayAvatar";
import Indiglyph from "../../../../../components/02_Brand_Assets/Indiglyph";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import useArtistThumbnailUrl from "../../../hooks/useArtistThumbnailUrl";
import useImageSampleColor from "../../../hooks/useImageSampleColor";

type ArtistThumbnailLayout = "horizontal" | "vertical";

interface IArtistThumbnailProps {
  featuredDeal: ArtistThumbnail_featuredDeal$key;
  layout: ResponsiveValue<ArtistThumbnailLayout>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
function ArtistThumbnail(props: IArtistThumbnailProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment ArtistThumbnail_featuredDeal on FeaturedDealNode {
        artist {
          thumbnailUrl
          ...useArtistThumbnailUrl_artist
        }
        releases {
          edges {
            node {
              thumbnailUrl
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              thumbnailUrl
            }
          }
        }
      }
    `,
    props.featuredDeal
  );
  const artistSampleColor = useImageSampleColor(
    featuredDeal.artist.thumbnailUrl
  );
  const activeLayout = useActiveResponsiveValue(props.layout);
  const allReleaseImages = compact(
    [].concat(
      featuredDeal.releases.edges.map(r => r.node.thumbnailUrl),
      featuredDeal.unreleasedReleases.edges.map(r => r.node.thumbnailUrl)
    )
  );
  const hasReleaseImage = allReleaseImages.length > 0;

  return (
    <Flex
      sx={{
        flexDirection: activeLayout === "horizontal" ? "row" : "column",
        flexShrink: 0,
        minWidth: "min-content"
      }}
    >
      {!hasReleaseImage && (
        <Indiglyph
          type={"01_spotlight"}
          color={"black"}
          sxOverrides={{ size: "148px" }}
        />
      )}
      {
        <DisplayAvatar
          imageUrl={useArtistThumbnailUrl(featuredDeal.artist)}
          size={"small"}
          type={"sampled"}
          sampleColor={artistSampleColor}
        />
      }
      {hasReleaseImage && (
        <Indiglyph
          type={"07_earn"}
          color={"black"}
          sxOverrides={{ size: "148px" }}
        />
      )}
    </Flex>
  );
}

export default ArtistThumbnail;
