import { SectionCompanyKnown_referral$key } from "__generated__/SectionCompanyKnown_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import Ic20Lock from "../../../../../../../imgs/icons/ic20-lock.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import {
  getLabelVariant,
  InputSize
} from "../../../../../../components/01_Core/Forms/Input";
import AvatarName from "../../../../../../components/01_Core/Users/AvatarName";
import { useFindActiveRV } from "../../../../../../utils/responsiveUtils";

interface ISectionCompanyKnownProps {
  referral: SectionCompanyKnown_referral$key;
}

function SectionCompanyKnown(props: ISectionCompanyKnownProps): ReactElement {
  const referral = useFragment(
    graphql`
      fragment SectionCompanyKnown_referral on OptionalCompanyInterface {
        company {
          displayName
          thumbnailUrl
        }
      }
    `,
    props.referral
  );

  const responsiveInputSize: ResponsiveValue<InputSize> = [
    "medium",
    "medium",
    "large",
    "large"
  ];
  const labelVariant = useFindActiveRV(responsiveInputSize, getLabelVariant);

  return (
    <AutoLayout
      spacing={8}
      resizingX={FillContainer()}
      dependentProps={{
        direction: "vertical"
      }}
    >
      <Text variant={labelVariant} color={"black100"}>
        Company name
      </Text>
      <AutoLayout
        spacing={16}
        resizingX={FillContainer()}
        dependentProps={{
          direction: "horizontal",
          distribution: "spaceBetween",
          alignment: "center"
        }}
        sx={{
          backgroundColor: "lightGray100",
          borderRadius: "5000px",
          padding: "8px 16px 8px 8px"
        }}
      >
        <AvatarName
          size={"small"}
          imageUrl={referral.company.thumbnailUrl}
          displayName={referral.company.displayName}
        />
        <Ic20Lock color={"deepGray70"} />
      </AutoLayout>
      <Text variant={"bodyMedium"} color={"deepGray100"}>
        This is the company you have been invited to join.
      </Text>
    </AutoLayout>
  );
}

export default SectionCompanyKnown;
