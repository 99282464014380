import { OfferForm_artist$key } from "__generated__/OfferForm_artist.graphql";
import { OfferForm_partner$key } from "__generated__/OfferForm_partner.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { useLocation } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import Wizard, {
  IWizardPageLayoutProps,
  IWizardProps,
  WizardPage
} from "../../../../../components/01_Core/Forms/Wizard";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import {
  IFundingOfferFormFieldProps,
  IFundingOfferFormValues
} from "../../07_Deal_Flow/OfferFormFunding";
import { IActionMakeOfferLocationState } from "../Actions/ActionMakeOffer";
import SimpleNav from "../Navigation/SimpleNav";
import OfferFormAdvance from "./OfferFormAdvance/OfferFormAdvance";
import OfferFormFooter from "./OfferFormFooter";
import OfferFormHeader from "./OfferFormHeader";
import OfferFormSignature from "./OfferFormSignature";
import OfferFormSplits from "./OfferFormSplits/OfferFormSplits";
import OfferFormTermLength from "./OfferFormTermLength";
import OfferFormReleasesIncluded from "./ReleasesIncluded/OfferFormReleasesIncluded";

export type OfferFormSize = "small" | "medium";

export interface IOfferFormProps {
  initialValues?: Partial<IFundingOfferFormValues>;
  sendOffer: (formValues: IFundingOfferFormValues) => void;
  artist: OfferForm_artist$key;
  partner: OfferForm_partner$key;
  closeModal: () => void;
}

const responsiveFormSize: ResponsiveValue<OfferFormSize> = [
  "small",
  "medium",
  "medium",
  "medium"
];

/**
 * figma:
 * - https://www.figma.com/file/DwRAHDiXrsacj54PzBmIv1/06-Deal-Flow?node-id=1343%3A69715
 * - https://www.figma.com/file/DwRAHDiXrsacj54PzBmIv1/06-Deal-Flow?node-id=1343%3A69677
 * - https://www.figma.com/file/DwRAHDiXrsacj54PzBmIv1/06-Deal-Flow?node-id=1343%3A69663
 */
function OfferForm(props: IOfferFormProps): ReactElement {
  const location = useLocation<IActionMakeOfferLocationState>();
  const initialValuesParams = location.state?.initialValues;
  const artist = useFragment(
    graphql`
      fragment OfferForm_artist on ArtistNode {
        id
        displayName
        ...OfferFormHeader_artist
      }
    `,
    props.artist
  );
  const partner = useFragment(
    graphql`
      fragment OfferForm_partner on PartnerNode {
        id
        company {
          id
        }
        ...OfferFormHeader_partner
      }
    `,
    props.partner
  );

  const initialValuesBase: Partial<IFundingOfferFormValues> = {};
  const initialValues = {
    ...initialValuesBase
  };
  const FooterDescription = () => {
    return (
      <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
        <Text variant={"bodySmall"} color={"deepGray100"}>
          {artist.displayName} will be able to accept or decline your offer.
        </Text>
        <Text variant={"bodySmall"} color={"deepGray100"}>
          If accepted, the agreement will become active based on the terms.
        </Text>
        <br />
        <Text variant={"bodySmall"} color={"deepGray100"}>
          You can cancel an offer anytime before the artist has responded to it.
        </Text>
      </Flex>
    );
  };

  const getFieldProps = (
    pageLayoutProps: Pick<IWizardPageLayoutProps, "hasAttemptedSubmit">
  ) => {
    const { hasAttemptedSubmit } = pageLayoutProps;
    return {
      size: responsiveFormSize,
      showAllErrors: hasAttemptedSubmit
    } as IFundingOfferFormFieldProps;
  };

  const pageLayout: IWizardProps<IFundingOfferFormValues>["pageLayout"] =
    pageLayoutProps => {
      const { activePage } = pageLayoutProps;
      const fieldProps = getFieldProps(pageLayoutProps);
      return (
        <Flex
          sx={{
            flexDirection: "column",
            height: "100%"
          }}
        >
          <SimpleNav />
          <Flex
            sx={{
              flexDirection: "column",
              overflowY: "auto",
              alignItems: "center",
              height: "100%",
              width: "100%"
            }}
          >
            <OfferFormHeader
              {...fieldProps}
              artist={artist}
              partner={partner}
              closeModal={props.closeModal}
            />
            <FixedGrid>
              <GridItem
                sx={{
                  flexDirection: "column",
                  gridColumn: [
                    "1 / span 4",
                    "2 / span 6",
                    "2 / span 6",
                    "4 / span 6"
                  ]
                }}
              >
                {activePage}
              </GridItem>
            </FixedGrid>
            <OfferFormFooter
              {...pageLayoutProps}
              submitButtonLabel={`Send offer to ${artist.displayName}`}
              description={<FooterDescription />}
            />
          </Flex>
        </Flex>
      );
    };

  const pages: IWizardProps<IFundingOfferFormValues>["pages"] = ({
    hasAttemptedSubmit
  }) => {
    const fieldProps = getFieldProps({ hasAttemptedSubmit });
    return [
      <WizardPage key="releases">
        <OfferFormReleasesIncluded
          {...fieldProps}
          artist={artist}
          showCopartnerWarnings={true}
        />
      </WizardPage>,
      <WizardPage key="terms">
        <OfferFormAdvance
          {...fieldProps}
          heading={"Advance"}
          includeTransferControls={true}
          artistName={artist.displayName}
          placeholder={"0"}
          showCopartnerWarnings={true}
        />
        <Divider />
        <OfferFormSplits
          {...fieldProps}
          povType={"asPartner"}
          isRequired={true}
          showCopartnerWarnings={true}
        />
        <Divider />
        <OfferFormTermLength {...fieldProps} isRequired={true} />
      </WizardPage>,
      <WizardPage key="signature">
        <OfferFormSignature {...fieldProps} />
      </WizardPage>
    ];
  };

  return (
    <Wizard<IFundingOfferFormValues>
      initialValues={{
        service: "funding",
        artistId: artist.id,
        partnerId: partner.id,
        companyId: partner.company.id,
        allReleases: [],
        willTransferCreativeAdvance: true,
        willTransferMarketingAdvance: true,
        ...initialValuesParams
      }}
      onSubmit={props.sendOffer}
      pageLayout={pageLayout}
      pages={pages}
    />
  );
}

export default OfferForm;
