import { Page_referral$key } from "__generated__/Page_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import AcceptBasicTerms from "./AcceptBasicTerms";
import AcceptTerms from "./AcceptTerms";
import ArtistDetails from "./ArtistDetails";
import ArtistNameSocialLinks from "./ArtistNameSocialLinks";
import ArtistSocialLinks from "./ArtistSocialLinks";
import ConfirmAccountInfo from "./ConfirmAccountInfo";
import LoginInfo from "./LoginInfo";
import PartnerAddCompanyInfo from "./PartnerAddCompanyInfo";
import PartnerNameCompanyName from "./PartnerNameCompanyName";

interface IPageProps {
  page: Pages;
  referral?: Page_referral$key;
}

export const ConfirmAccountInfoPage = "confirmAccountInfo";
export const LoginInfoPage = "loginInfo";

export const ArtistSocialLinksPage = "artistSocialLinks";
export const ArtistDetailsPage = "artistDetails";
export const ArtistNameSocialLinksPage = "artistNameSocialLinks";

export const ArtistAcceptTermsPage = "artistAcceptTerms";

export const PartnerAddCompanyInfoPage = "partnerAddCompanyInfo";
export const PartnerNameCompanyNamePage = "partnerNameCompanyName";
export const PartnerAcceptTermsPage = "partnerAcceptTerms";

export const AcceptBasicTermsPage = "artistAcceptBasicTerms";

export type Pages =
  | typeof ConfirmAccountInfoPage
  | typeof LoginInfoPage
  | typeof ArtistSocialLinksPage
  | typeof ArtistDetailsPage
  | typeof ArtistNameSocialLinksPage
  | typeof ArtistAcceptTermsPage
  | typeof PartnerAddCompanyInfoPage
  | typeof PartnerNameCompanyNamePage
  | typeof PartnerAcceptTermsPage
  | typeof AcceptBasicTermsPage;

export const skippablePages: { [P in Pages]?: boolean } = {
  [ArtistDetailsPage]: true
};

function Page(props: IPageProps): ReactElement {
  const referral = useFragment(
    graphql`
      fragment Page_referral on ReferralInterface {
        ...ConfirmAccountInfo_referral
        ...ArtistDetails_referral
        ...PartnerAddCompanyInfo_referral
      }
    `,
    props.referral
  );

  switch (props.page) {
    case ConfirmAccountInfoPage:
      return <ConfirmAccountInfo referral={referral} />;
    case LoginInfoPage:
      return <LoginInfo />;
    case ArtistSocialLinksPage:
      return <ArtistSocialLinks />;
    case ArtistDetailsPage:
      return <ArtistDetails referral={referral} />;
    case ArtistNameSocialLinksPage:
      return <ArtistNameSocialLinks />;
    case PartnerAddCompanyInfoPage:
      return <PartnerAddCompanyInfo referral={referral} />;
    case PartnerNameCompanyNamePage:
      return <PartnerNameCompanyName />;
    case ArtistAcceptTermsPage:
      return <AcceptTerms accountType={"artist"} />;
    case PartnerAcceptTermsPage:
      return <AcceptTerms accountType={"partner"} />;
    case AcceptBasicTermsPage:
      return <AcceptBasicTerms />;
    default:
      return <div>at {props.page}</div>;
  }
}

export default Page;
