import { ReleasesInfoRemovable_offerFeaturedDeal$key } from "__generated__/ReleasesInfoRemovable_offerFeaturedDeal.graphql";
import { ReleasesInfoRemovable_release$key } from "__generated__/ReleasesInfoRemovable_release.graphql";
import _ from "lodash";
import { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Link as ExternalLink, Text, useThemeUI } from "theme-ui";

import Ic16ChevronDown from "../../../../imgs/icons/ic16-chevron-down.svg";
import Ic20Remove from "../../../../imgs/icons/ic20-remove.svg";
import { actionIconSx } from "../../../pages/indify/components/03_UI_Kit/Discover/RowDeal";
import {
  EditReleaseModal,
  IEditReleaseFormValues
} from "../../../pages/indify/components/03_UI_Kit/Releases/UpdateOrCreateReleaseModal";
import { IFundingOfferFormValues } from "../../../pages/indify/components/07_Deal_Flow/OfferFormFunding";
import {
  rvMap,
  useActiveResponsiveValue,
  useFindActiveRV
} from "../../../utils/responsiveUtils";
import AutoLayout from "../AutoLayout";
import FieldBuilder, { FieldPrefix } from "../Forms/FieldBuilder";
import CheckboxInput, {
  getLabelTextColor,
  getLabelVariant
} from "../Forms/Inputs/CheckboxInput";
import { Field } from "../Forms/utils/reactFinalFormWrappers";
import { IconBox } from "../Foundations/Icons";
import Divider from "../Miscelleneous/Divider";
import ReleaseDetails, { ReleaseDetailsSize } from "./ReleaseDetails";
import ReleaseImageIcon, { ReleaseImageIconSize } from "./ReleaseImageIcon";

export type ReleaseInfoRemovableSize = "small" | "large";

interface IReleaseInfoRemovableProps {
  size: ResponsiveValue<ReleaseInfoRemovableSize>;
  // If `remove` is omitted, the release will not be removable
  remove?: () => void;
  // If `editRelease` is omitted, the release will not be editable
  onEditModalSubmit?: (values: IEditReleaseFormValues) => void;
  openEditModalOnRender?: boolean;
  // `release` is nullable so that users can add a new upcoming release (that is not yet in the DB) during the deal creation flow, in which case `newUnreleasedRelease` will be present
  release?: ReleasesInfoRemovable_release$key;
  newUnreleasedRelease?: IFundingOfferFormValues["allReleases"][number];
  offerFeaturedDeal?: ReleasesInfoRemovable_offerFeaturedDeal$key;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=2388%3A11175
 */
function ReleasesInfoRemovable(
  props: IReleaseInfoRemovableProps
): ReactElement {
  const release = useFragment(
    graphql`
      fragment ReleasesInfoRemovable_release on ReleaseInterface {
        id
        soundLink
        thumbnailUrl
        name
        ... on UnreleasedReleaseNode {
          __typename
        }
        ... on ReleaseNode {
          __typename
          tracks {
            edges {
              node {
                name
                trackNumber
                soundRecording {
                  id
                  isrc
                }
              }
            }
          }
        }
        ...ReleaseDetails_release
      }
    `,
    props.release
  );

  const canMakeChanges = !!props.remove;

  const isUnreleased =
    !!props.newUnreleasedRelease ||
    release.__typename === "UnreleasedReleaseNode";

  const [isEditModalOpen, setIsEditModalOpen] = useState(
    props.openEditModalOnRender
  );

  const { theme } = useThemeUI();

  const soundRecordingIds =
    props.offerFeaturedDeal &&
    useFragment(
      graphql`
        fragment ReleasesInfoRemovable_offerFeaturedDeal on OfferFeaturedDealInterface {
          soundRecordings {
            edges {
              node {
                id
              }
            }
          }
        }
      `,
      props.offerFeaturedDeal
    ).soundRecordings.edges.map(({ node }) => node.id);

  const tracks = isUnreleased
    ? null
    : release.tracks.edges.map(({ node: track }) => track);

  const soundRecordingIdIsIncluded = canMakeChanges
    ? useFormState<IFundingOfferFormValues>().values.soundRecordingIds
    : _.mapValues(
        _.keyBy(soundRecordingIds, id => id),
        () => true
      );

  const trackIsIncluded = (track: (typeof tracks)[number]) =>
    soundRecordingIdIsIncluded?.[track.soundRecording.id];

  const includedTracks = tracks?.filter(track => trackIsIncluded(track));

  if (canMakeChanges && release) {
    // Force re-validation of hidden field after included tracks change
    const form = useForm<IFundingOfferFormValues>();
    const soundRecordingIdsState = form.getState().values.soundRecordingIds;
    useEffect(() => {
      form.change("releaseHasIncludedTracks", {
        ...form.getState().values.releaseHasIncludedTracks,
        [release.id]: includedTracks?.length > 0
      });
    }, [soundRecordingIdsState]);
  }

  const anyTracksNotIncluded = includedTracks?.length !== tracks?.length;
  const [isTracksAccordionOpen, setIsTracksAccordionOpen] = useState(
    !canMakeChanges || anyTracksNotIncluded
  );

  const activeSize = useActiveResponsiveValue(props.size);
  const inputSize = activeSize === "small" ? "small" : "medium";

  const releaseInfo = (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "horizontal", alignment: "left" }}
      sx={{ width: "100%" }}
    >
      <ReleaseImageIcon
        src={release?.thumbnailUrl ?? null}
        size={useFindActiveRV(props.size, getReleaseImageIconSize)}
        sxOverrides={{
          border: isUnreleased ? "1px dashed" : "unset",
          borderColor: "midGray100"
        }}
      />
      <ReleaseDetails
        release={release}
        newUnreleasedRelease={props.newUnreleasedRelease}
        size={rvMap(props.size, getReleaseDetailsSize)}
      />
      {canMakeChanges && <RemoveButton {...props} remove={props.remove} />}
    </AutoLayout>
  );

  const trackListRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical" }}
        sx={removableStyles}
      >
        {canMakeChanges ? (
          releaseInfo
        ) : (
          <ExternalLink
            href={release.soundLink}
            target={"_blank"}
            sx={{
              display: "inline",
              textDecoration: "none",
              color: "black100",
              ":hover": release.soundLink
                ? { textDecoration: "underline" }
                : undefined,
              width: "100%"
            }}
          >
            {releaseInfo}
          </ExternalLink>
        )}
        {/* Track info */}
        {!isUnreleased && (
          <AutoLayout
            dependentProps={{ direction: "vertical" }}
            spacing={0}
            sx={{ width: "100%" }}
          >
            {/* Track list */}
            <Box
              ref={trackListRef}
              sx={{
                width: "100%",
                overflow: "hidden",
                maxHeight: isTracksAccordionOpen
                  ? trackListRef.current?.scrollHeight ?? "unset"
                  : "0",
                transition: "max-height 0.5s ease-out"
              }}
            >
              <AutoLayout
                spacing={16}
                dependentProps={{ direction: "vertical" }}
                sx={{ width: "100%", paddingBottom: "16px" }}
              >
                <Divider />
                <AutoLayout
                  spacing={8}
                  dependentProps={{ direction: "vertical" }}
                  sx={{ width: "100%", paddingX: "16px" }}
                >
                  {_.sortBy(tracks ?? [], t => t.trackNumber)
                    .filter(track => canMakeChanges || trackIsIncluded(track))
                    .map(track => {
                      const trackName = (
                        <Text>{`${track.trackNumber}. ${track.name}`}</Text>
                      );
                      return (
                        <Fragment key={track.soundRecording.id}>
                          {canMakeChanges ? (
                            <FieldPrefix prefix="soundRecordingIds">
                              <FieldBuilder
                                fieldName={track.soundRecording.id}
                                inputField={CheckboxInput}
                                inputFieldProps={{
                                  label: trackName,
                                  caption: (
                                    <Text>
                                      ISRC:{" "}
                                      <Text
                                        sx={{
                                          fontFamily: "monospace",
                                          fontSize: "0.8lh"
                                        }}
                                      >
                                        {track.soundRecording.isrc}
                                      </Text>
                                    </Text>
                                  ),
                                  size: inputSize
                                }}
                              />
                            </FieldPrefix>
                          ) : (
                            <Flex sx={{ flexDirection: "column" }}>
                              <Text
                                variant={getLabelVariant(inputSize)}
                                color={getLabelTextColor("normal")}
                              >
                                {trackName}
                              </Text>
                            </Flex>
                          )}
                        </Fragment>
                      );
                    })}
                </AutoLayout>
                <Divider />
              </AutoLayout>
            </Box>
            {/* Track summary */}
            <AutoLayout
              spacing={16}
              dependentProps={{ direction: "horizontal" }}
              sx={{
                width: "100%",
                alignItems: "center",
                cursor: canMakeChanges ? "pointer" : undefined,
                justifyContent: "center"
              }}
              onClick={
                canMakeChanges
                  ? () => setIsTracksAccordionOpen(!isTracksAccordionOpen)
                  : undefined
              }
            >
              <Text variant="bodySmall" color="deepGray100">
                <strong>{includedTracks.length}</strong> of{" "}
                <strong>{tracks.length}</strong> sound recording
                {tracks.length === 1 ? "" : "s"} included
              </Text>
              {canMakeChanges && (
                <>
                  {/* Accordion show/hide control */}
                  <IconBox
                    icon={Ic16ChevronDown}
                    sx={{
                      color: theme.colors.deepGray100 as string,
                      width: "16px",
                      height: "16px",
                      transform: isTracksAccordionOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.5s"
                    }}
                  />
                  {/* Hidden field to require at least one box is checked */}
                  {release && (
                    <FieldPrefix prefix="releaseHasIncludedTracks">
                      <Field
                        name={release.id}
                        validate={value =>
                          value
                            ? null
                            : "Please include at least one sound recording for each included release."
                        }
                      >
                        {() => <input type="hidden" />}
                      </Field>
                    </FieldPrefix>
                  )}
                </>
              )}
            </AutoLayout>
          </AutoLayout>
        )}
      </AutoLayout>
      {props.onEditModalSubmit && (
        // TODO if user exits the modal without submitting, we should remove the new release from the list
        <EditReleaseModal
          initialValues={release ?? props.newUnreleasedRelease}
          onSubmit={values => {
            setIsEditModalOpen(false);
            props.onEditModalSubmit(values);
          }}
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          releaseExists={!!release}
          submitLabel="Add release"
        />
      )}
    </>
  );
}

const removableStyles = {
  padding: "16px",
  border: "1px solid",
  borderRadius: "4px",
  backgroundColor: "lightGray100",
  borderColor: "midGray100",
  width: "100%"
};

export function RemovableIcon(props: {
  size: ResponsiveValue<ReleaseInfoRemovableSize>;
  remove: () => void;
  children: ReactElement | ReactElement[];
}): ReactElement {
  return (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "horizontal", alignment: "left" }}
      sx={removableStyles}
    >
      {props.children}
      {props.remove && <RemoveButton {...props} />}
    </AutoLayout>
  );
}

function RemoveButton(props: {
  remove: () => void;
  size: ResponsiveValue<ReleaseInfoRemovableSize>;
}) {
  const activeSize = useActiveResponsiveValue(props.size);
  return (
    <IconBox
      icon={Ic20Remove}
      sx={{ ...actionIconSx(), size: activeSize == "small" ? "20px" : "24px" }}
      onClick={props.remove}
    />
  );
}

export function getReleaseDetailsSize(
  size: ReleaseInfoRemovableSize
): ReleaseDetailsSize {
  switch (size) {
    case "small":
      return "small";
    case "large":
      return "medium";
  }
}

export function getReleaseImageIconSize(
  size: ReleaseInfoRemovableSize
): ReleaseImageIconSize {
  switch (size) {
    case "small":
      return "medium";
    case "large":
      return "large";
  }
}

export default ReleasesInfoRemovable;
