import { ReactElement, ReactNode } from "react";
import { Text } from "theme-ui";

import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import OfferNextStepBadge from "./OfferNextStapBadge";
import { OfferNextStepEnum } from "./OfferNextStep";

interface IOfferNextStepDisplayProps {
  badgeType: OfferNextStepEnum;
  header?: ReactNode;
  headerCaption?: ReactNode;
  children?: ReactElement;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2842%3A23794
 */
function OfferNextStepDisplay(props: IOfferNextStepDisplayProps): ReactElement {
  return (
    <AutoLayout
      spacing={24}
      dependentProps={{ direction: "vertical", alignment: "center" }}
      sx={{
        padding: "24px",
        backgroundColor: "lightGray100",
        width: "100%",
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "midGray100"
      }}
    >
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical", alignment: "center" }}
      >
        <OfferNextStepBadge
          type={props.badgeType}
          subtleBadgeProps={{ size: "medium", color: "stroked" }}
        />
        {props.header && (
          <Text
            variant={"bodyMedium"}
            color={"deepGray100"}
            sx={{ textAlign: "center" }}
          >
            {props.header}
          </Text>
        )}
        {props.headerCaption && (
          <Text
            variant={"bodySmall"}
            color={"deepGray100"}
            sx={{ textAlign: "center" }}
          >
            {props.headerCaption}
          </Text>
        )}
      </AutoLayout>
      {props.children}
    </AutoLayout>
  );
}

export default OfferNextStepDisplay;
