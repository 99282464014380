import { FeaturedDeals_artist$key } from "__generated__/FeaturedDeals_artist.graphql";
import { ReactElement } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import DealListItem from "../03_UI_Kit/Items/DealListItem";

interface IFeaturedDealsProps {
  artist: FeaturedDeals_artist$key;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=1618%3A44596
 */
function FeaturedDeals(props: IFeaturedDealsProps): ReactElement {
  const artist = useFragment(
    graphql`
      fragment FeaturedDeals_artist on ArtistNode {
        featuredDeals {
          edges {
            node {
              id
              ...DealListItem_featureddeal
            }
          }
        }
      }
    `,
    props.artist
  );

  if (!artist.featuredDeals || artist.featuredDeals.edges.length === 0) {
    return null;
  }

  return (
    <AutoLayout
      spacing={16}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical", alignment: "left" }}
      sx={{ alignSelf: "flex-start" }}
    >
      <Text variant={"h400"} color={"black100"}>
        Featured Deal{artist.featuredDeals.edges.length > 1 ? "s" : ""}
      </Text>
      {artist.featuredDeals.edges.map(featuredDeal => (
        <DealListItem
          key={featuredDeal.node.id}
          deal={featuredDeal.node}
          size={["small", "large", "large", "large"]}
        />
      ))}
    </AutoLayout>
  );
}

export default FeaturedDeals;
