import { EmailSubscriptions_user$key } from "__generated__/EmailSubscriptions_user.graphql";
import {
  EmailSubscriptionsMutation,
  EmailSubscriptionsMutation$variables,
  UserSetEmailSubscriptionsMutationInput
} from "__generated__/EmailSubscriptionsMutation.graphql";
import _ from "lodash";
import { ReactElement, useContext } from "react";
import { Field, Form } from "react-final-form";
import { graphql, useFragment } from "react-relay";

import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import Button from "../../../../components/01_Core/Buttons/Button";
import CheckboxInput from "../../../../components/01_Core/Forms/Inputs/CheckboxInput";
import useMutationPromise from "../../hooks/useMutationPromise";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import Footer from "./Footer";

export default function EmailSubscriptions(props: {
  user: EmailSubscriptions_user$key;
}): ReactElement {
  const notifyBanner = useContext(BannerContext);

  const user = useFragment(
    graphql`
      fragment EmailSubscriptions_user on UserNode {
        userType
        emailSubscriptions {
          edges {
            node {
              emailType
              isSubscribed
            }
          }
        }
      }
    `,
    props.user
  );

  const canUnsubscribeEmailTypes = Object.entries(GLOBALS.EMAIL_TYPES).filter(
    ([, { userType, canUnsubscribe }]) =>
      canUnsubscribe && (userType === null || userType === user.userType)
  );

  const [commitSetEmailSubscriptions] =
    useMutationPromise<EmailSubscriptionsMutation>(graphql`
      mutation EmailSubscriptionsMutation(
        $input: UserSetEmailSubscriptionsMutationInput!
      ) {
        userSetEmailSubcriptions(data: $input) {
          user {
            id
            emailSubscriptions {
              edges {
                node {
                  id
                  emailType
                  isSubscribed
                }
              }
            }
          }
        }
      }
    `);

  const setEmailSubscriptions = (
    variables: EmailSubscriptionsMutation$variables
  ) =>
    commitSetEmailSubscriptions({
      variables
    })
      .then(() =>
        notifyBanner({
          type: "neutral",
          children: "Your email subscriptions have been updated."
        })
      )
      .catch(() =>
        notifyBanner({
          type: "alert",
          children: "Something went wrong."
        })
      );

  const emailSubscriptionsByName = _.keyBy(
    user.emailSubscriptions.edges.map(({ node }) => node),
    et => et.emailType
  );
  const initialValues = Object.fromEntries(
    canUnsubscribeEmailTypes.map(([key, { name }]) => {
      const emailSubscription = emailSubscriptionsByName[name.toUpperCase()];
      return [key, emailSubscription ? emailSubscription.isSubscribed : true];
    })
  );

  const labels = {
    [GLOBALS.EMAIL_TYPES.E15PartnerNewActionedArtistProposal.name]:
      "New deal proposals from artists you've interacted with",
    [GLOBALS.EMAIL_TYPES.E16PartnerNewHighlightedDeals.name]:
      "Hot deal proposals from top artists",
    [GLOBALS.EMAIL_TYPES.E17PartnerNewDeals.name]:
      "Summary of deal proposals posted in the last week",
    [GLOBALS.EMAIL_TYPES.E19ArtistReminderToCreateFeaturedDealForNewReleases
      .name]: "Reminder to create deal proposals for your new releases",
    [GLOBALS.EMAIL_TYPES.E25ArtistOfferReminder.name]:
      "Reminder to respond to offers from partners"
  };
  return (
    <Form<UserSetEmailSubscriptionsMutationInput>
      initialValues={initialValues}
      onSubmit={input => setEmailSubscriptions({ input })}
      key={"form"}
    >
      {formProps => {
        const setAllFn = (value: boolean) => () =>
          canUnsubscribeEmailTypes.forEach(([key]) => {
            formProps.form.change(key as any, value);
          });
        const values = Object.values(formProps.values);

        return (
          <form onSubmit={formProps.handleSubmit} style={{ width: "100%" }}>
            <AccountManagementFormContent>
              <AccountManagementHeading
                key={"heading"}
                size={["small", "large", "large", "large"]}
                title={"Email Notifications"}
                description={
                  "Manage your preferences for receiving emails from indify."
                }
              />
              <AutoLayout
                spacing={[16, 24, 24, 24]}
                resizingX={FillContainer()}
                dependentProps={{
                  direction: "vertical"
                }}
              >
                {canUnsubscribeEmailTypes.map(
                  ([key, { name, canUnsubscribe }]) => (
                    <Field name={key} key={key}>
                      {input => (
                        <CheckboxInput
                          {...input}
                          label={labels[name]}
                          size={["medium", "medium", "large", "large"]}
                          disabled={formProps.submitting || !canUnsubscribe}
                          state="normal"
                        />
                      )}
                    </Field>
                  )
                )}
                <AutoLayout
                  spacing={8}
                  resizingX={FillContainer()}
                  dependentProps={{
                    direction: "horizontal"
                  }}
                >
                  <Button
                    size={"small"}
                    variant="secondary"
                    disabled={formProps.submitting || values.every(v => !v)}
                    sx={{ width: "100%" }}
                    onClick={setAllFn(false)}
                  >
                    Unsubscribe from all
                  </Button>
                  <Button
                    size={"small"}
                    variant="secondary"
                    disabled={formProps.submitting || values.every(v => v)}
                    sx={{ width: "100%" }}
                    onClick={setAllFn(true)}
                  >
                    Subscribe to all
                  </Button>
                </AutoLayout>
              </AutoLayout>
              <Footer
                buttonDisabled={
                  formProps.pristine || formProps.hasValidationErrors
                }
                loading={formProps.submitting}
                onClick={formProps.handleSubmit}
                size={["small", "large", "large", "large"]}
              />
            </AccountManagementFormContent>
          </form>
        );
      }}
    </Form>
  );
}
