/**
 * @generated SignedSource<<822b682d0a6b52fe7bd0b982440531dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartnershipsGrid_offers$data = ReadonlyArray<{
  readonly dateOfferAccepted: any | null;
  readonly id: string;
  readonly terms: any;
  readonly " $fragmentSpreads": FragmentRefs<"DealCard_offer">;
  readonly " $fragmentType": "PartnershipsGrid_offers";
}>;
export type PartnershipsGrid_offers$key = ReadonlyArray<{
  readonly " $data"?: PartnershipsGrid_offers$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartnershipsGrid_offers">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PartnershipsGrid_offers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfferAccepted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terms",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealCard_offer"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "5ca9d11cb11ac0b4d872cb6675700bcc";

export default node;
