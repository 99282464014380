import { ReleaseStatusMenu_DeleteMutation } from "__generated__/ReleaseStatusMenu_DeleteMutation.graphql";
import { ReleaseStatusMenu_release$key } from "__generated__/ReleaseStatusMenu_release.graphql";
import { forwardRef, useContext, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { useThemeUI } from "theme-ui";

import Ic16ChevronDown from "../../../../../../imgs/icons/ic16-chevron-down.svg";
import Ic20Delete from "../../../../../../imgs/icons/ic20-delete.svg";
import Ic20FileEdit from "../../../../../../imgs/icons/ic20-file-edit.svg";
import Ic20VisibilityOn from "../../../../../../imgs/icons/ic20-visibility.svg";
import Ic20VisibilityOff from "../../../../../../imgs/icons/ic20-visibility-off.svg";
import { BannerContext } from "../../../../../components/01_Core/Announcements/BannerProvider";
import IconButton from "../../../../../components/01_Core/Buttons/IconButton";
import TextButton from "../../../../../components/01_Core/Buttons/TextButton";
import Dropdown from "../../../../../components/01_Core/Dropdown_Menus/Dropdown";
import useMutationPromise from "../../../hooks/useMutationPromise";
import { ArtistContext } from "../../08_Account_Management/ArtistManageReleases";
import UpdateOrCreateReleaseModal, {
  useUpdateOrCreateRelease
} from "./UpdateOrCreateReleaseModal";

interface IReleaseDropdownProps {
  release: ReleaseStatusMenu_release$key;
  openDirection: "left" | "right";
}

/**
 * https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/07-Account-Management?node-id=2002%3A23089
 */
const ReleaseStatusMenu = forwardRef((props: IReleaseDropdownProps, ref) => {
  const { theme } = useThemeUI();
  const notifyBanner = useContext(BannerContext);

  const artist = useContext(ArtistContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const release = useFragment(
    graphql`
      fragment ReleaseStatusMenu_release on ReleaseInterface {
        __typename
        id
        isVisible
        canDelete
        ...UpdateOrCreateReleaseModal_release
      }
    `,
    props.release
  );

  const [_, updateRelease] = useUpdateOrCreateRelease({ artist, release });

  const [commitDelete] = useMutationPromise<ReleaseStatusMenu_DeleteMutation>(
    graphql`
      mutation ReleaseStatusMenu_DeleteMutation($id: ID!) {
        deleteUnreleasedRelease(id: $id) {
          artist {
            id
          }
        }
      }
    `
  );

  const deleteRelease = () =>
    commitDelete({
      variables: {
        id: release.id
      },
      updater: store => store.get(artist.id)?.invalidateRecord()
    })
      .then(() =>
        notifyBanner({
          type: "neutral",
          children: "Your release has been deleted successfully."
        })
      )
      .catch(() =>
        notifyBanner({
          type: "alert",
          children: "Something went wrong."
        })
      );

  const ToggleProfileVisibility = (componentProps: {
    currentlyVisible: boolean;
  }) => (
    <TextButton
      onClick={() => {
        updateRelease({
          isVisible: !componentProps.currentlyVisible
        });
      }}
      size={"medium"}
      type={"secondary"}
      iconProps={{
        placement: "left",
        icon: componentProps.currentlyVisible ? (
          <Ic20VisibilityOff />
        ) : (
          <Ic20VisibilityOn />
        )
      }}
    >
      {componentProps.currentlyVisible
        ? "Hide from profile"
        : "Show on profile"}
    </TextButton>
  );

  return (
    <>
      <UpdateOrCreateReleaseModal
        release={release}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
      />
      <Dropdown
        triggerElement={
          <IconButton
            variant={"tertiary"}
            disabled={false}
            size={"small"}
            sxOverrides={{ borderRadius: "4px" }}
          >
            <Ic16ChevronDown color={theme.colors.black100 as string} />
          </IconButton>
        }
        openDirection={props.openDirection}
        styles={{ top: 38, width: 270 }}
      >
        {release.__typename === "UnreleasedReleaseNode" && (
          <TextButton
            size={"medium"}
            type={"secondary"}
            iconProps={{ placement: "left", icon: <Ic20FileEdit /> }}
            onClick={() => setIsEditModalOpen(true)}
          >
            Edit upcoming release
          </TextButton>
        )}
        <ToggleProfileVisibility currentlyVisible={release.isVisible} />
        {release.__typename === "UnreleasedReleaseNode" &&
          release.canDelete && (
            <TextButton
              size={"medium"}
              type={"secondary"}
              iconProps={{ placement: "left", icon: <Ic20Delete /> }}
              onClick={deleteRelease}
            >
              Delete upcoming release
            </TextButton>
          )}
      </Dropdown>
    </>
  );
});

export default ReleaseStatusMenu;
