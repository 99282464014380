import { ReactElement, ReactNode } from "react";
import { Box, Text } from "theme-ui";

import Ic16Info from "../../../../../../imgs/icons/ic16-info.svg";
import Ic16Warning from "../../../../../../imgs/icons/ic16-warning.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { IconBox } from "../../../../../components/01_Core/Foundations/Icons";

export function getPostRecoupSplitWarning(partnerSplit: number): string | null {
  return (
    partnerSplit >= 40 &&
    "This post-recoupment partner split is higher than most deals on indify."
  );
}

export function getTermLengthWarning(
  termLength: number,
  userType: "artist" | "partner"
): string | null {
  if (termLength < 36) {
    if (userType === "partner") return null;
    return "This term length is shorter than most deals on indify. Deal proposals with term lengths of at least 36 months are more likely to receive offers.";
  }
  if (termLength >= 96)
    return "This term length is longer than most deals on indify.";
  return null;
}

export function OfferTermsWarningBlock(props: {
  type: "warning" | "info";
  size: "small" | "large";
  children: ReactNode;
}): ReactElement {
  const Icon = (() => {
    switch (props.type) {
      case "warning":
        return Ic16Warning;
      case "info":
        return Ic16Info;
    }
  })();

  const iconColor = (() => {
    switch (props.type) {
      case "warning":
        return "warning50";
      case "info":
        return "deepGray70";
    }
  })();

  const bgColor = (() => {
    switch (props.type) {
      case "warning":
        return "warning05";
      case "info":
        return "secondary10";
    }
  })();

  const padding = (() => {
    switch (props.size) {
      case "small":
        return 8;
      case "large":
        return 16;
    }
  })();

  const iconSize = (() => {
    switch (props.size) {
      case "small":
        return "16px";
      case "large":
        return "24px";
    }
  })();

  return (
    <Box
      bg={theme => theme.colors[bgColor]}
      padding={`${padding}px`}
      sx={{ borderRadius: "4px", width: "100%" }}
    >
      <AutoLayout
        spacing={padding}
        dependentProps={{ direction: "horizontal" }}
      >
        <IconBox
          icon={Icon}
          sx={{
            flexShrink: 0,
            width: iconSize,
            height: iconSize,
            color: iconColor
          }}
        />
        <Text
          color={"deepGray100"}
          variant={props.size === "small" ? "bodySmall" : "bodyMedium"}
        >
          {props.children}
        </Text>
      </AutoLayout>
    </Box>
  );
}
