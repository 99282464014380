/**
 * @generated SignedSource<<a7b14d246fe4fc60dd14bef47f490ca4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DrillInQuery$variables = {
  slug: string;
};
export type DrillInQuery$data = {
  readonly artistBySlug: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor" | "MakeOfferAction_artist" | "MessageAction_artist" | "WatchArtistAction_artist">;
  } | null;
};
export type DrillInQuery = {
  response: DrillInQuery$data;
  variables: DrillInQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DrillInQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArtistNode",
        "kind": "LinkedField",
        "name": "artistBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AvatarName_actor"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WatchArtistAction_artist"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MessageAction_artist"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MakeOfferAction_artist"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DrillInQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArtistNode",
        "kind": "LinkedField",
        "name": "artistBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "watching",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastActiveAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "actor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileNode",
                            "kind": "LinkedField",
                            "name": "spotifyProfile",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ArtistNode",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyNode",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "PartnerNode",
                "abstractKey": null
              }
            ],
            "type": "ActorInterface",
            "abstractKey": "__isActorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae2b49cafaf7a37cea42c8d48f9855f4",
    "id": null,
    "metadata": {},
    "name": "DrillInQuery",
    "operationKind": "query",
    "text": "query DrillInQuery(\n  $slug: String!\n) {\n  artistBySlug(slug: $slug) {\n    id\n    ...AvatarName_actor\n    ...WatchArtistAction_artist\n    ...MessageAction_artist\n    ...MakeOfferAction_artist\n  }\n}\n\nfragment AvatarIcon_actor on ActorInterface {\n  __isActorInterface: __typename\n  user {\n    lastActiveAt\n    ...useUserThumbnailUrl_user\n    id\n  }\n}\n\nfragment AvatarName_actor on ActorInterface {\n  __isActorInterface: __typename\n  __typename\n  slug\n  displayName\n  ...AvatarIcon_actor\n  ... on PartnerNode {\n    company {\n      slug\n      displayName\n      id\n    }\n  }\n}\n\nfragment MakeOfferAction_artist on ArtistNode {\n  ...useMessage_actor\n  displayName\n}\n\nfragment MessageAction_artist on ArtistNode {\n  ...useMessage_actor\n}\n\nfragment WatchArtistAction_artist on ArtistNode {\n  id\n  watching\n}\n\nfragment useArtistThumbnailUrl_artist on ArtistNode {\n  thumbnailUrl\n  spotifyProfile {\n    thumbnailUrl\n    id\n  }\n}\n\nfragment useMessage_actor on ActorInterface {\n  __isActorInterface: __typename\n  user {\n    ...useMessage_user\n    id\n  }\n}\n\nfragment useMessage_user on UserNode {\n  id\n}\n\nfragment useUserThumbnailUrl_user on UserNode {\n  actor {\n    __typename\n    thumbnailUrl\n    ...useArtistThumbnailUrl_artist\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec3fcace927220257ac1108a73537aea";

export default node;
