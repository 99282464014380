import * as EmailValidator from "email-validator";

// A validator marks something as valid if it returns undefined, otherwise it returns a string with the explanation of
// why the thing is invalid
export type ValidationError =
  | string
  | {
      reason: string;
      section?: string;
    };
export type FieldValidationResult =
  | ValidationError
  | undefined
  | Promise<ValidationError | undefined>;
export type FieldValidator<FieldType> = (
  value: FieldType
) => FieldValidationResult;

export const composeFieldValidators =
  <FieldType>(
    ...validators: Array<FieldValidator<FieldType>>
  ): FieldValidator<FieldType> =>
  value =>
    validators.reduce<FieldValidationResult>((err, validator) => {
      return err == null ? validator(value) : err;
    }, undefined);

export function optional<FieldType>(
  v: FieldValidator<FieldType>
): FieldValidator<FieldType | null> {
  return (value: FieldType | null) => (value == null ? undefined : v(value));
}

export const emailValidator: FieldValidator<string> = value =>
  EmailValidator.validate(value)
    ? undefined
    : "Please enter a valid email address";

export const urlValidator: FieldValidator<string> = value => {
  if (!value.startsWith("http://") && !value.startsWith("https://")) {
    return "URL must start with http:// or https://";
  }

  try {
    const url = new URL(value);
    if (url.protocol === "http:" || url.protocol === "https:") {
      return undefined;
    }
  } catch {
    /* Do nothing */
  }
  return "Please enter a valid URL";
};

export const required: <FieldType>(
  message: string
) => FieldValidator<FieldType | null> = message => value =>
  value == null ? message : undefined;

export const requiredTrue: (
  message: string
) => FieldValidator<boolean | null> = message => value =>
  value !== true ? message : undefined;

export const requiredStringValidator: FieldValidator<string | null> = value =>
  value == null || value.trim() === "" ? "This can't be blank." : undefined;

export const requiredStringValidatorShort: FieldValidator<
  string | null
> = value => (value == null || value.trim() === "" ? "Required." : undefined);

export const slugValidator: FieldValidator<string | null> = value => {
  // "A slug is a short label for something, containing only letters, numbers, underscores or hyphens"
  //   https://docs.djangoproject.com/en/3.2/ref/models/fields/#slugfield
  // Allow lowercase letters, numbers, and hyphens. Do not allow underscores. Must not begin or end with a hyphen.
  const slugRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/g;
  return value == null || value.match(slugRegex) == null
    ? "Your profile URL must be all lowercase and can only contain letters, numbers, or hyphens."
    : undefined;
};

export const getMinimumLengthStringValidator: (
  length: number,
  message?: string
) => FieldValidator<string | null> = (length, message) => value =>
  value == null || value.length < length
    ? message != null
      ? message
      : `This field must be at least ${length} characters`
    : undefined;

export const getMaximumLengthStringValidator: (
  length: number,
  message?: string
) => FieldValidator<string | null> = (length, message) => value =>
  value == null || value.length > length
    ? message != null
      ? message
      : `This field must be at no greater than ${length} characters`
    : undefined;

export const requiredEmailValidator = composeFieldValidators(
  requiredStringValidator,
  emailValidator
);
