/**
 * @generated SignedSource<<5b588c677a68cb9e4ce09b677cbc0a7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserListCompanyTeam_company$data = {
  readonly partnerCompanyReferrals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ReferralRecipientInvited_referral">;
      } | null;
    } | null>;
  };
  readonly partners: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ReferralRecipientActive_recipient">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "UserListCompanyTeam_company";
};
export type UserListCompanyTeam_company$key = {
  readonly " $data"?: UserListCompanyTeam_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserListCompanyTeam_company">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserListCompanyTeam_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNodeConnection",
      "kind": "LinkedField",
      "name": "partners",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PartnerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReferralRecipientActive_recipient"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerToCompanyPartnerReferralNodeConnection",
      "kind": "LinkedField",
      "name": "partnerCompanyReferrals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerToCompanyPartnerReferralNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PartnerToCompanyPartnerReferralNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReferralRecipientInvited_referral"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CompanyNode",
  "abstractKey": null
};
})();

(node as any).hash = "7e760c73b66a1a156aa7fc8be4e96654";

export default node;
