/**
 * @generated SignedSource<<5b925902c335aad1cae9176c18dc501a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpecialDeals_matchForCompanyDeals$data = {
  readonly matchForCompanyDeals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"SpecialDeal_featuredDeal">;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "SpecialDeals_matchForCompanyDeals";
};
export type SpecialDeals_matchForCompanyDeals$key = {
  readonly " $data"?: SpecialDeals_matchForCompanyDeals$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpecialDeals_matchForCompanyDeals">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./SpecialDealsMatchForCompanyDealsRefetchQuery.graphql')
    }
  },
  "name": "SpecialDeals_matchForCompanyDeals",
  "selections": [
    {
      "alias": "matchForCompanyDeals",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        },
        {
          "kind": "Literal",
          "name": "isMatchDismissed",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "isMatchForCompany",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-date"
        }
      ],
      "concreteType": "FeaturedDealNodeConnection",
      "kind": "LinkedField",
      "name": "availableFeaturedDeals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeaturedDealNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FeaturedDealNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SpecialDeal_featuredDeal"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "availableFeaturedDeals(first:3,isMatchDismissed:false,isMatchForCompany:true,orderBy:\"-date\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fc7ad0b7eabb68b74b289325dd7ae73b";

export default node;
