import { FundPortfolioPage_authUser$key } from "__generated__/FundPortfolioPage_authUser.graphql";
import { FundPortfolioPage_offers$key } from "__generated__/FundPortfolioPage_offers.graphql";
import { FundPortfolioPageQuery as FundPortfolioPageQueryType } from "__generated__/FundPortfolioPageQuery.graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { ReactElement } from "react";
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery
} from "react-relay";
import { Redirect } from "react-router-dom";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { useAuth } from "../../hooks/useAuth";
import EarningsSection from "./EarningsSection";
import FundPortfolioRowDeal from "./FundPortfolioRowDeal";
import PayoutsSection from "./PayoutsSection";
import WeeklyStreamsSection from "./WeeklyStreamsSection";

dayjs.extend(relativeTime);

interface IFundPortfolioPageProps {
  queryRef: PreloadedQuery<FundPortfolioPageQueryType>;
}

/**
 * figma: https://www.figma.com/file/66ojJGheymaB4yMt8HbG6J/09-Payments-(Gestural-Design)?node-id=2806%3A3154
 */
function FundPortfolioPage(props: IFundPortfolioPageProps): ReactElement {
  const authUser = useFragment<FundPortfolioPage_authUser$key>(
    graphql`
      fragment FundPortfolioPage_authUser on UserNode {
        partner {
          canViewPortfolio
        }
      }
    `,
    useAuth().authUser
  );
  if (!authUser.partner.canViewPortfolio) {
    return <Redirect to={"/"} />;
  }

  const { me, portfolio } = usePreloadedQuery<FundPortfolioPageQueryType>(
    FundPortfolioPageQuery,
    props.queryRef
  );

  const offers = useFragment<FundPortfolioPage_offers$key>(
    graphql`
      fragment FundPortfolioPage_offers on OfferNode @relay(plural: true) {
        monthlyEarnings {
          edges {
            node {
              month
              partnerEarnings
              partnerExpenses
            }
          }
        }
      }
    `,
    portfolio
  );

  const monthlyEarnings = _.chain(offers)
    .flatMap(offer => offer.monthlyEarnings.edges.map(({ node }) => node))
    .groupBy(({ month }) => dayjs(month).format("YYYY-MM"))
    .values()
    .map(group => {
      return {
        month: group[0].month as string,
        earnings: _.sumBy(group, ({ partnerEarnings }) =>
          parseFloat(partnerEarnings as string)
        ),
        expenses: _.sumBy(group, ({ partnerExpenses }) =>
          parseFloat(partnerExpenses as string)
        )
      };
    })
    .sortBy(({ month }) => month)
    .value();

  const totalMonthlyEarnings = _.sumBy(
    monthlyEarnings,
    ({ earnings }) => earnings
  );
  const totalInvested =
    0 - _.sumBy(monthlyEarnings, ({ expenses }) => expenses);

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}
    >
      <FixedGrid>
        <GridItem gridColumn={"1 / -1"}>
          <AutoLayout
            spacing={48}
            dependentProps={{ direction: "vertical" }}
            resizingX={FillContainer()}
            my={48}
          >
            <Text variant={"h600"} color={"black100"}>
              Your company portfolio
            </Text>
            <Divider saturation={"dark"} />
            <EarningsSection
              totalInvested={totalInvested}
              monthlyEarnings={monthlyEarnings}
              totalMonthlyEarnings={totalMonthlyEarnings}
              size={["small", "small", "medium", "large"]}
            />
            <Divider saturation={"dark"} />
            <WeeklyStreamsSection
              entity={me.partner.company}
              size={["small", "small", "small", "large"]}
            />
            {monthlyEarnings.length > 0 && <Divider saturation={"dark"} />}
            {monthlyEarnings.length > 0 && <PayoutsSection />}
            <Divider saturation={"dark"} />
            <AutoLayout
              spacing={24}
              resizingX={FillContainer()}
              dependentProps={{ direction: "vertical" }}
            >
              <Text variant={"h400"} color={"black100"}>
                Partnerships
              </Text>
              {portfolio.length === 0 ? (
                <Text
                  variant={"h300"}
                  color={"deepGray100"}
                  sx={{ textAlign: "center", width: "100%" }}
                >
                  {
                    "You don't have any partnerships yet.  Try messaging some artists to get started!"
                  }
                </Text>
              ) : (
                <Box
                  sx={{
                    backgroundColor: "white100",
                    width: "100%",
                    border: "1px solid",
                    borderColor: "midGray70",
                    borderBottom: "unset"
                  }}
                >
                  {portfolio.map(deal => (
                    <FundPortfolioRowDeal
                      key={deal.id}
                      offer={deal}
                      size={["small", "medium", "medium", "large"]}
                    />
                  ))}
                </Box>
              )}
            </AutoLayout>
          </AutoLayout>
        </GridItem>
      </FixedGrid>
    </GridItem>
  );
}

export const FundPortfolioPageQuery = graphql`
  query FundPortfolioPageQuery {
    me {
      partner {
        company {
          ...WeeklyStreamsSection_entity
        }
      }
    }
    portfolio {
      id
      ...FundPortfolioPage_offers
      ...FundPortfolioRowDeal_offer
    }
  }
`;

export default FundPortfolioPage;
