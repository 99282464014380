import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Flex } from "theme-ui";

import { rvMap } from "../../../../../utils/responsiveUtils";
import { getBodyHeight, getBodyPadding, ReleaseCardSize } from "./ReleaseCard";

interface IReleaseCardEmptyProps {
  size: ResponsiveValue<ReleaseCardSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1681%3A25769
 */
function ReleaseCardEmpty(props: IReleaseCardEmptyProps): ReactElement {
  return (
    <Flex sx={{ flexDirection: "column", width: "100%" }}>
      {/* Release Info */}
      <Flex
        sx={{
          backgroundColor: "midGray70",
          padding: rvMap(props.size, getBodyPadding),
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          height: rvMap(props.size, getBodyHeight),
          width: "100%"
        }}
      >
        <Box bg={"lightGray100"} sx={{ width: "100%", height: "192px" }} />
      </Flex>
      {/*  Ask Value */}
      <Flex
        sx={{
          alignItems: "flex-end",
          backgroundColor: "midGray70",
          padding: rvMap(props.size, getBodyPadding),
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          width: "100%"
        }}
      >
        <Box bg={"lightGray100"} sx={{ width: "100%", height: "40px" }} />
      </Flex>
    </Flex>
  );
}

function getWidth(size: ReleaseCardSize) {
  switch (size) {
    case "small": {
      return "162px";
    }
    case "large": {
      return "240px";
    }
  }
}

export default ReleaseCardEmpty;
