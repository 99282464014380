import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";

import Ic16Minus from "../../../../../../imgs/icons/ic16-minus.svg";
import Ic16Plus from "../../../../../../imgs/icons/ic16-plus.svg";
import Ic20Minus from "../../../../../../imgs/icons/ic20-minus.svg";
import Ic20Plus from "../../../../../../imgs/icons/ic20-plus.svg";
import Ic24Minus from "../../../../../../imgs/icons/ic24-minus.svg";
import Ic24Plus from "../../../../../../imgs/icons/ic24-plus.svg";
import TextButton from "../../../../../components/01_Core/Buttons/TextButton";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";

type ShowMoreSize = "small" | "medium" | "large";

export interface IShowMoreProps {
  size: ResponsiveValue<ShowMoreSize>;
  onClick?: () => void;
  expanded: boolean;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=321%3A381
 */
function ShowMore(props: IShowMoreProps): ReactElement {
  const activeSize = useActiveResponsiveValue<ShowMoreSize>(props.size);

  const [MinusIcon, PlusIcon] = getIcons(activeSize);

  return (
    <TextButton
      onClick={props.onClick}
      size={props.size}
      type={"secondary"}
      iconProps={{
        placement: "left",
        icon: props.expanded ? <MinusIcon /> : <PlusIcon />
      }}
    >
      {props.expanded ? "Show less" : "Show more"}
    </TextButton>
  );
}

function getIcons(activeSize: ShowMoreSize) {
  switch (activeSize) {
    case "small":
      return [Ic16Minus, Ic16Plus];
    case "medium":
      return [Ic20Minus, Ic20Plus];
    case "large":
      return [Ic24Minus, Ic24Plus];
  }
}

export default ShowMore;
