/**
 * @generated SignedSource<<91662201217891dfc14a7add00ab7dbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useToggleWatchCompany_company$data = {
  readonly slug: string;
  readonly " $fragmentType": "useToggleWatchCompany_company";
};
export type useToggleWatchCompany_company$key = {
  readonly " $data"?: useToggleWatchCompany_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useToggleWatchCompany_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useToggleWatchCompany_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "CompanyNode",
  "abstractKey": null
};

(node as any).hash = "86d38f4411795149b4d62f731160fc69";

export default node;
