import { ReactElement } from "react";
import { Box, BoxProps } from "theme-ui";

import { zIndices } from "../../../../theme/theme";

export interface IRecoupmentProgressBarProps extends BoxProps {
  totalMonthlyEarnings: number;
  totalInvested: number;
}

export function RecoupmentProgressBar(
  props: IRecoupmentProgressBarProps
): ReactElement {
  const { totalMonthlyEarnings, totalInvested, ...boxProps } = props;

  const hasRecouped = totalMonthlyEarnings >= totalInvested;

  return (
    <Box
      sx={{ width: "100%", height: "8px", position: "relative" }}
      {...boxProps}
    >
      <Box
        sx={{
          backgroundColor: "upwards100",
          borderRadius: hasRecouped ? "4px" : "4px 0 0 4px",
          width: `${Math.min(
            (totalMonthlyEarnings / totalInvested) * 100,
            100
          )}%`,
          height: "100%",
          zIndex: zIndices.switch,
          position: "absolute",
          top: 0,
          left: 0
        }}
      />
      <Box
        sx={{
          backgroundColor: "midGray100",
          borderRadius: "4px",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0
        }}
      />
    </Box>
  );
}
