import {
  AccountManagementPageQuery as AccountManagementPageQueryType,
  AccountManagementPageQuery$data
} from "__generated__/AccountManagementPageQuery.graphql";
import { useAuth_MeFragment$data } from "__generated__/useAuth_MeFragment.graphql";
import * as _ from "lodash";
import { ReactElement } from "react";
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay";
import { generatePath, Redirect } from "react-router-dom";

import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { Artist, Partner } from "../../../../types/users";
import { useAuth } from "../../hooks/useAuth";
import usePathState from "../../hooks/usePathState";
import { InternalRoutes } from "../../Routing/InternalRoutes";
import AccountNav from "../03_UI_Kit/Navigation/AccountNav";
import ArtistEditSocialLinks from "./ArtistEditSocialLinks";
import ArtistManageBank from "./ArtistManageBank";
import ArtistManageDeals from "./ArtistManageDeals";
import ArtistManageReleases from "./ArtistManageReleases";
import ArtistManagerInfo from "./ArtistManagerInfo";
import ArtistReferrals from "./ArtistReferrals";
import ArtistViewTeam from "./ArtistViewTeam";
import BasicInfoFormArtist from "./BasicInfoFormArtist";
import BasicInfoFormPartner from "./BasicInfoFormPartner";
import CompanyInfo from "./CompanyInfo";
import CompanySocialLinks from "./CompanySocialLinks";
import CompanyTeam from "./CompanyTeam";
import EmailSubscriptions from "./EmailSubscriptions";
import ManageSubscription from "./ManageSubscription";
import PartnerReferrals from "./PartnerReferrals";
import PartnerViewRoster from "./PartnerViewRoster";
import UpdatePasswordForm from "./UpdatePasswordForm";

export const basicInfoSubpage = "basic-info";
export const manageReleasesSubpage = "releases";
export const socialLinksSubpage = "social-links";
export const teamMembersSubpage = "team-members";
export const bankSettingsSubpage = "bank-settings";
export const companyInfoSubpage = "fund-info";
export const companySocialLinksSubpage = "fund-socials";
export const managerInfoSubpage = "manager-info";
export const companyTeamSubpage = "fund-team";
export const artistRosterSubpage = "artist-roster";
export const partnerReferralsSubpage = "partner-referrals";
export const artistReferralsSubpage = "artist-referrals";
export const emailSubscriptionsSubpage = "email-subscriptions";
export const passwordSubpage = "password";
export const dealsSubpage = "deals";
export const subscriptionSubpage = "subscription";

const subpageMap = {
  [basicInfoSubpage]: [Artist, Partner],
  [dealsSubpage]: [Artist],
  [manageReleasesSubpage]: [Artist],
  [socialLinksSubpage]: [Artist],
  [managerInfoSubpage]: [Artist],
  [teamMembersSubpage]: [Artist],
  [bankSettingsSubpage]: [Artist],
  [companyInfoSubpage]: [Partner],
  [companySocialLinksSubpage]: [Partner],
  [companyTeamSubpage]: [Partner],
  [artistRosterSubpage]: [Partner],
  [partnerReferralsSubpage]: [Partner],
  [artistReferralsSubpage]: [Partner],
  [emailSubscriptionsSubpage]: [Artist, Partner],
  [passwordSubpage]: [Artist, Partner],
  [subscriptionSubpage]: [Artist, Partner]
};

export type Subpage = keyof typeof subpageMap;

export const getAvailableSubpages = (
  authUser: useAuth_MeFragment$data
): Subpage[] =>
  _.keys(_.pickBy(subpageMap, p => _.includes(p, authUser.userType))).map(
    k => k as Subpage
  );

interface IAccountManagementPageProps {
  queryRef: PreloadedQuery<AccountManagementPageQueryType>;
}

export interface IEntityInfoValues {
  displayName: string;
  location?: string;
  about?: string;
}

/**
 * Manages which account settings page to display
 * @constructor
 */
function AccountManagementPage(
  props: IAccountManagementPageProps
): ReactElement {
  const data = usePreloadedQuery<AccountManagementPageQueryType>(
    AccountManagementPageQuery,
    props.queryRef
  );

  const { value: selectedSubpage } = usePathState("settings", "subpage");
  const { authUser } = useAuth();

  const subpages = getAvailableSubpages(authUser);

  if (!_.includes(subpages, selectedSubpage)) {
    return (
      <Redirect
        to={generatePath(InternalRoutes.settings, { subpage: subpages[0] })}
      />
    );
  }

  // TODO: Remove overall grid from site to remove extra grid item tab
  return (
    <GridItem
      gridColumn={"1 / -1"}
      mt={["32px", "64px", "64px", "64px"]}
      sx={{ width: "100%", justifyContent: "center" }}
    >
      <FixedGrid
        sx={{
          gridTemplateAreas: [
            `
              "nav nav nav nav"
              "con con con con"
            `,
            `
              ". nav nav nav nav nav nav ."
              ". con con con con con con ."
            `,
            `
              ". nav nav nav nav nav nav ."
              ". con con con con con con ."
            `,
            `
              "nav nav nav nav con con con con con con con con"
            `
          ]
        }}
      >
        <GridItem gridArea={"nav"}>
          <AccountNav size={["small", "small", "small", "large"]} />
        </GridItem>
        <GridItem gridArea={"con"}>
          <SubpageSwitch subpage={selectedSubpage as Subpage} data={data} />
        </GridItem>
      </FixedGrid>
    </GridItem>
  );
}

function SubpageSwitch(props: {
  subpage: Subpage;
  data: AccountManagementPageQuery$data;
}): ReactElement {
  const { authUser } = useAuth();

  switch (props.subpage) {
    case basicInfoSubpage:
      switch (authUser.userType) {
        case "artist":
          return <BasicInfoFormArtist artist={props.data.settings.artist} />;
        case "partner":
          return <BasicInfoFormPartner partner={props.data.settings.partner} />;
        default:
          return <>Bad user type</>;
      }
    case manageReleasesSubpage:
      return <ArtistManageReleases artist={props.data.settings.artist} />;
    case companyInfoSubpage:
      return <CompanyInfo company={props.data.settings.partner.company} />;
    case companySocialLinksSubpage:
      return (
        <CompanySocialLinks company={props.data.settings.partner.company} />
      );
    case companyTeamSubpage:
      return <CompanyTeam company={props.data.settings.partner.company} />;
    case passwordSubpage:
      return <UpdatePasswordForm />;
    case socialLinksSubpage:
      return <ArtistEditSocialLinks artist={props.data.settings.artist} />;
    case managerInfoSubpage:
      return <ArtistManagerInfo artist={props.data.settings.artist} />;
    case teamMembersSubpage:
      return <ArtistViewTeam artist={props.data.settings.artist} />;
    case bankSettingsSubpage:
      return <ArtistManageBank user={props.data.settings} />;
    case artistRosterSubpage:
      return <PartnerViewRoster partner={props.data.settings.partner} />;
    case partnerReferralsSubpage:
      return <PartnerReferrals partner={props.data.settings.partner} />;
    case artistReferralsSubpage:
      return <ArtistReferrals partner={props.data.settings.partner} />;
    case emailSubscriptionsSubpage:
      return <EmailSubscriptions user={props.data.settings} />;
    case dealsSubpage:
      return (
        <ArtistManageDeals
          artist={props.data.settings.artist}
          featuredDeals={props.data.settings.artist.featuredDeals.edges.map(
            e => e.node
          )}
        />
      );
    case subscriptionSubpage:
      return <ManageSubscription user={props.data.settings} />;
  }
}

export const AccountManagementPageQuery = graphql`
  query AccountManagementPageQuery {
    settings {
      ...ArtistManageBank_user
      ...EmailSubscriptions_user
      ...ManageSubscription_user
      artist {
        ...BasicInfoFormArtist_artist
        ...ArtistEditSocialLinks_artist
        ...ArtistManagerInfo_artist
        ...ArtistManageReleases_artist
        ...ArtistManageDeals_artist
        ...ArtistViewTeam_teamMembers
        featuredDeals {
          edges {
            node {
              ...ArtistManageDeals_featuredDeals
            }
          }
        }
      }
      partner {
        ...BasicInfoFormPartner_partner
        ...PartnerReferrals_partner
        ...ArtistReferrals_partner
        company {
          ...CompanyInfo_company
          ...CompanySocialLinks_company
          ...CompanyTeam_company
        }
        ...PartnerViewRoster_artistRoster
      }
    }
  }
`;

export default AccountManagementPage;
