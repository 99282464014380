/**
 * @generated SignedSource<<c6fe26beee13dba61b4147100355a992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMessage_user$data = {
  readonly id: string;
  readonly " $fragmentType": "useMessage_user";
};
export type useMessage_user$key = {
  readonly " $data"?: useMessage_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMessage_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMessage_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "9ef05d517118b74232b3bf703cae66da";

export default node;
