/**
 * @generated SignedSource<<1388d52d732803972ba1259f5140fcd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMessageUser_GetOrCreateChatMutation$variables = {
  recipientUserId: string;
};
export type useMessageUser_GetOrCreateChatMutation$data = {
  readonly getOrCreateChat: {
    readonly chat: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"InboxChats_chats">;
    } | null;
  } | null;
};
export type useMessageUser_GetOrCreateChatMutation = {
  response: useMessageUser_GetOrCreateChatMutation$data;
  variables: useMessageUser_GetOrCreateChatMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "recipientUserId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "recipientUserId",
        "variableName": "recipientUserId"
      }
    ],
    "kind": "ObjectValue",
    "name": "data"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMessageUser_GetOrCreateChatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetOrCreateChatMutation",
        "kind": "LinkedField",
        "name": "getOrCreateChat",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatNode",
            "kind": "LinkedField",
            "name": "chat",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InboxChats_chats"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMessageUser_GetOrCreateChatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetOrCreateChatMutation",
        "kind": "LinkedField",
        "name": "getOrCreateChat",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatNode",
            "kind": "LinkedField",
            "name": "chat",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastMessageSentAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUnreadMessages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "other",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "actor",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isActorInterface"
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastActiveAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "actor",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProfileNode",
                                    "kind": "LinkedField",
                                    "name": "spotifyProfile",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ArtistNode",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CompanyNode",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PartnerNode",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferNode",
                "kind": "LinkedField",
                "name": "mostRecentActionableOffer",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moneyTransferAmountCents",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "transferRecipient",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "stripeConnectedAccountApiId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArtistNode",
                    "kind": "LinkedField",
                    "name": "artist",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acceptedOffersCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abcdd5a271da09d473205060d2ae62ce",
    "id": null,
    "metadata": {},
    "name": "useMessageUser_GetOrCreateChatMutation",
    "operationKind": "mutation",
    "text": "mutation useMessageUser_GetOrCreateChatMutation(\n  $recipientUserId: String!\n) {\n  getOrCreateChat(data: {recipientUserId: $recipientUserId}) {\n    chat {\n      id\n      ...InboxChats_chats\n    }\n  }\n}\n\nfragment AvatarIcon_actor on ActorInterface {\n  __isActorInterface: __typename\n  user {\n    lastActiveAt\n    ...useUserThumbnailUrl_user\n    id\n  }\n}\n\nfragment AvatarName_actor on ActorInterface {\n  __isActorInterface: __typename\n  __typename\n  slug\n  displayName\n  ...AvatarIcon_actor\n  ... on PartnerNode {\n    company {\n      slug\n      displayName\n      id\n    }\n  }\n}\n\nfragment InboxChat_chat on ChatNode {\n  hasUnreadMessages\n  lastMessageSentAt\n  other {\n    actor {\n      __typename\n      ...AvatarName_actor\n      id\n    }\n    id\n  }\n  mostRecentActionableOffer {\n    __typename\n    ...useOfferNextStep_offer\n    id\n  }\n  acceptedOffersCount\n}\n\nfragment InboxChats_chats on ChatNode {\n  id\n  lastMessageSentAt\n  ...InboxChat_chat\n}\n\nfragment useArtistThumbnailUrl_artist on ArtistNode {\n  thumbnailUrl\n  spotifyProfile {\n    thumbnailUrl\n    id\n  }\n}\n\nfragment useOfferNextStep_offer on OfferNode {\n  status\n  moneyTransferAmountCents\n  transferRecipient {\n    __typename\n    user {\n      id\n      stripeConnectedAccountApiId\n    }\n    id\n  }\n  artist {\n    user {\n      id\n    }\n    id\n  }\n}\n\nfragment useUserThumbnailUrl_user on UserNode {\n  actor {\n    __typename\n    thumbnailUrl\n    ...useArtistThumbnailUrl_artist\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "311ffaeec1e52359e6faa8eb08a703d4";

export default node;
