// TODO: improve types such that BreakpointMatchMap gets its breakpoint names from BreakpointMap
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

import breakpointProviderQueries from "../theme/breakpointProviderQueries";

export type BreakpointMatchMap = {
  [key in keyof typeof breakpointProviderQueries]?: boolean;
};

const defaultValue: BreakpointMatchMap = {};

const BreakpointContext = createContext<BreakpointMatchMap>(defaultValue);

function BreakpointProvider({
  children
}: {
  children: ReactNode;
}): ReactElement {
  const [queryMatch, setQueryMatch] = useState<BreakpointMatchMap>({});
  const queries = breakpointProviderQueries;

  useEffect(() => {
    const mediaQueryLists: { [breakpoint: string]: MediaQueryList } = {};
    const keys = Object.keys(queries) as Array<keyof typeof queries>;
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce<BreakpointMatchMap>((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: BreakpointMatchMap = {};
      keys.forEach(media => {
        mediaQueryLists[media] = window.matchMedia(queries[media]);
        matches[media] = mediaQueryLists[media].matches;
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach(media => {
        // This if check is because safari is not good
        if (mediaQueryLists[media].addEventListener != null) {
          mediaQueryLists[media].addEventListener(
            "change",
            handleQueryListener
          );
        } else {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach(media => {
          mediaQueryLists[media].removeEventListener(
            "change",
            handleQueryListener
          );
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
}

function useBreakpoint(): BreakpointMatchMap {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }
  return context;
}

export { BreakpointProvider, useBreakpoint };
