import { ReactElement, ReactNode } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";

interface IRowCellProps {
  children: ReactNode;
  align?: "left" | "right" | "center";
  styles?: ThemeUIStyleObject;
}

function RowCell(props: IRowCellProps): ReactElement {
  const align = props.align ?? "left";
  const justifyContent = (() => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
    }
  })();

  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent,
        height: "64px",
        width: "100%",
        ...props.styles
      }}
    >
      {props.children}
    </Flex>
  );
}

export default RowCell;
