import { ArtistPortfolioPage_offers$key } from "__generated__/ArtistPortfolioPage_offers.graphql";
import { ArtistPortfolioPageQuery as ArtistPortfolioPageQueryType } from "__generated__/ArtistPortfolioPageQuery.graphql";
import { ReactElement } from "react";
import { graphql } from "react-relay";
import {
  PreloadedQuery,
  useFragment,
  usePreloadedQuery
} from "react-relay/hooks";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import ArtistPortfolioRowDeal from "./ArtistPortfolioRowDeal";
import PayoutsSection from "./PayoutsSection";
import WeeklyStreamsSection from "./WeeklyStreamsSection";

export default function ArtistPortfolioPage(props: {
  queryRef: PreloadedQuery<ArtistPortfolioPageQueryType>;
}): ReactElement {
  const { me, portfolio } = usePreloadedQuery<ArtistPortfolioPageQueryType>(
    ArtistPortfolioPageQuery,
    props.queryRef
  );

  const offers = useFragment<ArtistPortfolioPage_offers$key>(
    graphql`
      fragment ArtistPortfolioPage_offers on OfferNode @relay(plural: true) {
        monthlyEarnings {
          edges {
            node {
              __typename
            }
          }
        }
      }
    `,
    portfolio
  );
  const monthlyEarnings = offers.flatMap(offer => offer.monthlyEarnings.edges);

  return (
    <GridItem
      gridColumn={"1 / -1"}
      sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}
    >
      <FixedGrid>
        <GridItem gridColumn={"1 / -1"}>
          <AutoLayout
            spacing={48}
            dependentProps={{ direction: "vertical" }}
            resizingX={FillContainer()}
            my={48}
          >
            <Text variant={"h600"} color={"black100"}>
              Your artist portfolio
            </Text>
            <Divider saturation={"dark"} />
            <WeeklyStreamsSection
              entity={me.artist}
              size={["small", "small", "small", "large"]}
            />
            {monthlyEarnings.length > 0 && <Divider saturation={"dark"} />}
            {monthlyEarnings.length > 0 && <PayoutsSection />}
            <Divider saturation={"dark"} />
            <AutoLayout
              spacing={24}
              resizingX={FillContainer()}
              dependentProps={{ direction: "vertical" }}
            >
              <Text variant={"h400"} color={"black100"}>
                Partnerships
              </Text>
              {portfolio.length === 0 ? (
                <Text
                  variant={"h300"}
                  color={"deepGray100"}
                  sx={{ textAlign: "center", width: "100%" }}
                >
                  {
                    "You haven't accepted any offers yet.  Try messaging some companies to get started!"
                  }
                </Text>
              ) : (
                <Box
                  sx={{
                    backgroundColor: "white100",
                    width: "100%",
                    border: "1px solid",
                    borderColor: "midGray70",
                    borderBottom: "unset"
                  }}
                >
                  {portfolio.map(deal => (
                    <ArtistPortfolioRowDeal key={deal.id} offer={deal} />
                  ))}
                </Box>
              )}
            </AutoLayout>
          </AutoLayout>
        </GridItem>
      </FixedGrid>
    </GridItem>
  );
}

export const ArtistPortfolioPageQuery = graphql`
  query ArtistPortfolioPageQuery {
    me {
      artist {
        ...WeeklyStreamsSection_entity
      }
    }
    portfolio {
      id
      ...ArtistPortfolioPage_offers
      ...ArtistPortfolioRowDeal_offer
    }
  }
`;
