import { InvitedToCompanyTeam_referral$key } from "__generated__/InvitedToCompanyTeam_referral.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";

import { TextFragmentKind } from "../../../../../../utils/textFragments";
import Banner, { BannerSize } from "./Banner";

interface IInvitedToCompanyTeamProps {
  referral: InvitedToCompanyTeam_referral$key;
  size: ResponsiveValue<BannerSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1432%3A24835
 */
function InvitedToCompanyTeam(props: IInvitedToCompanyTeamProps): ReactElement {
  const referral = useFragment(
    graphql`
      fragment InvitedToCompanyTeam_referral on ReferralInterface {
        ... on PartnerToCompanyPartnerReferralNode {
          sender {
            displayName
            thumbnailUrl
          }
        }
        ... on OptionalCompanyInterface {
          company {
            displayName
            thumbnailUrl
          }
        }
      }
    `,
    props.referral
  );

  return (
    <Banner
      size={props.size}
      senders={[referral.sender, referral.company].filter(s => s?.displayName)}
      textFragments={[
        ...(referral.sender?.displayName
          ? [
              {
                kind: "emphasis" as TextFragmentKind,
                text: referral.sender.displayName
              },
              {
                kind: "normal" as TextFragmentKind,
                text: " has invited you to join the "
              }
            ]
          : [
              {
                kind: "normal" as TextFragmentKind,
                text: "You have been invited to join the "
              }
            ]),
        { kind: "emphasis", text: referral.company.displayName },
        { kind: "normal", text: " company on indify." }
      ]}
    />
  );
}

export default InvitedToCompanyTeam;
