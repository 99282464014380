import { ChatThreadHeading_chat$key } from "__generated__/ChatThreadHeading_chat.graphql";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text } from "theme-ui";

import Ic24ArrowLeft from "../../../../../../imgs/icons/ic24-arrow-left.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import Button from "../../../../../components/01_Core/Buttons/Button";
import { ActorAvatarIcon } from "../../../../../components/01_Core/Users/AvatarIcon";
import {
  useActiveResponsiveValue,
  useFindActiveRV
} from "../../../../../utils/responsiveUtils";
import ConditionalLink from "../../../Routing/ConditionalLink";
import { InternalRoutes } from "../../../Routing/InternalRoutes";

type ChatThreadHeadingSize = "small" | "medium" | "large";
interface IChatThreadHeadingProps {
  chat: ChatThreadHeading_chat$key;
  size: ResponsiveValue<ChatThreadHeadingSize>;
}

function getPadding(size: ChatThreadHeadingSize) {
  switch (size) {
    case "large":
    case "medium":
      return "24px";
    case "small":
      return "16px";
  }
}

function getHeadingTextVariant(size: ChatThreadHeadingSize) {
  switch (size) {
    case "large":
    case "medium":
      return "primary.h500";
    case "small":
      return "primary.h400";
  }
}

function ChatThreadHeading(props: IChatThreadHeadingProps): ReactElement {
  const chat = useFragment(
    graphql`
      fragment ChatThreadHeading_chat on ChatNode {
        other {
          userType
          name
          lastActiveAt
          actor {
            ...AvatarIcon_actor
          }
          artist {
            visible
            slug
          }
          partner {
            displayName
            company {
              displayName
              visible
              slug
            }
          }
        }
      }
    `,
    props.chat
  );

  const activeSize = useActiveResponsiveValue(props.size);
  const flexPadding = useFindActiveRV(props.size, getPadding);
  const headingTextVariant = useFindActiveRV(props.size, getHeadingTextVariant);

  const other = chat.other;
  const profileHref =
    other.userType === "artist"
      ? generatePath(InternalRoutes.artistProfile, {
          artistSlug: other.artist.slug
        })
      : generatePath(InternalRoutes.fundProfile, {
          companySlug: other.partner.company.slug
        });
  const profileVisible =
    other.userType === "artist"
      ? chat.other.artist.visible
      : chat.other.partner.company.visible;

  return (
    <Flex
      padding={flexPadding}
      bg={"white100"}
      sx={{
        borderBottom: theme => `1px solid ${theme.colors.midGray70}`,
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      }}
    >
      {/* Left-aligned elements*/}
      <Flex sx={{ alignItems: "center" }}>
        {activeSize === "small" && (
          <Link to={generatePath(InternalRoutes.inbox)}>
            <Box mr={"32px"} sx={{ cursor: "pointer" }}>
              <Ic24ArrowLeft />
            </Box>
          </Link>
        )}
        <Flex sx={{ flexDirection: "column" }}>
          <Text variant={headingTextVariant}>{chat.other.name}</Text>
          {chat.other.userType === "partner" && (
            <Text variant={"secondary.bodyMedium"}>
              {chat.other.partner.company.displayName}
            </Text>
          )}
          {other.lastActiveAt && (
            <Text variant={"secondary.bodyMedium"}>
              Active {dayjs(other.lastActiveAt).fromNow()}
            </Text>
          )}
        </Flex>
      </Flex>

      {/* Right-aligned elements*/}
      <Flex>
        <ConditionalLink
          to={profileHref}
          disabled={!profileVisible}
          style={{ width: "100%" }}
        >
          <Button
            variant={"tertiary"}
            size={"medium"}
            disabled={!profileVisible}
            sx={{ width: "100%" }}
          >
            <AutoLayout
              spacing={8}
              dependentProps={{ direction: "horizontal" }}
            >
              <ActorAvatarIcon size={"extraSmall"} actor={other.actor} />
              <Text>
                {activeSize === "small"
                  ? "View"
                  : `View ${
                      other.userType === "artist" ? "profile" : "company"
                    }`}
              </Text>
            </AutoLayout>
          </Button>
        </ConditionalLink>
      </Flex>
    </Flex>
  );
}

export default ChatThreadHeading;
