import { useOfferName$key } from "__generated__/useOfferName.graphql";
import { Fragment, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { getFeaturedDealTitle } from "./useFeaturedDealTitle";

export function useOfferName(
  offer: useOfferName$key,
  formatted: Parameters<typeof getFeaturedDealTitle>[1]
): ReactNode[] {
  const data = useFragment(
    graphql`
      fragment useOfferName on OfferNode {
        artist {
          displayName
        }
        releases {
          edges {
            node {
              name
              type
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              name
              type
            }
          }
        }
      }
    `,
    offer
  );

  const releases = [data.releases, data.unreleasedReleases]
    .flatMap(a => a.edges)
    .map(e => e.node);

  const pieces = [
    data.artist.displayName,
    " - ",
    ...getFeaturedDealTitle(releases, formatted)
  ];
  if (!formatted) return pieces;
  return pieces.map((piece, i) => <Fragment key={i}>{piece}</Fragment>);
}
