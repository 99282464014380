/**
 * @generated SignedSource<<ba235db602c1d991569d5ace1220f541>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Inbox_authUser$data = {
  readonly numUnreadMessages: number | null;
  readonly " $fragmentType": "Inbox_authUser";
};
export type Inbox_authUser$key = {
  readonly " $data"?: Inbox_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"Inbox_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Inbox_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numUnreadMessages",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "8766de34705a6c0647495062432aa782";

export default node;
