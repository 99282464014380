import { ReactElement } from "react";
import {
  Field as FinalFormField,
  FieldProps,
  FieldRenderProps,
  useField as finalFormUseField,
  UseFieldConfig
} from "react-final-form";

import { FieldPrefixContext } from "../FieldBuilder";

export function useField<FieldValue = any, T extends HTMLElement = HTMLElement>(
  name: string,
  config?: UseFieldConfig<FieldValue>
): FieldRenderProps<FieldValue, T> {
  const mergedConfig = {
    format: (v: any) => v,
    parse: (v: any) => v,
    ...(config ?? {})
  };
  return finalFormUseField(name, mergedConfig);
}

export function Field<
  FieldValue = any,
  RP extends FieldRenderProps<FieldValue, T> = FieldRenderProps<
    FieldValue,
    HTMLElement
  >,
  T extends HTMLElement = HTMLElement
>(props: FieldProps<FieldValue, RP, T>): ReactElement {
  const { name, ...rest } = props;
  return (
    <FieldPrefixContext.Consumer>
      {prefix => {
        const mergedProps = {
          format: (v: any) => v,
          parse: (v: any) => v,
          name: [prefix, name].filter(Boolean).join("."),
          ...rest
        };
        return <FinalFormField {...mergedProps} />;
      }}
    </FieldPrefixContext.Consumer>
  );
}
