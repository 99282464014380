import { ReactElement } from "react";
import { Link, Redirect } from "react-router-dom";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import { useAuth } from "../../hooks/useAuth";
import { InternalRoutes } from "../../Routing/InternalRoutes";

function SignupUserTypeSelect(): ReactElement {
  if (useAuth().authUser) {
    return <Redirect to={"/"} />;
  }

  return (
    <GridItem
      gridColumn={"1 / -1"}
      mt={"64px"}
      sx={{ justifyContent: "center" }}
    >
      <FixedGrid mb={"32px"}>
        <GridItem gridColumn={"1 / -1"}>
          <AutoLayout
            spacing={32}
            dependentProps={{ direction: "vertical", alignment: "center" }}
            resizingX={FillContainer()}
          >
            <Text variant={"h600"}>Sign up as</Text>
            <AutoLayout
              spacing={16}
              dependentProps={[
                { direction: "vertical", alignment: "center" },
                { direction: "vertical", alignment: "center" },
                { direction: "horizontal", alignment: "center" },
                { direction: "horizontal", alignment: "center" }
              ]}
              resizingX={FillContainer()}
            >
              <SignupItem
                route={InternalRoutes.artistSignup}
                label={"Artist"}
                caption={"Navigate your music career"}
              />
              <SignupItem
                route={InternalRoutes.partnerSignup}
                label={"Partner"}
                caption={"Premier artist discovery"}
              />
            </AutoLayout>
            <Link to={InternalRoutes.login}>
              <TextButton size={"medium"} type={"secondary"}>
                Already have an account?
              </TextButton>
            </Link>
          </AutoLayout>
        </GridItem>
      </FixedGrid>
    </GridItem>
  );
}

interface ISignupItemProps {
  route: (typeof InternalRoutes)[keyof typeof InternalRoutes];
  label: string;
  caption: string;
}

function SignupItem(props: ISignupItemProps): ReactElement {
  return (
    <Link to={props.route} style={{ display: "contents" }}>
      <AutoLayout
        spacing={8}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        resizingX={FillContainer()}
        sx={{
          maxWidth: "350px",
          height: "200px",
          backgroundColor: "white100",
          border: "1px solid",
          borderColor: "midGray100",
          borderRadius: "4px",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          "&:hover": {
            borderColor: "black100"
          }
        }}
      >
        <Text variant={"h400"}>{props.label}</Text>
        <Text variant={"bodyMedium"}>{props.caption}</Text>
      </AutoLayout>
    </Link>
  );
}

export default SignupUserTypeSelect;
