import _ from "lodash";
import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import SubtleBadge from "../../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { centsToDollars, formatDollars } from "../../../../../../utils/money";
import { useFindActiveRV } from "../../../../../../utils/responsiveUtils";
import { IFundingOfferFormValues } from "../../../07_Deal_Flow/OfferFormFunding";

type AdvanceAggregateSize = "small" | "medium";
interface IAdvanceAggregateProps {
  placeholder: string;
  size: ResponsiveValue<AdvanceAggregateSize>;
}

function useFundingAmountsDollars() {
  const formState = useFormState<IFundingOfferFormValues>();
  const { advanceAmountCents, marketingAmountCents, creativeAmountCents } =
    formState.values;
  return _.mapValues(
    { advanceAmountCents, marketingAmountCents, creativeAmountCents },
    n => n && centsToDollars(n)
  );
}

export function useTotalAmountDollars(): number {
  return _.sum(Object.values(useFundingAmountsDollars()));
}

/**
 * Displays the total sum being sent by an offer with marketing, advance,
 * and creative amounts.
 * @constructor
 */
function AdvanceAggregate(props: IAdvanceAggregateProps): ReactElement {
  const fundingAmountsDollars = useFundingAmountsDollars();
  const dollars = useTotalAmountDollars();
  const textVariant = useFindActiveRV(props.size, getTextVariant);

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <SubtleBadge size={props.size} color={"blue"} styles={{ width: "100%" }}>
        <Text variant={textVariant} color={"secondary100"} pl={"12px"}>
          $
        </Text>
        <Text
          variant={textVariant}
          color={"secondary100"}
          pl={"4px"}
          pr={"8px"}
        >
          {_.some(fundingAmountsDollars, _.isNumber)
            ? formatDollars(dollars)
            : props.placeholder}
        </Text>
      </SubtleBadge>
    </Box>
  );
}

function getTextVariant(size: AdvanceAggregateSize) {
  switch (size) {
    case "small":
      return "bodySmall";
    case "medium":
      return "bodyMedium";
  }
}

export default AdvanceAggregate;
