import { ReactElement, ReactNode } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import ColumnHeader, {
  IColumnHeaderProps
} from "../../../../components/01_Core/Tables/ColumnHeader";
import { FilterRanges } from "./FilterRanges";

export default function FilterRangesWithHeader<
  FilterRangeOptions extends { [key: string]: readonly string[] }
>(props: {
  label: ReactNode;
  settings: FilterRangeOptions;
  settingName: keyof FilterRangeOptions & string;
  columnHeaderProps: Omit<IColumnHeaderProps, "children">;
}): ReactElement {
  return (
    <AutoLayout
      dependentProps={{ direction: "vertical" }}
      spacing={8}
      resizingX={FillContainer()}
    >
      <ColumnHeader {...props.columnHeaderProps}>{props.label}</ColumnHeader>
      <FilterRanges settings={props.settings} settingName={props.settingName} />
    </AutoLayout>
  );
}
