import { Properties } from "csstype";
import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex, FlexProps } from "theme-ui";

import { useActiveResponsiveValue } from "../../../utils/responsiveUtils";

export interface GridItemProps extends FlexProps {
  gridTemplateAreas?: ResponsiveValue<Properties["gridTemplateAreas"]>;
  gridColumn?: ResponsiveValue<Properties["gridColumn"]>;
  gridRow?: ResponsiveValue<Properties["gridRow"]>;
  gridArea?: ResponsiveValue<Properties["gridArea"]>;
  display?: ResponsiveValue<Properties["display"]>;
  children?: ReactNode;
}

export function GridItem(props: GridItemProps): ReactElement {
  const {
    gridTemplateAreas,
    gridColumn,
    gridRow,
    gridArea,
    display,
    sx,
    children,
    ...flexProps
  } = props;
  if (gridTemplateAreas) {
    // Return null if gridArea not in gridTemplateAreas
    const gridAreaBoundedRegex = new RegExp(`\\b${gridArea}\\b`);
    if (
      !gridAreaBoundedRegex.test(useActiveResponsiveValue(gridTemplateAreas))
    ) {
      return null;
    }
  }
  return (
    <Flex {...flexProps} sx={{ gridColumn, gridRow, gridArea, display, ...sx }}>
      {children}
    </Flex>
  );
}
