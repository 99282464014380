import { ArtistDirectoryCards_allArtists$key } from "__generated__/ArtistDirectoryCards_allArtists.graphql";
import { ArtistDirectoryQuery as ArtistDirectoryQueryType } from "__generated__/ArtistDirectoryQuery.graphql";
import { ArtistListPaginationQuery } from "__generated__/ArtistListPaginationQuery.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  graphql,
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery
} from "react-relay";
import { Flex, Grid, Text } from "theme-ui";

import { Loader } from "../../../../components/01_Core/999_Miscellaneous/Loader";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";
import ArtistDirectoryCard, {
  PlaceholderArtistDirectoryCard
} from "../03_UI_Kit/Discover/ArtistDirectoryCard";
import { ArtistOrderingOption } from "../03_UI_Kit/Discover/ArtistDirectorySearchSort";
import { ArtistDirectoryQuery } from "./ArtistDirectory";
import { IArtistsFilterForm } from "./ArtistDirectoryFilters";

export interface IArtistDirectoryCardsProps {
  queryRef: PreloadedQuery<ArtistDirectoryQueryType>;
  displayName_Icontains: string;
}

const numColumns = [1, 2, 2, 3];

export function PlaceholderArtistDirectoryCards(): ReactElement {
  return (
    <Grid
      gap={["16px", "16px", "24px", "32px"]}
      columns={`repeat(${useActiveResponsiveValue(numColumns)}, 1fr)`}
      paddingBottom={"200px"}
      sx={{
        width: "100%",
        paddingY: ["24px", "24px", "32px", "32px"]
      }}
    >
      {_.range(6).map(n => (
        <PlaceholderArtistDirectoryCard key={n} />
      ))}
    </Grid>
  );
}

function ArtistDirectoryCards(props: IArtistDirectoryCardsProps): ReactElement {
  const root = usePreloadedQuery<ArtistDirectoryQueryType>(
    ArtistDirectoryQuery,
    props.queryRef
  );

  const {
    data: refetchableData,
    loadNext,
    hasNext,
    refetch
  } = usePaginationFragment<
    ArtistListPaginationQuery,
    ArtistDirectoryCards_allArtists$key
  >(
    graphql`
      fragment ArtistDirectoryCards_allArtists on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 6 }
        cursor: { type: "String" }
        orderBy: { type: "ArtistOrdering", defaultValue: growth_score_desc }
        displayName_Icontains: { type: "String", defaultValue: "" }
        onlyWatching: { type: "Boolean", defaultValue: false }
        genres: { type: "[ID]" }
        partnershipStatuses: { type: "[ArtistPartnershipStatusFilter]" }
        seekingServices: { type: "[ArtistSeekingServiceServiceFilter]" }
        streamsThisWeek: { type: "[ArtistStreamsThisWeekRange]" }
        spotifyMonthlyListeners: {
          type: "[ArtistSpotifyMonthlyListenersRange]"
        }
        tiktokPosts: { type: "[ArtistTiktokPostsRange]" }
        tiktokFollowers: { type: "[ArtistTiktokFollowersRange]" }
        instagramFollowers: { type: "[ArtistInstagramFollowersRange]" }
      )
      @refetchable(queryName: "ArtistListPaginationQuery") {
        allArtists(
          first: $count
          after: $cursor
          orderBy: $orderBy
          displayName_Icontains: $displayName_Icontains
          onlyWatching: $onlyWatching
          genres: $genres
          partnershipStatuses: $partnershipStatuses
          seekingServices: $seekingServices
          streamsThisWeek: $streamsThisWeek
          spotifyMonthlyListeners: $spotifyMonthlyListeners
          tiktokPosts: $tiktokPosts
          tiktokFollowers: $tiktokFollowers
          instagramFollowers: $instagramFollowers
        ) @connection(key: "ArtistList_allArtists") {
          edges {
            node {
              id
              ...ArtistDirectoryCard_artist
            }
          }
        }
      }
    `,
    root
  );

  const filterSettings = useFormState<IArtistsFilterForm>().values;
  const filterValues = _.mapValues(
    filterSettings,
    (value, key: keyof IArtistsFilterForm) => {
      switch (key) {
        case "orderBy":
          return (value as ArtistOrderingOption).value;
        case "partnershipStatuses":
        case "seekingServices":
          return (
            value as IArtistsFilterForm[
              | "partnershipStatuses"
              | "seekingServices"]
          ).map(v => v.value);
        case "genres":
          return (value as IArtistsFilterForm["genres"]).map(v => v.label);
        case "streamsThisWeek":
        case "spotifyMonthlyListeners":
        case "tiktokPosts":
        case "tiktokFollowers":
        case "instagramFollowers":
          return Object.keys(value).filter(
            k => value[k as keyof typeof value]
          ) as any;
      }
    }
  );

  const fetchProps = {
    ...filterValues,
    ...props
  };

  useDidUpdateEffect(() => {
    refetch(fetchProps);
  }, [filterSettings, props.displayName_Icontains]);

  return (
    <InfiniteScroll
      next={() => loadNext(6)}
      hasMore={hasNext}
      loader={
        <Flex
          my={"24px"}
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </Flex>
      }
      dataLength={refetchableData.allArtists.edges.length}
      style={{ overflow: "hidden", width: "100%" }}
      endMessage={
        <Flex
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "32px"
          }}
        >
          <Text variant={"bodyMedium"} color={"deepGray100"}>
            {refetchableData.allArtists.edges.length
              ? "You've reached the end of the list."
              : "No artists found. Try adjusting your filters."}
          </Text>
        </Flex>
      }
    >
      <Grid
        gap={["16px", "16px", "24px", "32px"]}
        columns={`repeat(${useActiveResponsiveValue(numColumns)}, 1fr)`}
        paddingBottom={"200px"}
        sx={{
          width: "100%",
          paddingY: ["24px", "24px", "32px", "32px"]
        }}
      >
        {[
          ...refetchableData.allArtists.edges
            .map(e => e.node)
            .map(a => <ArtistDirectoryCard key={a.id} artist={a} />)
        ]}
      </Grid>
    </InfiniteScroll>
  );
}

export default ArtistDirectoryCards;
