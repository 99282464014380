/**
 * @generated SignedSource<<ae98c206141234d3719509b43993b7df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompanyServiceService = "CONTENT_PRODUCTION" | "DISTRIBUTION" | "FUNDING" | "INDUSTRY" | "INFLUENCER" | "MARKETING";
import { FragmentRefs } from "relay-runtime";
export type CompanyInfo_company$data = {
  readonly about: string | null;
  readonly caseStudyOffer: {
    readonly id: string;
  } | null;
  readonly displayName: string;
  readonly genres: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly id: string;
  readonly location: string | null;
  readonly recipientPartner: {
    readonly id: string;
  } | null;
  readonly services: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly service: CompanyServiceService;
      } | null;
    } | null>;
  };
  readonly slug: string;
  readonly thumbnailUrl: string;
  readonly websiteUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CaseStudyOffer_company" | "RecipientPartner_company">;
  readonly " $fragmentType": "CompanyInfo_company";
};
export type CompanyInfo_company$key = {
  readonly " $data"?: CompanyInfo_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyInfo_company">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyInfo_company",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyServiceNodeConnection",
      "kind": "LinkedField",
      "name": "services",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyServiceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyServiceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "service",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GenreNodeConnection",
      "kind": "LinkedField",
      "name": "genres",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GenreNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GenreNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferNode",
      "kind": "LinkedField",
      "name": "caseStudyOffer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CaseStudyOffer_company"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "recipientPartner",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecipientPartner_company"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "websiteUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "about",
      "storageKey": null
    }
  ],
  "type": "CompanyNode",
  "abstractKey": null
};
})();

(node as any).hash = "6386ef5b9ec5062a78128e8792925b6c";

export default node;
