import { Path } from "history";
import { ReactElement, ReactNode } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Box } from "theme-ui";

import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import { useAuth } from "../../../hooks/useAuth";

export interface ILocationState {
  from?: Path;
}

interface ILoginPageProps {
  children: ReactNode;
}

function LoginPage(props: ILoginPageProps): ReactElement {
  const { authUser } = useAuth();
  const location = useLocation<ILocationState>();

  if (authUser) {
    return <Redirect to={location.state?.from ?? "/"} />;
  }

  const gridColumns = ["1 / span 4", "2 / span 6", "2 / span 6", "4 / span 6"];

  return (
    <GridItem
      gridColumn={gridColumns}
      mt={"64px"}
      pb={"64px"}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box
        px={[18, 0]}
        sx={{
          borderRadius: "4px",
          width: ["unset", 448]
        }}
      >
        {props.children}
      </Box>
    </GridItem>
  );
}

export default LoginPage;
