import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import { NodeId } from "../../../types/relay_types";
import { useFindActiveRV } from "../../../utils/responsiveUtils";
import { lineClampStyles } from "../../../utils/utils";
import AvatarName from "./AvatarName";
import MultipleAvatars from "./MultipleAvatars";

export type AvatarUser = {
  id: NodeId;
  name: string;
  imageUrl: string;
};

type MultipleAvatarsNamesSize =
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";

interface IMultipleAvatarsNamesProps {
  size: ResponsiveValue<MultipleAvatarsNamesSize>;
  users: AvatarUser[];
  numNames?: number;
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1435%3A184
 */
function MultipleAvatarsNames(props: IMultipleAvatarsNamesProps): ReactElement {
  if (props.users.length === 0) {
    return null;
  } else if (props.users.length === 1) {
    return (
      <AvatarName
        imageUrl={props.users[0].imageUrl}
        size={props.size}
        displayName={props.users[0].name}
      />
    );
  }

  const textVariant = useFindActiveRV(props.size, getTextVariant);

  function getTextVariant(size: MultipleAvatarsNamesSize) {
    switch (size) {
      case "extraLarge":
        return "primary.subtitle";
      case "large":
      case "medium":
        return "primary.bodyLarge";
      case "small":
      case "extraSmall":
        return "primary.bodyMedium";
    }
  }

  function getNamesString() {
    const numNames = props.numNames ?? 2;
    switch (props.users.length) {
      case 2:
        return `${props.users[0].name} and ${props.users[1].name}`;
      default: {
        const numNotShown = props.users.length - numNames;
        return `${props.users
          .slice(0, numNames)
          .map(u => u.name)
          .join(", ")}${numNotShown > 0 ? ` + ${numNotShown}` : ""}`;
      }
    }
  }

  // TODO: sort the users
  return (
    <Flex sx={{ alignItems: "center" }}>
      <MultipleAvatars avatars={props.users} size={props.size} />
      <Text variant={textVariant} sx={lineClampStyles(2)}>
        {getNamesString()}
      </Text>
    </Flex>
  );
}

export default MultipleAvatarsNames;
