/**
 * @generated SignedSource<<3ed8ae3dacb23741356d286d4fa8263b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TikTokData_featuredDeal$data = {
  readonly artist: {
    readonly tiktokProfile: {
      readonly tiktokProfileData: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly date: any;
            readonly followerCount: number | null;
          } | null;
        } | null>;
      };
    } | null;
  };
  readonly tiktokSounds: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly data: ReadonlyArray<{
          readonly date: any;
          readonly value: number;
        }>;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "TikTokData_featuredDeal";
};
export type TikTokData_featuredDeal$key = {
  readonly " $data"?: TikTokData_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"TikTokData_featuredDeal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TikTokData_featuredDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TikTokSoundNodeConnection",
      "kind": "LinkedField",
      "name": "tiktokSounds",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TikTokSoundNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TikTokSoundNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TikTokSoundDataNode",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "tiktokProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TikTokProfileDataNodeConnection",
              "kind": "LinkedField",
              "name": "tiktokProfileData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TikTokProfileDataNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TikTokProfileDataNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "followerCount",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};
})();

(node as any).hash = "2cae361f5f8b9736398f7f2a746a9556";

export default node;
