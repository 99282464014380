/**
 * @generated SignedSource<<819d6f4eb271273a0f83c0a180fe4054>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WhyInvest_featuredDeal$data = {
  readonly distributionAgreement: string | null;
  readonly distributor: string | null;
  readonly editorialNote: string | null;
  readonly lastApprovedAt: any | null;
  readonly producerAgreement: string | null;
  readonly whyInvestNow: string | null;
  readonly " $fragmentType": "WhyInvest_featuredDeal";
};
export type WhyInvest_featuredDeal$key = {
  readonly " $data"?: WhyInvest_featuredDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"WhyInvest_featuredDeal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WhyInvest_featuredDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whyInvestNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editorialNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "distributor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "distributionAgreement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "producerAgreement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastApprovedAt",
      "storageKey": null
    }
  ],
  "type": "FeaturedDealNode",
  "abstractKey": null
};

(node as any).hash = "8efd0a57b7175fede9d7678a40eb8820";

export default node;
