/**
 * @generated SignedSource<<781b8fbec6dd2d02c39d077e0a8150f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioRowDeal_offer$data = {
  readonly pdfUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageBody_offer">;
  readonly " $fragmentType": "PortfolioRowDeal_offer";
};
export type PortfolioRowDeal_offer$key = {
  readonly " $data"?: PortfolioRowDeal_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioRowDeal_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioRowDeal_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdfUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferMessageBody_offer"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "a68dc56d899bcf9e28737fde28cb5b21";

export default node;
