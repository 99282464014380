import { ChangeEvent, ReactElement } from "react";
import { Input as ThemeUIInput } from "theme-ui";

import Input, { IInputElementProps, InputProps } from "../Input";

function PasswordInput(
  props: InputProps<HTMLInputElement, string | null>
): ReactElement {
  return <Input InputComponent={BasePasswordInput} {...props} />;
}

function BasePasswordInput(
  props: IInputElementProps<HTMLInputElement, string | null>
) {
  const {
    input: { value, onChange, ...inputRest },
    meta,
    ...rest
  } = props;

  const serializingOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v: string = e.target.value;
    onChange(v === "" ? null : v);
  };

  const serializedValue = value?.toString();

  return (
    <ThemeUIInput
      {...rest}
      {...inputRest}
      value={serializedValue}
      onChange={serializingOnChange}
      type="password"
    />
  );
}

export default PasswordInput;
