/**
 * @generated SignedSource<<a582a2c5bea7e6b5d45b2ff09dc7fb09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePasswordFormMutation$variables = {
  currentPassword: string;
  newPassword: string;
};
export type UpdatePasswordFormMutation$data = {
  readonly updatePassword: {
    readonly ok: boolean | null;
  } | null;
};
export type UpdatePasswordFormMutation = {
  response: UpdatePasswordFormMutation$data;
  variables: UpdatePasswordFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentPassword"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newPassword"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currentPassword",
        "variableName": "currentPassword"
      },
      {
        "kind": "Variable",
        "name": "newPassword",
        "variableName": "newPassword"
      }
    ],
    "concreteType": "UpdatePasswordMutation",
    "kind": "LinkedField",
    "name": "updatePassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePasswordFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePasswordFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bbdd8da467bcad96c238436081e2136f",
    "id": null,
    "metadata": {},
    "name": "UpdatePasswordFormMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePasswordFormMutation(\n  $currentPassword: String!\n  $newPassword: String!\n) {\n  updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "2796680820d3b22f7cfbe6e92687e2cc";

export default node;
