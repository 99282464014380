/**
 * @generated SignedSource<<0ed10c1b3b6adee85842bc0e5abf2700>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistManageReleasesRefreshQuery$variables = {
  id: string;
};
export type ArtistManageReleasesRefreshQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ArtistManageReleases_artist">;
  } | null;
};
export type ArtistManageReleasesRefreshQuery = {
  response: ArtistManageReleasesRefreshQuery$data;
  variables: ArtistManageReleasesRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000
},
v5 = [
  (v3/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "releaseType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "releaseDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVisible",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tracksCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canDelete",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "soundLink",
        "storageKey": null
      }
    ],
    "type": "ReleaseInterface",
    "abstractKey": "__isReleaseInterface"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArtistManageReleasesRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArtistManageReleases_artist"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArtistManageReleasesRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  (v4/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "-releaseDate"
                  }
                ],
                "concreteType": "ReleaseNodeConnection",
                "kind": "LinkedField",
                "name": "releasesPrefetch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReleaseNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "releasesPrefetch(first:1000,orderBy:\"-releaseDate\")"
              },
              {
                "alias": null,
                "args": [
                  (v4/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "-createdAt"
                  }
                ],
                "concreteType": "UnreleasedReleaseNodeConnection",
                "kind": "LinkedField",
                "name": "unreleasedReleasesPrefetch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnreleasedReleaseNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnreleasedReleaseNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "unreleasedReleasesPrefetch(first:1000,orderBy:\"-createdAt\")"
              }
            ],
            "type": "ArtistNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d441c27a7f94d5b407cf4341fbbac574",
    "id": null,
    "metadata": {},
    "name": "ArtistManageReleasesRefreshQuery",
    "operationKind": "query",
    "text": "query ArtistManageReleasesRefreshQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ArtistManageReleases_artist\n    id\n  }\n}\n\nfragment ArtistManageReleases_artist on ArtistNode {\n  id\n  releasesPrefetch(orderBy: \"-releaseDate\", first: 1000) {\n    edges {\n      node {\n        ...ArtistManageReleases_releasesSection\n        id\n      }\n    }\n  }\n  unreleasedReleasesPrefetch(orderBy: \"-createdAt\", first: 1000) {\n    edges {\n      node {\n        ...ArtistManageReleases_releasesSection\n        id\n      }\n    }\n  }\n}\n\nfragment ArtistManageReleases_releasesSection on ReleaseInterface {\n  __isReleaseInterface: __typename\n  __typename\n  id\n  releaseType\n  releaseDate\n  updatedAt\n  ...ReleaseListItem_release\n}\n\nfragment ReleaseDetails_release on ReleaseInterface {\n  __isReleaseInterface: __typename\n  isVisible\n  name\n  ...useReleaseMetadata_release\n}\n\nfragment ReleaseListItem_release on ReleaseInterface {\n  __isReleaseInterface: __typename\n  thumbnailUrl\n  isVisible\n  ...ReleaseDetails_release\n  ...ReleaseStatusMenu_release\n}\n\nfragment ReleaseStatusMenu_release on ReleaseInterface {\n  __isReleaseInterface: __typename\n  __typename\n  id\n  isVisible\n  canDelete\n  ...UpdateOrCreateReleaseModal_release\n}\n\nfragment UpdateOrCreateReleaseModal_release on ReleaseInterface {\n  __isReleaseInterface: __typename\n  __typename\n  id\n  name\n  releaseType\n  soundLink\n  isVisible\n  tracksCount\n}\n\nfragment useReleaseMetadata_release on ReleaseInterface {\n  __isReleaseInterface: __typename\n  releaseType\n  tracksCount\n  releaseDate\n}\n"
  }
};
})();

(node as any).hash = "862897c41321e5335e646630d4dc6c5b";

export default node;
