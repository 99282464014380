import { ArtistManageDeals_artist$key } from "__generated__/ArtistManageDeals_artist.graphql";
import { ArtistManageDeals_featuredDeals$key } from "__generated__/ArtistManageDeals_featuredDeals.graphql";
import _ from "lodash";
import { Fragment, ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import Ic20PlusCircle from "../../../../../imgs/icons/ic20-plus-circle.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import FullScreenModal from "../03_UI_Kit/Modals/FullScreenModal";
import DealManageable from "../06_Deal_Merchandising/DealManageable";
import DealMerchandisingFormPage, {
  IFeaturedDealFormValues
} from "../06_Deal_Merchandising/DealMerchandisingFormPage";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";

interface IArtistManageDealsProps {
  artist: ArtistManageDeals_artist$key;
  featuredDeals: ArtistManageDeals_featuredDeals$key;
}

interface IArtistManageDealsLocationState {
  isDraftingDeal: boolean;
  featuredDealId?: string;
  initialValues?: Partial<IFeaturedDealFormValues>;
}

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=2810%3A27229
 */
function ArtistManageDeals(props: IArtistManageDealsProps): ReactElement {
  const location = useLocation<IArtistManageDealsLocationState>();
  const history = useHistory();
  const isDraftingDeal = location.state?.isDraftingDeal ?? false;
  const initialValues = location.state?.initialValues ?? null;
  const featuredDealId = location.state?.featuredDealId ?? null;

  const setIsDraftingDeal = (
    isOpen: boolean,
    featuredDealId: string = null,
    initialValues: Partial<IFeaturedDealFormValues> = null
  ) => {
    const locationState: IArtistManageDealsLocationState = {
      isDraftingDeal: isOpen,
      featuredDealId,
      initialValues
    };
    history.replace(generatePath(location.pathname), locationState);
  };

  const openModal = (
    featuredDealId: string = null,
    initialValues: Partial<IFeaturedDealFormValues> = null
  ) => {
    setIsDraftingDeal(true, featuredDealId, initialValues);
  };

  const closeModal = () => {
    setIsDraftingDeal(false);
  };

  const artist = useFragment(
    graphql`
      fragment ArtistManageDeals_artist on ArtistNode {
        managerName
        managerLink
        managerCompany
        managerEmail
        ...DealMerchandisingFormPage_artist
      }
    `,
    props.artist
  );

  const managerFields = {
    managerName: artist.managerName,
    managerCompany: artist.managerCompany,
    managerEmail: artist.managerEmail,
    managerLink: artist.managerLink
  };

  const onClickAddNewDeal = () =>
    openModal(undefined, {
      allReleases: [],
      ...managerFields
    });

  const featuredDeals = useFragment(
    graphql`
      fragment ArtistManageDeals_featuredDeals on FeaturedDealNode
      @relay(plural: true) {
        id
        inPocketAmountRaising
        marketingAmountRaising
        creativeAmountRaising
        soundLink
        tiktokSounds {
          edges {
            node {
              id
            }
          }
        }
        whyInvestNow
        partnerSplitPreRecoupment
        partnerSplitPostRecoupment
        termLengthMonths
        soundRecordings {
          edges {
            node {
              id
            }
          }
        }
        releases {
          edges {
            node {
              id
              __typename
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              id
              __typename
            }
          }
        }
        ...DealManageable_featuredDeal
      }
    `,
    props.featuredDeals
  );

  return (
    <Fragment>
      <FullScreenModal isOpen={isDraftingDeal} onClose={closeModal}>
        <DealMerchandisingFormPage
          artist={artist}
          closeModal={closeModal}
          initialValues={initialValues}
          featuredDealId={featuredDealId}
        />
      </FullScreenModal>
      <AccountManagementFormContent>
        <AccountManagementHeading
          size={["small", "large", "large", "large"]}
          title={"Deal Proposals"}
          description={
            "Manage your deal proposals for potential funding partners."
          }
          moreDescription={[
            "Building a deal proposal is the best way to get offers from partners on indify. A proposal allows you to show off your music, demonstrate your traction on social media, suggest terms that make sense for you, and establish a starting point for negotiations with partners.",
            "Rest assured that you'll have time to evaluate and negotiate any offers sent to you before a partnership is finalized."
          ]}
        />
        <AutoLayout
          spacing={24}
          resizingX={FillContainer()}
          dependentProps={{ direction: "vertical" }}
        >
          <TextButton
            onClick={onClickAddNewDeal}
            size={"medium"}
            type={"secondary"}
            iconProps={{ placement: "left", icon: <Ic20PlusCircle /> }}
          >
            Add a proposed deal
          </TextButton>
          <AutoLayout
            spacing={16}
            resizingX={FillContainer()}
            dependentProps={{ direction: "vertical" }}
          >
            {featuredDeals.map(featuredDeal => {
              const editDealFormInitialValues: IFeaturedDealFormValues = {
                service: "funding",
                advanceAmountCents:
                  featuredDeal.inPocketAmountRaising &&
                  featuredDeal.inPocketAmountRaising * 100,
                marketingAmountCents:
                  featuredDeal.marketingAmountRaising &&
                  featuredDeal.marketingAmountRaising * 100,
                creativeAmountCents:
                  featuredDeal.creativeAmountRaising &&
                  featuredDeal.creativeAmountRaising * 100,
                soundLink: featuredDeal.soundLink,
                ...managerFields,
                pitch: featuredDeal.whyInvestNow,
                termLength: featuredDeal.termLengthMonths,
                tikTokSoundIds: featuredDeal.tiktokSounds.edges.map(
                  ({ node }) => node.id
                ),
                soundRecordingIds: _.mapValues(
                  _.keyBy(
                    featuredDeal.soundRecordings.edges,
                    ({ node: { id } }) => id
                  ),
                  () => true
                ),
                allReleases: [
                  ...featuredDeal.releases.edges.map(({ node }) => {
                    return { typename: node.__typename, id: node.id };
                  }),
                  ...featuredDeal.unreleasedReleases.edges.map(({ node }) => {
                    return { typename: node.__typename, id: node.id };
                  })
                ],
                ...(featuredDeal.partnerSplitPreRecoupment !== null && {
                  splitBeforeRecoupment: {
                    partnerPercent: featuredDeal.partnerSplitPreRecoupment
                  }
                }),
                ...(featuredDeal.partnerSplitPostRecoupment !== null && {
                  splitAfterRecoupment: {
                    partnerPercent: featuredDeal.partnerSplitPostRecoupment
                  }
                }),
                releaseHasIncludedTracks: {}
              };
              const onClickEditDeal = () =>
                openModal(featuredDeal.id, editDealFormInitialValues);

              return (
                <DealManageable
                  key={featuredDeal.id}
                  featuredDeal={featuredDeal}
                  onClickEditDeal={onClickEditDeal}
                  size={["small", "small", "small", "large"]}
                />
              );
            })}
          </AutoLayout>
        </AutoLayout>
      </AccountManagementFormContent>
    </Fragment>
  );
}

export default ArtistManageDeals;
