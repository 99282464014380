import { TeamList_offers$key } from "__generated__/TeamList_offers.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Flex } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { ActorAvatarName } from "../../../../components/01_Core/Users/AvatarName";
import { rvMap } from "../../../../utils/responsiveUtils";
import { useAuth } from "../../hooks/useAuth";
import useFeaturedDealTitle from "../../hooks/useFeaturedDealTitle";

type TeamListSize = "small" | "medium" | "large";

interface ITeamListProps {
  size: ResponsiveValue<TeamListSize>;
  offers: TeamList_offers$key;
}

function TeamList(props: ITeamListProps): ReactElement {
  const offers = useFragment(
    graphql`
      fragment TeamList_offers on OfferNode @relay(plural: true) {
        id
        company {
          displayName
        }
        partner {
          id
          displayName
          ...AvatarName_actor
        }
        artist {
          id
          ...AvatarName_actor
        }
        ...useFeaturedDealTitle
      }
    `,
    props.offers
  );
  const { authUser } = useAuth();
  const { userType } = authUser;
  return (
    <AutoLayout
      spacing={24}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      {offers.map(offer => {
        const { artist, partner, company } = offer;
        const teamMember = userType === "artist" ? partner : artist;
        const displayName =
          userType === "artist"
            ? `${partner.displayName} (${company.displayName})`
            : undefined;
        const caption = useFeaturedDealTitle(offer);
        return (
          <Flex key={teamMember.id} sx={{ justifyContent: "center" }}>
            <ActorAvatarName
              actor={teamMember}
              size={rvMap(props.size, getLabelledAvatarSize)}
              displayName={displayName}
              caption={caption}
            />
          </Flex>
        );
      })}
    </AutoLayout>
  );
}

function getLabelledAvatarSize(size: TeamListSize) {
  switch (size) {
    case "large":
      return "large";
    case "medium":
      return "medium";
    case "small":
      return "small";
  }
}

export default TeamList;
