/**
 * @generated SignedSource<<d7d8d6872b8a7023f92a629ef69ae3aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserStatus = "ACTIVE" | "DISABLED" | "INACTIVE" | "PENDING";
import { FragmentRefs } from "relay-runtime";
export type ReferralRecipientActive_recipient$data = {
  readonly dateJoined: any;
  readonly user: {
    readonly status: UserStatus;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
  readonly " $fragmentType": "ReferralRecipientActive_recipient";
};
export type ReferralRecipientActive_recipient$key = {
  readonly " $data"?: ReferralRecipientActive_recipient$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralRecipientActive_recipient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralRecipientActive_recipient",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarName_actor"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateJoined",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActorInterface",
  "abstractKey": "__isActorInterface"
};

(node as any).hash = "d3b2d77bb2d0bccdb57b8c1da5a47808";

export default node;
