/**
 * @generated SignedSource<<e3ed695134194fd2501bcf0877fb7975>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WeeklyStreamsSection_entity$data = {
  readonly partnershipStreamsByWeek?: ReadonlyArray<{
    readonly streams: number | null;
    readonly weekEndDay: any | null;
    readonly weekStartDay: any | null;
  } | null> | null;
  readonly " $fragmentType": "WeeklyStreamsSection_entity";
};
export type WeeklyStreamsSection_entity$key = {
  readonly " $data"?: WeeklyStreamsSection_entity$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeeklyStreamsSection_entity">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WeeklyStreams",
    "kind": "LinkedField",
    "name": "partnershipStreamsByWeek",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weekStartDay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weekEndDay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "streams",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeeklyStreamsSection_entity",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "CompanyNode",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "ArtistNode",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
})();

(node as any).hash = "66f225fd07d2266d445847aa5c497c27";

export default node;
