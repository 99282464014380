import { InboxChat_chat$key } from "__generated__/InboxChat_chat.graphql";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Text } from "theme-ui";

import Ic24Difference from "../../../../../../imgs/icons/ic24-difference.svg";
import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import LabelDot from "../../../../../components/01_Core/Dropdown_Menus/LabelDot";
import { ActorAvatarName } from "../../../../../components/01_Core/Users/AvatarName";
import { useFindActiveRV } from "../../../../../utils/responsiveUtils";
import useOfferNextStep from "../../../hooks/useOfferNextStep";
import { actionBorderSx } from "../Discover/RowDeal";
import OfferNextStepBadge from "./OfferNextStep/OfferNextStapBadge";

type InboxChatSize = "small" | "medium";
interface IInboxChat {
  chat: InboxChat_chat$key;
  state: "normal" | "active";
  size: ResponsiveValue<"small" | "medium">;
  onClick?: () => void;
}

function getPadding(size: InboxChatSize) {
  switch (size) {
    case "small":
      return "8px";
    case "medium":
      return "16px";
  }
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?type=design&node-id=621%3A1649&t=m8U3ikbhWDtzseZ4-1
 */
function InboxChat(props: IInboxChat): ReactElement {
  const chat = useFragment(
    graphql`
      fragment InboxChat_chat on ChatNode {
        hasUnreadMessages
        lastMessageSentAt
        other {
          actor {
            ...AvatarName_actor
          }
        }
        mostRecentActionableOffer {
          __typename
          ...useOfferNextStep_offer
        }
        acceptedOffersCount
      }
    `,
    props.chat
  );

  const flexPadding = useFindActiveRV(props.size, getPadding);

  const offerNextStep = useOfferNextStep(chat.mostRecentActionableOffer);

  return (
    <AutoLayout
      spacing={8}
      dependentProps={{ direction: "vertical" }}
      padding={flexPadding}
      bg={"white100"}
      onClick={props.onClick}
      mb={"8px"}
      sx={{
        width: "100%",
        flexDirection: "column",
        borderRadius: "8px",
        border: "1px solid",
        ...actionBorderSx(),
        borderColor: "midGray70",
        ...(props.state === "active" && { borderColor: "black100" })
      }}
    >
      <AutoLayout
        spacing={8}
        dependentProps={{ direction: "horizontal" }}
        sx={{ width: "100%", alignItems: "center" }}
      >
        <ActorAvatarName actor={chat.other.actor} size={"small"} disableLink />
        {chat.lastMessageSentAt && (
          <AutoLayout
            spacing={8}
            dependentProps={{ direction: "horizontal" }}
            sx={{ alignItems: "center", marginLeft: "auto" }}
          >
            <Text variant={"secondary.bodySmall"}>
              {dayjs(chat.lastMessageSentAt).fromNow()}
            </Text>
            {chat.hasUnreadMessages && <LabelDot color={"blue"} mx={"8px"} />}
          </AutoLayout>
        )}
      </AutoLayout>
      {chat.mostRecentActionableOffer != null ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <OfferNextStepBadge
            type={offerNextStep}
            subtleBadgeProps={{ size: "small", color: "blue" }}
          />
        </Box>
      ) : (
        !!chat.acceptedOffersCount && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <SubtleBadge
              size={"small"}
              color={"stroked"}
              icon={
                <Ic24Difference style={{ width: "1.1em", height: "1.1em" }} />
              }
            >
              {chat.acceptedOffersCount > 1
                ? `${chat.acceptedOffersCount} active deals`
                : "Active deal"}
            </SubtleBadge>
          </Box>
        )
      )}
    </AutoLayout>
  );
}

export default InboxChat;
