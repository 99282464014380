/**
 * @generated SignedSource<<2fba2babd44eb91da042760697796466>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IndifyToArtistReferralFlow_referral$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Page_referral">;
  readonly " $fragmentType": "IndifyToArtistReferralFlow_referral";
};
export type IndifyToArtistReferralFlow_referral$key = {
  readonly " $data"?: IndifyToArtistReferralFlow_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"IndifyToArtistReferralFlow_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndifyToArtistReferralFlow_referral",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Page_referral"
    }
  ],
  "type": "IndifyToArtistReferralNode",
  "abstractKey": null
};

(node as any).hash = "7fd6d83ec293a2eaef36ed1af5305df8";

export default node;
