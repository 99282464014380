import {
  useSubscribedUser$data,
  useSubscribedUser$key
} from "__generated__/useSubscribedUser.graphql";
import { graphql, useFragment } from "react-relay";

import { useAuth } from "./useAuth";

export default function useSubscribedUser(): useSubscribedUser$data["partner"]["company"]["partners"]["edges"][number]["node"]["user"] {
  const authUser = useFragment<useSubscribedUser$key>(
    graphql`
      fragment useSubscribedUser on UserNode {
        ...ManageSubscription_subscribedUser
        stripePriceId
        availableProductName
        partner {
          company {
            partners {
              edges {
                node {
                  user {
                    ...ManageSubscription_subscribedUser
                    availableProductName
                  }
                }
              }
            }
          }
        }
      }
    `,
    useAuth().authUser
  );

  if (!authUser) return null;

  // If this user is a partner and another partner in the company is subscribed to the enterprise plan, then this user is also considered subscribed
  const companyPartners = authUser.partner?.company?.partners?.edges.map(
    ({ node }) => node
  );
  const enterprisePartner = companyPartners?.find(
    partner => partner.user.availableProductName === "partner_enterprise"
  );
  if (enterprisePartner) return enterprisePartner.user;

  if (authUser.stripePriceId) return authUser;

  return null;
}
