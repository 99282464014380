import { ReactElement } from "react";

import AutoLayout from "../../../../components/01_Core/AutoLayout";

interface IAccountManagementFormContentProps {
  children: ReactElement[];
}

function AccountManagementFormContent(
  props: IAccountManagementFormContentProps
): ReactElement {
  return (
    <AutoLayout
      spacing={[32, 48, 48, 48]}
      dependentProps={{
        direction: "vertical"
      }}
      padding={["32px", "88px", "88px", "88px"]}
      marginBottom={["64px", "128px", "128px", "128px"]}
      bg={"white100"}
      sx={{
        border: "1px solid",
        borderColor: "midGray70",
        width: "100%",
        minHeight: ["auto", "auto", "auto", "640px"]
      }}
    >
      {props.children}
    </AutoLayout>
  );
}

export default AccountManagementFormContent;
