/**
 * @generated SignedSource<<9b400675e695911d5eb2657895bb3d37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyTeam_company$data = {
  readonly displayName: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserListCompanyTeam_company">;
  readonly " $fragmentType": "CompanyTeam_company";
};
export type CompanyTeam_company$key = {
  readonly " $data"?: CompanyTeam_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyTeam_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyTeam_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserListCompanyTeam_company"
    }
  ],
  "type": "CompanyNode",
  "abstractKey": null
};

(node as any).hash = "b99eb92648c2d2e24a704aba7e8dac56";

export default node;
