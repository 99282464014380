import { ReactElement, ReactNode } from "react";
import { Box } from "theme-ui";

import AutoLayout from "../AutoLayout";
import { IBadgeProps, WithBadge } from "../Users/AvatarIcon";

export function DotLabel(props: {
  dotProps: IBadgeProps;
  children?: ReactNode;
}): ReactElement {
  const { dotProps, children } = props;
  return (
    <AutoLayout
      spacing={"0.55ch"}
      dependentProps={{
        direction: "horizontal",
        alignment: "center"
      }}
    >
      {dotProps && (
        <WithBadge {...dotProps} size="100%" border={undefined}>
          <Box
            sx={{
              width: "0.4lh",
              height: "0.4lh",
              verticalAlign: "middle"
            }}
          />
        </WithBadge>
      )}
      {children}
    </AutoLayout>
  );
}
