/**
 * @generated SignedSource<<c7e76c760005485a652e39c87989f234>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageAction_artist$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useMessage_actor">;
  readonly " $fragmentType": "MessageAction_artist";
};
export type MessageAction_artist$key = {
  readonly " $data"?: MessageAction_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageAction_artist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageAction_artist",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMessage_actor"
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};

(node as any).hash = "c951263c0e12bc7c57f0490bd742535f";

export default node;
