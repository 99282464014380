/**
 * @generated SignedSource<<05b8b0053a6633cc41952d3596ea5ecc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseImagesGrid_unreleasedReleases$data = ReadonlyArray<{
  readonly id: string;
  readonly indiglyphType: string;
  readonly thumbnailUrl: string | null;
  readonly " $fragmentType": "ReleaseImagesGrid_unreleasedReleases";
}>;
export type ReleaseImagesGrid_unreleasedReleases$key = ReadonlyArray<{
  readonly " $data"?: ReleaseImagesGrid_unreleasedReleases$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseImagesGrid_unreleasedReleases">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ReleaseImagesGrid_unreleasedReleases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "indiglyphType",
      "storageKey": null
    }
  ],
  "type": "UnreleasedReleaseNode",
  "abstractKey": null
};

(node as any).hash = "7914cd0952e0e3207e49e0cce3af0564";

export default node;
