/**
 * @generated SignedSource<<a98176dffe7928e98362ae17a70b1df4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InboxChat_chat$data = {
  readonly acceptedOffersCount: number | null;
  readonly hasUnreadMessages: boolean | null;
  readonly lastMessageSentAt: any | null;
  readonly mostRecentActionableOffer: {
    readonly __typename: "OfferNode";
    readonly " $fragmentSpreads": FragmentRefs<"useOfferNextStep_offer">;
  } | null;
  readonly other: {
    readonly actor: {
      readonly " $fragmentSpreads": FragmentRefs<"AvatarName_actor">;
    } | null;
  } | null;
  readonly " $fragmentType": "InboxChat_chat";
};
export type InboxChat_chat$key = {
  readonly " $data"?: InboxChat_chat$data;
  readonly " $fragmentSpreads": FragmentRefs<"InboxChat_chat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InboxChat_chat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUnreadMessages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastMessageSentAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "other",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "actor",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AvatarName_actor"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferNode",
      "kind": "LinkedField",
      "name": "mostRecentActionableOffer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useOfferNextStep_offer"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptedOffersCount",
      "storageKey": null
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};

(node as any).hash = "b9eda83ee9449acd6902bb9ba0a03297";

export default node;
