/**
 * @generated SignedSource<<11a01da933e4875e133ffd7366d2a058>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundPortfolioRowDeal_offer$data = {
  readonly copartners: ReadonlyArray<{
    readonly company: {
      readonly displayName: string;
    } | null;
    readonly displayName: string;
    readonly id: string;
    readonly thumbnailUrl: string;
  } | null> | null;
  readonly dateOfferAccepted: any | null;
  readonly hasInvestmentCopartner: boolean | null;
  readonly monthlyEarnings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly partnerEarnings: any | null;
        readonly partnerExpenses: any | null;
      } | null;
    } | null>;
  };
  readonly totalStreams: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"OfferDealArtistReleases_query" | "PortfolioRowDeal_offer">;
  readonly " $fragmentType": "FundPortfolioRowDeal_offer";
};
export type FundPortfolioRowDeal_offer$key = {
  readonly " $data"?: FundPortfolioRowDeal_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundPortfolioRowDeal_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundPortfolioRowDeal_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalStreams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfferAccepted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MonthlyEarningsNodeConnection",
      "kind": "LinkedField",
      "name": "monthlyEarnings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MonthlyEarningsNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyEarningsNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partnerEarnings",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partnerExpenses",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerNode",
      "kind": "LinkedField",
      "name": "copartners",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyNode",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasInvestmentCopartner",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferDealArtistReleases_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioRowDeal_offer"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};
})();

(node as any).hash = "743c6a391c6f7ed7adff406e4699518a";

export default node;
