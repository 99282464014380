import { ReactElement } from "react";
import { Flex, Link, Text } from "theme-ui";

import SimpleNav from "../../pages/indify/components/03_UI_Kit/Navigation/SimpleNav";
import { useBreakpoint } from "../../utils/useBreakpoints";
import AutoLayout, { FillContainer } from "../01_Core/AutoLayout";
import Button from "../01_Core/Buttons/Button";
import { FixedGrid } from "../01_Core/Grids/Grid";
import { GridItem } from "../01_Core/Grids/GridItem";

/**
 * figma: https://www.figma.com/file/kgb4PuaHGOpOq7NYLyBAom/00-Globals?node-id=597%3A4395
 */
function GlobalErrorPage(): ReactElement {
  const breakpointMap = useBreakpoint();

  function getTextVariant() {
    if (breakpointMap["mobile"]) {
      return "h600";
    } else {
      return "h700";
    }
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
      }}
    >
      <SimpleNav />
      <FixedGrid>
        <GridItem
          gridColumn={["1 / span 4", "1 / span 8", "1 / span 8", "3 / span 8"]}
        >
          <AutoLayout
            spacing={[33, 64, 64, 64]}
            dependentProps={{ direction: "vertical" }}
            resizingX={FillContainer()}
            sx={{
              backgroundColor: "white100",
              border: "1px solid",
              borderColor: "midGray70",
              padding: ["32px", "88px", "88px", "88px"],
              marginTop: ["24px", "88px", "88px", "88px"]
            }}
          >
            <Text
              variant={getTextVariant()}
              color={"black100"}
              sx={{ textAlign: "center", width: "100%" }}
            >
              Oops, it looks like something didn't work.
            </Text>
            <Text
              variant={"bodyLarge"}
              color={"black100"}
              sx={{ textAlign: "center", width: "100%" }}
            >
              Try reloading the page. If that doesn't work, send us a note at{" "}
              <Link
                href={"mailto:support@indify.io"}
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" }
                }}
              >
                <Text color={"secondary100"}>
                  <strong>support@indify.io</strong>
                </Text>
              </Link>
              .
            </Text>
            <Link href={window.location.origin} sx={{ width: "100%" }}>
              <Button
                variant={"primary"}
                size={"large"}
                disabled={false}
                sx={{ width: "100%" }}
              >
                Back to home
              </Button>
            </Link>
          </AutoLayout>
        </GridItem>
      </FixedGrid>
    </Flex>
  );
}

export default GlobalErrorPage;
