/**
 * @generated SignedSource<<09f8d60a9de389766b7de84d42c360a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundCaseStudyOffer_offer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOfferName">;
  readonly " $fragmentType": "FundCaseStudyOffer_offer";
};
export type FundCaseStudyOffer_offer$key = {
  readonly " $data"?: FundCaseStudyOffer_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundCaseStudyOffer_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundCaseStudyOffer_offer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOfferName"
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};

(node as any).hash = "75b4d30c916e286f826a44fa4efc20be";

export default node;
