import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { getInputSpacing } from "../../../../../components/01_Core/Forms/Input";
import { TooltipIconRight } from "../../../../../components/01_Core/Tooltips/Tooltip";
import { rvMap, useFindActiveRV } from "../../../../../utils/responsiveUtils";
import { OfferTermsWarningBlock } from "../Offers/OfferTermsWarningBlock";

export type FormSectionSize = "small" | "medium";
export interface IFormSectionProps {
  heading: string;
  headingTooltip?: string;
  size: ResponsiveValue<FormSectionSize>;
  children: ReactNode;
  error?: string;
  caption?: string;
  warning?: {
    type: Parameters<typeof OfferTermsWarningBlock>[0]["type"];
    children: ReactNode;
  };
}

/**
 * figma:
 * - https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=688%3A1958
 * - https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=688%3A1948
 */
function FormSection(props: IFormSectionProps): ReactElement {
  const headingTextVariant = useFindActiveRV(props.size, getHeadingTextVariant);
  const errorTextVariant = useFindActiveRV(props.size, getErrorTextVariant);
  const tooltipSize = useFindActiveRV(props.size, size => {
    switch (size) {
      case "small":
        return "small";
      case "medium":
        return "medium";
    }
  });

  const headingText = (
    <Text variant={headingTextVariant} color={"black100"}>
      {props.heading}
    </Text>
  );

  return (
    <AutoLayout
      py={"32px"}
      px={"24px"}
      spacing={16}
      sx={{ width: "100%" }}
      dependentProps={{ direction: "vertical" }}
    >
      {props.headingTooltip ? (
        <TooltipIconRight size={tooltipSize} content={props.headingTooltip}>
          {headingText}
        </TooltipIconRight>
      ) : (
        headingText
      )}
      {props.error && (
        <Text variant={errorTextVariant} color={"alert50"}>
          {props.error}
        </Text>
      )}
      <AutoLayout
        spacing={rvMap(props.size, getInputSpacing)}
        dependentProps={{ direction: "vertical" }}
        sx={{ width: "100%" }}
      >
        {props.children}
        {props.caption != null && (
          <Text
            variant={"bodySmall"}
            color={"deepGray100"}
            pt={rvMap(props.size, getCaptionPt)}
          >
            {props.caption}
          </Text>
        )}
      </AutoLayout>
      {props.warning?.children && (
        <OfferTermsWarningBlock type={props.warning.type} size={"large"}>
          {props.warning.children}
        </OfferTermsWarningBlock>
      )}
    </AutoLayout>
  );
}

function getHeadingTextVariant(size: FormSectionSize) {
  switch (size) {
    case "medium":
      return "h400";
    case "small":
      return "h300";
  }
}

function getErrorTextVariant(size: FormSectionSize) {
  switch (size) {
    case "medium":
      return "bodyMedium";
    case "small":
      return "bodySmall";
  }
}

function getCaptionPt(size: FormSectionSize) {
  switch (size) {
    case "medium":
      return "8px";
    case "small":
      return "4px";
  }
}

export default FormSection;
