/**
 * @generated SignedSource<<c2fa3b1e2c7ec0cb10710bbaa45f9849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type mutationsDeleteFeaturedDealMutation$variables = {
  id: string;
};
export type mutationsDeleteFeaturedDealMutation$data = {
  readonly deleteFeaturedDeal: {
    readonly artist: {
      readonly featuredDeals: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type mutationsDeleteFeaturedDealMutation = {
  response: mutationsDeleteFeaturedDealMutation$data;
  variables: mutationsDeleteFeaturedDealMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FeaturedDealNodeConnection",
  "kind": "LinkedField",
  "name": "featuredDeals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeaturedDealNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeaturedDealNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsDeleteFeaturedDealMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFeaturedDealMutation",
        "kind": "LinkedField",
        "name": "deleteFeaturedDeal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationsDeleteFeaturedDealMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFeaturedDealMutation",
        "kind": "LinkedField",
        "name": "deleteFeaturedDeal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53579f82d4eda0a95c127af45e44bc5a",
    "id": null,
    "metadata": {},
    "name": "mutationsDeleteFeaturedDealMutation",
    "operationKind": "mutation",
    "text": "mutation mutationsDeleteFeaturedDealMutation(\n  $id: ID!\n) {\n  deleteFeaturedDeal(id: $id) {\n    artist {\n      featuredDeals {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff63696bda451e5ebfcf3a8d8fc818c4";

export default node;
