/**
 * @generated SignedSource<<56e022e7365f6d780b522bfa1d9660a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyServiceService = "CONTENT_PRODUCTION" | "DISTRIBUTION" | "FUNDING" | "INDUSTRY" | "INFLUENCER" | "MARKETING";
export type EditCompanyInfoMutationInput = {
  about?: string | null;
  caseStudyOfferNodeId?: string | null;
  companyNodeId: string;
  displayName: string;
  genreNodeIds?: ReadonlyArray<string | null> | null;
  location?: string | null;
  recipientPartnerNodeId?: string | null;
  services?: ReadonlyArray<CompanyServiceService | null> | null;
  slug: string;
  websiteUrl?: string | null;
};
export type CompanyInfoMutation$variables = {
  input: EditCompanyInfoMutationInput;
};
export type CompanyInfoMutation$data = {
  readonly editCompanyInfo: {
    readonly company: {
      readonly " $fragmentSpreads": FragmentRefs<"CompanyInfo_company">;
    } | null;
  } | null;
};
export type CompanyInfoMutation = {
  response: CompanyInfoMutation$data;
  variables: CompanyInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditCompanyInfoMutation",
        "kind": "LinkedField",
        "name": "editCompanyInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNode",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CompanyInfo_company"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditCompanyInfoMutation",
        "kind": "LinkedField",
        "name": "editCompanyInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNode",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyServiceNodeConnection",
                "kind": "LinkedField",
                "name": "services",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyServiceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompanyServiceNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "service",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GenreNodeConnection",
                "kind": "LinkedField",
                "name": "genres",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GenreNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GenreNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferNode",
                "kind": "LinkedField",
                "name": "caseStudyOffer",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isAccepted",
                    "value": true
                  }
                ],
                "concreteType": "OfferNodeConnection",
                "kind": "LinkedField",
                "name": "offers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OfferNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OfferNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ArtistNode",
                            "kind": "LinkedField",
                            "name": "artist",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReleaseNodeConnection",
                            "kind": "LinkedField",
                            "name": "releases",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReleaseNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReleaseNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v5/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UnreleasedReleaseNodeConnection",
                            "kind": "LinkedField",
                            "name": "unreleasedReleases",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UnreleasedReleaseNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UnreleasedReleaseNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v5/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "offers(isAccepted:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PartnerNode",
                "kind": "LinkedField",
                "name": "recipientPartner",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": "activePartners",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isActive",
                    "value": true
                  }
                ],
                "concreteType": "PartnerNodeConnection",
                "kind": "LinkedField",
                "name": "partners",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PartnerNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PartnerNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "partners(isActive:true)"
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "websiteUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "about",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d1d68c89d1391d02b3d97793df98f84",
    "id": null,
    "metadata": {},
    "name": "CompanyInfoMutation",
    "operationKind": "mutation",
    "text": "mutation CompanyInfoMutation(\n  $input: EditCompanyInfoMutationInput!\n) {\n  editCompanyInfo(data: $input) {\n    company {\n      ...CompanyInfo_company\n      id\n    }\n  }\n}\n\nfragment CaseStudyOffer_company on CompanyNode {\n  offers(isAccepted: true) {\n    edges {\n      node {\n        id\n        ...useOfferName\n      }\n    }\n  }\n}\n\nfragment CompanyInfo_company on CompanyNode {\n  id\n  services {\n    edges {\n      node {\n        service\n        id\n      }\n    }\n  }\n  genres {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  caseStudyOffer {\n    id\n  }\n  ...CaseStudyOffer_company\n  recipientPartner {\n    id\n  }\n  ...RecipientPartner_company\n  displayName\n  slug\n  thumbnailUrl\n  location\n  websiteUrl\n  about\n}\n\nfragment RecipientPartner_company on CompanyNode {\n  activePartners: partners(isActive: true) {\n    edges {\n      node {\n        id\n        displayName\n      }\n    }\n  }\n}\n\nfragment useOfferName on OfferNode {\n  artist {\n    displayName\n    id\n  }\n  releases {\n    edges {\n      node {\n        name\n        type\n        id\n      }\n    }\n  }\n  unreleasedReleases {\n    edges {\n      node {\n        name\n        type\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5bf279baf201f0ad46966102c6313ebb";

export default node;
