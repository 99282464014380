/**
 * @generated SignedSource<<6fe9b9634d6f2d6df117211dbfe19f8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpecialDeals_highlightDeals$data = {
  readonly highlightDeals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"SpecialDeal_featuredDeal">;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "SpecialDeals_highlightDeals";
};
export type SpecialDeals_highlightDeals$key = {
  readonly " $data"?: SpecialDeals_highlightDeals$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpecialDeals_highlightDeals">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpecialDeals_highlightDeals",
  "selections": [
    {
      "alias": "highlightDeals",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        },
        {
          "kind": "Literal",
          "name": "isHighlight",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "isMatchForCompany",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-date"
        }
      ],
      "concreteType": "FeaturedDealNodeConnection",
      "kind": "LinkedField",
      "name": "availableFeaturedDeals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeaturedDealNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FeaturedDealNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SpecialDeal_featuredDeal"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "availableFeaturedDeals(first:3,isHighlight:true,isMatchForCompany:false,orderBy:\"-date\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a31fe5b8cdc9a6f59e360280edd6df57";

export default node;
