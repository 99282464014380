import { ReactElement, ReactNode } from "react";
import { Text } from "theme-ui";

import AutoLayout from "../../../../../../components/01_Core/AutoLayout";

export type OfferMessageSectionSize = "small" | "medium";

interface IOfferMessageSectionProps {
  size: OfferMessageSectionSize;
  heading: string;
  description: ReactNode;
}

function getSectionHeadingVariant(size: OfferMessageSectionSize) {
  switch (size) {
    case "medium":
      return "h200";
    case "small":
      return "h100";
  }
}

function OfferMessageSection(props: IOfferMessageSectionProps): ReactElement {
  return (
    <AutoLayout
      spacing={8}
      dependentProps={{ direction: "vertical" }}
      sx={{ padding: "16px 24px", width: "100%" }}
    >
      <Text variant={getSectionHeadingVariant(props.size)} as={"p"}>
        <strong>{props.heading}</strong>
      </Text>
      <Text variant={"primary.bodyMedium"} mt={"8px"} sx={{ width: "100%" }}>
        {props.description}
      </Text>
    </AutoLayout>
  );
}

export default OfferMessageSection;
