import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";

type PageTitleSize = "small" | "large";

interface IPageTitleProps {
  size: ResponsiveValue<PageTitleSize>;
  title: string;
  description?: string;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1907%3A22701
 */
function DirectoryPageTitle(props: IPageTitleProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);

  return (
    <FixedGrid>
      <GridItem
        gridColumn={["1 / span 4", "1 / span 8", "1 / span 8", "3 / span 8"]}
      >
        <AutoLayout
          dependentProps={{ direction: "vertical" }}
          spacing={activeSize === "large" ? 24 : 16}
          sx={{
            flexDirection: "column",
            alignItems: "center",
            paddingY: activeSize === "large" ? "48px" : "32px",
            width: "100%"
          }}
        >
          <Text
            variant={activeSize === "large" ? "h500" : "h400"}
            color={"black100"}
          >
            {props.title}
          </Text>
          {props.description && <Divider />}
          {props.description && (
            <Text
              variant={activeSize === "large" ? "bodyLarge" : "bodyMedium"}
              color={"deepGray100"}
              sx={{ textAlign: "center" }}
            >
              {props.description}
            </Text>
          )}
        </AutoLayout>
      </GridItem>
    </FixedGrid>
  );
}

export default DirectoryPageTitle;
