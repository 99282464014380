/**
 * @generated SignedSource<<737c677b9c130d451e05495e5f628d1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageInput_chat$data = {
  readonly artistUser: {
    readonly artist: {
      readonly " $fragmentSpreads": FragmentRefs<"useArtistCanMessageCompany_artist">;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useArtistCanMessageCompany_chat">;
  readonly " $fragmentType": "MessageInput_chat";
};
export type MessageInput_chat$key = {
  readonly " $data"?: MessageInput_chat$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageInput_chat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageInput_chat",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useArtistCanMessageCompany_chat"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "artistUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistNode",
          "kind": "LinkedField",
          "name": "artist",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useArtistCanMessageCompany_artist"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};

(node as any).hash = "0731efcb2ba7b2e245899c443644d58f";

export default node;
