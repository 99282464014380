/**
 * @generated SignedSource<<a0052d7ecec9f06577dd29120e0103d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferMessageReleasesIncluded_unreleasedReleases$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ReleasesInfoRemovable_release">;
  readonly " $fragmentType": "OfferMessageReleasesIncluded_unreleasedReleases";
}>;
export type OfferMessageReleasesIncluded_unreleasedReleases$key = ReadonlyArray<{
  readonly " $data"?: OfferMessageReleasesIncluded_unreleasedReleases$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageReleasesIncluded_unreleasedReleases">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OfferMessageReleasesIncluded_unreleasedReleases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleasesInfoRemovable_release"
    }
  ],
  "type": "UnreleasedReleaseNode",
  "abstractKey": null
};

(node as any).hash = "15e3c6ddc1cce1999150d9f1257bc4a9";

export default node;
