import { ReactElement } from "react";
import { Link, Text } from "theme-ui";

import { useFindActiveRV } from "../../../../utils/responsiveUtils";

function HelperText(): ReactElement {
  const textVariant = useFindActiveRV(
    ["small", "small", "large", "large"],
    size => {
      switch (size) {
        case "small":
          return "bodyMedium";
        case "large":
          return "bodyLarge";
      }
    }
  );

  return (
    <Text
      my={"24px"}
      px={["8px", "8px", "0px", "0px"]}
      color={"black100"}
      variant={textVariant}
      sx={{ textAlign: "center" }}
    >
      Have a question or feedback? Get in touch at{" "}
      <Link
        href={"mailto:support@indify.io"}
        sx={{ textDecoration: "none" }}
        color={"secondary100"}
      >
        <strong>support@indify.io</strong>
      </Link>
      .
    </Text>
  );
}

export default HelperText;
