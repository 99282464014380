import { ReactElement, ReactNode } from "react";
import ReactLinkify from "react-linkify";
import { Link as ExternalLink, Text } from "theme-ui";

import Ic24OpenInNew from "../../imgs/icons/ic24-open-in-new.svg";
import { IconLabel } from "../pages/indify/components/03_UI_Kit/Discover/RowHeaderDeal";

// Always show full domain, shorten/hide path as needed
const MAX_URL_LENGTH = 40;
function shortenURL(url: URL): string {
  const host = `${url.protocol}//${url.hostname}${
    url.port ? `:${url.port}` : ""
  }`;
  const allowedPathLength = MAX_URL_LENGTH - host.length;
  const path = url.pathname;
  return `${host}${`${allowedPathLength < path.length ? "/..." : ""}${
    allowedPathLength > 0 ? path.slice(-allowedPathLength) : ""
  }`}`;
}

export default function Linkify(props: { children: ReactNode }): ReactElement {
  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => {
        const url = new URL(decoratedHref);
        const shortUrl = url.protocol.match(/http/)
          ? shortenURL(url)
          : decoratedText;

        return (
          <ExternalLink key={key} href={decoratedHref} target={"_blank"}>
            <IconLabel
              Icon={Ic24OpenInNew}
              iconSide="right"
              size="0.9em"
              styles={{ maxWidth: "100%" }}
            >
              <Text sx={{ textDecoration: "underline", overflow: "hidden" }}>
                {shortUrl}
              </Text>
            </IconLabel>
          </ExternalLink>
        );
      }}
    >
      {props.children}
    </ReactLinkify>
  );
}
