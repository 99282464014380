import { ReactElement } from "react";
import { FormRenderProps } from "react-final-form";
import { BehaviorSubject, Subject } from "rxjs";
import { useThemeUI } from "theme-ui";

import Ic24ArrowLeft from "../../../../../imgs/icons/ic24-arrow-left.svg";
import Ic24ArrowRight from "../../../../../imgs/icons/ic24-arrow-right.svg";
import useBehaviorSubjectState from "../../hooks/useBehaviorSubjectState";
import Footer from "../03_UI_Kit/Forms/Footer";
import {
  FormValues,
  INavState,
  NavigationRequest
} from "./ClaimYourAccountFlow";
import { skippablePages } from "./Pages/Page";

interface IClaimYourAccountFlowFooterProps {
  navState: BehaviorSubject<INavState>;
  navRequests: Subject<NavigationRequest>;
  formRenderProps: FormRenderProps<FormValues>;
}
function ClaimYourAccountFlowFooter(
  props: IClaimYourAccountFlowFooterProps
): ReactElement {
  const { theme } = useThemeUI();
  const { current, total, page } = useBehaviorSubjectState(props.navState);
  const canGoBack = current > 0;
  const notAtEnd = current < total - 1;
  const isCurrentSkippable = skippablePages[page] != null;
  const goBackwards = () => props.navRequests.next("back");
  const goForward = () => {
    if (notAtEnd) {
      props.navRequests.next("continue");
    } else {
      props.formRenderProps.handleSubmit();
    }
  };

  return (
    <Footer
      size={["small", "small", "large", "large"]}
      primaryCta={{
        label: "Continue",
        onClick: goForward,
        loading: props.formRenderProps.submitting,
        disabled:
          props.formRenderProps.validating ||
          props.formRenderProps.hasValidationErrors
      }}
      secondaryCta={
        canGoBack
          ? {
              label: "Back",
              onClick: goBackwards,
              icon: <Ic24ArrowLeft color={theme.colors.black100 as string} />
            }
          : undefined
      }
      tertiaryCta={
        isCurrentSkippable
          ? {
              label: "Skip for now",
              onClick: goForward,
              icon: <Ic24ArrowRight color={theme.colors.black100 as string} />
            }
          : undefined
      }
    />
  );
}

export default ClaimYourAccountFlowFooter;
