/**
 * @generated SignedSource<<d402b5bc768815509160dac0758fcf8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebaseHeader_homebase$data = {
  readonly artist: {
    readonly displayName: string;
    readonly genre: {
      readonly genre: string;
    } | null;
    readonly imageUrl: string;
    readonly location: string | null;
    readonly profiles: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"SocialLinksIcons_profiles">;
        } | null;
      } | null>;
    };
    readonly stats: {
      readonly ranked: number | null;
    } | null;
  } | null;
  readonly instagramFollowers: {
    readonly value: number | null;
  } | null;
  readonly tiktokFollowers: {
    readonly value: number | null;
  } | null;
  readonly weeklyStreams: {
    readonly value: number | null;
  } | null;
  readonly " $fragmentType": "HomebaseHeader_homebase";
};
export type HomebaseHeader_homebase$key = {
  readonly " $data"?: HomebaseHeader_homebase$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebaseHeader_homebase">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomebaseHeader_homebase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "instagramFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "tiktokFollowers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HomebaseDataNode",
      "kind": "LinkedField",
      "name": "weeklyStreams",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "location",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistStatsObject",
          "kind": "LinkedField",
          "name": "stats",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ranked",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GenreNode",
          "kind": "LinkedField",
          "name": "genre",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "genre",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNodeConnection",
          "kind": "LinkedField",
          "name": "profiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProfileNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "SocialLinksIcons_profiles"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HomebaseNode",
  "abstractKey": null
};
})();

(node as any).hash = "de9ef916320b43efa53817006eed41ed";

export default node;
