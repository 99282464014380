import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { ResponsiveValue } from "styled-system";
import { Link as ExternalLink, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import InternalLink from "../../../../../utils/InternalLink";
import { rvMap, useFindActiveRV } from "../../../../../utils/responsiveUtils";

type UserNavItemSize = "small" | "large";
interface IUserNavItemProps {
  size: ResponsiveValue<UserNavItemSize>;
  onClick?: () => void;
  route?: string;
  external?: boolean;
  disabled?: boolean;
  badge?: string;
  children: string;
}

function InternalExternalLink(props: {
  route?: string;
  external?: boolean;
  disabled?: boolean;
  children: ReactNode;
}) {
  if (!props.route) {
    return props.children;
  }

  if (props.disabled) {
    return (
      <InternalLink to={props.route} disabled>
        {props.children}
      </InternalLink>
    );
  }

  return props.external ? (
    <ExternalLink href={props.route} target={"_blank"}>
      {props.children}
    </ExternalLink>
  ) : (
    <Link to={props.route}>{props.children} </Link>
  );
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1355%3A24962
 */
function UserNavItem(props: IUserNavItemProps): ReactElement {
  const textTpe = useFindActiveRV(props.size, getTextType);

  return (
    <InternalExternalLink
      route={props.route}
      external={props.external}
      disabled={props.disabled}
    >
      <AutoLayout
        spacing={4}
        dependentProps={{ direction: "horizontal", alignment: "left" }}
        resizingX={FillContainer()}
        onClick={props.onClick}
        px={"32px"}
        py={rvMap(props.size, getPY)}
        sx={{ ":hover": { backgroundColor: "secondary10" } }}
      >
        <Text variant={textTpe}>{props.children}</Text>
        {props.badge && (
          <SubtleBadge size={"small"} color={"orange"}>
            <strong>{props.badge}</strong>
          </SubtleBadge>
        )}
      </AutoLayout>
    </InternalExternalLink>
  );
}

function getPY(size: UserNavItemSize) {
  switch (size) {
    case "small":
      return "16px";
    case "large":
      return "12px";
  }
}

function getTextType(size: UserNavItemSize) {
  switch (size) {
    case "small":
      return "h300";
    case "large":
      return "bodyLarge";
  }
}

export default UserNavItem;
