import { ReactElement } from "react";

import AutoLayout from "../../../../../../components/01_Core/AutoLayout";
import { Tooltip } from "../../../../../../components/01_Core/Tooltips/Tooltip";
import { useBreakpoint } from "../../../../../../utils/useBreakpoints";
import usePartnerCanViewDeals from "../../../../hooks/usePartnerCanViewDeals";
import { InternalRoutes } from "../../../../Routing/InternalRoutes";
import Base from "./Items/Base";

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=212%3A2218
 */
function Partner(): ReactElement {
  const breakpointMap = useBreakpoint();

  const [partnerCanViewDeals, partnerCannotViewDealsText] =
    usePartnerCanViewDeals();

  return (
    !breakpointMap["mobile"] &&
    !breakpointMap["tablet"] && (
      <AutoLayout
        spacing={32}
        dependentProps={{ direction: "horizontal", alignment: "center" }}
        sx={{ height: "100%" }}
      >
        <Base route={InternalRoutes.featuredDealDirectory}>Deals</Base>
        <Base route={InternalRoutes.artistDirectory}>Artists</Base>
        <Base route={InternalRoutes.fundDirectory}>Partners</Base>
        <Tooltip content={partnerCannotViewDealsText} size="medium">
          <Base
            route={InternalRoutes.portfolio}
            disabled={!partnerCanViewDeals}
          >
            Portfolio
          </Base>
        </Tooltip>
      </AutoLayout>
    )
  );
}

export default Partner;
