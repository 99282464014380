import { DealManageMenu_featuredDeal$key } from "__generated__/DealManageMenu_featuredDeal.graphql";
import { mutationsDeleteFeaturedDealMutation } from "__generated__/mutationsDeleteFeaturedDealMutation.graphql";
import { ReactElement } from "react";
import { commitMutation, graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import Ic16ChevronDown from "../../../../../imgs/icons/ic16-chevron-down.svg";
import Ic20Delete from "../../../../../imgs/icons/ic20-delete.svg";
import Ic20File from "../../../../../imgs/icons/ic20-file.svg";
import Ic20FileEdit from "../../../../../imgs/icons/ic20-file-edit.svg";
import Ic20Share from "../../../../../imgs/icons/ic20-share.svg";
import Ic20Visibility from "../../../../../imgs/icons/ic20-visibility.svg";
import Ic20VisibilityOff from "../../../../../imgs/icons/ic20-visibility-off.svg";
import IconButton from "../../../../components/01_Core/Buttons/IconButton";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import Dropdown, {
  IDropdownProps
} from "../../../../components/01_Core/Dropdown_Menus/Dropdown";
import environment from "../../../../environment";
import { deleteFeaturedDealMutation } from "../../../../mutations/mutations";
import { InternalRoutes } from "../../Routing/InternalRoutes";

interface IDealManageMenuProps {
  openDirection: IDropdownProps["openDirection"];
  featuredDeal: DealManageMenu_featuredDeal$key;
  onClickEditDeal: () => void;
  onClickToggleDraftDeal: () => void;
  onClickCopyDealLink: () => void;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=1019%3A19493
 */
function DealManageMenu(props: IDealManageMenuProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment DealManageMenu_featuredDeal on FeaturedDealNode {
        id
        status
      }
    `,
    props.featuredDeal
  );

  const deleteFeaturedDeal = () =>
    commitMutation<mutationsDeleteFeaturedDealMutation>(environment, {
      mutation: deleteFeaturedDealMutation,
      variables: {
        id: featuredDeal.id
      }
    });

  return (
    <Dropdown
      triggerElement={
        <IconButton variant={"tertiary"} size={"small"} disabled={false}>
          <Ic16ChevronDown />
        </IconButton>
      }
      openDirection={props.openDirection}
      styles={{ top: 38, width: 270 }}
    >
      <Link
        to={generatePath(InternalRoutes.featuredDeal, {
          dealId: featuredDeal.id
        })}
      >
        <TextButton
          size={"medium"}
          type={"secondary"}
          iconProps={{ placement: "left", icon: <Ic20File /> }}
        >
          Preview deal
        </TextButton>
      </Link>
      <TextButton
        size={"medium"}
        type={"secondary"}
        onClick={props.onClickEditDeal}
        iconProps={{ placement: "left", icon: <Ic20FileEdit /> }}
      >
        Edit deal
      </TextButton>
      {featuredDeal.status !== "PENDING" && (
        <TextButton
          size={"medium"}
          type={"secondary"}
          iconProps={{ placement: "left", icon: <Ic20Share /> }}
          onClick={props.onClickCopyDealLink}
        >
          Copy public link to deal
        </TextButton>
      )}
      {featuredDeal.status !== "PENDING" && (
        <TextButton
          size={"medium"}
          type={"secondary"}
          onClick={props.onClickToggleDraftDeal}
          iconProps={{
            placement: "left",
            icon:
              featuredDeal.status === "PUBLISHED" ? (
                <Ic20VisibilityOff />
              ) : (
                <Ic20Visibility />
              )
          }}
        >
          {featuredDeal.status === "PUBLISHED"
            ? "Hide deal from partners"
            : "Show deal to partners"}
        </TextButton>
      )}
      <TextButton
        size={"medium"}
        type={"secondary"}
        onClick={deleteFeaturedDeal}
        iconProps={{ placement: "left", icon: <Ic20Delete /> }}
      >
        Remove deal from indify
      </TextButton>
    </Dropdown>
  );
}

export default DealManageMenu;
