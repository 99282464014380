import { useEffect, useState } from "react";

// Defaulting the generic type S to undefined allows for usage without
// specifying a type. The type is then inferred from the initialState param.
function useDebouncedState<S = undefined>(
  initialState: S,
  debounceTime = 500
): [S, (value: S) => void] {
  const [rawValue, setRawValue] = useState<S>(initialState);
  const [debouncedValue, setDebouncedValue] = useState<S>(initialState);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(rawValue);
    }, debounceTime);
    return () => clearTimeout(timeoutId);
  }, [rawValue]);

  return [debouncedValue, setRawValue];
}

export default useDebouncedState;
