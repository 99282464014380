import { ReactElement } from "react";
import { useFormState } from "react-final-form";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import {
  emailValidator,
  optional,
  urlValidator
} from "../../../../../components/01_Core/Forms/utils/validators";
import { IFeaturedDealFormValues } from "../../06_Deal_Merchandising/DealMerchandisingFormPage";
import { IFundingOfferFormFieldProps } from "../../07_Deal_Flow/OfferFormFunding";
import FormSection from "./FormSection";
import { OfferTermsWarningBlock } from "./OfferTermsWarningBlock";

export const managerNameFieldName: keyof IFeaturedDealFormValues =
  "managerName";
export const managerCompanyFieldName: keyof IFeaturedDealFormValues =
  "managerCompany";
export const managerEmailFieldName: keyof IFeaturedDealFormValues =
  "managerEmail";
export const managerLinkFieldName: keyof IFeaturedDealFormValues =
  "managerLink";

type IOfferFormManagerInfoProps = IFundingOfferFormFieldProps;

export function ManagerEmailWarning<
  FormType extends { managerEmail: string }
>(): ReactElement {
  const formState = useFormState<FormType>();
  const hasEmail = !!formState.values.managerEmail;
  if (formState.errors.managerEmail) return null;
  return (
    <OfferTermsWarningBlock type={hasEmail ? "info" : "warning"} size={"large"}>
      {hasEmail
        ? "Your manager will receive copies of all emails sent to you about offers."
        : "Add a manager email to receive copies of all emails sent to you about offers."}
    </OfferTermsWarningBlock>
  );
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2363%3A17324
 */
function OfferFormManagerInfo(props: IOfferFormManagerInfoProps): ReactElement {
  return (
    <FormSection heading={"Management Details"} size={props.size}>
      <AutoLayout
        spacing={16}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical" }}
      >
        <FieldBuilder
          inputField={TextInput}
          fieldName={managerNameFieldName}
          inputFieldProps={{
            label: "Manager name",
            size: props.size,
            caption: "Optional."
          }}
        />
        <FieldBuilder
          inputField={TextInput}
          fieldName={managerCompanyFieldName}
          inputFieldProps={{
            label: "Manager company",
            size: props.size,
            caption: "Optional."
          }}
        />
        <FieldBuilder
          inputField={TextInput}
          fieldName={managerEmailFieldName}
          validator={optional(emailValidator)}
          inputFieldProps={{
            label: "Manager email",
            size: props.size,
            caption: "Optional."
          }}
        />
        <ManagerEmailWarning />
        <FieldBuilder
          inputField={TextInput}
          fieldName={managerLinkFieldName}
          validator={optional(urlValidator)}
          inputFieldProps={{
            label: "Manager website",
            size: props.size,
            caption: "Optional."
          }}
        />
      </AutoLayout>
    </FormSection>
  );
}

export default OfferFormManagerInfo;
