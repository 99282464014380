import {
  ArtistManageReleases_artist$data,
  ArtistManageReleases_artist$key
} from "__generated__/ArtistManageReleases_artist.graphql";
import { ArtistManageReleases_releasesSection$key } from "__generated__/ArtistManageReleases_releasesSection.graphql";
import { ArtistManageReleasesMutation } from "__generated__/ArtistManageReleasesMutation.graphql";
import ArtistManageReleasesRefreshQuery, {
  ArtistManageReleasesRefreshQuery as ArtistManageReleasesRefreshQueryType
} from "__generated__/ArtistManageReleasesRefreshQuery.graphql";
import _ from "lodash";
import {
  createContext,
  Fragment,
  ReactElement,
  useContext,
  useState
} from "react";
import {
  fetchQuery,
  graphql,
  useFragment,
  useSubscribeToInvalidationState
} from "react-relay";
import { Flex, Text, useThemeUI } from "theme-ui";

import Ic16ChevronDown from "../../../../../imgs/icons/ic16-chevron-down.svg";
import Ic20PlusCircle from "../../../../../imgs/icons/ic20-plus-circle.svg";
import Ic20VisibilityOn from "../../../../../imgs/icons/ic20-visibility.svg";
import Ic20VisibilityOff from "../../../../../imgs/icons/ic20-visibility-off.svg";
import Ic24FlipToBack from "../../../../../imgs/icons/ic24-flip-to-back.svg";
import Ic24Spotify from "../../../../../imgs/icons/ic24-social-spotify.svg";
import { BannerContext } from "../../../../components/01_Core/Announcements/BannerProvider";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import IconButton from "../../../../components/01_Core/Buttons/IconButton";
import TextButton from "../../../../components/01_Core/Buttons/TextButton";
import Dropdown from "../../../../components/01_Core/Dropdown_Menus/Dropdown";
import environment from "../../../../environment";
import useMutationPromise from "../../hooks/useMutationPromise";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";
import ReleaseListItem from "../03_UI_Kit/Releases/ReleaseListItem";
import UpdateOrCreateReleaseModal from "../03_UI_Kit/Releases/UpdateOrCreateReleaseModal";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";

export const ArtistContext =
  createContext<ArtistManageReleases_artist$data>(null);

/**
 * https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/07-Account-Management?node-id=2045%3A22255
 * https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/07-Account-Management?node-id=2094%3A37708
 */
function ArtistManageReleases(props: {
  artist: ArtistManageReleases_artist$key;
}): ReactElement {
  const artist = useFragment(
    graphql`
      fragment ArtistManageReleases_artist on ArtistNode
      @refetchable(queryName: "ArtistManageReleasesRefreshQuery") {
        id
        releasesPrefetch(orderBy: "-releaseDate", first: 1000) {
          edges {
            node {
              ...ArtistManageReleases_releasesSection
            }
          }
        }
        unreleasedReleasesPrefetch(orderBy: "-createdAt", first: 1000) {
          edges {
            node {
              ...ArtistManageReleases_releasesSection
            }
          }
        }
      }
    `,
    props.artist
  );

  useSubscribeToInvalidationState([artist.id], () =>
    fetchQuery<ArtistManageReleasesRefreshQueryType>(
      environment,
      ArtistManageReleasesRefreshQuery,
      { id: artist.id }
    ).toPromise()
  );

  return (
    <ArtistContext.Provider value={artist}>
      <AccountManagementFormContent>
        <AccountManagementHeading
          key={"heading"}
          size={["small", "large", "large", "large"]}
          title={"Releases"}
          description={"Manage how your releases show up on your profile."}
        />
        <ManageReleasesCategory
          artist={artist}
          typename={"UnreleasedReleaseNode"}
          releases={artist.unreleasedReleasesPrefetch.edges.map(e => e.node)}
          desc={"upcoming"}
          Icon={Ic24FlipToBack}
        />
        <ManageReleasesCategory
          artist={artist}
          typename={"ReleaseNode"}
          releases={artist.releasesPrefetch.edges.map(e => e.node)}
          desc={"found on your Spotify profile"}
          Icon={Ic24Spotify}
        />
      </AccountManagementFormContent>
    </ArtistContext.Provider>
  );
}

function ManageReleasesCategory(props: {
  artist: ArtistManageReleases_artist$data;
  typename: "ReleaseNode" | "UnreleasedReleaseNode";
  releases: ArtistManageReleases_releasesSection$key;
  desc: string;
  Icon: SvgrComponent;
}): ReactElement {
  const { theme } = useThemeUI();
  const notifyBanner = useContext(BannerContext);

  const releases = useFragment(
    graphql`
      fragment ArtistManageReleases_releasesSection on ReleaseInterface
      @relay(plural: true) {
        __typename
        id
        releaseType
        releaseDate
        updatedAt
        ...ReleaseListItem_release
      }
    `,
    props.releases
  );

  const ReleasesDropdown = () => {
    const [commit] = useMutationPromise<ArtistManageReleasesMutation>(graphql`
      mutation ArtistManageReleasesMutation(
        $input: SetReleasesVisibleMutationInput!
      ) {
        setReleasesVisible(data: $input) {
          artist {
            releases {
              edges {
                node {
                  id
                  isVisible
                }
              }
            }
            unreleasedReleases {
              edges {
                node {
                  id
                  isVisible
                }
              }
            }
          }
        }
      }
    `);

    const setReleasesVisible = (isVisible: boolean) =>
      commit({
        variables: {
          input: {
            artistNodeId: props.artist.id,
            typename: props.typename,
            isVisible
          }
        }
      }).then(() =>
        notifyBanner({
          type: "neutral",
          children: "Your releases have been updated successfully."
        })
      );

    return (
      <Dropdown
        triggerElement={
          <IconButton
            variant={"tertiary"}
            disabled={false}
            size={"small"}
            sxOverrides={{ borderRadius: "4px", marginLeft: "16px" }}
          >
            <Ic16ChevronDown color={theme.colors.black100 as string} />
          </IconButton>
        }
        openDirection={"left"}
        styles={{ top: 38, width: 270 }}
      >
        <TextButton
          onClick={() => setReleasesVisible(false)}
          size={"medium"}
          type={"secondary"}
          iconProps={{
            placement: "left",
            icon: <Ic20VisibilityOff />
          }}
        >
          <Text>
            Set all to <strong>hidden</strong>
          </Text>
        </TextButton>
        <TextButton
          onClick={() => setReleasesVisible(true)}
          size={"medium"}
          type={"secondary"}
          iconProps={{
            placement: "left",
            icon: <Ic20VisibilityOn />
          }}
        >
          <Text>
            Set all to <strong>visible</strong>
          </Text>
        </TextButton>
      </Dropdown>
    );
  };

  const ReleaseTypesSummary = () => {
    const releaseTypeCounts = _.toPairs(
      _.countBy(releases, r => r.releaseType ?? "unspecified release")
    );
    const phrases = _.sortBy(
      releaseTypeCounts,
      ([releaseType, _count]) => releaseType
    ).map(
      ([releaseType, count]) =>
        `${count} ${releaseType}${count === 1 ? "" : "s"}`
    );

    return (
      <IconLabel Icon={props.Icon}>
        <Text variant={"bodyMedium"} color={"black100"}>
          {phrases.length ? (
            phrases.map((phrase, i) => (
              <Fragment key={i}>
                {i > 0 && " "}
                <strong>
                  {phrase}
                  {phrases.length >= 3 && i !== phrases.length - 1 && ","}
                </strong>
                {i === phrases.length - 2 && " and"}
              </Fragment>
            ))
          ) : (
            <strong>No releases</strong>
          )}{" "}
          {props.desc}
        </Text>
      </IconLabel>
    );
  };

  const CreateRelease = () => {
    if (props.typename === "ReleaseNode") return null;

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    return (
      <>
        <TextButton
          onClick={() => setIsCreateModalOpen(true)}
          size={"medium"}
          type={"secondary"}
          iconProps={{ placement: "left", icon: <Ic20PlusCircle /> }}
        >
          Add an upcoming release to your indify profile
        </TextButton>
        <UpdateOrCreateReleaseModal
          isOpen={isCreateModalOpen}
          setIsOpen={setIsCreateModalOpen}
          release={null}
        />
      </>
    );
  };

  return (
    <AutoLayout
      spacing={[16, 24, 24, 24]}
      dependentProps={{
        direction: "vertical"
      }}
      sx={{ width: "100%" }}
    >
      <Flex
        paddingRight={[0, "16px", "16px", "16px"]}
        paddingY={[0, "16px", "16px", "16px"]}
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <ReleaseTypesSummary />
        <ReleasesDropdown />
      </Flex>
      <CreateRelease />
      <AutoLayout
        spacing={[16, 12, 12, 12]}
        resizingX={FillContainer()}
        dependentProps={{
          direction: "vertical"
        }}
      >
        {_.sortBy(
          releases,
          release => -(release.releaseDate ?? release.updatedAt)
        ).map(release => (
          <ReleaseListItem
            key={release.id}
            release={release}
            size={["small", "small", "small", "large"]}
          />
        ))}
      </AutoLayout>
    </AutoLayout>
  );
}

export default ArtistManageReleases;
