/**
 * @generated SignedSource<<e7368a1314cafd3c5195e6a045e47e7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferMessageCTAPartner_chat$data = {
  readonly id: string;
  readonly " $fragmentType": "OfferMessageCTAPartner_chat";
};
export type OfferMessageCTAPartner_chat$key = {
  readonly " $data"?: OfferMessageCTAPartner_chat$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferMessageCTAPartner_chat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferMessageCTAPartner_chat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};

(node as any).hash = "a26cd0c02dd7d89b95a9bba6429ce783";

export default node;
