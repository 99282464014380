import { useStripePortalRedirectMutation } from "__generated__/useStripePortalRedirectMutation.graphql";
import { graphql } from "react-relay";

import useMutationPromise from "./useMutationPromise";

export function useStripePortalRedirect(
  redirectUrl?: string
): [() => void, boolean] {
  const [commitMutation, isInFlight] =
    useMutationPromise<useStripePortalRedirectMutation>(graphql`
      mutation useStripePortalRedirectMutation($redirectUrl: String) {
        createStripePortalSession(redirectUrl: $redirectUrl) {
          url
        }
      }
    `);

  const stripePortalRedirect = () =>
    commitMutation({
      variables: {
        redirectUrl
      }
    }).then(response => {
      window.location.href = response.createStripePortalSession.url;
    });

  return [stripePortalRedirect, isInFlight];
}
