import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import { Box, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../../components/01_Core/AutoLayout";
import { getInputSpacing } from "../../../../../../components/01_Core/Forms/Input";
import getHasError from "../../../../../../components/01_Core/Forms/utils/getHasError";
import { TooltipIconRight } from "../../../../../../components/01_Core/Tooltips/Tooltip";
import {
  rvMap,
  useFindActiveRV
} from "../../../../../../utils/responsiveUtils";
import { formatNumeral } from "../../../../../../utils/utils";
import {
  IFundingOfferFormFieldProps,
  IFundingOfferFormValues
} from "../../../07_Deal_Flow/OfferFormFunding";
import FormSection, { FormSectionSize } from "../FormSection";
import { useTotalAmountDollars } from "../OfferFormAdvance/AdvanceAggregate";
import OfferFormGuidance from "../OfferFormGuidance";
import {
  getPostRecoupSplitWarning,
  OfferTermsWarningBlock
} from "../OfferTermsWarningBlock";
import Split from "./Split";

interface ISplitsSectionProps extends IFundingOfferFormFieldProps {
  povType: "asArtist" | "asPartner";
  isRequired: boolean;
  showCopartnerWarnings?: boolean;
}

export function getStreamsToRecoup(): number {
  const formState = useFormState<IFundingOfferFormValues>();
  const preRecoupmentSplit = formState.values.splitBeforeRecoupment;
  const totalRevenueDollarsNeededForPartnerToRecoup =
    (useTotalAmountDollars() * 100.0) /
    (preRecoupmentSplit?.partnerPercent ?? 100);
  return (
    totalRevenueDollarsNeededForPartnerToRecoup / GLOBALS.DOLLARS_PER_STREAM
  );
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=688%3A1951
 */
function OfferFormSplits(props: ISplitsSectionProps): ReactElement {
  const hasAdvanceAmount = useTotalAmountDollars() > 0;

  function getError(): string | null {
    const partnerSplitBeforeRecoupmentHasError =
      getHasError<IFundingOfferFormValues>(
        props.showAllErrors,
        "splitBeforeRecoupment" as keyof IFundingOfferFormValues
      );

    const partnerSplitAfterRecoupmentHasError =
      getHasError<IFundingOfferFormValues>(
        props.showAllErrors,
        "splitAfterRecoupment" as keyof IFundingOfferFormValues
      );

    if (hasAdvanceAmount && partnerSplitBeforeRecoupmentHasError) {
      return "Please specify a pre-recoupment split.";
    }

    if (partnerSplitAfterRecoupmentHasError) {
      return `Please specify a ${
        hasAdvanceAmount ? "post-recoupment" : ""
      } split of 50% or less.`;
    }

    return null;
  }

  const labelVariant = useFindActiveRV(props.size, getLabelVariant);

  const formState = useFormState<IFundingOfferFormValues>();

  const preRecoupmentSplit = formState.values.splitBeforeRecoupment;
  const streamsToRecoup = getStreamsToRecoup();

  const showRecoupCalculation =
    useTotalAmountDollars() > 0 &&
    (100 - preRecoupmentSplit?.partnerPercent ?? 0) < 100;

  const postRecoupSplitWarning =
    formState.values.splitAfterRecoupment &&
    getPostRecoupSplitWarning(
      formState.values.splitAfterRecoupment.partnerPercent
    );

  const anyReleaseHasExistingPartnership = formState.values.allReleases.some(
    r => r.hasExistingPartnership
  );
  const anyReleaseHasExistingInvestmentPartnership =
    formState.values.allReleases.some(r => r.hasExistingInvestmentPartnership);

  return (
    <FormSection
      heading={"Splits"}
      headingTooltip={
        "The share of money the partner will receive as a percentage of total earnings from streams and digital sales."
      }
      error={getError()}
      size={props.size}
      warning={{ type: "warning", children: postRecoupSplitWarning }}
    >
      {hasAdvanceAmount &&
        props.showCopartnerWarnings &&
        anyReleaseHasExistingPartnership && (
          <OfferTermsWarningBlock type={"warning"} size={"large"}>
            The artist has already done a deal with another partner for some of
            the selected releases. Please speak with the artist to ensure the
            previous partners are aware of this new deal and are willing to
            stand behind recoupment. Your pre-recoupment split should reflect
            the available percentage of the releases.
          </OfferTermsWarningBlock>
        )}
      {props.showCopartnerWarnings &&
        anyReleaseHasExistingInvestmentPartnership && (
          <OfferTermsWarningBlock type={"warning"} size={"large"}>
            The artist has already done a deal that includes an advance with
            another partner for some of the selected releases. You will stand
            behind the existing advance. Your split will be applied once the
            existing advance is recouped.
          </OfferTermsWarningBlock>
        )}
      <AutoLayout
        spacing={0}
        dependentProps={{ direction: "horizontal" }}
        resizingX={FillContainer()}
      >
        {hasAdvanceAmount && (
          <AutoLayout
            spacing={rvMap(props.size, getInputSpacing)}
            dependentProps={{ direction: "vertical" }}
            pr={"12px"}
          >
            <TooltipIconRight
              size={props.size}
              content={
                "The splits applied up until the partner has received money equal to their total invested amount."
              }
            >
              <Text variant={labelVariant} color={"black100"}>
                Pre-Recoupment
              </Text>
            </TooltipIconRight>

            <Split
              povType={props.povType}
              recoupmentType={"before"}
              size={props.size}
              isRequired={props.isRequired}
            />
          </AutoLayout>
        )}
        <AutoLayout
          spacing={rvMap(props.size, getInputSpacing)}
          dependentProps={{ direction: "vertical" }}
          pr={"12px"}
        >
          {hasAdvanceAmount && (
            <TooltipIconRight
              size={props.size}
              content={
                "The splits applied after the partner has received money equal to their total invested amount."
              }
            >
              <Text variant={labelVariant} color={"black100"}>
                Post-Recoupment
              </Text>
            </TooltipIconRight>
          )}
          <Split
            povType={props.povType}
            recoupmentType={"after"}
            size={props.size}
            isRequired={props.isRequired}
          />
        </AutoLayout>
      </AutoLayout>
      {showRecoupCalculation && (
        <Box mt={"16px"} sx={{ width: "100%" }}>
          <OfferFormGuidance>
            Fully recouped after
            <strong>
              {" "}
              {formatNumeral(
                parseFloat(streamsToRecoup.toPrecision(2)),
                true
              )}{" "}
              streams{" "}
            </strong>
            at Spotify rates.
          </OfferFormGuidance>
        </Box>
      )}
    </FormSection>
  );
}

function getLabelVariant(size: FormSectionSize) {
  switch (size) {
    case "small":
      return "h100";
    case "medium":
      return "h200";
  }
}

export default OfferFormSplits;
