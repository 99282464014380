import { ReactElement } from "react";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextInput from "../../../../../components/01_Core/Forms/Inputs/TextInput";
import { IFundingOfferFormFieldProps } from "../../07_Deal_Flow/OfferFormFunding";
import FormSection from "./FormSection";

interface IOfferFormExistingAgreementsProps
  extends IFundingOfferFormFieldProps {}

export const ExistingAgreementTooltips = {
  distributor:
    "Distributors serve artists in two key functions: 1) they help artists upload their music to DSPs (Digital Service Providers, e.g. Spotify or Apple Music) and 2) they help artists collect royalties from their music. Example distributors include DistroKid, TuneCore, UnitedMasters, etc. Artists who do a deal on indify may be asked to move their releases from their current distributor to one of indify's preferred distributors.",
  distributionAgreement:
    "A distributor may require the artist to sign a longer-term agreement that commits the artist to exclusively distribute certain tracks through the distributor for a set period of time. This is called a distribution agreement. Typically, the distributor takes payment in the form of a percentage of royalties, in exchange for services such as DSP playlist pitching. Under such an agreement, the tracks committed to the distributor must stay there, and cannot be moved even if the artist does a deal on indify -- in this event, indify will work with the artist to figure out a solution.",
  producerAgreement:
    "If the artist worked with a producer, the producer will often receive a percentage of the track's royalties in exchange for producing the track. The agreement between the artist and producer is called a producer agreement, which specifies the percentage the producer will receive. In indify deals, the producer's percentage comes out of the artist's share of royalties."
};

export default function OfferFormExistingAgreements(
  props: IOfferFormExistingAgreementsProps
): ReactElement {
  return (
    <FormSection heading={"Existing Agreements"} size={props.size}>
      <AutoLayout
        spacing={16}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical" }}
      >
        <FieldBuilder
          inputField={TextInput}
          fieldName={"distributor"}
          inputFieldProps={{
            label: "Current distributor",
            labelTooltip: ExistingAgreementTooltips.distributor,
            size: props.size,
            caption:
              "If the releases are currently distributed, please list the distributor here. Otherwise, please leave this field blank."
          }}
        />
        <FieldBuilder
          inputField={TextInput}
          fieldName={"distributionAgreement"}
          inputFieldProps={{
            label: "Distribution agreement",
            labelTooltip: ExistingAgreementTooltips.distributionAgreement,
            size: props.size,
            caption:
              "If a distributor is entitled to a percentage of royalties from any sound recordings in the deal, please describe the percentage and terms of the agreement here. Otherwise, please leave this field blank."
          }}
        />
        <FieldBuilder
          inputField={TextInput}
          fieldName={"producerAgreement"}
          inputFieldProps={{
            label: "Producer agreement",
            labelTooltip: ExistingAgreementTooltips.producerAgreement,
            size: props.size,
            caption:
              "If a producer is entitled to a percentage of royalties from any sound recordings in the deal, please describe the percentage and terms of the agreement here. Please also list any beats or samples used in the releases, and the terms of any agreements related to those beats or samples. Otherwise, please leave this field blank."
          }}
        />
      </AutoLayout>
    </FormSection>
  );
}
