import { ReactElement } from "react";
import { Box, Flex, Image } from "theme-ui";

import LeaderboardSkeletonDesktop from "../../../../../imgs/skeleton_states/leaderboard_skeleton_desktop.png";
import LeaderboardSkeletonMobile from "../../../../../imgs/skeleton_states/leaderboard_skeleton_mobile.png";
import LeaderboardSkeletonTablet from "../../../../../imgs/skeleton_states/leaderboard_skeleton_tablet.png";
import { Loader } from "../../../../components/01_Core/999_Miscellaneous/Loader";
import { useBreakpoint } from "../../../../utils/useBreakpoints";

/**
 * figma: N/A
 */
function DirectorySkeleton(): ReactElement {
  const breakpointMatchMap = useBreakpoint();
  const skeletonSource = breakpointMatchMap.mobile
    ? LeaderboardSkeletonMobile
    : breakpointMatchMap.tablet
    ? LeaderboardSkeletonTablet
    : LeaderboardSkeletonDesktop;

  return (
    <Flex
      py={"16px"}
      px={"24px"}
      sx={{
        size: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
      }}
    >
      <Image src={skeletonSource} />
      <Box sx={{ position: "absolute" }}>
        <Loader />
      </Box>
    </Flex>
  );
}

export default DirectorySkeleton;
