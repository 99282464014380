import { ReactElement } from "react";

import pitchSchema from "../../../../../../../schemas/v1-0-0/dealTerms/pitch.json";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import TextareaInput from "../../../../../components/01_Core/Forms/Inputs/TextareaInput";
import useSchemaValidation from "../../../../../components/01_Core/Forms/utils/useSchemaValidation";
import { IDealMerchandisingFieldProps } from "../../06_Deal_Merchandising/DealMerchandisingFormPage";
import FormSection from "./FormSection";

export const pitchFieldName = "pitch";
type PitchType = string;

export type IOfferFormYourPitchProps = IDealMerchandisingFieldProps;

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=704%3A416
 */
function OfferFormYourPitch(props: IOfferFormYourPitchProps): ReactElement {
  const validator = useSchemaValidation<PitchType>(pitchSchema, false);

  return (
    <FormSection heading={"Fundraising Pitch"} size={props.size}>
      <FieldBuilder
        inputField={TextareaInput}
        fieldName={pitchFieldName}
        inputFieldProps={{
          size: props.size,
          placeholder: "Provide some details about your project...",
          caption:
            "Optional. Describe why partners should invest in your project."
        }}
        validator={validator}
        suppressValidationText={true}
      />
    </FormSection>
  );
}

export default OfferFormYourPitch;
