import { OfferDealArtistReleases_query$key } from "__generated__/OfferDealArtistReleases_query.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { Text, useThemeUI } from "theme-ui";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import { ActorAvatarIcon } from "../../../../../components/01_Core/Users/AvatarIcon";
import {
  ActorDisplayName,
  actorDisplayNameCssId
} from "../../../../../components/01_Core/Users/AvatarName";
import { lineClampStyles } from "../../../../../utils/utils";
import useFeaturedDealTitle from "../../../hooks/useFeaturedDealTitle";
import { getBookmarkElement } from "./BookmarkIcon";

export function OfferDealArtistReleases(props: {
  query: OfferDealArtistReleases_query$key;
  link?: string;
}): ReactElement {
  const { theme } = useThemeUI();

  const featuredDeal = useFragment(
    graphql`
      fragment OfferDealArtistReleases_query on OfferFeaturedDealInterface {
        artist {
          displayName
          isWatchingCurrentUserCompany
          ...AvatarIcon_actor
        }
        ...useFeaturedDealTitle
      }
    `,
    props.query
  );

  const featuredDealTitleFormatted = useFeaturedDealTitle(featuredDeal);
  const featuredDealTitleUnformatted = useFeaturedDealTitle(
    featuredDeal,
    false
  );

  const avatarName = (
    <AutoLayout
      spacing={16}
      dependentProps={{ direction: "horizontal", alignment: "left" }}
      sx={{
        width: "100%",
        ...(props.link && {
          cursor: "pointer",
          ":hover": {
            [`#${actorDisplayNameCssId}`]: {
              borderColor: "black100"
            }
          }
        })
      }}
    >
      <ActorAvatarIcon
        actor={featuredDeal.artist}
        size={"small"}
        badge={
          featuredDeal.artist.isWatchingCurrentUserCompany && {
            Icon: getBookmarkElement(true),
            title: "Watches your company",
            bg: theme.colors.warning05 as string,
            color: theme.colors.warning50 as string,
            size: "32%"
          }
        }
      />
      <AutoLayout
        spacing={4}
        dependentProps={{ direction: "vertical" }}
        sx={{
          width: "100%"
        }}
      >
        <ActorDisplayName
          displayName={featuredDeal.artist.displayName}
          size={"small"}
          hasLink={!!props.link}
        />
        <Text
          variant={"bodyMedium"}
          color={"black100"}
          sx={lineClampStyles(1)}
          title={featuredDealTitleUnformatted.join("")}
        >
          {featuredDealTitleFormatted.map((t, i) => (
            <Text key={i}>{t}</Text>
          ))}
        </Text>
      </AutoLayout>
    </AutoLayout>
  );

  return props.link ? <Link to={props.link}> {avatarName} </Link> : avatarName;
}
