import { CompanyTeam_company$key } from "__generated__/CompanyTeam_company.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";
import ReferralInput from "./ReferralInput";
import UserListCompanyTeam from "./UserListCompanyTeam";

interface ICompanyTeamProps {
  company: CompanyTeam_company$key;
}

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=1368%3A14159
 */
function CompanyTeam(props: ICompanyTeamProps): ReactElement {
  const company = useFragment(
    graphql`
      fragment CompanyTeam_company on CompanyNode {
        displayName
        ...UserListCompanyTeam_company
      }
    `,
    props.company
  );

  return (
    <AccountManagementFormContent>
      <AccountManagementHeading
        key={"heading"}
        size={["small", "small", "small", "large"]}
        title={`${company.displayName} Team`}
        description={"See who from your company has joined indify."}
      />
      <ReferralInput
        size={["small", "small", "small", "large"]}
        typename="PartnerToCompanyPartnerReferralNode"
      />
      <UserListCompanyTeam company={company} />
    </AccountManagementFormContent>
  );
}

export default CompanyTeam;
