import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Text } from "theme-ui";

import Ic24Close from "../../../../../imgs/icons/ic24-close.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { IconBox } from "../../../../components/01_Core/Foundations/Icons";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { useFindActiveRV } from "../../../../utils/responsiveUtils";
import { OfferFormHeaderSize } from "../03_UI_Kit/Offers/OfferFormHeader";

interface IDealMerchandisingFormHeaderProps {
  size: ResponsiveValue<OfferFormHeaderSize>;
  closeModal: () => void;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=1014%3A19201
 */
function DealMerchandisingFormHeader(
  props: IDealMerchandisingFormHeaderProps
): ReactElement {
  const headerTextVariant = useFindActiveRV(props.size, getHeaderTextVariant);

  return (
    <AutoLayout
      spacing={0}
      dependentProps={{ direction: "vertical", alignment: "topCenter" }}
      resizingX={FillContainer()}
      sx={{
        backgroundColor: "lightGray100"
      }}
    >
      <FixedGrid py={"24px"}>
        <Text
          variant={headerTextVariant}
          color={"black100"}
          px={"24px"}
          sx={{
            gridColumn: [
              "1 / span 4",
              "2 / span 6",
              "2 / span 6",
              "4 / span 6"
            ],
            textAlign: "center",
            order: [2, 1, 1, 1]
          }}
        >
          Propose a deal
        </Text>
        <IconBox
          icon={Ic24Close}
          onClick={props.closeModal}
          sx={{
            cursor: "pointer",
            size: "24px",
            gridColumn: [
              "4 / span 1",
              "8 / span 1",
              "8 / span 1",
              "10 / span 1"
            ],
            justifySelf: ["end", "center", "center", "center"],
            order: [1, 2, 2, 2]
          }}
        />
      </FixedGrid>
      <Divider />
    </AutoLayout>
  );
}

function getHeaderTextVariant(size: OfferFormHeaderSize) {
  switch (size) {
    case "small":
      return "h500";
    case "medium":
      return "h600";
  }
}

export default DealMerchandisingFormHeader;
