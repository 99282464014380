/**
 * @generated SignedSource<<ac14789d40dc36a6d18154107f8f9bca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChatThreadMarkReadMutation$variables = {
  chatNodeId: string;
};
export type ChatThreadMarkReadMutation$data = {
  readonly markRead: {
    readonly chat: {
      readonly hasUnreadMessages: boolean | null;
      readonly id: string;
    } | null;
    readonly user: {
      readonly id: string;
      readonly numUnreadMessages: number | null;
    } | null;
  } | null;
};
export type ChatThreadMarkReadMutation = {
  response: ChatThreadMarkReadMutation$data;
  variables: ChatThreadMarkReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chatNodeId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "chatNodeId",
        "variableName": "chatNodeId"
      }
    ],
    "concreteType": "MarkReadMutation",
    "kind": "LinkedField",
    "name": "markRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChatNode",
        "kind": "LinkedField",
        "name": "chat",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasUnreadMessages",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numUnreadMessages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatThreadMarkReadMutation",
    "selections": (v2/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatThreadMarkReadMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "65f37eaafd6f4c18439c7ec8faf55403",
    "id": null,
    "metadata": {},
    "name": "ChatThreadMarkReadMutation",
    "operationKind": "mutation",
    "text": "mutation ChatThreadMarkReadMutation(\n  $chatNodeId: ID!\n) {\n  markRead(chatNodeId: $chatNodeId) {\n    chat {\n      id\n      hasUnreadMessages\n    }\n    user {\n      id\n      numUnreadMessages\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c00b0ca1176ba1a1088ee36d54e63b54";

export default node;
