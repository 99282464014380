/**
 * @generated SignedSource<<79f47fc783e855aec7a93b83b2d8e1ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReferralTypename = "PartnerToArtistReferralNode" | "PartnerToCompanyPartnerReferralNode" | "PartnerToPartnerReferralNode";
export type ReferralRecipientInvitedMutation$variables = {
  referralNodeId: string;
  typename: ReferralTypename;
};
export type ReferralRecipientInvitedMutation$data = {
  readonly referralDelete: {
    readonly user: {
      readonly partner: {
        readonly company: {
          readonly partnerCompanyReferrals: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
              } | null;
            } | null>;
          };
        } | null;
        readonly partnerReferrals: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null>;
        };
        readonly partnerReferralsMaxAllowed: number;
      } | null;
    } | null;
  } | null;
};
export type ReferralRecipientInvitedMutation = {
  response: ReferralRecipientInvitedMutation$data;
  variables: ReferralRecipientInvitedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "referralNodeId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typename"
},
v2 = [
  {
    "kind": "Variable",
    "name": "referralNodeId",
    "variableName": "referralNodeId"
  },
  {
    "kind": "Variable",
    "name": "typename",
    "variableName": "typename"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partnerReferralsMaxAllowed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PartnerToPartnerReferralNodeConnection",
  "kind": "LinkedField",
  "name": "partnerReferrals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerToPartnerReferralNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerToPartnerReferralNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PartnerToCompanyPartnerReferralNodeConnection",
  "kind": "LinkedField",
  "name": "partnerCompanyReferrals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PartnerToCompanyPartnerReferralNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PartnerToCompanyPartnerReferralNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferralRecipientInvitedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ReferralDelete",
        "kind": "LinkedField",
        "name": "referralDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PartnerNode",
                "kind": "LinkedField",
                "name": "partner",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyNode",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReferralRecipientInvitedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ReferralDelete",
        "kind": "LinkedField",
        "name": "referralDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PartnerNode",
                "kind": "LinkedField",
                "name": "partner",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyNode",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33ff19d892fa48f97f2e7afe46158615",
    "id": null,
    "metadata": {},
    "name": "ReferralRecipientInvitedMutation",
    "operationKind": "mutation",
    "text": "mutation ReferralRecipientInvitedMutation(\n  $typename: ReferralTypename!\n  $referralNodeId: ID!\n) {\n  referralDelete(typename: $typename, referralNodeId: $referralNodeId) {\n    user {\n      partner {\n        partnerReferralsMaxAllowed\n        partnerReferrals {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        company {\n          partnerCompanyReferrals {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5311d2a89e3028f29d11826e292bc49e";

export default node;
