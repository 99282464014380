/**
 * @generated SignedSource<<5d6b761877c7a732e5026e471250cbb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseDetails_release$data = {
  readonly isVisible: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"useReleaseMetadata_release">;
  readonly " $fragmentType": "ReleaseDetails_release";
};
export type ReleaseDetails_release$key = {
  readonly " $data"?: ReleaseDetails_release$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseDetails_release">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleaseDetails_release",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVisible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReleaseMetadata_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "671ebb37dd8a1e89890d990b8722fafd";

export default node;
