import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Spinner, SpinnerProps } from "theme-ui";

import { useFindActiveRV } from "../../../utils/responsiveUtils";

export type LoaderSize = "small" | "medium" | "large" | "extraLarge";

interface ILoaderProps extends Omit<SpinnerProps, "size"> {
  size?: ResponsiveValue<LoaderSize>;
}

/**
 * TODO: implement contrast
 *
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1281%3A2387
 */
export function Loader(props: ILoaderProps): ReactElement {
  const { size, ...spinnerProps } = props;
  const loaderSize = useFindActiveRV(size ?? "extraLarge", getLoaderSize);

  return <Spinner strokeWidth={2} size={loaderSize} {...spinnerProps} />;
}

function getLoaderSize(size: LoaderSize): number {
  switch (size) {
    case "small":
      return 16;
    case "medium":
      return 20;
    case "large":
      return 24;
    case "extraLarge":
      return 48;
  }
}
