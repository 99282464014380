/**
 * @generated SignedSource<<75bb2b2e0e1695ff388349ba1f99503a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferralFlowSwitch_referral$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"IndifyToArtistReferralFlow_referral" | "IndifyToPartnerReferralFlow_referral" | "PartnerToCompanyPartnerReferralFlowFragment_referral">;
  readonly " $fragmentType": "ReferralFlowSwitch_referral";
};
export type ReferralFlowSwitch_referral$key = {
  readonly " $data"?: ReferralFlowSwitch_referral$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralFlowSwitch_referral">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralFlowSwitch_referral",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IndifyToArtistReferralFlow_referral"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IndifyToPartnerReferralFlow_referral"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PartnerToCompanyPartnerReferralFlowFragment_referral"
    }
  ],
  "type": "ReferralInterface",
  "abstractKey": "__isReferralInterface"
};

(node as any).hash = "2bc9ed67de3e037510aa545481849d87";

export default node;
