import { CaseStudyOffer_company$key } from "__generated__/CaseStudyOffer_company.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import SelectInput from "../../../../../components/01_Core/Forms/Inputs/SelectInput";
import { Field } from "../../../../../components/01_Core/Forms/utils/reactFinalFormWrappers";
import { useOfferName } from "../../../hooks/useOfferName";

export default function CaseStudyOffer(props: {
  company: CaseStudyOffer_company$key;
}): ReactElement {
  const company = useFragment(
    graphql`
      fragment CaseStudyOffer_company on CompanyNode {
        offers(isAccepted: true) {
          edges {
            node {
              id
              ...useOfferName
            }
          }
        }
      }
    `,
    props.company
  );
  const options = company.offers.edges.map(({ node }) => {
    return {
      label: useOfferName(node, false).join(""),
      value: node.id
    };
  });
  return (
    <Field name={"caseStudyOffer"}>
      {({ input, meta }) => {
        const currentValue = options.find(o => o.value === input.value.value);
        return (
          <SelectInput
            size={"large"}
            label={"Featured partnership"}
            caption={
              "Optional. Your profile will show the streaming performance for tracks included in this partnership, before and after the partnership was signed."
            }
            input={{
              ...input,
              onChange: input.onChange,
              value: currentValue
            }}
            meta={meta}
            options={options}
            isClearable={true}
          />
        );
      }}
    </Field>
  );
}
