import { FeaturedDealDirectoryPaginationQuery } from "__generated__/FeaturedDealDirectoryPaginationQuery.graphql";
import { FeaturedDealDirectoryQuery as FeaturedDealDirectoryQueryType } from "__generated__/FeaturedDealDirectoryQuery.graphql";
import { FeaturedDealDirectoryRows_availableFeaturedDeals$key } from "__generated__/FeaturedDealDirectoryRows_availableFeaturedDeals.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  graphql,
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery
} from "react-relay";
import { Flex, Text } from "theme-ui";

import { Loader } from "../../../../components/01_Core/999_Miscellaneous/Loader";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";
import RowDeal from "../03_UI_Kit/Discover/RowDeal";
import {
  FeaturedDealDirectoryQuery,
  FeaturedDealSort
} from "./FeaturedDealDirectory";
import { IFeaturedDealsFilterForm } from "./FeaturedDealDirectoryFilters";

interface IFeaturedDealDirectoryRowsProps {
  sort: FeaturedDealSort;
  onlyWatching: boolean;
  queryRef: PreloadedQuery<FeaturedDealDirectoryQueryType>;
}

/**
 * figma: N/A
 */
function FeaturedDealDirectoryRows(
  props: IFeaturedDealDirectoryRowsProps
): ReactElement {
  const root = usePreloadedQuery<FeaturedDealDirectoryQueryType>(
    FeaturedDealDirectoryQuery,
    props.queryRef
  );

  const {
    data: refetchableData,
    loadNext,
    hasNext,
    refetch
  } = usePaginationFragment<
    FeaturedDealDirectoryPaginationQuery,
    FeaturedDealDirectoryRows_availableFeaturedDeals$key
  >(
    graphql`
      fragment FeaturedDealDirectoryRows_availableFeaturedDeals on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        orderBy: { type: "String", defaultValue: "-date" }
        onlyWatching: { type: "Boolean", defaultValue: false }
        genres: { type: "[ID]" }
        releasesCount: { type: "[FeaturedDealReleasesCountRange]" }
        streamsThisWeek: { type: "[FeaturedDealStreamsThisWeekRange]" }
        spotifyMonthlyListeners: {
          type: "[FeaturedDealSpotifyMonthlyListenersRange]"
        }
        tiktokPosts: { type: "[FeaturedDealTiktokPostsRange]" }
        tiktokFollowers: { type: "[FeaturedDealTiktokFollowersRange]" }
      )
      @refetchable(queryName: "FeaturedDealDirectoryPaginationQuery") {
        availableFeaturedDeals(
          first: $count
          after: $cursor
          orderBy: $orderBy
          onlyWatching: $onlyWatching
          genres: $genres
          releasesCount: $releasesCount
          streamsThisWeek: $streamsThisWeek
          spotifyMonthlyListeners: $spotifyMonthlyListeners
          tiktokPosts: $tiktokPosts
          tiktokFollowers: $tiktokFollowers
        ) @connection(key: "FeaturedDealDirectory_availableFeaturedDeals") {
          edges {
            node {
              id
              ...RowDeal_featuredDeal
            }
          }
        }
      }
    `,
    root
  );

  const filterSettings = useFormState<IFeaturedDealsFilterForm>().values;
  const filterValues = _.mapValues(
    filterSettings,
    (value, key: keyof IFeaturedDealsFilterForm) => {
      switch (key) {
        case "genres":
          return (value as IFeaturedDealsFilterForm["genres"]).map(
            v => v.label
          );
        default:
          return Object.keys(value).filter(
            k => value[k as keyof typeof value]
          ) as any;
      }
    }
  );

  const { sort, ...rest } = props;
  const fetchProps = {
    orderBy: `${sort.dir === "desc" ? "-" : ""}${sort.type}`,
    ...filterValues,
    ...rest
  };

  useDidUpdateEffect(() => {
    refetch(fetchProps);
  }, [sort, filterSettings]);

  useDidUpdateEffect(() => {
    refetch(fetchProps, {
      // If a user has watched a deal since last fetch, they won't
      // see it in the list unless we force a network refetch
      fetchPolicy: "network-only"
    });
  }, [props.onlyWatching]);

  return (
    <InfiniteScroll
      next={() => loadNext(25)}
      hasMore={hasNext}
      loader={
        <Flex
          my={"24px"}
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </Flex>
      }
      dataLength={refetchableData.availableFeaturedDeals.edges.length}
      style={{
        overflow: "initial"
      }}
    >
      {[
        ...(refetchableData.availableFeaturedDeals.edges.length === 0
          ? [
              <Flex
                key="no-deals"
                sx={{
                  justifyContent: "center",
                  width: "100%",
                  padding: "24px"
                }}
              >
                <Text variant="bodyMedium" color="deepGray100">
                  No deals found. Try adjusting your filters.
                </Text>
              </Flex>
            ]
          : []),
        ...refetchableData.availableFeaturedDeals.edges
          .map(e => e.node)
          .map(fd => {
            return (
              <RowDeal
                key={fd.id}
                featuredDeal={fd}
                size={["small", "medium", "medium", "large"]}
              />
            );
          })
      ]}
    </InfiniteScroll>
  );
}

export default FeaturedDealDirectoryRows;
