/**
 * @generated SignedSource<<30a1c10387f8762893303c109c0d50d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InboxChats_chats$data = ReadonlyArray<{
  readonly id: string;
  readonly lastMessageSentAt: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"InboxChat_chat">;
  readonly " $fragmentType": "InboxChats_chats";
}>;
export type InboxChats_chats$key = ReadonlyArray<{
  readonly " $data"?: InboxChats_chats$data;
  readonly " $fragmentSpreads": FragmentRefs<"InboxChats_chats">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InboxChats_chats",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastMessageSentAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InboxChat_chat"
    }
  ],
  "type": "ChatNode",
  "abstractKey": null
};

(node as any).hash = "482755a2816e3db1e630cdf70deb74bc";

export default node;
