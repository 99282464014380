import { ReactElement, ReactNode } from "react";
import { Flex, Text } from "theme-ui";

interface IMessageBodyProps {
  sender?: string;
  children: ReactNode;
}

const MessageBody = (props: IMessageBodyProps): ReactElement => {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      {props.sender != null && (
        <Text variant={"secondary.bodyMedium"}>{props.sender}</Text>
      )}
      <Text
        variant={"primary.bodyMedium"}
        py={props.sender == null ? "8px" : 0}
      >
        {props.children}
      </Text>
    </Flex>
  );
};

export default MessageBody;
