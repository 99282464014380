/**
 * @generated SignedSource<<83985dba88166d733e695ba45027ef39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArtistPartnershipStatus = "ACTIVELY_LOOKING" | "NOT_LOOKING" | "OPEN";
export type ArtistSeekingServiceService = "CONTENT_PRODUCTION" | "DISTRIBUTION" | "FUNDING" | "INDUSTRY" | "INFLUENCER" | "MARKETING";
import { FragmentRefs } from "relay-runtime";
export type BasicInfoFormArtist_artist$data = {
  readonly about: string | null;
  readonly displayName: string;
  readonly genre: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly location: string | null;
  readonly partnershipStatus: ArtistPartnershipStatus;
  readonly seekingServices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly service: ArtistSeekingServiceService;
      } | null;
    } | null>;
  };
  readonly slug: string;
  readonly thumbnailUrl: string;
  readonly visible: boolean;
  readonly " $fragmentType": "BasicInfoFormArtist_artist";
};
export type BasicInfoFormArtist_artist$key = {
  readonly " $data"?: BasicInfoFormArtist_artist$data;
  readonly " $fragmentSpreads": FragmentRefs<"BasicInfoFormArtist_artist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BasicInfoFormArtist_artist",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "about",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GenreNode",
      "kind": "LinkedField",
      "name": "genre",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnershipStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistSeekingServiceNodeConnection",
      "kind": "LinkedField",
      "name": "seekingServices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArtistSeekingServiceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArtistSeekingServiceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "service",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ArtistNode",
  "abstractKey": null
};
})();

(node as any).hash = "1b27152d440ad95cc128b6a6aea8f919";

export default node;
