/**
 * @generated SignedSource<<c4c6b5a7aa43ff5abb734991cf16da53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleaseIconsNames_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseIconsNames_releaseImages" | "useFeaturedDealTitle">;
  readonly " $fragmentType": "ReleaseIconsNames_query";
};
export type ReleaseIconsNames_query$key = {
  readonly " $data"?: ReleaseIconsNames_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseIconsNames_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleaseIconsNames_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseIconsNames_releaseImages"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeaturedDealTitle"
    }
  ],
  "type": "OfferFeaturedDealInterface",
  "abstractKey": "__isOfferFeaturedDealInterface"
};

(node as any).hash = "e253a3c3f53a4ae4a0e829d1801f47d4";

export default node;
