import { cloneElement, ReactElement } from "react";

import { Tooltip } from "../components/01_Core/Tooltips/Tooltip";
import useCanAccessMarketplace from "../pages/indify/hooks/useCanAccessMarketplace";

export default function MarketplaceAccessGate(props: {
  styles?: Parameters<typeof Tooltip>[0]["styles"];
  children: ReactElement & { disabled?: boolean };
}): ReactElement {
  const [canAccessMarketplace, cannotAccessMarketplaceText] =
    useCanAccessMarketplace();

  return (
    <Tooltip
      content={cannotAccessMarketplaceText}
      size="medium"
      styles={{
        cursor: canAccessMarketplace ? undefined : "not-allowed",
        ...props.styles
      }}
    >
      {cloneElement(props.children, {
        disabled: props.children.disabled || !canAccessMarketplace
      })}
    </Tooltip>
  );
}
