import {
  FundInfo_fund$data,
  FundInfo_fund$key
} from "__generated__/FundInfo_fund.graphql";
import { FundInfo_FundMetrics_fund$key } from "__generated__/FundInfo_FundMetrics_fund.graphql";
import * as dayjs from "dayjs";
import _ from "lodash";
import { Fragment, ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Grid, Text } from "theme-ui";

import Ic24Suitcase from "../../../../../imgs/icons/ic24-suitcase.svg";
import Ic24VolumeMax from "../../../../../imgs/icons/ic24-volume-max.svg";
import AutoLayout, {
  FillContainer,
  Fixed
} from "../../../../components/01_Core/AutoLayout";
import { DotLabel } from "../../../../components/01_Core/Badges_and_Tags/DotLabel";
import SubtleBadge, {
  SubtleBadgeSize
} from "../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { getUserActiveDotProps } from "../../../../components/01_Core/Users/AvatarIcon";
import { ActorAvatarName } from "../../../../components/01_Core/Users/AvatarName";
import DisplayAvatar from "../../../../components/01_Core/Users/DisplayAvatar";
import Linkify from "../../../../utils/Linkify";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { useBreakpoint } from "../../../../utils/useBreakpoints";
import { formatNumeral, toSentence } from "../../../../utils/utils";
import ShowMore from "../03_UI_Kit/Actions/ShowMore";
import About from "./About";
import SocialLinks from "./SocialLinks";

type FundInfoSize = "small" | "large";

interface IFundInfoProps {
  fund: FundInfo_fund$key;
  size: ResponsiveValue<FundInfoSize>;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=1903%3A44307
 */
export default function FundInfo(props: IFundInfoProps): ReactElement {
  const fund = useFragment(
    graphql`
      fragment FundInfo_fund on CompanyNode {
        displayName
        services {
          edges {
            node {
              service
            }
          }
        }
        genres {
          edges {
            node {
              genre
            }
          }
        }
        thumbnailUrl
        about
        websiteUrl
        ...FundInfo_FundMetrics_fund
        profiles {
          edges {
            node {
              ...SocialLinks_profiles
            }
          }
        }
        partners(isActive: true) {
          edges {
            node {
              id
              user {
                lastActiveAt
              }
              ...AvatarName_actor
            }
          }
        }
        artistsBacked {
          id
          ...AvatarName_actor
        }
      }
    `,
    props.fund
  );

  const activeSize = useActiveResponsiveValue(props.size);
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const numArtistsToShow = 5;

  const breakpointMatchMap = useBreakpoint();

  const serviceLabels = fund.services.edges.map(({ node }, i) => (
    <strong key={i}>
      {GLOBALS.PARTNER_SERVICES[node.service].displayName}
    </strong>
  ));

  const genreLabels = fund.genres.edges.map(({ node }, i) => (
    <strong key={i}>{node.genre}</strong>
  ));

  const lastActiveAt = _.max(
    fund.partners.edges.map(({ node }) => node.user?.lastActiveAt)
  );
  const activeDotProps = getUserActiveDotProps(lastActiveAt);

  const badgeSize = [
    "small",
    "medium",
    "medium",
    "medium"
  ] as SubtleBadgeSize[];
  const iconStyle = { width: "1.1em", height: "1.1em" };

  function RosterArtist(rosterArtistProps: {
    artist: FundInfo_fund$data["artistsBacked"][number];
  }) {
    const { artist } = rosterArtistProps;
    return <ActorAvatarName actor={artist} size={"large"} />;
  }

  return (
    <AutoLayout
      spacing={48}
      dependentProps={{ direction: "vertical", alignment: "topCenter" }}
      resizingX={FillContainer()}
      sx={{ paddingY: "32px", paddingX: activeSize === "large" ? "0" : "16px" }}
    >
      <AutoLayout
        spacing={activeSize === "small" ? 24 : 16}
        dependentProps={{ direction: "vertical", alignment: "topCenter" }}
      >
        <Text
          variant={activeSize === "large" ? "h900" : "h700"}
          sx={{ textAlign: "center" }}
        >
          {fund.displayName}
        </Text>
        <AutoLayout
          spacing={12}
          dependentProps={{
            direction: breakpointMatchMap.mobile ? "vertical" : "horizontal",
            alignment: "center"
          }}
        >
          {activeDotProps && (
            <SubtleBadge size={badgeSize} color="gray">
              <DotLabel dotProps={activeDotProps}>
                {`Active ${dayjs(lastActiveAt).fromNow()}`}
              </DotLabel>
            </SubtleBadge>
          )}
          {!!genreLabels.length && (
            <SubtleBadge
              size={badgeSize}
              color={"gray"}
              icon={<Ic24VolumeMax style={iconStyle} />}
            >
              {genreLabels.length > 1 ? "Genres" : "Genre"}:{" "}
              {toSentence(genreLabels).map((node, i) => (
                <Fragment key={i}>{node}</Fragment>
              ))}
            </SubtleBadge>
          )}
          {!!serviceLabels.length && (
            <SubtleBadge
              size={badgeSize}
              color={"blue"}
              icon={<Ic24Suitcase style={iconStyle} />}
            >
              {serviceLabels.length > 1 ? "Services" : "Service"}:{" "}
              {toSentence(serviceLabels).map((node, i) => (
                <Fragment key={i}>{node}</Fragment>
              ))}
            </SubtleBadge>
          )}
        </AutoLayout>
      </AutoLayout>
      <DisplayAvatar
        size={activeSize === "large" ? "extraLarge" : "large"}
        imageUrl={fund.thumbnailUrl}
      />
      <FundMetrics fund={fund} />
      {fund.profiles.edges.length > 0 && (
        <SocialLinks profiles={fund.profiles.edges.map(e => e.node)} />
      )}
      {/* People Block */}
      <AutoLayout
        spacing={activeSize === "small" ? 32 : 0}
        dependentProps={{
          direction: activeSize === "small" ? "vertical" : "horizontal"
        }}
        resizingX={FillContainer()}
      >
        <AutoLayout
          spacing={16}
          dependentProps={{ direction: "vertical" }}
          resizingX={Fixed(activeSize === "small" ? "100%" : "50%")}
        >
          <Text variant={"h400"}>Team</Text>
          {fund.partners.edges
            .map(e => e.node)
            .map(p => (
              <ActorAvatarName
                key={p.id}
                actor={p}
                size={"large"}
                caption={false}
                disableLink
              />
            ))}
        </AutoLayout>
        {!!fund.artistsBacked.length && (
          <AutoLayout
            spacing={16}
            dependentProps={{ direction: "vertical" }}
            resizingX={Fixed(activeSize === "small" ? "100%" : "50%")}
          >
            <Text variant={"h400"}>Artist Roster</Text>
            {fund.artistsBacked
              .slice(0, expanded ? undefined : numArtistsToShow)
              .map(a => (
                <RosterArtist key={a.id} artist={a} />
              ))}
            {fund.artistsBacked.length > numArtistsToShow && (
              <ShowMore
                onClick={toggleExpanded}
                expanded={expanded}
                size={activeSize}
              />
            )}
          </AutoLayout>
        )}
      </AutoLayout>
      {fund.about && <Divider />}
      {fund.about && <About size={props.size} about={fund.about} />}
      <Text
        variant={activeSize === "small" ? "bodyMedium" : "bodyLarge"}
        sx={{ textAlign: "center" }}
      >
        <Linkify>{fund.websiteUrl}</Linkify>
      </Text>
    </AutoLayout>
  );
}

function FundMetrics(props: {
  fund: FundInfo_FundMetrics_fund$key;
}): ReactElement {
  const fund = useFragment(
    graphql`
      fragment FundInfo_FundMetrics_fund on CompanyNode {
        totalStreams
        ranked
        numReleasesInClosedDeals
        artistsBacked {
          __typename
        }
      }
    `,
    props.fund
  );

  return (
    <Grid
      columns={[2]}
      gap={32}
      p={24}
      sx={{
        backgroundColor: "white100",
        border: "1px solid",
        borderColor: "midGray100",
        borderRadius: "4px"
      }}
    >
      <AutoLayout
        spacing={4}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        sx={{
          margin: "0 auto"
        }}
      >
        <Text variant={"h400"}>
          {fund.totalStreams ? `#${fund.ranked}` : "—"}
        </Text>
        <Text variant={"bodySmall"} color={"deepGray100"}>
          indify Rank
        </Text>
      </AutoLayout>
      <AutoLayout
        spacing={4}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        sx={{
          margin: "0 auto"
        }}
      >
        <Text variant={"h400"}>
          {fund.artistsBacked.length ? fund.artistsBacked.length : 0}
        </Text>
        <Text variant={"bodySmall"} color={"deepGray100"}>
          Artists Serviced
        </Text>
      </AutoLayout>
      <AutoLayout
        spacing={4}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        sx={{
          margin: "0 auto"
        }}
      >
        <Text variant={"h400"}>
          {fund.numReleasesInClosedDeals > 0
            ? fund.numReleasesInClosedDeals
            : 0}
        </Text>
        <Text variant={"bodySmall"} color={"deepGray100"}>
          Releases Worked
        </Text>
      </AutoLayout>
      <AutoLayout
        spacing={4}
        dependentProps={{ direction: "vertical", alignment: "center" }}
        sx={{
          margin: "0 auto"
        }}
      >
        <Text variant={"h400"}>
          {fund.totalStreams ? formatNumeral(fund.totalStreams) : 0}
        </Text>
        <Text variant={"bodySmall"} color={"deepGray100"}>
          Total Company Streams
        </Text>
      </AutoLayout>
    </Grid>
  );
}
