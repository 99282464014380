/**
 * @generated SignedSource<<cd402fa534579f7c0d5e1b50165aa132>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedDealDirectoryRows_availableFeaturedDeals$data = {
  readonly availableFeaturedDeals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"RowDeal_featuredDeal">;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "FeaturedDealDirectoryRows_availableFeaturedDeals";
};
export type FeaturedDealDirectoryRows_availableFeaturedDeals$key = {
  readonly " $data"?: FeaturedDealDirectoryRows_availableFeaturedDeals$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedDealDirectoryRows_availableFeaturedDeals">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "availableFeaturedDeals"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "genres"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "onlyWatching"
    },
    {
      "defaultValue": "-date",
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "releasesCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "spotifyMonthlyListeners"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "streamsThisWeek"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tiktokFollowers"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tiktokPosts"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./FeaturedDealDirectoryPaginationQuery.graphql')
    }
  },
  "name": "FeaturedDealDirectoryRows_availableFeaturedDeals",
  "selections": [
    {
      "alias": "availableFeaturedDeals",
      "args": [
        {
          "kind": "Variable",
          "name": "genres",
          "variableName": "genres"
        },
        {
          "kind": "Variable",
          "name": "onlyWatching",
          "variableName": "onlyWatching"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "releasesCount",
          "variableName": "releasesCount"
        },
        {
          "kind": "Variable",
          "name": "spotifyMonthlyListeners",
          "variableName": "spotifyMonthlyListeners"
        },
        {
          "kind": "Variable",
          "name": "streamsThisWeek",
          "variableName": "streamsThisWeek"
        },
        {
          "kind": "Variable",
          "name": "tiktokFollowers",
          "variableName": "tiktokFollowers"
        },
        {
          "kind": "Variable",
          "name": "tiktokPosts",
          "variableName": "tiktokPosts"
        }
      ],
      "concreteType": "FeaturedDealNodeConnection",
      "kind": "LinkedField",
      "name": "__FeaturedDealDirectory_availableFeaturedDeals_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeaturedDealNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FeaturedDealNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RowDeal_featuredDeal"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "363459db76e4206a9e8596694287a6aa";

export default node;
