import { ActivateFormUserFromActivationKeyQuery } from "__generated__/ActivateFormUserFromActivationKeyQuery.graphql";
import { FORM_ERROR } from "final-form";
import { Path } from "history";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { fetchQuery, graphql } from "react-relay";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Flex, Link, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import FieldBuilder from "../../../../../components/01_Core/Forms/FieldBuilder";
import PasswordInput from "../../../../../components/01_Core/Forms/Inputs/PasswordInput";
import {
  composeFieldValidators,
  requiredStringValidator
} from "../../../../../components/01_Core/Forms/utils/validators";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import environment from "../../../../../environment";
import { useAuth } from "../../../hooks/useAuth";
import { ILocationState } from "./LoginPage";

export interface IActivateFormValues {
  password: string;
  passwordRepeat: string;
}

const passwordValidator = composeFieldValidators(
  requiredStringValidator,
  (password: string) =>
    password.length >= 8 ? undefined : "Password must be 8 or more characters"
);

const isSamePasswordValidator = (
  password: string,
  values: IActivateFormValues
) =>
  values.password === password ? undefined : "The passwords must be the same";

const userFromActivationKeyQuery = graphql`
  query ActivateFormUserFromActivationKeyQuery($activationKey: String) {
    userByActivationKey(activationKey: $activationKey) {
      id
    }
  }
`;

function ActivateForm(): ReactElement {
  const { activate } = useAuth();
  const { activationKey } = useParams<{ activationKey?: string }>();

  const [userFoundFromActivationKey, setUserFoundFromActivationKey] =
    useState<boolean>(false);
  const [defaultHeaderText, setDefaultHeaderText] = useState<string>("");

  useEffect(() => {
    fetchQuery<ActivateFormUserFromActivationKeyQuery>(
      environment,
      userFromActivationKeyQuery,
      { activationKey }
    )
      .toPromise()
      .then(response => {
        if (response.userByActivationKey) {
          setUserFoundFromActivationKey(true);
          setDefaultHeaderText(
            "Set a password to activate your indify account"
          );
        } else {
          setUserFoundFromActivationKey(false);
          setDefaultHeaderText("Activation link invalid or has expired.");
        }
      });
  }, []);

  const history = useHistory();
  const location = useLocation<ILocationState>();
  const from: Path = location.state?.from ?? "/";
  const onSubmit = (values: IActivateFormValues) => {
    return activate(activationKey, values.password).then(
      _ => {
        history.replace(from);
      },
      reason => {
        return { [FORM_ERROR]: reason?.kind ?? "Activate Failed" };
      }
    );
  };

  return (
    <Fragment>
      <GridItem sx={{ justifyContent: "center" }}>
        <Text variant={"h600"} mb={40} sx={{ textAlign: "center" }}>
          {defaultHeaderText}
        </Text>
      </GridItem>

      {userFoundFromActivationKey ? (
        <Fragment>
          <Form
            initialValues={{ password: "", passwordRepeat: "" }}
            onSubmit={onSubmit}
          >
            {(formProps: FormRenderProps<IActivateFormValues>) => (
              <form>
                <Flex
                  bg={"white100"}
                  sx={{
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #EAE8E6",
                    borderRadius: "4px",
                    overflow: "hidden"
                  }}
                >
                  <Box p={[24, 48]} sx={{ width: "100%" }}>
                    <AutoLayout
                      spacing={24}
                      dependentProps={{
                        direction: "vertical"
                      }}
                      resizingX={FillContainer()}
                    >
                      <FieldBuilder
                        inputField={PasswordInput}
                        fieldName={"password"}
                        validator={passwordValidator}
                        inputFieldProps={{
                          label: "Password",
                          placeholder: "Enter Password",
                          size: ["medium", "large", "large", "large"]
                        }}
                      />
                      <FieldBuilder
                        inputField={PasswordInput}
                        fieldName={"passwordRepeat"}
                        validator={isSamePasswordValidator}
                        inputFieldProps={{
                          label: "Repeat Password",
                          placeholder: "Repeat Password",
                          size: ["medium", "large", "large", "large"]
                        }}
                      />
                    </AutoLayout>
                    <Box mt={11} mb={26}>
                      <Text
                        variant={"h100"}
                        color={"#FF9900"}
                        sx={{
                          display:
                            formProps.hasSubmitErrors ||
                            formProps.hasValidationErrors
                              ? "initial"
                              : "none"
                        }}
                      >
                        {formProps.submitError === "bad_inputs" ? (
                          <Box>Please check the passwords you entered.</Box>
                        ) : null}
                      </Text>
                    </Box>
                    <LoadingButton
                      variant={"primary"}
                      size={"large"}
                      disabled={false}
                      onClick={formProps.handleSubmit}
                      loading={formProps.submitting}
                      sx={{
                        width: "100%"
                      }}
                    >
                      Activate and sign in
                    </LoadingButton>
                  </Box>
                </Flex>
              </form>
            )}
          </Form>
          <GridItem sx={{ justifyContent: "center" }}>
            <Text
              variant={"h200"}
              mt={"24px"}
              color={"black100"}
              sx={{ justifyContent: "center", textAlign: "center" }}
            >
              Have a question?
            </Text>
          </GridItem>
          <GridItem sx={{ justifyContent: "center" }}>
            <Text
              variant={"bodyMedium"}
              mt={"6px"}
              color={"black100"}
              sx={{ textAlign: "center" }}
            >
              You can always contact us at{" "}
              <Link href={""}>
                <Text
                  variant={"bodyMedium"}
                  color={"black100"}
                  sx={{ display: "inline", fontWeight: "bold" }}
                >
                  support@indify.io
                </Text>
              </Link>
            </Text>
          </GridItem>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

export default ActivateForm;
