import { PartnershipsGrid_offers$key } from "__generated__/PartnershipsGrid_offers.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text } from "theme-ui";

import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import DealCard from "../03_UI_Kit/Deals/DealCard";
import ProfileCardsGrid from "./ProfileCardsGrid";

type PartnershipsGridSize = "small" | "large";

interface IPartnershipsGridProps {
  size: ResponsiveValue<PartnershipsGridSize>;
  offers: PartnershipsGrid_offers$key;
}

/**
 * figma: https://www.figma.com/file/iwHx206j2lhnGJ6fNimtg2/04-User-Profile?node-id=1907%3A218085
 */
function PartnershipsGrid(props: IPartnershipsGridProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const offers = useFragment(
    graphql`
      fragment PartnershipsGrid_offers on OfferNode @relay(plural: true) {
        id
        dateOfferAccepted
        terms
        ...DealCard_offer
      }
    `,
    props.offers
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          paddingY: activeSize === "small" ? "48px" : "64px"
        }}
      >
        <Text
          variant={activeSize === "small" ? "h400" : "h600"}
          sx={{ textAlign: "center" }}
        >
          Partnerships
        </Text>
      </Flex>
      <ProfileCardsGrid>
        {offers.map(o => (
          <DealCard key={o.id} size={activeSize} offer={o} />
        ))}
      </ProfileCardsGrid>
    </Box>
  );
}

export default PartnershipsGrid;
