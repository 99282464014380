import { DealMerchandisingFormPage_artist$key } from "__generated__/DealMerchandisingFormPage_artist.graphql";
import { mutationsCreateFeaturedDealMutation } from "__generated__/mutationsCreateFeaturedDealMutation.graphql";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import { ReactElement } from "react";
import { useFormState } from "react-final-form";
import { commitMutation, graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Link as ExternalLink, Text } from "theme-ui";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import Wizard, {
  IWizardProps,
  WizardPage
} from "../../../../components/01_Core/Forms/Wizard";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import environment from "../../../../environment";
import { updateOrCreateFeaturedDealMutation } from "../../../../mutations/mutations";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { ExternalRoutes } from "../../Routing/ExternalRoutes";
import BlockLowEmphasis from "../03_UI_Kit/Marketing/BlockLowEmphasis";
import SimpleNav from "../03_UI_Kit/Navigation/SimpleNav";
import { FormSectionSize } from "../03_UI_Kit/Offers/FormSection";
import { OfferFormSize } from "../03_UI_Kit/Offers/OfferForm";
import OfferFormAdvance from "../03_UI_Kit/Offers/OfferFormAdvance/OfferFormAdvance";
import OfferFormExistingAgreements from "../03_UI_Kit/Offers/OfferFormExistingAgreements";
import OfferFormFooter from "../03_UI_Kit/Offers/OfferFormFooter";
import OfferFormManagerInfo from "../03_UI_Kit/Offers/OfferFormManagerInfo";
import OfferFormSoundLink from "../03_UI_Kit/Offers/OfferFormSoundLink";
import OfferFormSplits from "../03_UI_Kit/Offers/OfferFormSplits/OfferFormSplits";
import OfferFormTermLength from "../03_UI_Kit/Offers/OfferFormTermLength";
import OfferFormYourPitch from "../03_UI_Kit/Offers/OfferFormYourPitch";
import FeaturedDealFormReleasesIncluded from "../03_UI_Kit/Offers/ReleasesIncluded/FeaturedDealFormReleasesIncluded";
import TikTokSoundsIncluded from "../03_UI_Kit/Offers/TikTokSoundsIncluded/TikTokSoundsIncluded";
import { IFundingFormValues } from "../07_Deal_Flow/OfferFormFunding";
import DealMerchandisingFormHeader from "./DealMerchandisingFormHeader";

export type IFeaturedDealFormValues = IFundingFormValues & {
  tikTokSoundIds: string[];
  soundLink: string;
  tikTokSoundLink?: string;
  pitch: string;
  distributor?: string;
  distributionAgreement?: string;
  producerAgreement?: string;
  managerName?: string;
  managerCompany?: string;
  managerEmail?: string;
  managerLink?: string;
};

export interface IDealMerchandisingFieldProps {
  size: ResponsiveValue<FormSectionSize>;
  showAllErrors: boolean;
}

interface IDealMerchandisingFormPageProps {
  artist: DealMerchandisingFormPage_artist$key;
  closeModal: () => void;
  initialValues?: Partial<IFeaturedDealFormValues>;
  featuredDealId?: string;
}

/**
 * figma: https://www.figma.com/file/IF1kneOJMIUGtuGuRnMDqn/08-Account-Management?node-id=2810%3A27229
 */
function DealMerchandisingFormPage(
  props: IDealMerchandisingFormPageProps
): ReactElement {
  const responsiveFormSize: ResponsiveValue<OfferFormSize> = [
    "small",
    "medium",
    "medium",
    "medium"
  ];
  const responsiveFooterTextVariant: ResponsiveValue<string> = [
    "bodySmall",
    "bodyMedium",
    "bodyMedium",
    "bodyMedium"
  ];
  const activeFooterTextVariant = useActiveResponsiveValue(
    responsiveFooterTextVariant
  );
  const artist = useFragment(
    graphql`
      fragment DealMerchandisingFormPage_artist on ArtistNode {
        id
      }
    `,
    props.artist
  );

  const handleSubmit = (data: IFeaturedDealFormValues) => {
    return new Promise(resolve => {
      const resolveUnknownError = () =>
        resolve({ [FORM_ERROR]: "Something went wrong." });
      commitMutation<mutationsCreateFeaturedDealMutation>(environment, {
        mutation: updateOrCreateFeaturedDealMutation,
        variables: {
          data: {
            featuredDealId: props.featuredDealId,
            artistNodeId: artist.id,
            allReleases: data.allReleases.map(
              ({
                tempId: _skip,
                hasExistingPartnership: _skip1,
                hasExistingInvestmentPartnership: _skip2,
                ...rest
              }) => rest
            ),
            soundRecordingIds: Object.keys(
              _.pickBy(data.soundRecordingIds, Boolean)
            ),
            soundLink: data.soundLink,
            tiktokSoundIds: data.tikTokSoundIds,
            inPocketAmountRaising:
              data.advanceAmountCents && data.advanceAmountCents / 100,
            creativeAmountRaising:
              data.creativeAmountCents && data.creativeAmountCents / 100,
            marketingAmountRaising:
              data.marketingAmountCents && data.marketingAmountCents / 100,
            partnerSplitPreRecoupment:
              data.splitBeforeRecoupment?.partnerPercent,
            partnerSplitPostRecoupment:
              data.splitAfterRecoupment?.partnerPercent,
            termLengthMonths: data.termLength,
            whyInvestNow: data.pitch,
            distributor: data.distributor,
            distributionAgreement: data.distributionAgreement,
            producerAgreement: data.producerAgreement,
            managerName: data.managerName,
            managerCompany: data.managerCompany,
            managerEmail: data.managerEmail,
            managerLink: data.managerLink
          }
        },
        onCompleted: (response, errors) => {
          if (errors) {
            return resolveUnknownError();
          }

          props.closeModal();
          resolve(undefined);
        },
        onError: () => {
          resolveUnknownError();
        }
      });
    });
  };

  const pageLayout: IWizardProps<IFeaturedDealFormValues>["pageLayout"] =
    pageLayoutProps => {
      const formProps = useFormState<IFeaturedDealFormValues>();
      const { activePage } = pageLayoutProps;
      return (
        <Flex sx={{ flexDirection: "column", height: "100%" }}>
          <SimpleNav />
          <Flex
            sx={{
              flexDirection: "column",
              overflowY: "auto",
              alignItems: "center",
              width: "100%"
            }}
          >
            <DealMerchandisingFormHeader
              size={responsiveFormSize}
              closeModal={props.closeModal}
            />
            <Flex
              mt={4}
              sx={{
                justifyContent: "center",
                width: "100%"
              }}
            >
              <FixedGrid>
                <GridItem
                  sx={{
                    flexDirection: "column",
                    gridColumn: [
                      "1 / span 4",
                      "2 / span 6",
                      "2 / span 6",
                      "4 / span 6"
                    ]
                  }}
                >
                  <BlockLowEmphasis size={["small", "small", "large", "large"]}>
                    <AutoLayout
                      spacing={8}
                      dependentProps={{
                        direction: "vertical"
                      }}
                    >
                      <Text variant={"h400"}>Need some guidance?</Text>
                      <Text>
                        Check out our guide to creating a proposed deal{" "}
                        <ExternalLink
                          href={ExternalRoutes.dealGuide}
                          target={"_blank"}
                          sx={{
                            fontWeight: "bold",
                            textDecoration: "underline"
                          }}
                        >
                          found here
                        </ExternalLink>
                        .
                      </Text>
                    </AutoLayout>
                  </BlockLowEmphasis>
                  {activePage}
                </GridItem>
              </FixedGrid>
            </Flex>
            <OfferFormFooter
              {...pageLayoutProps}
              submitButtonLabel="Publish proposed deal"
              description={
                formProps.submitError ? (
                  <Text
                    variant={activeFooterTextVariant}
                    color={"alert50"}
                    sx={{ textAlign: "center", width: "100%" }}
                  >
                    {formProps.submitError}
                  </Text>
                ) : (
                  <Text
                    variant={activeFooterTextVariant}
                    color={"deepGray100"}
                    sx={{ textAlign: "center", width: "100%" }}
                  >
                    <Box>
                      Once your proposed deal is approved, funding partners on
                      indify will be able to see it and make you a funding
                      offer. Their proposed terms may differ from yours.
                    </Box>
                    <Box mt={"12px"}>
                      You will have the opportunity to review and negotiate any
                      received offers before accepting.
                    </Box>
                  </Text>
                )
              }
            />
          </Flex>
        </Flex>
      );
    };

  const pages: IWizardProps<IFeaturedDealFormValues>["pages"] = ({
    hasAttemptedSubmit
  }) => {
    const fieldProps: IDealMerchandisingFieldProps = {
      size: responsiveFormSize,
      showAllErrors: hasAttemptedSubmit
    };
    return [
      <WizardPage key="releases_agreements">
        <FeaturedDealFormReleasesIncluded {...fieldProps} artist={artist} />
        <Divider />
        <OfferFormExistingAgreements {...fieldProps} />
      </WizardPage>,
      <WizardPage key="sound_pitch">
        <TikTokSoundsIncluded {...fieldProps} artist={artist} />
        <Divider />
        <OfferFormSoundLink {...fieldProps} />
        <Divider />
        <OfferFormYourPitch {...fieldProps} />
      </WizardPage>,
      <WizardPage key="manager">
        <OfferFormManagerInfo {...fieldProps} />
      </WizardPage>,
      <WizardPage key="terms">
        <OfferFormAdvance
          {...fieldProps}
          heading={"Amount Raising"}
          placeholder={"—"}
        />
        <Divider />
        {/* If artist counter-offers are implemented at some point, should pass isRequired=true */}
        <OfferFormSplits
          {...fieldProps}
          povType={"asArtist"}
          isRequired={false}
        />
        <Divider />
        <OfferFormTermLength {...fieldProps} isRequired={false} />
      </WizardPage>
    ];
  };

  return (
    <Wizard<Partial<IFeaturedDealFormValues>>
      onSubmit={handleSubmit}
      initialValues={props.initialValues}
      pageLayout={pageLayout}
      pages={pages}
    />
  );
}

export default DealMerchandisingFormPage;
