/**
 * @generated SignedSource<<d3eca432894c531f4db0b05015a034af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReleaseTypeEnum = "album" | "compilation" | "single";
import { FragmentRefs } from "relay-runtime";
export type ArtistManageReleases_releasesSection$data = ReadonlyArray<{
  readonly __typename: string;
  readonly id: string;
  readonly releaseDate: any | null;
  readonly releaseType: ReleaseTypeEnum | null;
  readonly updatedAt: any;
  readonly " $fragmentSpreads": FragmentRefs<"ReleaseListItem_release">;
  readonly " $fragmentType": "ArtistManageReleases_releasesSection";
}>;
export type ArtistManageReleases_releasesSection$key = ReadonlyArray<{
  readonly " $data"?: ArtistManageReleases_releasesSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArtistManageReleases_releasesSection">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ArtistManageReleases_releasesSection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReleaseListItem_release"
    }
  ],
  "type": "ReleaseInterface",
  "abstractKey": "__isReleaseInterface"
};

(node as any).hash = "ac0f342a8c48a6cf6d3d7b9fe522c900";

export default node;
