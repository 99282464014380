import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, BoxProps, Text } from "theme-ui";

import { rvMap } from "../../../../../utils/responsiveUtils";

type MessageSize = "small" | "large";
interface IMessageProps extends BoxProps {
  size: ResponsiveValue<MessageSize>;
  alert?: boolean;
  children: ReactNode;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1350%3A3
 */
function BlockLowEmphasis(props: IMessageProps): ReactElement {
  const { size, alert, sx, children, ...rest } = props;

  return (
    <Box
      padding={rvMap(size, getPadding)}
      bg={alert ? "warning05" : "secondary10"}
      sx={{ ...sx, borderRadius: "4px" }}
      {...rest}
    >
      <Text variant={"bodyMedium"} color={"black100"}>
        {children}
      </Text>
    </Box>
  );
}

function getPadding(size: MessageSize) {
  switch (size) {
    case "small":
      return "16px";
    case "large":
      return "24px";
  }
}

export default BlockLowEmphasis;
