/**
 * @generated SignedSource<<3b6ca511a784823dc45ce88b30b42044>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfilePlatform = "FACEBOOK" | "INSTAGRAM" | "LUMINATE" | "SOUNDCLOUD" | "SPOTIFY" | "TIKTOK" | "TWITTER" | "YOUTUBE";
export type EditArtistSocialLinksMutationInput = {
  artistNodeId: string;
  socialLinks?: ArtistSocialLinks | null;
};
export type ArtistSocialLinks = {
  instagram?: string | null;
  soundcloud?: string | null;
  spotify?: string | null;
  tiktok?: string | null;
  twitter?: string | null;
  youtube?: string | null;
};
export type ArtistEditSocialLinksMutation$variables = {
  input: EditArtistSocialLinksMutationInput;
};
export type ArtistEditSocialLinksMutation$data = {
  readonly editArtistSocialLinks: {
    readonly artist: {
      readonly profiles: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly platform: ProfilePlatform;
            readonly url: string | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type ArtistEditSocialLinksMutation = {
  response: ArtistEditSocialLinksMutation$data;
  variables: ArtistEditSocialLinksMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNodeConnection",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "platform",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArtistEditSocialLinksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditArtistSocialLinksMutation",
        "kind": "LinkedField",
        "name": "editArtistSocialLinks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArtistEditSocialLinksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditArtistSocialLinksMutation",
        "kind": "LinkedField",
        "name": "editArtistSocialLinks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArtistNode",
            "kind": "LinkedField",
            "name": "artist",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e252b179be96445a312f842d961bc92d",
    "id": null,
    "metadata": {},
    "name": "ArtistEditSocialLinksMutation",
    "operationKind": "mutation",
    "text": "mutation ArtistEditSocialLinksMutation(\n  $input: EditArtistSocialLinksMutationInput!\n) {\n  editArtistSocialLinks(data: $input) {\n    artist {\n      profiles {\n        edges {\n          node {\n            id\n            url\n            platform\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcf31103020f51e4b79b698028a8d1fb";

export default node;
