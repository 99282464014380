/**
 * @generated SignedSource<<98db496b530c0521aba7ec0e87521377>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OfferStatus = "active" | "canceled" | "declined" | "legacy_incomplete_transfer" | "needs_artist_response" | "needs_earnings_directed" | "needs_invoice_creation" | "needs_invoice_payment" | "voided";
import { FragmentRefs } from "relay-runtime";
export type useOfferNextStep_offer$data = {
  readonly artist: {
    readonly user: {
      readonly id: string;
    } | null;
  };
  readonly moneyTransferAmountCents: number | null;
  readonly status: OfferStatus;
  readonly transferRecipient: {
    readonly user: {
      readonly id: string;
      readonly stripeConnectedAccountApiId: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useOfferNextStep_offer";
};
export type useOfferNextStep_offer$key = {
  readonly " $data"?: useOfferNextStep_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOfferNextStep_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOfferNextStep_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "moneyTransferAmountCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "transferRecipient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripeConnectedAccountApiId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArtistNode",
      "kind": "LinkedField",
      "name": "artist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OfferNode",
  "abstractKey": null
};
})();

(node as any).hash = "0458538ec4c328d6ecdc431a86b933f8";

export default node;
