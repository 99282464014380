import { css, Global } from "@emotion/react";
import { ReactElement } from "react";
import ReactModal from "react-modal";
import { useThemeUI } from "theme-ui";

import NBAkademieBold from "../../fonts/nb_akademie_bold.woff2";
import NBAkademieBoldItalic from "../../fonts/nb_akademie_bold_italic.woff2";
import NBAkademieNormal from "../../fonts/nb_akademie_normal.woff2";
import NBAkademieNormalItalic from "../../fonts/nb_akademie_normal_italic.woff2";
import { zIndices } from "./theme";

ReactModal.defaultStyles.overlay = {
  ...ReactModal.defaultStyles.overlay,
  backgroundColor: "rgba(0, 0, 0, 0.35)",
  height: "100%",
  width: "100%",
  zIndex: zIndices.modal
};

function GlobalStyles(): ReactElement {
  const { theme } = useThemeUI();

  return (
    <Global
      styles={css`
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }

        @font-face {
          font-family: "NB Akademie";
          src: url("${NBAkademieNormal}") format("woff2");
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: "NB Akademie";
          src: url("${NBAkademieNormalItalic}") format("woff2");
          font-weight: normal;
          font-style: italic;
        }

        @font-face {
          font-family: "NB Akademie";
          src: url("${NBAkademieBold}") format("woff2");
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: "NB Akademie";
          src: url("${NBAkademieBoldItalic}") format("woff2");
          font-weight: bold;
          font-style: italic;
        }

        html {
          height: 100%;
          font-family: "NB Akademie", Helvetica, Arial, sans-serif;
          color: ${theme.colors.black100};
        }

        body {
          height: 100%;
          display: flex;
          box-sizing: border-box;
          margin: 0;
          background-color: ${theme.colors.lightGray100};
        }

        :focus {
          outline: none;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      `}
    />
  );
}

export default GlobalStyles;
