import { Details_featuredDeal$key } from "__generated__/Details_featuredDeal.graphql";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Flex } from "theme-ui";

import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import KeyPerformanceStats from "../../03_UI_Kit/Deals/KeyPerformanceStats";
import Terms from "../../03_UI_Kit/Deals/Terms";
import WhyInvest from "../../03_UI_Kit/Deals/WhyInvest";
import TikTokData from "./TikTokData";

interface IDetailsProps {
  featuredDeal: Details_featuredDeal$key;
}

/**
 * figma: https://www.figma.com/file/oJysh9msx0e6spi4bOq2lJ/06-Deal-Merchandising?node-id=524%3A16257
 */
function Details(props: IDetailsProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment Details_featuredDeal on FeaturedDealNode {
        ...Terms_featuredDeal
        ...WhyInvest_featuredDeal
        artist {
          ...KeyPerformanceStats_artist
        }
        ...TikTokData_featuredDeal
      }
    `,
    props.featuredDeal
  );

  return (
    <Flex
      sx={{
        backgroundColor: "white100",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <FixedGrid
        sx={{
          gridTemplateAreas: [
            `
            "ter ter ter ter"
            "why why why why"
            "sta sta sta sta"
            "ttk ttk ttk ttk"
          `,
            `
            "why why why why ter ter ter ter"
            "sta sta sta sta ter ter ter ter"
            "ttk ttk ttk ttk ttk ttk ttk ttk"
          `,
            `
            "why why why why ter ter ter ter"
            "sta sta sta sta ter ter ter ter"
            "ttk ttk ttk ttk ttk ttk ttk ttk" 
          `,
            `
            "why why why why why why why .   ter ter ter ter"
            "sta sta sta sta sta sta sta .   ter ter ter ter"
            "ttk ttk ttk ttk ttk ttk ttk ttk ttk ttk ttk ttk"
          `
          ],
          rowGap: ["64px", "164px", "164px", "80px"]
        }}
        paddingY={["48px", "80px", "80px", "80px"]}
      >
        <GridItem gridArea={"ter"}>
          <Terms
            size={["small", "large", "large", "large"]}
            featuredDeal={featuredDeal}
          />
        </GridItem>
        <GridItem gridArea={"why"}>
          <WhyInvest
            size={["small", "large", "large", "large"]}
            featuredDeal={featuredDeal}
          />
        </GridItem>
        <GridItem gridArea={"sta"}>
          <KeyPerformanceStats
            size={["small", "small", "small", "large"]}
            artist={featuredDeal.artist}
          />
        </GridItem>
        <GridItem gridArea={"ttk"}>
          <TikTokData
            featuredDeal={featuredDeal}
            size={["small", "small", "small", "large"]}
          />
        </GridItem>
      </FixedGrid>
    </Flex>
  );
}

export default Details;
