import { Properties } from "csstype";
import { ReactElement } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";

import IndifyGlyph from "../../../imgs/Indiglyph/00_indify.svg";
import Spotlight from "../../../imgs/Indiglyph/01_spotlight.svg";
import Trending from "../../../imgs/Indiglyph/02_trending.svg";
import Partnership from "../../../imgs/Indiglyph/03_partnership.svg";
import Play from "../../../imgs/Indiglyph/04_play.svg";
import Marketplace from "../../../imgs/Indiglyph/05_marketplace.svg";
import Invest from "../../../imgs/Indiglyph/06_invest.svg";
import Earn from "../../../imgs/Indiglyph/07_earn.svg";

type IndiglyphColor = "black" | "white" | "sampled";
const IndiglyphTypes = [
  "00_indify",
  "01_spotlight",
  "02_trending",
  "03_partnership",
  "04_play",
  "05_marketplace",
  "06_invest",
  "07_earn"
] as const;
export type IndiglyphType = (typeof IndiglyphTypes)[number];

interface IIndiglyphProps {
  type: IndiglyphType;
  color: IndiglyphColor;
  sampleColor?: Properties["color"];
  sxOverrides?: ThemeUIStyleObject;
}

/**
 * figma: https://www.figma.com/file/YDPXSPWrJj8DCxUILLc9dQ/02-Brand-Assets?node-id=707%3A111
 */
function Indiglyph(props: IIndiglyphProps): ReactElement {
  const GlyphComponent: SvgrComponent = (() => {
    switch (props.type) {
      case "00_indify":
        return IndifyGlyph;
      case "01_spotlight":
        return Spotlight;
      case "02_trending":
        return Trending;
      case "03_partnership":
        return Partnership;
      case "04_play":
        return Play;
      case "05_marketplace":
        return Marketplace;
      case "06_invest":
        return Invest;
      case "07_earn":
        return Earn;
    }
  })();

  return (
    <Flex
      color={
        props.sampleColor
          ? props.sampleColor
          : props.color === "black"
          ? "black100"
          : "white100"
      }
      sx={props.sxOverrides}
    >
      <GlyphComponent height={"100%"} width={"100%"} viewBox={"0 0 296 296"} />
    </Flex>
  );
}

export default Indiglyph;
