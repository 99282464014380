/**
 * @generated SignedSource<<5aacd135583b6a04e854d4aee07c31d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomebasePartners_partners$data = {
  readonly homebasePartners: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"HomebasePartners_partner">;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "HomebasePartners_partners";
};
export type HomebasePartners_partners$key = {
  readonly " $data"?: HomebasePartners_partners$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomebasePartners_partners">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 3,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "locations"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "offset"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./HomebasePartnersPaginationQuery.graphql')
    }
  },
  "name": "HomebasePartners_partners",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "locations",
          "variableName": "locations"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        }
      ],
      "concreteType": "HomebasePartnerNodeConnection",
      "kind": "LinkedField",
      "name": "homebasePartners",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HomebasePartnerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HomebasePartnerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HomebasePartners_partner"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "db09c4650094f3b637d929d04924a323";

export default node;
