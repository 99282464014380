/**
 * @generated SignedSource<<8d8da742d2d02af0c0d1de180b9e7ae4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewAppFeatures_features$data = {
  readonly newAppFeatures: ReadonlyArray<{
    readonly description: string;
    readonly id: string;
    readonly imageUrl: string | null;
    readonly title: string;
  } | null> | null;
  readonly " $fragmentType": "NewAppFeatures_features";
};
export type NewAppFeatures_features$key = {
  readonly " $data"?: NewAppFeatures_features$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewAppFeatures_features">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./NewAppFeaturesRefetchQuery.graphql')
    }
  },
  "name": "NewAppFeatures_features",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AppFeatureNode",
      "kind": "LinkedField",
      "name": "newAppFeatures",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5dd3ef8c4d955fee05437f5d967695f6";

export default node;
